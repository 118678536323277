/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { addPFSchema } from "redux/validator/admin/management";
import { useTranslation } from "react-i18next";
import useDebounce from "hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddPF,
  handleEditPF,
  handleSearchUser
} from "redux/appThunk/Admin/management";
import PropTypes from "prop-types";
import ViewEditPopup from "component/common/ViewEditPopup";
import { mappedPermissionObj } from "hooks/usePermission";
import { getPFDataByID } from "redux/actions/action";
import { typeObj } from "component/common/enum";

export default function AddProvidentFund({
  setOpenPopUp,
  id,
  type,
  setIsEdit,
  isEdit,
  setIsDisabled,
  isDisabled,
}) {
  const [userName, setUserName] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const currentDate = new Date();
  const currentMonthString = currentDate.toLocaleString("default", {
    month: "long",
  });
  const username = useDebounce(userName);
  const searchData = useSelector((state) => state.managementReducer.searchData);
  const getSingleCompanyTax = useSelector(
    (state) => state.managementReducer.getSingleCompanyTax
  );

  useEffect(() => {
    if (username) {
      dispatch(handleSearchUser(username));
    } else {
      setShow(false);
    }
  }, [username]);

  useEffect(() => {
    setFieldValue("name", getSingleCompanyTax?.fullName || "");
    setFieldValue("employeeShare", getSingleCompanyTax?.employeeShare || "");
    setFieldValue("employerShare", getSingleCompanyTax?.employerShare || "");
    setFieldValue("status", getSingleCompanyTax?.status || "");
    setFieldValue("empId", getSingleCompanyTax?.empId);
    setFieldValue("uAN", getSingleCompanyTax?.uanNo);
    setUserName(getSingleCompanyTax?.fullName || "");
  }, [getSingleCompanyTax]);

  const initialvalues = {
    name: userName,
    empId: "",
    uAN: "",
    month: currentMonthString,
    employeeShare: "",
    employerShare: "",
    status: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addPFSchema,
    onSubmit: async (values) => {
      try {
        setSubmitOnce(true);
        if (isEdit || type === typeObj.view) {
          dispatch(handleEditPF(values, values.empId));
          navigate("/management", { state: { activeTab: 1 } });
        } else {
          dispatch(handleAddPF(values, values.empId));
          navigate("/management", { state: { activeTab: 1 } });
        }
        dispatch(getPFDataByID({}));
        handleClose();
      } catch (error) {
        setSubmitOnce(true);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = Formik;

  const getColorFromEmail = (email) => {
    const hashCode = email
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setShow(true);
  };

  function BlurHandle() {
    setTimeout(() => {
      setShow(false);
    }, 500);
  }

  const handleEmployeedataPf = (item) => {
    setUserName(item.fullName);
    setShow(false);
    setFieldValue("uAN", item.uanNo);
    setFieldValue("empId", item.id);
  };

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit(id);
    } else if (event.key === "Escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  const handleClose = () => {
    dispatch(getPFDataByID({}));
    setOpenPopUp(false);
    setIsEdit(false);
    setIsDisabled(false);
    resetForm();
  };

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <ViewEditPopup
          popupBox={() => handleClose()}
          title={
            isEdit || type === typeObj.view
              ? t("Provident Fund Details")
              : t("Add Provident Fund")
          }
          handleSubmit={handleSubmit}
          submitBtnText={t("saveAndNext")}
          type={typeObj[type]}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          submitOnce={submitOnce}
          setIsDisabled={setIsDisabled}
          currentResource={mappedPermissionObj.Providentfund}
        >
          <FormikProvider value={values}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(id);
              }}
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
            >
              <div className="relative flex flex-col">
                <label htmlFor="name">{t("name")}</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={userName}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
              rounded-lg block w-full ps-4 p-2.5 "
                  onChange={handleUsernameChange}
                  required
                  disabled={id ? true : false}
                  onBlur={BlurHandle}
                  autoComplete="off"
                  onKeyDown={(e) => enterClick(e)}
                />
                {errors.name && touched.name ? (
                  <p className="text-[red]">{errors.name}</p>
                ) : null}
                <button
                  type="button"
                  className="absolute top-9 right-2"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </div>
              {show && (
                <div
                  className="absolute top-[28%] w-1/3 bg-white shadow-md
            p-[11px] rounded-md z-10 max-h-[19rem] overflow-y-scroll custom_scroll"
                >
                  {searchData &&
                    searchData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full p-1 flex items-center space-x-2 cursor-pointer hover:bg-[#031B59] rounded group "
                          onClick={() => {
                            handleEmployeedataPf(item);
                          }}
                        >
                          <div className="flex items-center">
                            <div
                              className="w-8 h-8 rounded-full flex items-center justify-center text-white bg-[#031B59]
                              mr-2"
                              style={{
                                backgroundColor: getColorFromEmail(item.email),
                              }}
                            >
                              {item.email?.[0]?.toUpperCase()}
                            </div>
                          </div>
                          <div className="w-full flex flex-col">
                            <p className="group-hover:text-white text-[#313131] capitalize"></p>
                            <span className="group-hover:text-white text-xs text-[#A1A1A1]">
                              {item.email}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              <div className="relative flex flex-col">
                <label htmlFor="empId">{t("empId")}</label>
                <input
                  type="text"
                  name="empId"
                  id="empId"
                  className="h-[2.625rem] w-full p-4 border border-[#E2E8F0] rounded"
                  value={values.empId}
                  onBlur={handleBlur}
                  disabled={id ? true : false}
                />
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="uan">{t("uan")}</label>
                <input
                  type="text"
                  name="uAN"
                  id="uan"
                  className="h-[2.625rem] w-full p-4 border border-[#E2E8F0] rounded"
                  value={values.uAN}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                />
                {errors.uAN && touched.uAN ? (
                  <p className="text-[red]">{errors.uAN}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="month">{t("month")}</label>
                <input
                  type="text"
                  name="month"
                  id="month"
                  className="h-[2.625rem] w-full p-4 border border-[#E2E8F0] rounded"
                  value={values.month}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                {errors.month && touched.month ? (
                  <p className="text-[red]">{errors.month}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="employeeShare">{t("employeeShare")}</label>
                <input
                  type="text"
                  name="employeeShare"
                  id="employeeShare"
                  className="h-[2.625rem] w-full p-4 border border-[#E2E8F0] rounded"
                  value={values.employeeShare}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => enterClick(e)}
                  disabled={isDisabled}
                />
                {errors.employeeShare && touched.employeeShare ? (
                  <p className="text-[red]">{errors.employeeShare}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="employerShare">{t("employerShare")}</label>
                <input
                  type="text"
                  name="employerShare"
                  id="employerShare"
                  className="h-[2.625rem] w-full p-4 border border-[#E2E8F0] rounded"
                  value={values.employerShare}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  disabled={isDisabled}
                />
                {errors.employerShare && touched.employerShare ? (
                  <p className="text-[red]">{errors.employerShare}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="status">{t("status")}</label>
                <select
                  className={`h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded   ${
                    values.status === "Settled"
                      ? "text-[orange]"
                      : values.status === "Deducted"
                        ? "text-[red]"
                        : values.status === "Refund"
                          ? "text-[green]"
                          : "text-[#A1A1A1]"
                  }`}
                  type="text"
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  disabled={isDisabled}
                >
                  <option value="Select" selected>
                    Select
                  </option>
                  <option value="Settled" className="text-[orange]">
                    Settled
                  </option>
                  <option value="Deducted" className="text-[red]">
                    Deducted
                  </option>
                  <option value="Refund" className="text-[green]">
                    Refund
                  </option>
                </select>
                {errors.status && touched.status ? (
                  <p className="text-[red]">{errors.status}</p>
                ) : null}
              </div>
            </Form>
          </FormikProvider>
        </ViewEditPopup>
      </div>
    </div>
  );
}
AddProvidentFund.propTypes = {
  setOpenPopUp: PropTypes.any,
  id: PropTypes.any,
  setShowPopup: PropTypes.any,
  saveClick: PropTypes.any,
  setSaveClick: PropTypes.any,
  currentPage: PropTypes.any,
  type: PropTypes.any,
  setIsEdit: PropTypes.any,
  isEdit: PropTypes.any,
  setIsDisabled: PropTypes.any,
  isDisabled: PropTypes.any,
};
