import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";

import {
  addProject,
  assignResourseToProject,
  fetchProject,
  fetchProjectDetailById,
  updateProjectDetailById
} from "redux/appThunk/Admin/project";
import { useFormik } from "formik";
import { addProjectSchema } from "redux/validator/admin/project";
import { GrFormClose } from "react-icons/gr";
import { GoSearch } from "react-icons/go";
import ViewEditPopup from "component/common/ViewEditPopup";
import { useTranslation } from "react-i18next";
import { fetchClientsData } from "redux/appThunk/Admin/client";
import { ProjectInfoById, setProjectId } from "redux/actions/action";
import { getDateDashFormat } from "utils/date";
import PropTypes from "prop-types";
import DatePicker from "component/common/DatePicker";
import { mappedPermissionObj } from "hooks/usePermission";
import Employeebrief from "component/admin/Employee/Employeebrief";

function AddProject({
  setShowPopup,
  saveClick,
  setSaveClick,
  show,
  setShow,
  currentPage,
  type,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resourses, setResourses] = useState([]);
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { projectId, projectInfoById } = useSelector(
    (state) => state.projectReducer
  );
  const [serachedData, setSearchedData] = useState([]);
  const [alreadyExistResource, setAlreadyExistResource] = useState([]);
  const { searchResult } = useSelector((state) => state.userSearchReducer);
  const { clientsData } = useSelector((state) => state.ClientsReducer);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialvalues = {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    clientName: "",
    clientId: "",
    currency: "",
    amount: "",
    billingType: "",
    status: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: !saveClick && addProjectSchema,
    onSubmit: async (values) => {
      setSaveClick(true);
      setSubmitOnce(true);
      if (saveClick === true) {
        const ids = resourses?.map((obj) => obj.id);
        dispatch(assignResourseToProject(projectId, ids));
        handleClose();
        setTimeout(() => {
          dispatch(fetchProject(currentPage));
        }, 500);

      } else {
        if (type === "view") {
          dispatch(updateProjectDetailById(projectId, values));
        } else {
          dispatch(addProject(values));
        }
      }
      setSubmitOnce(false);
    },
  });
  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectDetailById(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    const idArr = [];
    projectInfoById?.resources?.map((obj) => idArr.push(obj.userId));
    resourses?.map((obj) => idArr.push(obj.id));
    setAlreadyExistResource(idArr);
  }, [resourses, projectInfoById]);

  useEffect(() => {
    if (searchResult) {
      const filteredData = searchResult?.filter(
        (obj) => !alreadyExistResource?.includes(obj?.id)
      );
      setSearchedData(filteredData);
    }
  }, [searchResult]);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = Formik;

  useEffect(() => {
    setFieldValue("name", projectInfoById?.name || "");
    setFieldValue("description", projectInfoById?.description || "");
    setFieldValue(
      "startDate",
      projectInfoById?.startDate
        ? getDateDashFormat(projectInfoById?.startDate)
        : ""
    );
    setFieldValue(
      "endDate",
      projectInfoById?.endDate
        ? getDateDashFormat(projectInfoById?.endDate)
        : ""
    );
    setFieldValue("clientName", projectInfoById?.clientName || "");
    setFieldValue("clientId", projectInfoById?.clientId || "");
    setFieldValue("currency", projectInfoById?.currency || "");
    setFieldValue("amount", projectInfoById?.amount || "");
    setFieldValue("billingType", projectInfoById?.billingType || "");
    setFieldValue("status", projectInfoById?.status);
  }, [projectInfoById]);

  useEffect(() => {
    if (search !== "") {
      setShow(true);
      dispatch(handleSearchUserForChat(search));
    } else {
      setShow(false);
    }
  }, [search]);

  useEffect(() => {
    if (search === "") {
      document.querySelector(".focus")?.focus();
    }
  }, [search]);

  const handleRemove = (id) => {
    setResourses((prevState) => prevState.filter((obj) => obj.id !== id));
  };

  const handleClose = () => {
    setShowPopup(false);
    setSaveClick(false);
    resetForm();
    setResourses([]);
    dispatch(setProjectId(""));
    dispatch(ProjectInfoById({}));
  };

  const handleEmployeedata1 = (item) => {
    setFieldValue("clientName", item?.name);
    setFieldValue("clientId", item?.user_id);
    setShow(false);
  };

  const handleEmployeedata2 = (item) => {
    setShow(false);
    setResourses((prevState) => [...prevState, item]);
    setSearch("");
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      setShow(true);
    } else {
      setShow(false);
      setFieldValue("clientId", null);
    }
    setFieldValue("clientName", e.target.value);
    let debounceTimer;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      dispatch(fetchClientsData(e.target.value));
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  };

  const getColorFromEmail = (email) => {
    const hashCode = email
      ?.split("")
      ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };

  return (
    <div className="z-30">
      {saveClick ? (
        <div
          className="w-full h-full flex items-center
          justify-center fixed top-0 left-0  bg-[rgba(3,27,89,.2)]"
        >
          <div
            className="min-w-[37.5rem] w-fit z-10 h-fit flex flex-col items-center gap-[1.5rem] bg-white
            rounded-[18px] shadow-lg"
          >
            <form
              className="w-full flex flex-col h-full"
              onSubmit={handleSubmit}
            >
              <div
                className="w-full px-7 py-5 flex justify-between items-center text-[#031B59]
                font-extrabold text-xl"
              >
                <p>{t("assignResource")}</p>
                <button className="w-fit h-fit" onClick={handleClose}>
                  <GrFormClose className="flex w-[2rem] h-[2rem]" />
                </button>
              </div>
              <div className="w-full flex flex-col">
                <hr />
                <div
                  className="m-[1rem] p-[0.25rem] border rounded-[1rem] flex items-center
            justify-center"
                >
                  <div
                    className="w-full max-w-[37.5rem] h-fit min-h-[45px] flex flex-wrap items-center
                 justify-start gap-2 p-[0.5rem]"
                  >
                    {resourses?.map((obj, index) => (
                      <span
                        key={index}
                        className="p-2 flex flex-row items-center justify-normal gap-2
                  rounded-full bg-[#F2F6FF]"
                      >
                        {obj.name}
                        <GrFormClose
                          className="fill-[#A1A1A1] w-5 h-5 p-1 box-content cursor-pointer"
                          onClick={() => {
                            handleRemove(obj.id);
                          }}
                        />
                      </span>
                    ))}
                    <input
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      name="clientName"
                      className="focus w-[25rem] outline-none"
                    />
                  </div>
                  <GoSearch className="w-[1.375rem] h-[1.375rem]" />
                </div>
                <div className="relative z-10 h-0 px-2">
                  {show && (
                    <div
                      className="flex justify-start flex-col
                w-full max-w-[36rem] bg-white group custom_scroll
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-3 rounded-2xl  z-10 overflow-y-auto max-h-60"
                    >
                      {serachedData?.length > 0 ? (
                        serachedData.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="w-full px-2 flex items-center space-x-2 cursor-pointer
               hover:bg-[#031B59] group rounded"
                              onClick={() => handleEmployeedata2(item)}
                            >
                              <div className="w-full flex flex-col">
                                <p className="text-[#313131] hover:text-white capitalize p-1">
                                  <div className="flex items-center">
                                    <div
                                      className="w-9 h-8 rounded-full flex items-center
                            justify-center text-white bg-[#031B59]
                      mr-2"
                                      style={{
                                        backgroundColor: getColorFromEmail(
                                          item.email
                                        ),
                                      }}
                                    >
                                      {item.email?.[0]?.toUpperCase()}
                                    </div>
                                    <Employeebrief
                                      email={item.email}
                                      names={item.name}
                                    />
                                  </div>
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-[#031B59]">{t("noUserFound")}</p>
                      )}
                    </div>
                  )}
                </div>
                <hr />
              </div>
              <div className="w-full py-5 flex justify-between items-center">
                {(type === "add" || isEdit) && (
                  <button
                    className="flex w-[7.5rem] h-[3rem] justify-center items-center gap-[0.5rem]
      rounded-[0.5rem] text-[#686868]"
                    onClick={() => {
                      handleClose();
                      setTimeout(() => {
                        dispatch(fetchProject(currentPage));
                      }, 500);
                    }}
                  >
                    {t("skip")}
                  </button>
                )}
                <div className="w-full flex justify-end items-center gap-8 px-7">
                  <button
                    onClick={handleClose}
                    className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center
                items-center gap-[0.5rem]
      rounded-[0.5rem] text-[#686868]"
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center 
                items-center gap-[0.5rem]
                  ${
        resourses?.length <= 0 ? "bg-gray-400" : "bg-[#031B59]"
        } rounded-[2.5rem] font-inter text-[1rem]
        font-bold leading-normal text-white`}
                    type="button"
                    disabled={resourses?.length <= 0}
                    onClick={handleSubmit}
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ViewEditPopup
          popupBox={() => handleClose()}
          title={
            isEdit || type === "view" ? t("projectDetails") : t("addProject")
          }
          handleSubmit={handleSubmit}
          submitBtnText={t("saveAndNext")}
          type={type}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          submitOnce={submitOnce}
          currentResource={mappedPermissionObj.Project}
          setIsDisabled={setIsDisabled}
          isDisabled={isDisabled}
        >
          <form className="flex flex-col gap-[1rem] h-full max-h-[43rem] overflow-y-scroll no-scrollbar">
            <div className="flex flex-col gap-[0.25rem] ">
              <label className="text-[16px]">{t("projectName")}</label>
              <input
                className="w-[592px] p-[10px] border rounded-[0.25rem] outline-none"
                type="text"
                placeholder={t("projectName")}
                name="name"
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={type === "view" && !isEdit}
              />
              {errors?.name && touched?.name && (
                <p className="text-[1rem] text-red-500">{errors.name}</p>
              )}
            </div>
            <div className="flex flex-col gap-[0.25rem]">
              <label className="text-[16px]">{t("project_desc")}</label>
              <input
                className="w-[592px] p-[10px] border rounded-[0.25rem] outline-none"
                type="text"
                placeholder={t("project_desc")}
                name="description"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={type === "view" && !isEdit}
              />
              {errors?.description && touched?.description && (
                <p className="text-[1rem] text-red-500">{errors.description}</p>
              )}
            </div>
            <div className="flex gap-5">
              <div className="flex flex-col">
                <div className="grid">
                  <label className="text-[16px]">{t("startDate")}</label>
                  <DatePicker
                    name={"startDate"}
                    value={values?.startDate}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={type === "view" && !isEdit}
                    styles={"w-[286px] mt-[0.25rem]"}
                  />
                </div>
                {errors?.startDate && touched?.startDate && (
                  <p className="text-[1rem] text-red-500">{errors.startDate}</p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-[16px]">{t("endDate")}</label>
                <DatePicker
                  name={"endDate"}
                  value={values?.endDate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled={type === "view" && !isEdit}
                  styles={"w-[286px] mt-[0.25rem]"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[0.25rem]">
              <label className="text-[16px]">{t("clientName")}</label>
              <div
                className="w-[592px] p-[10px] border rounded-[0.25rem] flex items-center
          justify-center"
              >
                <input
                  placeholder={t("clientName")}
                  onBlur={handleBlur}
                  onChange={handleSearch}
                  value={values?.clientName}
                  name="clientName"
                  className="w-[592px] outline-none"
                  disabled={type === "view" && !isEdit}
                />
                <GoSearch className="w-[1.375rem] h-[1.375rem]" />
              </div>
            </div>
            {show && (
              <div
                className="absolute flex justify-start flex-col top-[28rem] w-full
            max-w-[36rem] bg-white group
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10
      overflow-y-auto max-h-80"
              >
                {clientsData ? (
                  clientsData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full px-2 flex items-center space-x-2 cursor-pointer
               hover:bg-[#031B59] group rounded"
                        onClick={() => handleEmployeedata1(item)}
                      >
                        <div className="w-full flex flex-col">
                          <p className="text-[#313131] hover:text-white capitalize p-1">
                            <div className="flex items-center">
                              <div
                                className="w-9 h-8 rounded-full flex items-center
                            justify-center text-white bg-[#031B59]
                      mr-2"
                                style={{
                                  backgroundColor: getColorFromEmail(
                                    item.email
                                  ),
                                }}
                              >
                                {item.email?.[0]?.toUpperCase()}
                              </div>
                              <Employeebrief
                                email={item.email}
                                names={item.name}
                              />
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-[#031B59]">{t("noUserFound")}</p>
                )}
              </div>
            )}
            <div className="flex gap-5">
              <div className="flex flex-col gap-[0.25rem]">
                <label className="text-[16px]">{t("currency")}</label>
                <select
                  className="w-[286px] p-[10px] border rounded-[0.25rem] outline-none bg-white"
                  name="currency"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.currency}
                  disabled={type === "view" && !isEdit}
                >
                  <option value="">Select Currency</option>
                  <option value="Dollar">$</option>
                  <option value="Canadian">C$</option>
                  <option value="Indian">₹</option>
                </select>
              </div>
              <div className="flex flex-col gap-[0.25rem]">
                <label className="text-[16px]">{t("amount")}</label>
                <input
                  className="w-[286px] p-[10px] border rounded-[0.25rem] outline-none"
                  placeholder={t("amount")}
                  type="text"
                  name="amount"
                  value={Formik.values.amount}
                  onChange={(e) =>
                    setFieldValue("amount", e.target.value.replace(/\D/g, ""))
                  }
                  onBlur={handleBlur}
                  disabled={type === "view" && !isEdit}
                />
                {errors?.amount && touched?.amount && (
                  <p className="text-[1rem] text-red-500">{errors.amount}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[0.25rem]">
              <label className="text-[16px]">{t("billingType")}</label>
              <select
                className="w-[592px] p-[10px] border rounded-[0.25rem] outline-none bg-white"
                name="billingType"
                onChange={handleChange}
                value={values?.billingType}
                onBlur={handleBlur}
                disabled={type === "view" && !isEdit}
              >
                <option value="">Select Billing Type</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="status">{t("status")}</label>
              <div className="relative">
                <select
                  name="status"
                  className="w-[592px] p-[10px] border rounded-[0.25rem] outline-none bg-white"
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  value={values?.status}
                  disabled={type === "view" && !isEdit}
                >
                  <option value="">{t("select")}</option>
                  <option value="start">{t("started")}</option>
                  <option value="complete">{t("completed")}</option>
                  <option value="Not Yet Start">{t("Not Yet Started")}</option>
                  <option value="delay">{t("delayed")}</option>
                </select>
              </div>
              {Formik.errors.status && Formik.touched.status ? (
                <p className="text-[red]">{Formik.errors.status}</p>
              ) : null}
            </div>
          </form>
        </ViewEditPopup>
      )}
    </div>
  );
}

export default AddProject;

AddProject.propTypes = {
  Popup: PropTypes.func,
  saveClick: PropTypes.bool,
  setSaveClick: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  currentPage: PropTypes.any,
  type: PropTypes.string,
  setShowPopup: PropTypes.func,
};
