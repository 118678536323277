import Card from "component/common/Card";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoChevronDown } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAttendance } from "redux/appThunk/Admin/dashboard";
import DashboardAttendantPopup from "./DashboardAttendentPopup";
import PropTypes from "prop-types";
import { useOnClickOutside } from "component/common/useOneClickOutsid";

const DashboardAttendant = () => {
  const { t } = useTranslation();
  const roles = JSON.parse(localStorage?.getItem("userLoginToken"));
  const dispatch = useDispatch();
  const [val, setVal] = useState( roles?.role === "employee" ? "all" : "team");
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  const selector = useSelector((state) => state.dashboardReducers.allAttendent);
  useEffect(() => {
    dispatch(dashboardAttendance(val));
  }, [val]);
  const header = [
    {
      id: 1,
      title: t("empName"),
    },
    {
      id: 2,
      title: t("Department"),
    },
    {
      id: 3,
      title: t("Location"),
    },
    {
      id: 4,
      title: t("Job Title"),
    },
    {
      id: 5,
      title: t("Status"),
    },
    {
      id: 6,
      title: t("Punch In"),
    },
    {
      id: 7,
      title: t("Delay"),
    },
  ];

  let data = [
    {
      id: 1,
      num: selector.alluser ? selector.alluser : 0,
      status: t("total"),
    },

    {
      id: 2,
      num: selector.activeruser ? selector.activeruser : 0,
      status: t("active"),
    },
    {
      id: 3,
      num: selector.onleave ? selector.onleave : 0,
      status: t("onLeave"),
    },
    {
      id: 4,
      num: selector.halfday ? selector.halfday : 0,
      status: t("onHalfDay"),
    },
    {
      id: 5,
      num: selector.wfh ? selector.wfh : 0,
      status: t("wfh"),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
    case t("total"):
      return "#031B59";
    case t("active"):
      return "#1A8718";
    case t("onLeave"):
      return "#FF0000";
    case t("onHalfDay"):
      return "#ED6E0F";
    case t("wfh"):
      return "#191919";
    default:
      return "#191919"; //
    }
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const [selectedItem, setSelectedItem] = useState(
    roles?.role === "employee" ? "organizationalAttendance" : "teamAttendant");

  const handleItemClick = (value) => {
    setSelectedItem(value);
    setVal("team");
  };

  const handleItemClick2 = (value) => {
    setSelectedItem(value);
    setVal("all");
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  useOnClickOutside(menuRef, () => setOpen(!open));

  return (
    <Card cardClass={"h-[10.563rem] w-full flex flex-col col-span-2 p-5 "}>
      <div className="flex flex-row ">
        <div className="h-fit w-full flex justify-between">
          <div className="w-full h-full flex items-center ">
            <p
              className={` lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center text-[#031B59] `}
            >
              {selectedItem === "teamAttendant"
                ? t("teamAttendant")
                : t("organizationalAttendance")}
            </p>
            {
              roles?.role === "employee" ? null : (
                <div className="relative">
                  <button
                    className="h-9 w-9 flex items-center justify-center"
                    onClick={handleOpen}
                  >
                    <GoChevronDown className="h-5 w-5 text-[#031B59]" />
                  </button>
                  {open && (
                    <div
                      ref={menuRef}
                      className="absolute hidden bg-white shadow-md py-2 rounded-md z-10 top-full left-0"
                    >
                      <ul className="p-1">
                        <li
                          className={`block px-4 py-2 text-gray-800 
                    hover:bg-[#F2F6FF] 
                    whitespace-nowrap rounded-lg`}
                          value={"teamAttendant"}
                          onClick={() => handleItemClick("teamAttendant")}
                        >
                          {t("teamAttendant")}
                        </li>
                        <li
                          className={`block px-4 py-2 text-gray-800
                     hover:bg-[#F2F6FF] 
                     whitespace-nowrap rounded-lg`}
                          onClick={() =>
                            handleItemClick2("organizationalAttendance")
                          }
                        >
                          {t("organizationalAttendance")}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )
            }
          </div>

          <div>
            <button
              className="text-[#031B59] text-base whitespace-nowrap"
              onClick={() => setOpenDrawer(true)}
            >
              {t("view_all")}
            </button>
            {openDrawer && (
              <DashboardAttendantPopup
                title={
                  selectedItem === "teamAttendant"
                    ? t("teamAttendant")
                    : t("organizationalAttendance")
                }
                closeDrawer={closeDrawer}
                headerData={header}
                selectorData={selector}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-around text-3xl font-black ">
        {data.length === 0 ? (
          <>
            {[1, 2, 3, 4, 5].map((id) => (
              <div key={id} className={`px-2 w-full border-r border-[#E2E8F0]`}>
                <div className="hover:bg-[#F2F6FF] hover:rounded-[1.25rem] p-6">
                  <h4 style={{ color: "#191919" }}>0</h4>
                  <p className="text-[#191919] font-normal text-sm whitespace-nowrap">
                    {t("loading")}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          data.map((item) => (
            <div
              key={item.id}
              className="px-2 w-full border-r border-[#E2E8F0]"
            >
              <div className="hover:bg-[#F2F6FF] hover:rounded-[1.25rem] p-6">
                <h4 style={{ color: getStatusColor(item.status) }}>
                  {item.num}
                </h4>
                <p className="text-[#191919] font-normal text-sm whitespace-nowrap">
                  {item.status}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </Card>
  );
};

DashboardAttendant.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  headerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectorData: PropTypes.shape({
    alluser: PropTypes.number.isRequired,
    activeruser: PropTypes.number.isRequired,
    onleave: PropTypes.number.isRequired,
    halfday: PropTypes.number.isRequired,
    wfh: PropTypes.number.isRequired,
  }).isRequired,
};

export default DashboardAttendant;
