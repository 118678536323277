export const formatSearchData = (response) => {
  const requiredResponse = response.departments.map(department => ({
    department: department.name,
    id: department.id,
  }));

  return requiredResponse;
};

export const formatDesignationData = (response) => {
  const requiredResponse = [];
  const desinationName = Object?.keys(response);
  desinationName?.map((obj) => {
    const tempObj = {
      id: obj,
      designation: response[obj]?.designation,
      users: response[obj]?.users,
    };
    requiredResponse.push(tempObj);

    return requiredResponse;
  });
  const data = Object.keys(response).map((key) => {
    let updatedObj = {
      id: key,
      designation: response[key]["designation"],
      users: response[key]["users"],
    };

    return updatedObj;
  });

  return data;
};

export const departments = ({ currentPage, totalPages, departments }) => {
  return {
    data: departments.map((item) => ({
      id: item?.id,
      department: item?.name,
      date: item?.created_date,
      createdBy: item?.created_by,
      designation: item?.designations,
    })),
    currentPage,
    totalPages,
  };

};

export const designations = (response) => {
  const requiredResponse = response.map((item) => {

    const usersInfo = item.users.map((user) => {
      return{
        email: user.email,
        full_name: user.full_name,

      };

    });

    return {
      id: item?.id,
      designation: item?.designation,
      users: usersInfo,
      department_id: item?.department_id,

    };
  });

  return requiredResponse;
};

