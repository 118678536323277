export const documentContent = {
  aadhar: "adharCard",
  bankStatement: "Bank Statement",
  expLetter: "Experience Letter",
  pAN: "PAN",
  passport: "Passport",
  resume: "Resume",
  releivingLetter: "Relieving Letter",
  tenthMarksheet: "10 Marksheet",
  twelthMarksheet: "12 Marksheet",
  anyOtherCertificate: "Any Other Certificate",
  graduation: "Graduation Degree",
};
