import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  isLoading,
  setEmployeeData,
  setIsLoading,
  setIsSuccess,
  setPageCount,
  setstatus
} from "redux/actions/action";
import { formatStatuslist } from "redux/selector/Admin/status";
import { formatStatusResponse } from "redux/selector/Employee/status";

function createFormDataKey(...keys) {
  return keys.reduce((acc, key) => {
    return `${acc}[${key}]`;
  }, "status");
}

function createFormData(jsonData) {
  const formData = new FormData();

  for (let key in jsonData) {
    if (Array.isArray(jsonData[key])) {
      jsonData[key].forEach((child, index) => {
        for (let childkey in child) {
          if (Array.isArray(child[childkey])) {
            if (childkey === "images" && child[childkey]?.length > 0) {
              child[childkey].forEach((img) => {
                formData.append(
                  `${createFormDataKey(key, index, childkey)}[]`,
                  img
                );
              });
            }
            else if (childkey === "removed_images" && child[childkey]?.length > 0) {
              child[childkey]?.forEach((img) =>
                formData.append(
                  `${createFormDataKey(key, index, "remove_images")}[]`,
                  img
                )
              );
            }
          } else {
            formData.append(
              createFormDataKey(key, index, childkey),
              child[childkey]
            );
          }
        }
      });
    } else {
      formData.append(createFormDataKey(key), jsonData[key]);
    }
  }

  return formData;
}

export const fetchstatus = (currentPage, itemsPerPage) => async (dispatch) => {
  const id = JSON.parse(localStorage.getItem("userLoginToken")).id || null;
  try {
    dispatch(isLoading(true));
    const path = `time_sheets?page=${currentPage}&per_page=${itemsPerPage}&id=${id}`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatStatusResponse(response?.statuses);
    dispatch(setstatus(formattedResponse));
    dispatch(setPageCount(response?.pagination_data?.total_pages));
    dispatch(isLoading(false));
  } catch (error) {
    ToastServices.showToast({
      message: "Error during fetch data !",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchData = (setProjectsList) => async () => {
  try {
    const userData = JSON.parse(localStorage.getItem("userLoginToken"));
    const userID = userData?.id;
    const path = `users/${userID}/projects`;
    const { response } = await Helper.get(path);
    setProjectsList(response);
  } catch (error) {
    ToastServices.showToast({
      message: "Error !",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchEmployeeData = (currentPage) => async (dispatch) => {
  const id = JSON.parse(localStorage.getItem("userLoginToken")).id || null;
  try {
    const path = `time_sheets/${id}?page=${currentPage}&per_page=10`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatStatuslist(response);

    dispatch(setEmployeeData(formattedResponse));
    dispatch(setPageCount(response?.totalPages));
  } catch (error) {
    ToastServices.showToast({
      message: "Error during fetch data !",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const updateTimeSheet = (statusId, formData) => async (dispatch) => {
  const path = `time_sheets/${statusId}`;
  const { status } = await Helper.put(formData, path, true);
  try {
    dispatch(setIsLoading(false));
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Updated Successfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  } finally {
    setTimeout(() => {
      dispatch(setIsLoading(true));
    }, 2000);
  }
};

export const createTimeSheet = (data) => async (dispatch) => {
  dispatch(setIsSuccess(false));
  const path = apiUrl.status;
  let formData = createFormData(data);

  const { status } = await Helper.post(formData, path, true);
  if (status === 200 || status === 201) {
    ToastServices.showToast({
      message: "Added Successfully !",
      type: "success",
      autoClose: 3000,
    });

    dispatch(setIsSuccess(true));
  } else throw new Error();
};

export const deleteTimesheetTask = (taskId) => async (dispatch) => {
  dispatch(setIsSuccess(false));
  const path = `time_sheets/${taskId}`;
  const response = await Helper.delete(path);
  if (response?.status === 200 || response?.status === 201) {
    ToastServices.showToast({
      message: "Deleted Successfully !",
      type: "success",
      autoClose: 3000,
    });
    dispatch(setIsSuccess(true));
  } else throw new Error();
};

// export const getUserIndividualTimeSheet = (timeSheetId) => async (dispatch) => {
//   dispatch(setIndividualLoading(true));
//   const selectedTimesheet = await Helper.get(
//     apiUrl.getIndividualTimesheet(timeSheetId)
//   );
//   const updatedData = {
//     id: selectedTimesheet?.response?.status.id,
//     employeeId: selectedTimesheet?.response?.status.user_id,
//     statusDate: selectedTimesheet?.response?.status_date,
//     totalWorkingHours: selectedTimesheet?.response?.total_working_hours,
//     totalBillingHours: selectedTimesheet?.response?.total_billing_hours,
//     tasks: selectedTimesheet?.response?.tasks,
//   };
//   dispatch(setUserSingleTimesheet(updatedData));
//   dispatch(setIndividualLoading(false));
// };
