import React from 'react';

export default function SVGRedCross() {
  return (
    <div className='cursor-pointer'>
      <svg width="24" height="24" viewBox="0 0 24 24"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.625C5.71836 0.625 0.625 5.71836
        0.625 12C0.625 18.2816 5.71836 23.375
        12 23.375C18.2816 23.375 23.375 18.2816 23.375
        12C23.375 5.71836 18.2816 0.625 12 0.625ZM16.1996
        16.3215L14.5238 16.3139L12 13.3051L9.47871
        16.3113L7.80039 16.3189C7.68867 16.3189
        7.59727 16.2301 7.59727 16.1158C7.59727
        16.0676 7.61504 16.0219 7.64551 15.9838L10.9488
        12.0482L7.64551 8.11523C7.61483 8.07802 7.5978
        8.03143 7.59727 7.9832C7.59727 7.87148 7.68867
        7.78008 7.80039 7.78008L9.47871 7.7877L12 10.7965L14.5213
        7.79023L16.1971 7.78262C16.3088 7.78262 16.4002 7.87148
        16.4002 7.98574C16.4002 8.03398 16.3824 8.07969 16.352
        8.11777L13.0537 12.0508L16.3545 15.9863C16.385 16.0244
        16.4027 16.0701 16.4027 16.1184C16.4027 16.2301 16.3113
        16.3215 16.1996 16.3215Z" fill="#FF0000"/>
      </svg>
    </div>
  );
}
