import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector } from "react-redux";
import { birthdays } from "redux/appThunk/Admin/dashboard";
import DashboardCommon from "./DashboardCommon";

const DashboardBirthday = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selector = useSelector((state) => state.dashboardReducers.birthday);
  useEffect(() => {
    dispatch(birthdays());
  }, []);

  return (
    <div className="h-[10rem]">
      {(selector?.length === 0) ? (
        <DashboardCommon title={t("birthdays")} count={0} data={[]} message="No One Have Birthday Today" />
      ) : selector[0]?.todaysBirthdays?.length === 0 && selector[0]?.upcomingBirthdays?.length === 0 ? (
        <DashboardCommon title={t("birthdays")} count={0} data={[]} message="No One Have Birthday Today " />
      ) :
        selector[0]?.todaysBirthdays?.length === 0 ? (
          <DashboardCommon title={t("upcomingbirth")} count={selector[0]?.upcomingBirthdays?.length}
            data={selector[0]?.upcomingBirthdays} />
        ) : (
          <DashboardCommon title={t("todaybirth")} count={selector[0]?.upcomingBirthdays?.length}
            data={selector[0]?.todaysBirthdays} />
        )
      }
    </div>

  );
};

export default DashboardBirthday;

