import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoaderComp from "component/loader/LoaderComp";
import Popup from "component/common/Popup";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import { IoIosRemoveCircleOutline, IoIosSend } from "react-icons/io";
import Employeebrief from "./Employeebrief";
import { LiaToggleOnSolid } from "react-icons/lia";
import { convertDateFormat } from "utils/date";
import { employeeEye, isShowDialogBoxChange } from "redux/actions/action";
import { reInvitationSent } from "redux/appThunk/Admin/employee";
import { useDispatch } from "react-redux";

const EmployeeTable = ({
  finalFilteredValue,
  setId,
  setOpenPopUp,
  onSpotChange,
  setOnSpotChange,
  employeesType,
  setEnable,
  userPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [invitePopupOpen, setInvitePOpupOpen] = useState(false);
  const [invitationGetId, setInvitationGetId] = useState("");
  const { isLoading } = useSelector((state) => state.leaveReducer);
  const { allUser } = useSelector((state) => state.profileReducer);

  const renderRow = (
    data,
    index
  ) => {

    const adminName = JSON.parse(localStorage.getItem("userLoginToken"));

    const handleOpenDialogBox = (data) => {
      dispatch(isShowDialogBoxChange(true));
      setId(data.id);
    };

    const invitePopupChange = () => {
      dispatch(
        reInvitationSent(
          invitationGetId,
          setOnSpotChange,
          onSpotChange,
          adminName
        )
      );

      setInvitePOpupOpen(false);
    };

    const handleEmpEye = (data) => {
      setOpenPopUp(true);
      dispatch(employeeEye(data)
      );
    };

    return (

      <tr
        className="h-[3.125rem] even:bg-[#f8f9fc] text-[#031B59] z-20  border-y border-[#E2E8F0]"
        key={index}
      >
        <td
          className={`z-20 lg:w-[9.533rem] min-w-[9.533rem] p-2 left-0 sticky  font-semibold
                     ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}
      `}
        >
          <div
            className="flex items-center"
            onClick={() => {
              handleEmpEye(data);
            }}
          >
            <Employeebrief
              imageUrl={data?.profile_picture_url}
              userName={data?.name}
              names={data?.full_name}
              userEmail={data?.email}
              email={data?.email}
              designation={data?.designation?.designation}
              data={data}
            />
          </div>
        </td>
        <td className="min-w-[14rem]  p-2 text-center ">
          {data.employee_id || "---"}
        </td>

        <td className="min-w-[14rem]  p-2 text-center ">
          {data.designation?.designation || "---"}
        </td>

        <td className="min-w-[14rem] p-2 capitalize text-center">
          {data.contact_no || "---"}
        </td>

        <td className="min-w-[14rem] p-2 text-center">
          {(data?.date_of_joining &&
            convertDateFormat(data?.date_of_joining)) ||
            "---"}
        </td>
        <td className="flex items-center justify-center min-w-[14rem] p-3 ">
          {data.log_in_time !== null ? (
            <div className="m-3 w-full flex justify-center items-center">
              <button
                className={
                  " w-[8rem] h-full flex items-center justify-start edit-btn relative group" }
                style={{ color: "green" }}
              >
                <HiOutlineCheckCircle className="text-3xl m-auto" />
                <span
                  className="opacity-0 group-hover:opacity-100
                transition duration-300 absolute inset-0 flex
                items-center justify-center bg-white text-[#031B59] top-8"
                >
                  Accepted
                </span>
              </button>
            </div>
          ) : (
            <div className="m-3 w-full flex justify-center items-center">
              <button
                className={
                  " w-[8rem]  flex items-center justify-start edit-btn relative group"
                }
                disabled={
                  new Date(data?.last_invited).getTime() + 900000 >=
                  new Date().getTime()
                }
                onClick={() => {
                  setInvitePOpupOpen(true);
                  setInvitationGetId(data?.id);
                  setEmail(data?.email);
                  setName(data?.full_name);
                }}
              >
                {new Date(data?.last_invited).getTime() + 900000 >=
                  new Date().getTime() ? (
                    <>
                      <IoIosRemoveCircleOutline
                        className="text-3xl m-auto "
                        style={{ color: "grey" }}
                      />
                      <span
                        className="opacity-0 group-hover:opacity-100
                      transition duration-300 absolute inset-0 flex w-full
                      items-center justify-center bg-white text-[#031B59] top-8"
                      >
                      sent after 15min
                      </span>
                    </>
                  ) : (
                    <>
                      <IoIosSend className={`text-3xl m-auto`} />
                      <span
                        className="opacity-0 group-hover:opacity-100
                      transition duration-300 absolute inset-0 flex w-full
                      items-center justify-center bg-white text-[#031B59] top-8"
                      >
                      Invite Sent
                      </span>
                    </>
                  )}
              </button>
            </div>
          )}
          {invitePopupOpen ? (
            <Popup
              title={`Invite ${name}`}
              handleSubmit={() => invitePopupChange()}
              popupBox={() => {
                setInvitePOpupOpen(false);
              }}
            >
              {`Sending invitation to ${email}`}
            </Popup>
          ) : null}
        </td>
        {finalFilteredValue?.map(
          (item, index) =>
            item.isChecked && (
              <td key={index} className="min-w-[14rem] p-2 text-center">
                {data[item.key] || "---"}
              </td>
            )
        )}
        <td
          className={` w-[14rem] p-2 py-5  text-center   
            ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"} `}
        >
          {employeesType !== "Disable employees" ? (
            <button
              className="mr-[6px]"
              onClick={() => {
                handleEmpEye(data);
              }}
            >
              <FaEye fontSize="20px" />
            </button>
          ) : (
            <button className="mr-[6px]">
              <FaEyeSlash fontSize="20px" />
            </button>
          )}
        </td>
        {userPermission?.viewAll && (
          <td
            className={`text-center min-w-[4rem] lg-min-w-[4rem] sticky right-0 top-0 p-2 py-5 
         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"} `}
          >
            { data.is_active ? (
              <>
                <button
                  onClick={() => handleOpenDialogBox(data)}
                  className="w-full text-center h-[2rem] flex items-center justify-center disable-btn relative group"
                >
                  <LiaToggleOnSolid fontSize="40px" className="mr-[6px]" />
                  <span
                    className="opacity-0 group-hover:opacity-100
                transition duration-300 absolute inset-0
                flex items-center justify-center bg-white text-[#031B59] top-8 "
                  >
                    {t("disableEnable")}
                  </span>
                </button>
              </>
            ) : (
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={false}
                  onClick={() => {
                    handleOpenDialogBox(data);
                    setEnable(true);
                  }}
                />
                <div
                  className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                  dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full
                  rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border
                  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                  peer-checked:bg-blue-600"
                ></div>
              </label>
            )}
          </td>

        )}
      </tr>
    );
  };

  return (
    <div
      className={`overflow-x-scroll overflow-y border-x border-[#E2E8F0] 
    ${isLoading ? "custom_scroller" : "custom_scroll"}`}
    >
      <table className="w-full p-3 bg-white rounded-lg text-left">
        <thead className="flex-0 text-left left-0 ">
          <tr
            className="max-h-[3.125rem] p-2 text-[#686868]
         text-sm  font-normal  bg-[#F2F6FF] border-x left-0 z-30"
          >
            <th
              className="lg:w-[18.224rem] min-w-[18.224rem]
           max-h-[3.125rem]  left-0 sticky top-0 z-30 h-full p-4 bg-[#F2F6FF] "
            >
              {t("emp_name")}
            </th>
            <th
              className="lg:w-[14rem] min-w-[14rem] max-h-[3.125rem]  h-full p-2
          text-center  bg-[#F2F6FF] top-0 sticky"
            >
              {t("employee_id")}
            </th>
            <th
              className="lg:w-[14rem] min-w-[14rem] max-h-[3.125rem]  h-full p-2
          text-center  bg-[#F2F6FF] top-0 sticky"
            >
              {t("designation")}
            </th>
            <th
              className="lg:w-[14rem] min-w-[14rem] max-h-[3.125rem] h-full p-2
          text-center  bg-[#F2F6FF] top-0 sticky"
            >
              {t("contact_no")}
            </th>
            <th
              className="lg:w-[14rem] min-w-[14rem] max-h-[3.125rem] h-full p-2
          text-center  bg-[#F2F6FF] top-0 sticky"
            >
              {t("doj")}
            </th>
            <th
              className="lg:w-[14rem] min-w-[9rem] max-h-[3.125rem] h-full p-2
          text-center top-0 right-0 sticky  bg-[#F2F6FF] z-20"
            >
              {t("Invitation")}
            </th>
            {finalFilteredValue?.map(
              (value, index) =>
                value.isChecked && (
                  <th
                    key={index}
                    className="lg:min-w-[14rem] min-w-[14rem] h-full p-2 text-center bg-[#F2F6FF] sticky top-0"
                  >
                    {value.header}
                  </th>
                )
            )}
            <th
              className="w-[14rem] 2xl-w-[2rem] max-h-[3.125rem] h-full p-2  bg-[#F2F6FF]
          text-center sticky top-0"
            >
              {t("Action")}
            </th>
            <th
              className="lg:w-[6rem] min-w-[6rem] max-h-[3.125rem] h-full p-2
          text-center top-0 z-10 right-0 sticky  bg-[#F2F6FF]"
            >
              {t("D&A")}
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody className="p-2 text-sm text-left font-normal   flex-0">
            {allUser?.map((data, index) => renderRow(
              data,
              index))}
          </tbody>
        )}
      </table>
      {isLoading && (
        <div className="flex items-center  justify-center h-[80vh] w-full">
          <LoaderComp />
        </div>
      )}
    </div>
  );
};

export default EmployeeTable;

EmployeeTable.propTypes = {
  setId: PropTypes.any,
  finalFilteredValue: PropTypes.array,
  setOpenPopUp: PropTypes.func.isRequired,
  onSpotChange: PropTypes.func.isRequired,
  setOnSpotChange: PropTypes.func.isRequired,
  employeesType: PropTypes.array.isRequired,
  setEnable: PropTypes.func.isRequired,
  currentPage: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  userPermission: PropTypes.func.isRequired,
};
