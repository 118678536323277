import React, { useState, useEffect, useRef } from "react";
import Tree from "react-d3-tree";
import ZoomIn from "svgComponents/myTeam/ZoomIn";
import ZoomOut from "svgComponents/myTeam/ZoomOut";
import TreeNodeLabel from "./TreeNodeLabel";
import Rotate from "svgComponents/myTeam/Rotate";
import { useDispatch, useSelector } from "react-redux";
import { fetchHierarchyUsers } from "redux/appThunk/Admin/myTeams";

const Hierarchy = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [orientation, setOrientation] = useState("horizontal");
  const treeContainerRef = useRef(null);
  const dispatch = useDispatch();
  const {hierarchyData} = useSelector(
    (state) => state.MyTeamsReducer
  );

  useEffect(() => {
    const dimensions = treeContainerRef.current.getBoundingClientRect();
    setTranslate({ x: dimensions.width / 4, y: dimensions.height / 2 });
  }, []);

  useEffect(() => {
    dispatch(fetchHierarchyUsers());
  }, [dispatch]);

  const renderCustomNodeElement = ({ nodeDatum, toggleNode }) => (
    <foreignObject x={-110} y={-90} width={220} height={180}>
      <TreeNodeLabel nodeData={nodeDatum} toggleNode={toggleNode} orientation={orientation} />
    </foreignObject>
  );

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.2, 2));
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));
  };

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "horizontal" ? "vertical" : "horizontal"
    );
  };

  return (
    <div className="shadow-lg">
      <div
        className="w-[95rem] h-[45rem] rounded-lg  overflow-auto tree-container relative"
        ref={treeContainerRef}
      >
        {hierarchyData.length > 0 && (
          <Tree
            data={hierarchyData}
            orientation={orientation}
            translate={translate}
            pathFunc="elbow"
            nodeSize={{ x: 260, y: 260 }}
            separation={{ siblings: 2, nonSiblings: 2 }}
            renderCustomNodeElement={renderCustomNodeElement}
            collapsible={true}
            enableLegacyTransitions
            zoomable={true}
            scaleExtent={{ min: 0.5, max: 2 }}
            initialZoom={scale}
            zoom={scale}
          />
        )}
      </div>
      <div className="top-4 right-4 z-10 flex gap-2 pl-[85rem]">
        <button
          onClick={zoomIn}
          className="text-white px-4 py-2 rounded shadow"
        >
          <ZoomIn />
        </button>
        <button
          onClick={zoomOut}
          className="px-4 py-2 rounded shadow"
        >
          <ZoomOut />
        </button>
        <button
          onClick={toggleOrientation}
          className="text-white px-4 py-2 rounded shadow"
        >
          <Rotate />
        </button>
      </div>
    </div>
  );

};

export default Hierarchy;
