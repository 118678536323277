/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import LoaderComp from "component/loader/LoaderComp";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";
import { FaFilePdf } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { applicationRecieved } from "redux/appThunk/Admin/dashboard";
import upDownArrow from "svgComponents/upDownArrow";

const DashboardApplicationTablePopup = ({ closeDrawer, isLoading }) => {
  const { t } = useTranslation();
  const [openModel, setOpenModel] = useState(null);
  const selector = useSelector((state) => state.dashboardReducers.application);
  const [searchParams, setSearchParams] = useSearchParams();
  const jobTitle = searchParams.get("jobId");

  const handlePopup = (data) => {
    setOpenModel((prevIndex) => (prevIndex === data.id ? null : data.id));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(applicationRecieved());
  }, [dispatch]);

  const length = selector?.length;

  const handleDownload = async (url) => {
    window.open(url, "_blank");
  };
  const renderRow = (data, index) => {
    let filename = data?.resume.split("/").pop();
    filename = decodeURIComponent(filename.replace(/\?.*/, "")).replace(
      /[^a-zA-Z0-9\s]/g,
      "_"
    );

    return (
      <>
        <tr
          key={index}
          className="w-full h-12 cursor-pointer"
          onClick={() => handlePopup(data)}
        >
          <td className="h-[3.125rem] even:bg-[#f8f9fc] text-[#031B59]  border-y border-[#E2E8F0] capitalize p-5 font-bold">
            {data.fullName ? data.fullName : "Employee"}
          </td>
          <td className="border-b py-4 px-2 text-left">{data?.emails ? data?.emails : "---"}</td>
          <td className="border-b text-left px-2 py-4">{data?.appliedfor ? data?.appliedfor : "Software Developer"}</td>
          <td className="border-b text-left px-2 py-4">
            {new Date(data?.appliedDate).toLocaleDateString("en-US")}
          </td>
          <td className="border-b text-left px-2 py-4">{data?.contact ? data?.contact : "---"}</td>
          <td className="border-b text-left px-2 py-4">
            <div className="flex gap-2">
              <div
                className="flex"
                onClick={() => handleDownload(data?.resume)}
              >
                <div className="w-fit flex items-center justify-center">
                  <FaFilePdf />{" "}
                </div>
                <div className=" w-full">
                  <span className="text-xs px-1 flex">Resume</span></div>
              </div>
              <div>
                <BsChevronDown
                  className={`${
                    openModel === data.id ? "rotate-180" : ""
                  } size-4`}
                />
              </div>
            </div>
          </td>
        </tr>
        {openModel === data.id && (
          <tr>
            <td colSpan={5}>
              <AccordionForm data={data} />
            </td>
          </tr>
        )}
      </>
    );
  };

  const AccordionForm = ({ data }) => {
    const sections = [
      {
        title: t("country"),
        value: data?.country,
      },
      {
        title: t("state"),
        value: data?.states,
      },
      {
        title: t("linkedUrl"),
        value: data?.linkedin ?? "none",
      },
      {
        title: t("portfolioUrl"),
        value: data?.portfolio ?? "none",
      },
      {
        title: t("experience"),
        value: data?.experiences,
      },
      {
        title: t("currentCtc"),
        value: data?.currentctc,
      },
      {
        title: "Expected Salary",
        value: data?.expextedSalary,
      },
      {
        title: t("noticePeriod"),
        value: `${data?.noticeperiod} ${
          data?.noticePeriodNegotiable ? " (Negotiable)" : " (Non-Negotiable)"
        }`,
      },
      {
        title: t("immediateJoiner"),
        value: data?.immediateJoin ? "Yes" : "No",
      },
      {
        title: t("findUs"),
        value: data?.references,
      },
    ];

    return (
      <div className="w-full h-[261px] p-2.5 bg-white border border-slate-200 justify-start items-start inline-flex">
        <div className="w-[50%] self-stretch flex-col justify-start items-start inline-flex">
          {sections.slice(0, 5).map((section, index) => (
            <AccordionSection key={index} {...section} />
          ))}
        </div>
        <div className="w-[50%] self-stretch flex-col justify-start items-start inline-flex">
          {sections.slice(5).map((section, index) => (
            <AccordionSection key={index} {...section} />
          ))}
        </div>
      </div>
    );
  };

  const AccordionSection = ({ title, value }) => (
    <div className="self-stretch grow shrink basis-0 p-2.5 bg-slate-50 rounded-tl rounded-bl border-r border-slate-200 justify-start items-center gap-[30px] inline-flex">
      <div className="w-44 text-stone-500 text-sm font-normal flex-1-0 self-start independent items-center inline-flex">
        {title}
      </div>
      <div className="text-xs self-stretch font-normal text-slate-800 grow basis-auto">
        {value}
      </div>
    </div>
  );

  return (
    <div
      className="w-full h-full flex items-center
      justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="w-[60%] h-full bg-white flex flex-col gap-5
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight "
      >
        <div className=" w-full  h-[5rem] p-2 flex justify-between items-center border-b border-[#E2E8F0]">
          <h1 className="font-extrabold text-xl text-[#031B59] capitalize">
            {t("Application Received")} {jobTitle}
          </h1>
          <div
            className="border-2 p-1 text-[1.5rem] cursor-pointer"
            onClick={closeDrawer}
          >
            <RxCross2 />
          </div>
        </div>
        <div className="flex flex-col w-full h-full px-4">
          <div className="p-2">
            <p>
              {length} {t("listed")}{" "}
            </p>
          </div>
          <table className="p-2">
            <thead className="">
              <tr
                className="p-2 text-[#686868]
             text-sm  font-normal   bg-[#F2F6FF] border-x left-0"
              >
                <th
                  className="max-w-44 text-left
                 max-h-[3.125rem]  left-0 sticky top-0  h-full p-4 bg-[#F2F6FF] z-10"
                >
                  {t("name")}
                </th>
                <th
                  className="max-w-[2.5rem] max-h-[3.125rem]  h-full p-2
                text-left  bg-[#F2F6FF] top-0 sticky"
                >
                  {t("email")}
                </th>
                <th
                  className="max-w-44 min-w-28 max-h-[3.125rem] h-full p-2
                text-left  bg-[#F2F6FF] top-0 sticky"
                >
                  {t("appliedFor")}
                </th>
                <th
                  className="max-w-44 min-w-28 max-h-[3.125rem] h-full p-2
                text-left  bg-[#F2F6FF] top-0 sticky"
                >
                  {t("appliedDate")}
                </th>
                <th
                  className="max-h-[3.125rem] h-full p-2
                text-left  bg-[#F2F6FF] top-0 sticky"
                >
                  {t("contact")}
                </th>
                <th
                  className="h-full p-2
                text-left top-0 right-0 sticky  bg-[#F2F6FF]"
                >
                  {t("resume")}
                </th>
              </tr>
            </thead>
            {!isLoading && (
              <tbody className="p-2 text-sm text-left font-normal   flex-0">
                {selector?.map((data, index) => renderRow(data, index))}
              </tbody>
            )}
          </table>
          {isLoading && (
            <div className="items-center mt-[20rem]">
              <LoaderComp />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardApplicationTablePopup;
