import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AiOutlineRight } from "react-icons/ai";
import { convertByFormat } from "utils/date";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoSmiley } from "react-icons/go";
import { MdOutlineAttachFile } from "react-icons/md";
import { MdSend } from "react-icons/md";
import { isShowDialogBoxChange } from "redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFeedbacks,
  fetchIndividualInterviewDataa,
  fetchMyFeedbacks,
  setAddFeedback
} from "redux/appThunk/Employee/interview";
import { awsURL } from "utils/Constants";

function Notes({ id, setRenderSchedulePage, setRenderFeedbackPage }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [feedback, setFeedback] = useState("");
  const { individualInterviewData, allFeedbackData, myFeedbackData } = useSelector((state) => state.interviewReducer);
  const [activeFeedbackTab, setActiveFeedbackTab] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const user_id = userData.id;
  const [combinedFeedback,setCombinedFeedback] = useState();

  useEffect(() => {
    if (location?.state) {
      if (location?.state.activeTab === 2) {
        setActiveTab(location?.state?.activeTab);
      }
      if (location?.state.activeTab === 1) {
        setActiveTab(location?.state?.activeTab);
      }
    }
  }, [location]);

  const handleClose = () => {
    setRenderFeedbackPage(false);
    setRenderSchedulePage(false);
    dispatch(isShowDialogBoxChange(false));
  };

  function handleFeedbackTab(type) {
    if(type == "all"){
      setActiveFeedbackTab(0);
      setCombinedFeedback(allFeedbackData);

    }
    else{
      setActiveFeedbackTab(1);
      setCombinedFeedback(myFeedbackData);
    }

  }
  useEffect(() => {
    setCombinedFeedback(allFeedbackData);
  },[activeTab,allFeedbackData]);

  useEffect(() => {
    dispatch(fetchIndividualInterviewDataa(id));

  }, []);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleFeedback = () => {
    if (feedback !== "") {
      dispatch(setAddFeedback(feedback, individualInterviewData?.id, handleClose));
    }
  };

  useEffect(() => {
    if (individualInterviewData) {
      dispatch(fetchAllFeedbacks(individualInterviewData?.id));
      dispatch(fetchMyFeedbacks(id, user_id));
    }
  }, [individualInterviewData]);

  return (
    <div
      className=" bg-[#fff] shadow-md h-screen top-[-1rem] w-[45%] mt-3 absolute right-0 z-20 space-y-4 overflow-auto
     custom_scroll"
    >
      <div className="w-full h-12 bg-white flex justify-between items-center p-4 pt-6">
        <div>
          <button
            onClick={function () {
              handleTabClick(1);
            }}
          >
            <Link rel="stylesheet" href="#">
              <h5
                className={`text-xl ${activeTab === 1
                  ? "text-[#002169] font-bold text-[16px]"
                  : "text-[#686868] font-normal text-[1rem]"
                }`}
              >
                {t("notes")}
              </h5>
            </Link>
          </button>
          <button
            onClick={function () {
              handleTabClick(2);
            }}
          >
            <Link rel="stylesheet" href="#">
              <h5
                className={`text-xl ${activeTab === 2
                  ? "text-[#002169] font-bold text-[16px] pl-9"
                  : "text-[#686868] font-normal text-[1rem] pl-9"
                }`}
              >
                {t("feedBack")}
              </h5>
            </Link>
          </button>
        </div>
        <div className="">
          <GrFormClose
            className=" w-[2rem] h-[2rem] text-[#686868] cursor-pointer"
            onClick={handleClose}
          />
        </div>
      </div>
      <hr />
      {activeTab === 1 &&
        (<div className="flex flex-col space-y-3 pl-4 pr-4 items-center ">
          <div className="w-full flex flex-col space-y-2 ">
            <label htmlFor="" className="text-[14px] text-[#686868]">
              {t("project_name")}
            </label>
            <input
              type="text"
              name="projectName"
              id="projectName"
              value={individualInterviewData?.projectName}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3"
            ></input>
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("client_name")}
            </label>
            <input
              type="text"
              name="clientName"
              id="clientName"
              value={individualInterviewData?.clientName}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3"
            ></input>
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("package")}
            </label>
            <input
              className="h-[2.625rem] rounded-[5px] p-2"
              name="package"
              id="package"
              value={individualInterviewData?.package}
            />
            <label htmlFor="website" className="text-[14px] text-[#686868]">
              {t("website_any")}
            </label>
            <input
              type="text"
              name="website"
              id="website"
              value={individualInterviewData?.website}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3"
            />
            <label htmlFor="projectProviderName" className="text-[16px] text-[#686868]">
              {t("came_from")}
            </label>
            <input
              type="text"
              name="projectProviderName"
              id="projectProviderName"
              value={individualInterviewData?.projectProviderName}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3"
            />
            <label htmlFor="title" className="text-[16px] text-[#686868]">
              {t("title")}
            </label>
            <input
              className="h-[2.625rem] rounded-[5px] p-2 text-[#191919]"
              name="title"
              id="title"
              value={individualInterviewData?.title}
            />
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("desc")}
            </label>
            <textarea
              className="h-[7rem] rounded-[5px] p-2 resize-none text-[#191919]"
              name="description"
              id="description"
              value={individualInterviewData?.description}
            />
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("assignee")}
            </label>
            <input
              className="h-[2.625rem] rounded-[5px] p-2"
              name="assigneeName"
              id="assigneeName"
              value={individualInterviewData?.assigneeName}
            />
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("interview_details")}
            </label>
            <input
              type="text"
              name="interviewRounds"
              id="interviewRounds"
              value={individualInterviewData?.interviewRounds}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3"
            />
            <input
              type="text"
              name="fromTime"
              id="fromTime"
              value={`${convertByFormat(individualInterviewData?.fromTime, "MMMM DD")}; ` +
                `${convertByFormat(individualInterviewData?.fromTime, 'HH:mm ')}-`
                + convertByFormat(individualInterviewData?.toTime, 'HH:mm A')}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3
            text-[#686868]"
            />
            <input
              type="text"
              name="meetingLink"
              id="meetingLink"
              value={individualInterviewData?.meetingLink}
              className="h-[2.625rem] rounded-[5px] flex items-center justify-between p-3
            text-[#012FF2]"
            />
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("uploaded_documents")}
            </label>
            <input
              type="text"
              name="doc"
              id="doc"
              value={individualInterviewData?.doc}
              className="h-[3rem] rounded-[5px] flex items-center justify-between p-3"
            />
            <label htmlFor="" className="text-[16px] text-[#686868]">
              {t("result")}
            </label>
            <input
              className="h-[2.625rem] w-full p-2 bg-white rounded text-[#191919]"
              type="text"
              id="result"
              name="result"
              value={individualInterviewData?.result}
            />
            <div className="flex justify-between"
              onClick={() => {
                navigate("/interview", { state: { activeTab: 2 } });
              }}>
              <label htmlFor="" className="text-[16px] text-[#031B59] pt-4 pb-2">
                {t("feedback")}
              </label>
              <AiOutlineRight className="mt-5 text-[#A1A1A1]" />
            </div>
          </div>
        </div>)
      }
      {activeTab === 2 &&
        (<div
          className="p-6 flex flex-col gap-[20px]"
        >
          {/* Text Box Part */}
          <div className="w-full flex flex-col space-y-[10px]">
            <p className="text-sm text-[#686868]">{t("writeFeedback")}</p>
            <textarea className="w-full p-6 bg-[#F2F6FF] resize-none"
              onChange={(e) => setFeedback(e.target.value)} />
            <div className="w-full flex space-x-[19px] justify-end items-center">
              <GoSmiley className="fill-[#A1A1A1] h-6 w-6" />
              <div className="w-7 h-7 rounded-full bg-[#F2F6FF] flex items-center justify-center">
                <MdOutlineAttachFile className="-rotate-45 h-[18px] w-[18px1 fill-[#A1A1A1]" />
              </div>
              <button
                className={`${feedback !== "" ? "bg-[#031B59]" : ""}  w-[42px] h-8 rounded-md flex items-center
                  justify-center`}
                onClick={handleFeedback}
              >
                <MdSend className={`w-5 h-5 ${feedback !== "" ? "fill-[#fff]" : "fill-[#A1A1A1]"} `} />
              </button>
            </div>
          </div>
          <hr />
          {/* Tab part (All/ My Feedback) */}
          <div className="w-full flex space-x-8">
            <p className={`font-medium cursor-pointer  ${activeFeedbackTab == 0 ? "text-[#031B59]" : "text-[#A1A1A1]"}`}
              onClick={() => handleFeedbackTab("all")}>{t("all")}</p>
            <p className={`font-medium cursor-pointer ${activeFeedbackTab == 1 ? "text-[#031B59]" : "text-[#A1A1A1]"}`}
              onClick={() => handleFeedbackTab("myfeedback")}>{t("myFeedbacks")}</p>
          </div>
          {/* Feedback Card*/}
          {combinedFeedback?.length > 0 &&
            combinedFeedback?.map((obj, index) => (
              <div key={index}
                className="w-full h-fit min-h-[8.313rem] p-4 flex flex-col gap-[15px] border border-[#E2E8F0]
                  rounded bg-white"
              >
                <div className="w-full flex items-center justify-between">
                  <div className="w-full flex items-center justify-start space-x-[10px]">
                    <div className="w-[26px] h-[26px] rounded-full">
                      <img
                        className="w-full h-full object-cover"
                        src={`${awsURL}/images/user_active.jpg`}
                        alt="User Profile"
                      />
                    </div>
                    <p className="font-medium text-[#031B59]">{obj.name}</p>
                  </div>
                  <div className="w-full flex items-center justify-end">
                    <p className="text-[#686868]">{convertByFormat(obj.time, "DD MMM YYYY, HH:mm A")}</p>
                  </div>
                </div>
                <div className="w-full text-[#A1A1A1]">
                  <p>
                    {obj?.comment}
                  </p>
                </div>
              </div>
            ))
          }
        </div>)}
    </div>
  );
}

export default Notes;

Notes.propTypes = {
  id: PropTypes.string,
  setRenderFeedbackPage: PropTypes.func,
  setRenderSchedulePage: PropTypes.func,
};
