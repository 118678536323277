import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "component/common/Tabs.jsx";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission.jsx";
import MyTimesheet from "./MyTimesheet";
import UsersTimesheet from "./Listing";

let timesheetTabs = {
  mytime: "My Timesheet",
  team: "Team Timesheet",
};

const TimeSheetList = () => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(timesheetTabs.mytime);
  const { userPermission } = usePermissions(mappedPermissionObj.Status);

  return (
    <div className="w-full flex flex-col gap-[1rem]">
      <div className="w-[100%] px-[1.38rem] flex items-center justify-start">
        {!isAdmin && userPermission?.viewAll && (
          <Tabs
            tabsData={[
              { name: timesheetTabs.mytime, label: "my timesheet" },
              { name: timesheetTabs.team, label: t("teamTimesheet") },
            ]}
            activeTab={activeTab}
            onTabChange={(tabname) => setActiveTab(tabname)}
          />
        )}
      </div>
      <div className="w-full flex justify-center">
        {!isAdmin && activeTab === timesheetTabs.mytime ? (
          <MyTimesheet />
        ) : (
          <UsersTimesheet
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};

export default TimeSheetList;
