import React from "react";

const SvgRight = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path d="M8.1239 11.4421C7.73338 11.8326 7.10021 11.8326 6.70969
    11.4421L4.7918 9.52422C4.50185 9.23427 4.03175 9.23427 3.7418 9.52422C3.45185
    9.81417 3.45185 10.2843 3.7418 10.5742L6.70969 13.5421C7.10021 13.9326 7.73338
    13.9326 8.1239 13.5421L15.8918 5.77422C16.1817 5.48427 16.1817 5.01417 15.8918
     4.72422C15.6018 4.43427 15.1317 4.43427 14.8418 4.72422L8.1239 11.4421Z" fill="#1A8718"/>
    </svg>
  );
};

export default SvgRight;
