import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { setProfileDocumentData } from "redux/actions/action";

export const fetchProfileAllDocuments = (id, setLoader, userId) => async (dispatch) => {
  const tempId = id ? id : userId;
  const path = `background_verification/${tempId}`;
  try {
    const { response } = await Helper.get(path);
    dispatch(setProfileDocumentData(response?.background_verification));
    setLoader(false);
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
  }
};

export const deleteDocument = (obj, id,setLoader, userId) =>
  async (dispatch) => {
    const tempId = id ? id : userId;
    try {
      const path = apiUrl.removeDocument + `/${tempId}`;
      const {status} = await Helper.put(obj, path);
      if(status === 200 || status === 201){
        dispatch(fetchProfileAllDocuments(tempId,setLoader));
        ToastServices.showToast({
          type: "success",
          message: " Status has been Update.",
          autoClose: 1500,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error !",
        autoClose: 3000,
      });
    }
  };

export const consentResponse = (obj, id,setLoader, userId) =>
  async (dispatch) => {
    const tempId = id ? id : userId;
    try {
      const path = `${apiUrl.haveConsent}${tempId}`;
      const {status} = await Helper.put(obj, path);
      if(status === 200 || status === 201){
        dispatch(fetchProfileAllDocuments(tempId,setLoader));
        ToastServices.showToast({
          type: "success",
          message: " Status has been Update.",
          autoClose: 1500,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error !",
        autoClose: 3000,
      });
    }
  };

export const consentHoldResponse = (obj, id,setLoader, userId) =>
  async (dispatch) => {
    const tempId = id ? id : userId;
    try {
      const path = `${apiUrl.holdConsent}${tempId}`;
      const {status} = await Helper.put(obj, path);
      if(status === 200 || status === 201){
        dispatch(fetchProfileAllDocuments(tempId,setLoader));
        ToastServices.showToast({
          type: "success",
          message: " Status has been Update.",
          autoClose: 1500,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error !",
        autoClose: 3000,
      });
    }
  };

export const uploadIdentityDocuments = (obj, id , setLoader,setHandelButton) => async () => {
  setLoader(true);
  const path = `identity_check/${id}`;
  try {
    const {status } = await Helper.post(obj, path, true);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "data successfully uploaded",
        type: "success",
        autoClose: 1000,
      }
      );
      setLoader(false);
      setHandelButton(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
    setLoader(false);

  }
};

export const uplaodEducationDocument = (id , files , setLoading , setHandelButton) => async () => {

  try {
    setLoading(true);
    const path = `education_check/${id}`;
    const {status} = await Helper.post( files , path , true );
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Updated Successfull !",
        type: "success",
        autoClose: 1000,
      });
      setLoading(false);
      setHandelButton(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
    setLoading(false);
  }

};

export const uploadAddressDocument = (id , file ,setLoader, setHandleButton) => async () => {
  setLoader(true);
  try {
    const path = `address_check/${id}`;
    const {status} = await Helper.post(file , path , true);

    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Updated Successfull !",
        type: "success",
        autoClose: 1000,
      });
      setLoader(false);
      setHandleButton(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
    setLoader(false);
  }
};

export const uploadHistoryDocument = (id , files , setLoading, setHandelButton, userId) => async () => {
  const tempId = id ? id : userId;

  try {
    setLoading(true);
    const path = `employement_history_check/${tempId}`;
    const {status} = await Helper.post( files , path , true );
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Updated Successfull !",
        type: "success",
        autoClose: 1000,
      });
      setLoading(false);
      setHandelButton(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Something went wrong !",
      type: "warning",
      autoClose: 3000,
    });
    setLoading(false);
  }

};

