/* eslint-disable no-unreachable */
import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { resetPasswordSchema } from "redux/validator/login";
import { IoChevronBackOutline } from "react-icons/io5";
import { BiSolidCheckCircle } from "react-icons/bi";
import { conditionalFunctions } from "utils/CommonFunctions";
import { awsURL } from "utils/Constants";
import { PasswordConditions } from "utils/constant/Content";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ToastServices from "ToastServices";
import LoadSpinner from "component/common/loadingSpinner";
import { PasswordField } from "component/common/InputField";

const initialValue = {
  newPassword: "",
  confirmPassword: "",
};

const FirstChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();

  const [params] = useSearchParams();
  const emailParam = params?.get("email");

  const Formik = useFormik({
    initialValues: initialValue,
    validationSchema: resetPasswordSchema,
    onSubmit: async (value, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const jsonObj = {
          token: token,
          new_password: value.newPassword,
          confirm_password: value.confirmPassword,
        };
        const path = apiUrl?.firstChangePassword;
        const status = await Helper.post(jsonObj, path);
        if (status === 201 || status === 200) {
          ToastServices.showToast({
            message: "Successfully !",
            type: "success",
            autoClose: 3000,
          });
        }
        navigate("/login");
      } catch (error) {
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values } = Formik;

  return (
    <>
      <div className="h-screen lg:w-full w-full items-center px-2 flex xl:gap-8 justify-center font-Roboto">
        <div
          className="lg:basis-2/6 w-full h-full px-4 py-24 space-y-12 flex flex-col md:items-start
          items-center lg:justify-start justify-center"
        >
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="h-[3.063rem] w-[3.063rem] flex items-center group hover:bg-[#031B59]
              hover:scale-110 justify-center border-[1.5px] border-[#E2E8F0] rounded-full bg-white"
          >
            <IoChevronBackOutline className="h-6 w-6 group-hover:text-white" />
          </button>
          <FormikProvider value={values}>
            <Form
              id="change-password"
              className="w-full flex flex-col space-y-8"
              onSubmit={Formik.handleSubmit}
            >
              <div className="w-full flex flex-col space-y-8">
                <div className="flex flex-col space-y-2">
                  <h2 className="text-3xl font-semibold text-[#23275E] tracking-wide">
                    {t("newPassword")}
                  </h2>
                  <div className="flex gap-1">
                    <h4>{t("newPasswordMessage")}</h4>
                    <h4>{emailParam}</h4>
                  </div>
                </div>
                <div className="w-full h-fit flex flex-col space-y-3">
                  <PasswordField
                    label={t("newPassword")}
                    name="newPassword"
                    value={Formik.values.newPassword}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                  />
                  {Formik.errors.newPassword && Formik.touched.newPassword ? (
                    <p className="text-[red]">{Formik.errors.newPassword}</p>
                  ) : null}
                  <div className="h-fit w-full relative flex flex-col space-y-2">
                    <PasswordField
                      label={t("confirmPassword")}
                      id="confirmPassword"
                      name="confirmPassword"
                      value={Formik.values.confirmPassword}
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      disabled={values.newPassword.length <= 0}
                    />
                  </div>
                  {Formik.errors.confirmPassword &&
                  Formik.touched.confirmPassword ? (
                      <p className="text-[red]">
                        {Formik.errors.confirmPassword}
                      </p>
                    ) : null}
                </div>
                <div className="w-full flex flex-col space-y-2 text-[#191919]">
                  <h4>{t("yourPasswordContain")}</h4>
                  <ul className="w-full flex flex-col justify-start">
                    {PasswordConditions.conditions.map((condition, _id) => {
                      return (
                        <li key={_id} className="flex gap-2 w-full">
                          {" "}
                          <BiSolidCheckCircle
                            className={`h-5 w-5 fill-[${
                              conditionalFunctions(_id, values.newPassword)
                                ? "#2db611"
                                : "#64748B"
                            }]`}
                          />
                          {condition}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <button
                  className="h-12 rounded-full disabled:bg-[#9aa4bd] w-full text-white
                disabled:text-[#4d4d4d] text-lg bg-[#23275E]"
                  type="submit"
                  onClick={Formik.handleSubmit}
                  disabled={Formik.isSubmitting}
                >
                  {Formik.isSubmitting ? (
                    <>
                      loading...
                      <LoadSpinner />
                    </>
                  ) : (
                    t("setNewPassword")
                  )}
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
        <div className="w-fit py-24 hidden lg:flex items-center">
          <img src={`${awsURL}/images/forget.png`} alt="side_image" />
        </div>
      </div>
    </>
  );
};

export default FirstChangePassword;
