import { CREATE_DEPARTMENT, SET_DEPARTMENT, CREATE_DESIGNATION,
  SHOW_DESIGNATION, PAGE_COUNT, UPDATE_DEPARTMENT,SET_DEPARTMENT_DATA } from "redux/actions/types";

const initialstate = {
  data: [],
  designationData: [],
  updateDepartment: [],
  setDepartment: [],
  pageCount: 0,
  isLoading: true,
};

export const departmentReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case SET_DEPARTMENT:
    return { ...state, data: payload };
  case CREATE_DEPARTMENT:
    return { ...state, data: payload };
  case CREATE_DESIGNATION:
    return { ...state, data: payload };
  case SHOW_DESIGNATION:
    return { ...state, designationData: payload };
  case PAGE_COUNT:
    return { ...state, pageCount: payload };
  case UPDATE_DEPARTMENT:
    return { ...state, updateDepartment: payload };
  case SET_DEPARTMENT_DATA:
    return { ...state, setDepartment: payload };
  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };
  default:
    return state;
  }
};
