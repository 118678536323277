/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";

import DashboardAttendant from "./DashboardAttendant";
import DashboardOnGoingProject from "./DashboardOnGoingProject";
import DashboardPunchIn from "./DashboardPunchIn";
import DashboardDirectActions from "./DashboardDirectActions";
import DashboardWorkingDays from "./DashboardWorkingDays";
import DashboardLeaveBalance from "./DashboardLeaveBalance";
import DashboardSalarySlip from "./DashboardSalarySlip";
import DashboardTable from "./DashboardTable";
import AdminGraphContainer from "./AdminGraphContainer";
import DashboardAnniversary from "./DashboardAnniversary";
import DashboardBirthday from "./DashboardBirthday";
import DashboardLatestFeed from "./DashboardLatestFeed";
import DashboardEmployeeLoginGraph from "./DashboardEmployeeLoginGraph";
import DashboardProbation from "./DashboardProbation";
import DashboardCalender from "./DashboardCalender";
import DashboardApplication from "./DashboardApplication";
import DashboardWorkingHoursGraph from "./DashboardWorkingHoursGraph";
import DashboardCurrentOpenings from "./DashboardCurrentOpenings";
import usePermissions from "hooks/usePermission";
import HiringInfo from "./HiringInfo";
import DashboardPerfomanceGraph from "./DashboardPerformaceGraph";
import DashboardReview from "./DashboardReview";
import AddEmployee from "component/admin/Employee/AddEmployee";
import AddLeave from "component/leavePanel/AddLeaveData";

export default function DashboardAdmin() {
  const { userPermission, isAdmin } = usePermissions();
  const [addemployeePop, setAddEmployeePop] = useState(false);
  const [addLeavePop, setAddLeavePop] = useState(false);

  return (
    <div className="flex flex-wrap justify-center w-[96%] bg-none h-fit mt-[12px] gap-5">
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        <DashboardPunchIn />
        <DashboardAttendant />
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        <DashboardOnGoingProject />
        <DashboardDirectActions setAddEmployeePop={setAddEmployeePop} setAddLeavePop={setAddLeavePop}/>
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        {isAdmin && <DashboardWorkingDays />}
        {isAdmin && <DashboardLeaveBalance />}
        {isAdmin && <DashboardSalarySlip />}
      </div>
      {userPermission?.leave?.viewAll && (
        <div className="w-full ">
          <DashboardTable />
        </div>
      )}
      {userPermission?.jobOpening?.viewAll && (
        <div className="w-full ">
          <DashboardApplication />
        </div>
      )}
      {(isAdmin || userPermission?.management?.viewAll) && (
        <div className="w-full">
          <AdminGraphContainer />
        </div>
      )}
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        {isAdmin && <DashboardWorkingHoursGraph />}
        {(isAdmin || userPermission?.jobOpening?.viewAll) && (
          <DashboardCurrentOpenings />
        )}
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        <DashboardLatestFeed />
        <DashboardCalender />
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
        xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        {(isAdmin || userPermission?.jobOpening?.viewAll) && <HiringInfo />}
        {isAdmin && <DashboardEmployeeLoginGraph />}
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        {(isAdmin || userPermission?.jobOpening?.viewAll) && (
          <DashboardProbation />
        )}
        <DashboardAnniversary />
        <DashboardBirthday />
      </div>
      <div
        className="h-full w-full grid grid-cols-2 grid-flow-dense xl:grid
      xl:grid-cols-3 gap-8 text-white rounded-[20px]"
      >
        {isAdmin && (
          <>
            <DashboardPerfomanceGraph />
            <DashboardReview />
          </>
        )}
      </div>
      {addemployeePop && <AddEmployee setAddEmployeePop={setAddEmployeePop} />}
      {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
    </div>
  );
}
