import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { otherdailogSchema } from "redux/validator/admin/setting";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { AddAdditionalField } from "redux/appThunk/Admin/salary";
import Popup from "component/common/Popup";

function DeductionDialog({ handleDialogBox, previousData, id }) {
  let deductiondata = previousData?.deductions;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialvalues = {
    name: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: otherdailogSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        salary_type: {
          deductions: [...deductiondata, { name: values.name }],
        },
      };
      dispatch(AddAdditionalField(id, jsonObj));
      handleDialogBox();
    },
  });

  const { errors, touched, handleBlur, handleChange, handleSubmit } = Formik;

  return (
    <Popup
      handleSubmit={handleSubmit}
      popupBox={handleDialogBox}
      title={t("deductions")}
    >
      <div className="flex flex-col p-3">
        <label htmlFor="leaveName" className="text-[#313135] font-medium">
          {t("name")}
        </label>
        <input
          role="input"
          type="text"
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          className="h-[2.625rem] w-[33rem] p-2 border border-[#E2E8F0] rounded text-[#191919]
outline-none bg-[#fff]"
        />
        {errors.name && touched.name ? (
          <p className="text-[red]">{errors.name}</p>
        ) : null}
      </div>
    </Popup>
  );
}

DeductionDialog.propTypes = {
  handleDialogBox: PropTypes.any,
  previousData: PropTypes.any,
  id: PropTypes.any,
};

export default DeductionDialog;
