import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { applyLeaveSchema } from "redux/validator/employee/leaves";
import { useSelector } from "react-redux/es/hooks/useSelector";
import ApplyEdit from "./ApplyEdit";
import {
  isAdditionDialogBoxToggle,
  isShowDialogBoxChange
} from "redux/actions/action";

const ApplyLeave = () => {
  const [submitOnce, setSubmitOnce] = useState(false);
  const { id } = useParams();
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const isAdditionDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowAdditionDialogBox
  );
  const dispatch = useDispatch();

  const initialvalues = {
    leaveType: "",
    fromDate: "",
    toDate: "",
    reason: "",
    consumedLeave: "",
    toTime: "",
    fromTime: "",
    status: "",
  };

  const AddEmployessDialoBox = () => {
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    dispatch(isAdditionDialogBoxToggle(!isAdditionDialogBox));
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: applyLeaveSchema,
    onSubmit: async () => {
      setSubmitOnce(true);
      AddEmployessDialoBox();
      setSubmitOnce(false);
    },
  });
  const { values } = Formik;

  return (
    <ApplyEdit
      paramId={id}
      Formik={Formik}
      submitOnce={submitOnce}
      setSubmitOnce={setSubmitOnce}
      EditData={values}
    />
  );
};

export default ApplyLeave;
