export const typeObj = Object.freeze({
  view: "view",
  add: "add",
});

export const employeeTypeObj = Object.freeze({
  admin: "admin",
  employee: "employee",
  contract: "contract",
  approved: "approved",
  cancelled: "cancelled",
  consultancyFee: "Consultancy Fee",
  tds: "TDS",
});

export const ProjectKeys = {
  CLIENT_ID: "clientId",
  Start_Date: "startDate",
  Status: "status",
  Complete: "complete",
  Delay: "delay",
  notYetStarted: "Not Yet Start",
};

export const AttendanceKeys = {
  TEAM_ATTENDANCE: "teamAttendance",
  ORGANIZATION_ATTENDANCE: "organizationAttendance",
  MY_ATTENDANCE: "myAttendance",
};
