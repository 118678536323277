import React from "react";

export default function version() {

  return(
    <div className="flex flex-wrap justify-center w-[96%] bg-none h-fit ">
      <div
        className="h-full w-full flex flex-col flex-col-3 rounded-[20px]"
      >
        {/* On Version Card */}
        <div className="flex h-[50px] p-[12px_12px_12px_15px] items-center gap-[10px] flex-col-1
         border border-[031B59] bg-[#F2F6FF]">
          <div className="w-[153px] h-[22px] text-[#031B59] text-base font-bold
           leading-[140%]">Version Release(v.1)
          </div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">Api URL</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer"> <a className="hover">
            https://deeporionback-0cd6e44c3c14.**********.com/</a></div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">App Name</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer">Deeporion</div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">Version</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer">2.2.20</div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">DB Connection</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer">localhost:3000</div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">S3 bucketName</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer">
            awsURL
          </div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">Vite Mode</div>
          <div className="text-[#031B59] w-[794px] h-[45px] cursor-pointer">
            local
          </div>
        </div>
        <div className="flex h-[50px] p-[12px_12px_12px_15px] border border-[031B59]">
          <div className="text-[#686868] w-[328px] h-[45px]">Last Login</div>
          <div className="text-[#031B59] w-[794px] h-[45px]">27 Feb 2024 at 5:30</div>
        </div>
      </div>
    </div>
  );
}

