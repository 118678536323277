import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CgCalendarDates } from "react-icons/cg";
import { AiOutlineFieldTime } from "react-icons/ai";
import { RiFileListLine } from "react-icons/ri";
import { MdOutlineEditNote } from "react-icons/md";
import { isShowDialogBoxChange } from "redux/actions/action";

import Notes from "./Notes";
import { convertByFormat, convertDateFormat } from "utils/date";
import { fetchInterviewData } from "redux/appThunk/Employee/interview";
import LoaderComp from "component/loader/LoaderComp";
import { awsURL } from "utils/Constants";

export default function Interview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [renderSchedulePage, setRenderSchedulePage] = useState(false);
  const [renderFeedbackPage, setRenderFeedbackPage] = useState(false);
  const [id, setId] = useState("");
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const { interviewData, isLoading } = useSelector(
    (state) => state.interviewReducer
  );

  const handleSchedulePage = () => {
    setRenderSchedulePage(true);
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
  };

  const handleFeedbackPage = () => {
    setRenderFeedbackPage(true);
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
  };

  window.addEventListener("popstate", () => {
    dispatch(isShowDialogBoxChange(false));
  });

  useEffect(() => {
    dispatch(fetchInterviewData());
  }, [renderSchedulePage, renderFeedbackPage, isOpenDialogBoxToggle]);

  return (
    <div className="w-[96%] h-fit flex items-center justify-center">
      <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-1">
        {interviewData?.length > 0 ? (
          isLoading ? (
            <div>
              <LoaderComp />
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              {interviewData.map((data, index) => (
                <div
                  key={index}
                  className="w-full
                p-[18px] flex flex-col rounded-[20px] bg-white
                  border border-[#E2E8F0]"
                >
                  <div className="w-full flex flex-col gap-4">
                    {/* Date and Status */}
                    <div className="w-full flex items-center justify-between">
                      <div className="flex justify-start items-center space-x-4">
                        <CgCalendarDates className="h-5 w-5 text-[#FF7914]" />
                        <p>{convertDateFormat(data.date)}</p>
                        <spam>
                          {" "}
                          <p>{convertByFormat(data.date, "HH:mm A")}</p>
                        </spam>
                      </div>
                      <div
                        className="h-[1.875rem] w-[5.813rem] flex items-center justify-center
                        bg-[rgba(254,135,46,0.1)] rounded-[20px]"
                      >
                        <p className="text-[#FE872E]">{data.interviewStatus}</p>
                      </div>
                    </div>
                    {/* Project name and Round */}
                    <div className="w-full flex items-center justify-between">
                      <div className="flex flex-col justify-start items-start">
                        <p className="font-semibold">{data.projectName}</p>
                        <p className="text-[#A1A1A1]">{data.description}</p>
                      </div>
                      <div className="h-[1.875rem] w-[5.813rem] flex items-start justify-center space-x-2">
                        <AiOutlineFieldTime className="mt-1 h-4 w-4 text-[#FE872E]" />
                        <p>{data.interviewRounds}</p>
                      </div>
                    </div>
                    {/* Client Name and Review */}
                    <div className="w-full flex items-center justify-between">
                      <div className="flex justify-start items-start space-x-2">
                        <img
                          className="w-[1.875rem] h-[1.875rem] rounded-full"
                          src={`${awsURL}/images/Client.jpg`}
                          alt="client"
                        />
                        <p className="text-[#191919]">{data.clientName}</p>
                      </div>
                      <div className="h-[1.875rem] w-[5.813rem] flex items-start justify-end space-x-2">
                        <button
                          className="w-[1.875rem] h-[1.875rem] flex items-center justify-center
                 rounded-full bg-[#031B59]"
                          onClick={() => {
                            handleSchedulePage();
                            setId(data?.id);
                            navigate("/interview", { state: { activeTab: 1 } });
                          }}
                        >
                          <RiFileListLine className="text-white" />
                        </button>
                        <button
                          className="w-[1.875rem] h-[1.875rem] flex items-center justify-center
                 rounded-full border border-[#A1A1A1]"
                          onClick={() => {
                            handleFeedbackPage();
                            setId(data?.id);
                            navigate("/interview", { state: { activeTab: 2 } });
                          }}
                        >
                          <MdOutlineEditNote className="text-[#A1A1A1]" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          <h1
            className="text-center
           mt-[40px] text-[#031B59] text-lg"
          >
            {t("no_data_found")}
          </h1>
        )}
        {(renderSchedulePage || renderFeedbackPage) &&
          isOpenDialogBoxToggle && (
          <Notes
            setRenderSchedulePage={setRenderSchedulePage}
            setRenderFeedbackPage={setRenderFeedbackPage}
            id={id}
          />
        )}
      </div>
    </div>
  );
}
