import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { MdAdd, MdOutlineMoreVert } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  isShowDialogBoxChange,
  isAdditionDialogBoxToggle,
  isDeductionDialogBoxToggle,
  isOtherDialogBoxToggle,
  isDeleteDialogBoxToggle,
  setAllLeaveType,
  setFetchSuccess
} from "redux/actions/action";
import { GrFormClose } from "react-icons/gr";
import LoaderComp from "../../loader/LoaderComp";
import { useFormik } from "formik";
import { settingsSchema } from "redux/validator/admin/setting";
import { useTranslation } from "react-i18next";
import { BiEditAlt } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { creditTimePeriod } from "utils/Constants";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import AdditionalDialog from "./AdditionalDialog";
import { fetchSalaryAttributes } from "redux/appThunk/Admin/salary";
import DeductionDialog from "./DeductiionDialog";
import OthersDialog from "./OthersDialog";
import ToastServices from "ToastServices";

export default function Manage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openOptions, setOpenOptions] = useState(false);
  const [leaveTypeId, setLeaveTypeId] = useState("");
  const [leaveType, setLeaveType] = useState({});
  const [onSpotChange, setOnSpotChange] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { salaryStructure } = useSelector((state) => state.SalaryInfoReducer);
  const { fetchSuccess } = useSelector((state) => state.SalaryInfoReducer);
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const isAdditionDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowAdditionDialogBox
  );
  const isDeductionDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowDeductionDialogBox
  );
  const isOtherDialogBox = useSelector(
    (state) => state.popUpDialogBox.isSHowOtherDialogBox
  );
  const isShowDisableDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowDisableDialogBox
  );

  const allLeaveType = useSelector((state) => state.leaveReducer.allLeaveType);

  const fetchAllLeaveType = async () => {
    const path = apiUrl?.leaveType;
    const { response, status } = await Helper.get(path);
    if (status === 200) {
      dispatch(setAllLeaveType(response));
    }
  };

  useEffect(() => {
    if (fetchSuccess) {
      fetchAllLeaveType();
      dispatch(fetchSalaryAttributes(setIsLoading));
      dispatch(setFetchSuccess(false));
    }
  }, [onSpotChange, fetchSuccess]);

  const handleSuccess = () => {
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    setOnSpotChange(!onSpotChange);
    setLeaveType({});
  };

  const initialvalues = {
    leaveTypeName: "",
    numOfLeaves: "",
    creditTimePeriod: "",
    isActive: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: settingsSchema,
    onSubmit: async (values) => {
      let jsonObj = {};
      if (leaveTypeId === "") {
        jsonObj = {
          leave_type: {
            leave_name: values?.leaveTypeName,
            no_of_leaves: Number(values?.numOfLeaves),
            credit_time_period: values?.creditTimePeriod,
          },
        };
        const path = apiUrl?.leaveType;
        try {
          const { status } = await Helper.post(jsonObj, path);
          if (status === 200) {
            ToastServices.showToast({
              message: "Successfully !",
              type: "success",
              autoClose: 3000,
            });
            handleSuccess();
          }
        } catch (error) {
          ToastServices.showToast({
            message: "Error !",
            type: "error",
            autoClose: 3000,
          });
        }
      } else if (leaveTypeId !== "") {
        jsonObj = {
          leave_type: {
            leave_name: values?.leaveTypeName,
            no_of_leaves: Number(values?.numOfLeaves),
            credit_time_period: values?.creditTimePeriod,
            is_active: values?.isActive,
          },
        };
        const path = `${apiUrl?.leaveType}/${leaveTypeId}`;
        try {
          const { status } = await Helper.put(jsonObj, path);
          if (status === 200) {
            ToastServices.showToast({
              message: "Successfully !",
              type: "success",
              autoClose: 3000,
            });
            handleSuccess();
          }
        } catch (error) {
          ToastServices.showToast({
            message: "Error !",
            type: "error",
            autoClose: 3000,
          });
        }
      }
    },
  });

  const fetchLeaveType = async () => {
    const path = `${apiUrl.leaveType}/${leaveTypeId}`;
    const { response, status } = await Helper.get(path);
    if (status === 200) {
      setLeaveType(response);
    }
  };
  const handleEditLeaveModal = () => {
    setIsActionOpen(false);
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
  };

  useEffect(() => {
    if (leaveTypeId !== "") {
      fetchLeaveType();
    }
  }, [leaveTypeId]);

  const handleAddLeaveDialogBox = () => {
    if (!isActionOpen) {
      dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    }
  };
  const handleAdditionDialogBox = () => {
    dispatch(isAdditionDialogBoxToggle(!isAdditionDialogBox));
  };

  const handleDeductionDialogBox = () => {
    dispatch(isDeductionDialogBoxToggle(!isDeductionDialogBox));
  };

  const handleOtherDialogBox = () => {
    dispatch(isOtherDialogBoxToggle(!isOtherDialogBox));
  };
  const handleDisableDialogBox = () => {
    setIsActionOpen(false);
    dispatch(isDeleteDialogBoxToggle(!isShowDisableDialogBox));
  };
  const handleEnableLeaveType = async (id) => {
    const jsonObj = {
      leave_type: {
        is_active: "true",
      },
    };
    const path = `${apiUrl?.leaveType}/${id}`;
    try {
      const { status } = await Helper.put(jsonObj, path);
      if (status === 200) {
        setIsActionOpen(false);
        ToastServices.showToast({
          message: "Leave type has been Enabled !",
          autoClose: 3000,
        });
        setOnSpotChange(!onSpotChange);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Error !",
        type: "error",
        autoClose: 3000,
      });
    }
  };
  const handleDisableLeaveType = async () => {
    const path = `${apiUrl?.disableLeaveType}/${id}`;
    try {
      const { status } = await Helper.put({}, path);
      if (status === 200) {
        dispatch(isDeleteDialogBoxToggle(!isShowDisableDialogBox));
        ToastServices.showToast({
          message: "leave Type Has Been Disabled !",
          type: "success",
          autoClose: 3000,
        });
        setOnSpotChange(!onSpotChange);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Error !",
        type: "error",
        autoClose: 3000,
      });
    }
  };
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = Formik;

  useEffect(() => {
    if (Object.keys(leaveType).length > 0) {
      setFieldValue("leaveTypeName", leaveType?.leave_name);
      setFieldValue("numOfLeaves", leaveType?.no_of_leaves);
      setFieldValue("creditTimePeriod", leaveType?.credit_time_period);
      setFieldValue("isActive", leaveType?.is_active);
    }
  }, [leaveType]);

  useEffect(() => {
  }, [errors]);

  return (
    <div
      className="w-[96%] h-[53rem] p-5 bg-white flex-flex-col
      space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] rounded-[20px]"
    >
      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <Link rel="canonical" onClick={() => navigate(-1)}>
            <h3 className="font-normal text-xl text-[#191919] flex items-center mr-3">
              <FaArrowLeftLong />
            </h3>
          </Link>
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {t("manage")}
          </h2>
        </div>
      </div>
      {isOpenDialogBoxToggle && (
        <form autoComplete="off">
          <div
            className="w-full h-full flex items-center
          justify-center fixed top-0 left-0 z-50  bg-[rgba(3,27,89,.2)] op"
          >
            <div
              className="w-[37.5rem] h-auto absolute z-60 flex flex-col leading-normal space-y-4
            rounded-br-[2rem] items-center justify-center
          bg-white drop-shadow-lg"
            >
              <div className="w-[33rem] h-16 bg-white flex justify-between items-center ">
                <div className="font-bold text-[20px] text-[#031B59]">
                  {leaveTypeId === "" ? t("addNewLeaveType") : t("edit")}
                </div>
                <GrFormClose
                  className="w-[2rem] h-[2rem] text-[#686868]"
                  onClick={() => {
                    resetForm();
                    setLeaveTypeId("");
                    setLeaveType({});
                    handleAddLeaveDialogBox();
                  }}
                />
              </div>
              <hr className="w-full" />
              <div className="flex flex-col">
                <label
                  htmlFor="leaveName"
                  className="text-[#313135] font-medium"
                >
                  {t("leaveName")}
                </label>
                <input
                  type="text"
                  id="leaveName"
                  name="leaveTypeName"
                  value={values.leaveTypeName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="h-[2.625rem] w-[33rem] p-2 border border-[#E2E8F0] rounded text-[#191919]
                outline-none bg-[#fff]"
                />
                {errors.leaveTypeName && touched.leaveTypeName ? (
                  <p className="text-[red]">{errors.leaveTypeName}</p>
                ) : null}
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="numOfLeaves"
                  className="text-[#313135] font-medium"
                >
                  {t("numOfLeaves")}
                </label>
                <input
                  type="text"
                  name="numOfLeaves"
                  id="numOfLeaves"
                  value={values.numOfLeaves}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                  className="h-[2.625rem] w-[33rem] p-2 border border-[#E2E8F0]
                rounded text-[#191919] outline-none bg-[#fff]"
                />
                {errors.numOfLeaves && touched.numOfLeaves ? (
                  <p className="text-[red]">{errors.numOfLeaves}</p>
                ) : null}
              </div>
              <div className="flex flex-col w-[33rem]">
                <label
                  htmlFor="creditTimePrd"
                  className="text-[#313135] font-medium"
                >
                  {t("creditTimePrd")}
                </label>
                <div
                  className="flex border border-solid border-stroke-light rounded-md justify-between
                  w-full px-2 py-3  m-auto text-[#191919] bg-white mr-3 relative cursor-pointer justify-items-center"
                  onClick={() => setOpenOptions(!openOptions)}
                >
                  <h6 name="creditTimePeriod">{values?.creditTimePeriod}</h6>
                  <FaAngleDown className="mr-[6px] mt-1" />
                </div>
                <div
                  className="relative"
                  onMouseLeave={() => {
                    setOpenOptions(false);
                  }}
                >
                  {openOptions && (
                    <div
                      className="w-full flex flex-col
                border border-solid border-stroke-light rounded-md absolute z-10
            items-start gap-[2px] justify-center mt-[0.3rem] p-[4px] bg-white drop-shadow-lg"
                    >
                      {creditTimePeriod.map((option) => (
                        <div
                          className={`w-full px-5 py-2 m-0
                       hover:bg-[#F2F6FF] cursor-pointer`}
                          key={option.id}
                          onClick={() =>
                            setFieldValue("creditTimePeriod", option?.period)
                          }
                        >
                          {option.period}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {errors.creditTimePeriod && touched.creditTimePeriod ? (
                  <p className="text-[red]">{errors.creditTimePeriod}</p>
                ) : null}
              </div>
              <div
                className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem]
            rounded-br-[2rem] border-t-[1px] border-[#E2E8F0] bg-white"
              >
                <button>
                  <div
                    onClick={() => {
                      resetForm();
                      handleAddLeaveDialogBox();
                    }}
                    className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem]
                  rounded-[0.5rem] text-[#686868]"
                  >
                    {t("cancel")}
                  </div>
                </button>
                <button
                  className="flex h-[3rem] p-[1rem_1.875rem] w-[6rem] justify-center items-center gap-[0.5rem]
                  bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem] font-bold leading-normal text-white"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {leaveTypeId !== "" ? t("save") : t("add")}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {isShowDisableDialogBox && (
        <div
          className="w-[37.5rem] h-[13.75rem] z-20 flex flex-col rounded-br-[2rem] absolute
        items-center gap-[1.5rem] justify-center ml-[10rem] top-[15rem] bg-white drop-shadow-lg"
        >
          <button
            onClick={() => {
              handleDisableDialogBox();
            }}
          >
            <GrFormClose
              className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem]
                         right-[1rem]"
            />
          </button>
          <div
            className="w-[28.5rem] h-[3rem] color-[#191919] font-inter font-bold text-2xl
                      leading-8 tracking-[0.0075rem]"
          >
            {t("disableLeaveTypeMsg")}
          </div>
          <div
            className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem]
                      rounded-br-[2rem] border-t-[1px] border-[#E2E8F0] bg-white"
          >
            <button>
              <div
                className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center
                            gap-[0.5rem] rounded-[0.5rem]"
                onClick={() => {
                  handleDisableDialogBox();
                }}
              >
                {t("cancel")}
              </div>
            </button>
            <button>
              <div
                className="flex h-[3rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                          rounded-[2.5rem] bg-[#031B59] font-inter text-[1rem] font-bold leading-normal text-white"
                onClick={handleDisableLeaveType}
              >
                {t("disable")}
              </div>
            </button>
          </div>
        </div>
      )}
      <div
        className="w-full flex border border-[#F2F6FF] rounded-[20px] flex-col justify-center items-start
        p-[1.125rem_1.3125rem_1.5rem] space-y-4"
      >
        <div className="font-medium text-lg text-[#031B59]">
          {t("leaveType")}
        </div>
        <div className="w-full flex flex-wrap gap-2">
          {allLeaveType
            ?.sort((a, b) => a.id - b.id)
            ?.map((obj) => (
              <div
                key={obj?.id}
                className=" w-[11rem] flex flex-col border rounded-[10px] border-[#F2F6FF] h-[5rem] relative"
              >
                <div className="flex items-center justify-between w-full">
                  <div
                    className={` text-[18px] text-[#A1A1A1] font-normal mr-4 mt-2 ml-2
                 ${!obj?.is_active && "text-opacity-[0.5]"}`}
                  >
                    {obj?.no_of_leaves}
                  </div>
                  <MdOutlineMoreVert
                    className="cursor-pointer"
                    onClick={() => {
                      setId(obj?.id);
                      setIsActionOpen(!isActionOpen);
                    }}
                  />
                  {isActionOpen && id === obj?.id && (
                    <div
                      className="absolute bg-white w-[9rem] h-fit flex flex-col top-7 border py-2 border-[#E2E8F0]
                   rounded-md items-center justify-center right-0 z-10"
                      onMouseLeave={() => {
                        setIsActionOpen(false);
                        setLeaveType({});
                      }}
                    >
                      {obj?.is_active ? (
                        <>
                          <div
                            className="w-full px-5 py-2
                       hover:bg-[#F2F6FF] cursor-pointer"
                            onClick={() => {
                              setLeaveTypeId(obj?.id);
                              handleEditLeaveModal();
                            }}
                          >
                            {t("edit")}
                          </div>
                          <div
                            className="w-full px-5 py-2 m-0
                       hover:bg-[#F2F6FF] cursor-pointer"
                            onClick={() => {
                              handleDisableDialogBox();
                              setId(obj?.id);
                            }}
                          >
                            {t("disable")}
                          </div>
                        </>
                      ) : (
                        <div
                          className="w-full px-5 py-2
                       hover:bg-[#F2F6FF] cursor-pointer"
                          onClick={() => handleEnableLeaveType(obj?.id)}
                        >
                          {t("enable")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    obj?.is_active ? "text-[#031B59]" : "text-[#A1A1A1]"
                  }
               text-base font-medium mt-2 ml-2`}
                >
                  {obj?.leave_name}
                </div>
              </div>
            ))}
          <div
            className="w-[11rem] flex h-[5rem] items-center justify-center gap-2 cursor-pointer
              border shadow-[0px_0px_10px_-2px_rgba(3,27,89,0.10)] rounded-[10px] border-[#F2F6FF] text-[#031B59]"
            onClick={() => {
              setLeaveTypeId("");
              handleAddLeaveDialogBox();
            }}
          >
            <MdAdd />
            <div className="font-semibold">{t("addNew")}</div>
          </div>
        </div>
      </div>
      <div
        className="h-[25rem] w-full p-5 bg-white flex-flex-col border
      space-y-2 border-[#F2F6FF] rounded-[20px]"
      >
        <div className="font-medium text-lg text-[#031B59]">
          {t("salaryStructure")}
        </div>
        <div className="h-full w-full custom_scroll overflow-y-scroll p-[10px] ">
          <div
            className="flex w-full flex-col mt-[1em]
         border rounded-[20px] border-[#F2F6FF]"
          >
            <div
              className="h-[3rem] flex justify-between items-center
           w-full rounded-[10px] bg-[#F2F6FF] border-none"
            >
              <div className="font-medium text-lg text-[#686868] ml-2 mt-2  ">
                {t("Additions")}
              </div>

              {isAdditionDialogBox && (
                <AdditionalDialog
                  handleDialogBox={handleAdditionDialogBox}
                  previousData={salaryStructure}
                  id={salaryStructure?.id}
                />
              )}
              <div
                className="bg-[white] border rounded-[12px] h-[2rem] flex w-[2rem]
             items-center justify-center mr-3"
                onClick={() => handleAdditionDialogBox()}
              >
                <MdAdd />
              </div>
            </div>
            {isLoading && (
              <div className="items-center mt-3">
                <LoaderComp />
              </div>
            )}
            {!isLoading &&
              salaryStructure &&
              salaryStructure?.additions?.map((value, index) => (
                <div
                  key={index}
                  className="h-[3rem] flex justify-between w-full"
                >
                  <div className="font-medium text-lg text-[#031B59] mt-2 ml-2 ">
                    {value.name}
                  </div>
                  <div className="flex items-center  space-x-1 ml-2">
                    <BiEditAlt
                      fontSize="20px"
                      className="mr-[6px]"
                      onClick={() => handleAdditionDialogBox()}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div
            className="flex flex-col mt-[1.88rem]
        w-full border rounded-[20px] border-[#F2F6FF]"
          >
            <div
              className="h-[3rem] flex justify-between  items-center
          w-full rounded-[10px] mb-2 bg-[#F2F6FF] border-none"
            >
              <div className="font-medium text-lg text-[#686868] ml-2 mt-2 ">
                {t("deductions")}
              </div>
              {isDeductionDialogBox && (
                <DeductionDialog
                  handleDialogBox={handleDeductionDialogBox}
                  previousData={salaryStructure}
                  id={salaryStructure?.id}
                />
              )}
              <div
                className="bg-[white] border rounded-[12px] h-[2rem] flex w-[2rem]
              items-center justify-center mr-3"
                onClick={() => handleDeductionDialogBox()}
              >
                <MdAdd />
              </div>
            </div>
            {salaryStructure &&
              salaryStructure?.deductions?.map((value, index) => (
                <div
                  key={index}
                  className="h-[3rem] flex justify-between w-full"
                >
                  <div className="font-medium text-lg text-[#031B59] mt-2 ml-2 ">
                    {value.name}
                  </div>
                  <div className="flex items-center  space-x-1 ml-2">
                    <BiEditAlt fontSize="20px" className="mr-[6px]" />
                  </div>
                </div>
              ))}
          </div>
          <div
            className="flex flex-col mt-[1.88rem] w-full
        border rounded-[20px] border-[#F2F6FF]"
          >
            <div className="h-[3rem] flex items-center justify-between w-full rounded-[10px] bg-[#F2F6FF] border-none">
              <div className="font-medium text-lg text-[#686868] ml-4">
                {t("other")}
              </div>
              {isOtherDialogBox && (
                <OthersDialog
                  handleDialogBox={handleOtherDialogBox}
                  previousData={salaryStructure}
                  id={salaryStructure?.id}
                />
              )}
              <div
                className="bg-[white] border rounded-[12px] h-[2rem] flex w-[2rem]
             items-center justify-center mr-3"
                onClick={() => handleOtherDialogBox()}
              >
                <MdAdd />
              </div>
            </div>
            {salaryStructure &&
              salaryStructure?.others?.map((value, index) => (
                <div
                  key={index}
                  className="h-[3rem] flex justify-between w-full"
                >
                  <div className="font-medium text-lg text-[#031B59] mt-2 ml-2 ">
                    {value.name}
                  </div>
                  <div className="flex items-center  space-x-1 ml-2">
                    <BiEditAlt fontSize="20px" className="mr-[6px]" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
