import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import {
  isAdditionDialogBoxToggle,
  isShowDialogBoxChange,
  setAllLeaveType,
  setIndividualleave
} from "redux/actions/action";
import Svg18 from "svgComponents/Svg18";
import { convertDateFormat } from "utils/date";
import { fetchEmployeeLeaveData } from "redux/appThunk/Employee/leaves";
// import AddLeavePopup from "component/admin/Leave/AddLeavePopup";
import { FaArrowLeft } from "react-icons/fa";
import ToastServices from "ToastServices";
import AddLeavePopup from "component/leavePanel/AddLeavePopup";

const ApplyEdit = ({ paramId, Formik, setSubmitOnce, EditData,submitOnce }) => {
  const indiviualleave = useSelector((state) => state.leaveReducer.leaveData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = paramId ? t("editLeave") : t("applyLeave");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openToast, setOpenToast] = useState(false);
  const { allLeaveType, allLeaves } = useSelector(
    (state) => state.leaveReducer
  );
  const [dates, setDates] = useState([]);
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const isAdditionDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowAdditionDialogBox
  );
  const itemsPerPage = 10;
  const [currentPage] = useState(1);

  function calculateNumberOfDays(fromDate, toDate, fromTime, toTime) {
    const millisecondsInDay = 864e5;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const daysDifference =
      Math.floor((endDate - startDate) / millisecondsInDay) + 1;

    if (
      (fromTime === "First Half" && toTime === "First Half") ||
      (fromTime === "Second Half" && toTime === "Second Half")
    ) {
      return daysDifference - 1 + 0.5;
    } else if (fromTime === "Second Half" && toTime === "First Half") {
      return daysDifference - 1;
    }

    return daysDifference;
  }

  const fetchAllLeaveType = async () => {
    const path = apiUrl?.leaveType;
    const { response, status } = await Helper.get(path, navigate);
    if (status === 200) {
      dispatch(setAllLeaveType(response));
    }
  };

  useEffect(() => {
    fetchAllLeaveType();
    dispatch(fetchEmployeeLeaveData(currentPage, itemsPerPage));
  }, []);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    if (allLeaves) {
      const CurrentDate = [];
      allLeaves?.map((obj) => {
        CurrentDate.push(`${obj?.fromDate}`);
      });
      setDates(CurrentDate);
    }
  }, [allLeaves]);

  useEffect(() => {
    if (isOpenDialogBoxToggle) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpenDialogBoxToggle]);

  useEffect(() => {
    if (indiviualleave && id) {
      setFieldValue("leaveType", indiviualleave?.leaveType);
      setFieldValue("fromDate", indiviualleave?.fromDate);
      setFieldValue("fromTime", indiviualleave?.fromTime);
      setFieldValue("toDate", indiviualleave?.toDate);
      setFieldValue("toTime", indiviualleave?.toTime);
      setFieldValue("reason", indiviualleave?.reason);
      setFieldValue("status", indiviualleave?.leaveStatus);
      setFieldValue(
        "consumedLeave",
        indiviualleave?.leaveBalance ? indiviualleave?.leaveBalance : ""
      );
      if (!openToast) {
        if (
          (indiviualleave?.leaveStatus === "approve" ||
            indiviualleave?.leaveStatus === "reject") &&
          indiviualleave?.leaveStatus !== ""
        ) {
          setOpenToast(true);
          ToastServices.showToast({
            message: "Can't Update",
            type: "warning",
            autoClose: 3000,
          });
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        }
      }
    }
  }, [indiviualleave]);

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  useEffect(() => {
    if (values?.fromDate && values?.toDate) {
      setFieldValue(
        "consumedLeave",
        calculateNumberOfDays(
          values?.fromDate,
          values?.toDate,
          values?.fromTime,
          values?.toTime
        )
      );
    }
  }, [values?.fromDate, values?.toDate, values?.fromTime, values?.toTime]);

  const displayLeaveData = {
    "From Date": values?.fromDate,
    "From Time": values?.fromTime,
    "To date": values?.toDate,
    "To time": values?.toTime,
    "Consumed Leave": values?.consumedLeave,
    "Leave Type": values?.leaveType,
    Reason: values?.reason,
  };
  const AddEmployessDialoBox = () => {
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    dispatch(isAdditionDialogBoxToggle(!isAdditionDialogBox));
    setSubmitOnce(false);
  };
  useEffect(() => {
    if (values?.fromDate) {
      if (dates.includes(`${values.fromDate}`) && !paramId) {
        setFieldValue("fromDate","");
        setFieldValue("toDate","");
        ToastServices.showToast({
          message: "Leave Can't apply for same Date!!",
          type: "warning",
          autoClose: 3000,
        });
      }
    }
  }, [values, dates]);

  return (
    <>
      <form
        className="w-[96%] h-fit lg:h-[48.5rem] bg-white flex flex-col space-y-4
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] rounded-[20px] p-5] p-10 mt-2"
        onSubmit={handleSubmit}
      >
        <div className=" w-full h-16 flex justify-between ">
          <div className="w-full h-full flex justify-start items-center">
            <div className="flex justify-center items-center">
              <span
                className="text-[#031B59] mr-4 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <FaArrowLeft />
              </span>
              <h2 className="font-extrabold text-xl text-[#031B59]">{title}</h2>
            </div>
          </div>
          <div className="w-full h-fit bg-white flex justify-center lg:justify-between ">
            <div className="w-full h-fit hidden lg:flex"></div>
            {isOpenDialogBoxToggle && isAdditionDialogBox && (
              <AddLeavePopup
                AddEmployessDialoBox={AddEmployessDialoBox}
                checkData={displayLeaveData}
                employeedata={values}
                role="employee"
                setSubmitOnce={setSubmitOnce}
                type="Leave Confirmation"
                paramId={paramId}
                EditData={EditData}
                submitOnce={submitOnce}
              />
            )}
            <div className="flex items-center justify-center space-x-4">
              <button
                className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem] p-2
                  border border-[#23275E] text-[#23275E] rounded-full"
                type="submit"
                onClick={() => {
                  navigate("/leave");
                  dispatch(setIndividualleave({}));
                }}
              >
                {t("cancel")}
              </button>

              <button
                className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem]
                  p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                data-icon="success"
                onClick={handleSubmit}
              >
                {!paramId ? t("apply") : t("update")}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 gap-1 md:gap-2 lg:grid lg:grid-cols-2 lg:gap-4 ">
          <div className="flex flex-col">
            <label className="text-[#191919]" htmlFor="fromDate">
              {" "}
              {t("fromDate")}{" "}
            </label>
            <div className="mb-[10px] flex border p-2 rounded-lg w-[100%]">
              <input
                className="w-[90%] mr-3 bg-red bg-inherit"
                type="text"
                name="fromDate"
                placeholder="DD MM YYYY"
                value={
                  dates.includes(values?.fromDate)
                    ? ""
                    : convertDateFormat(values?.fromDate)
                }
              />

              <input
                className="m-0 w-5 rounded text-[#191919] outline-none ml-4"
                type="date"
                id="fromDate"
                name="fromDate"
                onChange={handleChange}
                onBlur={handleBlur}
                min={new Date().toISOString().split("T")[0]}
                onKeyDown={(e) => enterClick(e)}
              />
            </div>
            {errors.fromDate && touched.fromDate ? (
              <p className="text-[red]">{errors.fromDate}</p>
            ) : null}
          </div>

          <div className=" flex flex-col w-full">
            <label className="text-[#191919]" htmlFor="fromTime">
              {t("shift")}
            </label>
            <div className="relative">
              <select
                className="h-11 min-h-11 w-full border-[#E2E8F0]
                p-2 bg-white border rounded-lg appearance-none"
                style={{
                  borderColor: errors.fromTime && touched.fromTime ? "red" : "",
                }}
                type="number"
                id="fromTime"
                name="fromTime"
                value={values?.fromTime}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => enterClick(e)}
              >
                <option>{t("selectShift")}</option>
                <option value="First Half">{t("firstHalf")}</option>
                <option value="Second Half">{t("secondHalf")}</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
            {errors.fromTime && touched.fromTime ? (
              <p className="text-[red]">{errors.fromTime}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-[#191919]" htmlFor="toDate">
              {" "}
              {t("toDate")}{" "}
            </label>
            <div className="mb-[10px] rounded-lg flex border p-2 w-[100%]">
              <input
                className="w-[90%] mr-3 bg-red bg-inherit"
                type="text"
                name="toDate"
                placeholder="DD MM YYYY"
                value={
                  dates.includes(values?.toDate)
                    ? ""
                    : convertDateFormat(values?.toDate)
                }
              />
              <input
                className="m-0 w-5 rounded text-[#191919] outline-none ml-4 "
                type="date"
                id="toDate"
                name="toDate"
                value={values?.toDate}
                onChange={handleChange}
                onBlur={handleBlur}
                min={new Date().toISOString().split("T")[0]}
                onKeyDown={(e) => enterClick(e)}
              />
            </div>
            {errors.toDate && touched.toDate ? (
              <p className="text-[red]">{errors.toDate}</p>
            ) : null}
          </div>

          <div className=" flex flex-col w-full">
            <label className="text-[#191919]" htmlFor="toTime">
              {t("shift")}
            </label>
            <div className="relative">
              <select
                className=" h-11 min-h-11 w-full border-[#E2E8F0] p-2
                bg-white border rounded-lg appearance-none"
                style={{
                  borderColor: errors.toTime && touched.toTime ? "red" : "",
                }}
                type="number"
                id="toTime"
                name="toTime"
                value={values?.toTime}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => enterClick(e)}
              >
                <option>{t("selectShift")}</option>
                <option value="First Half">{t("firstHalf")}</option>
                <option value="Second Half">{t("secondHalf")}</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
            {errors.toTime && touched.toTime ? (
              <p className="text-[red]">{errors.toTime}</p>
            ) : null}
          </div>
          <div className="h-fit w-full flex flex-col">
            <label className="text-[#191919]" htmlFor="leaveType">
              {t("leaveType")}
            </label>
            <div className="relative">
              <select
                className=" h-11 min-h-11 w-full border-[#E2E8F0] p-2 capitalize
                bg-white border rounded-lg appearance-none"
                style={{
                  borderColor:
                    errors.leaveType && touched.leaveType ? "red" : "",
                }}
                type="number"
                id="leaveType"
                name="leaveType"
                value={values?.leaveType}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={(e) => enterClick(e)}
              >
                <option value={""}>{t("selectLeaveType")}</option>
                {allLeaveType
                  ?.filter((obj) => obj?.is_active === true)
                  .map((data) => (
                    <option key={data?.id} value={data?.leave_name}>
                      {data?.leave_name} {t("leave")}
                    </option>
                  ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
            {errors.leaveType && touched.leaveType ? (
              <p className="text-[red]">{errors.leaveType}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label className="text-[#191919]" htmlFor="consumedLeave">
              {t("conLeave")}
            </label>
            <input
              className="h-11 min-w-[8.438rem] bg-white border-[#E2E8F0] p-4 border rounded-lg"
              type="number"
              disabled
              id="consumedLeave"
              name="consumedLeave"
              value={values.consumedLeave}
            />
          </div>
          {id ? (
            <div className="flex flex-col">
              <label className="text-[#191919]" htmlFor="status">
                {" "}
                {t("Status")}{" "}
              </label>
              <input
                className="h-11 min-w-[8.438rem] bg-white border-[#E2E8F0] p-4 border rounded-lg"
                type="text"
                id="status"
                name="status"
                value={values?.status}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <label className="text-[#191919]" htmlFor="reason">
            {" "}
            {t("reason")}{" "}
          </label>
          <textarea
            className="h-24 w-full border-[#E2E8F0] p-2 border rounded-lg "
            style={{
              borderColor: errors.reason && touched.reason ? "red" : "",
            }}
            type="text"
            id="reason"
            name="reason"
            value={values?.reason}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.reason && touched.reason ? (
            <p className="text-[red]">{errors.reason}</p>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default ApplyEdit;

ApplyEdit.propTypes = {
  paramId: PropTypes.string,
  Formik: PropTypes.object,
  indiviualleave: PropTypes.object,
  submitOnce: PropTypes.bool,
  setSubmitOnce: PropTypes.any,
  EditData: PropTypes.any,
};
