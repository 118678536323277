import {
  SET_MONTHLY_CLIENTS,
  SET_MONTHLY_EMPLOYEES,
  SET_MONTHLY_REVENUE,
  SET_OVERVIEW_REVENUE,
  SET_PUNCH_IN_DATA,
  TIMER_DATA,
  TOTAL_WORKING_DAYS_LEAVES,
  SET_TOTAL_EMPLOYEES,
  SET_MONTHLY_WORKINGHOURS,
  SET_TOTAL_AVG,
  SET_YOUR_AVG,
  SET_YEARLY_WORKINGHOURS,
  SET_OVERIVEW_REVIEW,
  SET_BIRTHDAYS,
  SET_REQUESTS,
  SET_NEW_HIRES,
  SET_WORK_ANIVERSARY,
  SET_DASHBOARD_ALLATEND,
  SET_LEAVE_BALANCE,
  SET_ONGOING_DATA,
  SET_APPLICATION,
  SET_CREATE_FEED_GET,
  SET_DELETE_FEED,
  SET_WORKING_DAYS,
  SET_PROBATION,
  SET_TEAMATTEND_POPUP,
  SET_WFH_REQUEST,
  SET_VIEW_EVENT,
  SET_ALLAPPLICANTS,
  SET_CHECKIN_DATA,
  SET_SALARY_SLIP,
  SET_ONBOARDING
} from "redux/actions/types";

const initialstate = {
  dashboardData: [],
  getTimerData: [],
  totalWorkingDaysLeaves: [],
  monthlyRevenue: [],
  revenue: [],
  monthlyClients: [],
  monthlyEmployees: [],
  totalEmployees: [],
  monthlyWorkingHours: [],
  yearlyWorkingHours: [],
  totalAvg: [],
  yourAvg: [],
  overiewReviews: [],
  birthday: [],
  request: [],
  hire: [],
  aniversary: [],
  allAttendent: [],
  allAttendteam: [],
  leavebalance: [],
  ongoing: [],
  application: [],
  feedlatest: [],
  workingdays: [],
  isFeedDelete: false,
  probation: [],
  teampopup: [],
  wfhrequest: [],
  viewevent: [],
  getAllApplicantsData: [],
  setsalary: [],
  setonboarding: [],
};

export const dashboardReducers = (state = initialstate, { type, payload }) => {
  switch (type) {
  case SET_PUNCH_IN_DATA:
    return {
      ...state,
      dashboardData: payload,
    };
  case TIMER_DATA:
    return {
      ...state,
      getTimerData: payload,
    };
  case TOTAL_WORKING_DAYS_LEAVES:
    return {
      ...state,
      totalWorkingDaysLeaves: payload,
    };
  case SET_MONTHLY_REVENUE:
    return {
      ...state,
      monthlyRevenue: payload,
    };
  case SET_OVERVIEW_REVENUE:
    return {
      ...state,
      revenue: payload,
    };
  case SET_MONTHLY_CLIENTS:
    return {
      ...state,
      monthlyClients: payload,
    };
  case SET_MONTHLY_EMPLOYEES:
    return {
      ...state,
      monthlyEmployees: payload,
    };
  case SET_TOTAL_EMPLOYEES:
    return {
      ...state,
      totalEmployees: payload,
    };
  case SET_MONTHLY_WORKINGHOURS:
    return {
      ...state,
      monthlyWorkingHours: payload,
    };
  case SET_YEARLY_WORKINGHOURS:
    return {
      ...state,
      yearlyWorkingHours: payload,
    };
  case SET_TOTAL_AVG:
    return {
      ...state,
      totalAvg: payload,
    };

  case SET_YOUR_AVG:
    return {
      ...state,
      yourAvg: payload,
    };
  case SET_OVERIVEW_REVIEW:
    return {
      ...state,
      overiewReviews: payload,
    };
  case SET_BIRTHDAYS:
    return {
      ...state,
      birthday: payload,
    };
  case SET_REQUESTS:
    return {
      ...state,
      request: payload,
    };
  case SET_NEW_HIRES:
    return {
      ...state,
      hire: payload,
    };
  case SET_WORK_ANIVERSARY:
    return {
      ...state,
      aniversary: payload,
    };
  case SET_DASHBOARD_ALLATEND:
    return {
      ...state,
      allAttendent: payload,
    };
  case SET_LEAVE_BALANCE:
    return {
      ...state,
      leavebalance: payload,
    };
  case SET_ONGOING_DATA:
    return {
      ...state,
      ongoing: payload,
    };
  case SET_APPLICATION:
    return {
      ...state,
      application: payload,
    };

  case SET_CREATE_FEED_GET:
    return {
      ...state,
      feedlatest: payload,
    };

  case SET_DELETE_FEED:
    return {
      ...state,
      isFeedDelete: true,
    };

  case SET_WORKING_DAYS:
    return {
      ...state,
      workingdays: payload,
    };

  case SET_PROBATION:
    return {
      ...state,
      probation: payload,
    };

  case SET_TEAMATTEND_POPUP:
    return {
      ...state,
      teampopup: payload,
    };

  case SET_WFH_REQUEST:
    return {
      ...state,
      wfhrequest: payload,
    };

  case SET_ALLAPPLICANTS:
    return {
      ...state,
      getAllApplicantsData: payload,
    };

  case SET_VIEW_EVENT:
    return {
      ...state,
      viewevent: payload,
    };

  case SET_CHECKIN_DATA:
    return {
      ...state,
      setCheckIn: payload,
    };
  case SET_SALARY_SLIP :
    return {
      ...state,
      setsalary: payload,
    };

  case SET_ONBOARDING :
    return {
      ...state,
      setonboarding: payload,
    };

  default:
    return state;
  }
};
