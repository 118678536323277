import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import PropTypes from "prop-types";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { projectAction, resourceAction } from "redux/actions/action";
import { listFilterData } from "redux/appThunk/Admin/client";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "component/common/useOneClickOutsid";

const ClientTimesheetFilter = ({
  setShowFilter,
  handleFilterApply,
  selectedResources,
  selectedProjects,
  setSelectedProjects,
  setSelectedResources,
  resourceName,
  setResourceName,
  projectName,
  setProjectName,
}) => {
  const [resourceOpen, setResourceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermProjects, setSearchTermProjects] = useState("");
  const dispatch = useDispatch();
  const { stateTimeSheet } = useSelector((state) => state.ClientsReducer);
  const menuRef = useRef();
  const listResources = [...new Set(stateTimeSheet?.map((item) => item))];

  const handleResourceToggle = () => setResourceOpen(!resourceOpen);
  const handleProjectsToggle = () => setProjectsOpen(!projectsOpen);

  const handleSelectResource = (resource) => {
    const resourceIdArray = resource?.clientId;
    const resourceId =
      Array.isArray(resourceIdArray) && resourceIdArray.length > 0
        ? Number(resourceIdArray[0])
        : resourceIdArray;

    const resourceObj = {
      id: resourceId,
      name: resource?.employeeName,
    };

    setResourceName((prevSelectedProjects) => {
      const isSelected = prevSelectedProjects?.some(
        (project) => project?.id === resourceId
      );

      if (isSelected) {
        return prevSelectedProjects?.filter(
          (project) => project.id !== resourceId
        );
      } else {
        return [...prevSelectedProjects, resourceObj];
      }
    });

    setSelectedResources((prevSelectedResources) => {
      if (prevSelectedResources.includes(resourceId)) {
        return prevSelectedResources.filter((id) => id !== resourceId);
      } else {
        return [...prevSelectedResources, resourceId];
      }
    });
  };

  const handleSelectProject = (resource) => {
    const resourceIdArray = resource?.clientId;
    const resourceId =
      Array.isArray(resourceIdArray) && resourceIdArray.length > 0
        ? Number(resourceIdArray[0])
        : resourceIdArray;

    const projectObject = {
      id: resourceId,
      name: resource?.employeeName,
    };

    setProjectName((prevSelectedProjects) => {
      const isSelected = prevSelectedProjects.some(
        (project) => project.id === resourceId
      );

      if (isSelected) {
        return prevSelectedProjects.filter(
          (project) => project.id !== resourceId
        );
      } else {
        return [...prevSelectedProjects, projectObject];
      }
    });

    setSelectedProjects((prevSelectedProjects) => {
      if (prevSelectedProjects.includes(resourceId)) {
        return prevSelectedProjects.filter((id) => id !== resourceId);
      } else {
        return [...prevSelectedProjects, resourceId];
      }
    });
  };

  useEffect(() => {
    dispatch(resourceAction(selectedResources));
  }, [selectedResources]);

  useEffect(() => {
    dispatch(projectAction(selectedProjects));
  }, [selectedProjects]);

  const onCLose = () => {
    setShowFilter(false);
  };
  useEffect(() => {
    dispatch(listFilterData(id));
  }, [resourceOpen]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProjectSearch = (e) => {
    setSearchTermProjects(e.target.value);
  };

  const getUniqueResources = (resources) => {
    const seenNames = new Set();

    return resources.filter((resource) => {
      const isDuplicate = seenNames.has(resource.employeeName.toLowerCase());
      seenNames.add(resource.employeeName.toLowerCase());

      return !isDuplicate;
    });
  };

  const filteredResources = getUniqueResources(
    listResources?.filter((resource) =>
      resource?.employeeName?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const getUniqueProjects = (projects) => {
    const seenNames = new Set();

    return projects.filter((project) => {
      const isDuplicate = seenNames.has(project.projectName.toLowerCase());
      seenNames.add(project.projectName.toLowerCase());

      return !isDuplicate;
    });
  };

  const filteredProjects = getUniqueProjects(
    listResources?.filter((resource) =>
      resource.projectName
        .toLowerCase()
        .includes(searchTermProjects.toLowerCase())
    )
  );

  useOnClickOutside(menuRef, () => setShowFilter(false));

  return (
    <div ref={menuRef}
      className="w-96 p-4 bg-white border rounded-md shadow-md top-96">
      <div className="flex justify-between items-center">
        <div className="text-[#031B59] text-xl font-bold">Filters</div>
        <div onClick={onCLose} className="text-xl">
          <IoClose />
        </div>
      </div>
      <div className="mb-4">
        <div className="flex flex-wrap gap-2">
          {resourceName.map((resource) => (
            <span
              key={resource.id}
              className="px-2 py-1 bg-blue-900 text-white rounded-full"
            >
              {resource.name}
            </span>
          ))}
          {projectName.map((project) => (
            <span
              key={project.id}
              className="px-2 py-1 bg-blue-900 text-white rounded-full"
            >
              {project.name}
            </span>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div>
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleResourceToggle}
          >
            <div>{t("resource")}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {resourceOpen && (
            <div className="p-2 my-1 border">
              <input
                type="text"
                placeholder="Search"
                className="p-2 w-full border rounded-md mb-2"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="p-2 my-1 max-h-[15vh] overflow-y-auto custom_scroll">
                {filteredResources?.map((resource) => (
                  <div key={resource} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={selectedResources.includes(resource?.userId)}
                      onChange={() =>
                        handleSelectResource({
                          clientId: resource?.userId,
                          employeeName: resource?.employeeName,
                        })
                      }
                      className="mr-2"
                    />
                    <label>{resource?.employeeName}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleProjectsToggle}
          >
            <div>{t("proj")}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {projectsOpen && (
            <div className="p-2 my-1 border ">
              <input
                type="text"
                placeholder="Search"
                className="p-2 w-full border rounded-md mb-2"
                value={searchTermProjects}
                onChange={handleProjectSearch}
              />
              <div className="p-2 my-1 max-h-[15vh] overflow-y-auto custom_scroll">
                {filteredProjects?.map((project) => (
                  <div key={project} className="flex items-center mb-2 ">
                    <input
                      type="checkbox"
                      checked={selectedProjects.includes(
                        Number(project?.projID[0])
                      )}
                      onChange={() =>
                        handleSelectProject({
                          clientId: project?.projID[0],
                          employeeName: project?.projectName,
                        })
                      }
                      className="mr-2"
                    />
                    <label>{project?.projectName}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md">
          <MdRefresh />
        </button>
        <button
          onClick={handleFilterApply}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59]
         rounded-md"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};

export default ClientTimesheetFilter;

ClientTimesheetFilter.propTypes = {
  setShowFilter: PropTypes.bool,
  clientTimeSheet: PropTypes.any,
  handleFilterApply: PropTypes.any,
  filteredKeys: PropTypes.any,
  selectedResources: PropTypes.any,
  selectedProjects: PropTypes.any,
  setSelectedProjects: PropTypes.any,
  setSelectedResources: PropTypes.any,
  resourceName: PropTypes.any,
  setResourceName: PropTypes.any,
  projectName: PropTypes.any,
  setProjectName: PropTypes.any,
};
