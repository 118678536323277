export const formatSalaryAttributes = (response) => {
  const salary_type = {
    additions: [],
    deductions: [],
    others: [],
    id: null,
  };
  salary_type.additions = response?.salary_type[0]?.additions;
  salary_type.deductions = response?.salary_type[0]?.deductions;
  salary_type.others = response?.salary_type[0]?.others;
  salary_type.id = response?.salary_type[0]?.id;

  return salary_type;

};

export const formatSalaryListing = (response) => {
  return response?.data.map((obj) => ({
    id: obj?.id,
    ctc: obj.salary_details ? obj.salary_details.ctc : null,
    salarytype: obj.salary_details.salary_type ? obj.salary_details.salary_type : "No Record",
    status: obj.salary_details ? obj.salary_details.status : null,
    basicSalary: obj.salary_details?.additions
      ? obj.salary_details.additions.find((item) => item.name === "basic_salary")?.value
      : null,
    houseRentAllowance: obj.salary_details?.additions
      ? obj.salary_details.additions.find((item) => item.name === "house_rent_allowance")?.value
      : null,
    medicalAllowance: obj.salary_details?.additions
      ? obj.salary_details.additions.find((item) => item.name === "medical_allowance")?.value
      : null,
    specialAllowance: obj.salary_details?.additions
      ? obj.salary_details.additions.find((item) => item.name === "special_allowance")?.value
      : null,
    providentFund: obj.salary_details?.deductions
      ? obj.salary_details.deductions.find((item) => item.name === "providient_fund")?.value
      : null,
    esic: obj.salary_details?.deductions
      ? obj.salary_details.deductions.find((item) => item.name === "esic")?.value
      : null,
    gratuity: obj.salary_details?.deductions
      ? obj.salary_details.deductions.find((item) => item.name === "gratuity")?.value
      : null,
    tds: obj.salary_details?.deductions
      ? obj.salary_details.deductions.find((item) => item.name === "tds")?.value
      : null,
    incentives: obj.salary_details?.others
      ? obj.salary_details.others.find((item) => item.name === "incentives")?.value
      : null,
    retentionBonus: obj.salary_details?.others
      ? obj.salary_details.others.find((item) => item.name === "retention_bonus")?.value
      : null,
    bonus1: obj.salary_details ? obj.salary_details.bonus1 : null,
    bonus1Type: obj.salary_details ? obj.salary_details.bonus1_type : null,
    bonus2: obj.salary_details ? obj.salary_details.bonus2 : null,
    bonus2Type: obj.salary_details ? obj.salary_details.bonus2_type : null,
    fullName: obj.user_details?.employee_name || "user",
    employeeCode: obj.user_details ? obj.user_details.employee_code : null,
    totalWorkingDays: obj.user_details ? obj.user_details.total_working_days : null,
    effectiveWorkingDays: obj.user_details ? obj.user_details.effective_working_days : null,
    dateOfJoining: obj.user_details ? obj.user_details.date_of_joining : null,
    lossOfPayDays: obj.user_details ? obj.user_details.loss_of_pay_days : null,
    designation: obj.user_details ? obj.user_details.designation : null,
    bankName: obj.bank_details ? obj.bank_details.bank_name : null,
    accountNumber: obj.bank_details ? obj.bank_details.account_number : null,
    ifscCode: obj.bank_details ? obj.bank_details.ifsc_code : null,
    uanNo: obj.bank_details ? obj.bank_details.uan_no : null,
    esicNo: obj.user_details ? obj.user_details.esic_no : null,
  }));

};

