import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchClientsData } from "redux/appThunk/Admin/client";

const UseFetchClientData = ({ permissionToFetch, onSpotChange, clientType,
  currentPage,selectedFilter,search }) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(search, 500);

  useEffect(() => {
    const fetchUserData = () => {
      if (permissionToFetch.viewAll) {
        if (searchQuery)
          dispatch(fetchClientsData(searchQuery, currentPage));
        else {
          dispatch(fetchClientsData(search ,currentPage,selectedFilter));
        }
      }
    };
    fetchUserData();
  }, [onSpotChange, clientType, searchQuery, currentPage,selectedFilter]);
};

export default UseFetchClientData;

