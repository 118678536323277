import React from 'react';
import 'styles/App.css';
import { MainRoutes } from './routes';
import "language/i18n";

function App() {

  return (
    <>
      <MainRoutes/>
    </>
  )
  ;
}

export default App;
