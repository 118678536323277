import React, { useState } from "react";
import PropTypes from "prop-types";
import { getColorFromFullName, profileName } from "utils/CommonFunctions";
import { RxCross2 } from "react-icons/rx";

const DashboardEventChip = ({
  src,
  name,
  height = "2rem",
  width = "2rem",
  bgImgColor = "",
  customClass = "",
  textColor = "white",
}) => {
  const [visible, setVisible] = useState(true);

  const handleRemoveClick = () => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="chip flex items-center gap-2 p-[3px] h-[2.3rem] text-base rounded-3xl bg-[#F2F6FF]  ">
      {src ? (
        <img
          className={`h-[${height}] w-[${width}] flex items-center border-2 border-[white] rounded-full`}
          src={src}
          alt="Profile Avatar"
        />
      ) : (
        <div
          className={`text-[${textColor}] flex h-[${height}] w-[${width}]
           items-center justify-center border-2 border-[white] rounded-full ${customClass}`}
          style={{
            backgroundColor: bgImgColor
              ? bgImgColor
              : getColorFromFullName(name),
          }}
        >
          {profileName(name)}
        </div>
      )}
      <p className="text-[#031B59] font-normal whitespace-nowrap">{name}</p>
      <RxCross2 onClick={handleRemoveClick} className="text-[#A1A1A1]" />
    </div>
  );
};

DashboardEventChip.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  bgImgColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  customClass: PropTypes.string,
  textColor: PropTypes.string,
  border: PropTypes.string,
};

export default DashboardEventChip;
