import React from "react";
import TeamListing from "./TeamListing";

export default function Team() {
  return (
    <>
      <TeamListing />
    </>
  );
}
