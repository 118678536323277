import React, { useEffect, useState } from "react";
import Card from "component/common/Card";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DashboardAddEventPopup from "./DashboardAddEventPopup";
import DashboardViewEventPopup from "./DashboardViewEventPopup";
import { useDispatch, useSelector } from "react-redux";
import { viewEvent } from "redux/appThunk/Admin/dashboard";

const DashboardCalender = () => {
  // Initialize value with an array containing two Date objects
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const selector = useSelector((state) => state.dashboardReducers.viewevent);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewEvent());
  },[]);
  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleOpenEvent = () => {
    setOpenEvent(!openEvent);
  };

  return (
    <Card>
      <div className="flex justify-center items-center flex-col">
        <Calendar />
        <div className="w-full">
          <div className="flex p-5 justify-around gap-2 border border-t-[#f8f8f8]">
            <button
              onClick={handleOpenEvent}
              className="text-[#031B59] text-sm border border-[#E2E8F0] rounded-3xl
             px-4 py-3 font-medium hover:bg-[#031B59] hover:text-white"
            >
              View All Events
            </button>
            <button
              onClick={handleOpenDrawer}
              className="text-[#031B59] text-sm border border-[#E2E8F0] rounded-3xl
             px-4 py-3 font-medium hover:bg-[#031B59] hover:text-white"
            >
              Schedule an event
            </button>
          </div>
        </div>
      </div>
      {openDrawer && (
        <DashboardAddEventPopup
          title={"Add Event"}
          closeDrawer={handleOpenDrawer}
          selectorData={selector}
        />
      )}

      {openEvent && <DashboardViewEventPopup closeDrawer={handleOpenEvent} selectorData={selector} />}
    </Card>
  );
};

export default DashboardCalender;
