const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export const salaryColumn = [
  {
    title: "Employee Name",
    field: "fullName",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#031B59]",
    style: "",
    sorting: true,
  },
  {
    title: "Employee ID",
    field: "id",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#031B59]",
    style: "",
    sorting: true,
  },
  {
    title: "ESIC Number",
    field: "esic",
    align: alignValues.left,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#031B59]",
    style: "",
    sorting: true,
  },
  {
    title: "Salary Type",
    field: "salarytype",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#031B59]",
    style: "",
  },
  {
    title: "Date",
    field: "date",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#031B59]",
    style: "",
  },
  {
    title: "Basic Salary",
    field: "BasicSalary",
    align: alignValues.left,
    className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#031B59]",
  },
];

