import { PAGE_COUNT } from "redux/actions/types";

const initialstate = {
  allRoles: [],
  isLoading: true,
  pageCount: 0,
  AllPermissions: [],
  SetPermissions: [],
  manageAccessRole: "",
  reLoading: true ,
  viewRoleState: {},
  AllRolesPermissionId: {},
  permissionEmpty: {},
  UserByPermission: [],
};

export const permissionReducer = (state = initialstate, { type, payload }) => {

  switch (type) {
  case "ALL_ROLES":
    return {
      ...state,
      allRoles: payload,
    };

  case "SET_EMPTY_PERM":
    return {
      ...state,
      permissionEmpty: payload,
    };
  case "SET_ROLE_PERMISSION_ID":
    return {
      ...state,
      AllRolesPermissionId: payload,
    };
  case "ALL_PERMISSIONS":
    return {
      ...state,
      AllPermissions: payload,
    };
  case "SET_PERMISSIONS":
    return {
      ...state,
      AllPermissions: payload,
    };

  case "SET_USER_BY_PERMISSION":
    return {
      ...state,
      UserByPermission: payload,
    };
  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };

  case "SET_ROLE_DATA":
    return {
      ...state,
      viewRoleState: payload,
    };
  case PAGE_COUNT:
    return { ...state, pageCount: payload };
  case "MANAGE_ACCESS_ROLE":
    return {...state, manageAccessRole: payload};
  case "retrieve_IsLoding" :
    return{ ...state ,
      reLoading: payload ,
    };

  default:
    return state;
  }
};
