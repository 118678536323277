/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Employeebrief from "../Employee/Employeebrief";
import { GoSearch } from "react-icons/go";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import Popup from "component/common/Popup";
import {
  assignRoleForAccessPopup,
  assignRoleForAccessPopupNew
} from "redux/appThunk/Admin/permission";

function AddUserPop({
  handleCloseDialogBox,
  roleName,
  setRoleListing,
  currentPage,
  setPageCount,
  onAccess,
  roleDetail,
}) {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState();
  const [show, setShow] = useState(false);
  const { searchResult } = useSelector((state) => state.userSearchReducer);
  const { allRoles } = useSelector((state) => state.permissionReducer);
  const dispatch = useDispatch();
  const [userSelectedData, setUserSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search !== "") {
      setShow(true);
      dispatch(handleSearchUserForChat(search));
    } else {
      setShow(false);
    }
  }, [search]);

  const handleEmployeedata2 = (item) => {
    setShow(false);
    setUserSelectedData((prevState) => [...prevState, item]);
    setSearch("");
  };

  const getColorFromEmail = (email) => {
    const hashCode = email
      ?.split("")
      ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };

  const handleRemove = (id) => {
    setUserSelectedData((prevState) =>
      prevState.filter((obj) => obj.id !== id)
    );
  };

  useEffect(() => {
    if (allRoles && allRoles?.length > 0) {
      const rolename = window.location.pathname.split("/").pop();
      const role = allRoles.find((item) => rolename === item?.name);
      setSelectedRole(role);
    }
  }, [allRoles]);

  const handleSubmit = () => {
    if (userSelectedData?.length > 0) {
      const Ids = userSelectedData?.map((item) => item.id);
      if (roleName && onAccess === "") {
        dispatch(assignRoleForAccessPopup(Ids, roleName, setRoleListing, currentPage, setPageCount));
      }else {
        dispatch(assignRoleForAccessPopupNew(Ids, roleName));

      }
      handleCloseDialogBox();
    }
  };

  return (
    <Popup
      title={t("addUser")}
      popupBox={handleCloseDialogBox}
      handleSubmit={handleSubmit}
      submitBtnText={t("add")}
    >
      <div className="w-full">
        <>
          <div className=" m-auto flex flex-col gap-[10px]">
            <div className="w-full">
              <div className="relative flex flex-col">
                <div
                  className="m-[1rem] p-[0.25rem] border rounded-[0.4rem] flex items-center
                    justify-center"
                >
                  <div
                    className="w-full max-w-[37.5rem] h-fit min-h-[45px] flex flex-wrap items-center
                      justify-start gap-2 p-[0.5rem]"
                  >
                    {userSelectedData?.map((obj, index) => (
                      <span
                        key={index}
                        className="p-2 flex flex-row items-center justify-normal gap-2
                          rounded-full bg-[#F2F6FF]"
                      >
                        {obj.name}AddUser
                        <GrFormClose
                          className="fill-[#A1A1A1] w-5 h-5 p-1 box-content cursor-pointer"
                          onClick={() => {
                            handleRemove(obj.id);
                          }}
                        />
                      </span>
                    ))}
                    <input
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      name="clientName"
                      className="focus w-[30rem] outline-none"
                    />
                    <GoSearch className="w-[1.375rem] h-[1.375rem]" />
                  </div>

                </div>
                {show && (
                  <div
                    className="absolute flex justify-start flex-col top-[5rem] ml-[1rem]
      w-full max-w-[36rem] bg-white group
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
                  >
                    {searchResult?.length > 0 ? (
                      searchResult.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full px-2 flex items-center space-x-2 cursor-pointer
            hover:bg-[#031B59] group rounded"
                            onClick={() => handleEmployeedata2(item)}
                          >
                            <div className="w-full flex flex-col">
                              <p className="text-[#313131] hover:text-white capitalize p-1">
                                <div className="flex items-center">
                                  <div
                                    className="w-9 h-8 rounded-full flex items-center
                      justify-center text-white bg-[#031B59]
                      mr-2"
                                    style={{
                                      backgroundColor: getColorFromEmail(
                                        item.email
                                      ),
                                    }}
                                  >
                                    {item.email?.[0]?.toUpperCase()}
                                  </div>
                                  <Employeebrief
                                    email={item.email}
                                    names={item.name}
                                  />
                                </div>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-[#031B59]">{t("noUserFound")}</p>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>
        </>
      </div>
    </Popup>
  );
}

export default AddUserPop;
AddUserPop.propTypes = {
  handleCloseDialogBox: PropTypes.any,
  handleSubmit: PropTypes.func,
  path: PropTypes.string,
  setRoleListing: PropTypes.func,
  currentPage: PropTypes.number,
  setPageCount: PropTypes.func,
  roleLisiting: PropTypes.array,
  roleName: PropTypes.string,
  roleDetail: PropTypes.object,
  onAccess: PropTypes.string,
};
