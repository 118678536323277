import React from "react";

const SvgDelete = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">

      <path d="M5.16602 14.25C5.16602 15.075 5.84102 15.75 6.66602 15.75H12.666C13.491 15.75
      14.166 15.075 14.166 14.25V6.25C14.166 5.69772 13.7183 5.25 13.166 5.25H6.16602C5.61373
      5.25 5.16602 5.69772 5.16602 6.25V14.25ZM6.66602 7.75C6.66602 7.19772 7.11373 6.75 7.66602
       6.75H11.666C12.2183 6.75 12.666 7.19772 12.666 7.75V13.25C12.666 13.8023 12.2183 14.25 11.666
       14.25H7.66602C7.11373 14.25 6.66602 13.8023 6.66602 13.25V7.75ZM12.7052 3C12.44 3 12.1857 2.89464
       11.9981 2.70711L11.8339 2.54289C11.6464 2.35536 11.392 2.25 11.1268 2.25H8.20523C7.94001 2.25
       7.68566 2.35536 7.49812 2.54289L7.33391 2.70711C7.14637 2.89464 6.89202 3 6.6268 3H5.16602C4.7518
        3 4.41602 3.33579 4.41602 3.75C4.41602 4.16421 4.7518 4.5 5.16602 4.5H14.166C14.5802 4.5 14.916
         4.16421 14.916 3.75C14.916 3.33579 14.5802 3 14.166 3H12.7052Z" fill="#FF0000"/>
    </svg>
  );
};

export default SvgDelete;
