/* eslint-disable max-len */
import React from 'react';

const Svg01 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
    >
      <g clipPath="url(#clip0_1832_5314)">
        <path
          d="M14.9891 12.9822H2.22984C2.62063 12.5837 2.97163 12.2176 3.33574 11.866C3.38821 11.815 3.50535 11.8224 3.5922 11.8224C6.90281 11.8205 10.2133 11.8205 13.5236 11.8224H13.8244V4.75036H1.16645V5.01387C1.16645 6.4446 1.16329 7.87533 1.17053 9.3056C1.17378 9.38493 1.1597 9.46401 1.12934 9.53705C1.09898 9.61009 1.05309 9.67525 0.995029 9.72777C0.678411 10.0437 0.374006 10.3735 0.0234647 10.7405C0.0139661 10.606 0.00537109 10.541 0.00537109 10.4756C0.00537109 7.42115 0.00537109 4.36669 0.00537109 1.31224C0.00537109 0.479499 0.473963 0.000732422 1.28089 0.000732422H13.7235C14.53 0.000732422 14.999 0.480427 14.999 1.3127C14.999 5.1249 14.999 8.93709 14.999 12.7493C14.9981 12.8244 14.9927 12.8991 14.9891 12.9822ZM13.8298 1.78497H5.79812V2.93503H13.8298V1.78497ZM2.30718 2.36348C2.30906 2.28619 2.2958 2.2093 2.26821 2.13735C2.24062 2.06541 2.19926 1.99986 2.14655 1.94459C2.09384 1.88932 2.03087 1.84544 1.96135 1.81556C1.89183 1.78567 1.81717 1.77038 1.74179 1.77059C1.62721 1.7682 1.51452 1.80085 1.4181 1.86438C1.32167 1.92791 1.24588 2.01945 1.20036 2.12733C1.15484 2.23521 1.14166 2.35454 1.16251 2.47013C1.18336 2.58572 1.2373 2.69232 1.31743 2.77635C1.39757 2.86039 1.50029 2.91805 1.61249 2.94199C1.72469 2.96593 1.8413 2.95506 1.94747 2.91078C2.05363 2.86649 2.14453 2.79079 2.20859 2.69332C2.27266 2.59586 2.30698 2.48103 2.30718 2.36348ZM4.61714 2.35328C4.61528 2.19739 4.55312 2.04864 4.44433 1.93975C4.33554 1.83087 4.18904 1.77077 4.03705 1.77268C3.88506 1.77459 3.74003 1.83834 3.63387 1.94992C3.52771 2.0615 3.46912 2.21177 3.47098 2.36766C3.47284 2.52355 3.535 2.6723 3.64379 2.78118C3.75258 2.89007 3.89908 2.95016 4.05107 2.94826C4.20306 2.94635 4.34809 2.88259 4.45424 2.77101C4.5604 2.65943 4.619 2.50917 4.61714 2.35328Z"
          fill="#031B59"
        />
        <path
          d="M4.63074 7.09575C4.86598 7.09259 5.09612 7.16614 5.28797 7.3058C5.47982 7.44546 5.62346 7.64401 5.69819 7.87281C5.74025 7.99854 5.80267 8.05235 5.92434 8.09039C6.33142 8.2189 6.73851 8.36875 7.14559 8.49818C7.24062 8.52501 7.34199 8.51477 7.43009 8.46942C7.75395 8.27457 8.08368 8.21797 8.44824 8.33024C8.54507 8.35438 8.64725 8.33943 8.73365 8.28849C9.24024 7.95446 9.74095 7.61116 10.2358 7.25858C10.3148 7.19718 10.3677 7.10699 10.3837 7.00667C10.4497 6.33584 11.0463 5.81393 11.6574 5.90764C12.3625 6.01527 12.7931 6.63646 12.6561 7.34812C12.5313 7.99761 11.8713 8.40168 11.2168 8.21194C11.1506 8.18924 11.0799 8.1838 11.0112 8.19611C10.9424 8.20842 10.8777 8.23809 10.8229 8.28246C10.3389 8.62344 9.84407 8.94911 9.36101 9.29102C9.2882 9.34741 9.23956 9.43056 9.22531 9.52298C9.17375 10.0876 8.78341 10.5371 8.25465 10.6206C7.71595 10.706 7.21433 10.3928 6.99541 9.85515C6.95136 9.75619 6.87324 9.67739 6.77604 9.63386C6.36082 9.4752 5.93927 9.33277 5.51636 9.19638C5.43963 9.17642 5.35832 9.18843 5.2902 9.22978C5.04299 9.39676 4.74681 9.47024 4.45252 9.43762C4.36206 9.42973 4.24084 9.49607 4.17119 9.56705C3.12182 10.6338 2.07669 11.7046 1.03577 12.7797C0.854841 12.9653 0.644966 13.0437 0.399814 12.9708C0.18768 12.9078 0.0614862 12.7468 0.0112796 12.5278C-0.0484255 12.2694 0.0791237 12.0838 0.246027 11.9131C1.19528 10.941 2.14423 9.9685 3.09288 8.9955C3.12794 8.95514 3.16524 8.91688 3.2046 8.88091C3.40859 8.71437 3.49272 8.52462 3.4796 8.23374C3.45518 7.60049 3.99524 7.09343 4.63074 7.09575Z"
          fill="#031B59"
        />
      </g>
      <defs>
        <clipPath id="clip0_1832_5314">
          <rect width="15" height="13" fill="#031B59" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg01;
