import * as Yup from "yup";

export const applyStatusSchema = Yup.object({
  status_date: Yup.string().required("Please enter current date."),
  tasksDetails: Yup.array().of(
    Yup.object().shape({
      project_id: Yup.string().required("Project Name is required"),
      working_hours: Yup.string().required("Working Hours is required").test("working-hours-format", "Invalid working hours format", function(value) {
        if (!value) return true;
        const floatValue = parseFloat(value);
        const regex = /^\d+(\.\d{1,1})?$/;

        return (
          regex.test(value) &&
          floatValue >= 1 &&
          floatValue <= 12
        );
      }),
      task_status: Yup.string().required("Status is required"),
      task_description: Yup.string().required("Description is required"),
    })
  ),
});
