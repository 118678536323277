import React from "react";
import PropTypes from "prop-types";
import Mobile from "svgComponents/myTeam/Mobile";
import Email from "svgComponents/myTeam/Email";
import Design from "svgComponents/myTeam/Design";
import ProfileAvtar from "component/common/ProfileAvtar";

const Card = ({ item }) => {
  return (
    <div className="w-full h-full flex-col justify-start
    items-start border rounded-xl px-5 py-[18px] gap-4 inline-flex">
      <div className="w-full h-fit flex justify-between items-center gap-6">
        <div className="w-full flex flex-col justify-start items-start">
          <div className="text-blue-950 text-lg font-semibold capitalize">
            {item?.userName ? item.userName : "U-User"}
          </div>
          <div className="text-orange-500 text-xs font-normal">
            {item?.userDesignation ? item.userDesignation : "U-Designation"}
            | {item?.userLocation ? item.userLocation : "U-Location"}
          </div>
        </div>
        <div className="w-20 h-16 bg-white rounded-[136px]
          flex-col justify-center items-center inline-flex">
          <ProfileAvtar
            key={item?.userName }
            name={item?.userName || "User"}
            src={item?.userProfilePicture}
          />
        </div>
      </div>
      <div className="self-stretch h-[74px] flex-col justify-start items-start gap-1 flex">
        <div className="justify-start items-center gap-2 inline-flex text-zinc-900 text-sm font-normal ">
          <Design /> {item?.userDepartment ? item?.userDepartment : "U-Department"}
        </div>
        <div className=" flex justify-start items-center gap-2 text-zinc-900 text-sm font-normal ">
          <Mobile/> {item?.userMobile ? item?.userMobile : "9858564265"}
        </div>
        <div className=" flex justify-start items-center gap-2 text-zinc-900 text-sm font-normal  ">
          <Email /> {item?.userEmail ? item.userEmail : "No Email"}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  item: PropTypes.shape({
    userName: PropTypes.string,
    userProfilePicture: PropTypes.string,
    userDesignation: PropTypes.string,
    userDepartment: PropTypes.string,
    userLocation: PropTypes.string,
    userMobile: PropTypes.string,
    userEmail: PropTypes.string,
  }),
};

export default Card;
