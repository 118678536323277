/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from "react";
import Card from "component/common/Card";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workingday } from "redux/appThunk/Admin/dashboard";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DashboardWorkingDays = () => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(getCurrentMonth());
  const { workingdays } = useSelector((state) => state.dashboardReducers);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  function getCurrentMonth() {
    return months[new Date().getMonth()];
  }

  const handleItemClick = (month) => {
    setSelectedItem(month);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(workingday());
  }, [dispatch]);

  const totalWorkingDays = workingdays ? workingdays.totalworking || 0 : 0;
  const paidLeave = workingdays ? workingdays.paidleave || 0 : 0;
  const unpaidLeave = workingdays ? workingdays.unpaidleave || 0 : 0;

  return (
    <div>
      <Card cardClass="h-[10.563rem] w-full flex flex-col col-span-1 p-5">
        <div className="flex flex-row justify-between">
          <div>
            <h4 className="text-lg font-medium text-[#031B59] ">
              {t("total_working_days")}
            </h4>
          </div>
          <div className="flex items-end relative" ref={dropdownRef}>
            <button
              className="flex gap-2 items-center justify-center font-medium text-[#ED6E0F] text-base"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {t(selectedItem)} <IoIosArrowDown />
            </button>
            {isDropdownOpen && (
              <div
                className="bg-white p-3 rounded-md z-10 max-h-[200px] overflow-y-scroll"
                style={{
                  position: "absolute",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  top: "100%",
                  right: 0,
                }}
              >
                <ul>
                  {months.map((month, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(month)}
                      className={`block px-4 py-2 text-gray-800 hover:bg-[#F2F6FF] whitespace-nowrap rounded-lg cursor-pointer`}
                    >
                      {t(month)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="w-full h-full flex justify-between items-end">
          <div className="w-full h-full flex justify-between items-end">
            <div>
              <p
                className={` lg:text-2xl sm:text-lg font-bold text-[#031B59]`}
              >
                {totalWorkingDays}
              </p>
            </div>
            <div>
              <p className="text-[#031B59] text-base">
                {t("paid")}: {paidLeave}
              </p>
              <p className="text-[#031B59] text-base">
                {t("unPaidLeave")}: {unpaidLeave}
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DashboardWorkingDays;
