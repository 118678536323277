import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { additinaldailogSchema } from "redux/validator/admin/setting";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { AddAdditionalField } from "redux/appThunk/Admin/salary";
import Popup from "component/common/Popup";

function AdditionalDialog({ handleDialogBox, previousData, id }) {
  let additiondata = previousData?.additions;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialvalues = {
    field: "",
    name: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: additinaldailogSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        salary_type: {
          additions: [
            ...additiondata,
            { name: values.name, field_type: values.field },
          ],
        },
      };
      dispatch(AddAdditionalField(id, jsonObj));
      handleDialogBox();
    },
  });

  const { errors, touched, handleBlur, handleChange, handleSubmit } = Formik;

  return (
    <Popup
      handleSubmit={handleSubmit}
      popupBox={handleDialogBox}
      titleClass={"text-[#313135] font-medium"}
      title={t("addition")}
    >
      <div>
        <div className="flex flex-col item-center gap-4 mb-4">
          <div className="h-[2.625rem] w-[33rem] flex flex-col gap-1 p-3">
            <label htmlFor="leaveName" className="text-[#313135] font-medium">
              {t("ChooseField")}
            </label>
            <div className="flex gap-10">
              <div className="flex gap-3">
                <input
                  type="radio"
                  name="field"
                  value="Fixed"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className=" p-2 border border-[#E2E8F0] rounded text-[#191919]
outline-none bg-[#fff]"
                />
                <label htmlFor="Fixed" className="text-[#313135] font-medium">
                  {t("fixed")}
                </label>
              </div>
              <div className="flex gap-3">
                <input
                  type="radio"
                  name="field"
                  value="Variable"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className=" p-2 border border-[#E2E8F0] rounded text-[#191919]
outline-none bg-[#fff]"
                />
                <label
                  htmlFor="Variable"
                  className="text-[#313135] font-medium"
                >
                  {t("variable")}
                </label>
              </div>
            </div>
          </div>
          {errors.field && touched.field ? (
            <p className="text-[red] m-3">{errors.field}</p>
          ) : null}
        </div>

        <div className="flex flex-col p-3">
          <label htmlFor="leaveName" className="text-[#313135] font-medium">
            {t("name")}
          </label>
          <input
            type="text"
            role="input"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            className="h-[2.625rem] w-[33rem] p-2 border border-[#E2E8F0] rounded text-[#191919]
outline-none bg-[#fff]"
          />
          {errors.name && touched.name ? (
            <p className="text-[red]">{errors.name}</p>
          ) : null}
        </div>
      </div>
    </Popup>
  );
}

AdditionalDialog.propTypes = {
  handleDialogBox: PropTypes.func,
  previousData: PropTypes.object,
  id: PropTypes.number,
};

export default AdditionalDialog;
