import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";
import EditLeaveDetails from "./EditLeaveDetails";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";

const MyLeavepopup = ({ setOpenPopup, datas }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { t } = useTranslation();
  const getRole = JSON.parse(localStorage.getItem("userLoginToken"));

  useEffect(() => {
    if (showPopup || showEditPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup, showEditPopup]);

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedLeave(null);
  };

  const handleEditClick = () => {
    setShowPopup(false);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setOpenPopup(false);
  };

  const handleSaveEdit = (editedLeave) => {
    setSelectedLeave(editedLeave);
    setShowEditPopup(false);
  };

  return (
    <>
      {showPopup && selectedLeave && (
        <>
          <div className="w-full h-full flex items-center justify-end
        fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
            <div className="max-w-[40%] h-full p-5 bg-white flex flex-col
           space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto">
              <div className="w-full flex items-center justify-between">
                <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">  {t("viewleave")}</h2>
                <IoClose onClick={handleClosePopup} className="fill-[#686868] w-6 h-6 cursor-pointer" />
              </div>
              <hr className="my-4 mt-4 " />
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold"> {t("leaveDetails")}</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("emp_id")}</label>
                  <input
                    type="text"
                    name="employeeid"
                    value={datas?.id || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("name")}</label>
                  <input
                    type="text"
                    name="employeename"
                    value={datas?.name || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("fromDate")}</label>
                  <input
                    type="text"
                    name="fromDate"
                    value={datas?.fromDate || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("toDate")}</label>
                  <input
                    type="text"
                    name="toDate"
                    value={datas?.toDate || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium
                 font-['Roboto'] leading-snug">
                    {t("numberofdays")}</label>
                  <input
                    type="text"
                    name="consumedleave"
                    value={datas?.consumedLeave || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                 font-['Roboto'] leading-snug">
                    {t("leaveType")}</label>
                  <input
                    type="text"
                    name="leaveType"
                    value={datas?.leaveType || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium font-['Roboto'] leading-snug">
                    {t("reason")}</label>
                  <input
                    type="text"
                    name="reason"
                    value={datas?.reason || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("status")}</label>
                  <input
                    type="text"
                    name="leaveStatus"
                    value={datas?.leaveStatus || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
                  />
                </div>
              </div>
              <hr className="my-4 mt-4 " />
              <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug px-5 py-5">
                {t("Image")}</label>
              {datas?.imagesUrl ? (
                <img src={datas?.imagesUrl} alt="Leave document"
                  className="mt-2 w-full h-40 object-cover p-2 border border-slate-200 rounded-[4px]" />
              ) : (
                <div className="flex items-center gap-1 mt-2">
                  <img className="w-[22.77px] h-6" src="https://via.placeholder.com/23x24" alt="Default" />
                  <div className="text-zinc-900 text-base font-normal font-['Roboto']"> {t("Image")}</div>
                </div>
              )}

              {getRole?.role === "admin" && (

                <>
                  <hr />
                  <div className=" h-full flex justify-between items-end">
                    <div className="flex items-center">
                      <MdEdit className="text-xl"/>
                      <button className="flex items-center px-1 py-2 text-[#031B59]" onClick={handleEditClick}>
                        {t("edit")}
                      </button>
                    </div>
                    {datas?.leaveStatus !== "pending" && (
                      <>
                        {datas?.leaveStatus === "approved" && (
                          <label
                            className="px-4 py-2  gap-7 bg-[#1A8718] text-[#FFFFFF] rounded-full"
                          >
                            {t("approve")}
                          </label>
                        )}
                        {datas?.leaveStatus === "reject" && (
                          <label
                            className="px-4 py-2 mr-3  bg-[#FF0000] text-[#FFFFFF] rounded-full"
                          >
                            {t("rejected")}
                          </label>
                        )}
                        {datas?.leaveStatus === "cancelled" && (
                          <label
                            className="px-4 py-2 mr-3  bg-[#4444ac] text-[#FFFFFF] rounded-full"
                          >
                            {t("cancelled")}
                          </label>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {showEditPopup && (
        <EditLeaveDetails
          setOpenPopup={setOpenPopup}
          leaveDetails={datas}
          onClose={handleCloseEditPopup}
          onSave={handleSaveEdit}
        />
      )}
    </>
  );
};

MyLeavepopup.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  datas: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    consumedLeave: PropTypes.number.isRequired,
    leaveStatus: PropTypes.string.isRequired,
    leaveType: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    imagesUrl: PropTypes.string,
  }).isRequired,
};

export default MyLeavepopup;
