/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Form, FormikProvider, useFormik } from "formik";
import { applyStatusSchema } from "redux/validator/employee/status";
import { formatDate } from "utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import { getDateDashFormat } from "utils/date";
import TaskDescription from "./TaskDescription";
import Popup from "component/common/Popup";
import DatePicker from "component/common/DatePicker";
import { IoClose } from "react-icons/io5";
import {
  createTimeSheet,
  updateTimeSheet
} from "redux/appThunk/Employee/status";
import Tooltip from "component/Tooltip";

const TimeSheetForm = ({ closeDrawer, editData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPopUp, setShowPopUp] = useState(false);

  const initialvalues = {
    status_date:
      editData && editData?.statusDate
        ? moment(editData?.statusDate).utc().format("YYYY-MM-DD")
        : formatDate(),
    tasksDetails:
      editData && editData?.tasks
        ? editData.tasks
        : [
          {
            id: editData ? editData.id : "",
            working_hours: editData ? editData.workingHours : "",
            task_status: editData ? editData.taskStatus : "In Progress",
            task_description: editData ? editData.description : "",
            billable: editData ? editData.billable : "",
            project_id: editData ? editData.projectId : "",
            images: editData ? editData.task_image : "",
          },
        ],
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: applyStatusSchema,
    onSubmit: async () => {
      try {
        setShowPopUp(true);
      } catch (error) {
        /* empty */
      }
    },
  });

  const { values, touched, errors, handleSubmit, handleChange, handleBlur } =
    Formik;
  const addTask = () => {
    Formik.setValues({
      ...values,
      tasksDetails: [
        ...values.tasksDetails,
        {
          id: "",
          working_hours: "",
          task_status: "In Progress",
          task_description: "",
          billable: "",
          project_id: "",
          images: "",
        },
      ],
    });
  };
  const addStatusDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    if (showPopUp) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showPopUp]);

  const Submit = async () => {
    try {
      Formik.setSubmitting(true);
      if (editData) {
        const jsonObj = {
          status: {
            to: "",
            cc: "",
            status_date: Formik.values.status_date,
            task_description: Formik.values.tasksDetails,
          },
        };
        await dispatch(updateTimeSheet(editData.id, jsonObj));
      } else {
        const data = {
          status_date: Formik.values.status_date,
          tasks_attributes: Formik.values.tasksDetails,
        };
        await dispatch(createTimeSheet(data));
      }
      setShowPopUp(false);
      closeDrawer();
      Formik.setSubmitting(false);
    } catch (error) {
      Formik.setSubmitting(false);
    }
  };

  return (
    <div>
      {showPopUp && (
        <Popup
          popupBox={addStatusDialoBox}
          title={t("confirmDetail")}
          handleSubmit={Submit}
          submitBtnText={editData ? t("update") : t("save")}
          submitOnce={Formik.isSubmitting}
        >
          <div className="w-full flex flex-col py-8 gap-2">
            <div className="p-2 flex justify-center font-bold text-xl">
              <h2>{t("areYSure")}</h2>
            </div>
          </div>
        </Popup>
      )}
      <div
        className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
      >
        <div
          className="min-w-[20%] w-[40rem] h-full bg-white flex-flex-col space-y-8
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
        >
          <FormikProvider value={Formik}>
            <Form
              className="sticky"
              autoComplete="off"
              id="timesheet-form"
              onSubmit={handleSubmit}
            >
              <div className="w-full sticky h-fit flex justify-between  p-5 px-8 z-5">
                <div className="flex flex-col justify-center items-center">
                  <h2 className="font-extrabold text-xl text-[#031B59]">
                    {editData ? t("edit_status") : t("fillTimeSheet")}
                  </h2>
                </div>

                <IoClose
                  onClick={closeDrawer}
                  className="fill-[#686868] w-6 h-6 cursor-pointer mr-3"
                />
              </div>
              <hr />
              <div className="h-full flex flex-col">
                <div className=" flex flex-col gap-2 h-[90vh] overflow-y-auto custom_scroll">
                  <div className="h-[100px] w-[full] grid grid-cols-1 lg:grid lg:grid-cols-2 gap-0 mx-5 p-3">
                    <div className="flex  w-[35rem] flex-col gap-0 ">
                      <label
                        className="text-[#313135] font-medium"
                        htmlFor="status_date"
                      >
                        {t("status_date")}
                      </label>
                      {editData ? (
                        <Tooltip message={"disabled"} className="w-full">
                          <DatePicker
                            styles={"w-full"}
                            name={"status_date"}
                            id="status_date"
                            value={
                              values?.status_date === ""
                                ? getDateDashFormat(new Date())
                                : getDateDashFormat(values?.status_date)
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            min={new Date().toISOString().split("T")[0]}
                            disabled={!!editData}
                          />
                        </Tooltip>
                      ) : (
                        <DatePicker
                          styles={"w-full"}
                          name={"status_date"}
                          id="status_date"
                          value={
                            values?.status_date === ""
                              ? getDateDashFormat(new Date())
                              : getDateDashFormat(values?.status_date)
                          }
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          min={new Date().toISOString().split("T")[0]}
                          disabled={!!editData}
                        />
                      )}

                      {errors?.status_date && touched.status_date ? (
                        <p className="text-[red]">{errors?.status_date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="p-3 flex flex-col gap-4 w-full">
                    {values?.tasksDetails.map((task, index) => (
                      <TaskDescription key={index} task={task} index={index} />
                    ))}
                  </div>

                  <div className="ml-3 p-3">
                    <button
                      className="text-md font-semibold text-[#031B59] pl-1 mt-4 ml-1 flex items-center
                      justify-center gap-1"
                      type="button"
                      onClick={addTask}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5
                  13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5
                  4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                          stroke="#031B59"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("add_more_task")}
                    </button>
                    <hr className="mt-1" />
                    <div className="h-fit">
                      <div className="w-full h-fit flex justify-between">
                        <div className="flex flex-col justify-center items-center"></div>
                        <div className="flex items-center justify-center space-x-4 mt-6">
                          <button
                            className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem] flex
              items-center justify-center p-2 text-[#23275E] rounded-full outline-none"
                            type="button"
                            onClick={closeDrawer}
                          >
                            {t("cancel")}
                          </button>
                          <button
                            className={`h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem] flex items-center
                          justify-center p-2 rounded-full
                          ${
    Formik.isSubmitting
      ? "bg-[#ddddddad]"
      : "bg-[#031B59] text-white"
    }`}
                            type="submit"
                            disabled={Formik.isSubmitting}
                          >
                            {editData ? t("update") : t("send")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

export default TimeSheetForm;
