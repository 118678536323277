/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import swalService from "utils/SwalServices";
import { setEmployeeSalaryInfo } from "redux/actions/action";
import { SALARY_TYPE, STATUS_ARR } from "utils/Constants";
import { useTranslation } from "react-i18next";
import { section } from "utils/Constants";
import ToastServices from "ToastServices";
import { BiEditAlt } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import PropTypes from "prop-types";

export default function SalaryDetails({setShowPopup}) {
  const [isEdit , setIsEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const employeeSalaryInfo = useSelector(
    (state) => state.SalaryInfoReducer.employeeSalaryInfo
  );

  const handleEmployeeInfo = async (id) => {
    const path = `${apiUrl.salary}/${id}`;
    try {
      const { response } = await Helper.get(path);
      dispatch(setEmployeeSalaryInfo(response));
    } catch (error) {
      ToastServices.showToast({
        message: "Error !",
        type: "error",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (id) {
      handleEmployeeInfo(id);
    }
  }, [id]);

  let newObj = {};
  employeeSalaryInfo?.salary_slip?.additions?.map((val) =>
    val.value ? (newObj[val.name] = val.value) : (newObj[val.name] = "")
  );
  employeeSalaryInfo?.salary_slip?.deductions?.map((val) =>
    val.value ? (newObj[val.name] = val.value) : (newObj[val.name] = "")
  );
  employeeSalaryInfo?.salary_slip?.others?.map((val) =>
    val.value ? (newObj[val.name] = val.value) : (newObj[val.name] = "")
  );

  const initialvalues = {
    full_name: "",
    date: "",
    employee_id: "",
    total_working_days: "",
    Effective_Working_days: "",
    total_leaves: "",
    salary_type: "",
    status: "",
    consultancy_fees: "",
    ...newObj,
  };
  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialvalues,
    onSubmit: async (values) => {
      let jsonObj = {
        salary_slip: {
          user_id: employeeSalaryInfo?.user?.id,
          deductions: [],
          additions: [],
          others: [],
          salary_info_id: employeeSalaryInfo?.salary_slip?.salary_info_id,
          status: values?.status,
          salary_type: values?.salary_type,
        },
      };
      if (
        employeeSalaryInfo?.user?.section_applicable === section.section192
      ) {
        employeeSalaryInfo &&
          employeeSalaryInfo?.salary_slip?.deductions?.map((val) =>
            jsonObj.salary_slip.deductions.push({
              name: val.name,
              value: values[val.name],
            })
          );
        employeeSalaryInfo &&
          employeeSalaryInfo?.salary_slip?.additions?.map((val) =>
            jsonObj.salary_slip.additions.push({
              name: val.name,
              value: values[val.name],
            })
          );
        employeeSalaryInfo &&
          employeeSalaryInfo?.salary_slip?.others?.map((val) =>
            jsonObj.salary_slip.others.push({
              name: val.name,
              value: values[val.name],
            })
          );

      } else {
        if (
          employeeSalaryInfo?.user?.section_applicable === section.section194
        ){
          employeeSalaryInfo &&
          employeeSalaryInfo?.salary_slip?.deductions?.filter((val) =>
            val.name === "Consultancy Fee" || val.name === "TDS"
          )?.map((val) =>
            jsonObj.salary_slip.deductions.push({
              name: val.name,
              value: values[val.name],
            })
          );
        }
      }

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            var id = employeeSalaryInfo?.salary_slip?.id;
            const path = `${apiUrl.salary}/${id}`;
            try {
              await Helper.put(jsonObj, path);
              ToastServices.showToast({
                message: "Status Has Been Updated !",
                type: "success",
                autoClose: 3000,
              });
              navigate(-1);

            } catch (error) {
              ToastServices.showToast({
                message: "Error !",
                type: "error",
                autoClose: 3000,
              });
            }
          }
        });
    },
  });
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
  } = Formik;

  useEffect(() => {
    if (employeeSalaryInfo) {
      setFieldValue("full_name", employeeSalaryInfo?.user?.full_name);
      setFieldValue(
        "date",
        moment(employeeSalaryInfo?.date, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      setFieldValue("employee_id", employeeSalaryInfo?.user?.id);
      setFieldValue(
        "total_working_days",
        employeeSalaryInfo?.total_working_days
      );
      setFieldValue(
        "Effective_Working_days",
        employeeSalaryInfo?.Effective_Working_days
      );
      setFieldValue("total_leaves", employeeSalaryInfo?.total_leaves);
      setFieldValue(
        "basic_salary",
        employeeSalaryInfo?.salary_slip?.basic_salary
      );
      setFieldValue(
        "allowance_medical",
        employeeSalaryInfo?.salary_slip?.allowance_medical
      );
      setFieldValue(
        "allowance_special",
        employeeSalaryInfo?.salary_slip?.allowance_special
      );
      setFieldValue("hra", employeeSalaryInfo?.salary_slip?.hra);
      setFieldValue("gratuity", employeeSalaryInfo?.salary_slip?.gratuity);
      setFieldValue("income_tax", employeeSalaryInfo?.salary_slip?.income_tax);
      setFieldValue("pf", employeeSalaryInfo?.salary_slip?.pf);
      setFieldValue("esic", employeeSalaryInfo?.salary_slip?.esic);
      setFieldValue("tds", employeeSalaryInfo?.salary_slip?.tds);
      setFieldValue(
        "professional_tax",
        employeeSalaryInfo?.salary_slip?.professional_tax
      );
      setFieldValue(
        "consultancy_fees",
        employeeSalaryInfo?.salary_slip?.consultancy_fees
      );
      setFieldValue("incentives", employeeSalaryInfo?.salary_slip?.incentives);
      setFieldValue(
        "salary_type",
        employeeSalaryInfo?.salary_slip?.salary_type
      );
      setFieldValue(
        "bonus_type_1",
        employeeSalaryInfo?.salary_slip?.bonus_type_1
      );
      setFieldValue("bonus_1", employeeSalaryInfo?.salary_slip?.bonus_1);
      setFieldValue(
        "bonus_type_2",
        employeeSalaryInfo?.salary_slip?.bonus_type_2
      );
      setFieldValue("bonus_2", employeeSalaryInfo?.salary_slip?.bonus_2);
      setFieldValue("status", employeeSalaryInfo?.salary_slip?.status);
      setFieldValue("gst", employeeSalaryInfo?.salary_slip?.gst);
      setFieldValue("invoice", employeeSalaryInfo?.salary_slip?.invoice);
    }
  }, [employeeSalaryInfo]);

  return (
    <div
      className="w-[40%] h-full text-[1rem] p-5 bg-white flex-col items-center
          justify-center absolute right-0 bottom-0 z-30 space-y-2 overflow-y-scroll
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.2)] custom_scroll   "
    >
      <div className="w-full h-10 bg-white flex justify-between ">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#031B59] ">
            {isEdit ? ("Edit Salary") : ("View Details") }
          </h2>
        </div>
        <div className="flex items-center justify-center space-x-2">
          {
            !isEdit &&
          (<button
            className="h-[2.813rem] text-[1.5rem]  p-2 text-[#686868]"
            onClick={() => setIsEdit(true)}
          >
            <BiEditAlt/>
          </button>)
          }
          <button
            className="w-[2rem]  h-[1.688rem] text-[1.5rem]
            flex items-center justify-center rounded-full"
            onClick={() => setShowPopup(false)}
          >
            <IoCloseOutline/>
          </button>

        </div>
      </div>
      <form >
        <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-[1.25rem] p-4">
          <div className="flex flex-col">
            <label htmlFor="full_name" className="text-[#686868]">
              {t("Employee Name")}
            </label>
            <input
              type="text"
              name="full_name"
              id="full_name"
              value={values?.full_name}
              disabled = {!isEdit}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="employee_id" className="text-[#686868]">
              {t("emp_id")}
            </label>
            <input
              type="text"
              name="employee_id"
              id="employee_id"
              disabled = {!isEdit}
              value={values.employee_id}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919]
                 ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="esic" className="text-[#686868]">
              {t("ESIC")}
            </label>
            <input
              type="text"
              name="esic"
              id="esic"
              disabled = {!isEdit}
              value={values.employee_id}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919]
                 ${isEdit ? "border" : "border-none"}`}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="salary_type" className="text-[#686868]">
              {t("salary_type")}
            </label>
            <select
              id="salary_type"
              name="salary_type"
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919]
                 ${isEdit ? "border" : "border-none"}`}
              value={values.salary_type}
              onChange={handleChange}
              disabled = {!isEdit}
              onBlur={handleBlur}
            >
              {SALARY_TYPE?.map((obj) => (
                <option className="accent-[#031B59]" key={obj.id} value={obj.key}>
                  {obj.value}
                </option>
              ))}
            </select>
            {errors.salary_type && touched.salary_type ? (
              <p className="text-[red]">{errors.salary_type}</p>
            ) : null}
          </div>
          <div className="flex flex-col">
            <label htmlFor="date" className="text-[#686868]">
              {t("date")}
            </label>
            <input
              type="date"
              name="date"
              // id="date"
              value={values.date}
              disabled = {!isEdit}
              className={`h-[2.625rem] w-full border border-[#E2E8F0]
                 rounded p-[10px_14px_10px_10px] text-[#191919] 
                 ${isEdit ? "border" : "border-none"}`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="total_working_days" className="text-[#686868]">
              {t("total_working_days")}
            </label>
            <input
              type="text"
              id="total_working_days"
              disabled = {!isEdit}
              name="total_working_days"
              value={values.total_working_days}
              className={`h-[2.625rem] w-full border border-[#E2E8F0]
                 rounded p-[10px_14px_10px_10px] text-[#191919]
                  ${isEdit ? "border" : "border-none"}`}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="Effective_Working_days" className="text-[#686868]">
              {t("effective_working_days")}
            </label>
            <input
              type="text"
              name="Effective_Working_days"
              id="Effective_Working_days"
              disabled = {!isEdit}
              value={values.Effective_Working_days}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="total_leaves" className="text-[#686868]">
              {t("total_leaves")}
            </label>
            <input
              type="text"
              name="total_leaves"
              value={values?.total_leaves}
              disabled = {!isEdit}
              id="total_leaves"
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919]
                 ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="basic_salary" className="text-[#686868]">
              {t("Basic Salary")}
            </label>
            <input
              type="text"
              name="basic_salary"
              id="basic_salary"
              disabled = {!isEdit}
              value={values.basic_salary}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="house_rent_allowance" className="text-[#686868]">
              {t("House Rent Allowance")}
            </label>
            <input
              type="text"
              name="houseRent_allowance"
              id="houseRent_allowance"
              disabled = {!isEdit}
              value={values.houseRent_allowance}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="allowance_medical" className="text-[#686868]">
              {t("Medical Allowance")}
            </label>
            <input
              type="text"
              name="allowance_medical"
              id="allowance_medical"
              disabled = {!isEdit}
              value={values.allowance_medical}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="allowance_special" className="text-[#686868]">
              {t("Special Allowance")}
            </label>
            <input
              type="text"
              name="allowance_special"
              id="allowance_special"
              disabled = {!isEdit}
              value={values.allowance_special}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="consultancy_fees" className="text-[#686868]">
              {t("Consultancy Fees")}
            </label>
            <input
              type="text"
              name="consultancy_fees"
              id="consultancy_fees"
              disabled = {!isEdit}
              value={values.consultancy_fees}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="incentives" className="text-[#686868]">
              {t("Incentives")}
            </label>
            <input
              type="text"
              name="incentives"
              id="incentives"
              disabled = {!isEdit}
              value={values.incentives}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="bonus_1" className="text-[#686868]">
              {t("Bonus 1")}
            </label>
            <input
              type="text"
              name="bonus_1"
              id="bonus_1"
              disabled = {!isEdit}
              value={values.bonus_1}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="bonus_type_1" className="text-[#686868]">
              {t("Bonus Type")}
            </label>
            <input
              type="text"
              name="bonus_type_1"
              id="bonus_type_1"
              disabled = {!isEdit}
              value={values.bonus_type_1}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="bonus_2" className="text-[#686868]">
              {t("Bonus 2")}
            </label>
            <input
              type="text"
              name="bonus_2"
              id="bonus_2"
              disabled = {!isEdit}
              value={values.bonus_2}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="bonus_type_2" className="text-[#686868]">
              {t("Bonus Type ")}
            </label>
            <input
              type="text"
              name="bonus_type_2"
              id="bonus_type_2"
              disabled = {!isEdit}
              value={values.bonus_type_2}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="provident_fund" className="text-[#686868]">
              {t("Provident Fund ")}
            </label>
            <input
              type="text"
              name="provident_fund"
              id="provident_fund"
              disabled = {!isEdit}
              value={values.provident_fund}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="deductions" className="text-[#686868]">
              {t("Tax Deduction At Source ")}
            </label>
            <input
              type="text"
              name="deductions"
              id="deductions"
              disabled = {!isEdit}
              value={values.deductions}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919]
                 ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="professional_tax" className="text-[#686868]">
              {t("Professional Tax")}
            </label>
            <input
              type="text"
              name="professional_tax"
              id="professional_tax"
              disabled = {!isEdit}
              value={values.professional_tax}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="gratuity" className="text-[#686868]">
              {t("gratuity")}
            </label>
            <input
              type="text"
              name="gratuity"
              id="gratuity"
              disabled = {!isEdit}
              value={values.gratuity}
              className={`h-[2.625rem] w-full border border-[#E2E8F0] 
                rounded p-[10px_14px_10px_10px] text-[#191919] 
                ${isEdit ? "border" : "border-none"}`}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="status" className="text-[#686868]">{t("empstatus")}</label>
            <select
              id="status"
              name="status"
              value={values.status}
              className={`h-[2.625rem] p-2 hover:accent-[#031B59] w-full 
                border border-[#E2E8F0] rounded bg-white 
                ${isEdit ? "border" : "border-none"}`}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled = {!isEdit}
            >
              {STATUS_ARR?.map((obj) => (
                <option
                  className="accent-[#031B59]"
                  key={obj.id}
                  value={obj.key}
                  disabled={obj.disable}
                >
                  {obj.value}
                </option>
              ))}
            </select>
            {errors.status && touched.status ? (
              <p className="text-[red]">{errors.status}</p>
            ) : null}
          </div>

          {employeeSalaryInfo?.user?.section_applicable === section.section192 &&
          employeeSalaryInfo?.salary_slip?.additions?.map((val, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={val.name}>{val.name}</label>
              <input
                type="text"
                name={val?.name}
                id={val?.name}
                value={Formik.values[val?.name]}
                className="h-[2.625rem] w-full p-2
                 border border-[#E2E8F0] rounded text-[#191919]"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.val?.name && touched.val?.name ? (
                <p className="text-[red]">{errors.val?.name}</p>
              ) : null}
            </div>
          ))}
          {employeeSalaryInfo?.user?.section_applicable === section.section192 &&
          employeeSalaryInfo?.salary_slip?.deductions
            ?.filter((val) => val.name != "Consultancy Fee")
            ?.map((val, index) => (
              <div key={index} className="flex flex-col">
                <label htmlFor={val.name}>{val.name}</label>
                <input
                  type="text"
                  name={val?.name}
                  id={val?.name}
                  value={Formik.values[val?.name]}
                  className="h-[2.625rem] w-full p-2
                 border border-[#E2E8F0] rounded text-[#191919]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.val?.name && touched.val?.name ? (
                  <p className="text-[red]">{errors.val?.name}</p>
                ) : null}
              </div>
            ))}{" "}
          {employeeSalaryInfo?.user?.section_applicable === section.section194 &&
          employeeSalaryInfo?.salary_slip?.deductions
            ?.filter(
              (val) => val.name === "Consultancy Fee" || val.name === "TDS"
            )
            ?.map((val, index) => (
              <div key={index} className="flex flex-col">
                <label htmlFor={val.name}>{val.name}</label>
                <input
                  type="text"
                  name={val?.name}
                  id={val?.name}
                  value={Formik.values[val?.name]}
                  className="h-[2.625rem] w-full p-2
                 border border-[#E2E8F0] rounded text-[#191919]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.val?.name && touched.val?.name ? (
                  <p className="text-[red]">{errors.val?.name}</p>
                ) : null}
              </div>
            ))}
          {employeeSalaryInfo?.user?.section_applicable === section.section192 &&
          employeeSalaryInfo?.salary_slip?.others?.map((val, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={val.name}>{val.name}</label>
              <input
                type="text"
                name={val?.name}
                id={val?.name}
                value={Formik.values[val?.name]}
                className="h-[2.625rem] w-full p-2
                 border border-[#E2E8F0] rounded text-[#191919]"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.val?.name && touched.val?.name ? (
                <p className="text-[red]">{errors.val?.name}</p>
              ) : null}
            </div>
          ))}
        </div>
      </form>

      {
        isEdit &&
  (< div className="flex justify-end items-end mx-[2.5rem] my-[1.5rem] space-x-2">
    <button
      className="text-[#031B59] px-[1.5rem] py-[0.75rem]"
      onClick={() => setShowPopup(false)}
    >
      cancel
    </button>

    <button
      data-testid="save-button"
      className="px-[1.5rem] py-[0.75rem] text-white bg-[#031B59]
      border border-[#031B59] rounded-[1.5625rem]"
      type="submit"
      onClick={handleSubmit}
    >
      Update & Save
    </button>
  </div>)
      }

    </div>
  );
}

SalaryDetails.propTypes = {
  setShowPopup: PropTypes.func.isRequired,
};
