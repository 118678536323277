/* eslint-disable max-len */
import React from "react";

const MyProject3Svg = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10.0002 6.89696C10.8533 6.89696 11.5513 6.199 11.5513 5.34594C11.5513 4.49288 10.8533 3.79492 10.0002 3.79492C9.14718 3.79492 8.44922 4.49288 8.44922 5.34594C8.44922 6.199 9.14718 6.89696 10.0002 6.89696ZM10.0002 8.44798C9.14718 8.44798 8.44922 9.14594 8.44922 9.999C8.44922 10.8521 9.14718 11.55 10.0002 11.55C10.8533 11.55 11.5513 10.8521 11.5513 9.999C11.5513 9.14594 10.8533 8.44798 10.0002 8.44798ZM10.0002 13.101C9.14718 13.101 8.44922 13.799 8.44922 14.6521C8.44922 15.5051 9.14718 16.2031 10.0002 16.2031C10.8533 16.2031 11.5513 15.5051 11.5513 14.6521C11.5513 13.799 10.8533 13.101 10.0002 13.101Z"
          fill="#686868"
        />
      </svg>
    </div>
  );
};

export default MyProject3Svg;
