import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "svgComponents/myTeam/Dropdown";
import PropTypes from 'prop-types';

const DepartmentDropdown = ({ selectedDepartments, onDepartmentsChange, clearFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedDepartments);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const departments = [
    "Select All",
    "Unassigned",
    "Software Engineer",
    "HR",
    "Designer",
    "Business Development",
    "Administration",
    "Accounts",
  ];

  useEffect(() => {
    if (clearFilter) {
      setSelected([]);
    }
  }, [clearFilter]);

  useEffect(() => {
    onDepartmentsChange(selected);
  }, [selected, onDepartmentsChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (department) => {
    if (department === "Select All") {
      if (selected.length === departments.length - 1) {
        setSelected([]);
      } else {
        setSelected(departments.filter((dept) => dept !== "Select All"));
      }
    } else {
      setSelected((prevSelected) =>
        prevSelected.includes(department)
          ? prevSelected.filter((dept) => dept !== department)
          : [...prevSelected, department]
      );
    }
  };

  const isSelectAllChecked = selected.length === departments.length - 1;

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="inline-flex justify-center w-full rounded-md shadow-sm
        px-4 py-2 bg-white text-sm font-medium text-gray-700
         hover:bg-gray-50 focus:outline-none"
        onClick={handleToggle}
      >
        {t("department")}
        <Dropdown />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56
        rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {departments.map((department) => (
              <div key={department} className="flex items-center px-4 py-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={
                    department === "Select All"
                      ? isSelectAllChecked
                      : selected.includes(department)
                  }
                  onChange={() => handleCheckboxChange(department)}
                />
                <label className="ml-2 block text-sm text-gray-700">
                  {department}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

DepartmentDropdown.propTypes = {
  selectedDepartments: PropTypes.array.isRequired,
  onDepartmentsChange: PropTypes.func.isRequired,
  clearFilter: PropTypes.bool.isRequired,
};

export default DepartmentDropdown;
