import React from "react";
import PropTypes from "prop-types";

const Tabs = ({ tabsData, activeTab, onTabChange }) => {
  return (
    <div className="flex w-full justify-start items-center gap-4">
      {tabsData?.map((tab) => (
        <button
          key={tab.name}
          className={`relative capitalize w-fit h-fit min-w-fit flex font-bold text-base  ${
            activeTab === tab.name
              ? ` text-[#031B59] after:w-[25%] after:absolute after:h-1
               after:bg-[#031B59] after:left-0 after:top-[1.61rem]`
              : "text-[#686868]"
          }`}
          onClick={() => onTabChange(tab.name)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default Tabs;
