import Card from "component/common/Card";
import Graph from "component/common/Graph";
import Switch from "component/common/Switch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { formatNumberToK } from "utils/CommonFunctions";

const DashboardRevenueGraph = () => {
  const { t } = useTranslation();
  const {revenue } = useSelector((state) => state.dashboardReducers);
  const { monthlyRevenue } = useSelector((state) => state.dashboardReducers);

  const getTotalRevenue = (givenRevenueData) => {
    let totalEarnings = 0;
    let totalExpenses = 0;
    let totalRevenue = 0;

    givenRevenueData?.forEach((total) => {
      totalEarnings += total?.Earnings;
      totalExpenses += total?.Expenses;
      totalRevenue += total?.Revenue;
    });

    return {
      earnings: formatNumberToK(totalEarnings),
      expenses: formatNumberToK(totalExpenses),
      revenues: formatNumberToK(totalRevenue),
    };
  };

  const [graphArrState, setGraphArrState] = useState({
    revenue: true,
    clients: true,
    workingHours: true,
  });

  const [revenueData, setRevenueData] = useState([
    {
      title: "Revenue",
      id: "revenues",
      data: "---",
      percent: "+7.0%",
      color: "#978FED",
    },
    {
      title: "Earnings",
      id: "earnings",
      data: "---",
      percent: "+4.0%",
      color: "#EE89DF",
    },
    {
      title: "Expenses",
      id: "expenses",
      data: "---",
      percent: "+11.4%",
      color: "#F6CF7D",
    },
  ]);
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    if (name === "revenue") {
      let revenueState = checked ? revenue : monthlyRevenue;
      const updatedRevenue = getTotalRevenue(revenueState);
      setRevenueData((prevData) =>
        prevData.map((prevObj) => ({
          ...prevObj,
          data: updatedRevenue[prevObj.id],
        }))
      );
    }
    setGraphArrState((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    if (revenue.length > 0)
      setRevenueData([
        {
          title: "Revenue",
          id: "revenues",
          data: getTotalRevenue(revenue).revenues || "---",
          percent: "+7.0%",
          color: "#978FED",
        },
        {
          title: "Earnings",
          id: "earnings",
          data: getTotalRevenue(revenue).earnings || "---",
          percent: "+4.0%",
          color: "#EE89DF",
        },
        {
          title: "Expenses",
          id: "expenses",
          data: getTotalRevenue(revenue).expenses || "---",
          percent: "+11.4%",
          color: "#F6CF7D",
        },
      ]);
  }, [revenue]);

  return (
    <div className="w-full col-span-2 flex">
      <Card id={"revenueCard"} cardClass={"flex flex-col w-full h-[24rem]"}>
        <div className="flex justify-betwen items-center w-[95%] m-[20px] ">
          <div className="w-full flex items-center justify-start">
            <h3 className="text-xl lg:text-[1.125rem] font-semibold text-[#031B59]">
              {t("graph_revenue")}
            </h3>
          </div>

          <Switch
            handleToggle={handleToggle}
            name={"revenue"}
            labelLeft={t("monthly")}
            labelRight={t("overview")}
            checkedValue={graphArrState.revenue}
          />
        </div>

        <div className="flex h-full">
          {graphArrState.revenue ? (
            <Graph type={"RevenueGraph"} data={revenue} />
          ) : (
            <Graph type={"RevenueGraph"} data={monthlyRevenue} barSize={15} />
          )}
          <div className="flex flex-col justify-center ml-[30px]">
            <div className="flex flex-col gap-4 justify-between h-fit">
              {revenueData?.map((value) => (
                <div className="flex flex-col gap-3" key={value}>
                  <div className="flex flex-row items-center w-[5.43rem]">
                    <FaCircle color={value.color} />
                    <div className="lg:text-md sm:text-sm font-semibold text-[#7A7A7A] font-[Roboto] pl-2">
                      {value.title}
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-[110px]">
                    <div className="lg:text-base sm:text-2xl text-[black]
                     whitespace-nowrap overflow-hidden text-ellipsis">
                      {value.data}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </Card>
    </div>
  );
};

export default DashboardRevenueGraph;
