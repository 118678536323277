import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  setFetchSuccess,
  setSalary,
  setSalaryStructure,
  setSuccess
} from "redux/actions/action";
import {
  formatSalaryAttributes,
  formatSalaryListing
} from "redux/selector/Admin/salary";

export const fetchSalaryInfo =
  (month, setIsLoading, status) => async (dispatch) => {
    const year = new Date().getFullYear();
    const period = `${year}-${month + 1}`;
    const path = apiUrl.salarySlips + `?Status=${status}?page=5&per_page=300&month=${period}`;

    try {
      setIsLoading(true);
      const { response } = await Helper.get(path);
      const filteredData = formatSalaryListing(response, dispatch);
      dispatch(setSalary(filteredData));
      setIsLoading(false);
    } catch (error) {
      ToastServices.showToast({
        message: "Error during fetch data !",
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const fetchSalaryAttributes = (setIsLoading) => async (dispatch) => {
  try {
    setIsLoading(true);
    const path = apiUrl.salaryAttributes;
    const { response } = await Helper.get(path);
    const filteredData = formatSalaryAttributes(response);
    dispatch(setSalaryStructure(filteredData));
    setIsLoading(false);
  } catch (error) {
    ToastServices.showToast({
      message: "Error during fetch data !",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const AddAdditionalField = (id, jsonObj) => async (dispatch) => {
  try {
    const path = `${apiUrl.salarytypes}/${id}`;
    await Helper.put(jsonObj, path);
    dispatch(setFetchSuccess(true));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: 'error',
    });
  }
};

export const UpdateSalarydata = (id, jsonObj) => async (dispatch) => {
  try {
    const path = `salary_infos/${id}`;

    const {status} = await Helper.put(jsonObj, path);
    if(status === 200 || status === 201){
      ToastServices.showToast({
        message: "Details has been Updated",
        type: 'success',
      });
    }
    dispatch(setSuccess(true));
  } catch (error) {

    ToastServices.showToast({
      message: error,
      type: 'error',
    });
  }
};

