import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { RiEdit2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UpdatePermmisions, fetchPermissionsByRoleId } from 'redux/appThunk/Admin/permission';

const ViewRoleLeftPermission = ({ newAllPermission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editState, setEditState] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [defaultPermission, setDefaultPermission] = useState([]);
  const { AllPermissions } = useSelector((state) => state.permissionReducer);
  const filterAtLeastOneTrue = (response) => {
    return response.filter(obj => Object.values(obj.actions).some(value => value === true));
  };
  const jsonObj = {
    name: newAllPermission.name,
    description: newAllPermission.description,
    permissions: defaultPermission,
  };
  const filteredResponse = filterAtLeastOneTrue(AllPermissions?.requiredResponse);

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    dispatch(fetchPermissionsByRoleId(id));
  }, [editState]);

  useEffect(() => {
    if (AllPermissions?.requiredResponse) {
      const selectedPermissions = [];

      AllPermissions.requiredResponse.forEach((permission) => {
        Object.keys(permission.actions).forEach((action) => {
          if (permission.actions[action]) {
            selectedPermissions.push(action);
          }
        });
      });
      setDefaultPermission(selectedPermissions);
    }
  }, [AllPermissions]);

  const handleSelectUser = (action, isChecked) => {
    setDefaultPermission((prevPermissions) => {
      if (isChecked) {
        return [...prevPermissions, action];
      } else {
        return prevPermissions.filter((perm) => perm !== action);
      }
    });
  };

  const handleEdit = () => {
    setEditState(true);
  };

  const handleCancel = () => {
    setEditState(false);
  };

  const handleUpdate = async () => {
    if (defaultPermission?.length > 0) {
      await dispatch(UpdatePermmisions(jsonObj, id, setSubmitOnce));
      await setEditState(false);
    }
  };

  const handleParentCheck = (data) => {
    const actionArr = Object.keys(data.actions);
    let val = true;
    actionArr?.forEach((item) => {
      if (!defaultPermission?.includes(item)) {
        val = false;
      }
    });

    return val;
  };

  const handleParentOnchange = (isFunction, data) => {
    const actionArr = Object.keys(data.actions);
    if (isFunction) {
      const dataIncludes = defaultPermission?.filter((items) => !actionArr?.includes(items));
      setDefaultPermission(dataIncludes);
    } else {
      const newPermissions = [...defaultPermission, ...actionArr.filter((item) => !defaultPermission.includes(item))];
      setDefaultPermission(newPermissions);
    }
  };

  const trueForState = (editState ? AllPermissions?.requiredResponse : filteredResponse) || [];

  return (
    <div className='w-full rounded-lg border p-3'>
      <div className='flex justify-between items-center '>
        <h1 className='font-bold text-[#031b59] text-[1.2rem]'>
          {t('controls')}
          <span className='text-[0.9rem] font-normal text-black ml-1'>({t('keepOne')})</span>
        </h1>
        {editState ? (
          <div className='flex gap-3 items-center'>
            <button className='border h-[2.1rem] px-2 flex items-center justify-center rounded-xl'
              onClick={handleCancel}
            >
              {t('cancel')}
            </button>
            {
              submitOnce ? (
                <button className='border h-[2.1rem] bg-[#031b59] w-[5rem] px-2 flex items-center justify-center rounded-xl'>
                  <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
                </button>
              ) : (
                <button className='border w-[5rem] text-white bg-[#031b59] h-[2.1rem] px-2 flex items-center justify-center rounded-xl'
                  onClick={handleUpdate}
                >
                  {t('update')}
                </button>
              )
            }
          </div>
        ) :
          (<div className='text-[1.2rem] cursor-pointer'
            onClick={handleEdit}
          ><RiEdit2Line /></div>)}
      </div>
      <div className='mt-2'>
        <div className='overflow-x-auto custom_scroll h-[74vh] overflow-y-scroll'>
          <table className="w-full p-3 bg-white rounded-lg text-left ">
            <thead className=" text-left z-10 left-0 sticky top-0">
              <tr
                className="max-h-[3.125rem] p-2 text-[#686868]
                text-sm w-full font-normal bg-[#F2F6FF] border-collapse left-0 z-30"
              >
                <th
                  className="w-full max-w-[18rem]
                  max-h-[3.125rem] h-full px-2 py-4 bg-[#F2F6FF] border border-gray-300"
                >{t("permissions")}
                </th>
                <th
                  className=" min-w-[14rem] max-h-[3.125rem] h-full p-2 border border-gray-300 bg-[#F2F6FF]"
                >
                  <div className='relative'>
                    <div className=""
                    >
                      <h1>{t("name")}</h1>
                      <p className='text-[1rem] text-black capitalize'>
                        {newAllPermission.name ? newAllPermission.name : "User"}
                      </p>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="p-2 text-sm text-left font-normal w-full ">
              {trueForState?.map((data, index) => {
                return (
                  <Fragment key={`permission-${index}`}>
                    <tr key={`resource-row-${index}`} className='h-[50px]'>
                      <td className='border border-gray-300 bg-[#F9FAFD] '>
                        <div className='flex items-center gap-5 ml-3'>
                          <span>
                            <FaAngleDown />
                          </span>
                          <span className='text-[#313135] text-[1.1rem] font-normal'>
                            {data?.resource}
                          </span>
                        </div>
                      </td>
                      <td className='border border-gray-300 bg-[#F9FAFD]'>
                        <input
                          className="relative h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                        checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                        accent-[#031B59!important] checked:bg-[#031B59!important]
                        checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                        checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                        checked:after:border-l-0 checked:after:border-t-0
                        checked:after:rotate-[45deg] checked:after:border-[0.15rem]
                        checked:after:border-solid checked:after:border-white
                        checked:after:bg-transparent checked:after:content-[''] ml-4 cursor-pointer"
                          onChange={() => handleParentOnchange(handleParentCheck(data), data)}
                          type="checkbox" disabled={!editState} checked={handleParentCheck(data)} />
                      </td>
                    </tr>
                    {Object.keys(data?.actions)?.map((item, level) => {
                      return (
                        <tr key={`action-row-${index}-${level}`} className='h-[50px]'>
                          <td className='border border-gray-300 px-4 text-[1rem]'>
                            {convertToTitleCase(item)}
                          </td>
                          <td className='border border-gray-300'>
                            <input
                              className="relative h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                           accent-[#031B59!important] checked:bg-[#031B59!important]
                           checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                           checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                           checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                           checked:after:rotate-[45deg] checked:after:border-[0.15rem]
                           checked:after:border-l-0 checked:after:border-t-0
                           checked:after:border-solid checked:after:border-white
                           checked:after:bg-transparent checked:after:content-[''] ml-4 cursor-pointer"
                              type="checkbox"
                              disabled={!editState}
                              checked={defaultPermission.includes(item)}
                              onChange={() => handleSelectUser(item, !defaultPermission.includes(item))}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              }
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewRoleLeftPermission;

ViewRoleLeftPermission.propTypes = {
  newAllPermission: PropTypes.any,
};

