import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { addTdsSchema } from "redux/validator/admin/management";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Employeebrief from "../Employee/Employeebrief";
import Svg18 from "svgComponents/Svg18";
import useDebounce from "hooks/useDebounce";
import {
  handleAddTds,
  handleEditTds,
  handleSearchUser,
  handleTdsDetails
} from "redux/appThunk/Admin/management";
import PropTypes from "prop-types";

export default function AddTdsDetails({setOpenPopUp, id, setIsview}) {
  const { t } = useTranslation();
  const managementData = useSelector(
    (state) => state.managementReducer.managementData
  );
  const getSingleCompanyTax = useSelector((state) => state.managementReducer.getSingleCompanyTax);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ids, setId] = useState("");
  // const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [valid, setValid] = useState(true);
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState("");
  const username = useDebounce(userName);
  const [showModal, setShowModal] = useState(false);
  const { searchData } = useSelector((state) => state.managementReducer);
  const initialvalues = {
    empId: "",
    name: "",
    pan: "",
    totalAmount: "",
    tdsAmount: "",
    status: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addTdsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (id) {
          dispatch(handleEditTds({...values,users: {
            id: ids,
          }}, id));
        } else {
          dispatch(handleAddTds(values, ids));
        }
        setSubmitting(false);
        setOpenPopUp(false);
        navigate("/management", { state: { activeTab: 2 } });
      } catch (error) {
        setSubmitting(false);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = Formik;

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  const handleUser = (val) => {
    const tempArray = managementData.filter((data) => data.empId === val);
    setValid(tempArray.length > 0 ? true : false);
  };

  useEffect(() => {
    if (username) {
      dispatch(handleSearchUser(username));
    } else {
      setShow(false);
      setFieldValue("empId", "");
    }
  }, [username]);

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setShow(true);
  };

  const handleEmployeedata1 = (item) => {
    setUserName(item?.fullName);
    setShow(false);
    setFieldValue("empId", item.id);
    setFieldValue("name", item.fullName);
  };

  function BlurHandle() {
    setTimeout(() => {
      setShow(false);
    }, 500);
  }

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  const getColorFromEmail = (email) => {
    const hashCode = email
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };
  useEffect(() => {
    if (id) {
      dispatch(handleTdsDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (getSingleCompanyTax && id) {
      Formik.setValues((value) => ({
        ...value,
        ["empId"]: getSingleCompanyTax?.empId,
        ["name"]: getSingleCompanyTax?.fullName,
        ["pan"]: getSingleCompanyTax?.pan,
        ["totalAmount"]: getSingleCompanyTax?.totalAmount,
        ["tdsAmount"]: getSingleCompanyTax?.tdsAmount,
        ["status"]: getSingleCompanyTax?.status,
      }));
      setUserName(getSingleCompanyTax?.fullName);
    }
  }, [getSingleCompanyTax]);

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
              {id ? t("editTds") : t("addNewTds")}
            </h3>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
              onClick={() => {
                setOpenPopUp(false);
                setIsview(false);
              }}
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              {id ? t("save") : t("add")}
            </button>
          </div>
        </div>
        <FormikProvider value={values}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
          >
            <div className="relative flex flex-col justify-center">
              <label>{t("name")}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={userName}
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                onChange={handleUsernameChange}
                required
                onBlur={BlurHandle}
                onKeyDown={(e) => enterClick(e)}
                disabled={id ? true : false}
              />
              {/* <button
                type="button"
                className="absolute inset-y-11 end-[10px] flex items-center pe-4 pt-1"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button> */}
              {show && (
                <div
                  className="absolute flex justify-start flex-col top-[4.6rem] w-full bg-white
              shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
                >
                  {searchData &&
                  searchData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full px-2 flex items-center space-x-2 cursor-pointer
                        hover:bg-[#031B59] group rounded hover:text-white"
                        onClick={() => handleEmployeedata1(item)}
                      >
                        <div className="w-full flex flex-col">
                          <p
                            className="group-hover:text-white text-[#313131] capitalize p-1"
                            onClick={() => {
                              handleEmployeedata1(item?.fullName);
                              handleUser(item.id);
                              setId(item.id);
                            }}
                          >
                            <div className="flex items-center">
                              <div
                                className="w-9 h-8 rounded-full flex items-center justify-center text-white bg-[#031B59]
                              mr-2"
                                style={{
                                  backgroundColor: getColorFromEmail(
                                    item.email
                                  ),
                                }}
                              >
                                {item.email?.[0]?.toUpperCase()}
                              </div>
                              <Employeebrief
                                email={item.email}
                                names={item.fullName}
                              />
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="empId">{t("empId")}</label>
              <input
                type="text"
                name="empId"
                id="empId"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values?.empId}
                onBlur={handleBlur}
                disabled={id ? true : false}
              />
              {errors.empId && touched.empId ? (
                <p className="text-[red]">{errors.empId}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="pan">{t("pAN")}</label>
              <input
                type="text"
                name="pan"
                id="pan"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values?.pan}
                onChange={(e) => {
                  const uppercaseValue = e.target.value.toUpperCase();
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: uppercaseValue,
                    },
                  });
                }}
                onBlur={handleBlur}
                maxLength={10}
              />
              {errors.pan && touched.pan ? (
                <p className="text-[red]">{errors.pan}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="totalAmount">{t("totalAmount")}</label>
              <input
                type="text"
                name="totalAmount"
                id="totalAmount"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values?.totalAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.totalAmount && touched.totalAmount ? (
                <p className="text-[red]">{errors.totalAmount}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="tdsAmount">{t("tdsAmount")}</label>
              <input
                type="text"
                name="tdsAmount"
                id="tdsAmount"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values?.tdsAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.tdsAmount && touched.tdsAmount ? (
                <p className="text-[red]">{errors.tdsAmount}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="sttatus">{t("status")}</label>
              <div className="relative">
                <select
                  id="sttatus"
                  name="status"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
               rounded bg-white appearance-none"
                  value={values?.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">{t("select")}</option>
                  <option value="Done">{t("done")}</option>
                </select>
                <div className="absolute right-0 top-[14px]">
                  <Svg18 />
                </div>
              </div>
              {errors.status && touched.status ? (
                <p className="text-[red]">{errors.status}</p>
              ) : null}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
AddTdsDetails.propTypes = {
  setOpenPopUp: PropTypes.any,
  id: PropTypes.any,
  setIsview: PropTypes.any,
};
