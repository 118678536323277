export const formatStatusListResponse = (response) => {
  const requiredResponse = response?.statuses?.map((obj) => ({
    id: obj?.id,
    email: obj?.email,
    employeeId: obj?.employee_id,
    fullName: obj?.full_name,
    designation: obj?.designation?.designation || "--",
    statusDate: obj?.tasks[0].status_date,
    billable: obj?.tasks[0].billable || "-",
  }));

  return {
    requiredResponse,
    currentPage: response?.pagination_data?.current_page,
    totalPages: response?.pagination_data?.total_pages,
    totalCount: response?.pagination_data?.total_count,
    perPage: response?.pagination_data?.items_per_page,
  };
};

export const formatStatuslistNameAndDate = (response) => {
  const requiredResponse = [];

  response?.map((obj) => {
    const totalWorkingHours = obj?.tasks?.reduce(
      (total, current) => total + parseFloat(current?.working_hours || 0),
      0
    );
    requiredResponse.push({
      id: obj?.id,
      email: obj?.email,
      employeeId: obj?.employee_id,
      fullName: obj?.full_name,
      designation: obj?.designation?.designation || "--",
      statusDate: obj?.tasks[0]?.status_date,
      workingHours: totalWorkingHours,
      billable: obj?.tasks[0]?.billable || "-",
      description: obj?.tasks[0]?.task_description || "--",
      projectName: obj?.tasks[0]?.project_name || "--",
      taskStatus: obj?.tasks[0]?.task_status || "--",
      task_image: obj?.tasks[0]?.task_image || "--",
    });
  });

  return requiredResponse;
};

export const formatTimesheetData = (response) =>
  response.map((timesheet) => ({
    id: timesheet.id,
    employeeId: timesheet.user_id,
    totalWorkingHours: timesheet.total_working_hours,
    totalBillingHours: timesheet.total_billing_hours,
    statusDate: timesheet?.status_date,
    tasks: timesheet.tasks,
  }));

export const formatStatuslist = ({
  statuses,
  totalCount,
  totalPages,
  currentPage,
}) => {
  return {
    data: statuses?.map((obj) => ({
      id: obj?.tasks[0]?.id,
      statusId: obj?.id,
      email: obj?.tasks[0]?.email,
      employeeId: obj?.tasks[0]?.employee_id,
      fullName: obj?.tasks[0]?.full_name,
      designation: obj?.designation?.designation || "--",
      statusDate: obj?.tasks[0]?.status_date,
      workingHours: obj?.tasks[0]?.working_hours || "--",
      billable: obj?.tasks[0]?.billable || "-",
      description: obj?.tasks[0]?.task_description || "--",
      projectName: obj?.tasks[0]?.project_name || "--",
      taskStatus: obj?.tasks[0]?.task_status || "--",
      task_image: obj?.tasks[0]?.task_images_url || "--",
      projectId: obj?.tasks[0]?.project_id,
    })),
    currentPage,
    totalPages,
    totalCount,
  };
};

export const formatStatuslistData = (response) => {
  const data = [];

  response?.users?.map((obj) =>
    data.push({
      employeeId: obj.id,
      statusId: obj?.statuses[0]?.id,
      fullName: obj.full_name,
      designation: obj?.designation || "--",
      workingHours: obj.statuses[0]?.tasks[0]?.working_hours || "--",
      statusDate: obj?.statuses[0]?.status_date,
      billable: obj?.statuses?.task?.billable || "-",
      description: obj?.statuses[0]?.tasks[0]?.task_description || "--",
      projectName: obj?.statuses[0]?.tasks[0]?.project_name || "--",
      taskStatus: obj?.statuses[0]?.tasks[0]?.task_status || "--",
      task_image: obj?.statuses[0]?.tasks[0]?.task_images_url || "--",
      projectId: obj?.statuses[0]?.tasks[0]?.project_id,
    })
  );

  return {
    data,
    currentPage: response?.pagination?.current_page,
    totalPages: response?.pagination?.total_pages,
    totalCount: response?.pagination?.total_count,
    per_page: 1,
  };
};
