import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoaderComp from "component/loader/LoaderComp";
import Paginate from "component/admin/Employee/Paginate";
import Employeebrief from "component/admin/Employee/Employeebrief";
import { fetchMyteam, fetchUserData } from "redux/appThunk/Employee/myTeam";
import { GoSearch } from "react-icons/go";
import { awsURL } from "utils/Constants";

export default function TeamListing() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [teamName, setTeamName] = useState("");
  const [search, setSearch] = useState(false);
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const { MyTeamInfo, pageCount, isLoading } = useSelector(
    (state) => state.MyTeamsReducer
  );
  const user = useSelector((state) => state.profileReducer.profileData);

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  useEffect(() => {
    if (user) {
      let debounceTimer;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        dispatch(
          fetchMyteam(currentPage, itemsPerPage, teamName, user.designationId)
        );
      }, 1000);

      return () => {
        clearTimeout(debounceTimer);
      };
    }
  }, [user, currentPage, teamName]);

  const handleUsernameChange = (e) => {
    setTeamName(e.target.value);
  };

  return (
    <div
      className="w-[96%] h-fit min-h-[530px] bg-white space-y-4 flex flex-col
      p-5 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {t("myTeams")}
          </h2>
        </div>
        <div className="flex items-center justify-center space-x-4">
          {search ? (
            <div
              className="flex mr-[14.5%] w-[14.4375rem]
            h-[3.0625rem] rounded-[2.5rem] border-[1.5px] border-[#E2E8F0]"
            >
              <div className="flex items-center p-2 justify-center text-[#A1A1A1]">
                <input
                  value={teamName}
                  onChange={handleUsernameChange}
                  placeholder="Search"
                  className="outline-none w-[11.5rem] ml-2"
                />
                <GoSearch className="w-[1.375rem] h-[1.375rem]" />
              </div>
            </div>
          ) : (
            <button
              onClick={() => setSearch(!search)}
              className="w-[2.688rem] h-[2.688rem] flex items-center
            justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
            >
              <GoSearch className="w-6 h-6 stroke-[#031B59] text-[#A1A1A1]" />
            </button>
          )}
        </div>
      </div>
      {MyTeamInfo && MyTeamInfo?.length === 0 && !isLoading && (
        <div className="w-full h-fit flex  justify-center ">
          <div
            className="text-Roboto text-[#A1A1A1] flex justify-center
            flex-col items-center leading-snug "
          >
            <div>
              <img
                className="h-[18rem] mt-20"
                src={`${awsURL}/images/staus.jpg`}
                alt="not found"
              />
            </div>
            <div className="mt-5">
              <span>{t("noDataFoundForTeamListing")}</span>
            </div>
          </div>
        </div>
      )}
      {!isLoading && MyTeamInfo?.length > 0 && (
        <table className="h-full w-full p-5 bg-white rounded-lg text-left">
          <thead className="border flex-0 text-left p-2">
            <tr className="h-[3.125rem] p-2 text-[#686868] text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0]">
              <th className="min-w-[5.5rem] p-2">{t("employeeName")}</th>
              <th className="min-w-[5.5rem] p-2 text-center">
                {t("designation")}
              </th>
              <th className="min-w-[5.5rem] p-2 text-center">{t("jobType")}</th>
              <th className="min-w-[5.5rem] p-2 text-center">{t("status")}</th>
            </tr>
          </thead>
          <tbody className="p-2 text-sm text-left font-normal flex-0">
            {!isLoading &&
              MyTeamInfo != null &&
              MyTeamInfo?.map((data, i) => (
                <tr
                  className="h-[3.125rem] even:bg-[#F8FAFC] text-[#23275E] border border-[#E2E8F0]"
                  key={i}
                >
                  <td className="min-w-[18.224rem] p-2">
                    <div className="flex items-center z-10">
                      <Employeebrief
                        names={data?.fullName}
                        userName={data?.fullName}
                        userEmail={data?.email}
                        email={data?.email}
                        designation={data?.designation?.designation}
                      />
                    </div>
                  </td>
                  <td className="min-w-[5.5rem] p-2 text-center">
                    {data?.designation?.designation || "---"}
                  </td>
                  <td className="min-w-[5.5rem] p-2 text-center">
                    {data?.jobType || "---"}
                  </td>
                  <td className="min-w-[5.5rem] p-2 text-center">
                    {data?.status || "---"}
                  </td>
                </tr>
              ))}
          </tbody>
          {isLoading && (
            <tr>
              <td colSpan="7" className=" items-center mt-3">
                <LoaderComp />
              </td>
            </tr>
          )}
        </table>
      )}

      {!isLoading && MyTeamInfo?.length > 0 && (
        <div className="w-full h-16 bg-white flex justify-between items-center ">
          {MyTeamInfo?.length > 0 && (
            <>
              <div className="text-[#031B59] font-medium">
                Showing {currentPage} of {pageCount}
              </div>
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={2}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
