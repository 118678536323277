// export default DashboardOnboarding;
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {onBoarding } from "redux/appThunk/Admin/dashboard";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";

const DashboardOnboarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const selector = useSelector((state) => state.dashboardReducers.setonboarding);

  useEffect(() => {
    setLoading(true);
    dispatch(onBoarding()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="w-full flex flex-col gap-1 p-5 h-[17.5rem] overflow-hidden relative">

      {loading ? (
        <div data-testid="loader" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoaderComp />
        </div>
      ) :
        selector.length === 0 ? (
          <div className="text-slate-500">{t("noOnbaord")}</div>
        ) : (
          selector.map((item, id) => (
            <div key={id}>
              <div className="flex gap-2 items-center">
                {item?.img ? (
                  <img
                    className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
                    src={item?.img}
                    alt="Profile Avatar"
                  />
                ) : (
                  <ProfileAvtar
                    height="3rem"
                    width="3rem"
                    name={item.full_name}
                  />
                )}
                <div>
                  <div className="tab flex flex-col items-start  ">
                    <h3 className="text-[#031B59] capitalize text-sm font-medium">
                      {item.full_name}
                    </h3>
                    <p className="text-xs relative bottom-1 capitalize text-[#A1A1A1]">
                      {item.emp_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}

    </div>
  );
};

export default DashboardOnboarding;
