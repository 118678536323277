/* eslint-disable max-len */
import React from "react";

const ApplyLeave = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.24805 12.7813H12.3203C12.6439 12.7813 12.9063 12.5189 12.9063 12.1953V11.6533C12.9063 10.7366 12.433 9.92872 11.7183 9.45985C11.9218 9.17301 12.042 8.82305 12.042 8.44532C12.042 7.47606 11.2534 6.6875 10.2842 6.6875C9.31493 6.6875 8.52637 7.47606 8.52637 8.44532C8.52637 8.82305 8.64653 9.17301 8.85004 9.45985C8.13535 9.92872 7.66211 10.7366 7.66211 11.6533V12.1953C7.66211 12.5189 7.92445 12.7813 8.24805 12.7813ZM9.69825 8.44532C9.69825 8.12223 9.9611 7.85938 10.2842 7.85938C10.6073 7.85938 10.8701 8.12223 10.8701 8.44532C10.8701 8.7684 10.6073 9.03125 10.2842 9.03125C9.9611 9.03125 9.69825 8.7684 9.69825 8.44532ZM10.2842 10.2031C11.0691 10.2031 11.7104 10.83 11.7337 11.6094H8.83461C8.85793 10.83 9.49922 10.2031 10.2842 10.2031Z"
        fill="#ED6E0F"
      />
      <path
        d="M17.3145 3.41446H13.2227C13.0741 2.61078 12.3686 2 11.5226 2H9.04392C8.1979 2 7.49231 2.61078 7.34376 3.41446H3.25195C2.92836 3.41446 2.66602 3.6768 2.66602 4.00039V21.4141C2.66602 21.7377 2.92836 22 3.25195 22H17.3145C17.6381 22 17.9004 21.7377 17.9004 21.4141V4.00039C17.9004 3.6768 17.6381 3.41446 17.3145 3.41446ZM8.48634 3.72942C8.48634 3.422 8.73646 3.17188 9.04388 3.17188H11.5225C11.83 3.17188 12.0801 3.422 12.0801 3.72942V4.34375H8.48634V3.72942ZM16.7285 20.8282H3.83789V4.58633H7.31446V4.92969C7.31446 5.25329 7.57681 5.51563 7.9004 5.51563H12.666C12.9896 5.51563 13.252 5.25329 13.252 4.92969V4.58633H16.7285V20.8282Z"
        fill="#ED6E0F"
      />
      <path
        d="M22.0801 5.16406H19.7363C19.4127 5.16406 19.1504 5.42641 19.1504 5.75V19.0703C19.1504 19.1613 19.1716 19.251 19.2122 19.3324L20.3841 21.6761C20.5798 22.1016 21.2366 22.1015 21.4323 21.6761L22.6041 19.3324C22.6449 19.251 22.666 19.1613 22.666 19.0703V5.75C22.666 5.42641 22.4037 5.16406 22.0801 5.16406ZM21.4941 6.33594V7.50782H20.3223V6.33594H21.4941ZM20.9082 20.1039L20.3223 18.932V8.67969H21.4941V18.932L20.9082 20.1039Z"
        fill="#ED6E0F"
      />
      <path
        d="M7.31034 17.3474L6.41604 18.2417L6.01002 17.8357C5.78123 17.6069 5.41022 17.6069 5.18139 17.8357C4.95256 18.0644 4.95256 18.4355 5.18139 18.6643L6.0017 19.4846C6.23053 19.7134 6.60151 19.7134 6.83034 19.4846L8.13893 18.176C8.36776 17.9472 8.36776 17.5762 8.13893 17.3474C7.91014 17.1186 7.53913 17.1186 7.31034 17.3474Z"
        fill="#ED6E0F"
      />
      <path
        d="M7.31034 14.0662L6.41604 14.9604L6.01002 14.5544C5.78123 14.3256 5.41022 14.3256 5.18139 14.5544C4.95256 14.7832 4.95256 15.1542 5.18139 15.383L6.0017 16.2033C6.23053 16.4322 6.60151 16.4322 6.83034 16.2033L8.13893 14.8948C8.36776 14.666 8.36776 14.2949 8.13893 14.0661C7.91014 13.8373 7.53913 13.8373 7.31034 14.0662Z"
        fill="#ED6E0F"
      />
      <path
        d="M14.9707 18.2207H9.77539C9.4518 18.2207 9.18945 18.483 9.18945 18.8066C9.18945 19.1302 9.4518 19.3926 9.77539 19.3926H14.9707C15.2943 19.3926 15.5567 19.1302 15.5567 18.8066C15.5567 18.483 15.2943 18.2207 14.9707 18.2207Z"
        fill="#ED6E0F"
      />
      <path
        d="M9.77539 14.9395C9.4518 14.9395 9.18945 15.2018 9.18945 15.5254C9.18945 15.849 9.4518 16.1113 9.77539 16.1113H14.9707C15.2943 16.1113 15.5567 15.849 15.5567 15.5254C15.5567 15.2018 15.2943 14.9395 14.9707 14.9395H9.77539Z"
        fill="#ED6E0F"
      />
    </svg>
  );
};

export default ApplyLeave;
