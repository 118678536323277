import React from "react";
import { useTranslation } from "react-i18next";
import ImageList from "../ImagesList";
import PropTypes from "prop-types";

// timesheet re-usable components
export const InputField = ({
  isLoading,
  className = "mt-1 p-1 text-base w-full",
  ...inputProps
}) =>
  isLoading ? (
    <Shimmer />
  ) : (
    <input type="text" readOnly className={className} {...inputProps} />
  );
InputField.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inputProps: PropTypes.object,
};

export function TaskList({ task }) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center w-full shadow-[0px_0px_10px_0px_rgba(3,27,89,0.10)] rounded-lg">
      <div
        className="grid grid-cols-2 w-fit py-6 px-4
        gap-4 items-start justify-start text-left"
      >
        <div>
          <label
            className="text-[#686868] mx-1 text-base font-medium
                         font-['Roboto'] leading-snug"
          >
            {t("projectName")}
          </label>
          <input
            type="text"
            name="projectName"
            value={task?.project_name || "-"}
            readOnly
            className={`mt-1 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
          />
        </div>
        <div>
          <label
            className="text-[#686868] mx-1 text-base font-medium
  font-['Roboto'] leading-snug"
          >
            {t("totalWorkingHours")}
          </label>
          <input
            type="text"
            name="totalWorkingHours"
            value={task?.working_hours || "-"}
            readOnly
            className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
          />
        </div>
        <div>
          <label
            className="text-[#686868] mx-1 text-base font-medium
  font-['Roboto'] leading-snug"
          >
            {t("status")}
          </label>
          <input
            type="text"
            name="status"
            value={task?.task_status || "-"}
            readOnly
            className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
          />
        </div>
        <div>
          <label
            className="text-[#686868] mx-1 text-base font-medium
  font-['Roboto'] leading-snug"
          >
            {t("billable")}
          </label>
          <input
            type="text"
            name="billable"
            value={task?.billable || "-"}
            readOnly
            className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
          />
        </div>
        <div>
          <label
            className="text-[#686868] mx-1 text-base font-medium
  font-['Roboto'] leading-snug"
          >
            {t("task_description")}
          </label>
          <input
            type="text"
            name="description"
            value={task?.task_description || "-"}
            readOnly
            className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
          />
        </div>
        <div className="flex flex-col col-span-2 justify-center w-full items-start mb-4 border border-slate-200 rounded-[4px] p-2">
          <h3 className="text-sm font-bold"> {t("image")}</h3>
          {task?.task_images_url?.map((img) => (
            <ImageList file={img} imageLink={img} key={img} index={img} />
          ))}
        </div>
      </div>
    </div>
  );
}

TaskList.propTypes = {
  task: PropTypes.shape({
    project_name: PropTypes.string,
    working_hours: PropTypes.string,
    task_status: PropTypes.string,
    billable: PropTypes.string,
    task_description: PropTypes.string,
    task_images_url: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

// shimmer components
export const TaskListShimmer = () => {
  return (
    <div className="flex items-center w-full shadow-[0px_0px_10px_0px_rgba(3,27,89,0.10)] rounded-lg">
      <div
        className="grid grid-cols-2 w-full py-6 px-4
        gap-4 items-start justify-start text-left"
      >
        <div className="col-span-2">
          <div className="animate-pulse bg-gray-300 h-6 w-3/4 rounded-md mt-1 p-1" />
        </div>
        <div>
          <div className="animate-pulse bg-gray-300 h-6 w-full rounded-md mt-1 p-1" />
        </div>
        <div>
          <div className="animate-pulse bg-gray-300 h-6 w-full rounded-md mt-1 p-1" />
        </div>
        <div>
          <div className="animate-pulse bg-gray-300 h-6 w-full rounded-md mt-1 p-1" />
        </div>
        <div>
          <div className="animate-pulse bg-gray-300 h-6 w-full rounded-md mt-1 p-1" />
        </div>
        <div>
          <div className="animate-pulse bg-gray-300 h-6 w-full rounded-md mt-1 p-1" />
        </div>
        <div className="flex flex-col col-span-2 justify-center w-full items-start mb-4">
          <div className="animate-pulse bg-gray-300 h-5 w-1/2 rounded-md mt-1 p-1" />
          <div className="animate-pulse bg-gray-300 h-24 w-full rounded-md mt-1 p-1" />
        </div>
      </div>
    </div>
  );
};
const Shimmer = () => (
  <div
    className="animate-pulse bg-gray-300 bg-gradient-to-r
     from-gray-200 to-gray-300 h-5 w-full rounded-md"
  />
);

// util function
export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};
