import * as Yup from "yup";

const nameRegex = /^(?!\s)(?!.*\s\s)(.*[^\s])?$/;

export const addProjectSchema = Yup.object({
  name: Yup.string()
    .matches(nameRegex, "Remove extra spaces!")
    .required("Please enter project name"),
  description: Yup.string()
    .matches(nameRegex, "Remove extra spaces!")
    .required("Please enter project description"),
  startDate: Yup.string().required("Please enter start date"),
  amount: Yup.number().required("Amount is required"),
  status: Yup.string().required("Status Is required"),
});
