import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const LeaveFilterColumn = ({
  filterTitleFirst,
  filterTitleSecond,
  filterColumn,
  dataFilter,
  dataFilterValue,
  ColumnValue,
  setShowFilter,
  checkBox1,
  checkBox2,
  handleFilterApply,
  handleResetFilters,
  SearchValue,
  checkedResources,
  setCheckedResources,
}) => {
  const [resourceOpen, setResourceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [checkedProjects, setCheckedProjects] = useState(new Set());
  const { t } = useTranslation();
  const menuRef = useRef();
  const handleResourceToggle = () => setResourceOpen(!resourceOpen);
  const handleProjectsToggle = () => setProjectsOpen(!projectsOpen);
  const onClose = () => setShowFilter(false);
  useOnClickOutside(menuRef, onClose);
  const handleResourceChange = (resourceTitle) => {
    setCheckedResources((prevChecked) => {
      const updatedChecked = new Set(prevChecked);
      if (updatedChecked.has(resourceTitle)) {
        updatedChecked.delete(resourceTitle);
      } else {
        updatedChecked.add(resourceTitle);
      }
      checkBox1(Array.from(updatedChecked));

      return updatedChecked;
    });
  };

  const handleProjectChange = (projectTitle) => {
    setCheckedProjects((prevChecked) => {
      const updatedChecked = new Set(prevChecked);
      if (updatedChecked.has(projectTitle)) {
        updatedChecked.delete(projectTitle);
      } else {
        updatedChecked.add(projectTitle);
      }
      checkBox2(Array.from(updatedChecked));

      return updatedChecked;
    });
  };

  return (
    <div ref={menuRef} className="p-4 bg-white border ml-[-17rem] w-[20rem] rounded-md shadow-md max-h-[67vh] overflow-y-auto custom_scroll">
      <div className="flex justify-between items-center">
        <div className="text-[#031B59] text-xl font-bold">Filters</div>
        <div onClick={onClose} className="text-xl"><IoClose /></div>
      </div>
      <div className="">
        {filterTitleFirst && dataFilter && (
          <div className="border p-2 cursor-pointer flex justify-between items-center" onClick={handleResourceToggle}>
            <div>{filterTitleFirst}</div>
            <div><IoIosArrowDown /></div>
          </div>
        )}
        {resourceOpen && (
          <div className="p-2 my-1 border">
            {SearchValue && (
              <div>
                <input type="text" placeholder="Search" className="p-2 w-full border rounded-md mb-2" />
              </div>
            )}
            <div className="p-2 my-1">
              {dataFilter?.map((resource) => (
                <div key={resource.title} className="flex items-center mb-2">
                  <input
                    id={resource.title}
                    type="checkbox"
                    className="mr-2"
                    name={resource.title}
                    checked={checkedResources instanceof Set && checkedResources.has(resource.title)}
                    onChange={() => handleResourceChange(resource.title)}
                  />
                  <label className="text-blue-950" htmlFor={resource.title}>{resource.title}</label>
                </div>
              ))}
            </div>
          </div>
        )}
        {filterTitleSecond && dataFilterValue && (
          <div>
            <div className="border p-2 cursor-pointer flex justify-between items-center" onClick={handleProjectsToggle}>
              <div>{filterTitleSecond}</div>
              <div><IoIosArrowDown /></div>
            </div>
            {projectsOpen && (
              <div className="p-2 my-1 border">
                <div className="p-2 my-1">
                  {dataFilterValue?.map((project) => (
                    <div key={project.title} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name={project.title}
                        checked={checkedProjects.has(project.title)}
                        onChange={() => handleProjectChange(project.title)}
                      />
                      <label className="text-blue-950" htmlFor={project.title}>{project.title}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-2">
        <h1 className="font-semibold text-blue-950">{filterColumn}</h1>
        <div className="p-2 my-1 max-h-fit">
          {ColumnValue?.map((project) => (
            <div key={project.title} className="flex items-center mb-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={checkedProjects.has(project.title)}
                onChange={() => handleProjectChange(project.title)}
              />
              <label className="text-blue-950">{project.title}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md" onClick={handleResetFilters}>
          <MdRefresh />
        </button>
        <button onClick={handleFilterApply} className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59] rounded-md">
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};
LeaveFilterColumn.propTypes = {
  setShowFilter: PropTypes.func.isRequired,
  handleFilterApply: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filterTitleFirst: PropTypes.string.isRequired,
  filterTitleSecond: PropTypes.string.isRequired,
  filterColumn: PropTypes.string.isRequired,
  dataFilter: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })),
  dataFilterValue: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })),
  ColumnValue: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })),
  checkBox1: PropTypes.func.isRequired,
  checkBox2: PropTypes.func.isRequired,
  SearchValue: PropTypes.func.isRequired,
  checkedResources: PropTypes.instanceOf(Set).isRequired,
  setCheckedResources: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
};

export default LeaveFilterColumn;
