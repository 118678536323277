import React, { useEffect, useState } from "react";
import { content } from "../../utils/constant/Content";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import swalService from "../../utils/SwalServices";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { personalInformationSchema } from "redux/validator/Profile/personalInfo";
import LoaderComp from "component/loader/LoaderComp";
import Svg18 from "svgComponents/Svg18";
import {
  PersonalProgress,
  Profile,
  getCities,
  profileData,
  updateUserProfile
} from "redux/appThunk/Employee/profile";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "component/common/DatePicker";
import { handleCountryName, handleStateChange, handlecitychange } from "redux/appThunk/Admin/companyProfile";

export default function PersonalInformation({ isEdit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cityFind, setcityFind] = useState("");
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState(false);
  const [submitshow, setSubmitshow] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [handelvalue, setHandelValue] = useState(false);
  // const [setTwoNameCountry] = useState('');
  const { id } = useParams();
  const user = useSelector((state) => state.profileReducer.profileData);
  const { statename, countryname } = useSelector((state) => state.CompanyProfileReducer);
  const Progress = useSelector((state) => state.profileReducer.personalStatus);
  const cityname = useSelector((state) => state.CompanyProfileReducer.cityname);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  useEffect(() => {
    dispatch(handleCountryName());
  }, [country]);
  useEffect(() => {
    dispatch(handleStateChange(country));
  }, [country]);
  useEffect(() => {
    dispatch(handlecitychange(state));
  }, [state]);

  useEffect(() => {
    if (id) {
      // dispatch(handleCountryName());
      dispatch(profileData(id, setLoading));
      dispatch(Profile(id));
      dispatch(PersonalProgress(id));
    } else {
      // dispatch(handleCountryName());
      dispatch(Profile(userData?.id));
      dispatch(profileData(userData?.id, setLoading));
      dispatch(PersonalProgress(userData?.id));
    }
  }, [userData?.token, handle]);

  const initialValues = {
    email: user?.email,
    fullName: user?.full_name,
    fatherName: user?.father_name,
    motherName: user?.mother_name,
    linkedinProfile: user?.linkedin_profile,
    contactNo: user?.contact_no,
    personalEmail: user?.personal_email,
    blood_group: user?.blood_group,
    maritalStatus: user?.marital_status,
    dateOfBirth: user?.date_of_birth,
    gender: user?.gender,
    city: user?.city,
    state: user?.state,
    country: user?.country,
    pincode: user?.pincode,
    address: user?.address,
    emergencyContactNo: user?.emergency_contact_no,
  };

  const Formik = useFormik({
    enableReinitialize: true,
    validationSchema: personalInformationSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      const jsonObj = {
        user: {
          email: values?.email,
          full_name: values?.fullName,
          father_name: values?.fatherName,
          mother_name: values?.motherName,
          linkedin_profile: values?.linkedinProfile,
          contact_no: values?.contactNo,
          personal_email: values?.personalEmail,
          blood_group: values?.blood_group,
          marital_status: values?.maritalStatus,
          date_of_birth: values?.dateOfBirth,
          gender: values?.gender,
          country: values?.country,
          city: values?.city,
          state: values?.state,
          pincode: values?.pincode,
          address: values?.address,
          emergency_contact_no: values?.emergencyContactNo,
        },
      };
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update Details!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === "admin") {
              id = user?.id;
            }
            else if (userData?.role === "employee") {
              id = userData?.id;
            }
            dispatch(updateUserProfile(id, jsonObj, setHandle, setSubmitshow));
          }
        });
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    dispatch(getCities(cityFind, values?.state));
  }, [cityFind, values?.state]);

  useEffect(() => {
    setcityFind(user?.state);
  }, [user]);

  const bloodGroup = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

  function handleOnChange(e) {
    handleChange(e);
    setSubmitshow(true);
    setHandelValue(true);

    if (e.target.name === "blood_group") {
      setFieldValue("blood_group", e.target.value);
    } else if (e.target.name === "state") {
      setcityFind(e.target.value);
      setFieldValue("state", e.target.value);
    } else if (e.target.name === "city") {
      setFieldValue("city", e.target.value);
    }

  }

  function handleCountryChange(e) {

    setCountry(e.target.value);
    setFieldValue(
      "country", e.target.value
    );
    setSubmitshow(true);
  }

  function handleState(e) {
    setState(e.target.value);
    setFieldValue("state", e.target.value);
    setSubmitshow(true);
  }

  function handleCity(e) {
    setFieldValue("city", e.target.value);
    setSubmitshow(true);
  }

  // const handleClickChange = (val) => {
  //   setTwoNameCountry(val);
  // };
  useEffect(() => {
    setCountry(user?.country);
    setState(user?.state);
  }, [user]);

  return (
    <div className="w-[96%] h-[fit] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]">
      {loading ? (
        <LoaderComp />
      ) : (
        <>
          <div className="w-full h-[6rem] p-2 bg-[#F2F6FF] flex items-center gap-6 shadow-lg rounded-md">
            <div className="w-[5rem] h-[5rem] flex justify-center items-center">
              <CircularProgressbar
                value={Progress}
                text={Progress + "%"}
                styles={buildStyles({
                  rotation: 1.0,
                  strokeLinecap: "butt",
                  textSize: "22px",
                  pathColor: `#031B59`,
                  textColor: "#031B59",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                  counterClockwise: false,
                  strokeWidth: "90px",
                })}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="capitalize text-[#031B59]">
                {Progress === 100 ? (
                  <h1>{t("progress1")}</h1>
                ) : (
                  <h1>{t("progress2")}</h1>
                )}
              </div>
              <div>
                {Progress !== 100 ? <h2>{t("progressBarText")}</h2> : null}
              </div>
            </div>
          </div>
          <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col space-y-2">
              <label htmlFor="full_name" className="text-[#313135]">
                {t("name")}
              </label>
              <input
                type="text"
                name="fullName"
                id="full_name"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.fullName : initialValues?.fullName}
                onChange={handleOnChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  const inputValue = e.target.value + inputChar;
                  if (pattern.test(inputChar) || (inputValue.length === 1 && inputChar === ' ')) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.fullName && touched.fullName ? (
                <p className="text-[red]">{errors.fullName}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="father_name" className="text-[#313135]">
                {t("fathersName")}
              </label>
              <input
                type="text"
                name="fatherName"
                id="father_name"
                value={handelvalue ? values?.fatherName : initialValues?.fatherName}
                onChange={handleOnChange}
                onBlur={handleBlur}
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  const inputValue = e.target.value + inputChar;
                  if (pattern.test(inputChar) || (inputValue.length === 1 && inputChar === ' ')) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.fatherName && touched.fatherName ? (
                <p className="text-[red]">{errors.fatherName}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="mother_name" className="text-[#313135]">
                {t("mothersName")}
              </label>
              <input
                type="text"
                name="motherName"
                id="mother_name"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.motherName : initialValues?.motherName}
                onChange={handleOnChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  const inputValue = e.target.value + inputChar;
                  if (pattern.test(inputChar) || (inputValue.length === 1 && inputChar === ' ')) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.motherName && touched.motherName ? (
                <p className="text-[red]">{errors.motherName}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="personal_email" className="text-[#313135]">
                {t("personalEmail")}
              </label>
              <input
                type="text"
                id="personalEmail"
                name="personalEmail"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.personalEmail : initialValues?.personalEmail}
                onChange={handleOnChange}
                onBlur={handleBlur}
              />
              {handelvalue && (errors.personalEmail && touched.personalEmail ? (
                <p className="text-[red]">{errors.personalEmail}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="email" className="text-[#313135]">
                {t("officialEmail")}
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.email : initialValues?.email}
                onChange={handleOnChange}
                onBlur={handleBlur}
                disabled={true}
              />
              {handelvalue && (errors.email && touched.email ? (
                <p className="text-[red]">{errors.email}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="contact_no" className="text-[#313135]">
                {t("contactNo")}
              </label>
              <input
                type="text"
                id="contact_no"
                name="contactNo"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.contactNo : initialValues?.contactNo}
                onChange={handleOnChange}
                onBlur={handleBlur}
                maxLength="10"
                max="10"
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.contactNo && touched.contactNo ? (
                <p className="text-[red]">{errors.contactNo}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="emergency_contact_no" className="text-[#313135]">
                {t("emergencycontactNo")}
              </label>
              <input
                type="text"
                id="emergency_contact_no"
                name="emergencyContactNo"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.emergencyContactNo : initialValues?.emergencyContactNo}
                onChange={handleOnChange}
                onBlur={handleBlur}
                maxLength="10"
                max="10"
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.emergencyContactNo && touched.emergencyContactNo ? (
                <p className="text-[red]">{errors.emergencyContactNo}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="gender" className="text-[#313135]">
                {t("gender")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full p-[10px] border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                  type="number"
                  id="gender"
                  value={handelvalue ? values?.gender : initialValues?.gender || ""}
                  name="gender"
                  onChange={handleOnChange}
                >
                  <option value="" disabled>Select Gender</option>
                  <option value={content.female}>{content.female}</option>
                  <option value={content.male}>{content.male}</option>
                  <option value={content.others}>{content.others}</option>
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {handelvalue && (errors.gender && touched.gender ? (
                <p className="text-[red]">{errors.gender}</p>
              ) : null)}
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="date_of_birth" className="text-[#313135]">
                {t("dOB")}
              </label>
              <DatePicker
                name="dateOfBirth"
                value={handelvalue ? values?.dateOfBirth : initialValues?.dateOfBirth}
                handleBlur={handleBlur}
                handleChange={handleOnChange}
                styles="h-[2.625rem]"
              />
              {handelvalue && (errors.dateOfBirth && touched.dateOfBirth ? (
                <p className="text-[red]">{errors.dateOfBirth}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="blood_group" className="text-[#313135]">
                {t("bloodGroup")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full  p-[10px] border border-[#E2E8F0]
               bg-white rounded text-[#191919] appearance-none"
                  type="text"
                  id="blood_group"
                  name="blood_group"
                  value={handelvalue ? values?.blood_group :
                    initialValues?.blood_group || ""}
                  onChange={handleOnChange}
                >
                  <option value="" disabled>Select Blood Group</option>
                  {bloodGroup.length > 0 &&
                    bloodGroup?.map((bg, index) => (
                      <option value={bg} key={index}>
                        {bg}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {handelvalue && (errors.blood_group && touched.blood_group ? (
                <p className="text-[red]">{errors.blood_group}</p>
              ) : null)}
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="address" className="text-[#313135]">
                {t("address")}
              </label>
              <input
                type="text"
                id="address"
                name="address"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.address : initialValues?.address}
                onChange={handleOnChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="pincode" className="text-[#313135]">
                {t("pinCode")}
              </label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={handelvalue ? values?.pincode : initialValues?.pincode}
                onChange={handleOnChange}
                onBlur={handleBlur}
                maxLength="6"
                max="6"
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {handelvalue && (errors.pincode && touched.pincode ? (
                <p className="text-[red]">{errors.pincode}</p>
              ) : null)}
            </div>

            <div className="flex flex-col space-y-2">
              <label className="pl-[2px] text-[#313135]" htmlFor="country">
                {t("country")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full p-[10px] border border-[#E2E8F0]
                   bg-white rounded text-[#191919] appearance-none"
                  type="text"
                  name="country"
                  id="country"
                  readOnly={!isEdit}
                  onBlur={handleBlur}
                  value={values?.country}
                  onChange={handleCountryChange}
                >
                  <option className="p-5" value="">
                    {t("select_country")}
                  </option>
                  {countryname &&
                    Object.keys(countryname)?.map((st, index) => (
                      <option value={st} key={index}>
                        {countryname[st]}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {handelvalue && (errors.country && touched.country ? (
                <p className="text-[red]">{errors.country}</p>
              ) : null)}
            </div>

            <div className="flex flex-col space-y-2">
              <label className="pl-[2px] text-[#313135]" htmlFor="state">
                {t("state")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full p-[10px] border border-[#E2E8F0]
                   bg-white rounded text-[#191919] appearance-none"
                  type="text"
                  name="state"
                  id="state"
                  value={values?.state}
                  onChange={handleState}
                  onBlur={handleBlur}
                  readOnly={!isEdit}
                >
                  <option className="p-5" value="">
                    {t("select_state")}
                  </option>
                  {statename &&
                    Object.keys(statename)?.map((pm, index) => (
                      <option value={pm} key={index}>
                        {statename[pm]}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {handelvalue && (errors.state && touched.state ? (
                <p className="text-[red]">{errors.state}</p>
              ) : null)}
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="city" className="text-[#313135]">
                {t("city")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full p-[10px] border border-[#E2E8F0]
                   bg-white rounded text-[#191919] appearance-none"
                  type="text"
                  name="city"
                  id="city"
                  onChange={handleCity}
                  onBlur={handleBlur}
                  value={values?.city}
                  readOnly={!isEdit}
                >
                  <option className="p-5" value="">
                    {t("select_city")}
                  </option>
                  {cityname?.length > 0 &&
                    cityname?.map((pm, index) => (
                      <option value={pm} key={index}>
                        {pm}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
                {handelvalue && (errors.city && touched.city ? (
                  <p className="text-[red]">{errors.city}</p>
                ) : null)}
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="marital_status" className="text-[#313135]">
                Marital Status
              </label>
              <div className="relative">
                <select
                  id="marital_status"
                  className="h-[2.625rem] w-full p-[10px] border border-[#E2E8F0]
                   bg-white rounded text-[#191919] appearance-none"
                  type="text"
                  name="maritalStatus"
                  value={handelvalue ? values?.maritalStatus : initialValues?.maritalStatus || ""}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled>Select Marital status</option>
                  <option value="married">Married</option>
                  <option value="unmarried">Unmarried</option>
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {handelvalue && (errors.maritalStatus && touched.maritalStatus ? (
                <p className="text-[red]">{errors.maritalStatus}</p>
              ) : null)}
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="linkedin_profile" className="text-[#313135]">
                {t("linkedInUrl")}
              </label>
              <input
                type="url"
                id="linkedin_profile"
                name="linkedinProfile"
                className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-3"
                value={handelvalue ? values?.linkedinProfile : initialValues?.linkedinProfile}
                onChange={handleOnChange}
                onBlur={handleBlur}
              />
            </div>
          </form>
        </>
      )
      }
      {
        submitshow ? (
          <div className="w-full h-16 bg-white flex justify-end">
            <div className="flex items-center justify-center space-x-4">
              <button
                className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
                onClick={() => {
                  setSubmitshow(false);
                  setHandelValue(false);
                }}
              >
                {t("cancel")}
              </button>
              <button
                className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("update")}
              </button>
            </div>
          </div>
        ) : null
      }
    </div >
  );
}

PersonalInformation.propTypes = {
  isEdit: PropTypes.any,
};
