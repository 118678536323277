import React, { useEffect } from "react";
import Frame from "./Frame";
import Block from "./Block";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployee } from "redux/appThunk/Admin/myTeams";

const Organization = () => {
  const dispatch = useDispatch();
  const selector = useSelector(
    (state) => state.MyTeamsReducer.myOrganizationData
  );

  useEffect(() => {
    dispatch(fetchEmployee(1, 21, "", "", ""));
  }, []);

  return (
    <div className="w-full h-full">
      <div className="w-[80vw] mb-4">
        <Frame />
      </div>

      <div
        className="flex w-full h-[40rem] p-[1.25rem_2.25rem] flex-col justify-start
        items-start gap-5 rounded-[1.25rem] shadow border"
      >
        <Block apiType="organization" />

        <div
          className="w-full grid frid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5
          custom_scroll overflow-x-hidden "
        >
          {selector?.map((item) => (
            <div key={item.id} className="w-full">
              <Card item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Organization;
