import PropTypes from "prop-types";
import { getStatusColor } from "component/project/Projectlisting";
// import ProfileAvtar from "component/common/PofileAvtar";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import LoaderComp from "component/loader/LoaderComp";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { GoPlusCircle } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProjectId } from "redux/actions/action";
import { convertDateFormat } from "utils/date";
import { ProjectKeys } from "component/common/enum";
import AddResource from "./AddResource";

const ProjectTable = ({
  columnModifier,
  handleView,
  handleAddResource,
  onDelete,
  onSetType,
  isLoading,
  allProjects,
}) => {
  const tableHeader =
    allProjects && allProjects[0]?.project
      ? Object.keys(allProjects[0]?.project)
      : null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState("");

  useOnClickOutside(menuRef, () => setShowDropdown(""));

  const handleDropDownClick = (id) => {
    if (showDropdown === id) {
      setShowDropdown("");
    } else {
      setShowDropdown(id);
    }
    dispatch(setProjectId(id));
  };
  const onClientNameClick = (projectId) => {
    navigate("/clients/Name/" + projectId);
  };
  const renderCells = (key, data) => {
    let content = (
      <td
        className={`min-w-[5.5rem] max-w-[5.5rem]  p-2 text-left overflow-hidden text-ellipsis
           whitespace-nowrap ${data?.project?.clientId}  cursor-pointer text-[#031B59]`}
      >
        {data?.project[key] ?? "---"}
      </td>
    );

    if (key === ProjectKeys.CLIENT_ID) {
      content = (
        <td
          className={`min-w-[5.5rem] p-2 text-left ${data?.project?.clientId}  cursor-pointer text-[#031B59]`}
          onClick={() => {
            onClientNameClick(data?.project?.clientId);
          }}
        >
          {data?.project?.clientId ? data?.clientDetail?.name ?? "NA" : "---"}
        </td>
      );
    }
    if (key === ProjectKeys.Start_Date) {
      content = (
        <td className="lg:w-[9.533rem] min-w-[9.533rem] p-2 -left-1 z-10">
          {(data?.project?.startDate &&
            convertDateFormat(data?.project[key])) ||
            "---"}
        </td>
      );
    }
    if (key === ProjectKeys.Status) {
      content = (
        <td className="min-w-[5.5rem] p-2 text-left">
          <span
            className={`capitalize ${getStatusColor(
              data?.project[key] || "---"
            )}`}
          >
            {(data?.project[key] === "ongoing"
              ? t("started")
              : data?.project[key]) || "---"}
          </span>
        </td>
      );
    }

    return content;
  };

  return (
    <div
      data-testid="loader"
      className={` overflow-x-scroll ${
        isLoading ? "custom_scroller" : "custom_scroll"
      }`}
    >
      <table className="w-full p-3 bg-white rounded-lg text-left">
        <thead className="flex-0 text-left left-0">
          <tr
            className="h-[3.125rem] max-h-[3.125rem] p-2 text-[#686868]
            text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0] sticky
            top-0 z-20 "
          >
            <th
              className="lg:w-[9.533rem] min-w-[9.533rem] p-2 max-h-[3.125rem]
           h-full -left-1 sticky bg-[#F2F6FF] z-10"
            >
              {t("projectName")}
            </th>
            <th
              className="min-w-[12rem] lg:w-[9.109rem] sticky top-0 bg-[#F2F6FF]
               md: p-2 max-h-[3.125rem] h-full text-left"
            >
              {t("projectId")}
            </th>
            <th
              className=" min-w-[12rem] lg:w-[9.109rem] sticky top-0 bg-[#F2F6FF]
               md: p-2 max-h-[3.125rem] h-full text-left"
            >
              {t("resource")}
            </th>
            {tableHeader &&
              tableHeader?.map(
                (head) =>
                  columnModifier?.has(head) && (
                    <th
                      key={head}
                      className="min-w-[12rem] lg:w-[9.109rem] sticky top-0 bg-[#F2F6FF] md:p-2
                      max-h-[3.125rem] h-full text-left"
                    >
                      {t(head)}
                    </th>
                  )
              )}

            <th
              className=" min-w-[12rem] lg:w-[9.109rem] sticky top-0 bg-[#F2F6FF]
            md: p-2 max-h-[3.125rem] h-full text-left"
            >
              {t("action")}
            </th>
            <th
              className=" min-w-[12rem] lg:w-[9.109rem] sticky top-0 bg-[#F2F6FF]
              md: p-2 max-h-[3.125rem] h-full text-left"
            >
              {"Add Resources"}
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody className="text-sm font-normal flex-0">
            {allProjects?.map((data, index) => {
              return (
                <tr
                  key={index}
                  className="h-[3.125rem] even:bg-[#F8FAFC] border border-[#E2E8F0]
                text-[#191919] last-row-project"
                >
                  <td
                    className={`lg:w-[9.533rem] min-w-[9.533rem] p-2 -left-1 sticky font-semibold 
                      ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}
                      cursor-pointer text-[#031B59] z-10`}
                  >
                    <span>
                      {data?.project?.name
                        ? data.project.name.trim().charAt(0).toUpperCase() +
                          data.project.name.trim().slice(1)
                        : "---"}
                    </span>
                  </td>
                  <td
                    className={`lg:w-[9.533rem] min-w-[9.533rem] p-2 -left-1  font-semibold 
                  cursor-pointer text-[#031B59] z-10`}
                  >
                    <span>{data?.project?.id || "---"}</span>
                  </td>
                  <td
                    className={`lg:w-[9.533rem] min-w-[9.533rem] p-2 -left-1  font-semibold 
                    cursor-pointer text-[#031B59] z-10`}
                    onClick={() => onSetType("view")}
                  >
                    <AddResource
                      resourceData={data.resources}
                      showDropdown={showDropdown}
                      currentIndex={index}
                      data={data}
                      onDelete={(pId, rId, rName) => {
                        onDelete(pId, rId, rName);
                      }}
                      onDropdownClick={handleDropDownClick}
                    />
                  </td>
                  {tableHeader.map(
                    (projectObjKey) =>
                      columnModifier.has(projectObjKey) &&
                      renderCells(projectObjKey, data, index)
                  )}

                  <td
                    className={` w-[14rem] p-2 py-5  text-center " : "bg-[#F8FAFC]"} `}
                  >
                    <FaEye
                      className="cursor-pointer"
                      fontSize="20px"
                      onClick={() => {
                        handleView(data?.project?.id);
                      }}
                    />
                  </td>
                  <td>
                    <div
                      className="text-[0.875rem] text-[#031B59] flex items-center cursor-pointer
                              justify-start w-[12rem] py-4 gap-[0.25rem] px-[0.5rem]"
                      onClick={() => handleAddResource(data?.project?.id)}
                    >
                      <GoPlusCircle />
                      <span>{t("addResources")}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {isLoading && (
        <div className="flex items-center  justify-center h-[80vh] w-full">
          <LoaderComp />
        </div>
      )}
    </div>
  );
};

export default ProjectTable;

ProjectTable.propTypes = {
  columnModifier: PropTypes.any,
  handleView: PropTypes.func.isRequired,
  handleAddResource: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetType: PropTypes.func.isRequired,
  isLoading: PropTypes.any.isRequired,
  allProjects: PropTypes.any.isRequired,
};
