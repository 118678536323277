import React, { useEffect, useState } from "react";
import {
  MdAddCircleOutline,
  MdOutlineCameraAlt,
  MdPerson
} from "react-icons/md";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaTwitter
} from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { companyProfileDetails } from "redux/validator/admin/setting";
import {
  createProfile,
  updateProfile
} from "redux/appThunk/Admin/companyProfile";
import BranchInput from "./BranchInput";
import { fetchCompanyProfileDetails } from "redux/appThunk/Admin/companyProfile";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderComp from "component/loader/LoaderComp";

export default function CompanyProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [hover, setHover] = useState(false);
  const [data, setData] = useState(null);
  const [profileReload, setProfileReload] = useState(false);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchCompanyProfileDetails(setLoading));
  }, [profileReload]);

  const companyProfile = useSelector(
    (state) => state.CompanyProfileReducer.companyProfile
  );
  const [isEdit, setIsEdit] = useState(false);
  const companyProfileData = {
    companyName: "",
    companyContactNo: [
      {
        id: 1,
        valuee: "",
      },
    ],
    companyMailInput: [
      {
        id: 1,
        valuee: "",
      },
      {
        id: 2,
        valuee: "",
      },
    ],
    emailIdNewsLetter: "",
    faceBook: "",
    linkedIn: "",
    instagram: "",
    X: "",
    branchInput: [
      {
        id: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pin: "",
        phone: [
          {
            id: 1,
            valuee: "",
          },
        ],
        email: [
          {
            id: 1,
            valuee: "",
          },
          {
            id: 2,
            valuee: "",
          },
        ],
        incorporation_date: "",
        pan_number: "",
        gst_number: "",
        tan_number: "",
        images_url: [],
      },
    ],
    projects: "",
    clients: "",
    experience: "",
  };
  const formik = useFormik({
    initialValues: companyProfileData,
    validationSchema: companyProfileDetails,
    onSubmit: (values) => {
      setSubmitOnce(true);
      const formData = new FormData();
      if (data != null) {
        formData.append("company_profile[logo]", data);
      }
      formData.append("company_profile[company_name]", values.companyName);
      formData.append(
        "company_profile[newsletter_email]",
        values.emailIdNewsLetter
      );
      formData.append("company_profile[experiences]", values.experience);
      formData.append("company_profile[social_links][][platform]", "facebook");
      formData.append("company_profile[social_links][][link]", values.faceBook);
      formData.append("company_profile[social_links][][platform]", "Linkedin");
      formData.append("company_profile[social_links][][link]", values.linkedIn);
      formData.append("company_profile[social_links][][platform]", "instagram");
      formData.append(
        "company_profile[social_links][][link]",
        values.instagram
      );
      formData.append("company_profile[social_links][][platform]", "Twitter");
      formData.append("company_profile[social_links][][link]", values.X);
      formData.append("company_profile[clients]", values.clients);
      formData.append("company_profile[projects]", values.projects);
      values?.companyContactNo?.map((val) => {
        formData.append("company_profile[contact_numbers][]", val.valuee);
      });
      values?.companyMailInput?.map((valu) => {
        formData.append("company_profile[emails][]", valu.valuee);
      });

      values.branchInput?.map(
        (value, index) => {
          const exists = companyProfile?.branches?.some((item) => item.id === values.branchInput[index].id);

          return(
            formData.append(
              `company_profile[company_details_attributes][${index}][address]`,
              values.branchInput[index].address
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][city]`,
              values.branchInput[index].city
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][state]`,
              values.branchInput[index].state
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][country]`,
              values.branchInput[index].country
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][pin]`,
              values.branchInput[index].pin
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][incorporation_date]`,
              values.branchInput[index].incorporation_date
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][tan_number]`,
              values.branchInput[index].tan_number
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][pan_number]`,
              values.branchInput[index].pan_number
            ),
            formData.append(
              `company_profile[company_details_attributes][${index}][gst_number]`,
              values.branchInput[index].gst_number
            ),
            values?.branchInput[index]?.images_url?.map((Url) => {
              if (Url.size) {
                formData.append(
                  `company_profile[company_details_attributes][${index}][images][]`,
                  Url
                );
              }
            }),
            values?.branchInput[index]?.email?.map((Cm) => {
              formData.append(
                `company_profile[company_details_attributes][${index}][emails][]`,
                Cm.valuee
              );
            }),
            values?.branchInput[index]?.phone?.map((Pm) => {
              formData.append(
                `company_profile[company_details_attributes][${index}][contact_numbers][]`,
                Pm.valuee
              );
            }),
            formData.append(
              `company_profile[company_details_attributes][${index}][id]`,
              exists ? values.branchInput[index].id : ""
            )
          );
        }
      );
      if (companyProfile?.id) {
        dispatch(updateProfile(formData,setProfileReload,setIsEdit));
        setSubmitOnce(false);
      } else {
        dispatch(createProfile(formData));
        setSubmitOnce(false);
      }
    },
  });
  const {
    setFieldValue,
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
  } = formik;

  const handleContactAdd = () => {
    setFieldValue("companyContactNo", [
      ...values.companyContactNo,
      { id: values?.companyContactNo?.length + 1, valuee: "" },
    ]);
  };
  const handleCompanyMailAdd = () => {
    setFieldValue("companyMailInput", [
      ...values.companyMailInput,
      { id: values?.companyMailInput?.length + 1, valuee: "" },
    ]);
  };

  const handleChangeContact = (e, index) => {
    const updatedContacts = [...values.companyContactNo];
    updatedContacts[index] = {
      ...updatedContacts[index],
      valuee: e.target.value,
    };
    setFieldValue("companyContactNo", updatedContacts);
  };

  const handleChangeMail = (e, index) => {
    const updatedMails = [...values.companyMailInput];
    updatedMails[index] = { ...updatedMails[index], valuee: e.target.value };
    setFieldValue("companyMailInput", updatedMails);
  };

  const handleAddBranch = () => {
    setFieldValue("branchInput", [
      ...values.branchInput,
      {
        ...companyProfileData.branchInput[0],
        id: values?.branchInput?.length + 1,
      },
    ]);
  };
  const handleRemove = (indexVal, val) => {
    if (val === "mail") {
      const valuee = values.companyMailInput.filter(
        (_, idd) => idd !== indexVal
      );
      setFieldValue("companyMailInput", valuee);
    }
    if (val === "contact") {
      const valuee = values.companyContactNo.filter(
        (_, idd) => idd !== indexVal
      );
      setFieldValue("companyContactNo", valuee);
    }
  };

  const findError = (mainKey, key, type, index) => {
    const isError =
      errors[mainKey] &&
      errors[mainKey][index] &&
      errors[mainKey][index][key] &&
      touched[mainKey] &&
      touched[mainKey][index] &&
      touched[mainKey][index][key];
    if (type === "text") {
      return isError ? (
        <p className="text-[#f00]">{errors[mainKey][index][key]}</p>
      ) : null;
    } else {
      return isError ? "red" : "";
    }
  };
  useEffect(() => {
    companyProfile?.branches?.map(
      (value, index) => (
        setFieldValue(`branchInput[${index}].address`, value.address),
        setFieldValue(`branchInput[${index}].country`, value.country),
        setFieldValue(`branchInput[${index}].state`, value.state),
        setFieldValue(`branchInput[${index}].city`, value.city),
        setFieldValue(
          `branchInput[${index}].incorporation_date`,
          value.incorporation_date
        ),
        setFieldValue(`branchInput[${index}].pin`, value.pin),
        value?.email?.map((a, b) => {
          setFieldValue(`branchInput[${index}].email[${b}].valuee`, a);
        }),
        value?.phone?.map((a, b) => {
          setFieldValue(`branchInput[${index}].phone[${b}].valuee`, a);
        }),
        setFieldValue(`branchInput[${index}].pan_number`, value.pan_number),
        setFieldValue(`branchInput[${index}].gst_number`, value.pan_number),
        setFieldValue(`branchInput[${index}].tan_number`, value.tan_number),
        setFieldValue(`branchInput[${index}].images_url`, value.images),
        setFieldValue(`branchInput[${index}].id`, value.id)
      )
    );
    companyProfile?.companyContactNo?.map((v, inde) => {
      setFieldValue(`companyContactNo[${inde}].valuee`, v);
    });
    companyProfile?.emails?.map((val, i) => {
      setFieldValue(`companyMailInput[${i}].valuee`, val);
    });
    setFieldValue("companyName", companyProfile?.companyName),
    setFieldValue("emailIdNewsLetter", companyProfile?.emailIdNewsLetter),
    setFieldValue("projects", companyProfile?.projects),
    setFieldValue("experience", companyProfile?.experience),
    setFieldValue("clients", companyProfile?.clients);
    companyProfile?.socialLinks?.map((val) => {
      if (val.platform === "facebook") {
        setFieldValue("faceBook", val.link);
      } else if (val.platform === "Linkedin") {
        setFieldValue("linkedIn", val.link);
      } else if (val.platform === "instagram") {
        setFieldValue("instagram", val.link);
      } else if (val.platform === "Twitter") {
        setFieldValue("X", val.link);
      }
    });
  }, [companyProfile]);

  return (
    <>{loading ? (
      <LoaderComp/>)
      : (
        <div
          className="w-[96%] min-h-[46.063rem] h-fit p-5 bg-white flex-flex-col space-y-4
     shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] rounded-[20px]"
        >
          {companyProfile?.id === 0 ? (
            <div className="w-full flex justify-between items-center">
              <div className="w-full flex justify-start items-center">
                <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
                  {t("company_profile")}
                </h3>
              </div>
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
                  onClick={() => navigate("/dashboard")}
                >
                  {t("cancel")}
                </button>
                <button
                  className={`h-[2.813rem] w-[7.625rem] p-2 ${
                    submitOnce ? "bg-[#7f82bb]" : "bg-[#23275E] cursor-pointer"
                  }
               text-white rounded-full`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-between items-center">
              <div className="w-full flex justify-start items-center">
                <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
                  {t("company_profile")}
                </h3>
              </div>
              {!isEdit && (
                <div className="w-full flex justify-end items-center space-x-4">
                  <button
                    className="w-[6.688rem] h-[2.688rem] flex items-center justify-center text-[#031B59]
               border border-[#E2E8F0] rounded-full"
                    onClick={() => { setIsEdit(true); }}
                  >
                    {t("edit")}
                  </button>
                </div>
              )}
              {isEdit && (
                <div className="flex items-center justify-center space-x-4">
                  <button
                    className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
                    onClick={() => setIsEdit(false)}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className={`h-[2.813rem] w-[7.625rem] p-2 ${
                      submitOnce ? "bg-[#7f82bb]" : "bg-[#23275E] cursor-pointer"
                    } text-white rounded-full`}
                    type="submit"
                    disabled={submitOnce}
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </button>
                </div>
              )}
            </div>
          )}
          <form className="space-y-4">
            <div className="w-full flex justify-start items-center">
              {companyProfile?.logo_url ? (
                <div
                  onMouseEnter={() => {
                    setHover(true);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                  }}
                  onClick={() => {
                    setHover(false);
                  }}
                  className="rounded-[50%]"
                >
                  <label
                    className="h-full w-full cursor-pointer flex items-center justify-center rounded-full"
                    htmlFor="logo"
                  >
                    <img
                      className="w-[6.25rem] h-[6.25rem] rounded-[50%] relative "
                      src={companyProfile?.logo_url}
                      alt="profile"
                    />
                    {hover && (
                      <div
                        className="absolute bg-slate-400 bg-opacity-70 h-[6.25rem] w-[6.25rem] flex justify-center
                        items-center rounded-full text-[#F2F6FF]"
                      >
                        <div className="flex flex-col items-center justify-center text-sm">
                          <div>
                            <MdOutlineCameraAlt className="h-[1.25rem] w-[1.25rem]" />
                          </div>
                          <div>{t('change')}</div>
                          <div>{t('profileCap')}</div>
                        </div>
                      </div>
                    )}
                    <input
                      className="hidden"
                      type="file"
                      name="logo"
                      id="logo"
                      onChange={(e) => {
                        setData(e.target.files[0]);
                      }}
                    />
                  </label>
                </div>
              ) : (
                <div
                  className="relative w-[6.25rem] h-[6.25rem]-full border-2 border-[#F2F6FF]
           flex rounded-full justify-center"
                >
                  <MdPerson className="h-[5.25rem] w-[5.25rem] fill-[#eeeeee]" />
                  <div
                    className="absolute top-[3.25rem] left-[4.5rem] w-[2.125rem] h-[2.125rem] cursor-pointer
             flex items-center justify-center-full drop-shadow-[0_0px_10px_rgba(3,27,89,0.10)]"
                  >
                    <label
                      className="h-full w-full cursor-pointer flex items-center justify-center bg-white rounded-full"
                      htmlFor="company_profile"
                    >
                      {" "}
                      <MdOutlineCameraAlt className="stroke-[#031B59]" />
                    </label>
                    <input
                      className="hidden"
                      type="file"
                      name="logo_url"
                      id="company_profile"
                      onChange={(e) => {
                        setData(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                <label className="pl-[2px] text-[#313135]" htmlFor="companyName">
                  {t("company_name")}
                </label>
                <input
                  className="w-full h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                  id="companyName"
                  type="text"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={!isEdit}
                />
                {errors.companyName && touched.companyName ? (
                  <p className="text-[#f00]">{errors.companyName}</p>
                ) : null}
              </div>
            </div>
            {/*Contact No. */}
            <div className="flex flex-col gap-2">
              <label className="pl-[2px] text-[#313135]" htmlFor="contactNo">
                {t("contact_no")}
              </label>
              <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-2">
                {values?.companyContactNo?.map((obj, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex gap-1">
                      <input
                        className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                        id="contactNo"
                        type="text"
                        name={`companyContactNo[${index}].valuee`}
                        onChange={(e) => handleChangeContact(e, index)}
                        onBlur={handleBlur}
                        value={obj.valuee}
                        readOnly={!isEdit}
                        maxLength="10"
                        max="10"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (!pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {index > 0 && isEdit && (
                        <IoIosClose
                          className="self-center text-[#f00]"
                          onClick={() => handleRemove(index, "contact")}
                        />
                      )}
                    </div>
                    {findError("companyContactNo", "valuee", "text", index)}
                  </div>
                ))}
              </div>
              {isEdit && (
                <div className="w-full flex justify-start items-center text-[#031B59] space-x-1">
                  <MdAddCircleOutline onClick={() => handleContactAdd()} />
                  <p>{t("add")}</p>
                </div>
              )}
            </div>

            {/* Company Mail */}
            <div className="flex flex-col gap-2">
              <label className="pl-[2px] text-[#313135]" htmlFor="email">
                {t("email")}
              </label>
              <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-2">
                {values?.companyMailInput.map((obj, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex gap-1">
                      <input
                        className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                        id="email"
                        type="email"
                        name={`companyMailInput[${index}].valuee`}
                        onBlur={handleBlur}
                        value={obj.valuee}
                        readOnly={!isEdit}
                        onChange={(e) => handleChangeMail(e, index)}
                      />
                      {index > 1 && isEdit && (
                        <IoIosClose
                          className="self-center text-[#f00]"
                          onClick={() => handleRemove(index, "mail")}
                        />
                      )}
                    </div>
                    {findError("companyMailInput", "valuee", "text", index)}
                  </div>
                ))}
              </div>
            </div>
            {isEdit && (
              <div className="w-full flex justify-start items-center text-[#031B59] space-x-1">
                <MdAddCircleOutline onClick={handleCompanyMailAdd} />
                <p>{t("add")}</p>
              </div>
            )}
            {/* E-mail for Newsletter*/}
            <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                <label
                  className="pl-[2px] text-[#313135]"
                  htmlFor="emailIdNewsLetter"
                >
                  {t("email_id_newsletter")}
                </label>
                <input
                  className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                  type="email"
                  name="emailIdNewsLetter"
                  id="emailIdNewsLetter"
                  readOnly={!isEdit}
                  onChange={handleChange}
                  value={values.emailIdNewsLetter}
                  onBlur={handleBlur}
                />
                {errors.emailIdNewsLetter && touched.emailIdNewsLetter ? (
                  <p className="text-[#f00]">{errors.emailIdNewsLetter}</p>
                ) : null}
              </div>
            </div>
            {/* Company Social id */}
            <div className="w-full flex flex-col items-start justify-center">
              <div>
                <h5 className="text-[#313135] font-semibold leading-5">
                  {t("social_media_links")}
                </h5>
              </div>
              <div className="w-full pt-4 pb-2 grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
                <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                  <label className="pl-[2px] text-[#313135]" htmlFor="faceBook">
                    {t("facebook")}
                  </label>
                  <div className="flex h-[2.625rem] w-full items-center justify-between relative">
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[#E2E8F0] rounded pr-[3rem]"
                      id="faceBook"
                      type="email"
                      name="faceBook"
                      value={values?.faceBook}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isEdit}
                    />
                    <div className="w-12 h-10 absolute top-[4px] right-[4px] flex items-center
                    justify-center rounded-xl">
                      <FaFacebookF className="fill-[#A1A1A1]" />
                    </div>
                  </div>
                  {errors.facebook && touched.facebook ? (
                    <p className="text-[#f00]">{errors.facebook}</p>
                  ) : null}
                </div>
                <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                  <label className="pl-[2px] text-[#313135]" htmlFor="linkedIn">
                    {t("linkedIn")}
                  </label>
                  <div className="flex h-[2.625rem] w-full items-center justify-between relative">
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[#E2E8F0] rounded pr-[3rem]"
                      id="linkedIn"
                      type="email"
                      name="linkedIn"
                      value={values?.linkedIn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isEdit}
                    />
                    <div className="w-12 h-10 absolute top-[4px] right-[4px] flex items-center
                    justify-center rounded-xl">
                      <FaLinkedinIn className="fill-[#A1A1A1]" />
                    </div>
                  </div>
                  {errors.linkedIn && touched.linkedIn ? (
                    <p className="text-[#f00]">{errors.linkedIn}</p>
                  ) : null}
                </div>
                <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                  <label className="pl-[2px] text-[#313135]" htmlFor="instagram">
                    {t("instagram")}
                  </label>
                  <div className="flex h-[2.625rem] w-full items-center justify-between relative">
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[#E2E8F0] rounded pr-[3rem]"
                      id="instagram"
                      type="email"
                      name="instagram"
                      value={values?.instagram}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isEdit}
                    />
                    <div className="w-12 h-10 absolute top-[4px] right-[4px] flex items-center
                    justify-center rounded-xl">
                      <FaInstagram className="fill-[#A1A1A1]" />
                    </div>
                  </div>
                  {errors.instagram && touched.instagram ? (
                    <p className="text-[#f00]">{errors.instagram}</p>
                  ) : null}
                </div>
                <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                  <label className="pl-[2px] text-[#313135]" htmlFor="x">
                X
                  </label>
                  <div className="flex h-[2.625rem] w-full items-center justify-between relative">
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[#E2E8F0] rounded pr-[3rem]"
                      id="x"
                      type="email"
                      name="X"
                      value={values?.X}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly={!isEdit}
                    />
                    <div className="w-12 h-10 absolute top-[4px] right-[4px] flex items-center
                    justify-center rounded-xl">
                      <FaTwitter className="fill-[#A1A1A1]" />
                    </div>
                  </div>
                  {errors.X && touched.X ? (
                    <p className="text-[#f00]">{errors.X}</p>
                  ) : null}
                </div>
              </div>
            </div>
            {values?.branchInput.map((obj, index) => (
              <BranchInput
                key={values?.branchInput[index]?.id}
                index={index}
                obj={obj}
                formik={formik}
                isEdit={isEdit}
                setLoading={setLoading}
              />
            ))}

            {isEdit && (
              <div
                className="w-full flex justify-start items-center text-[#031B59] space-x-1"
                onClick={handleAddBranch}
              >
                <MdAddCircleOutline />
                <p>{t("add")}</p>
              </div>
            )}

            <div
              className="w-full h-fit flex flex-col items-start justify-center border-r-[1px]
          border-l-[1px] border-b-[1px]  border-[#E2E8F0] rounded"
            >
              <div
                className="w-full h-[3.125rem] p-5 flex justify-between items-center bg-[#F2F6FF]
              border-y border-[#E2E8F0] rounded-t cursor-pointer text-[#031B59]"
                onClick={() => setIsAboutOpen(!isAboutOpen)}
              >
                <h5> {t("about")} </h5>
                {isAboutOpen ? (
                  <BsChevronUp className="h-3 w-4 stroke-1 " />
                ) : (
                  <BsChevronDown className="h-3 w-4 stroke-1 " />
                )}
              </div>
              {isAboutOpen && (
                <div className="w-full px-4 py-2 grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
                  <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                    <label
                      className="pl-[2px] text-[#313135]"
                      htmlFor="projects_completed"
                    >
                      {t("projectCompleted")}
                    </label>
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                      id="projects_completed"
                      type="number"
                      name="projects"
                      value={values.projects}
                      readOnly={!isEdit}
                      onChange={(e) => setFieldValue("projects", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors.projects && touched.projects ? (
                      <p className="text-[#f00]">{errors.projects}</p>
                    ) : null}
                  </div>
                  <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                    <label
                      className="pl-[2px] text-[#313135]"
                      htmlFor="satisfied_clients"
                    >
                      {t("satisfiedClients")}
                    </label>
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                      id="satisfied_clients"
                      type="number"
                      name="clients"
                      readOnly={!isEdit}
                      value={values.clients}
                      onChange={(e) => setFieldValue("clients", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors.clients && touched.clients ? (
                      <p className="text-[#f00]">{errors.clients}</p>
                    ) : null}
                  </div>
                  <div className="w-full flex flex-col justify-center items-start space-y-[2px]">
                    <label className="pl-[2px] text-[#313135]" htmlFor="yearsOfExp">
                      {t("years_of_experience")}
                    </label>
                    <input
                      className="w-full flex h-[2.625rem] px-3 border border-[E2E8F0] rounded"
                      id="yearsOfExp"
                      type="number"
                      name="experience"
                      readOnly={!isEdit}
                      value={values.experience}
                      onChange={(e) => setFieldValue("experience", e.target.value)}
                      onBlur={handleBlur}
                    />
                    {errors.experience && touched.experience ? (
                      <p className="text-[#f00]">{errors.experience}</p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      )
    }
    </>

  );
}
