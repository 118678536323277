/* eslint-disable max-len */
import React from "react";

const ZoomOut = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83398 9.16406H14.1673V10.8307H5.83398V9.16406Z"
        fill="#031B59"
      />
      <path
        d="M9.99935 1.66406C5.40435 1.66406 1.66602 5.4024 1.66602 9.9974C1.66602 14.5924 5.40435 18.3307 9.99935 18.3307C14.5943 18.3307 18.3327 14.5924 18.3327 9.9974C18.3327 5.4024 14.5943 1.66406 9.99935 1.66406ZM9.99935 16.6641C6.32352 16.6641 3.33268 13.6732 3.33268 9.9974C3.33268 6.32156 6.32352 3.33073 9.99935 3.33073C13.6752 3.33073 16.666 6.32156 16.666 9.9974C16.666 13.6732 13.6752 16.6641 9.99935 16.6641Z"
        fill="#031B59"
      />
    </svg>
  );
};

export default ZoomOut;
