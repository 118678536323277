import React from "react";
import PropTypes from "prop-types";
import ProfileAvtar from "component/common/ProfileAvtar";

const TreeNodeLabel = ({ nodeData, toggleNode, orientation }) => {
  const isHorizontal = orientation === "horizontal";

  return (
    <div className="p-4 bg-white border border-gray-300 rounded shadow-md text-center w-40 relative">
      <div className="flex-wrap flex items-center justify-center">
        <ProfileAvtar
          key={nodeData?.name}
          name={nodeData?.name || "U-User"}
          src={nodeData?.profilePicture}
        />
      </div>
      <p className="font-semibold text-nowrap w-auto">
        {nodeData?.name || "U-User"}
      </p>
      <p className="text-gray-500 text-nowrap">
        {nodeData?.designation || "U-Designation"}
      </p>
      <p className="text-gray-500 text-nowrap">
        {nodeData?.empCode || "U-Code"}
      </p>
      {nodeData?.children && nodeData?.children?.length > 0 && (
        <button
          className={`absolute ${
            isHorizontal
              ? "top-1/2 right-[-15px] transform -translate-y-1/2"
              : "bottom-[-15px] left-1/2 transform -translate-x-1/2"
          } bg-blue-500 text-white rounded-full w-6 h-6 flex 
          items-center justify-center shadow-md`}
          onClick={toggleNode}
        >
          {nodeData?._collapsed
            ? isHorizontal
              ? ">"
              : "v"
            : isHorizontal
              ? ">"
              : "v"}
        </button>
      )}
    </div>
  );
};

TreeNodeLabel.propTypes = {
  nodeData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    empCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    profilePicture: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
    _collapsed: PropTypes.bool,
  }).isRequired,
  toggleNode: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
};

export default TreeNodeLabel;
