import * as Yup from "yup";

export const documentSchema = Yup.object({
  tenthMarkSheet: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  twelthMarkSheet: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  graduationMarkSheet: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  postGraduationMarkSheet: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  otherMarkSheet: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
});

export const identitySchema = Yup.object({
  Adhar: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  documentName: Yup.string().required('Document Name is Required'),
});

export const addressSchema = Yup.object({
  Adhar: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      file: Yup.mixed(),
    })
  ).min(1, 'At least one document is required'),
  documentName: Yup.string().required('Document Name is Required'),
});

export const employeHistorySchema = Yup.object({
  relievingLetter: Yup.mixed().required('Document Required')
    .test('fileSize', 'File size must be less than 3MB', value => {
      if (value) {
        return value[0]?.size <= 3145728;
      }

      return true;
    }),
  experienceLetter: Yup.mixed().required('Document Required')
    .test('fileSize', 'File size must be less than 3MB', value => {
      if (value) {
        return value[0]?.size <= 3145728;
      }

      return true;
    }),
  bankStatement: Yup.mixed().required('Document Required')
    .test('fileSize', 'File size must be less than 3MB', value => {
      if (value) {
        return value[0]?.size <= 3145728;
      }

      return true;
    }),
});

export const consentSchema = Yup.object({
  isChecked: Yup.bool().oneOf([true], 'You must accept to proceed').required('Required'),
});
