// eslint-disable-next-line no-unused-vars

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options);
};

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export const myLeavesColumn =

[
  {
    title: "From Date",
    field: "fromDate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "px-[15px]",
    style: "",
  },

  {
    title: "To Date",
    field: "toDate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: " No. Of Days",
    field: "consumedLeave",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Leave Type",
    field: "leaveType",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
  {
    title: "Reason ",
    field: "reason",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },

];

export const myTeamsColumn = [
  {
    title: "Employee Name",
    field: "employeename",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "From Data",
    field: "fromdate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "",
    style: "",
  },
  {
    title: "To Date",
    field: "todate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: " No. Of Days",
    field: "no.ofdays",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Leave Type",
    field: "leavetype",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Reason ",
    field: "reason",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];

export const employeeDetails =

[

  {
    title: "From Data",
    field: "fromDate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "px-[15px]",
    style: "",
  },

  {
    title: "To Date",
    field: "toDate",
    align: alignValues.left,
    render: (value) => formatDate(value),
    className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: " No. Of Days",
    field: "consumedLeave",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Leave Type",
    field: "leaveType",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
  {
    title: "Reason ",
    field: "reason",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];
