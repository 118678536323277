/* eslint-disable max-len */
import React from "react";

const ZoomIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8327 5.83073H9.16602V9.16406H5.83268V10.8307H9.16602V14.1641H10.8327V10.8307H14.166V9.16406H10.8327V5.83073ZM9.99935 1.66406C5.39935 1.66406 1.66602 5.3974 1.66602 9.9974C1.66602 14.5974 5.39935 18.3307 9.99935 18.3307C14.5993 18.3307 18.3327 14.5974 18.3327 9.9974C18.3327 5.3974 14.5993 1.66406 9.99935 1.66406ZM9.99935 16.6641C6.32435 16.6641 3.33268 13.6724 3.33268 9.9974C3.33268 6.3224 6.32435 3.33073 9.99935 3.33073C13.6743 3.33073 16.666 6.3224 16.666 9.9974C16.666 13.6724 13.6743 16.6641 9.99935 16.6641Z"
        fill="#031B59"
      />
    </svg>
  );
};

export default ZoomIn;
