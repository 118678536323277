/* eslint-disable max-len */
import React from "react";

const ViewTimeSheet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_17658_120045)">
        <path
          d="M19.748 3.55469H9.12305C8.79945 3.55469 8.53711 3.81703 8.53711 4.14062V7.65625C8.53711 7.97984 8.79945 8.24219 9.12305 8.24219H19.748C20.0716 8.24219 20.334 7.97984 20.334 7.65625V4.14062C20.334 3.81703 20.0716 3.55469 19.748 3.55469ZM9.70898 4.72656H12.0918V7.07031H9.70898V4.72656ZM19.1621 7.07031H13.2637V4.72656H19.1621V7.07031Z"
          fill="#ED6E0F"
        />
        <path
          d="M19.748 14.1016H9.12305C8.79945 14.1016 8.53711 14.3639 8.53711 14.6875V18.2031C8.53711 18.5267 8.79945 18.7891 9.12305 18.7891H19.748C20.0716 18.7891 20.334 18.5267 20.334 18.2031V14.6875C20.334 14.3639 20.0716 14.1016 19.748 14.1016ZM9.70898 15.2734H15.6465V17.6172H9.70898V15.2734ZM19.1621 17.6172H16.8184V15.2734H19.1621V17.6172Z"
          fill="#ED6E0F"
        />
        <path
          d="M6.19336 12.9297C6.19336 11.6373 5.14195 10.5859 3.84961 10.5859C2.55727 10.5859 1.50586 11.6373 1.50586 12.9297C1.50586 14.222 2.55727 15.2734 3.84961 15.2734C5.14195 15.2734 6.19336 14.222 6.19336 12.9297ZM3.84961 14.1016C3.20344 14.1016 2.67773 13.5759 2.67773 12.9297C2.67773 12.2835 3.20344 11.7578 3.84961 11.7578C4.49578 11.7578 5.02148 12.2835 5.02148 12.9297C5.02148 13.5759 4.49578 14.1016 3.84961 14.1016Z"
          fill="#ED6E0F"
        />
        <path
          d="M0.333984 19.4141C0.333984 19.7377 0.596328 20 0.919922 20H6.7793C7.10289 20 7.36523 19.7377 7.36523 19.4141V18.8281C7.36523 16.8781 5.78598 15.2734 3.84961 15.2734C1.91527 15.2734 0.333984 16.8763 0.333984 18.8281V19.4141ZM3.84961 16.4453C5.14195 16.4453 6.19336 17.5142 6.19336 18.8281H1.50586C1.50586 17.5142 2.55727 16.4453 3.84961 16.4453Z"
          fill="#ED6E0F"
        />
        <path
          d="M0.333984 8.24219V8.82812C0.333984 9.15172 0.596328 9.41406 0.919922 9.41406H6.7793C7.10289 9.41406 7.36523 9.15172 7.36523 8.82812V8.24219C7.36523 6.30367 5.78813 4.72656 3.84961 4.72656C1.91109 4.72656 0.333984 6.30367 0.333984 8.24219ZM3.84961 5.89844C5.14195 5.89844 6.19336 6.94984 6.19336 8.24219H1.50586C1.50586 6.94984 2.55727 5.89844 3.84961 5.89844Z"
          fill="#ED6E0F"
        />
        <path
          d="M6.19336 2.38281C6.19336 1.09789 5.15492 0 3.84961 0C2.54398 0 1.50586 1.09812 1.50586 2.38281C1.50586 3.67516 2.55727 4.72656 3.84961 4.72656C5.14195 4.72656 6.19336 3.67516 6.19336 2.38281ZM3.84961 3.55469C3.20344 3.55469 2.67773 3.02898 2.67773 2.38281C2.67773 1.72641 3.21438 1.17188 3.84961 1.17188C4.48484 1.17188 5.02148 1.72641 5.02148 2.38281C5.02148 3.02898 4.49578 3.55469 3.84961 3.55469Z"
          fill="#ED6E0F"
        />
      </g>
      <defs>
        <clipPath id="clip0_17658_120045">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ViewTimeSheet;
