/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import LoaderComp from "component/loader/LoaderComp";
import { awsURL } from "utils/Constants";
import { useTranslation } from "react-i18next";

const CustomTable = ({
  columns,
  data,
  isLoading,
  tableContainerClass,
  customTableClass,
  selectedItems,
  setSelectedItems,
  handleSelectAll,
  allSelect,
  TopNewData,
  renderAction,
  // showNoDatamessage,
}) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRefs = useRef([]);
  const {t} = useTranslation();

  useEffect(() => {
    const handler = (event) => {
      if (openDropdownId !== null) {
        const currentDropdownRef = dropdownRefs.current[openDropdownId];
        if (currentDropdownRef && !currentDropdownRef.contains(event.target)) {
          setOpenDropdownId(null);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [openDropdownId]);

  return (
    <div
      className={`w-full border border-[#E2E8F0] rounded ${tableContainerClass}`}
    >
      <table className={`w-full rounded ${customTableClass}`}>
        <thead className="w-full bg-[#F2F6FF] text-[#686868] sticky top-0 z-10">
          <tr className="h-[4.4rem]">
            {columns?.map((col) => (
              <th
                className="px-[15px]"
                key={col.field || col.title}
                align={col?.align}
              >
                <div className="flex gap-3">
                  {col?.inputcheck && (
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        disabled={!data?.length}
                        checked={allSelect}
                        onChange={(e) =>
                          handleSelectAll(e.target.checked, data)
                        }
                      />
                    </div>
                  )}
                  <div className="flex gap-8 w-full">
                    {col?.title}
                    {col?.icon && <col.icon />}
                  </div>
                </div>
              </th>
            ))}
            {renderAction && (<th className="min-w-[5rem] w-fit text-left px-[15px]
             text-[#686868] capitalize">{t("action")} </th>)}
          </tr>
        </thead>
        <tbody className={`w-full h-full text-gray-700`}>
          {isLoading ? (
            <tr>
              <td colSpan={columns?.length} align="center">
                <LoaderComp />
              </td>
            </tr>
          ) : (
            <>
              {TopNewData && (
                <tr>
                  <td colSpan={columns?.length}>{TopNewData}</td>
                </tr>
              )}
              {data?.length > 0 ? (
                data.map((job, jobIndex) => (
                  <tr
                    className="h-[4.2rem] bg-[#fff] even:bg-[#F2F6FF]"
                    key={job.id}
                  >
                    {columns?.map((col) => (
                      <td
                        key={col?.field}
                        className={
                          col?.className ||
                          "min-w-[151px] p-[24px] h-[4.2rem] text-[#686868] capitalize"
                        }
                        align={col?.align}
                      >
                        <div className="flex gap-3 pt-5 pb-5">
                          {col?.inputcheck && (
                            <input
                              type="checkbox"
                              checked={selectedItems?.hasOwnProperty(job.id)}
                              onChange={(e) =>
                                setSelectedItems(job?.id, e.target.checked)
                              }
                            />
                          )}
                          {col?.render
                            ? col.render(job[col?.field], job, jobIndex)
                            : job[col?.field] === (null || undefined || "")
                              ? "- - - - - "
                              : job[col?.field]}
                        </div>
                      </td>
                    ))}
                    {renderAction && (
                      <td className="cursor-pointer">
                        <div className="w-full flex items-center justify-start px-[15px]">
                          <div
                            ref={(el) => (dropdownRefs.current[job.id] = el)}
                          >
                            {renderAction(job, jobIndex)}
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                // !TopNewData && showNoDatamessage &&
                <tr>
                  <td colSpan={columns?.length} align="center">
                    <div className="w-full h-full flex flex-col gap-3 items-start sm:items-center justify-center">
                      <img
                        className="w-auto h-[10rem] md:h-[15rem] lg:h-[20rem]"
                        src={`${awsURL}/images/portfolio_filter_NoDataFound.webp`}
                        alt="No Data Found"
                      />
                      <p className="text-[#031B59] text-2xl max-w-[10em] lg:max-w-none">
                        The Data you are looking for could not be found.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
