/* eslint-disable no-constant-condition */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { MdChevronLeft } from "react-icons/md";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import { UpdateSalarydata } from "redux/appThunk/Admin/salary";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";

export default function SalaryInfo({ accordionOpen, editState, setEditState }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);
  const user = useSelector((state) => state.profileReducer.profileData);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [periodOption, setPeriodOption] = useState(false);
  const [customError, setCustomError] = useState("");

  const addCurrencySymbol = (value) => {

    if (value === undefined || value === null || value === '') {
      return '';
    }

    const strValue = value.toString().trim();

    if (strValue.startsWith('₹')) {
      return strValue;
    }

    return `₹${strValue}`;
  };

  const initialValues = (() => {
    if (!user || !user.salary || !user.salary.addition || !user.salary.deduction || !user.salary.other) {
      return null;
    }

    return {
      basic_salary: addCurrencySymbol(user.salary.addition.basic_salary),
      house_rent_allowance: addCurrencySymbol(user.salary.addition.house_rent_allowance),
      medical_allowance: addCurrencySymbol(user.salary.addition.medical_allowance),
      special_allowance: addCurrencySymbol(user.salary.addition.special_allowance),
      esic: addCurrencySymbol(user.salary.deduction.esic),
      gratuity: addCurrencySymbol(user.salary.deduction.gratuity),
      provident_fund: addCurrencySymbol(user.salary.deduction.provident_fund),
      tds: addCurrencySymbol(user.salary.deduction.tds),
      incentives: addCurrencySymbol(user.salary.other.incentives),
      retention_bonus: addCurrencySymbol(user.salary.other.retention_bonus),
      ctc: addCurrencySymbol(user.salary.ctc),

      ...Object.keys(user?.salary?.addition || {}).reduce((acc, key) => {
        const { name, value } = user.salary.addition[key] || {};

        return { ...acc, [name]: value ? value : "" };
      }, {}),

      ...Object.keys(user?.salary?.deduction || {}).reduce((acc, key) => {
        const { name, value } = user.salary.deduction[key] || {};
        if (name !== employeeTypeObj.consultancyFee) {
          return { ...acc, [name]: value ? value : "" };
        }

        return acc;
      }, {}),

      ...Object.keys(user?.salary?.other || {}).reduce((acc, key) => {
        const { name, value } = user.salary.other[key] || {};

        return { ...acc, [name]: value ? value : "" };
      }, {}),
    };
  })();

  const initialData = {
    ...Object.keys(user?.salary?.deduction || {})
      .filter(
        (key) =>
          user.salary.deduction[key] === employeeTypeObj.consultancyFee ||
          user.salary.deduction[key] === employeeTypeObj.tds
      )
      .reduce((acc, key) => {
        const { name, value } = user.salary.deduction[key] || {};

        return { ...acc, [name]: value ? value : "" };
      }, {}),
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues:
      user?.section_applicable === "section192" || "section194"
        ? initialValues
        : initialData,
    onSubmit: async (values) => {
      let jsonObj = {};

      if (user?.section_applicable === "section192" || "section194") {
        jsonObj = {
          salary_info: {
            id: values.id,
            ctc: values.ctc || "",
            addition_attributes: {
              basic_salary: values?.basic_salary || "",
              house_rent_allowance: values?.house_rent_allowance || "",
              medical_allowance: values?.medical_allowance || "",
              special_allowance: values?.special_allowance || "",
            },
            deduction_attributes: {
              esic: values?.esic || "",
              tds: values?.tds || "",
              gratuity: values?.gratuity || "",
              provident_fund: values?.provident_fund || "",
            },
            other_attributes: {
              incentives: values?.incentives || "",
              retention_bonus: values?.retention_bonus || "",
            },
          },
        };
      } else {
        const objData = Object.keys(values).map((val) => ({
          field_name: val,
          value: values[val] || null,
        }));

        jsonObj = { salary_info: objData };
      }

      if (customError === "") {
        try {
          const result = await swalService.showWarning({
            icon: "warning",
            title: "Are you sure?",
            text: "You want to update this!",
            showCancelButton: true,
            confirmButtonText: "Yes, update it!",
            cancelButtonText: "No, cancel!",
          });

          if (result.value) {
            const id = user?.id;
            if (id) {
              await dispatch(
                UpdateSalarydata(
                  id,
                  jsonObj,
                  setEditState,
                  setEditState,
                  setHandle
                )
              );
            } else {
              console.error("User ID is missing.");
            }
          }
        } catch (error) {
          console.error(
            "An error occurred while showing the warning or updating the data:",
            error
          );
        }
      }
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
  } = Formik;

  useEffect(() => {
    if (values?.tds > 100) {
      setCustomError("tds must be between 0 and 100%");
    }
  }, [values?.tds]);

  function formatLabel(label) {
    if (label === "esic" || label === "tds") {
      return label?.toUpperCase();
    }

    if (label === "providient_fund") {
      return (label = "Provident Fund");
    }

    return label
      ?.toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const renderFieldFor192 = () => {
    return (
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="ctc" className="text-[#313135]">
            {t("ctc")}
          </label>
          <input
            type="text"
            name="ctc"
            id="ctc"
            value={Formik.values.ctc ?? "0"}
            className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
            disabled={userData?.role === employeeTypeObj.employee || !editState}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={20}
            onBlur={handleBlur}
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                e.preventDefault();
              }
            }}
          />
          {errors.ctc && touched.ctc ? (
            <p className="text-[red]">{errors.ctc}</p>
          ) : null}
        </div>
        <br />

        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59]">{t("addition")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user &&
              Object.keys(user?.salary?.addition)?.map((val, index) => {
                return (
                  <div key={index} className="flex flex-col space-y-2">
                    <label htmlFor={val} className="text-[#313135]">
                      {formatLabel(val)}
                    </label>
                    <input
                      type="text"
                      name={val}
                      id={val}
                      value={Formik.values?.[val]}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      disabled={
                        userData?.role === employeeTypeObj.employee ||
                        !editState
                      }
                      maxLength={20}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.val?.name && touched.val?.name ? (
                      <p className="text-[red]">{errors.val?.name}</p>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59]">{t("deductions")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user &&
              Object.keys(user?.salary?.deduction)?.map((val, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <label htmlFor={val} className="text-[#313135]">
                    {formatLabel(val)}
                  </label>
                  <input
                    type="text"
                    name={val}
                    id={val}
                    value={Formik.values?.[val]}
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                    disabled={
                      userData?.role === employeeTypeObj.employee || !editState
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    maxLength={20}
                    onBlur={handleBlur}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.val && touched.val ? (
                    <p className="text-[red]">{errors.val}</p>
                  ) : null}
                </div>
              ))}
          </div>
        </div>

        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59]">{t("other")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user &&
              Object.keys(user?.salary?.other)?.map((val, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <label htmlFor={val} className="text-[#313135]">
                    {formatLabel(val)}
                  </label>
                  <input
                    type="text"
                    name={val}
                    id={val}
                    value={Formik.values?.[val]}
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                    disabled={
                      userData?.role === employeeTypeObj.employee || !editState
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    maxLength={20}
                    onBlur={handleBlur}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.val?.name && touched.val ? (
                    <p className="text-[red]">{errors.val}</p>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      </form>
    );
  };

  const renderFieldFor194 = () => {
    return (
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        {user &&
          user?.salary?.deduction &&
          Object.keys(user.salary.deduction)
            .filter(
              (key) =>
                key === employeeTypeObj.consultancyFee ||
                key === employeeTypeObj.tds
            )
            ?.map((val, index) => (
              <div key={index} className="flex flex-col space-y-2">
                <label htmlFor={val?.name} className="text-[#313135]">
                  {val?.name}
                </label>
                <div className="flex border border-[#E2E8F0] w-full rounded">
                  <input
                    type="text"
                    name={val?.name}
                    id={val?.name}
                    className="w-full h-[2.625rem] p-2 text-[#191919] outline-none bg-[#fff]"
                    value={Formik.values[val.name]}
                    disabled={
                      userData?.role === employeeTypeObj.employee || !editState
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    maxLength={20}
                    onBlur={handleBlur}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {val?.name === employeeTypeObj.consultancyFee && (
                    <div
                      className="basis-1/4 w-full flex space-x-4 justify-end pr-2 items-center
                  relative capitalize text-[#031B59] cursor-pointer"
                      name="period"
                      value={values?.period}
                      onChange={handleChange}
                      disabled={!editState}
                      onClick={() => {
                        setPeriodOption(!periodOption);
                      }}
                    >
                      {values.period}
                      <MdChevronLeft className="w-5 h-5 transform -rotate-90 text-[#A1A1A1]" />
                      {periodOption && (
                        <div
                          className="absolute w-[115px] h-[74px] flex flex-col top-[3rem] right-[0] left-[2]
                    rounded-md justify-center items-center bg-[#fff] z-10 border border-[#E2E8F0]"
                        >
                          <div
                            className="cursor-pointer w-full hover:bg-[#F1F5F9] text-[#191919] rounded-md p-1"
                            onClick={() => setFieldValue("period", "monthly")}
                          >
                            {t("monthly")}
                          </div>
                          <div
                            className="cursor-pointer w-full hover:bg-[#F1F5F9] text-[#191919] rounded-md p-1"
                            onClick={() => setFieldValue("period", "hourly")}
                          >
                            {t("hourly")}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {errors.val && touched.val?.name ? (
                  <p className="text-[red]">{errors.val}</p>
                ) : null}
              </div>
            ))}
      </form>
    );
  };

  return (
    <div>
      {accordionOpen === 4 && (
        <div className="h-fit overflow-y-scroll custom_scroll m-[0.8rem] p-3 bg-[#f2f6ff]">
          {user?.section_applicable === "section192" || "section194" ? (
            <div className="overflow-y-scroll custom_scroll max-h-[42.563rem]">
              {" "}
              {renderFieldFor192()}
            </div>
          ) : (
            renderFieldFor194()
          )}
          {editState === 4 && (
            <div className="w-full h-16 bg-[#f2f6ff] flex justify-end">
              {userData?.role === employeeTypeObj.admin && (
                <>
                  {handle ? (
                    <div className="flex items-center justify-center space-x-4">
                      <LoaderComp />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center space-x-4">
                      <button
                        className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
                        onClick={() => {
                          setEditState(false);
                        }}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                        type="submit"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {t("update")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SalaryInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
};
