import React from "react";

const SvgEdit = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">

      <path d="M11.916 4.49977L14.166 6.74977M10.416 14.9998H16.416M4.41602 11.9998L3.66602
       14.9998L6.66602 14.2498L15.3555 5.56027C15.6367 5.27898 15.7947 4.89752 15.7947 4.49977C15.7947
       4.10203 15.6367 3.72057 15.3555 3.43927L15.2265 3.31027C14.9452 3.02907 14.5638 2.87109 14.166
       2.87109C13.7683 2.87109 13.3868 3.02907 13.1055 3.31027L4.41602 11.9998Z" stroke="#031B59"
      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SvgEdit;
