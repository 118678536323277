import {
  CITY_NAME,
  COMPANY_PROFILE,
  STATE_NAME,
  COUNTRY_NAME,
  PROFILE_ID
} from "redux/actions/types";

const initialstate = {
  companyProfile: {},
  countryname: {},
  statename: {},
  cityname: [],
  profileid: 0,
  error: "",
};

export const CompanyProfileReducer = (
  state = initialstate,
  { type, payload }
) => {
  switch (type) {
  case COMPANY_PROFILE:
    return { ...state, companyProfile: payload };

  case COUNTRY_NAME:
    return { ...state, countryname: payload };

  case STATE_NAME:
    return { ...state, statename: payload };

  case CITY_NAME:
    return { ...state, cityname: payload };

  case PROFILE_ID:
    return { ...state, profileid: payload };

  default:
    return state;
  }
};
