import React from "react";

const Dropdown = () => {
  return (
    <svg
      className="-mr-1 ml-2 h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M5.292 7.292a1 1 0 011.415 0L10 10.585l3.293-3.293a1
        1 0 011.415 1.415l-4 4a1 1 0 01-1.415 0l-4-4a1 1 0 010-1.415z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Dropdown;
