/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { statusFilterTableDefaultValue } from "utils/Constants.js";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission.jsx";
import Search from "../search_comp/Search.jsx";
import DepartmentListing from "./DepartmentListing.jsx";
import { CiBellOn } from "react-icons/ci";
import AddDepartmentPop from "../department/AddDepartmentPop.jsx";
import { useDispatch, useSelector } from "react-redux";
import { addDepartments, fetchDepartments, searchDepartment } from "redux/appThunk/Admin/department.js";
import Paginate from "../Employee/Paginate.jsx";
import { deleteDepartment } from "redux/actions/action.jsx";
import useDebounce from "hooks/useDebounce.jsx";
import LoaderComp from "component/loader/LoaderComp.js";

const DepartmentList = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [filterTableValue, setFilterTableValue] = useState(
    statusFilterTableDefaultValue
  );
  const [searchItem, setSearchItem] = useState("");
  const searchQuery = useDebounce (searchItem, 800);
  const itemsPerPage = 10;
  const [val, setVal] = useState();
  const { userPermission } = usePermissions(mappedPermissionObj.Department);
  const [isAddDepartmentPopOpen, setIsAddDepartmentPopOpen] = useState(false);
  const [getData, setGetData] = useState(false);
  const [loder, setLoder] = useState(false);
  const { data, pageCount } = useSelector(
    (state) => state.departmentReducer
  );
  useEffect(() => {
    setFilterTableValue(filterTableValue);
    dispatch(fetchDepartments(currentPage, itemsPerPage,searchQuery,setLoder));
  }, [currentPage,searchQuery,getData]);

  const handleSubmit = () => {
    dispatch(addDepartments(val, setIsAddDepartmentPopOpen,setGetData));
  };

  const handleOpenDialogBox = () => {
    setIsAddDepartmentPopOpen(!isAddDepartmentPopOpen);
  };

  const handleCloseDialogBox = () => {
    setIsAddDepartmentPopOpen(false);
  };

  return (
    <>
      <div className="w-[96%] h-[calc(100vh-9.1rem)] bg-white
      space-y-4 flex flex-col p-5 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] my-[2rem]">
        <div className="w-full h-16 flex lg:justify-between md:justify-start">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#031B59] ml-3">
              {t("department")}
            </h2>
          </div>
          <div className="flex items-center  justify-center lg:space-x-4 md:space-x-2 ">
            <Search
              searchItem = {searchItem}
              setSearchItem ={setSearchItem}
              currentResource={userPermission}
            />

            <button className="rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem]
                 md:w-[8.5rem]
                     text-[#A1A1A1]" onClick={handleOpenDialogBox}>Add Department</button>
            <span>
              <CiBellOn size={30} className="text-[grey] cursor-[pointer]" />
            </span>
          </div>
        </div>
        <div>

          {isAddDepartmentPopOpen && (
            <AddDepartmentPop
              handleCloseDialogBox={handleCloseDialogBox}
              path=""
              handleSubmit={() => handleSubmit()}
              setVal={setVal}
            />
          )}
          {
            loder ? (
              <div className="mt-16">
                <LoaderComp />
              </div>
            ) : (
              <DepartmentListing
                selector={data?.data}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                pageCount={data?.totalPages}
                setCurrentPage={setCurrentPage}
                setGetData={setGetData}
              />

            )
          }

        </div>
      </div>
    </>
  );
};

export default DepartmentList;
