import ToastServices from "ToastServices";
import Helper from "api/Helper";
import { employeeData, isLoading, setAttendance, setPageCount } from "redux/actions/action";
import { formattedData, formattedEmployeeData } from "redux/selector/Employee/attendance";

export const fetchAttendance =
  (userData, FromDate, ToDate, currentPage, itemsPerPage) =>
    async (dispatch) => {
      try {
        const path =
          `api/single_check_in_out?emp_code=` +
          userData?.emp_code +
          `&start_date=${FromDate}&end_date=${ToDate}&page=${currentPage}&pere_page=${itemsPerPage}`;
        const { response } = await Helper.get(path);
        const formattedResponse = formattedData(response?.check_in_outs);
        dispatch(setPageCount(response?.total_pages));
        dispatch(setAttendance(formattedResponse));
        dispatch(isLoading(false));
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: " Error !",
          autoClose: 3000,
        });
      }
    };

export const fetchEmployeeAttendance =
  (empCode, searchQuery) =>
    async (dispatch) => {
      try {
        const path =
          `api/last_week_or_month_summary?emp_code=${empCode}&last=${searchQuery}`;
        const { response } = await Helper.get(path);
        const formattedResponse = formattedEmployeeData(response,searchQuery);
        dispatch(employeeData(formattedResponse));
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: " Error !",
          autoClose: 3000,
        });
      }
    };
