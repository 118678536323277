import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { addInvoiceSchema } from "redux/validator/admin/invoice";
import Helper from "api/Helper";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa6";
import { AiFillFileImage } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
// import Employeebrief from "../Employee/Employeebrief";
import Svg18 from "svgComponents/Svg18";
import { filterClients } from "utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setInvoiceById } from "redux/actions/action";
import apiUrl from "api/apiUrl";
import ToastServices from "ToastServices";
import Popup from "component/common/Popup";
import { fetchInvoiceData } from "redux/appThunk/Admin/invoice";
import DatePicker from "component/common/DatePicker";
import { getDateDashFormat } from "utils/date";
import Employeebrief from "component/admin/Employee/Employeebrief";

export default function AddInvoiceDetails({
  setAddInvoicePop,
  searchName,
  currentPage,
  getParams,
  id,
  setEditId,
  setSearchItem,
}) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { t } = useTranslation();
  // const [ids] = useState();
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [searchRslt, setSearchRslt] = useState([]);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedFilter] = useState(filterClients[0]);
  const dispatch = useDispatch();
  const { editInvoiceData } = useSelector((state) => state.invoiceReducer);
  const [formData, setFormData] = useState();
  const [showPopUp, setShowPopUp] = useState(false);

  const initialvalues = {
    client_name: "",
    invoice_number: "",
    currency: "",
    amount: "",
    invoice_date: "",
    due_date: "",
    status: "",
    Upload_Image: [],
    user_id: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addInvoiceSchema,
    onSubmit: async () => {
      const submitData = new FormData();
      setSubmitOnce(false);
      submitData.append("invoice[client_name]", values.client_name);
      submitData.append("invoice[invoice_number]", values.invoice_number);
      submitData.append("invoice[currency]", values.currency);
      submitData.append("invoice[amount]", values.amount);
      submitData.append("invoice[invoice_date]", values.invoice_date);
      submitData.append("invoice[due_date]", values.due_date);
      submitData.append("invoice[status]", values.status);
      submitData.append("invoice[user_id]", values.user_id);
      values.Upload_Image.forEach((img) =>
        submitData.append(`invoice[gst_files][]`, img)
      );
      AddStatusDialoBox();
      setFormData(submitData);
    },
  });

  const { values, setFieldValue, handleSubmit, handleChange, handleBlur } =
    Formik;
  const checkData = {
    "Client Name": values.client_name,
    "Invoice  Number": values.invoice_number,
    Currency: values.currency,
    Amount: values.amount,
    "Invoice Date": values.invoice_date,
    "Due Date": values.due_date,
    Status: values.status,
    "Client Id": values.user_id,
  };
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  useEffect(() => {
    if (values?.name) {
      setFieldValue("empId", id);
    }
  }, [values?.name]);

  useEffect(() => {
    if (searchRslt) {
      Formik.setFieldValue("user_id", searchRslt[0]?.user_id);
    }
  }, [searchRslt]);

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setAddInvoicePop(false);
    }
  };

  const handleUsernameChange = (e) => {
    Formik.setFieldValue("client_name", e.target.value);
    setUserName(e.target.value);
    setShow(true);
  };

  const handleEmployeedata1 = (item) => {
    setFieldValue("client_name", item?.name);
    setShow(false);
  };

  function BlurHandle() {
    setTimeout(() => {
      setShow(false);
    }, 500);
  }

  const handleSearchUser = async () => {
    const path = `clients?client_type=${
      selectedFilter.key
    }&page=${""}&per_page=${""}
    &query=${userName}`;
    try {
      const { response } = await Helper.get(path);
      if (response && response.clients && response.clients.length > 0) {
        setSearchRslt(response.clients);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "User Not Found",
      });
    }
  };

  const fetchInvoiceById = async () => {
    const path = apiUrl.invoice + `/${id}`;
    const { response } = await Helper.get(path);
    dispatch(setInvoiceById(response));
  };

  useEffect(() => {
    if (id) {
      fetchInvoiceById();
    }
  }, [id]);

  useEffect(() => {
    let debounceTimer;
    if (userName) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        handleSearchUser();
      }, 500);
    } else {
      setShow(false);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [userName]);

  const getColorFromEmail = (email) => {
    const hashCode = email
      ?.split("")
      ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };

  const handleRemoveImage = (imageIndex) => {
    setFieldValue(
      `Upload_Image`,
      values.Upload_Image?.filter((_, i) => i !== imageIndex)
    );
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFieldValue(`Upload_Image`, [...values.Upload_Image, ...files]);
  };

  function validateKeyDown(event) {
    var allowedKeys = /[a-zA-Z\b\s]/;

    if (!allowedKeys.test(event.key)) {
      event.preventDefault();

      return false;
    }
  }

  const AddStatusDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    if (editInvoiceData && id) {
      setFieldValue("client_name", editInvoiceData?.invoice?.client_name);
      setFieldValue("currency", editInvoiceData?.invoice?.currency);
      setFieldValue("due_date", editInvoiceData?.invoice?.due_date);
      setFieldValue("invoice_date", editInvoiceData?.invoice?.invoice_date);
      setFieldValue("invoice_number", editInvoiceData?.invoice?.invoice_number);
      setFieldValue("status", editInvoiceData?.invoice?.status);
      setFieldValue("amount", editInvoiceData?.invoice?.amount);
      setFieldValue("gst_files", editInvoiceData?.invoice?.gst_files);
    }
  }, [editInvoiceData]);

  const submit = async () => {
    setSubmitOnce(true);
    const path = apiUrl.invoice;
    const { response, status } = await Helper.post(formData, path, true);
    setSubmitOnce(false);
    if (status === 201) {
      ToastServices.showToast({
        message: "Added Successfully!",
        type: "success",
      });
      dispatch(fetchInvoiceData(searchName, currentPage, getParams));
    } else {
      ToastServices.showToast({
        message: response.error,
        type: "error",
      });
    }
    setAddInvoicePop(false);
  };

  const hadleDataForProps = () => {
    setAddInvoicePop(false);
    setEditId(null);
    setSearchItem("");
  };

  return (
    <div
      className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-10 transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <span
              className="text-[#031B59] mr-4 cursor-pointer"
              onClick={hadleDataForProps}
            >
              <FaArrowLeft />
            </span>
            <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
              {id ? t("invoiceDetails") : t("addNewInvoice")}
            </h3>
          </div>
          {showPopUp && (
            <Popup
              popupBox={AddStatusDialoBox}
              title={"Add Invoice"}
              handleSubmit={submit}
              submitOnce={submitOnce}
            >
              <div className="w-full flex flex-col border gap-2 capitalize">
                {checkData &&
                  Object.keys(checkData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{checkData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>
            </Popup>
          )}
          <div className="flex items-center justify-center space-x-4">
            {id ? null : (
              <>
                <button
                  className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
                  onClick={() => setAddInvoicePop(false)}
                >
                  {t("cancel")}
                </button>
                <button
                  className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
                  type="submit"
                  disabled={submitOnce}
                  onClick={!submitOnce && handleSubmit}
                >
                  {t("submit")}
                </button>
              </>
            )}
          </div>
        </div>
        <form>
          <div className="relative w-full p-3">
            <div className="relative flex flex-col w-[49.5%]">
              <label htmlFor="client_name">{t("clientName")}</label>
              <input
                autoComplete="off"
                type="text"
                id="client_name"
                name="client_name"
                value={Formik.values.client_name}
                className="border text-sm rounded w-full p-2.5 capitalize"
                onChange={handleUsernameChange}
                required
                onBlur={BlurHandle}
                onKeyDown={(e) => enterClick(e)}
                maxLength={50}
                disabled={id}
              />
              <button
                type="button"
                className="absolute end-[5px] flex items-center pt-9"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button>
              {show && (
                <div
                  className="absolute flex justify-start flex-col top-[4.6rem] w-full bg-white group
              shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
                >
                  {searchRslt &&
                    searchRslt.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full px-2 flex items-center space-x-2 cursor-pointer
                       hover:bg-[#031B59] group rounded"
                          onClick={() => handleEmployeedata1(item)}
                        >
                          <div className="w-full flex flex-col">
                            <p className="text-[#313131] hover:text-white capitalize p-1">
                              <div className="flex items-center">
                                <div
                                  className="w-9 h-8 rounded-full flex items-center justify-center
                                   text-white bg-[#031B59]
                              mr-2"
                                  style={{
                                    backgroundColor: getColorFromEmail(
                                      item.email
                                    ),
                                  }}
                                >
                                  {item.email?.[0]?.toUpperCase()}
                                </div>
                                <Employeebrief
                                  email={item.email}
                                  names={item.name}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>

            {Formik.errors.client_name && Formik.touched.client_name ? (
              <p className="text-[red]"> {Formik.errors.client_name}</p>
            ) : null}
          </div>
          <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
            <div className="relative flex flex-col">
              <label htmlFor="invoiceNumber">{t("invoiceNumber")}</label>
              <input
                type="text"
                name="invoice_number"
                id="invoiceNumber"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={Formik.values.invoice_number}
                onChange={(e) =>
                  setFieldValue(
                    "invoice_number",
                    e.target.value.replace(/\D/g, "")
                  )
                }
                onBlur={Formik.handleBlur}
                disabled={id}
              />
              {Formik.errors.invoice_number && Formik.touched.invoice_number ? (
                <p className="text-[red]">{Formik.errors.invoice_number}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="currency">{t("currency")}</label>
              <select
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                type="text"
                id="currency"
                name="currency"
                value={Formik.values.currency}
                onChange={Formik.handleChange}
                onKeyDown={validateKeyDown}
                onBlur={Formik.handleBlur}
                disabled={id}
              >
                <option value="">Select</option>
                <option value="Rupee">INR</option>
                <option value="Doller">USD</option>
                <option value="Canadian">CA</option>
              </select>
              {Formik.errors.currency && Formik.touched.currency ? (
                <p className="text-[red]">{Formik.errors.currency}</p>
              ) : null}
            </div>
          </div>
          <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
            <div className="relative flex flex-col">
              <label htmlFor="amount">{t("amount")}</label>
              <input
                type="text"
                name="amount"
                id="amount"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={Formik.values.amount}
                onChange={(e) =>
                  setFieldValue("amount", e.target.value.replace(/\D/g, ""))
                }
                onBlur={Formik.handleBlur}
                disabled={id}
              />
              {Formik.errors.amount && Formik.touched.amount ? (
                <p className="text-[red]">{Formik.errors.amount}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="invoiceDate">{t("invoiceDate")}</label>
              <div>
                <DatePicker
                  name={"invoice_date"}
                  id="invoice_date"
                  value={
                    values?.invoice_date === ""
                      ? getDateDashFormat(new Date())
                      : getDateDashFormat(values?.invoice_date)
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  min={new Date().toISOString()?.split("T")[0]}
                  styles={"h-[2.625rem] w-full"}
                  disabled={id}
                />
              </div>
              {Formik.errors.invoice_date && Formik.touched.invoice_date ? (
                <p className="text-[red]">{Formik.errors.invoice_date}</p>
              ) : null}
            </div>
          </div>
          <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
            <div className="relative flex flex-col ">
              <label htmlFor="dueDate">{t("dueDate")}</label>
              <div>
                <DatePicker
                  name={"due_date"}
                  id="due_date"
                  value={
                    values?.due_date === ""
                      ? getDateDashFormat(new Date())
                      : getDateDashFormat(values?.due_date)
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  styles={"h-[2.625rem] w-full"}
                  min={new Date().toISOString()?.split("T")[0]}
                  disabled={id}
                />
              </div>

              {Formik.errors.due_date && Formik.touched.due_date ? (
                <p className="text-[red]">{Formik.errors.due_date}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="status">{t("Status")}</label>
              <div className="relative">
                <select
                  id="status"
                  name="status"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                  value={Formik.values.status}
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  disabled={id}
                >
                  <option value="">{t("select")}</option>
                  <option value="paid">{t("Paid")}</option>
                  <option value="pending">{t("Pending")}</option>
                  <option value="cancel">{t("Cancelled")}</option>
                </select>

                <div className="absolute right-0 top-0 mt-3">
                  <Svg18 />
                </div>
              </div>
              {Formik.errors.status && Formik.touched.status ? (
                <p className="text-[red]">{Formik.errors.status}</p>
              ) : null}
            </div>
          </div>
          <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
            {!id && (
              <div className="flex flex-col text-[#313135] font-medium w-full">
                <label htmlFor="status_image">
                  {/* {id ? "Uploaded image" : t("upload_image")} */}
                </label>
                <label
                  role="button"
                  className="border h-[160px] flex justify-center items-center rounded p-2"
                >
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  <span>
                    {t("drag_and_drop")} <b>{t("browse_file")}</b>
                  </span>
                </label>
              </div>
            )}
            {id && (
              <div>
                <span
                  data-testid="upload_image"
                  className="text-sm text-[#313135] font-medium"
                >
                  {t("uploaded_images")}
                </span>
                <div
                  className="border h-[160px] flex flex-col items-start flex-1
                rounded-[4px] py-3 px-4 gap-4 overflow-y-auto"
                >
                  {!values.Upload_Image?.length > 0 &&
                    editInvoiceData?.invoice?.gst_files?.map((image, i) => {
                      return (
                        <a
                          key={i}
                          className="w-full cursor-pointer flex flex-row items-center justify-normal gap-2"
                          href={image.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h4>{image.filename}</h4>
                        </a>
                      );
                    })}
                </div>
              </div>
            )}
            {values.Upload_Image?.length > 0 && (
              <div className="flex flex-col gap-1">
                <span
                  data-testid="upload_image"
                  className="text-sm text-[#313135] font-medium"
                >
                  {t("uploaded_images")}
                </span>
                <div
                  className="border h-[160px] flex flex-col items-start flex-1
              rounded-[4px] py-3 px-4 gap-4 overflow-y-auto"
                >
                  <div className="w-full flex flex-col items-start gap-4 pl-2">
                    {values.Upload_Image?.map((file, index) => (
                      <li
                        key={index}
                        className="w-full font-normal flex flex-row justify-between items-center
                        text-base text-[#191919]"
                      >
                        <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem]" />
                        <span>{file.name}</span>
                        <GrFormClose
                          className="fill-[#686868] cursor-pointer stroke-[#686868]
             stroke-[1px] w-6 h-6 p-1 box-content"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

AddInvoiceDetails.propTypes = {
  setAddInvoicePop: PropTypes.any,
  searchName: PropTypes.any,
  currentPage: PropTypes.any,
  getParams: PropTypes.any,
  id: PropTypes.any,
  setEditId: PropTypes.any,
  setSearchItem: PropTypes.any,
};
