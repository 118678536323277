import React from "react";

export default function LoadSpinner() {
  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center">
      <div
        className="w-8 h-8 border-[3px] text-blue-400 text-4xl animate-spin
                border-[#031B59] flex items-center justify-center border-t-[#F3F6FD]
                rounded-full"
      ></div>
    </div>
  );
}

import PropTypes from "prop-types";

export function LoadingSpinner({
  size = "h-4 w-4",
  color = "text-blue-400",
  text = "Loading...",
  textColor = "text-gray-600",
}) {
  return (
    <div className="flex items-center space-x-2">
      <svg
        className={`animate-spin ${size} ${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="#031B59"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="#031B59"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
        7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span className={`italic ${textColor}`}>{text}</span>
    </div>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
};
