export const GetCompanyProfileDetail = (response) => {
  const jsonData = {
    id: response?.id,
    companyName: response?.company_name,
    companyContactNo: response?.contact_numbers,
    emailIdNewsLetter: response?.newsletter_email,
    emails: response?.emails,
    socialLinks: response?.social_links,
    logo_url: response?.logo_url,
    experience: response?.experiences,
    clients: response?.clients,
    projects: response?.projects,
    branches: response?.branches,

  };

  return jsonData;
};

export const formateCompanyProfile = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      companyName: obj.companyName,
      companyContactNo: obj.companyContactNo,
      emailIdNewsLetter: obj.emailIdNewsLetter,
      facebook: obj.facebook,
      instagram: obj.instagram,
    });
  });

  return requiredResponse;
};

export const Getstate = (response) => {
  const requiredResponse = [];
  requiredResponse?.push({
    state: response?.state,
  });

  return requiredResponse;
};
