import Card from "component/common/Card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardCommonPopup from "./DashboardCommonPopup";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";
import PropTypes from "prop-types";

const DashboardCommon = ({
  title,
  img,
  name,
  designation,
  count,
  data,
  message,
}) => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

  }, []);

  return (
    <Card cardClass={`p-5`}>
      <div className="h-fit w-full flex flex-col justify-between gap-5 relative ">
        <div className="flex flex-row justify-between">
          <div className="w-full h-full flex items-center ">
            <p
              className={` lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center
              text-[#031B59] gap-[0.31rem] `}
            >
              {title}
              <span className="text-[0.87rem] text-[#686868]">
                {data ? `(${data?.length})` : "(0)"}
              </span>
            </p>
          </div>
          <div>
            <button
              className="text-[#031B59] text-base whitespace-nowrap"
              onClick={() => setOpenDrawer(true)}
            >
              {t("view_all")}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-4 h-28 overflow-hidden ">
          {loading ? (
            <div data-testid="loader" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoaderComp />
            </div>
          ) :
            data?.length === 0 ? (
              <div className="text-slate-500">{message}</div>
            ) : (
              <div className="flex flex-col gap-2 h-28 overflow-hidden ">
                {data?.map((obj,id) => (
                  <div key={id} className="flex items-center gap-2 ">
                    {obj?.img ? (
                      <img
                        src={obj?.img}
                        alt="Profile Avatar"
                      />
                    ) : (
                      <ProfileAvtar
                        height="3rem"
                        width="3rem"
                        name={obj.full_name ? obj.full_name : "Employee"}
                      />
                    )}

                    <div className="tab flex flex-col  items-start    ">
                      <p className="text-[0.87rem] text-[#031B59] font-medium text-sm capitalize">
                        {obj.full_name ? obj.full_name : "Employee"}
                      </p>
                      <p className="text-xs text-[#A1A1A1]">
                        {obj?.emp_code ? obj?.emp_code : "Software Enginner"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>

      {openDrawer && (
        <DashboardCommonPopup
          title={title}
          closeDrawer={closeDrawer}
          count={count}
          img={img}
          name={name}
          designation={designation}
          data={data}
        />
      )}
    </Card>
  );
};

export default DashboardCommon;

DashboardCommon.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  name: PropTypes.string,
  designation: PropTypes.string,
  count: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    img: PropTypes.string,
    fullName: PropTypes.string,
    designation: PropTypes.shape({
      designation: PropTypes.string,
    }),
  })),
  message: PropTypes.string,
};
