import PropTypes from "prop-types";
import { awsURL } from "utils/Constants";
import React, { useEffect, useRef, useState } from "react";
import { content } from "utils/constant/Content";
import { documentContent } from "utils/constant/DocumentContent";
import { useDispatch, useSelector } from "react-redux";
import swalService from "utils/SwalServices";
import { updateUserDocuments } from "redux/appThunk/Employee/profile";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";
import SVGPlus from "../EmployeeSVG/SVGPlus";
import SVGMinus from "../EmployeeSVG/SVGMinus";
import SVGRedCross from "../EmployeeSVG/SVGRedCross";
import { fetchProfileAllDocuments} from "redux/appThunk/Admin/profileDocument";
import { useParams } from "react-router-dom";
import SVGVerified from "../EmployeeSVG/SVGVerified";
import SVGPending from "../EmployeeSVG/SVGPending";
import { HiDotsHorizontal } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export default function DocumentInfo({
  accordionOpen,
  editState,
  setEditState,
  eyeId,
}) {
  const resumeInputRef = useRef(null);
  const identityInputRef = useRef(null);
  const panInputRef = useRef(null);
  const passportInputRef = useRef(null);
  const expInputRef = useRef(null);
  const bankInputRef = useRef(null);
  const relInputRef = useRef(null);
  const anyOtherCertificateInputRef = useRef(null);
  const graduationInputRef = useRef(null);
  const marksheet12InputRef = useRef(null);
  const marksheet10InputRef = useRef(null);
  const addressInputRef = useRef(null);
  const identityStatus = useRef();
  const [fileUrl, setFileUrl] = useState("");
  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);
  const user = useSelector((state) => state.profileReducer.profileData);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [message, setMessage] = useState("");
  const [loader] = useState(true);
  const [ setLoading] = useState(true);
  const path = useParams().id;
  const [document, setDocument] = useState([
    {
      id: 0,
      name: "resume",
      header: documentContent.resume,
      ref: resumeInputRef,
      url: "",
      file: {},
    },
    {
      id: 1,
      name: "identity",
      header: documentContent.aadhar,
      ref: identityInputRef,
      url: "",
      file: {},
    },
    {
      id: 2,
      name: "pan",
      header: documentContent.pAN,
      ref: panInputRef,
      url: "",
      file: {},
    },
    {
      id: 3,
      name: "passport",
      header: documentContent.passport,
      ref: passportInputRef,
      url: "",
      file: {},
    },
    {
      id: 4,
      name: "releivingLetter",
      header: documentContent.releivingLetter,
      ref: relInputRef,
      url: "",
      file: {},
    },
    {
      id: 5,
      name: "expLetter",
      header: documentContent.expLetter,
      ref: expInputRef,
      url: "",
      file: {},
    },
    {
      id: 6,
      name: "bankStatement",
      header: documentContent.bankStatement,
      ref: bankInputRef,
      url: "",
      file: {},
    },
    {
      id: 7,
      name: "tenthMarksheet",
      header: documentContent.tenthMarksheet,
      ref: marksheet10InputRef,
      url: "",
      file: {},
    },
    {
      id: 8,
      name: "twelthMarksheet",
      header: documentContent.twelthMarksheet,
      ref: marksheet12InputRef,
      url: "",
      file: {},
    },
    {
      id: 9,
      name: "anyOtherCertificate",
      header: documentContent.anyOtherCertificate,
      ref: anyOtherCertificateInputRef,
      url: "",
      file: {},
    },
    {
      id: 10,
      name: "graduation",
      header: documentContent.graduation,
      ref: graduationInputRef,
      url: "",
      file: {},
    },
    {
      id: 11,
      name: "address",
      header: documentContent.aadhar,
      ref: addressInputRef,
      url: "",
      file: {},
    },
    {
      id: 12,
      name: "identityStatus",
      ref: identityStatus,
    },

  ]);

  const {t} = useTranslation();
  const employmentIds = [4, 5, 6];
  const educationIds = [7, 8, 9, 10];

  const [defaultValue, setDefaultVal] = useState({});

  const handleURLChange = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl((prevState) => {
        return {
          ...prevState,
          [name]: fileURL,
        };
      });
    }
  };

  const handleDocumentSubmit = () => {
    let formData = new FormData();
    for (var i = 0; i < document.length; i++) {
      if (document[i].file?.name) {
        formData.append(`document[${document[i].name}]`, document[i].file);
      }
    }
    let isChange = false;
    document.map((obj) => {
      if (obj.file?.name) {
        isChange = true;
      }
    });
    if (!isChange) {
      swalService.showWarning({
        icon: "error",
        title: "Alert!",
        text: "You haven't made any changes. Please Update first!",
        confirmButtonText: "Ok",
      });
    } else {
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(updateUserDocuments(eyeId, formData, setEditState, setHandle));
          }
        });
    }
    setVisibleSection(null);
  };
  const buttonClick = (inputRef) => {
    inputRef.current.click();
  };
  const handleFileChange = (e, fileId) => {
    const { name, files } = e.target;
    const index = document.findIndex((x) => x.id === fileId);
    const updatedObj = { ...document[index], file: files[0] };
    const updatedDocument = [...document];
    updatedDocument[index] = updatedObj;
    setDocument(updatedDocument);
    setDefaultVal((prevState) => {
      return {
        ...prevState,
        [name]: files[0]?.name,
      };
    });
  };

  const handleChequeInput = (e, name) => {
    e.preventDefault();

    const documentUrls = (() => {
      if(!user || !user.background_verification){
        return null;
      }

      return{
        resume: user.background_verification.resume_url,
        pan: user.background_verification.pan_url,
        aadhar: user.background_verification.aadhar_url,
        passport: user.background_verification.passport_url,
        bankStatement: user.background_verification.bank_statement_3_mon_url,
        releivingLetter: user.background_verification.relieving_letter_url,
        expLetter: user.background_verification.experience_letters.url,
        tenthMarksheet: user.background_verification.tenth_marksheet_url,
        twelthMarksheet: user.background_verification.experience_letter_url,
        anyOtherCertificate: user.background_verification.other_certifications_url,
        graduation: user.background_verification.graduation_degrees_url,

      };
    });

    const documentUrl = documentUrls[name];
    if (fileUrl[name]) {
      window.open(fileUrl[name], "_blank");
    }
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    }
  };

  useEffect(() => {
    if (user?.background_verification === "") {
      setDocument([]);
      setMessage("User didn't gave consent to view or update these documents. Notify user to give consent.");
    } else if (user?.background_verification) {
      setDefaultVal({
        address: user.background_verification?.address_check_documents[0].url,
        identity: user.background_verification?.identity_check_documents[0].name,
        bankStatement: user.background_verification?.bank_statements[0].name,
        releivingLetter: user.background_verification?.relieving_letters[0].name,
        expLetter: user.background_verification?.experience_letters[0].name,
        tenthMarksheet: user.background_verification?.markshseet_10th[0].name,
        twelthMarksheet: user.background_verification?.markshseet_12th[0].name,
        graduation: user.background_verification?.graduation_degrees[0].name,
        anyOtherCertificate: user.background_verification?.other_certifications[0].name,
        identityStatus: user.background_verification?.identity_check_documents_status,
      });
      dispatch(fetchProfileAllDocuments(path || userData?.id, setLoading, loader));
      setMessage("");
    }
  }, [user,path]);

  const [visibleSection, setVisibleSection] = useState(null);
  const handleButtonClick = (section) => {
    setVisibleSection((prevSection) => (prevSection === section ? null : section));
  };

  return (
    <div>
      {accordionOpen === 3 && (
        <div className="h-fit overflow-y-scroll custom_scroll m-[0.8rem]  p-3 bg-[#f2f6ff] flex justify-center">
          {user?.background_verification === "" ? (
            <div className="w-fit font-normal text-red  bg-[#FFFFFF]
            border rounded-[8px] p-2 flex justify-center items-center gap-3">
              <SVGRedCross />
              <div>{message}</div>
            </div>
          ) : (
            <div className="w-full flex flex-col">
              <div>
                <div className="flex items-center justify-between">
                  <h1 className="text-[#191919] font-roboto text-base font-semibold
                  leading-7 text-left">Identity</h1>
                  <button onClick={() => handleButtonClick('identity')} >
                    {visibleSection === 'identity' ? (
                      <SVGMinus />
                    ) : (
                      <SVGPlus />
                    )}

                  </button>
                </div>
                {visibleSection === 'identity' && (
                  <form className="w-full h-fit  gap-4 mt-2">
                    {document?.filter(obj => obj.id === 1).map((obj) => (
                      <div
                        className="relative flex flex-col space-y-2"
                        key={obj.id}
                      >
                        <div className="flex w-full items-center justify-between">
                          <label htmlFor={obj.name} className="text-[#313135]">
                            {t(obj.header)}
                          </label>
                          <div className="inline-flex justify-center items-center gap-3">
                            {defaultValue?.identityStatus === "in_progress" ? (
                              <SVGPending/>
                            ) : (
                              <SVGVerified/>
                            )
                            }
                            <HiDotsHorizontal />
                          </div>
                        </div>
                        <div
                          className="flex h-[2.625rem] w-[50%] border border-[#E2E8F0] rounded p-1
                       items-center justify-between"
                          onClick={() => buttonClick(obj.ref)}
                        >
                          <input
                            type="file"
                            name={obj.name}
                            ref={obj.ref}
                            id={obj.name}
                            accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                            className="hidden"
                            disabled={!editState || handle}
                            onChange={(e) => {
                              handleFileChange(e, obj.id);
                              handleURLChange(e);
                            }}
                          />
                          <button
                            className="text-sm"
                            onClick={(e) => handleChequeInput(e, obj.name)}
                          >
                            {defaultValue[obj.name]}
                          </button>
                          <img
                            className="pr-3 cursor-pointer"
                            src={`${awsURL}/images/cloudUpload.png`}
                            alt="cloud upload"
                          />
                        </div>

                      </div>
                    ))}
                  </form>
                )}
                <hr className="border-t-2 border-gray-300 my-4" />
                <div className="flex items-center justify-between">
                  <h1 className=" text-[#191919] font-roboto text-base font-semibold leading-7 text-left">Education</h1>
                  <button onClick={() => handleButtonClick('education')}>
                    {visibleSection === 'education' ? (
                      <SVGMinus />
                    ) : (
                      <SVGPlus />
                    )}
                  </button>
                </div>
                {visibleSection === 'education' && (
                  <form className="w-full h-fit  gap-4 mt-2">
                    {document?.filter(obj => educationIds.includes(obj.id)).map((obj) => (
                      <div
                        className="relative flex flex-col space-y-2"
                        key={obj.id}
                      >
                        <div className="flex flex-wrap items-center mt-2 justify-between">
                          <label htmlFor={obj.name} className="text-[#313135]">
                            {t(obj.header)}
                          </label>
                          <div className="inline-flex justify-center items-center gap-3">
                            {defaultValue?.identityStatus === "in_progress" ? (
                              <SVGPending/>
                            ) : (
                              <SVGVerified/>
                            )
                            }
                            <HiDotsHorizontal />
                          </div>
                        </div>
                        <div
                          className="flex h-[2.625rem] w-[50%] border border-[#E2E8F0] rounded p-1
                       items-center justify-between"
                          onClick={() => buttonClick(obj.ref)}
                        >
                          <input
                            type="file"
                            name={obj.name}
                            ref={obj.ref}
                            id={obj.name}
                            accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                            className="hidden"
                            disabled={!editState || handle}
                            onChange={(e) => {
                              handleFileChange(e, obj.id);
                              handleURLChange(e);
                            }}
                          />
                          <button
                            className="text-sm"
                            onClick={(e) => handleChequeInput(e, obj.name)}
                          >
                            {defaultValue[obj.name]}
                          </button>
                          <img
                            className="pr-3 cursor-pointer"
                            src={`${awsURL}/images/cloudUpload.png`}
                            alt="cloud upload"
                          />
                        </div>
                      </div>
                    ))}
                  </form>
                )}
                <hr className="border-t-2 border-gray-300 my-4" />
                <div className="flex items-center justify-between">
                  <h1 className=" text-[#191919] font-roboto text-base font-semibold leading-7 text-left">Address</h1>
                  <button onClick={() => handleButtonClick('address')} >
                    {visibleSection === 'address' ? (
                      <SVGMinus />
                    ) : (
                      <SVGPlus />
                    )}
                  </button>
                </div>
                {visibleSection === 'address' && (
                  <form className="w-full h-fit  gap-4 mt-2">
                    {document?.filter(obj => obj.id === 1).map((obj) => (
                      <div
                        className="relative flex flex-col space-y-2"
                        key={obj.id}
                      >
                        <div className="flex items-center justify-between">
                          <label htmlFor={obj.name} className="text-[#313135]">
                            {t(obj.header)}
                          </label>
                          <div className="inline-flex justify-center items-center gap-3">
                            {defaultValue?.identityStatus === "in_progress" ? (
                              <SVGPending/>
                            ) : (
                              <SVGVerified/>
                            )
                            }
                            <HiDotsHorizontal />
                          </div>
                        </div>
                        <div
                          className="flex h-[2.625rem] w-[50%] border border-[#E2E8F0] rounded p-1
                       items-center justify-between"
                          onClick={() => buttonClick(obj.ref)}
                        >
                          <input
                            type="file"
                            name={obj.name}
                            ref={obj.ref}
                            id={obj.name}
                            accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                            className="hidden ;"
                            disabled={!editState || handle}
                            onChange={(e) => {
                              handleFileChange(e, obj.id);
                              handleURLChange(e);
                            }}
                          />
                          <button
                            className="text-sm"
                            onClick={(e) => handleChequeInput(e, obj.name)}
                          >
                            {defaultValue[obj.name]}
                          </button>
                          <img
                            className="pr-3 cursor-pointer"
                            src={`${awsURL}/images/cloudUpload.png`}
                            alt="cloud upload"
                          />
                        </div>
                      </div>
                    ))}
                  </form>
                )}
                <hr className="border-t-2 border-gray-300 my-4" />
                <div className="flex items-center justify-between">
                  <h1 className=" text-[#191919] font-roboto text-base font-semibold leading-7 text-left">
                      Employment History</h1>
                  <button onClick={() => handleButtonClick('employment')}>
                    {visibleSection === 'employment' ? (
                      <SVGMinus />
                    ) : (
                      <SVGPlus />
                    )}
                  </button>
                </div>
                {visibleSection === 'employment' && (
                  <form className="w-full h-fit  gap-4 mt-2">
                    {document?.filter(obj => employmentIds.includes(obj.id)).map((obj) => (
                      <div
                        className="relative flex flex-col space-y-2"
                        key={obj.id}
                      >
                        <div className="flex items-center mt-2 justify-between">
                          <label htmlFor={obj.name} className="text-[#313135]">
                            {t(obj.header)}
                          </label>
                          <div className="inline-flex justify-center items-center gap-3">
                            {defaultValue?.identityStatus === "in_progress" ? (
                              <SVGPending/>
                            ) : (
                              <SVGVerified/>
                            )
                            }
                            <HiDotsHorizontal />
                          </div>
                        </div>
                        <div
                          className="flex h-[2.625rem] w-[50%] border border-[#E2E8F0] rounded p-1
                       items-center justify-between"
                          onClick={() => buttonClick(obj.ref)}
                        >
                          <input
                            type="file"
                            name={obj.name}
                            ref={obj.ref}
                            id={obj.name}
                            accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                            className="hidden ;"
                            disabled={!editState || handle}
                            onChange={(e) => {
                              handleFileChange(e, obj.id);
                              handleURLChange(e);
                            }}
                          />
                          <button
                            className="text-sm"
                            onClick={(e) => handleChequeInput(e, obj.name)}
                          >
                            {defaultValue[obj.name]}
                          </button>
                          <img
                            className="pr-3 cursor-pointer"
                            src={`${awsURL}/images/cloudUpload.png`}
                            alt="cloud upload"
                          />
                        </div>
                      </div>
                    ))}
                  </form>

                )}
                {/* <hr className="border-t-2 border-gray-300 my-4" />
              <div className="max-w-fit mb-2">
                <h1 onClick={handleOpenConsent}
                  className="text-[#031B59] font-roboto text-base font-semibold
                leading-4 text-left border-b-2 border-[#031B59] cursor-pointer ">
                  {t("consent")}
                </h1>
                {isConsentVisible &&
                  (<div className="bg-[rgba(0,0,0,0.4)] h-[100%] w-[100%] fixed
                  inset-0 flex items-center justify-center m-auto z-50 border-0 rounded-lg">
                    <EmployeeConsent onClose={handleClose} />
                  </div>)
                }
              </div> */}
              </div>
              <div>
                {userData?.role === employeeTypeObj.admin && (
                  <>
                    {editState === 3 && (
                      <>
                        {handle ? (<div className="flex items-center p-2 pt-[1rem] justify-end">
                          <LoaderComp className="h-2 w-2 size-2 bg-black" />
                        </div>

                        ) : (

                          <div className="h-fit flex justify-end items-end">
                            <button
                              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
                              onClick={() => setEditState(false)}
                            >
                              {content.cancel}
                            </button>
                            <button
                              className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                              type="submit"
                              onClick={handleDocumentSubmit}
                            >
                              {content.updateBtn}
                            </button>
                          </div>)
                        }
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

DocumentInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  eyeId: PropTypes.any,
};
