import React from 'react';
import { useTranslation } from 'react-i18next';

const SVGPending = () => {
  const { t } = useTranslation();

  return (
    <div className="w-fit p-1.5 bg-[#dee3ea] rounded-2xl justify-start items-center gap-2 inline-flex">
      <div className="text-right text-[#031b59] text-xs font-normal font-['Roboto']">{t("PendingVerification")}</div>
    </div>
  );
};

export default SVGPending;

