/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { addAssetSchema } from "redux/validator/admin/management";
import Helper from "api/Helper";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { awsURL } from "utils/Constants";
import {
  handleAddAsset,
  handleAssetsDetails,
  handleEditAsset
} from "redux/appThunk/Admin/management";
import Popup from "component/common/Popup";
import DatePicker from "component/common/DatePicker";
import PropTypes from "prop-types";

export default function AssetDetails({setOpenPopUp, id}) {
  const { getSingleCompanyTax } = useSelector(
    (state) => state?.managementReducer
  );

  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [images, setImages] = useState([]);
  const [options, setOptions] = useState();
  const [showModal, setShowModal] = useState(false);
  const ref = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    assetId: "",
    gadgetName: "",
    assigned_to: [],
    assignedBy: "",
    assignedDate: "",
    serialNo: "",
    configuration: "",
    buyingDate: "",
    buyingPrice: "",
    gadgetImages: [],
  };

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: addAssetSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (id) {
          dispatch(handleEditAsset(values, id, images));
        } else {
          dispatch(handleAddAsset(values, images));
        }
        setSubmitting(false);
        setOpenPopUp(false);
        navigate("/management", { state: { activeTab: 3 } });
      } catch (error) {
        setSubmitting(false);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = Formik;

  const buttonClick = () => {
    inputRef.current.click();
  };

  const handleImage = (event) => {
    event.preventDefault();
    window.open(getSingleCompanyTax?.gadgetImages[0]?.url, "_blank");
  };

  const checkData = {
    gadget: {
      "Gadget Name": values?.gadgetName,
      "Assigned To": values?.assigned_to?.full_name,
      "Assigned Date": values?.assignedDate,
      "Serial No.": values?.serialNo,
      "Configuration": values?.configuration,
      "Buying Date": values?.buyingDate,
      "Buying Price": values?.buyingPrice,
      "File": values?.gadgetImages,
    },
  };

  async function fetchData() {
    try {
      const path = "fetch_all_emails";
      const { response } = await Helper.get(path);
      setOptions(response.active_users);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchData();
    if (id) {
      dispatch(handleAssetsDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (getSingleCompanyTax && id && getSingleCompanyTax.gadgetImages && getSingleCompanyTax.gadgetImages.length > 0) {
      Formik.setValues((value) => ({
        ...value,
        ["assetId"]: getSingleCompanyTax.assetId,
        ["gadgetName"]: getSingleCompanyTax.gadgetName,
        ["assigned_to"]: {
          full_name: getSingleCompanyTax.assignedTo.name,
          id: getSingleCompanyTax.assignedTo.id,
        },
        ["assignedDate"]: getSingleCompanyTax.assignedDate,
        ["configuration"]: getSingleCompanyTax.configuration,
        ["buyingDate"]: getSingleCompanyTax.buyingDate,
        ["buyingPrice"]: getSingleCompanyTax.buyingPrice,
        ["gadgetImages"]: getSingleCompanyTax.gadgetImages[0].filename,
        ["serialNo"]: getSingleCompanyTax.serialNo,
      }));
      setImages(getSingleCompanyTax.gadgetImages[0].filename);
    }
  }, [getSingleCompanyTax]);

  const handleSelectedNames = (name) => {
    setFieldValue("assigned_to", name);
    setShowModal(false);
  };

  const addAssetsDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div
            data-testid="showPopUp"
            className="flex justify-center items-center"
          >
            {showPopUp && (
              <Popup
                popupBox={addAssetsDialoBox}
                title={t("invite")}
                loading={Formik.isSubmitting}
                handleSubmit={handleSubmit}
              >
                <div className="w-full flex flex-col border gap-2 ">
                  {checkData &&
                  Object.keys(checkData?.gadget)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full text-[black]">
                          {val} <span>:</span>
                        </p>
                        <p>{checkData?.gadget[`${val}`]}</p>
                      </div>
                    );
                  })}
                </div>
              </Popup>
            )}
            <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
              {id ? t("editAsset") : t("addNewAssets")}
            </h3>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
              onClick={() =>
                setOpenPopUp(false)
              }
            >
              {t("cancel")}
            </button>
            <button
              data-testid="add"
              className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={() => setShowPopUp(true)}
            >
              {id ? t("save") : t("add")}
            </button>
          </div>
        </div>

        <FormikProvider value={values}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
          >
            {id ? (
              <div className="relative flex flex-col">
                <label htmlFor="assetId">{t("assetId")}</label>
                <input
                  data-testid="assetId"
                  type="text"
                  name="assetId"
                  id="assetId"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.assetId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!!id}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.assetId && touched.assetId ? (
                  <p className="text-[red]">{errors.assetId}</p>
                ) : null}
              </div>
            ) : null}
            <div className="relative flex flex-col">
              <label htmlFor="assetName">{t("assetName")}</label>
              <input
                type="text"
                name="gadgetName"
                id="assetName"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values?.gadgetName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.gadgetName && touched.gadgetName ? (
                <p className="text-[red]">{errors.gadgetName}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="assignedTo" className="text-[#313135]">
                {t("assignedTo")}
              </label>
              <div
                data-testid="toggle-button"
                onMouseOver={() => setShowModal(true)}
                onMouseOut={() => setShowModal(false)}
                className="relative w-full max-w-full h-fit flex items-center
              cursor-pointer border rounded border-[#E2E8F0] "
              >
                <div
                  className="w-full h-fit min-h-[45px] flex flex-wrap rounded
              items-center py-[10px] px-[14px] justify-start gap-2 "
                >
                  <span className="flex flex-row items-center justify-normal gap-2 ">
                    {values?.assigned_to?.full_name}
                  </span>
                </div>
                <div className="flex items-center justify-center">
                  <BsChevronDown className="ml-auto mr-3" />
                </div>
                <div
                  ref={ref}
                  type="text"
                  name="assigned_to"
                  value={values?.assigned_to?.id}
                  className={`${
                    showModal
                      ? "flex flex-col items-start justify-normal"
                      : "hidden"
                  } p-2 z-20 w-full bg-white absolute right-0 top-[110%] h-[300px] overflow-y-scroll shadow`}
                >
                  <div className="flex flex-col gap-2">
                    {options
                      ?.filter((opt) => opt.full_name)
                      .map((option, index) => {
                        return (
                          <option
                            key={index}
                            onClick={() => {
                              handleSelectedNames(option);
                            }}
                            className=""
                          >
                            {option.full_name}
                          </option>
                        );
                      })}
                  </div>
                </div>
              </div>
              {errors.assigned_to && touched.assigned_to && (
                <p className="text-[red] mt-[0.5rem] text-wrap w-[250px]">{errors.assigned_to}</p>
              )}
            </div>

            <div className="relative flex flex-col">
              <label htmlFor="assignedBy">{t("assignedBy")}</label>
              <div className="relative">
                <input
                  type="text"
                  name="assignedBy"
                  id="assignedBy"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded appearance-none"
                  value={userData?.full_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                ></input>
              </div>
              {errors.assignedBy && touched.assignedBy ? (
                <p className="text-[red]">{errors.assignedBy}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <div data-testid = "assignedDate" className="grid">
                <label htmlFor="assignedDate" className="text-[16px]">
                  {t("assignedDate")}
                </label>
                <DatePicker
                  name={"assignedDate"}
                  value={values?.assignedDate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  styles={"w-[100%] mt-[0.25rem"}
                />
              </div>
              {errors?.assignedDate && touched?.assignedDate && (
                <p className="text-[1rem] text-red-500">{errors.assignedDate}</p>
              )}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="assetSerialNo">{t("assetSerialNo")}</label>
              <input
                type="text"
                name="serialNo"
                id="assetSerialNo"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values.serialNo}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.serialNo && touched.serialNo ? (
                <p className="text-[red]">{errors.serialNo}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="configuration">{t("configuration")}</label>
              <input
                type="text"
                name="configuration"
                id="configuration"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values.configuration}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.configuration && touched.configuration ? (
                <p className="text-[red]">{errors.configuration}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <div className="grid">
                <label htmlFor="buyingDate" className="text-[16px]">{t("buyingDate")}</label>
                <DatePicker
                  name={"buyingDate"}
                  value={values?.buyingDate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  styles={"w-[100%] mt-[0.25rem]"}
                />
              </div>
              {errors?.buyingDate && touched?.buyingDate && (
                <p className="text-[1rem] text-red-500">{errors.buyingDate}</p>
              )}
            </div>

            <div className="relative flex flex-col">
              <label htmlFor="buyingPrice">{t("buyingPrice")}</label>
              <input
                type="text"
                name="buyingPrice"
                id="buyingPrice"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                value={values.buyingPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.buyingPrice && touched.buyingPrice ? (
                <p className="text-[red]">{errors.buyingPrice}</p>
              ) : null}
            </div>
            <div className="relative flex flex-col">
              <label htmlFor="assetImages">{t("assetImages")}</label>
              <div
                className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center justify-between"
                onClick={() => buttonClick(inputRef)}
              >
                <input
                  type="file"
                  name="gadgetImages"
                  accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                  id="assetImages"
                  ref={inputRef}
                  className="hidden"
                  onChange={(e) => {
                    setFieldValue("gadgetImages", e.target.value);
                    setImages(e.target.files[0]);
                  }}
                />
                <button onClick={handleImage}>{values.gadgetImages}</button>
                <img
                  className="absolute top 0 right-[10px] cursor-pointer"
                  src={`${awsURL}/images/cloudUpload.png`}
                  alt="cloud upload"
                />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
AssetDetails.propTypes = {
  setOpenPopUp: PropTypes.any,
  id: PropTypes.any,
};
