import React from "react";
import EmployeeListing from "../component/admin/Employee/EmployeeListing";

export default function EmployeeList() {
  return (
    <>
      <EmployeeListing />
    </>
  );
}
