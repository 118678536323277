import React from "react";
import { FaFilePdf } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

// eslint-disable-next-line react/prop-types
const DashboardEyePopup = ({ closeDrawer, applicantId }) => {

  const applicantData = [
    { label: "Name", key: "fullName" },
    { label: "Email", key: "emails" },
    { label: "Applied For", key: "UI/UX Designer" },
    { label: "Applied Date", key: "19 Jun 2023" },
    { label: "Contact No.", key: "contact" },
    { label: "Resume", key: "resume" },
    { label: "Country", key: "country" },
    { label: "State", key: "states" },
    { label: "LinkedIn URL", key: "linkedin" },
    { label: "Portfolio URL", key: "portfolio" },
    { label: "experiences", key: "experiences" },
    { label: "Current CTC", key: "currentctc" },
    { label: "Expected Salary", key: "expextedSalary" },
    { label: "Notice Period", key: "noticeperiod" },
    { label: "Immediate Joiner", key: "immediateJoin" },
    { label: "How did you find out about us?", key: "references" },
  ];

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
      <div className="min-w-[40%] h-full p-5 bg-white flex flex-col space-y-8
       shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
        <div className="flex justify-between px-[0.88rem] py-[0.62rem] border-b border-b-[#E2E8F0]">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">Details</p>
          </div>
          <div>
            <RxCross2 onClick={closeDrawer} className="text-[#191919] text-xl" />
          </div>
        </div>
        <div className="px-[0.88rem]">
          <div className="grid grid-cols-2 gap-4">
            {applicantData?.map((item, index) => (
              <div key={index}>
                <p className="font-normal text-[#686868] text-sm">{item.label}</p>
                {item.key === "resume" ? (
                  <div className="flex cursor-pointer">
                    {applicantId && (
                      <a className="flex" href={applicantId[item.key]}
                        download target="_blank" rel="noopener noreferrer">
                        <FaFilePdf className="text-xl" />
                        <span className="text-xs px-1 flex">Resume</span>
                      </a>
                    )}

                  </div>
                ) : (
                  <p className="text-[#191919] text-base">  {applicantId ? (applicantId[0][item.key] || "-") : "-"}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardEyePopup;
