/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  convertTo12HourFormat,
  createDate,
  getDateDashFormat
} from "utils/date";
import { AttendanceKeys } from "component/common/enum";
import Paginate from "component/admin/Employee/Paginate";
import LoaderComp from "component/loader/LoaderComp";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance } from "redux/appThunk/Employee/attendance";
import { awsURL, monthList } from "utils/Constants";
import ApplyWfh from "./ApplyWFH";
import ApplyLeave from "./ApplyLeave";
import ApplyRegularize from "./ApplyRegularize";
import ProfileAvtar from "component/common/ProfileAvtar";
import { CiSearch } from "react-icons/ci";
import { TeamAttendPop } from "redux/appThunk/Admin/dashboard";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { FaEye } from "react-icons/fa";
import DatePicker from "component/common/DatePicker";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import ArrowLeft from "svgComponents/ArrowLeft";
import { IoFilter } from "react-icons/io5";
import FilterColumn from "./FilterColumn";
import CustomTable from "component/common/table/CustomTable";
import Back from "svgComponents/myTeam/Back";
import { fetchDepartments } from "redux/appThunk/Admin/department";

export let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

export default function AttendanceTable() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [ids, setIds] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [applyWfhPop, setApplyWfhPop] = useState(false);
  const [applyLeavePop, setApplyLeavePop] = useState(false);
  const [applyRegularizePop, setApplyRegularizePop] = useState(false);
  const [activeTab, setActiveTab] = useState("myAttendance");
  const [loading, setLoading] = useState(false);
  const { userPermission } = usePermissions(mappedPermissionObj.Dashboard);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [itemData, setItemData] = useState(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [filter, setFilter] = useState(false);
  const [timeSheetPage, setTimeSheetPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [employeeName, setEmployeeName] = useState("");
  const [loader, setLoader] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState([]);
  const { data } = useSelector((state) => state.departmentReducer);
  const [departmentId, setDepartmentId] = useState(null);

  useEffect(() => {
    dispatch(fetchDepartments(1, 10, "", setLoader));
  }, []);

  const handleTabChange = (tab) => {
    setCurrentPage(1);
    setActiveTab(tab);
  };

  const ColumnDepartment = [
    {
      title: "Employee Name",
      field: "name",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
      sorting: true,
    },
    {
      title: "Department",
      field: "department",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Designation",
      field: "designation",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const ColumnDepartmentData = [
    {
      title: "Date",
      field: "date",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
      sorting: true,
    },
    {
      title: "Punch In",
      field: "checkIn",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Punch Out",
      field: "checkOut",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Total Time",
      field: "totalTime",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Punch Type",
      field: "punchType",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Status",
      field: "work_status",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const dispatch = useDispatch();
  const { attandance, isLoading, pageCount } = useSelector(
    (state) => state.attendanceReducer
  );
  const { teampopup } = useSelector((state) => state.dashboardReducers);

  const itemsPerPage = 10;
  var sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(new Date().getDate() - 7);
  const FromDate = createDate(sevenDaysAgo);
  const ToDate = createDate(new Date());
  const menuRef = useRef();

  useEffect(() => {
    const filteredData = attandance?.filter(
      (item) =>
        item?.date.split("/").join("-") ===
        searchDate.split("-").reverse().join("-")
    );
    setFilteredData(filteredData);
  }, [searchDate]);

  const selector = useSelector((state) => state.dashboardReducers.teampopup);

  useEffect(() => {
    setFilteredData(
      selector.filter((obj) =>
        obj.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [selector, searchQuery, loading]);
  useEffect(() => {
    if (activeTab === "teamAttendance") {
      setLoading(true);
      dispatch(TeamAttendPop("team", setLoading, timeSheetPage));
    } else if (activeTab === "organizationAttendance") {
      setItemData(null);
      setLoading(true);
      dispatch(TeamAttendPop("all", setLoading, totalPage));
    } else {
      dispatch(
        fetchAttendance(userData, FromDate, ToDate, currentPage, itemsPerPage)
      );
    }
  }, [activeTab,itemsPerPage,currentPage,isTableVisible,timeSheetPage,totalPage]);

  const currentMonthName = monthList[new Date().getMonth()];

  const handleButtonClick = (id) => {
    setPopupOpen(!popupOpen);
    setIds(id);
  };

  useEffect(() => {
    setIsTableVisible(false);
  }, []);

  const handleBack = () => {
    setItemData(null);
    dispatch(TeamAttendPop("all", setLoading, totalPage));
  };

  const handleBackData = () => {
    setIsTableVisible(null);
  };
  useEffect(() => {
    const dataFilter = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setDataFilter(dataFilter);
  }, [data]);

  useEffect(() => {
    const dataFilte = data?.data?.find((item) => item?.id === departmentId);
    const dataFilterValue = dataFilte?.designation?.map((item) => ({
      id: item?.id,
      title: item?.designation,
    }));
    setDataFilterValue(dataFilterValue);
  }, [departmentId]);

  const employeeFilter = [
    { id: 1, title: "Manual" },
    { id: 2, title: "Automatic" },
  ];
  const employeeFilterValue = [
    { id: 1, title: "WFH" },
    { id: 2, title: "Ontime" },
    { id: 3, title: "2nd Half" },
    { id: 4, title: "Leave" },
    { id: 5, title: "Late" },
  ];
  const employeeValue = [
    { id: 1, title: "Date", isChecked: true, disable: true, key: "startDate" },
    {
      id: 2,
      title: "Punch In",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
    {
      id: 3,
      title: "Punch Out",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
    {
      id: 4,
      title: "Total Time",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
    {
      id: 5,
      title: "Punch Type",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
    {
      id: 6,
      title: "Status",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
    {
      id: 7,
      title: "Action",
      isChecked: true,
      disable: true,
      key: "startDate",
    },
  ];

  const employeeHeader = [
    {
      id: 1,
      title: t("date"),
    },
    {
      id: 2,
      title: t("punchIn"),
    },
    {
      id: 3,
      title: t("punchOut"),
    },
    {
      id: 4,
      title: t("totalTime"),
    },
    {
      id: 5,
      title: t("punchType"),
    },
    {
      id: 6,
      title: t("status"),
    },
    {
      id: 7,
      title: t("action"),
    },
  ];

  const header = [
    {
      id: 1,
      title: t("empName"),
    },
    {
      id: 2,
      title: t("Department"),
    },

    {
      id: 3,
      title: t("Designation"),
    },

    {
      id: 4,
      title: t("action"),
    },
  ];

  useOnClickOutside(menuRef, () => setPopupOpen(false));

  useEffect(() => {
    setFilteredData(attandance);
  }, [attandance]);

  const EyeProfile = (emp) => {
    setEmployeeName(emp?.name);
    if (!isLoading && filteredData?.length > 0) {
      setIsTableVisible(true);
    }

    dispatch(fetchAttendance(emp, FromDate, ToDate, currentPage, itemsPerPage));
  };

  const handleOpen = (job) => {
    setEmployeeName(job?.name);
    setItemData(job);
    dispatch(fetchAttendance(job, FromDate, ToDate, currentPage, itemsPerPage));
  };

  return (
    <>
      <div className=" w-[96%] gap-5 flex justify-start items-start">
        <h4
          className={`relative cursor-pointer font-medium text-[#031B59] ${
            activeTab === AttendanceKeys.MY_ATTENDANCE
              ? "active:font-extrabold"
              : "text-[#686868]"
          }`}
          onClick={() => handleTabChange(AttendanceKeys.MY_ATTENDANCE)}
        >
          {activeTab === AttendanceKeys.MY_ATTENDANCE && (
            <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
          )}
          {t("myAttendance")}
        </h4>
        <h4
          className={`relative cursor-pointer font-medium text-[#031B59] ${
            activeTab === AttendanceKeys.TEAM_ATTENDANCE
              ? "active:font-extrabold"
              : "text-[#686868]"
          }`}
          onClick={() => handleTabChange(AttendanceKeys.TEAM_ATTENDANCE)}
        >
          {activeTab === AttendanceKeys.TEAM_ATTENDANCE && (
            <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
          )}
          {t("teamAttendance")}
        </h4>
        <h4
          className={`relative cursor-pointer font-medium text-[#031B59] ${
            activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE
              ? "active:font-extrabold"
              : "text-[#686868]"
          }`}
          onClick={() =>
            handleTabChange(AttendanceKeys.ORGANIZATION_ATTENDANCE)
          }
        >
          {activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE && (
            <div className="absolute top-[1.3rem] h-[3px] w-10 bg-[#031B59]"></div>
          )}

          {t("organizationAttendance")}
        </h4>
      </div>
      {activeTab === AttendanceKeys.MY_ATTENDANCE && (
        <div
          className="w-[96%] bg-white space-y-4 flex flex-col p-5 rounded-xl
     shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          {applyWfhPop && <ApplyWfh setApplyWfhPop={setApplyWfhPop} />}
          {applyLeavePop && <ApplyLeave setApplyLeavePop={setApplyLeavePop} />}
          {applyRegularizePop && (
            <ApplyRegularize setApplyRegularizePop={setApplyRegularizePop} />
          )}
          <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
            <div className="flex justify-center items-center">
              <h4 className="font-extrabold text-xl text-[#031B59]">
                {t("attendanceDetail")}
              </h4>
            </div>
            <div className="flex space-x-4">
              <DatePicker
                name="status_date"
                value={
                  searchDate === ""
                    ? getDateDashFormat(new Date())
                    : getDateDashFormat(searchDate)
                }
                handleChange={(e) => {
                  setSearchDate(e.target.value);
                }}
              />
              <div className="flex justify-center items-center">
                <select
                  className="outline-none text-[#031B59] bg-[#fff]"
                  defaultValue={currentMonthName}
                  onChange={(e) => {
                    e.target.value;
                  }}
                >
                  {monthList?.map((monthName, index) => (
                    <option
                      key={index}
                      className={`bg-[#fff] text-[#000]`}
                      value={monthName}
                    >
                      {monthName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <div className="absolute z-50 ml-[-20rem] mt-[-4rem]">
                    <FilterColumn
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      ColumnValue={employeeValue}
                      columnFilter={t('columnFilter')}
                      dataFilter={employeeFilter}
                      dataFilterValue={employeeFilterValue}
                      title1={t('punchType')}
                      title2={t('status')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`overflow-y-auto max-h-[30rem] ${
              isLoading ? "custom_scroller" : "custom_scroll"
            }`}
          >
            {attandance && attandance?.length === 0 && !isLoading && (
              <div className="w-full h-fit flex  justify-center ">
                <div
                  className="text-Roboto text-[#A1A1A1] flex justify-center
         flex-col items-center leading-snug "
                >
                  <div>
                    <img
                      className="h-[18rem] mt-20"
                      src={`${awsURL}/images/staus.jpg`}
                      alt="not found"
                    />
                  </div>
                  <div className="mt-5">
                    <span>{t("noDataFoundForAttendance")}</span>
                  </div>
                </div>
              </div>
            )}
            {!isLoading && attandance?.length > 0 && (
              <table className="w-full p-2 bg-white rounded-lg text-left">
                <thead className="flex-0 text-left left-0">
                  <tr
                    className="h-[3.125rem] top-0  text-[#686868] text-sm font-normal
           bg-[#F2F6FF] border border-[#E2E8F0]"
                  >
                    {employeeHeader.map((item) => (
                      <th
                        key="key"
                        className="lg:w-[18.224rem] min-w-[5.5rem]
           max-h-[3.125rem]  left-0 sticky top-0 z-30 h-full p-2 bg-[#F2F6FF] text-center"
                      >
                        {item?.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-sm  font-normal flex-0">
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((data, index) => (
                      <tr
                        className="h-[3.125rem] even:bg-[#F8FAFC] text-[#23275E] border border-[#E2E8F0] text-left"
                        key={index}
                      >
                        <td className="min-w-[5.5rem] p-2 text-center">
                          {data?.date}
                        </td>
                        <td className="min-w-[5.5rem] p-2 text-center">
                          {(data?.checkIn &&
                            convertTo12HourFormat(data?.checkIn)) ||
                            "---"}
                        </td>
                        <td className="min-w-[7.5rem] p-2 text-center">
                          {(data?.checkOut &&
                            convertTo12HourFormat(data?.checkOut)) ||
                            "---"}
                        </td>
                        <td className="min-w-[5.5rem] p-2 text-center">
                          {data?.totalTime && (
                            <>
                              {parseInt(data.totalTime.split(":")[0]) > 0 &&
                                `${Math.floor(
                                  data.totalTime.split(":")[0]
                                )} hours `}
                              {parseInt(data.totalTime.split(":")[1]) > 0 &&
                                `${Math.floor(
                                  data.totalTime.split(":")[1]
                                )} minutes `}
                              {parseInt(data.totalTime.split(":")[2]) > 0 &&
                                `${parseInt(
                                  data.totalTime.split(":")[2]
                                )} seconds`}
                            </>
                          )}
                        </td>
                        <td className="min-w-[5.5rem] p-2 text-center">
                          {data?.punchType}
                        </td>
                        <td
                          className={`min-w-[5.5rem] p-2 text-center ${
                            data?.work_status === "Late"
                              ? "text-[#FF0000]"
                              : data?.work_status === "Ontime"
                                ? "text-green-500"
                                : data?.work_status === "Absent"
                                  ? "text-[#FF0000]"
                                  : "text-gray-500"
                          }`}
                        >
                          {data?.work_status || "--"}
                        </td>
                        <td
                          className="lg:w-full md:w-[4rem] p-2 py-5 flex justify-center
              items-center text-2xl"
                        >
                          <button onClick={() => handleButtonClick(data?.id)}>
                            ...
                          </button>
                          {popupOpen && data?.id === ids && (
                            <div
                              ref={menuRef}
                              className="h-fit w-fit flex bg-white absolute mt-[7rem]
                    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mr-[11rem]
                    border-[1px] rounded-md justify-center items-center border-[#E2E8F0] p-1"
                            >
                              <div
                                className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                    items-start flex flex-col text-[black]"
                              >
                                <div
                                  onClick={() => {
                                    setApplyWfhPop(true);
                                  }}
                                  className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                >
                                  Apply WFH
                                </div>
                                <div
                                  className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem] "
                                  onClick={() => {
                                    setApplyLeavePop(true);
                                  }}
                                >
                                  Apply Leave
                                </div>
                                <div
                                  className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                  onClick={() => {
                                    setApplyRegularizePop(true);
                                  }}
                                >
                                  Regularize
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%">
                        <div className="w-full h-fit flex  justify-center m-auto ">
                          <div
                            className="text-Roboto text-[#A1A1A1] flex justify-center
         flex-col items-center leading-snug "
                          >
                            <div>
                              <img
                                className="h-[18rem] mt-20"
                                src={`${awsURL}/images/staus.jpg`}
                                alt="not found"
                              />
                            </div>
                            <div className="mt-5">
                              <span>{t("noDataFoundForAttendance")}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                {isLoading && (
                  <div className="flex items-center  justify-center h-[80vh] w-full">
                    <LoaderComp />
                  </div>
                )}
              </table>
            )}
          </div>
          {!isLoading && attandance?.length > 0 && (
            <div className="w-full h-26 bg-white flex justify-between items-center">
              {attandance?.length > 0 && (
                <>
                  <div className="text-[#031B59] font-medium">
                    {t("showing")} {currentPage} {t("of")} {pageCount}
                  </div>{" "}
                  <Paginate
                    currentPage={currentPage}
                    initialPageCount={pageCount}
                    pageRangeDisplayed={5}
                    next=">"
                    previous="<"
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {activeTab === AttendanceKeys.TEAM_ATTENDANCE && (
        <div
          className="w-[96%] bg-white space-y-4 flex flex-col p-5 rounded-xl
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          {applyWfhPop && <ApplyWfh setApplyWfhPop={setApplyWfhPop} />}
          {applyLeavePop && <ApplyLeave setApplyLeavePop={setApplyLeavePop} />}
          {applyRegularizePop && (
            <ApplyRegularize setApplyRegularizePop={setApplyRegularizePop} />
          )}
          <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
            <div className="flex justify-center items-center">
              <h4 className="font-extrabold text-xl text-[#031B59]">
                {isTableVisible ? (
                  <div className="flex gap-2">
                    <div className="mt-[0.2rem]" onClick={handleBackData}>
                      <Back />
                    </div>{" "}
                    {employeeName}
                  </div>
                ) : (
                  t("organizationEmployee")
                )}
              </h4>
              {isTableVisible && <ArrowLeft />}
            </div>
            <div className="flex space-x-4">
              {!isTableVisible && (
                <div
                  className="  flex justify-between px-[0.88rem] py-[0.62rem]
             border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
                >
                  <div className="w-full">
                    <input
                      type="search"
                      placeholder="Search"
                      className="text-[#A1A1A1] w-full"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div>
                    <CiSearch
                      className="text-[#A1A1A1] text-lg"
                      searchItem={searchItem}
                      setSearchItem={setSearchItem}
                      currentResource={userPermission}
                    />
                  </div>
                </div>
              )}
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <div className="absolute z-50 ml-[-20rem] mt-[-11rem]">
                    <FilterColumn
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      ColumnValue={isTableVisible ? employeeValue : null}
                      dataFilter={isTableVisible ? employeeFilter : dataFilter}
                      dataFilterValue={
                        isTableVisible ? employeeFilterValue : dataFilterValue
                      }
                      columnFilter={t('columnFilter')}
                      title1={isTableVisible ? "Punch Type" : "Department"}
                      title2={isTableVisible ? "Status" : "Designation"}
                      setDepartmentId={setDepartmentId}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`overflow-y-auto max-h-[30rem] ${
              isLoading ? "custom_scroller" : "custom_scroll"
            }`}
          >
            {
              <>
                {isTableVisible ? (
                  <table className="w-full p-2 bg-white rounded-lg text-left">
                    <thead className="flex-0 text-left left-0">
                      <tr
                        className="h-[3.125rem] top-0  text-[#686868] text-sm font-normal
           bg-[#F2F6FF] border border-[#E2E8F0]"
                      >
                        {""}
                        {employeeHeader.map((item) => (
                          <th
                            key="key"
                            className="lg:w-[18.224rem] min-w-[5.5rem]
           max-h-[3.125rem]  left-0 sticky top-0 z-30 h-full p-2 bg-[#F2F6FF] text-center"
                          >
                            {item?.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="text-sm  font-normal flex-0">
                      {attandance && attandance.length > 0 ? (
                        attandance.map((data, index) => (
                          <tr
                            className="h-[3.125rem] even:bg-[#F8FAFC] text-[#23275E] border border-[#E2E8F0] text-left"
                            key={index}
                          >
                            <td className="min-w-[5.5rem] p-2">{data?.date}</td>
                            <td className="min-w-[5.5rem] p-2 text-center">
                              {(data?.checkIn &&
                                convertTo12HourFormat(data?.checkIn)) ||
                                "---"}
                            </td>
                            <td className="min-w-[7.5rem] p-2 text-center">
                              {(data?.checkOut &&
                                convertTo12HourFormat(data?.checkOut)) ||
                                "---"}
                            </td>
                            <td className="min-w-[5.5rem] p-2 text-center">
                              {data?.totalTime && (
                                <>
                                  {parseInt(data.totalTime.split(":")[0]) > 0 &&
                                    `${Math.floor(
                                      data.totalTime.split(":")[0]
                                    )} hours `}
                                  {parseInt(data.totalTime.split(":")[1]) > 0 &&
                                    `${Math.floor(
                                      data.totalTime.split(":")[1]
                                    )} minutes `}
                                  {parseInt(data.totalTime.split(":")[2]) > 0 &&
                                    `${parseInt(
                                      data.totalTime.split(":")[2]
                                    )} seconds`}
                                </>
                              )}
                            </td>
                            <td className="min-w-[5.5rem] p-2 text-center">
                              {data?.punchType}
                            </td>
                            <td
                              className={`min-w-[5.5rem] p-2 text-center ${
                                data?.work_status === "Late"
                                  ? "text-[#FF0000]"
                                  : data?.work_status === "Ontime"
                                    ? "text-green-500"
                                    : data?.work_status === "Absent"
                                      ? "text-[#FF0000]"
                                      : "text-gray-500"
                              }`}
                            >
                              {data?.work_status || "--"}
                            </td>
                            <td
                              className="lg:w-full md:w-[4rem] p-2 py-5 flex justify-center
              items-center text-2xl"
                            >
                              <button
                                onClick={() => handleButtonClick(data?.id)}
                              >
                                ...
                              </button>
                              {popupOpen && data?.id === ids && (
                                <div
                                  ref={menuRef}
                                  className="h-fit w-fit flex bg-white absolute mt-[7rem]
                    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mr-[11rem]
                    border-[1px] rounded-md justify-center items-center border-[#E2E8F0] p-1"
                                >
                                  <div
                                    className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                    items-start flex flex-col text-[black]"
                                  >
                                    <div
                                      onClick={() => {
                                        setApplyWfhPop(true);
                                      }}
                                      className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                    >
                                      Apply WFH
                                    </div>
                                    <div
                                      className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                      onClick={() => {
                                        setApplyLeavePop(true);
                                      }}
                                    >
                                      Apply Leave
                                    </div>
                                    <div
                                      className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                      onClick={() => {
                                        setApplyRegularizePop(true);
                                      }}
                                    >
                                      Regularize
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="100%">
                            <div className="w-full h-fit flex  justify-center m-auto ">
                              <div
                                className="text-Roboto text-[#A1A1A1] flex justify-center
         flex-col items-center leading-snug "
                              >
                                <div>
                                  <img
                                    className="h-[18rem] mt-20"
                                    src={`${awsURL}/images/staus.jpg`}
                                    alt="not found"
                                  />
                                </div>
                                <div className="mt-5">
                                  <span>{t("noDataFoundForAttendance")}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {isLoading && (
                      <div className="flex items-center  justify-center h-[80vh] w-full">
                        <LoaderComp />
                      </div>
                    )}
                  </table>
                ) : (
                  <table className="w-full">
                    <thead className="flex-0 text-left left-0">
                      <tr
                        className="h-[3.125rem] top-0  text-[#686868] text-sm font-normal
           bg-[#F2F6FF] border border-[#E2E8F0]"
                      >
                        {header.map((item) => (
                          <th
                            key={item.id}
                            className="lg:w-[18.224rem] min-w-[5.5rem]
                    max-h-[3.125rem]  left-0 sticky top-0 z-30 h-full p-2 bg-[#F2F6FF]"
                          >
                            {item.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="text-sm  font-normal flex-0">
                      {filteredData?.length === 0 ? (
                        <tr>
                          <td colSpan="100%">
                            <div className="w-full h-fit flex  justify-center m-auto ">
                              <div
                                className="text-Roboto text-[#A1A1A1] flex justify-center
    flex-col items-center leading-snug "
                              >
                                <div>
                                  <img
                                    className="h-[18rem] mt-20"
                                    src={`${awsURL}/images/staus.jpg`}
                                    alt="not found"
                                  />
                                </div>{" "}
                                <div className="mt-5">
                                  <span>{t("noDataFoundForAttendance")}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        filteredData?.map((item, id) => (
                          <tr
                            className="h-[3.125rem] even:bg-[#F8FAFC] text-[#23275E] border border-[#E2E8F0] text-left"
                            key={id}
                          >
                            <td className="min-w-[5.5rem] p-2  border-b">
                              <div className="flex items-center gap-2">
                                <ProfileAvtar name={item?.name} />
                                {item?.name &&
                                item.name.replace(/^\w/, (c) => c.toUpperCase())
                                  ? item?.name
                                  : "User"}
                              </div>
                            </td>
                            <td className="min-w-[5.5rem] p-2 border-b ">
                              {item?.department &&
                              item.department.replace(/^\w/, (c) =>
                                c.toUpperCase()
                              )
                                ? item?.department
                                : "IT Department"}
                            </td>
                            <td className="min-w-[5.5rem] p-2 border-b">
                              {item?.designation &&
                              item.designation.replace(/^\w/, (c) =>
                                c.toUpperCase()
                              )
                                ? item?.designation
                                : "Software Engineer"}
                            </td>
                            <td className="min-w-[5.5rem] p-2 border-b">
                              <button
                                className="mr-[6px]"
                                onClick={() => EyeProfile(item)}
                              >
                                <FaEye fontSize="20px" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan="7" className=" items-center mt-3">
                          <LoaderComp />
                        </td>
                      </tr>
                    )}
                  </table>
                )}
              </>
            }
          </div>
          {!isLoading && filteredData?.length > 0 && (
            <div className="w-full h-26 bg-white flex justify-between items-center">
              {filteredData?.length > 0 && (
                <>
                  <div className="text-[#031B59] font-medium">
                    {t("showing")} {timeSheetPage} {t("of")} {pageCount}
                  </div>{" "}
                  <Paginate
                    currentPage={timeSheetPage}
                    initialPageCount={pageCount}
                    pageRangeDisplayed={5}
                    next=">"
                    previous="<"
                    setCurrentPage={setTimeSheetPage}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {""}
      {activeTab === AttendanceKeys.ORGANIZATION_ATTENDANCE && (
        <div
          className="w-[96%] bg-white space-y-4 flex flex-col p-5 rounded-xl
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          {applyWfhPop && <ApplyWfh setApplyWfhPop={setApplyWfhPop} />}
          {applyLeavePop && <ApplyLeave setApplyLeavePop={setApplyLeavePop} />}
          {applyRegularizePop && (
            <ApplyRegularize setApplyRegularizePop={setApplyRegularizePop} />
          )}
          <div className="w-full h-fit bg-white pl-5 pr-5 flex justify-between">
            <div className="flex justify-center items-center">
              <div>
                <h4 className="font-extrabold text-xl text-[#031B59]">
                  {itemData ? (
                    <div className="flex gap-2">
                      <div className="mt-[0.2rem]" onClick={handleBack}>
                        <Back />
                      </div>{" "}
                      {employeeName}
                    </div>
                  ) : (
                    t("organizationEmployee")
                  )}
                </h4>
              </div>
            </div>
            <div className="flex space-x-4">
              {!itemData && (
                <div
                  className="  flex justify-between px-[0.88rem] py-[0.62rem]
           border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
                >
                  <div className="w-full">
                    <input
                      type="search"
                      placeholder="search"
                      className="text-[#A1A1A1] w-full"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div>
                    <CiSearch
                      className="text-[#A1A1A1] text-lg"
                      searchItem={searchItem}
                      setSearchItem={setSearchItem}
                      currentResource={userPermission}
                    />
                  </div>
                </div>
              )}
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <div className="absolute z-50 ml-[-20rem] mt-[-4rem]">
                    <FilterColumn
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      ColumnValue={itemData ? employeeValue : null}
                      dataFilter={itemData ? employeeFilter : dataFilter}
                      dataFilterValue={
                        itemData ? employeeFilterValue : dataFilterValue
                      }
                      columnFilter={t('columnFilter')}
                      title1={itemData ? "Punch Type" : "Department"}
                      title2={itemData ? "Status" : "Designation"}
                      setDepartmentId={setDepartmentId}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="h-[30rem] overflow-y-auto custom_scroll">
            <CustomTable
              isLoading={loader}
              columns={
                itemData && activeTab === "organizationAttendance"
                  ? ColumnDepartmentData
                  : ColumnDepartment
              }
              data={
                itemData && activeTab === "organizationAttendance"
                  ? filteredData
                  : teampopup
              }
              renderAction={(job) => (
                <div>
                  <h4 className="font-extrabold text-xl text-[#031B59]">
                    {itemData ? (
                      <div className="flex">
                        <div onClick={() => handleButtonClick(job?.id)}>
                          ...
                        </div>
                        {popupOpen && job?.id === ids && (
                          <div
                            ref={menuRef}
                            className="h-fit w-fit flex bg-white absolute
                             shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] ml-[-9rem]
                             border-[1px] rounded-md justify-center items-center border-[#E2E8F0] p-1"
                          >
                            <div
                              className="font-Roboto text-[0.875rem] leading-[0.857rem] font-normal
                             items-start flex flex-col text-[black]"
                            >
                              <div
                                onClick={() => {
                                  setApplyWfhPop(true);
                                }}
                                className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                              >
                                Apply WFH
                              </div>
                              <div
                                className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                onClick={() => {
                                  setApplyLeavePop(true);
                                }}
                              >
                                Apply Leave
                              </div>
                              <div
                                className="cursor-pointer hover:bg-[#F1F5F9] rounded-md p-3 w-[7.85rem]"
                                onClick={() => {
                                  setApplyRegularizePop(true);
                                }}
                              >
                                Regularize
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div onClick={() => handleOpen(job)}>
                        <FaEye />
                      </div>
                    )}
                  </h4>
                </div>
              )}
            />
          </div>
          <div className="w-full h-26 bg-white flex justify-between items-center">
            <div className="text-[#031B59] font-medium">
              {t("showing")} {totalPage} {t("of")} {pageCount}
            </div>{" "}
            <Paginate
              currentPage={totalPage}
              initialPageCount={pageCount}
              pageRangeDisplayed={5}
              next=">"
              previous="<"
              setCurrentPage={setTotalPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
