/* eslint-disable max-len */
import React from "react";

const MyProject2Svg = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.22569 6.12169H10.7767V7.67271H9.22569V6.12169ZM9.22569 9.22373H10.7767V13.8768H9.22569V9.22373ZM10.0012 2.24414C5.72038 2.24414 2.24609 5.71843 2.24609 9.99924C2.24609 14.2801 5.72038 17.7543 10.0012 17.7543C14.282 17.7543 17.7563 14.2801 17.7563 9.99924C17.7563 5.71843 14.282 2.24414 10.0012 2.24414ZM10.0012 16.2033C6.5812 16.2033 3.79711 13.4192 3.79711 9.99924C3.79711 6.57924 6.5812 3.79516 10.0012 3.79516C13.4212 3.79516 16.2053 6.57924 16.2053 9.99924C16.2053 13.4192 13.4212 16.2033 10.0012 16.2033Z"
          fill="#686868"
        />
      </svg>
    </div>
  );
};

export default MyProject2Svg;
