import Card from "component/common/Card";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProject } from "redux/appThunk/Admin/project";
import { onGoingData } from "redux/appThunk/Admin/dashboard";
import { IoIosArrowDown } from "react-icons/io";

const DashboardOnGoingProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const selector = useSelector((state) => state.dashboardReducers.ongoing);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    dispatch(onGoingData());
    dispatch(fetchProject(1));
  }, [dispatch]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div>
      <Card
        cardClass="h-[12.12rem] w-full flex flex-col col-span-2 p-5"
        onClick={() => navigate("/projects")}
      >
        <div className="w-full h-full flex justify-between ">
          <p
            className={`lg:text-lg sm:text-1xl leading-2 flex font-medium 
              text-[#031B59] cursor-pointer`}
            onClick={() => navigate("/projects")}
          >
            {t("ongoing_projects")}
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <h4 className="lg:text-2xl sm:text-lg font-bold text-[#031B59]">
              {selector?.ongoingProject ?? 0}
            </h4>
          </div>
          <div className="h-fit flex">
            <button
              ref={buttonRef}
              className="flex gap-1 items-center justify-center text-base text-[#031B59]"
              onClick={toggleDropdown}
            >
              {t("project_name")}
              <IoIosArrowDown />
            </button>
          </div>
          {showDropdown && (
            <div
              ref={dropdownRef}
              className="absolute mt-2 w-49 ml-80 mt-20 rounded-md
              shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            >
              {selector?.ongoingProjectName?.length > 0 ? (
                selector?.ongoingProjectName?.map((item, index) => (
                  <p
                    key={index}
                    className="block px-4 py-2 text-gray-800 hover:bg-[#F2F6FF] cursor-pointer"
                  >
                    {item}
                  </p>
                ))
              ) : (
                <p className="block px-4 py-2 text-gray-800 cursor-default">
                  No data found
                </p>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DashboardOnGoingProject;
