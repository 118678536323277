import LoaderComp from 'component/loader/LoaderComp';
import React, { useEffect } from 'react';
import { LuArrowUpDown } from "react-icons/lu";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InvoiceTable = ({
  handleHeaderClick,
  setStatusModal,
  invoiceList,
  sortBy,
  sortOrder,
  setInvoiceList,
  statusModal,
  currentPage,
  renderRow,
}) => {
  const { invoiceData, isLoading } = useSelector(
    (state) => state.invoiceReducer
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (invoiceData) {
      const sorted = sortData(invoiceData, sortBy, sortOrder);
      setInvoiceList(sorted);
    }
  }, [sortBy, sortOrder, currentPage, invoiceData]);

  const sortData = (data, sortBy, sortOrder) => {
    if (data && data.length > 0) {
      const sorted = [...data].sort((a, b) => {
        const valueA = a[sortBy] || "";
        const valueB = b[sortBy] || "";
        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB, undefined, { numeric: true });
        } else {
          return valueB.localeCompare(valueA, undefined, { numeric: true });
        }
      });

      return sorted;
    }
  };

  return (
    <div className="">
      <div>
        <table className="w-full p-5 bg-white rounded-lg text-left">
          <thead className="sticky top-0 bg-white z-10">
            <tr
              className="max-h-[3.125rem] h-[3.125rem] p-2 text-[#686868] text-sm
           font-normal"
            >
              <th
                className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 max-h-[3.125rem] h-full
               bg-[#F2F6FF] sticky top-0 z-10 left-0"
              >
                {t("client_name")}
              </th>
              <th
                className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] max-h-[3.125rem] h-full p-2 sticky top-0
              bg-[#F2F6FF]"
              >
                {t("amount")}
              </th>
              <th
                className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] max-h-[3.125rem] h-full p-2 sticky top-0
                bg-[#F2F6FF]"
                onClick={() => handleHeaderClick("invoice_date")}
              >
                <div className="flex gap-8">
                  <span>{t("invoice_date")}</span>
                  <span className="cursor-pointer">
                    <LuArrowUpDown fontSize="22px" onClick={sortData} />
                  </span>
                </div>
              </th>
              <th
                className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]"
                onClick={() => handleHeaderClick("due_date")}
              >
                <div className="flex gap-8">
                  <span>{t("due_date")}</span>
                  <span className="cursor-pointer">
                    <LuArrowUpDown fontSize="22px" onClick={sortData} />
                  </span>
                </div>
              </th>
              <th className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                <div className="flex gap-8">
                  <span>{t("Status")}</span>
                  <span
                    className="relative cursor-pointer"
                    onClick={() => setStatusModal(!statusModal)}
                  >
                    <LuArrowUpDown fontSize="22px" />
                  </span>
                </div>
              </th>
              <th className="lg:w-[10rem]  md:w-[6rem] md:p-[1.5rem] p-2 bg-[#F2F6FF] sticky top-0">
                {t("gstInvoice")}
              </th>
              <th className="lg:w-[10rem]  md:w-[6rem] md:p-[1.5rem] p-2 sticky right-0 bg-[#F2F6FF] z-10 top-0">
                {t("action")}
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody className="p-2 text-sm text-left font-normal flex-0">
              {invoiceList?.length > 0 &&
                invoiceList?.map((data, index) => renderRow(data, index))}
            </tbody>
          )}
        </table>
        {isLoading && (
          <div className="items-center mt-3">
            <LoaderComp />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceTable;

InvoiceTable.propTypes = {
  handleHeaderClick: PropTypes.any,
  setStatusModal: PropTypes.func.isRequired,
  invoiceList: PropTypes.func.isRequired,
  sortBy: PropTypes.func.isRequired,
  sortOrder: PropTypes.func.isRequired,
  setInvoiceList: PropTypes.func.isRequired,
  statusModal: PropTypes.func.isRequired,
  currentPage: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};
