import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { setCompanyProfile, setCountryName } from "redux/actions/action";
import { GetCompanyProfileDetail} from "redux/selector/Admin/companyProfile";
import { setStateName } from "redux/actions/action";
import { setCityNames } from "redux/actions/action";

export const fetchCompanyProfileDetails = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const path = `/company_profiles/get_company`;
    const { response } = await Helper.get(path);
    const formattedResponse = GetCompanyProfileDetail(response);
    dispatch(setCompanyProfile(formattedResponse));
    setLoading(false);
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
    setLoading(false);
  }
};

export const updateProfile = (formData,setProfileReload,setIsEdit) => async () => {
  try {
    setProfileReload(false);
    const path = "/company_profiles/update_company_profile";
    const { status } = await Helper.put(formData,path,true);
    if (status === 201 || status === 200) {
      setProfileReload(true);
      setIsEdit(false);
      ToastServices.showToast({
        message: "Company Profile Updated !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const createProfile = (formData) => async () => {
  try {
    const path = apiUrl?.createCompany;

    const { status} = await Helper.post(formData, path,true);
    if (status === 201 || status === 200) {
      ToastServices.showToast({
        message: "Company Profile Created !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: "company profile already exits",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handleCountryName = () => async (dispatch) => {
  try {
    const path = "/country_state";

    const { response } = await Helper.get(path);
    dispatch(setCountryName(response?.country));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handleStateChange = (country) => async (dispatch) => {
  try {
    const path = `state_city?country=${country}`;

    const { response } = await Helper.get(path);
    dispatch(setStateName(response?.state));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handlecitychange = (state) => async (dispatch) => {
  try {
    const path = `city_add?state=${state}`;

    const { response } = await Helper.get(path);
    dispatch(setCityNames(response?.city));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handleDeleteBranch = (id) => async () => {
  try {
    const path = `company_profiles/delete_company_detail/${id}`;

    const {status} = await Helper.delete(path);
    if(status === 200) {
      ToastServices.showToast({
        message: "Branch Deteted Successfully !",
        type: "success",
        autoClose: 2000,
      });

    }
  }catch(error){
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 2000,
    });

  }

};
