import React from 'react';
import Table from "./AttendanceTable";
import AttendanceTypeDisplay from './AttendanceTypeDisplay';

const Attendance = () => {
  return (
    <div className="w-full flex items-center flex-col gap-8">
      <AttendanceTypeDisplay />
      <Table/>
    </div>
  );
};

export default Attendance;
