/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {createFeedGet,createFeeddelete} from "redux/appThunk/Admin/dashboard";
import Card from "component/common/Card";
import DashboardLatestFeedPopUp from "./DashboardLatestFeedPopUp";
import DashboardCommonPopup from "./DashboardCommonPopup";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";

const DashboardLatestFeed = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openId, setOpenId] = useState(null);
  const [handlePopupOpen, setHandlePopupOpen] = useState("");
  const [handleViewAll, setHandleViewAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const { feedlatest, isFeedDelete } = useSelector(
    (state) => state.dashboardReducers
  );
  useEffect(() => {
    setLoading(true);
    dispatch(createFeedGet()).finally(() => setLoading(false));
  }, [isFeedDelete, dispatch]);

  const handleClick = (type) => {
    setHandlePopupOpen(type);
    setIsOpen(false);
  };
  const handleDelete = () => {
    if (openId) {
      dispatch(createFeeddelete(openId));
      setIsOpen(false);
    }
  };

  const handleViewClick = () => {
    setHandleViewAll(!handleViewAll);
  };

  const options = [
    {
      id: 1,
      title: t("view_all"),
    },
    {
      id: 2,
      title: t("createNewFeed"),
      btn: <HiOutlineDotsHorizontal />,
    },
  ];
  const option1 = [
    {
      id: 1,
      title: t("edit"),
    },
    {
      id: 2,
      title: t("delete"),
    },
  ];

  return (
    <Card
      cardClass={
        "w-full flex flex-col col-span-2 gap-[1.25rem] p-5 overflow-y-scroll"
      }
    >
      <div className="flex flex-row justify-between">
        <div>
          <h4 className="text-lg font-medium text-[#031B59]">
            {t("latestFeeds")}
          </h4>
        </div>
        <div className="flex items-end relative">
          <button
            className="flex gap-2 items-center justify-center font-medium text-[#A1A1A1] text-base"
            onMouseEnter={() => setIsDropdownOpen(true)}
          >
            <HiOutlineDotsHorizontal />
          </button>
          {isDropdownOpen && (
            <div
              className="bg-white p-3 rounded-md z-10 max-h-[200px] overflow-y-scroll"
              style={{
                position: "absolute",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 10,
                top: "100%",
                right: 0,
              }}
            >
              <ul onMouseLeave={() => setIsDropdownOpen(false)}>
                {options?.map((item, i) => (
                  <li
                    key={item.id}
                    onClick={() =>
                      i === 1 ? handleClick("add") : ''
                    }
                    className={`block px-4 py-2 text-gray-800
                      hover:bg-[#F2F6FF] whitespace-nowrap rounded-lg cursor-pointer`}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-5 h-[17rem] no-scrollbar overflow-y-scroll">

        {loading ? (
          <LoaderComp/>
        ) : feedlatest.length === 0 ? (
          <p className="text-center text-gray-500">{t("noFeedsAvailable")}</p>
        ) :
          feedlatest?.map((item, i) => (
            <div
              className="border border-[#E2E8F0] rounded-lg p-[0.875rem] flex justify-between items-start gap-2"
              key={i}
            >
              <div className="flex flex-col gap-4">
                <div className="flex gap-1 items-center">
                  {item?.img ? (
                    <img
                      className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
                      src={item?.img}
                      alt="Profile Avatar"
                    />
                  ) : (
                    <ProfileAvtar
                      height="3rem"
                      width="3rem"
                      name={item.creatorname ? item.creatorname : "User"}
                    />
                  )}
                  <div>
                    <div>
                      <h3 className="text-sm text-[#191919] capitalize">
                        {item.creatorname ? item.creatorname : "User" }
                        <span className=" text-xs ml-1 text-[#A1A1A1] lowercase">
                          {t("createdAnAnnouncement")}
                        </span>
                      </h3>
                    </div>
                    <div>
                      <p className="text-xs text-[#686868]">{item. created_at ? item. created_at : "6 May 12:00" }</p>
                    </div>
                  </div>
                </div>
                <p className="w-[40.31rem] text-sm  font-semibold text-[#191919]">
                  {item.title ? item.title : "Title" }
                </p>
                <p className="w-[40.31rem] text-sm text-[#191919]">
                  {item.description ? item.description : "Description"}
                </p>
              </div>
              <div>
                <div
                  className="  h-[5rem]"
                >
                  <div className="ml-auto w-[1rem] relative">
                    <button
                      className=" py-2 text-sm text-[#A1A1A1]"
                      onClick={() => {
                        setOpenId(item.user_Id);
                        setIsOpen(!isOpen);
                      }}
                    >
                      <HiOutlineDotsHorizontal />
                    </button>
                    {isOpen && openId === item.user_Id && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg">
                        {option1?.map((option, index) => (
                          <a
                            key={option.id}
                            href="#"
                            className="block px-4 py-2 text-sm hover:bg-[#F2F6FF] whitespace-nowrap rounded-lg text-[#191919] "
                            onClick={() =>
                              index === 0 ? handleClick("edit") : handleDelete()
                            }
                          >
                            {option.title}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          ))
        }
      </div>
      {handlePopupOpen !== "" && (
        <DashboardLatestFeedPopUp
          setClose={() => handleClick("")}
          title={handlePopupOpen === "add" ? "New Feed" : "Edit Feed"}
          id={openId}
        />
      )}
      {handleViewAll && (
        <DashboardCommonPopup
          title={"All Feeds"}
          count={""}
          closeDrawer={handleViewClick}
          data={[]}
        />
      )}
    </Card>
  );
};

export default DashboardLatestFeed;
