/* eslint-disable no-unused-vars */
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { getCurrentYear } from "utils/date";
import {
  setDashboardData,
  setMonthlyReview,
  setTotalAvg,
  setTotalWorkingDays,
  setYourAvg,
  setcheckIn,
  timerData
} from "redux/actions/action";
import {
  checkIn,
  formatOverviewReview,
  formatPunchInOutResponse,
  formatPunchInRequest,
  formatPunchOutRequest,
  formatTimerData,
  formatTotalAvg,
  formatTotalWorkingDaysResponse,
  formatYourAvg
} from "redux/selector/Employee/dashboard";
import ToastServices from "ToastServices";

export const punchInOutDetails = () => async (dispatch) => {
  try {
    const path = `${apiUrl.dashboard}?period=${getCurrentYear()}`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatPunchInOutResponse(response?.dashboard);
    dispatch(setDashboardData(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error during fetch data !",
      autoClose: 3000,
    });
  }
};

export const fetchTimerData = (userData) => async (dispatch) => {
  try {
    const path = `${apiUrl.timer}?emp_code=${userData?.emp_code}`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatTimerData(response?.data);
    dispatch(timerData(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const totalWorkingDays = (totalworkingCardMonth) => async (dispatch) => {
  try {
    const path = `${apiUrl.totalWorkingDays}?period=${totalworkingCardMonth}`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatTotalWorkingDaysResponse(response);
    dispatch(setTotalWorkingDays(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const setPunchIn = (position) => async () => {
  try {
    const request = formatPunchInRequest(position);
    const path = apiUrl.punchIn;
    const { status, response } = await Helper.post(request, path);
    if (status === 200) {
      ToastServices.showToast({
        type: "success",
        message: "Checked in successfully from office ",
        autoClose: 3000,
      });
    } else {
      ToastServices.showToast({
        message: "You cannot check in from your current location",
        type: "error",
        autoClose: 3000,
      });
    }

    return status;
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error!",
      autoClose: 3000,
    });
  }
};

export const setPunchOut = (userData) => async () => {
  try {
    const request = formatPunchOutRequest(userData);
    const path = apiUrl.punchOut;
    const { status, response } = await Helper.post(request, path);
    if (status === 200) {
      ToastServices.showToast({
        message: response.message,
        type: "success",
      });
    }

    return status;
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }

};

export const totalAvgData = () => async (dispatch) => {
  try {
    const path = apiUrl.totalAverage;
    const { response } = await Helper.get(path);
    const formattedResponse = formatTotalAvg([response.total_average]);
    dispatch(setTotalAvg(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const yourAvgData = () => async (dispatch) => {
  try {
    const path = apiUrl.yourAverage;
    const { response } = await Helper.get(path);
    const formattedResponse = formatYourAvg(response.yourAverage);
    dispatch(setYourAvg(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error during fetch data !",
      autoClose: 3000,
    });
  }
};

export const overviewReviewData = () => async (dispatch) => {
  try {
    const path = apiUrl.overiewReview;
    const { response } = await Helper.get(path);
    const formattedResponse = formatOverviewReview(response?.ratings);
    dispatch(setMonthlyReview(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const loginSummary = () => async (dispatch) => {
  try {
    const path = `check_in_out/login_summary`;
    const { response } = await Helper.get(path);
    const formattedResponse = checkIn(response);
    dispatch(setcheckIn(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error during fetch data !",
      autoClose: 3000,
    });
  }
};
