import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addExpenseSchema } from "redux/validator/admin/management";
import { Form, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addExpense,
  fetchExpense,
  updateExpense
} from "redux/appThunk/Admin/management";
import { awsURL } from "utils/Constants";
import DatePicker from "component/common/DatePicker";
import PropTypes from "prop-types";
import ViewEditPopup from "component/common/ViewEditPopup";
import { mappedPermissionObj } from "hooks/usePermission";
import { typeObj } from "component/common/enum";
import { singleExpense } from "redux/actions/action";

export default function ExpenseDetails({
  setOpenPopUp,
  id,
  isEdit,
  setIsEdit,
  type,
  setIsDisabled,
  isDisabled,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  // this is useful in next
  const [fileName, setFileName] = useState("");
  const [submitOnce, setSubmitOnce] = useState(false);

  const { getSingleCompanyTax } = useSelector(
    (state) => state?.managementReducer
  );

  const dispatch = useDispatch();
  const initialvalues = {
    expenseFor: "",
    expenseBy: "",
    expenseDate: "",
    amount: "",
    paymentBy: "",
    paymentMethod: "",
    invoiceImage: "",
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchExpense(id));
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (getSingleCompanyTax && Object.keys(getSingleCompanyTax).length > 0) {
      formik.setValues((prev) => ({
        ...prev,
        ["expenseFor"]: getSingleCompanyTax.expenseFor,
        ["expenseBy"]: getSingleCompanyTax.expenseBy,
        ["expenseDate"]: getSingleCompanyTax.expenseDate,
        ["paymentBy"]: getSingleCompanyTax.paymentBy,
        ["paymentMethod"]: getSingleCompanyTax.paymentMethod,
        ["amount"]: getSingleCompanyTax.amount,
        ["invoiceImage"]: getSingleCompanyTax.invoiceImage,
      }));
    }
  }, [getSingleCompanyTax?.invoiceImage]);

  const formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addExpenseSchema,
    onSubmit: (values) => {
      setSubmitOnce(true);
      try {
        if (isEdit) {
          dispatch(updateExpense(values, id));
        } else {
          dispatch(addExpense(values));
        }
        navigate("/management", { state: { activeTab: 5 } });
        dispatch(singleExpense());
        setOpenPopUp(false);
      } catch (error) {
        setSubmitOnce(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  const buttonClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    //this is useful
    setFileName(file.name);
    setFieldValue("invoiceImage", file);
  };

  const handleClose = () => {
    setOpenPopUp(false);
    setIsEdit(false);
    setIsDisabled(false);
    dispatch(singleExpense());
    resetForm();
  };

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <ViewEditPopup
          popupBox={() => handleClose()}
          title={
            isEdit || type === typeObj.view
              ? t("Expense Details")
              : t("Add Expense")
          }
          handleSubmit={handleSubmit}
          submitBtnText={t("saveAndNext")}
          type={typeObj[type]}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          submitOnce={submitOnce}
          setIsDisabled={setIsDisabled}
          currentResource={mappedPermissionObj.Expense}
          isDisabled={isDisabled}
        >
          <FormikProvider value={values}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
            >
              <div className="relative flex flex-col">
                <label htmlFor="expenseFor">{t("expenseFor")}</label>
                <input
                  type="text"
                  name="expenseFor"
                  id="expenseFor"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.expenseFor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                />
                {errors.expenseFor && touched.expenseFor ? (
                  <p className="text-[red]">{errors.expenseFor}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="expenseBy">{t("expenseBy")}</label>
                <input
                  type="text"
                  name="expenseBy"
                  id="expenseBy"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.expenseBy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                />
                {errors.expenseBy && touched.expenseBy ? (
                  <p className="text-[red]">{errors.expenseBy}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <div data-testid="expenseDate" className="grid">
                  <label htmlFor="expenseDate" className="text-[16px]">
                    {t("expenseDate")}
                  </label>
                  <DatePicker
                    name={"expenseDate"}
                    value={values?.expenseDate}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    styles={"w-[100%] mt-[0.25rem"}
                    disabled={isDisabled}
                  />
                </div>
                {errors?.expenseDate && touched?.expenseDate && (
                  <p className="text-[1rem] text-red-500">
                    {errors.expenseDate}
                  </p>
                )}
              </div>

              <div className="relative flex flex-col">
                <label htmlFor="amount">{t("amount")}</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.amount && touched.amount ? (
                  <p className="text-[red]">{errors.amount}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="paymentBy">{t("paymentBy")}</label>
                <input
                  type="text"
                  name="paymentBy"
                  id="paymentBy"
                  disabled={isDisabled}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.paymentBy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.paymentBy && touched.paymentBy ? (
                  <p className="text-[red]">{errors.paymentBy}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="paymentMethod">{t("paymentMethod")}</label>
                <input
                  type="text"
                  name="paymentMethod"
                  id="paymentMethod"
                  disabled={isDisabled}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.paymentMethod}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.paymentMethod && touched.paymentMethod ? (
                  <p className="text-[red]">{errors.paymentMethod}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="invoiceImage">{t("invoiceImage")}</label>

                <div
                  className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center justify-between"
                  onClick={() => buttonClick()}
                >
                  <input
                    type="file"
                    name="invoiceImage"
                    id="invoiceImage"
                    ref={inputRef}
                    className="hidden"
                    onChange={handleFileChange}
                    onBlur={handleBlur}
                    disabled={isDisabled}
                    // value={values.invoiceImage}
                  />
                  {/* this is for using in next */}
                  <button type="button">{isEdit ? values.invoiceImage?.name || values.invoiceImage : fileName}</button>
                  <img
                    className="absolute top-[36px] right-[10px]"
                    src={`${awsURL}/images/cloudUpload.png`}
                    alt="cloud upload"
                    disabled={isDisabled}
                  />
                </div>
              </div>
              {errors.invoiceImage && touched.invoiceImage ? (
                <p style={{ color: "red" }}>{errors.invoiceImage}</p>
              ) : null}
            </Form>
          </FormikProvider>
        </ViewEditPopup>
      </div>
    </div>
  );
}

ExpenseDetails.propTypes = {
  setOpenPopUp: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.any,
  setIsEdit: PropTypes.any,
  type: PropTypes.any,
  setIsDisabled: PropTypes.any,
  isDisabled: PropTypes.any,
};
