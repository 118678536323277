import { SET_GET_FEEDBACK } from "redux/actions/types";

const initialstate = {
  jobOpenings: [],
  error: "",
  openingData: {},
  individualOpening: {},
  jobOpeningStatus: "",
  indiviualStatus: "",
  jobApplications: [],
  allJobApplications: [],
  Getfeeback: [],
  GetJobID: {},
  NewAllEvent: [],
  textReject: "",
};

export const jobOpeningReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "SET_JOB_OPENING_DATA":
    return { ...state, jobOpenings: payload };

  case "SET_JOB_APPLICATION_DATA":
    return { ...state, jobApplications: payload };

  case "SET_ALL_JOB_APPLICATION_DATA":
    return { ...state, allJobApplications: payload };

  case "DELETE_JOB_OPENING_DATA":
    return { ...state, payload };

  case "INDIVIDUAL_JOB_OEPNING":
    return { ...state, individualOpening: payload };

  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };
  case "SET_JOB_OPENING_STATUS":
    return {
      ...state,
      jobOpeningStatus: payload,
    };
  case "TEXT_REJECT":
    return {
      ...state,
      textReject: payload,
    };

  case "SET_ALL_EVENT":
    return {
      ...state,
      NewAllEvent: payload,
    };
  case "SET_INDIVIDUAL_STATUS":
    return {
      ...state,
      indiviualStatus: payload,
    };
  case SET_GET_FEEDBACK:
    return {
      ...state,
      Getfeeback: payload,
    };

  case "SET_JOBID":
    return {
      ...state,
      GetJobID: payload,
    };
  default:
    return state;
  }
};
