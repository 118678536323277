import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import {
  EmptyPermission,
  fetchALLPermissions,
  fetchRoleDataWithoutPage,
  fetchRolesPermId
} from 'redux/appThunk/Admin/permission';
import { MdArrowOutward } from 'react-icons/md';
import ShimmerName from 'component/common/ShimmerName';

const ListAddRoles = ({ perm, setPerm, checkedPerm, setCheckedPerm,
}) => {
  const [selectDrop, setSelectDrop] = useState(false);
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allRoles, permissionEmpty } = useSelector((state) => state.permissionReducer);
  const { AllRolesPermissionId } = useSelector((state) => state.permissionReducer);
  const permissionOfId = AllRolesPermissionId?.requiredResponse?.length > 0 ?
    AllRolesPermissionId?.requiredResponse
    :
    permissionEmpty?.requiredResponse;

  useEffect(() => {
    dispatch(fetchALLPermissions());
    dispatch(fetchRoleDataWithoutPage());
    dispatch(EmptyPermission());
  }, [dispatch]);

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleCheck = (e, item) => {
    const data = permissionOfId?.find(helooo => Object.keys(helooo?.actions)?.includes(item));
    let newData = false;
    if (!perm.includes(item)) {
      newData = Object.keys(data?.actions).every(items => [...perm, item].includes(items));
      setPerm([...perm, item]);
      if (newData) {
        setCheckedPerm([...checkedPerm, data?.resource]);
      }
    } else {
      setPerm(perm.filter((permItem) => permItem !== item));
      setCheckedPerm(checkedPerm.filter((checked) => checked !== data?.resource));
    }
  };

  const handleSingleCheck = (item) => {
    const data = permissionOfId?.filter(helooo => helooo?.resource?.includes(item));
    const newData = data?.map(item => Object.keys(item?.actions));
    const isParentChecked = newData[0].some((value) => !perm.includes(value));
    if (isParentChecked) {
      setCheckedPerm([...checkedPerm, item]);
      setPerm([...perm, ...newData[0]]);
    } else {
      setPerm(perm.filter((nedValue) => !newData[0].includes(nedValue)));
      setCheckedPerm(checkedPerm.filter((checked) => checked !== item));
    }
  };

  const handleSelectRole = () => {
    setSelectDrop(!selectDrop);
  };

  const handleClickRole = (item) => {
    setName(item);
    dispatch(fetchRolesPermId(item?.id));
    setSelectDrop(false);
  };

  useEffect(() => {
    if (permissionOfId) {
      const getPerm = permissionOfId.map((item) => Object.keys(item.actions).filter((c) => item.actions[c] === true));
      const flate = getPerm.flat();
      setPerm(flate);

      const getChecked = permissionOfId?.map((item) => {
        const arr = Object.keys(item?.actions)?.filter(c => item?.actions[c] === true);
        if (arr.length === Object.keys(item.actions).length) {
          return item.resource;
        } else {
          return null;
        }
      });

      const removeNull = getChecked?.filter((item) => item !== null);
      setCheckedPerm(removeNull);
    }
  }, [permissionOfId]);

  return (
    <div className='w-full h-auto mt-2'>
      <div>
        <p className='text-[#686868]'>{t('addRoleLine')}
          <span className='text-[red]'>*</span>
        </p>
      </div>
      <div className='overflow-x-auto custom_scroll h-[calc(90vh-200px)] max-h-[57vh] overflow-y-auto'>
        {
          permissionOfId?.length > 0 ? (
            <>
              <table className="w-full p-3 bg-white rounded-lg text-left ">
                <thead className="flex-0 text-left z-10 left-0 sticky top-0">
                  <tr
                    className="max-h-[3.125rem] p-2 text-[#686868]
                     text-sm  font-normal bg-[#F2F6FF] border-collapse left-0 z-30"
                  >
                    <th
                      className="lg:w-[18.224rem] min-w-[18.224rem]
                       max-h-[3.125rem] h-full px-2 py-4 bg-[#F2F6FF] border border-gray-300"
                    >
                      {t("permissions")}
                    </th>
                    <th
                      className="lg:w-[14rem] min-w-[14rem] max-h-[3.125rem] h-full p-2 border border-gray-300 bg-[#F2F6FF]"
                    >
                      <div className='relative'>
                        <div className="flex justify-between cursor-pointer items-center" onClick={handleSelectRole}>
                          <div>
                            <p>{t("select")}</p>
                            <p className='text-black capitalize text-[1rem]'>{name?.name}</p>
                          </div>
                          <span className='text-[1.5rem]'>
                            <MdArrowOutward />
                          </span>
                        </div>
                        {selectDrop && (
                          <div className='bg-white absolute shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] border border-[#DEE4EB]
                            w-full max-w-[14rem] rounded-md right-0 top-10 max-h-[20rem] custom_scroll overflow-y-auto'>
                            {allRoles?.map((item) => (
                              <div key={item?.name} className='h-[2.5rem] flex items-center pl-4 hover:bg-[#F2F6FF] capitalize cursor-pointer'
                                onClick={() => handleClickRole(item)}
                              >
                                {item?.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </th>
                    <th
                      className="lg:w-[14rem] min-w-[9rem] max-h-[3.125rem] h-full p-2 border border-gray-300 bg-[#F2F6FF] "
                    >
                      {t("customRole")}
                    </th>
                  </tr>
                </thead>
                <tbody className="p-2 text-sm text-left font-normal flex-0 ">
                  {permissionOfId?.map((data, index) => {
                    return (
                      <Fragment key={`permission-${index}`}>
                        <tr key={`resource-row-${index}`} className='h-[50px]'>
                          <td className='border border-gray-300 bg-[#F9FAFD] '>
                            <div className='flex items-center gap-5 ml-3'>
                              <span>
                                <FaAngleDown />
                              </span>
                              <span className='text-[#313135] text-[1.1rem] font-normal'>
                                {data?.resource}
                              </span>
                            </div>
                          </td>
                          <td className='border border-gray-300 bg-[#F9FAFD]'>
                          </td>
                          <td className='border border-gray-300 bg-[#F9FAFD]'>
                            <input
                              className="relative h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                              checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                              accent-[#031B59!important] checked:bg-[#031B59!important]
                              checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                              checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                              checked:after:border-l-0 checked:after:border-t-0
                              checked:after:rotate-[45deg] checked:after:border-[0.15rem]
                              checked:after:border-solid checked:after:border-white
                              checked:after:bg-transparent checked:after:content-[''] ml-4 cursor-pointer"
                              type="checkbox" checked={(checkedPerm?.includes(data?.resource))}
                              onChange={() => handleSingleCheck(data?.resource)} />
                          </td>
                        </tr>
                        {Object.keys(data?.actions)?.map((item, level) => {
                          return (
                            <tr key={`action-row-${index}-${level}`} className='h-[50px]'>
                              <td className='border border-gray-300 px-4 text-[1rem]'>{convertToTitleCase(item)}</td>
                              <td className='border border-gray-300'>
                                <input
                                  className="relative h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                                  accent-[#031B59!important] checked:bg-[#031B59!important]
                                  checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                                  checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                                  checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                                  checked:after:rotate-[45deg] checked:after:border-[0.15rem]
                                  checked:after:border-l-0 checked:after:border-t-0
                                  checked:after:border-solid checked:after:border-white
                                  checked:after:bg-transparent checked:after:content-[''] ml-4"
                                  type="checkbox"
                                  checked={data?.actions[item]}
                                  disabled={true}
                                />
                              </td>
                              <td className='border border-gray-300'>
                                <input
                                  className="relative h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                                  accent-[#031B59!important] checked:bg-[#031B59!important]
                                  checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                                  checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                                  checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                                  checked:after:rotate-[45deg] checked:after:border-[0.15rem]
                                  checked:after:border-l-0 checked:after:border-t-0
                                  checked:after:border-solid checked:after:border-white
                                  checked:after:bg-transparent checked:after:content-[''] ml-4 cursor-pointer"
                                  type="checkbox"
                                  onChange={(e) => handleCheck(e, item)}
                                  checked={perm?.includes(item)}
                                  required
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    );
                  }
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <ShimmerName
              height='55vh'
              width='90vw'
            />
          )
        }
      </div>
    </div>
  );
};

export default ListAddRoles;

ListAddRoles.propTypes = {
  perm: PropTypes.any,
  checkedPerm: PropTypes.any,
  setPerm: PropTypes.any,
  setCheckedPerm: PropTypes.any,
  check: PropTypes.any,
  newdData: PropTypes.any,
  setNewData: PropTypes.any,
  setCheck: PropTypes.any,
};

