/* eslint-disable no-unused-vars */
import React, { useState
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LoaderComp from "component/loader/LoaderComp";
import { FaArrowLeft } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";

export const LeaveDetails = ({ newId,setOpenPopup, isLoading}) => {
  const { t } = useTranslation();

  return (
    <div
      className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <form
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-fit flex justify-between">
          <div className="flex justify-between w-full items-center">
            <div className="flex justify-center items-center">
              <span
                data-testid="back-button"
                className="text-[#031B59] mr-4 cursor-pointer"
                onClick={() => setOpenPopup(false)}
              >
                <FaArrowLeft />
              </span>
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("leavedetails")}
              </h2>
            </div>
            <div className="p-3 border-2 cursor-pointer" onClick={() => setOpenPopup(false)}>
              <RxCross1 />
            </div>
          </div>
        </div>
        {!isLoading ? (
          <>
            <form
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
              autoComplete="off"
            >
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="employee_id" className="text-[#313135]">
                  {t("empId")}
                </label>
                <input
                  type="text"
                  name="id"
                  id="id"
                  disabled={true}
                  value={newId.id}
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                />
              </div>
              <div className="relative flex flex-col space-y-2">
                <label className="text-[#313135]">{t("employee_name")}</label>
                <input
                  type="text"
                  id="employeeName"
                  name="employeeName"
                  value={newId.fullName ? newId.fullName : "-"}
                  className="border text-sm rounded w-full p-2.5"
                  disabled={true}
                />
              </div>
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="fromDate" className="text-[#313135]">
                  {t("fromDate")}
                </label>
                <div className="flex border p-2 rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={
                      newId.fromDate
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="toDate" className="text-[#313135]">
                  {t("toDate")}
                </label>
                <div className="flex border p-2 rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={
                      newId.toDate
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="numOfDays" className="text-[#313135]">
                  {t("conLeave")}
                </label>
                <input
                  type="text"
                  name="numOfDays"
                  id="numOfDays"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                  disabled={true}
                  value={newId?.consumedLeave}
                />
              </div>
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="leaveType" className="text-[#313135] text-left">
                  {t("leaveType")}
                </label>
                <input
                  type="text"
                  name="leaveType"
                  id="leaveType"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-left text-[#191919]"
                  value={newId?.leaveType}
                />
              </div>

              <div className="relative flex flex-col space-y-2">
                <label htmlFor="reason" className="text-[#313135]">
                  {t("reason")}
                </label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                  value={newId?.reason}
                />
              </div>
              <div className="relative flex flex-col space-y-2">
                <label htmlFor="leaveStatus" className="text-[#313135]">
                  {t("Status")}
                </label>
                <input
                  type="text"
                  name="status"
                  id="status"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                  value={newId?.leaveStatus}
                />
              </div>
            </form>
          </>
        ) : (
          <LoaderComp />
        )}
      </form>
    </div>
  );
};

LeaveDetails.propTypes = {
  newId: PropTypes.array,
  setOpenPopup: PropTypes.array,
  isLoading: PropTypes.array,
};
