import React from "react";
import LoginForm from "component/login";

function Login() {
  return (
    <LoginForm />
  );
}

export default Login;
