import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { BsThreeDots } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import DashboardEventDetailPopup from "./DashboardEventDetailPopup";
import { useDispatch, useSelector } from "react-redux";
import { viewEvent } from "redux/appThunk/Admin/dashboard";

// eslint-disable-next-line react/prop-types
const DashboardViewEventPopup = ({ closeDrawer }) => {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const selector = useSelector((state) => state.dashboardReducers.viewevent);
  const formatTime = (timeStrings) => {
    const formattedTimes = timeStrings?.map((timeString) => {
      const time = new Date(timeString).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      return time.replace(/^0/, "");
    });

    return formattedTimes.join(" to ");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewEvent());
  }, []);
  const handleOpen = (eventId) => {
    setOpenDrawer(!openDrawer);
    setSelectedEventId(eventId);
  };

  const filterEvents = (events) => {
    const currentDate = new Date();

    return events.filter((event) => {
      const eventDate = new Date(event.date);

      return eventDate >= currentDate;
    });
  };

  const filteredEvents = filterEvents(selector);

  const formatDay = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    return day;
  };

  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleDateString("default", { month: "short" });

    return month;
  };
  const getFormattedDate = () => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    const days = [
      "Sunday", "Monday", "Tuesday", "Wednesday",
      "Thursday", "Friday", "Saturday"];

    const today = new Date();
    const dayOfWeek = days[today.getDay()];
    const day = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    return `${dayOfWeek}, ${day} ${month} ${year}`;
  };

  return (
    <div
      className="w-full h-full flex items-center justify-end fixed top-0
     left-0 z-20 bg-[rgba(3,27,89,.2)] text-[#191919]"
    >
      <div
        className="min-w-[25%] h-full p-5 bg-white flex-flex-col
      space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="flex justify-between px-[0.88rem] py-[0.62rem]  ">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">Events</p>
          </div>
          <div>
            <RxCross2
              onClick={closeDrawer}
              className="text-[#191919] text-xl"
            />
          </div>
        </div>
        <div></div>
        <div className="flex gap-2 items-center border-b-2 pb-4 border-b-[#F2F6FF]">
          <FaCalendarAlt />
          <p>{getFormattedDate()}</p>
        </div>
        <h3 className="font-medium">{t("invites")}</h3>
        <div className="space-y-5">
          {filteredEvents.map((item) => (
            <div key={new Date().getTime()} className="flex justify-between space-x-5">
              <div className="bg-[#F2F6FF] px-3 py-1">
                <h6 className="text-[#191919] font-medium text-base rounded-md">
                  {formatDay(item.date)}
                </h6>
                <span>{formatMonth(item.date)}</span>
              </div>
              <div className="flex flex-col gap-1 min-w-[130px] xl:min-w-[200px] 2xl:min-w-[500px]">
                <h4 className="text-base font-medium text-[#191919] capitalize">
                  {item.fullName ? item.fullName : "-" }
                </h4>
                <p className="text-[#A1A1A1] text-xs">
                  {formatTime([item.startTime, item.endTime])}
                </p>
              </div>
              <div>
                <button onClick={() => handleOpen(item.id)}>
                  <BsThreeDots className="text-[#191919]" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {openDrawer && (
        <DashboardEventDetailPopup
          setClose={handleOpen}
          title={"Event Details "}
          selectorData1={selector}
          eventId={selectedEventId}
        />
      )}
    </div>
  );
};

export default DashboardViewEventPopup;
