import React from "react";
import { RiMailCheckLine } from "react-icons/ri";
import { MdChevronLeft } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";
import { awsURL } from "utils/Constants";

export default function MailList() {
  return (
    <div
      className="w-full max-w-[24.813rem] h-[42.5rem] overflow-y-scroll flex flex-col gap-2 bg-white rounded-[20px]
     shadow-[0px_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      {/* Select and mark read row */}
      <div className="w-full p-5 flex justify-between">
        <div className="basis-1/2 w-full flex justify-start items-center gap-1">
          <div
            role="checkbox"
            className="h-[15px] w-[15px] rounded border border-[#A1A1A1]"
            aria-checked="true"
          ></div>
          <div>
            <MdChevronLeft className="-rotate-90 fill-[#A1A1A1] w-[18px] h-[18px]" />
          </div>
        </div>
        <div
          role="button"
          className="basis-1/2 w-full flex items-center text-[#7f7f7f]
         gap-2 justify-end"
        >
          <h6 className="text-xs">Mark all as read</h6>
          <RiMailCheckLine className="w-4 h-4 fill-[#A1A1A1]" />
        </div>
      </div>
      {/* mail row */}
      <div className="w-full flex flex-col items-center border-b-[1px] border-[#F2F6FF]">
        <div className="w-full h-10 p-5 flex items-center justify-start gap-2">
          <div
            role="checkbox"
            className="h-[15px] w-[15px] rounded border border-[#A1A1A1]"
            aria-checked="true"
          ></div>
          <div className="basis-4/6 w-full flex items-center hustify-between space-x-2">
            <p className="text-[#191919] text-sm font-semibold">Janie...</p>
            <div className="w-full flex judtify-start">
              <p className="text-sm text-[#333]">Email content here.....</p>
            </div>
          </div>
          <div className="basis-1/6 w-full flex items-center justify-beetween space-x-4 text-[#7f7f7f]">
            <p className="text-sm">03:30AM</p>
            <div className="w-fit p-0 flex justify-end items-center">
              <AiOutlineStar />
            </div>
          </div>
        </div>
        <div className="w-full pl-10 pr-5 pb-[10px] flex ">
          <div className="w-[8.75rem] h-[1.875rem] py-[5px] px-3 flex items-center justify-start border-[1px]
           border-[#E2E8F0]
           space-x-2
          rounded-full">
            <div className="w-[15px] h-[15px]">
              <img src={`${awsURL}/images/pdf_file.jpg`} alt="Pdf Image" />
            </div>
            <p className="text-xs">PdF Name</p>
          </div>
        </div>
      </div>
    </div>
  );
}
