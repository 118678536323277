import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addCompanySchema } from "redux/validator/admin/management";
import { Form, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Svg18 from "svgComponents/Svg18";
import {
  handleAddCompanyTax,
  handleCompanyTaxDetails,
  handleEditCompanyTax
} from "redux/appThunk/Admin/management";
import DatePicker from "component/common/DatePicker";
import PropTypes from "prop-types";
import ViewEditPopup from "component/common/ViewEditPopup";
import { mappedPermissionObj } from "hooks/usePermission";
import { typeObj } from "component/common/enum";
import { getCompanyData } from "redux/actions/action";

export default function CompanyTaxDetails({ setOpenPopUp, id, isEdit, type, setIsEdit ,setIsDisabled,isDisabled}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitOnce, setSubmitOnce] = useState(false);
  const getSingleCompanyTax = useSelector(
    (state) => state.managementReducer.getSingleCompanyTax
  );
  const initialvalues = {
    name: "",
    tax_type: "",
    amount: "",
    due_date: "",
    status: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addCompanySchema,
    onSubmit: async (values) => {
      try {
        setSubmitOnce(true);
        if (isEdit || type === typeObj.view) {
          dispatch(handleEditCompanyTax(values, id));
          navigate("/management", { state: { activeTab: 4 } });
        } else {
          dispatch(handleAddCompanyTax(values));
          navigate("/management", { state: { activeTab: 4 } });
        }
        dispatch(getCompanyData());

        setOpenPopUp(false);
      } catch (error) {
        setSubmitOnce(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = Formik;

  useEffect(() => {
    if (id) {
      dispatch(handleCompanyTaxDetails(id));
    }
  }, []);

  const handleClose = () => {
    setOpenPopUp(false);
    dispatch(getCompanyData());
    setIsEdit(false);
    setIsDisabled(false);
    resetForm();
  };

  useEffect(() => {
    if (getSingleCompanyTax) {
      setFieldValue("name", getSingleCompanyTax?.name);
      setFieldValue("tax_type", getSingleCompanyTax?.taxType);
      setFieldValue("amount", getSingleCompanyTax?.amount);
      setFieldValue("due_date", getSingleCompanyTax?.dueDate);
      setFieldValue("status", getSingleCompanyTax?.status);
    }
  }, [getSingleCompanyTax]);

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <ViewEditPopup
          popupBox={() => handleClose()}
          title={
            isEdit || type === typeObj.view
              ? t("Company Tax Details")
              : t("Add Tax")
          }
          handleSubmit={handleSubmit}
          submitBtnText={t("saveAndNext")}
          type={typeObj[type]}
          isEdit={isEdit}
          setIsDisabled={setIsDisabled}
          setIsEdit={setIsEdit}
          submitOnce={submitOnce}
          currentResource={mappedPermissionObj.Companytax}
        >
          <FormikProvider value={values}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
            >
              <div className="relative flex flex-col">
                <label htmlFor="name">{t("name")}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                />
                {errors.name && touched.name ? (
                  <p className="text-[red]">{errors.name}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="taxType">{t("taxType")}</label>
                <div className="relative">
                  <select
                    id="taxType"
                    name="tax_type"
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded appearance-none"
                    value={values.tax_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isDisabled}
                  >
                    <option value="">{t("select")}</option>
                    <option value="GST">{t("gst")}</option>
                    <option value="IncomeTax">{t("incomeTax")}</option>
                  </select>
                  <div className="absolute right-0 top-[14px]">
                    <Svg18 />
                  </div>
                  {errors.tax_type && touched.tax_type ? (
                    <p className="text-[red]">{errors.tax_type}</p>
                  ) : null}
                </div>
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="amount">{t("totalAmount")}</label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.amount && touched.amount ? (
                  <p className="text-[red]">{errors.amount}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <div data-testid="dueDate" className="grid">
                  <label htmlFor="dueDate" className="text-[16px]">
                    {t("dueDate")}
                  </label>
                  <DatePicker
                    name={"due_date"}
                    value={values?.due_date}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    styles={"w-[100%] mt-[0.25rem"}
                    disabled={isDisabled}
                  />
                </div>
                {errors?.due_date && touched?.due_date && (
                  <p className="text-[1rem] text-red-500">{errors.due_date}</p>
                )}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="status">{t("status")}</label>

                <div className="relative">
                  <select
                    name="status"
                    id="status"
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                    value={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isDisabled}
                  >
                    <option value="">{t("select")}</option>
                    <option value="Done">{t("done")}</option>
                  </select>
                  <div className="absolute right-0 top-[14px]">
                    <Svg18 />
                  </div>
                  {errors.status && touched.status ? (
                    <p className="text-[red]">{errors.status}</p>
                  ) : null}
                </div>
              </div>
            </Form>
          </FormikProvider>
        </ViewEditPopup>
      </div>
    </div>
  );
}
CompanyTaxDetails.propTypes = {
  setOpenPopUp: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.any,
  type: PropTypes.any,
  setIsEdit: PropTypes.any,
  setIsDisabled: PropTypes.any,
  isDisabled: PropTypes.any,
};
