import {
  PAGE_COUNT,
  SET_HIERARCHY_DATA,
  SET_MY_MEMBERS_DATA,
  SET_MY_ORGANIZATION_DATA,
  SET_MY_PROJECT_DATA,
  SET_MY_TEAMS_DATA,
  SET_MY_PROJECT_MEMBERS
} from "redux/actions/types";

const initialstate = {
  MyTeamInfo: [],
  pageCount: 0,
  isLoading: false,
  myTeamsData: [],
  myProjectData: [],
  myProjectMembersData: [],
  myMembersData: [],
  myOrganizationData: [],
  barCount: 0,
  hierarchyData: [],
};

export const MyTeamsReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "SET_MY_TEAMS":
    return {
      ...state,
      MyTeamInfo: payload,
    };
  case PAGE_COUNT:
    return { ...state, pageCount: payload };

  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };

  case SET_MY_TEAMS_DATA:
    return {
      ...state,
      myTeamsData: payload,
    };

  case SET_MY_PROJECT_DATA:
    return {
      ...state,
      myProjectData: payload,
    };

  case SET_MY_PROJECT_MEMBERS:
    return {
      ...state,
      myProjectMembersData: payload,
    };

  case SET_MY_MEMBERS_DATA:
    return {
      ...state,
      myMembersData: payload,
    };

  case SET_MY_ORGANIZATION_DATA:
    return {
      ...state,
      myOrganizationData: payload,
    };

  case "My_Team_Value":
    return {
      ...state,
      barCount: payload,
    };

  case SET_HIERARCHY_DATA:
    return {
      ...state,
      hierarchyData: payload,
    };

  default:
    return state;
  }
};
