import React, { useState } from "react";
import { IoClose, IoSendSharp } from "react-icons/io5";
import { AiOutlineStar } from "react-icons/ai";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { monthList } from "utils/Constants";
import { updateReviews } from "redux/appThunk/Admin/client";
import { useDispatch } from "react-redux";
import { addreviews } from "redux/validator/admin/client";
import Popup from "component/common/Popup";

function Editpopup({ userData, setHandlePopup }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rating, setRating] = useState({ punctuality: 0, productivity: 0, behaviour: 0 });
  const [hover, setHover] = useState({ punctuality: 0, productivity: 0, behaviour: 0 });
  const [showPopUp, setShowPopUp] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [newData, setNewData] = useState(null);

  const initialValues = {
    reviewInp: userData?.review?.review || '',
    month: userData?.review?.month || '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addreviews,
    onSubmit: async (values) => {
      setShowPopUp(true);
      const jsonObj = {
        review: {
          review: values.reviewInp,
          resource_id: userData?.review?.resourceId?.userId,
          project_id: userData?.review?.resourceId?.projectId,
          client_id: userData?.review?.clientId,
          punctuality_rating: rating?.punctuality,
          behaviour_rating: rating?.behaviour,
          productivity_rating: rating?.productivity,
          month: values?.month,
        },
      };
      setNewData(jsonObj);
    },
  });

  const submit = () => {
    if (!submitOnce) {
      setSubmitOnce(true);
      dispatch(updateReviews(newData, userData?.review?.id, setHandlePopup));
      setHandlePopup(false);
    }
  };

  const { values, handleChange, handleSubmit, handleBlur } = formik;

  return (
    <div
      className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)]"
    >
      <div
        className="min-w-[40%] h-full bg-white flex flex-col space-y-8
         shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between items-center p-4">
          <div className="font-bold text-[20px] text-[#031B59]">
            {t("edit")}
          </div>
          <div>
            <IoClose
              onClick={() => setHandlePopup(false)}
              className="ml-auto fill-[#686868] w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-col space-y-3 pl-4 pr-4 items-center">
          <div className="w-full flex flex-col space-y-2">
            <label htmlFor="month" className="text-[16px] font-medium text-[#313135]">
              {t("month")}
            </label>
            <select
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] bg-white rounded text-[#313135]"
              id="month"
              name="month"
              value={values?.month}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value={userData?.review?.month}>{userData?.review?.month}</option>
              {monthList.map((monthName, index) => (
                <option key={index} value={monthName.toLowerCase()}>
                  {monthName.toLowerCase()}
                </option>
              ))}
            </select>
            {formik.touched.month && formik.errors.month && (
              <p className="text-red-500">{formik.errors.month}</p>
            )}
          </div>
          <form
            className="w-full flex flex-col"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="w-full flex flex-col space-y-3">
              <label htmlFor="review" className="text-[16px] font-medium text-[#313135]">
                {t("write_review_here")}
              </label>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Punctuality</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${
                          index <= (hover?.punctuality || rating?.punctuality) ? "fill-[#FFC700]" : "fill-[#A1A1A1]"
                        } w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, punctuality: index })}
                        onMouseEnter={() => setHover({ ...hover, punctuality: index })}
                        onMouseLeave={() => setHover(rating?.punctuality)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Behaviour</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${
                          index <= (hover?.behaviour || rating?.behaviour) ? "fill-[#FFC700]" : "fill-[#A1A1A1]"
                        } w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, behaviour: index })}
                        onMouseEnter={() => setHover({ ...hover, behaviour: index })}
                        onMouseLeave={() => setHover(rating?.behaviour)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Productivity</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${
                          index <= (hover?.productivity || rating?.productivity) ? "fill-[#FFC700]" : "fill-[#A1A1A1]"
                        } w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, productivity: index })}
                        onMouseEnter={() => setHover({ ...hover, productivity: index })}
                        onMouseLeave={() => setHover(rating?.productivity)}
                      />
                    );
                  })}
                </div>
              </div>
              <textarea
                className="h-[6rem] border border-[#F2F6FF] rounded-[5px] p-2 resize-none"
                name="reviewInp"
                value={values?.reviewInp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formik.touched.reviewInp && formik.errors.reviewInp && (
                <p className="text-red-500">{formik.errors.reviewInp}</p>
              )}
            </div>
            <div className="flex w-full items-center justify-end gap-2">
              <button
                className="bg-[#031B59] w-[42px] h-8 rounded-md flex items-center justify-center"
                type="submit"
              >
                <IoSendSharp className="w-5 h-5 fill-[#fff]" />
                {showPopUp && (
                  <Popup
                    popupBox={() => setShowPopUp(false)}
                    title={`You have unsaved changes. Are you sure you want to discard the changes?`}
                    handleSubmit={submit}
                    submitOnce={submitOnce}
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Editpopup;

Editpopup.propTypes = {
  userData: PropTypes.shape({
    review: PropTypes.shape({
      review: PropTypes.string,
      month: PropTypes.string,
      resourceId: PropTypes.shape({
        userId: PropTypes.number,
        projectId: PropTypes.number,
      }),
      clientId: PropTypes.number,
      id: PropTypes.number,
      punctuality: PropTypes.number,
      behaviour: PropTypes.number,
      productivity: PropTypes.number,
    }),
  }).isRequired,
  setHandlePopup: PropTypes.func.isRequired,
};

