import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoaderComp from "component/loader/LoaderComp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LiaToggleOnSolid } from "react-icons/lia";
import { convertDateFormat } from "utils/date";
import { useNavigate } from "react-router-dom";
import Employeebrief from "component/admin/Employee/Employeebrief";
// import { setClientname } from "redux/actions/action";

const ClientTable = ({
  setEnable,
  isShowDialogBoxChange,
  setId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [finalFilteredValue] = useState([]);
  const { clientsData ,isLoading} = useSelector(
    (state) => state.ClientsReducer
  );

  const renderRow = (data, index) => {

    const handleOpenDialogBox = (data) => {
      dispatch(isShowDialogBoxChange(true));
      setId(data.id);
    };
    const EditEye = (data) => {
      navigate("/clients/Name/" + data?.user_id);
      // dispatch(setClientname(data?.name));
    };

    return (
      <tr
        className="max-h-[3.125rem] even:bg-[#F8FAFC] text-[#031B59] border border-[#E2E8F0]"
        key={index}
      >
        <td className="min-w-[5.5rem] p-2">
          <div className="flex items-center">
            <Employeebrief
              userName={data?.name}
              names={data?.name}
              userEmail={data?.email}
              email={data?.email}
              designation={data?.designation?.designation}
              data={data}
            />
          </div>
        </td>
        <td className="min-w-[5.5rem] p-2">
          {(data?.onboard_date && convertDateFormat(data?.onboard_date)) ||
            "---"}
        </td>
        {finalFilteredValue.map(
          (item, idx) =>
            item.isChecked && (
              <td key={idx} className="lg:w-[14rem] md:w-[6rem] p-2">
                {data[item.key] || "---"}
              </td>
            )
        )}
        <td
          className={`lg:w-[7.5rem] md:w-[4rem] p-2 py-4 sticky right-0 flex justify-start 
            ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
        >
          {data?.is_active ? (
            <>
              <button
                onClick={() => EditEye(data)}
                className="basis-1/3 w-full h-full flex items-center justify-start edit-btn relative group"
              >
                <FaEye fontSize="20px" className="mr-[6px] sticky ml-1.5" />
                <span
                  className="opacity-0 group-hover:opacity-100
                transition duration-300 absolute inset-0 flex
                items-center justify-center bg-white text-[#031B59] top-7"
                >
                  {t("edit")}
                </span>
              </button>
            </>
          ) : (
            <button className="basis-1/3 w-full h-full flex items-center justify-start edit-btn relative group">
              <FaEyeSlash fontSize="20px" className="mr-[6px] sticky ml-1.5" />
            </button>
          )}
        </td>
        <td
          className={`text-center min-w-[4rem] lg-min-w-[4rem] sticky right-0 top-0 p-2 py-5 
         ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"} `}
        >
          {data.is_active ? (
            <>
              <button
                onClick={() => handleOpenDialogBox(data)}
                className="w-full text-center h-[2rem] flex items-center justify-center disable-btn relative group"
              >
                <LiaToggleOnSolid fontSize="40px" className="mr-[6px]" />
                <span
                  className="opacity-0 group-hover:opacity-100
                transition absolute
                flex items-center justify-center bg-white text-[#031B59] top-8 "
                >
                  {t("disableEnable")}
                </span>
              </button>
            </>
          ) : (
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={false}
                onClick={() => {
                  handleOpenDialogBox(data);
                  setEnable(true);
                }}
              />
              <div
                className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                  dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full
                  rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border
                  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                  peer-checked:bg-blue-600"
              ></div>
            </label>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div
      className={`overflow-x-scroll ${
        isLoading ? "custom_scroller" : "custom_scroll"
      }`}
    >
      <table className=" w-full p-5 bg-white rounded-lg overflow-scroll text-left">
        <thead className="border flex-0 text-left p-2">
          <tr
            className="max-h-[3.125rem] h-[3.125rem] p-2 text-[#686868]
         text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0] "
          >
            <th className="lg:w-[15rem] p-2  max-h-[3.125rem] h-full sticky left-0 bg-[#F2F6FF] top-0 z-10">
              {t("client_name")}
            </th>
            <th className="lg:w-[14rem]  max-h-[3.125rem] h-full md:w-[8rem] p-2 sticky top-0 bg-[#F2F6FF]">
              {t("onBoardDate")}
            </th>
            {finalFilteredValue.map(
              (value, index) =>
                value.isChecked && (
                  <th
                    key={index}
                    className="lg:min-w-[14rem] md:w-[8rem] md:p-[1.5rem] p-2  max-h-[3.125rem] h-full"
                  >
                    {value.header}
                  </th>
                )
            )}
            <th
              className="lg:w-[5.5rem] md:w-[4rem] p-2  max-h-[3.125rem]
           h-full sticky right-0 bg-[#F2F6FF] top-0 z-10"
            >
              {t("action")}
            </th>
            <th
              className="lg:w-[6rem] min-w-[6rem] max-h-[3.125rem] h-full p-2
          text-center top-0 z-10 right-0 sticky  bg-[#F2F6FF]"
            >
              {t("D&A")}
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody className="p-2 text-sm text-left font-normal flex-0">
            {clientsData?.map((data, index) => renderRow(data, index))}
          </tbody>
        )}
      </table>
      {isLoading && (
        <div className="items-center mt-[20rem]">
          <LoaderComp />
        </div>
      )}
    </div>
  );
};

export default ClientTable;

ClientTable.propTypes = {
  setId: PropTypes.any,
  setEnable: PropTypes.func.isRequired,
  isShowDialogBoxChange: PropTypes.func.isRequired,
};
