import React, { useEffect, useState } from "react";
import DepartmentDropdown from "./DepartmentDropdown";
import LocationDropdown from "./LocationDropdown";
import Search from "svgComponents/myTeam/Search";
import { useDispatch } from "react-redux";
import { fetchEmployee, fetchMyTeams } from "redux/appThunk/Admin/myTeams";
import useDebounce from "hooks/useDebounce";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Block = ({ apiType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [records, setRecords] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [clearFilterState, setClearFilterState] = useState(false);
  const searchQuery = useDebounce(records, 500);

  useEffect(() => {
    if (clearFilterState) {
      setClearFilterState(false);

      return;
    }

    if (apiType === "myTeams") {
      dispatch(fetchMyTeams(1, 21, searchQuery, selectedLocations.join(','), selectedDepartments.join(',')));
    } else if (apiType === "organization") {
      dispatch(fetchEmployee(1, 21, searchQuery, selectedLocations.join(','), selectedDepartments.join(',')));
    }
  }, [searchQuery, selectedLocations, selectedDepartments, apiType, dispatch, clearFilterState]);

  const Filter = (event) => setRecords(event.target.value);

  const clearFilter = () => {
    setRecords("");
    setSelectedLocations([]);
    setSelectedDepartments([]);
    setClearFilterState(true);
  };

  const handleLocationChange = (locations) => {
    setSelectedLocations(locations);
    setClearFilterState(false);
  };

  const handleDepartmentChange = (departments) => {
    setSelectedDepartments(departments);
    setClearFilterState(false);
  };

  return (
    <div
      className="w-full h-[53px] px-3.5 py-2.5 bg-white
    rounded-xl border border-slate-200 flex items-center gap-3"
    >
      <div className="flex items-center gap-2">
        <span className="text-blue-950 text-base leading-normal">
          <DepartmentDropdown
            selectedDepartments={selectedDepartments}
            onDepartmentsChange={handleDepartmentChange}
            clearFilter={clearFilterState}
          />
        </span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-blue-950 text-base leading-normal">
          <LocationDropdown
            selectedLocations={selectedLocations}
            onLocationChange={handleLocationChange}
            clearFilter={clearFilterState}
          />
        </span>
      </div>
      <div
        className="flex w-[29.5625rem] px-[0.875rem] py-[0.625rem]
      pl-[0.625rem] items-center gap-[0.5rem] self-stretch
      rounded-[1rem] border border-[#E2E8F0]"
      >
        <input
          type="text"
          placeholder="Search"
          className="flex-grow text-zinc-900 placeholder:text-neutral-400
          text-base  leading-normal outline-none"
          onChange={Filter}
          value={records}
        />
        <div>
          <Search />
        </div>
      </div>
      <div className="flex-grow flex justify-end items-center">
        <button
          onClick={clearFilter}
          className="text-blue-950 text-base leading-normal"
        >
          {t("clearFilter")}
        </button>
      </div>
    </div>
  );
};

Block.propTypes = {
  apiType: PropTypes.oneOf(["myTeams", "organization"]).isRequired,
};

export default Block;
