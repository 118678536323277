/* eslint-disable max-len */
import React from "react";

const Rotate = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.6415 6.35292C16.0115 4.72292 13.7015 3.78292 11.1615 4.04292C7.49148 4.41292 4.47148 7.39292 4.06148 11.0629C3.51148 15.9129 7.26148 20.0029 11.9915 20.0029C15.1815 20.0029 17.9215 18.1329 19.2015 15.4429C19.5215 14.7729 19.0415 14.0029 18.3015 14.0029C17.9315 14.0029 17.5815 14.2029 17.4215 14.5329C16.2915 16.9629 13.5815 18.5029 10.6215 17.8429C8.40148 17.3529 6.61148 15.5429 6.14148 13.3229C5.30148 9.44292 8.25148 6.00292 11.9915 6.00292C13.6515 6.00292 15.1315 6.69292 16.2115 7.78292L14.7015 9.29292C14.0715 9.92292 14.5115 11.0029 15.4015 11.0029H18.9915C19.5415 11.0029 19.9915 10.5529 19.9915 10.0029V6.41292C19.9915 5.52292 18.9115 5.07292 18.2815 5.70292L17.6415 6.35292Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};

export default Rotate;
