import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRoleDataa,
  roleDelete
} from "redux/appThunk/Admin/permission";
import Paginate from "../Employee/Paginate";
import Search from "../search_comp/Search";
import CustomTable from "component/common/table/CustomTable";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import Popup from "component/common/Popup";
import useDebounce from "hooks/useDebounce";
import { viewRoleData } from "redux/actions/action";
import { GoPlusCircle } from "react-icons/go";
import AddAccessMembers from "./AddAccessMembers";

const AccessControl = () => {
  const [roleDeletePop, setRoleDeletePop] = useState(false);
  const [roleDeleteId, setRoleDeleteId] = useState(null);
  const [submitOn, setSubmitOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [againData, setAgainData] = useState(false);
  const [userAdd, setUserAdd] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const searchNewItem = useDebounce(searchQuery, 500);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { allRoles, pageCount } = useSelector(
    (state) => state.permissionReducer
  );

  let alignValues = {
    left: "left",
    right: "right",
    center: "center",
  };

  const ColumnAccess = [
    {
      title: "Role",
      field: "name",
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
      sorting: true,
    },
    {
      title: "Description",
      field: "description",
      align: alignValues.left,
      className: "min-w-[10rem] max-w-[11rem]  w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "User Count",
      field: "userCount",
      align: alignValues.left,
      className: "min-w-[5rem] max-w-[8rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Add Members",
      field: "userCount",
      align: alignValues.left,
      render: (value, roleInfo) => {
        return (
          <div className='w-full text-[#031b59] flex gap-2 items-center cursor-pointer'
            onClick={() => handleUserPop(value, roleInfo)}
          >
            <GoPlusCircle />
            <p className='underline'>{t('addMember')}</p>
          </div>
        );
      },
      className: "min-w-[5rem] max-w-[8rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
  ];

  useEffect(() => {
    dispatch(fetchRoleDataa(itemsPerPage, currentPage, searchQuery,setLoading));
  }, [itemsPerPage, currentPage, dispatch, searchNewItem, againData]);

  const handleAddRole = () => {
    navigate('/access/addRole');
  };
  const handleDeleteRole = (roleData) => {
    setRoleDeleteId(roleData);
    setRoleDeletePop(true);
  };
  const handleCloseDeletePop = () => {
    setRoleDeletePop(false);
  };
  const handleRemoveRole = () => {
    dispatch(roleDelete(roleDeleteId?.id, setSubmitOn, setRoleDeletePop,setAgainData));
  };

  const handleViewRole = (roleViewData) => {
    dispatch(viewRoleData(roleViewData));
    navigate('/access/viewRole/' + roleViewData?.id);
  };

  function handleUserPop(value, roleInfo) {
    setRoleName(roleInfo);
    setUserAdd(true);
  }

  return (
    <>
      {userAdd && (<AddAccessMembers
        userDrop={userAdd}
        setUserDrop={setUserAdd}
        roleName={roleName?.name}
        setAgainData={setAgainData}
      />)}
      {roleDeletePop && (
        <Popup
          popupBox={handleCloseDeletePop}
          title={t("remvRole")}
          submitBtnText="delete"
          handleSubmit={handleRemoveRole}
          submitOnce={submitOn}
        >
          <div>
            <h1 className="text-[1.6rem] font-bold w-full max-w-[30rem]">{t('deleteRoleMsg')}</h1>
          </div>
        </Popup>
      )}
      <div
        className="w-[96%] h-[calc(100vh-6.1rem)] bg-white flex flex-col p-5
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mt-2 relative justify-between gap-4"
      >
        <div className="w-full bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#002169]">
              {t("details")}
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            <Search searchItem={searchQuery} setSearchItem={setSearchQuery} className="p-6" />

            <button
              onClick={handleAddRole}
              className="h-[3.063rem] w-[7.625rem] p-2 border-[1.5px] border-[#E2E8F0] text-[#031B59] rounded-full"
            >
              {t("addRole")}
            </button>
          </div>
        </div>
        <div
          className='h-[90vh] overflow-y-auto custom_scroll'
        >
          <CustomTable
            columns={ColumnAccess}
            data={allRoles}
            isLoading={loading}
            renderAction={(
              job
            ) => {
              return (
                <div className="flex gap-5">
                  <div className="text-[1.39rem]" onClick={() => handleViewRole(job)}>
                    <AiOutlineEye />
                  </div>
                  <div className="text-[#FF0000] text-[1.39rem]" onClick={() => handleDeleteRole(job)}>
                    <MdDeleteOutline />
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div className='w-full h-fit flex items-end'>
          <div className="w-full h-fit py-2 bg-white flex justify-between items-center">
            <div className="text-[#031B59] font-medium">
              {t("showing")} {currentPage} {t("of")} {pageCount}
            </div>
            <Paginate
              currentPage={+currentPage}
              initialPageCount={+pageCount}
              pageRangeDisplayed={5}
              setCurrentPage={setCurrentPage}
              previous='<'
              next='>'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessControl;
