import React, { useEffect, useRef, useState } from "react";
import { content } from "../../utils/constant/Content";
import { documentContent } from "../../utils/constant/DocumentContent";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swalService from "../../utils/SwalServices";
import LoaderComp from "component/loader/LoaderComp";
import { profileData, updateUserDocuments } from "redux/appThunk/Employee/profile";
import { awsURL } from "utils/Constants";
import PropTypes from "prop-types";
import { RxCross2 } from "react-icons/rx";

export default function Documents({setAddDocumentsPop , id}) {
  const resumeInputRef = useRef(null);
  const aadharInputRef = useRef(null);
  const panInputRef = useRef(null);
  const passportInputRef = useRef(null);
  const expInputRef = useRef(null);
  const bankInputRef = useRef(null);
  const relInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.profileData);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [document, setDocument] = useState([
    {
      id: 0,
      name: "resume",
      header: documentContent.resume,
      ref: resumeInputRef,
      url: "",
      file: {},
    },
    {
      id: 1,
      name: "aadhar",
      header: documentContent.aadhar,
      ref: aadharInputRef,
      url: "",
      file: {},
    },
    {
      id: 2,
      name: "pan",
      header: documentContent.pAN,
      ref: panInputRef,
      url: "",
      file: {},
    },
    {
      id: 3,
      name: "passport",
      header: documentContent.passport,
      ref: passportInputRef,
      url: "",
      file: {},
    },
    {
      id: 4,
      name: "relieving_letter",
      header: documentContent.releivingLetter,
      ref: relInputRef,
      url: "",
      file: {},
    },
    {
      id: 5,
      name: "experience_letter",
      header: documentContent.expLetter,
      ref: expInputRef,
      url: "",
      file: {},
    },
    {
      id: 6,
      name: "bank_statement_3_mon",
      header: documentContent.bankStatement,
      ref: bankInputRef,
      url: "",
      file: {},
    },
  ]);

  const [defaultValue, setDefaultVal] = useState({});

  const handleURLChange = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl((prevState) => {
        return {
          ...prevState,
          [name]: fileURL,
        };
      });
    }
  };

  const handleDocumentSubmit = () => {
    let formData = new FormData();
    for (var i = 0; i < document.length; i++) {
      if (document[i].file?.name) {
        formData.append(`document[${document[i].name}]`, document[i].file);
      }
    }
    let isChange = false;
    document.map((obj) => {
      if (obj.file?.name) {
        isChange = true;
      }
    });
    if (!isChange) {
      swalService.showWarning({
        icon: "error",
        title: "Alert!",
        text: "You haven't made any changes. Please Update first!",
        confirmButtonText: "Ok",
      });
    } else {
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            if (userData?.role === "admin") {
              var id = user?.id;
            }
            dispatch(updateUserDocuments(id, formData));
            setTimeout(() => {
              setAddDocumentsPop(false);
            },2000);
          }
        });
    }
  };
  const buttonClick = (inputRef) => {
    inputRef.current.click();
  };
  const handleFileChange = (e, fileId) => {
    const { name, files } = e.target;
    const index = document.findIndex((x) => x.id === fileId);
    const updatedObj = { ...document[index], file: files[0] };
    const updatedDocument = [...document];
    updatedDocument[index] = updatedObj;
    setDocument(updatedDocument);
    setDefaultVal((prevState) => {
      return {
        ...prevState,
        [name]: files[0]?.name,
      };
    });
  };

  const handleChequeInput = (e, name) => {
    e.preventDefault();

    const documentUrls = {
      resume: user.document?.resume_url,
      pan: user.document?.pan_url,
      aadhar: user.document?.aadhar_url,
      passport: user.document?.passport_url,
      bank_statement_3_mon: user.document?.bank_statement_3_mon_url,
      relieving_letter: user.document?.relieving_letter_url,
      experience_letter: user.document?.experience_letter_url,
    };
    const documentUrl = documentUrls[name];
    if (fileUrl[name]) {
      window.open(fileUrl[name], "_blank");
    }
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(profileData(id, setLoading));
    } else {
      dispatch(profileData(userData?.id, setLoading));
    }
    if (id === undefined) {
      setUpdateDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (user?.document) {
      setDefaultVal({
        resume: user.document?.resume_name,
        pan: user.document?.pan_name,
        aadhar: user.document?.aadhar_name,
        passport: user.document?.passport_name,
        bank_statement_3_mon: user.document?.bank_statement_name,
        relieving_letter: user.document?.relieving_letter_name,
        experience_letter: user.document?.experience_letter_name,
      });
    }
  }, [user]);

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <Link onClick={() => setAddDocumentsPop(false)}>
              <h3 className="font-normal text-xl text-[#191919] flex items-center">
                {content.baseDetails}
                <span className="text-[#A1A1A1] mx-2">
                  <RiArrowRightDoubleLine />
                </span>
              </h3>
            </Link>
            <h2 className="font-extrabold text-xl text-[#031B59]">
              {content.documents}
            </h2>
          </div>
          <div className='border-2 border-[#031b59] p-1 text-[1.5rem] cursor-pointer'
            onClick={() => setAddDocumentsPop(false)}
          >
            <RxCross2 />
          </div>
        </div>
        <div className="flex flex-col gap-[26rem]">
          <div>

            {loading ? (
              <LoaderComp />
            ) : (
              <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
                {document?.map((obj) => (
                  <div className="relative flex flex-col space-y-2" key={obj.id}>
                    <label htmlFor={obj.name} className="text-[#313135]">
                      {obj.header}
                    </label>
                    <div
                      className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center justify-between"
                      onClick={() => buttonClick(obj.ref)}
                    >
                      <input
                        type="file"
                        name={obj.name}
                        ref={obj.ref}
                        id={obj.name}
                        accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                        className="hidden"
                        disabled={updateDisabled}
                        onChange={(e) => {
                          handleFileChange(e, obj.id);
                          handleURLChange(e);
                        }}
                      />
                      <button onClick={(e) => handleChequeInput(e, obj.name)}>
                        {defaultValue[obj.name]}
                      </button>
                      <img className="pr-3 cursor-pointer" src={`${awsURL}/images/cloudUpload.png`}
                        alt="cloud upload" />
                    </div>
                  </div>
                ))}
              </form>
            )}
          </div>
          <div>

            {userData?.role === "admin" && (
              <div className="h-[8rem] flex justify-end items-end">
                <button
                  className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full"
                  onClick={() => setAddDocumentsPop(false)}
                >
                  {content.cancel}
                </button>
                <button
                  className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                  type="submit"
                  onClick={handleDocumentSubmit}
                >
                  {content.save}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Documents.propTypes = {
  id: PropTypes.any,
  setAddDocumentsPop: PropTypes.any,
};
