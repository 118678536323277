import React, { useEffect, useState } from "react";
import { myTeamsTabLinks } from "utils/Constants";
import { useTranslation } from "react-i18next";
import MyTeams from "./MyTeams";
import Organization from "./Organization";
import Hierarchy from "./Hierarchy";
import MyProject from "./MyProject";
import { useDispatch } from "react-redux";
import { setMyTeamsCount } from "redux/actions/action";

const MyTeamsPage = () => {
  const { t } = useTranslation();
  const [myTeamsTabValue, setMyTeamsTabValue] = useState(1);
  const dispatch = useDispatch();

  const renderTabContent = () => {
    switch (myTeamsTabValue) {
    case 1 :
      return <MyTeams/>;
    case 2 :
      return <MyProject/>;
    case 3 :
      return <Organization/>;
    case 4 :
      return <Hierarchy/>;
    default:
      return null;
    }
  };

  useEffect(() => {
    dispatch(setMyTeamsCount(myTeamsTabValue));
  },[myTeamsTabValue]);

  return (
    <>
      <div className="w-[97%] flex space-x-12  p-5 ml-4">
        {myTeamsTabLinks?.map((obj) => (
          <button key={obj.id} onClick={() => setMyTeamsTabValue(obj.id)}>
            <div
              className={`text-xl ${
                myTeamsTabValue === obj.id
                  ? "text-[#002169] font-bold underline-small"
                  : "text-[#686868]"
              }`}
            >
              {t(obj.value)}
            </div>
          </button>
        ))}
      </div>
      <div>
        {renderTabContent()}
      </div>
    </>
  );
};

export default MyTeamsPage;
