import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addInvoiceSchema } from "redux/validator/admin/client";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa6";
import { AiFillFileImage } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { getDateDashFormat } from "utils/date";
import Svg18 from "svgComponents/Svg18";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isAdditionDialogBoxToggle, isShowDialogBoxChange} from "redux/actions/action";
import InvoicePopUp from "../admin/invoice/InvoicePopUp";
import DatePicker from "component/common/DatePicker";
import Helper from "api/Helper";
import ToastServices from "ToastServices";

export default function AddInvoiceDetails({index, setAddNewInvoice, setShowInvoice}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [userName] = useState("");
  const [setSearchRslt] = useState([]);
  const [selectedFilter] = useState();

  const [submitOnce, setSubmitOnce] = useState(false);
  const { clientInformation } = useSelector((state) => state.ClientsReducer);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();

  const initialvalues = {
    client_name: "",
    invoice_number: "",
    currency: "",
    amount: "",
    invoice_date: getDateDashFormat(new Date()),
    due_date: getDateDashFormat(new Date()),
    status: "",
    Upload_Image: [],
    user_id: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addInvoiceSchema,
    onSubmit: async (values) => {
      const submitData = new FormData();
      setSubmitOnce(false);
      submitData.append("invoice[client_name]", values.client_name);
      submitData.append("invoice[invoice_number]", values.invoice_number);
      submitData.append("invoice[currency]", values.currency);
      submitData.append("invoice[amount]", values.amount);
      submitData.append("invoice[invoice_date]", values.invoice_date);
      submitData.append("invoice[due_date]", values.due_date);
      submitData.append("invoice[status]", values.status);
      submitData.append("id", values.user_id);
      values.Upload_Image.forEach((img) =>
        submitData.append(`invoice[gst_files][]`, img)
      );
      AddStatusDialoBox();
      setFormData(submitData);

    },
  });

  const { handleSubmit, values, setFieldValue ,handleChange,handleBlur} = Formik;

  const jsonObj = {
    "Client Name": values.client_name,
    "Invoice  Number": values.invoice_number,
    "Currency": values.currency,
    "Amount": values.amount,
    "Invoice Date": values.invoice_date,
    "Due Date": values.due_date,
    "Status": values.status,
    "Client Id": values.user_id,
  };

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  useEffect(() => {
    Formik.setFieldValue("client_name", clientInformation?.name);
    Formik.setFieldValue("user_id", clientInformation?.user_id);
  }, [clientInformation]);

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  const handleRemoveImage = (imageIndex) => {
    setFieldValue(
      `Upload_Image`,
      values.Upload_Image.filter((_, i) => i !== imageIndex)
    );
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFieldValue(`Upload_Image`, [...values.Upload_Image, ...files]);
  };

  const handleSearchUser = async () => {
    const path = `clients?client_type=${
      selectedFilter.key
    }&page=${""}&per_page=${""}
    &query=${userName}`;
    try {
      const { response } = await Helper.get(path);
      if (response && response.clients && response.clients.length > 0) {
        setSearchRslt(response.clients);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "User Not Found",
      });
    }
  };

  useEffect(() => {
    let debounceTimer;
    if (userName) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        handleSearchUser();
      }, 500);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [userName]);

  const isAdditionDialogBox = useSelector(
    (state) => state.popUpDialogBox.isShowAdditionDialogBox
  );
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const AddStatusDialoBox = () => {
    dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    dispatch(isAdditionDialogBoxToggle(!isAdditionDialogBox));
  };

  function validateKeyDown(event) {
    var allowedKeys = /[a-zA-Z\b\s]/;

    if (!allowedKeys.test(event.key)) {
      event.preventDefault();

      return false;
    }
  }

  return (
    <div
      className="w-[96%] h-fit p-5 bg-white flex-flex-col space-y-8
      rounded-[20px]"
    >
      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <span
            className="text-[#031B59] mr-4 cursor-pointer"
            onClick={() => { setAddNewInvoice(false);
              setShowInvoice(true); }}
          >
            <FaArrowLeft />
          </span>
          <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
            {t("addNewInvoice")}
          </h3>
        </div>

        {isOpenDialogBoxToggle && isAdditionDialogBox && (
          <InvoicePopUp
            AddStatusDialoBox={AddStatusDialoBox}
            checkData={jsonObj}
            submitData={formData}
            submitOnce={submitOnce}
            setSubmitOnce={setSubmitOnce}
          />
        )}
        <div className="flex items-center justify-center space-x-4 ">
          <button
            className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
            onClick={() => { setAddNewInvoice(false);
              setShowInvoice(true); }
            }
          >
            {t("cancel")}
          </button>
          <button
            className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
            type="submit"
            disabled={submitOnce}
            onClick={!submitOnce && handleSubmit}
          >
            {t("add")}
          </button>
        </div>
      </div>
      <form>
        <div className="relative w-full p-3">
          <div className="relative flex flex-col w-[49.5%] text-black">
            <label htmlFor="client_name">{t("clientName")}</label>
            <input
              type="text"
              id="client_name"
              name="client_name"
              value={Formik.values.client_name}
              disabled
              className="border text-sm rounded w-full p-2.5 text-black"
              onBlur={Formik.handleBlur}
              onKeyDown={(e) => enterClick(e)}
            />
          </div>
          {Formik.errors.client_name && Formik.touched.client_name ? (
            <p className="text-[red]"> {Formik.errors.client_name}</p>
          ) : null}
        </div>
        <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
          <div className="relative flex flex-col">
            <label htmlFor="invoiceNumber">{t("invoiceNumber")}</label>
            <input
              type="text"
              name="invoice_number"
              id="invoiceNumber"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
              value={Formik.values.invoice_number}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            {Formik.errors.invoice_number && Formik.touched.invoice_number ? (
              <p className="text-[red]">{Formik.errors.invoice_number}</p>
            ) : null}
          </div>
          <div className="relative flex flex-col">
            <label htmlFor="currency">{t("currency")}</label>
            <div className="relative flex items-center">
              <select
                type="text"
                name="currency"
                id="currency"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                value={Formik.values.currency}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                onKeyDown={validateKeyDown}
              >
                <option value="">{t("select")}</option>
                <option value="Rupee">INR</option>
                <option value="Doller">USD</option>
                <option value="Canadian">CA</option>
              </select>
              <div className="absolute right-0 flex items-center pr-3 pointer-events-none">
                <Svg18 />
              </div>
            </div>

            {Formik.errors.currency && Formik.touched.currency ? (
              <p className="text-[red]">{Formik.errors.currency}</p>
            ) : null}
          </div>
        </div>
        <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
          <div className="relative flex flex-col">
            <label htmlFor="amount">{t("amount")}</label>
            <input
              type="text"
              name="amount"
              id="amount"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded"
              value={Formik.values.amount}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
            />
            {Formik.errors.amount && Formik.touched.amount ? (
              <p className="text-[red]">{Formik.errors.amount}</p>
            ) : null}
          </div>
          <div className="relative flex flex-col">
            <label htmlFor="invoiceDate">{t("invoiceDate")}</label>
            <div>
              <DatePicker
                name={"invoice_date"}
                id="invoice_date"
                value={
                  values?.invoice_date === ""
                    ? getDateDashFormat(new Date())
                    : getDateDashFormat(values?.invoice_date)
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                min={new Date().toISOString()?.split("T")[0]}
              />
            </div>
            {Formik.errors.invoice_date && Formik.touched.invoice_date ? (
              <p className="text-[red]">{Formik.errors.invoice_date}</p>
            ) : null}
          </div>
        </div>
        <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
          <div className="relative flex flex-col ">
            <label htmlFor="dueDate">{t("dueDate")}</label>
            <div>
              <DatePicker
                name={"due_date"}
                id="due_date"
                value={
                  values?.due_date === ""
                    ? getDateDashFormat(new Date())
                    : getDateDashFormat(values?.due_date)
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                styles={"h-[2.625rem] w-full"} min={new Date().toISOString()?.split("T")[0]}
              />
            </div>

            {Formik.errors.due_date && Formik.touched.due_date ? (
              <p className="text-[red]">{Formik.errors.due_date}</p>
            ) : null}
          </div>
          <div className="relative flex flex-col">
            <label htmlFor="status">{t("status")}</label>
            <div className="relative flex items-center">
              <select
                id="status"
                name="status"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white appearance-none"
                value={Formik.values.status}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
              >
                <option value="">{t("select")}</option>
                <option value="paid">{t("Paid")}</option>
                <option value="pending">{t("pending")}</option>
                <option value="cancel">{t("cancel")}</option>
              </select>
              <div className="absolute right-0 flex items-center pr-3 pointer-events-none">
                <Svg18 />
              </div>
            </div>
            {Formik.errors.status && Formik.touched.status ? (
              <p className="text-[red]">{Formik.errors.status}</p>
            ) : null}
          </div>
        </div>
        <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4 p-3">
          <div className="flex flex-col text-[#313135] font-medium w-full">
            <label htmlFor="status_image">{t("upload_image")}</label>
            <label
              role="button"
              id={`status_image${index}`}
              className="border h-[160px] flex justify-center items-center rounded p-2"
            >
              <input
                type="file"
                multiple
                id={`status_image${index}`}
                onChange={handleImageChange}
                className="hidden"
              />
              <span>
                {t("drag_and_drop")} <b>{t("browse_file")}</b>
              </span>
            </label>
          </div>
          {values.Upload_Image?.length > 0 && (
            <div
              className="border h-[160px] flex flex-col items-start flex-1 mt-6
              rounded-[4px] py-3 px-4 gap-4 overflow-y-auto"
            >
              <span className=" text-sm text-[#313135] font-medium">
                {t("uploaded_images")}
              </span>
              <div className="w-full flex flex-col items-start gap-4 pl-2">
                {values.Upload_Image?.map((file, index) => (
                  <li
                    key={index}
                    className="w-full font-normal flex flex-row justify-between items-center text-base text-[#191919]"
                  >
                    <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem]" />
                    <span>{file.name}</span>
                    <GrFormClose
                      className="fill-[#686868] cursor-pointer stroke-[#686868]
                     stroke-[1px] w-6 h-6 p-1 box-content"
                      onClick={() => handleRemoveImage(index)}
                    />
                  </li>
                ))}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

AddInvoiceDetails.propTypes = {
  index: PropTypes.any,
  clickClient: PropTypes.any,
  setAddNewInvoice: PropTypes.bool,
  setRenderListing: PropTypes.bool,
  setShowInvoice: PropTypes.bool,
};
