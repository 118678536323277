import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganisationLeave } from 'redux/appThunk/Employee/leaves';
import { AddButton } from 'component/common/accessControlUi/Button';
import CustomTable from 'component/common/table/CustomTable';
import { IoFilter } from 'react-icons/io5';
import ProfileAvtar from 'component/common/ProfileAvtar';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import LeaveFilterColumn from './LeaveFilterColumn';
import AddLeave from './AddLeaveData';
import Paginate from 'component/admin/Employee/Paginate';
import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';
import useFetchLeaveData from './UseFetchLeaveData';
import useDebounce from 'hooks/useDebounce';
import Search from 'component/common/accessControlUi/Search';
import { alignValues, employeeDetails } from './column';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import StatusPopup from './StatusPopup';
import { handleLeaveSearchData, handleStatusChange } from 'redux/appThunk/Admin/leave';
import MyLeavepopup from './MyLeavepopup';
import EditLeaveDetails from './EditLeaveDetails';
import Cancelpopup from './Cancelpopup';
import PropTypes from "prop-types";

const columns = [
  {
    title: "Employee Name",
    field: "name",
    align: "left",
    className: "pl-2",
    render: (value, job) => (
      <div className="flex">
        <div className="w-12 h-12 m-3">
          {job?.employeePicture ? (
            <img
              className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
              src={job?.employeePicture}
              alt="Profile Avatar"
            />
          ) : (
            <ProfileAvtar
              className="h-[3rem] width-[3rem]"
              name={job?.name ? job?.name : null}
            />
          )}
        </div>
        <div className="pl-2 py-5 flex flex-col text-left justify-center">
          <div>{job?.name}</div>
          <div>{job?.empCode}</div>
        </div>
      </div>
    ),
  },
  {
    title: "Department",
    field: "department",
    align: "left",
    className: "pl-4",
  },
  {
    title: "Designation",
    field: "designation",
    align: "center",
    className: "pl-4",
  },
];

const dataFilter = [
  { id: 1, title: "Design" },
  { id: 2, title: "Software Engineer" },
  { id: 3, title: "HR" },
  { id: 4, title: "Business Development" },
];

const dataFilterValue = [
  { id: 1, title: "Senior Designer" },
  { id: 2, title: "Junior Designer" },
  { id: 3, title: "Trainee" },
];

const OrganisationLeave = ({ activeTab }) => {

  const { t } = useTranslation();
  const permisionsLeaveType = usePermissions(mappedPermissionObj.Leavetype);
  const secondPermission = permisionsLeaveType.userPermission.viewAll;
  const { userPermission } = usePermissions(mappedPermissionObj.Leave);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(false);
  const [addLeavePop, setAddLeavePop] = useState(false);
  const [currentPage, setCurrentPage] = useState("1");
  const [searchItem, setSearch] = useState("");
  const itemsPerPage = 10;
  const searchName = useDebounce(searchItem, 800);
  const [showDetails, setShowDetails] = useState(false);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const { Organisation, allLeaves, pageCount, isLoading } = useSelector((state) => state.leaveReducer);
  const [userIdAction, setUserIdAction] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [datas, setDatas] = useState(null);
  const [dropdownId, setDropdownId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [handlecancelpopup, setHandlecancelpopup] = useState(false);
  const [checkedResources, setCheckedResources] = useState(new Set());
  const [ids, setIds] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupBtn, setPopupBtn] = useState("");

  useEffect(() => {
    dispatch(fetchOrganisationLeave(currentPage, itemsPerPage, "", "", ""));
  }, [dispatch, currentPage, itemsPerPage]);

  const handleFilterApply = () => {
    dispatch(fetchOrganisationLeave("", "", department, designation, ""));
  };

  const checkDepartment = (selectedDepartments) => {
    setDepartment(selectedDepartments);
  };

  const checkDesignation = (selectedDesignations) => {
    setDesignation(selectedDesignations);
  };

  const handleResetFilters = () => {
    setDepartment([]);
    setDesignation([]);
    setCheckedResources([]);
    dispatch(fetchOrganisationLeave(currentPage, itemsPerPage, "", "", ""));
  };

  const handleViewDetails = (data) => {

    setDatas(data);
    setShowDetails(true);
    setUserIdAction(data.userId);
    dispatch(handleLeaveSearchData({userId: data?.userId}));
  };

  useFetchLeaveData({
    permissionToFetch: userPermission,
    currentPage,
    itemsPerPage,
    searchName,
    userIdAction,
  });

  const handleOpenDropDown = (id) => {
    setIds(id);
  };

  const handleOptionClick = async (option, leaves) => {
    dispatch(handleStatusChange(leaves, ids, option));
  };

  const handleOpen = (data) => {
    setDatas(data);
    setopenPopup(true);
    if (dropdownOpen && dropdownId === data?.id) {
      setDropdownOpen(false);
      setDropdownId(null);
    } else {
      setDropdownOpen(true);
      setDropdownId(data?.id);
    }
  };

  const handleOpens = () => {
    setShowPopup(!showPopup);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setopenPopup(false);
  };

  const handleSaveEdit = () => {
    setShowEditPopup(false);
  };
  const tempData = [
    {
      title: "Status ",
      field: "status",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className='items-start'>
              <StatusPopup
                key={index}
                leave={data}
                handleOpenDropDown={handleOpenDropDown}
                handleSelect={handleOptionClick}
                showDropDown={data?.id === ids}
                handleOpen={handleOpens}
                setPopupTitle={setPopupTitle}
                setPopupBtn={setPopupBtn}
                activeTab={activeTab}
              />
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className='items-start'>
              <div onClick={() => handleOpen(data)}>
                <FaEye
                  key={index}
                  leave={data}
                  handleOpenDropDown={handleOpenDropDown}
                  handleSelect={handleOptionClick}
                  showDropDown={data.id === ids}
                />
              </div>

            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  return (
    <>

      <div className="w-full h-fit overflow-hidden">
        {openPopup && (
          <MyLeavepopup
            openPopup={openPopup}
            setOpenPopup={setopenPopup}
            datas={datas}
          />
        )}

        {showEditPopup && (
          <EditLeaveDetails
            setOpenPopup={setopenPopup}
            leaveDetails={datas}
            onClose={handleCloseEditPopup}
            onSave={handleSaveEdit}
            handlecancelpopup={handlecancelpopup}
          />
        )}

        {showPopup && (
          <Cancelpopup
            openPopup={showPopup}
            setOpenPopup={setShowPopup}
            datas={datas}
            onClosed={handleOpens}
            onSave={handleSaveEdit}
            setHandlecancelpopup={setHandlecancelpopup}
            popupTitle={popupTitle}
            popupBtn={popupBtn}
            handleOptionClick={handleOptionClick}
          />
        )}
        {!showDetails ? (
          <div
            className="w-[97.5%] h-[calc(100vh-10rem)] bg-white space-y-4
        flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mb-3 ml-3 mt-4">

            <div className="w-full h-16 bg-white flex justify-between">
              <div className="flex justify-center items-center">
                <h2 className="font-extrabold text-xl text-[#002169]">{t("details")}</h2>
              </div>
              <div className="flex items-center justify-center gap-4">
                <Search searchItem={searchItem} setSearchItem={setSearch} currentResource={userPermission} />
                <div className="relative">
                  <div className="p-3 text-xl rounded-full border">
                    <IoFilter className="w-6 h-6 stroke-[#031B59]" onClick={() => setFilter(!filter)} />
                  </div>
                  {filter && (
                    <div className="absolute top-[3.6rem] z-50">
                      <LeaveFilterColumn
                        showFilter={filter}
                        setFilter={setFilter}
                        setShowFilter={setFilter}
                        dataFilter={dataFilter}
                        dataFilterValue={dataFilterValue}
                        checkBox1={checkDepartment}
                        checkBox2={checkDesignation}
                        filterTitleFirst={"Department"}
                        filterTitleSecond={"Designation"}
                        handleFilterApply={handleFilterApply}
                        handleResetFilters={handleResetFilters}
                        checkedResources={checkedResources}
                        setCheckedResources={setCheckedResources}

                      />
                    </div>
                  )}
                </div>
                <AddButton
                  currentResource={mappedPermissionObj.Leave}
                  title={t('Apply Leave')}
                  onClick={() => setAddLeavePop(true)}
                />
                {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
              </div>
            </div>
            <div className={`overflow-x-scroll w-full ${isLoading ? 'custom_scroller' : 'custom_scroll'}`}>
              <CustomTable
                columns={columns}
                data={Organisation}
                isLoading={isLoading}
                renderAction={(value) => (
                  <FaEye
                    onClick={() => handleViewDetails(value)}
                  />
                )}
              />
            </div>

            {(userPermission?.view || secondPermission) && (
              <div className="w-full h-12 bg-white flex justify-between items-center">
                {!isLoading && (
                  <>
                    <div className='w-full flex flex-wrap justify-between items-center mb-4 '>

                      <div className="text-[#031B59] bg-white font-medium ">
                        {t('Showing')} {currentPage} {t('of')} {pageCount}
                      </div>
                      <Paginate
                        currentPage={currentPage}
                        initialPageCount={pageCount}
                        pageRangeDisplayed={5}
                        next='>'
                        previous='<'
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </>
                )}
                {Organisation.length === 0 && (
                  <div className="w-full flex items-center justify-center font-medium">
                    {t('no_data_found')}
                  </div>
                )}
              </div>
            )}

          </div>
        ) : (
          <div>
            <div className="w-[97.5%] h-[calc(100vh-10rem)] bg-white space-y-4
        flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mb-3 ml-3 mt-4">

              <div className="flex justify-between mb-4 items-center">
                <div>
                  <h2 className="font-extrabold text-xl text-[#031B59] flex items-center">
                    <HiArrowNarrowLeft
                      className={`mr-2  opacity-50" : "cursor-pointer" }`}
                      onClick={() => (setShowDetails(false))}
                    />
                    {datas?.name ? datas?.name : t("N/A")}
                  </h2>
                  <div className='pl-7 text-gray-400'>{datas?.designation ? datas?.designation : t("N/A")}</div>
                </div>
                <div className="flex items-center space-x-4">
                  {userPermission?.create && (
                    <AddButton
                      currentResource={mappedPermissionObj.Leave}
                      title={t("Apply Leave")}
                      onClick={() => setAddLeavePop(true)}

                    />
                  )}
                  {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}

                </div>
              </div>

              <div className={`overflow-x-scroll h-full ${isLoading ? 'custom_scroller' : 'custom_scroll'}`}>
                <CustomTable
                  newColumn={["Status", "Action"]}
                  columns={[...employeeDetails, ...tempData]}
                  data={allLeaves}
                  isLoading={isLoading}
                />
              </div>
              {(userPermission?.view || secondPermission) && (
                <div className="w-full h-12 bg-white flex justify-between items-center">
                  {!isLoading && (
                    <>
                      <div className='w-full flex flex-wrap justify-between items-center mb-4 '>

                        <div className="text-[#031B59] bg-white font-medium ">
                          {t('Showing')} {currentPage} {t('of')} {pageCount}
                        </div>
                        <Paginate
                          currentPage={currentPage}
                          initialPageCount={pageCount}
                          pageRangeDisplayed={5}
                          next='>'
                          previous='<'
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                    </>
                  )}
                  {Organisation.length === 0 && (
                    <div className="w-full flex items-center justify-center font-medium">
                      {t('no_data_found')}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

        )}

      </div>

    </>
  );
};

export default OrganisationLeave;

OrganisationLeave.propTypes = {
  activeTab: PropTypes.func,
  setPopupTitle: PropTypes.func,
};
