/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CustomTableVariant2 from "component/common/table/CustomTableVariant2";
import { ColumnDepartment, alignValues } from "./ColumnDepartment";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { deleteDepartment, EditDepartment } from "redux/appThunk/Admin/department";
import DepartmentView from "./DepartmentView";
import SvgEdit from "svgComponents/department/SvgEdit";
import SvgDelete from "svgComponents/department/SvgDelete";
import SvgEye from "svgComponents/department/SvgEye";
import Paginate from "../Employee/Paginate";
import Popup from "component/common/Popup";
import LoaderComp from "component/loader/LoaderComp";
import SvgWrong from "svgComponents/department/SvgWrong";
import SvgRight from "svgComponents/department/SvgRignt";

const DepartmentListing = ({ selector = [], pageCount, currentPage, setCurrentPage, isLoading, setGetData }) => {
  const [ids, setIds] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [eyePop, setEyePop] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const getRole = JSON.parse(localStorage.getItem("userLoginToken"));
  const [showInput, setShowInput] = useState(false);
  const [dropdownId, setDropdownId] = useState(null);
  const [newDepartment, setNewDepartment] = useState("");
  const [editField, setEditField] = useState(null);
  const [loader, setLoder] = useState(false);

  const inputRef = useRef(null);
  const [editValue, setEditValue] = useState("");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [departmentIdToDelete, setDepartmentIdToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenDropDown = (id) => {
    setIds(id);
  };

  const handleClick = () => {
    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    if (editField) {
      setNewDepartment(editField.department);
      setShowInput(true);
    }
  }, [editField]);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const handleOpen = (id) => {
    setOpenPopup(true);
    if (dropdownOpen && dropdownId === id) {
      setDropdownOpen(false);
      setDropdownId(null);
    } else {
      setDropdownOpen(true);
      setDropdownId(id);
    }
  };

  const handleDelete = (id) => {
    setDepartmentIdToDelete(id);
    setIsDeletePopupOpen(true);
  };

  const handleConfirmDelete = () => {
    if (departmentIdToDelete !== null) {
      dispatch(deleteDepartment(departmentIdToDelete, setLoder, setGetData));
      setIsDeletePopupOpen(false);
      setDepartmentIdToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setIsDeletePopupOpen(false);
    setDepartmentIdToDelete(null);
  };

  const handleEyeClick = (id) => {
    setSelectedDepartmentId(id);
    setEyePop(true);
  };

  const handleCloseEyePop = () => {
    setEyePop(false);
    setSelectedDepartmentId(null);
  };

  const handleEdit = (job) => {
    setIds(job);
    setEditField(job);
    setNewDepartment(job.department);
    setShowInput(true);
  };

  const handleEditDepartment = useCallback(() => {
    dispatch(EditDepartment(newDepartment, editField.id,setLoder,setGetData));
    setNewDepartment("");
    setShowInput(false);
    setEditField(null);
  }, [dispatch, editField, newDepartment]);

  const Input = useCallback(({ value, onChange }) => {
    return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        ref={inputRef}
        className="active:outline-[#031B59] focus-visible:outline-gray-500 text-black"
      />
    );
  }, []);

  const handleCancelNewRow = useCallback(() => {
    setShowInput(false);
    setNewDepartment("");
    setEditField(null);
  }, []);

  const handleInputChange = useCallback((e) => {
    setNewDepartment(e.target.value);
  }, []);

  const SvgData = useCallback(({ onClick, onCancel }) => {
    return (
      <>
        <button
          onClick={onClick}
          className="text-white p-1 rounded items-center justify-center"
        >
          <SvgRight />
        </button>
        <button
          onClick={onCancel}
          className="text-white p-1 rounded items-center justify-center"
        >
          <SvgWrong />
        </button>
      </>
    );
  }, []);

  const TopNewData = useMemo(() => (
    <tr>
      <td className="min-w-[11rem] w-fit px-[15px] h-[50px] text-[#d8d5d5]">
        <Input value={newDepartment} onChange={handleInputChange} />
      </td>
      <td className="min-w-[57rem] w-[15px] h-[60px] text-[#686868] flex items-center justify-center"></td>
      <td className="min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]">
        <SvgData onClick={handleEditDepartment} onCancel={handleCancelNewRow} />
      </td>
    </tr>
  ), [newDepartment, handleInputChange, handleEditDepartment, handleCancelNewRow]);

  const tempData = [
    {
      title: "Action",
      field: "action",
      align: alignValues.left,
      render: (index, job) => {
        return (
          <>
            <div className="items-start">
              {getRole?.role === "admin" ? (
                <>
                  <div onClick={() => handleOpen(job?.id)} className="flex space-x-2">
                    <div onClick={() => handleEyeClick(job?.id)} className="cursor-pointer">
                      <SvgEye
                        leave={job}
                        handleOpenDropDown={handleOpenDropDown}
                        showDropDown={job.id === ids}
                      />
                    </div>
                    <div className="cursor-pointer" onClick={() => handleEdit(job)}>
                      <SvgEdit>
                        <li>
                          <div className="hover:bg-[#F2F6FF] text-[#191919] font-Roboto py-1">
                            {t("edit")}
                          </div>
                        </li>
                      </SvgEdit>
                    </div>
                    <div className="cursor-pointer" onClick={() => handleDelete(job.id)}>
                      <SvgDelete>
                        <li>
                          <div className="hover:bg-[#F2F6FF] cursor-pointer text-[#191919] font-Roboto py-1">
                            {t("delete")}
                          </div>
                        </li>
                      </SvgDelete>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {dropdownOpen && dropdownId === job.id && (
                    <div className="absolute mt-2 bg-white border rounded-lg shadow-lg">
                      <ul className="py-1">
                        <li onClick={() => handleEyeClick(job?.id)}>
                          <div className="hover:bg-[#F2F6FF] text-[#191919] font-Roboto py-1">
                            {t("view")}
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div>
      {loader ? <LoaderComp /> : (
        <div className="">
          <div className="overflow-x-scroll custom_scroll w-full h-[43rem] capitalize">
            <CustomTableVariant2
              newColumn={["Action"]}
              columns={[...ColumnDepartment, ...tempData]}
              data={selector}
              TopNewData={showInput ? TopNewData : null}
            />
          </div>
        </div>
      )}

      {eyePop && <DepartmentView id={selectedDepartmentId} onClose={handleCloseEyePop} />}
      {isDeletePopupOpen && (
        <Popup
          popupBox={handleCancelDelete}
          title={t("deleteDepartment")}
          handleSubmit={handleConfirmDelete}
          submitBtnText="Delete"
        >
          <p></p>
        </Popup>
      )}
      {!isLoading && (
        <div className="w-full h-[3rem] bg-white flex justify-between items-center">
          {selector?.length > 0 ? (
            <>
              <div className="text-[#031B59] font-medium">
                {t("showing")} {currentPage} {t("of")} {pageCount}
              </div>
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                handlePageClick={(selected) => setCurrentPage(selected)}
              />
            </>
          ) : (
            <div className="text-[#031B59] font-medium">
              {t("noDataFound")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DepartmentListing.propTypes = {
  selector: PropTypes.array,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  // isLoading: PropTypes.bool,
  // setGetData: PropTypes.func,
};

export default DepartmentListing;
