import React, { useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AiFillFileImage } from "react-icons/ai";
import { useFormik } from "formik";
import FeedbackModal from "./FeedbackModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createInterviewSchedule } from "redux/appThunk/Admin/client";
import { awsURL } from "utils/Constants";
import { IoClose } from "react-icons/io5";
import DatePicker from "component/common/DatePicker";
import ProfileAvtar from "component/common/ProfileAvtar";
import { addScheduleSchema } from "redux/validator/admin/client";
import LoaderComp from "component/loader/LoaderComp";

function ScheduleModal({
  handleSchedulePage,
  projectId,
  userId,
  projectResourceData,
  resourceId,
  setRenderSchedulePage}) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [budgetToggle, setBudgetToggle] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [get, setGet] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (fileName) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const initialvalues = {
    description: "",
    date: "",
    name: projectResourceData?.employeeName || "User",
    startTime: "",
    endTime: "",
    interviewRounds: "",
    meetingLink: "",
    interviewDocument: [],
    title: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addScheduleSchema,
    onSubmit: async (values, { resetForm }) => {
      if (isSubmitting)
        setIsSubmitting(true);
      const formData = new FormData();
      formData.append("interview[title]", values.title);
      formData.append("interview[description]", values.description);
      formData.append("interview[date]", values.date);
      formData.append("interview[from_time]", values.startTime);
      formData.append("interview[to_time]", values.endTime);
      formData.append("interview[interview_rounds]", values.interviewRounds);
      formData.append("interview[meeting_link]", values.meetingLink);
      formData.append("interview[show_budget]", budgetToggle);
      if (selectedFiles) {
        for (var i = 0; i < selectedFiles.length; i++) {
          formData.append("interview[interview_documents][]", selectedFiles[i]);
        }
      }

      dispatch(
        createInterviewSchedule(
          formData,
          id,
          projectId,
          projectResourceData.id,
          setRenderSchedulePage,
          setGet
        )
      );

      setIsSubmitting(false);
      resetForm();
      handleSchedulePage();
      setRenderSchedulePage((prev) => !prev);
    },
  });

  const { values, handleSubmit, handleChange, handleBlur} = Formik;

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full bg-white flex-flex-col space-y-8
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-10 bg-white flex justify-between items-center p-4 pt-6">
          <div className="h-12 bg-white flex justify-between items-center ">
            <div
              className={`font-bold text-[20px] mr-[3rem] cursor-pointer ${
                activeTab === 1 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setActiveTab(1)}
            >
              {t("schedule_interview")}
            </div>
            <div
              className={`font-bold text-[20px] cursor-pointer ${
                activeTab === 2 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setActiveTab(2)}
            >
              {t("feedbacks")}
            </div>
          </div>

          <div>
            <IoClose
              onClick={handleSchedulePage}
              className="ml-auto fill-[#686868] w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
        <hr />
        {activeTab === 1 && (
          <div className="flex flex-col space-y-3 pl-4 pr-4 items-center custom_scroll overflow-y-auto h-[90%] ">
            <div className="w-full flex flex-col space-y-2 ">
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("resource_name")}
              </label>
              <div
                className="relative w-full max-w-full h-fit flex items-center
                 cursor-default border rounded border-[#E2E8F0] flex-col]"
              >
                <div
                  className="w-full h-fit min-h-[47px] flex flex-wrap rounded items-center
              py-[2px] px-[10px] justify-start gap-1"
                >
                  <span
                    className="p1 flex flex-row items-center justify-normal gap-2 px-[14px]
                rounded-full bg-[#F2F6FF]"
                  >
                    <div className="w-9 flex items-center justify-center rounded-full overflow-hidden">
                      <ProfileAvtar
                        height="2rem"
                        width="2rem"
                        name={projectResourceData?.employeeName ? projectResourceData?.employeeName : "User"}
                      />
                    </div>
                    {`${values.name}`}
                  </span>
                </div>
              </div>
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("title")}
              </label>
              <input
                type="text"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-3"
              />
              {Formik.touched.title && Formik.errors.title && (
                <p className="text-red-500">{Formik.errors.title}</p>
              )}
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("desc")}
              </label>
              <textarea
                className="h-[5rem] border border-[#E2E8F0] rounded-[5px] p-2 resize-none"
                name="description"
                id="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
              {Formik.touched.description && Formik.errors.description && (
                <p className="text-red-500">{Formik.errors.description}</p>
              )}
              <div className="flex justify-between">
                <label
                  htmlFor=""
                  className="text-[16px] font-medium text-[#313135]"
                >
                  {t("budget")}
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div
                    className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                  dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full
                  rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
                  after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border
                  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                  peer-checked:bg-blue-600"
                    onClick={() => setBudgetToggle(!budgetToggle)}
                  />
                </label>
              </div>
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("interview_details")}
              </label>

              <div className="flex flex-col gap-1">
                <h3 className="text-[#313135] text-base font-medium">
                  {t("date")}
                </h3>
                <div className="w-full relative">
                  <DatePicker
                    name="date"
                    type="date"
                    value={Formik.values.date}
                    handleChange={Formik.handleChange}
                    handleBlur={Formik.handleBlur}
                    min={new Date().toISOString().split("T")[0]}
                    styles={"h-[2.625rem] w-full text-black"}
                  />
                </div>
                {Formik.touched.date && Formik.errors.date && (
                  <p className="text-red-500">{Formik.errors.date}</p>
                )}
              </div>
              <div className="flex justify-start gap-4">
                <div className="flex flex-col gap-1 w-1/2">
                  <h3 className="text-[#313135] text-base font-medium">
                    {t("startTime")}
                  </h3>
                  <input
                    type="time"
                    name="startTime"
                    value={Formik.values.startTime}
                    onChange={Formik.handleChange}
                    className="border p-[0.62rem] text-black w-full cursor-pointer"
                  />
                  {Formik.touched.startTime && Formik.errors.startTime && (
                    <p className="text-red-500">{Formik.errors.startTime}</p>
                  )}
                </div>
                <div className="flex flex-col gap-1 w-1/2">
                  <h3 className="text-[#313135] text-base font-medium">
                    {t("endTime")}
                  </h3>
                  <input
                    type="time"
                    name="endTime"
                    value={Formik.values.endTime}
                    onChange={Formik.handleChange}
                    className="border p-[0.62rem] text-black w-full cursor-pointer"
                  />
                  {Formik.touched.endTime && Formik.errors.endTime && (
                    <p className="text-red-500">{Formik.errors.endTime}</p>
                  )}
                </div>
              </div>
              <label
                htmlFor="interviewRounds"
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("round")}
              </label>
              <select
                className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-3 bg-white cursor-pointer"
                value={values.interviewRounds}
                onChange={handleChange}
                onBlur={handleBlur}
                name="interviewRounds"
                id="interviewRounds"
              >
                <option value={''} selected disabled>Select Round</option>
                {Array.of(1,2,3,4,5)?.map((item) => (
                  <option
                    className="p-5"
                    key={item}
                    value={`Round ${item}`}
                  >
                    {`Round ${item}`}
                  </option>
                ))}
              </select>
              {Formik.touched.interviewRounds && Formik.errors.interviewRounds && (
                <p className="text-red-500">{Formik.errors.interviewRounds}</p>
              )}
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("url")}
              </label>
              <input
                type="text"
                name="meetingLink"
                id="meetingLink"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.meetingLink}
                className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-3"
              ></input>
              {Formik.touched.meetingLink && Formik.errors.meetingLink && (
                <p className="text-red-500">{Formik.errors. meetingLink}</p>
              )}
              <label
                htmlFor=""
                className="text-[16px] font-medium text-[#313135]"
              >
                {t("documents")}
              </label>
              <div>
                <div className="flex text-[16px] flex-col">
                  {selectedFiles.length > 0 &&
                  selectedFiles.map((file, index) => (
                    <div className="flex justify-between" key={index}>
                      <div key={file.name} className="flex">
                        <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem] mr-2" />
                        <span>{file.name}</span>
                      </div>
                      <GrFormClose
                        className="text-[#F00] w-5 h-5 box-content cursor-pointer mr-6"
                        onClick={() => handleRemoveFile(file.name)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <label
                role="button"
                className="border h-[62px] flex flex-col justify-center items-center rounded w-full"
              >
                <input
                  type="file"
                  multiple
                  id=""
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileChange}
                />
                <img
                  className="pr-3 cursor-pointer"
                  src={`${awsURL}/images/cloudUpload.png`}
                  alt="cloud upload"
                />
                <div className="flex items-center">
                  <span>
                    {t("drag_and_drop_or")}{" "}
                    <b className="text-[#031B59]">{t("browse_file")}</b>
                  </span>
                </div>
              </label>
              <div className="flex justify-end pr-4 pt-2 pb-2">
                {
                  get ? <LoaderComp /> : (
                    <button
                      onClick={handleSubmit}
                      disabled={isSubmitting}>
                      <div
                        className="flex w-[114px] h-[49px] p-[1rem_1.875rem] justify-center items-center
                  rounded-[2.5rem] bg-[#031B59] text-[1rem] font-bold text-white"
                      >
                        {t("schedule")}
                      </div>
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <FeedbackModal
            setRenderSchedulePage={setRenderSchedulePage}
            projectId={projectId}
            userId={userId}
            resourceId={resourceId}
          />
        )}
      </div>
    </div>
  );
}

export default ScheduleModal;

ScheduleModal.propTypes = {
  handleSchedulePage: PropTypes.func,
  projectId: PropTypes.number,
  projectResourceData: PropTypes.object,
  setRenderSchedulePage: PropTypes.bool,
  userId: PropTypes.number,
  resourceId: PropTypes.number,
};
