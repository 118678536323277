export const fetchJobData = ({ data, pagination_data }) => {
  if (!data) return { data: [] };

  return {
    data: data.map((obj) => ({
      id: obj.id,
      status: obj.status,
      title: obj.title,
      vacancy: obj.vacancy_count,
      requirements: obj.requirements,
      jobType: obj.job_type,
      createdAt: obj.created_at,
      createdBy: obj.created_by?.name,
      preferredLocation: obj.location,
      applicants: obj.applicants ?? "--",
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
  };
};

export const singleJobData = (response) => {
  return {
    id: response?.id,
    title: response.title,
    location: response.location,
    status: response.status,
    vacancy: response.vacancy_count,
    requirements: response.requirements,
    jobType: response.job_type,
    description: response.description,
    benefits: response.benefits,
  };
};

export const fetchJobApplications = (response) => {
  const requiredResponse = [];

  response.map((obj) => {
    requiredResponse.push({
      id: obj.id,
      fullName: obj.first_name + " " + obj.last_name,
      email: obj.email,
      jobName: obj.job_name,
      jobFullName: obj.first_name + " " + obj.last_name,
      appliedFor: obj.job_name,
      contactNo: obj.phone_number,
      noticePeriod: obj.notice_period,
      expectedSalary: obj.expected_salary,
      immediateJoiner: obj.immediate_joiner,
      reference: obj.reference,
      currentCTC: obj.current_ctc,
      experience: obj.experience,
      country: obj.country,
      state: obj.state,
      linkedUrl: obj.linkedin_url,
      portfolioUrl: obj.portfolioUrl,
      resumeUrl: obj.resume_url,
      appliedDate: obj.created_at,
      noticePeriodNegotiable: obj.notice_period_negotiable,
      status: obj.applicant_status,
      round: obj?.round,
      eventId: obj?.event_id,
      handledBy: obj.immediate_joiner,
      handledByNew: obj.handled_by,
    });
  });

  return requiredResponse;
};

export const fetchAllJobApplications = ({
  job_applications,
  pagination_data,
}) => {
  return {
    data: job_applications.map((obj) => ({
      id: obj.id,
      fullName: obj.first_name + " " + obj.last_name,
      email: obj.email,
      contactNo: obj.phone_number,
      noticePeriod: obj.notice_period,
      expectedSalary: obj.expected_salary,
      immediateJoiner: obj.immediate_joiner,
      reference: obj.reference,
      currentCTC: obj.current_ctc,
      experience: obj.experience,
      country: obj.country,
      state: obj.state,
      linkedUrl: obj.linkedin_url,
      portfolioUrl: obj.portfolioUrl,
      resumeUrl: obj.resume_url,
      appliedDate: obj.created_at,
      noticePeriodNegotiable: obj.notice_period_negotiable,
      jobName: obj.job_name,
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
  };
};

export const updateJobData = (response) => {
  const jsonObj = {
    job_opening: {
      title: response.title,
      location: response.location,
      status: response.status,
      vacancyCount: response.vacancy_count,
      requirements: response.requirements,
      jobType: response.job_type,
      description: response.description,
      benefits: response.benefits,
    },
  };

  return jsonObj;
};

export const getJobAppApplicants = ({
  job_applications,
  pagination_data,
}) => {
  return {
    data: job_applications.map((obj) => ({
      id: obj.id,
      title: obj.first_name + " " + obj.last_name,
      contactNo: obj.phone_number,
      jobFullName: obj.first_name + " " + obj.last_name,
      appliedFor: obj.job_name,
      jobName: obj.job_name,
      appliedDate: obj.created_at,
      handledBy: obj.immediate_joiner,
      handledByNew: obj.handled_by,
      status: obj.applicant_status,
      resumeUrl: obj.resume_url,
      firstName: obj.first_name,
      lastName: obj.last_name,
      email: obj.email,
      country: obj.country,
      state: obj.state,
      linkedIn: obj.linkedin_url,
      portFolio: obj.portfolio_url,
      currentCTC: obj.current_ctc || "",
      expectedSalary: obj.expected_salary || "",
      imidiateJoiner: obj.immediate_joiner,
      experience: obj.experience || "",
      noticePeriod: obj.notice_period,
      noticePeriodNegotiable: obj.notice_period_negotiable,
      round: obj?.round,
      eventId: obj?.event_id,
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
  };
};

export const fetchgetfeeback = (response) => {
  const requiredResponse = [];
  response?.job_applications_feedbacks?.map((data) => {
    const obj = data?.job_applications_feedback;
    const user = data?.user;
    requiredResponse.push({
      userId: user?.id,
      fullName: user?.full_name,
      email: user?.email,
      profilepictureurl: user?.profile_picture_url,
      id: obj?.id,
      description: obj?.description,
      createdbyid: obj?.created_by_id,
      technicalskills: obj?.technical_skills,
      soft_skills: obj?.soft_skills,
      experienceandknowledge: obj?.experience_and_knowledge,
      problemsolvingandcriticalthinking: obj?.problem_solving_and_critical_thinking,
      projectmanagement: obj?.project_management,
      leadershipandinitiative: obj?.leadership_and_initiative,
      culturalfit: obj?.cultural_fit,
      portfolioUrl: obj?.portfolioUrl,
      performanceunderpressure: obj?.performance_under_pressure,
      avgstars: obj?.avg_stars,
      jobapplicationid: obj?.job_application_id,
    });
  }
  );

  return requiredResponse;
};

export const fetchAllEvent = (response) => {
  const requiredResponse = [];
  response?.data?.map((obj) =>
    requiredResponse.push({
      fullName: obj?.title,
      date: obj?.date,
      id: obj?.id,
      startTime: obj?.start_time,
      endTime: obj?.end_time,
      desc: obj?.description,
      guest: obj?.guests_ids,
      documents: obj?.event_documents,
      url: obj?.meeting_url,
      round: obj?.round,
    })
  );

  return requiredResponse;
};
