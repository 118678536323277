import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
import ProfileAvtar from "component/common/ProfileAvtar";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DashboardCommonPopup = ({ title, closeDrawer, data }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const {t} = useTranslation();
  const filteredData = data?.filter((obj) =>
    obj.full_name && obj.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
      <div className="min-w-[40%] h-full p-5
       bg-white flex-flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
        <div className="flex justify-between px-[0.88rem] py-[0.62rem] border-b border-b-[#E2E8F0]">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">
              {title}{" "}
              <span className="text-[0.87rem] text-[#686868] font-normal">
              </span>
            </p>
          </div>
          <div>
            <RxCross2 onClick={closeDrawer} className="text-[#191919] text-xl" />
          </div>
        </div>
        <div className="flex justify-between px-[0.88rem]
         py-[0.62rem] border border-[#E2E8F0] rounded-[2.5rem] dashboard_search">
          <div className="w-full">
            <input
              type="search"
              placeholder="search"
              className="text-[#A1A1A1] w-full "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <CiSearch className="text-[#A1A1A1] text-lg" />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {filteredData?.length === 0 ? (
            <div className="flex items-center justify-center text-gray-500">
              {t("noRecord")}
            </div>
          ) : (
            filteredData?.map((obj, id) => (
              <div key={id} className="flex items-center gap-2">
                {obj?.img ? (
                  <img
                    src={obj?.img}
                    alt="Profile Avatar"
                  />
                ) : (
                  <ProfileAvtar
                    height="3rem"
                    width="3rem"
                    name={obj.full_name ? obj.full_name : "-"}
                  />
                )}
                <div className="tab relative  flex flex-col items-start    ">
                  <p className="  text-[0.87rem] text-[#031B59] font-medium text-sm capitalize">
                    {obj.full_name ? obj.full_name : "-"}
                  </p>
                  <p className="text-xs relative  text-[#A1A1A1]">
                    {obj?.emp_code ? obj?.emp_code : "-"}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

DashboardCommonPopup.propTypes = {
  title: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      designation: PropTypes.shape({
        designation: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default DashboardCommonPopup;
