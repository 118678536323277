import React, { useEffect, useState } from "react";
import { IoClose, IoSendSharp } from "react-icons/io5";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { monthList } from "utils/Constants";
import { convertDateFormat } from "utils/date";
import { addReviews, fetchReviews, updateReviews } from "redux/appThunk/Admin/client";
import { useDispatch, useSelector } from "react-redux";
import { addreviews } from "redux/validator/admin/client";
import ProfileAvtar from "component/common/ProfileAvtar";
import Editpopup from "./Editpopup";
import LoaderComp from "component/loader/LoaderComp";

function ReviewModal({ resourceId, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rating, setRating] = useState({ punctuality: 0, productivity: 0, behaviour: 0 });
  const [hover, setHover] = useState({ punctuality: 0, productivity: 0, behaviour: 0 });
  const [editId, setEditId] = useState(null);
  const [months, setMonths] = useState("");
  const [reload, setReload] = useState(false);
  const [handlePopup, setHandlePopup] = useState(false);
  const [userData, setUserData] = useState({});
  const initialValues = {
    reviewInp: "",
    month: "",
  };
  const { reviews, review } = useSelector((state) => state.ClientsReducer);

  useEffect(() => {
    if (resourceId) {
      dispatch(fetchReviews(resourceId?.userId, setReload));
    }
  }, [resourceId, handlePopup]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addreviews,
    onSubmit: async (values, { resetForm }) => {

      const jsonObj = {
        review: {
          resource_id: resourceId?.userId,
          review: values?.reviewInp,
          project_id: resourceId?.projectId,
          punctuality_rating: rating?.punctuality,
          behaviour_rating: rating?.behaviour,
          productivity_rating: rating?.behaviour,
          month: values?.month,
        },
      };
      if (editId) {
        dispatch(updateReviews(jsonObj, editId));
        setEditId(null);
      } else {
        dispatch(addReviews(jsonObj));
        resetForm();
      }
      onClose();
    },
  });

  const { values, handleChange, handleSubmit, isSubmitting, handleBlur } = formik;

  useEffect(() => {
    if (review) {
      setMonths(review?.month);
      setRating({
        punctuality: review?.punctualityRating,
        productivity: review?.productivityRating,
        behaviour: review?.behaviourRating,
      });
      formik.setFieldValue("review", review?.review);
    }
  }, [review]);
  const handleClick = ((review) => {
    setUserData((pev) => ({ ...pev, review }));
    setHandlePopup(true);
  });

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full bg-white flex-flex-col space-y-8
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >

        <div className="w-full h-16 bg-white flex justify-between items-center p-4">
          <div className="font-bold text-[20px] text-[#031B59]">
            {t("reviews")}
          </div>
          <div>
            <IoClose
              onClick={onClose}
              className="ml-auto fill-[#686868] w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-col space-y-3 pl-4 pr-4 items-center">
          <div className="w-full flex flex-col space-y-2">
            <label htmlFor="month" className="text-[16px] font-medium text-[#313135]">
              {t("month")}
            </label>
            <select
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] bg-white rounded text-[#313135]"
              id="month"
              name="month"
              value={values?.month ? values?.month : months}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select</option>
              {monthList.map((monthName, index) => (
                <option key={index} value={monthName.toLowerCase()}>
                  {monthName}
                </option>
              ))}
            </select>
            {formik.touched.month && formik.errors.month && (
              <p className="text-red-500">{formik.errors.month}</p>
            )}
          </div>
          <form className="w-full flex flex-col" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col space-y-3">
              <label htmlFor="review" className="text-[16px] font-medium text-[#313135]">
                {t("write_review_here")}
              </label>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Punctuality</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${index <= (hover?.punctuality || rating?.punctuality) ?
                          "fill-[#FFC700]" : "fill-[#A1A1A1]"} w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, punctuality: index })}
                        onMouseEnter={() => setHover({ ...hover, punctuality: index })}
                        onMouseLeave={() => setHover(rating?.punctuality)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Behaviour</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${index <= (hover?.behaviour || rating?.behaviour) ?
                          "fill-[#FFC700]" : "fill-[#A1A1A1]"} w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, behaviour: index })}
                        onMouseEnter={() => setHover({ ...hover, behaviour: index })}
                        onMouseLeave={() => setHover(rating?.behaviour)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#031B59]">Productivity</span>
                <div className="flex gap-3">
                  {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                      <AiOutlineStar
                        className={`${index <= (hover?.productivity || rating?.productivity) ?
                          "fill-[#FFC700]" : "fill-[#A1A1A1]"} w-5 h-5`}
                        type="button"
                        key={index}
                        onClick={() => setRating({ ...rating, productivity: index })}
                        onMouseEnter={() => setHover({ ...hover, productivity: index })}
                        onMouseLeave={() => setHover(rating?.productivity)}
                      />
                    );
                  })}
                </div>
              </div>
              <textarea
                className="h-[6rem] border border-[#F2F6FF] rounded-[5px] p-2 resize-none"
                name="reviewInp"
                value={values.reviewInp}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Tell us more..."
              />
              {formik.touched.reviewInp && formik.errors.reviewInp && (
                <p className="text-red-500">{formik.errors.reviewInp}</p>
              )}
            </div>
            <div className="flex w-full items-center justify-end gap-2">
              <button
                className="bg-[#031B59] w-[42px] h-8 rounded-md flex items-center justify-center mt-2"
                type="submit"
                disabled={isSubmitting}
              >
                <IoSendSharp className="w-5 h-5 fill-[#fff]" />
              </button>
            </div>
          </form>

          {reload ? (
            <LoaderComp />
          ) : (
            <>
              {
                reviews?.length > 0 ? (
                  <div className=" w-full rounded-[8px]
            flex flex-col space-y-3  custom_scroll overflow-y-scroll h-[50vh] ">
                    {reviews?.map((review) => (
                      <div key={review} className="h-fit border w-full rounded-[8px]
            flex flex-col pl-3 pr-3 space-y-3  ">
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex  items-center w-full justify-between">

                            <div className="flex items-center gap-2">
                              <div className="w-10 h-10 flex items-center justify-center">
                                <ProfileAvtar
                                  height="2rem"
                                  width="2rem"
                                  name={resourceId?.employeeName ? resourceId?.employeeName : "User"}
                                />
                              </div>
                              <div className="text-[#031B59] flex justify-center">
                                {resourceId?.employeeName}
                              </div>
                            </div>
                            <div >
                              <div className=" flex gap-3" >
                                {[...Array(5)].map((star, index) => {
                                  index += 1;

                                  return (
                                    <AiFillStar
                                      className={`${index <= review?.productivityRating ? "fill-[#FFC700]" :
                                        "fill-[#A1A1A1]"} w-5 h-5`}
                                      type="button"
                                      key={index}
                                    />

                                  );
                                })}
                              </div>
                              <div className=" flex gap-3">
                                {[...Array(5)].map((star, index) => {
                                  index += 1;

                                  return (
                                    <AiFillStar
                                      className={`${index <= review?.punctualityRating ? "fill-[#FFC700]" :
                                        "fill-[#A1A1A1]"} w-5 h-5`}
                                      type="button"
                                      key={index}
                                    />

                                  );
                                })}
                              </div>
                              <div className=" flex gap-3">
                                {[...Array(5)].map((star, index) => {
                                  index += 1;

                                  return (
                                    <AiFillStar
                                      className={`${index <= review?.behaviourRating ? "fill-[#FFC700]" :
                                        "fill-[#A1A1A1]"} w-5 h-5`}
                                      type="button"
                                      key={index}
                                    />

                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <div className="text-[#A1A1A1]">{review?.review}</div>
                          <div className="text-[#031B59]">{review?.month.toUpperCase()}</div>
                        </div>
                        <div className="flex items-center justify-between mb-1">
                          <div className="text-[#686868]">{convertDateFormat(review?.createdAt)}</div>
                          <button
                            onClick={() => handleClick(review)}
                            className="border border-[#E2E8F0] w-[42px] h-8 rounded-md flex items-center justify-center"
                          >
                            <BiEditAlt className="w-5 h-5 text-[#031B59]" />

                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-black relative z-10 flex justify-center items-center h-[10rem] left-[5px]">
                    {t("Noreviewsavailable")}
                  </div>
                )
              }

            </>
          )
          }
        </div>
      </div>
      {handlePopup && <Editpopup setHandlePopup={setHandlePopup} userData={userData} />}
    </div>
  );
}

export default ReviewModal;

ReviewModal.propTypes = {
  projectId: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
