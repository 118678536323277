import ProfileAvtar from "component/common/ProfileAvtar";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useOnClickOutside } from "component/common/useOneClickOutsid";

const AddResource = ({
  showDropdown,
  data,
  resourceData,
  onDropdownClick,
  onDelete,
  currentIndex,
}) => {
  const { allProjects } = useSelector((state) => state.projectReducer);
  const menuRef = useRef();
  useOnClickOutside(menuRef, () => onDropdownClick(data?.project?.id));
  const { t } = useTranslation();

  return (
    <div>
      {resourceData?.length === 0 ? (
        <span className="cursor-default">Please Add resource !</span>
      ) : (
        <div className="relative flex items-center justify-between gap-[4rem]">
          <div className="flex items-center relative w-[2rem]">
            {resourceData?.map(
              (obj, index) =>
                index < 4 && (
                  <div
                    key={index}
                    style={{
                      left: index !== 0 && `${index + 0.25}rem`,
                    }}
                    className="overflow-hidden w-[2rem] h-[2rem] rounded-full absolute"
                  >
                    {obj?.profilePictureUrl ? (
                      <img
                        className=" relative w-[2rem] h-[2rem] object-cover flex items-center
                        border-2 rounded-full border-white"
                        src={obj?.profilePictureUrl}
                        alt="Profile Avatar"
                      />
                    ) : (
                      <ProfileAvtar
                        name={obj?.employeeName}
                        height="2rem"
                        width="2rem"
                      />
                    )}
                  </div>
                )
            )}
          </div>
          <div className="w-[7rem] cursor-pointer">
            {resourceData?.filter((obj, index) => index >= 4).length > 0 ? (
              <div
                className="text-[#686868] text-[0.875rem]"
                onClick={() => onDropdownClick(data?.project?.id)}
              >
                {`+${
                  resourceData?.filter((obj, index) => index >= 4).length
                } ${t("more")}`}
              </div>
            ) : (
              <div className="flex items-center justify-center gap-[1rem]">
                <IoIosArrowDown
                  className="text-[#A1A1A1] w-[1.125rem] h-[1.125rem]"
                  onClick={() => onDropdownClick(data?.project?.id)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {showDropdown === data?.project?.id && (
        <div
          ref={menuRef}
          className={`h-fit bg-white absolute max-h-[17rem]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-1 border-[1px]
        mt-6
        rounded-md border-[#E2E8F0] overflow-y-auto z-10
        ${
        currentIndex == allProjects.length - 1 &&
          currentIndex === 9 &&
          "bottom-[4rem]"
        } 
        ${
        currentIndex == allProjects.length - 2 &&
          currentIndex === 8 &&
          "bottom-[4rem]"
        }
        ${
        currentIndex == allProjects.length - 3 &&
          currentIndex === 7 &&
          "bottom-[4rem]"
        }
        `}
        >
          {resourceData?.map((obj, index) => (
            <div
              key={index}
              className="flex hover:bg-[#F8FAFC] items-center justify-center gap-[0.75rem]
            py-[0.56rem] px-[0.94rem]"
            >
              {obj?.profilePictureUrl ? (
                <img
                  className="w-[3rem] h-[3rem] object-cover flex items-center rounded-full"
                  src={obj?.profilePictureUrl}
                  alt="Profile Avatar"
                />
              ) : (
                <ProfileAvtar
                  name={obj?.employeeName}
                  height="3rem"
                  width="3rem"
                />
              )}
              <div
                className="flex flex-col items-start justify-center
              gap-[0.31rem] w-[12rem]"
              >
                <p className="font-medium text-[#031B59] text-[0.875rem]">
                  {obj?.employeeName}
                </p>
                <p className="text-[#686868] text-[0.875rem]">{obj?.empCode}</p>
              </div>
              <MdDelete
                className="w-[1.5rem] h-[1.5rem] cursor-pointer"
                onClick={() => {
                  onDelete(data?.project?.id, obj?.userId, obj?.employeeName);
                }}
              />
            </div>
          ))}
        </div>

      )}
    </div>
  );
};

export default AddResource;

AddResource.propTypes = {
  showDropdown: PropTypes.string,
  data: PropTypes.object,
  resourceData: PropTypes.array,
  onDropdownClick: PropTypes.func,
  onDelete: PropTypes.func,
  currentIndex: PropTypes.number,
};
