import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { AssetInfo } from "redux/appThunk/Admin/dashboard";
import PropTypes from "prop-types";

const AssestInfo = ({ accordionOpen, eyeId }) => {
  const dispatch = useDispatch();
  const [openRowId, setOpenRowId] = useState(false);
  const AssetInfo1 = useSelector((state) => state.employeeReducer.assetsInfo);

  const handleRowClick = (id) => {
    setOpenRowId(openRowId === id ? null : id);
  };

  useEffect(() => {
    if (eyeId) {
      dispatch(AssetInfo(eyeId));
    }
  }, []);

  return (
    <div>
      {accordionOpen === 10 && (
        <div className="rounded-[4px] overflow-y-scroll custom_scroll">
          <table className="w-[100%] rounded-[4px]">
            <thead className="h-[50px] bg-[#f2f6ff] flex flex-row justify-start text-[15px] font-medium text-[#686868]">
              <tr className="flex justify-between w-full">
                <th className="w-[171px] h-[50px] py-[12px] px-[18px] rounded-tl-[4px]
                 flex justify-start font-medium text-[#686868]">
                  Asset id
                </th>
                <th className="w-[171px] h-[50px] py-[12px] px-[18px] flex justify-start font-medium text-[#686868]">
                  Asset Name
                </th>
                <th className="w-[171px] h-[50px] py-[12px] px-[18px] flex justify-start font-medium text-[#686868]">
                  Assigned by
                </th>
                <th className="w-[216px] h-[50px] py-[12px] px-[18px] flex justify-start font-medium text-[#686868]">
                  Assigned Date
                </th>
                <th className="w-[92px] h-[50px] flex items-center justify-center rounded-tr-[4px]"></th>
              </tr>
            </thead>
            <tbody>
              {AssetInfo1.map((asset, id) => (
                <>
                  <tr
                    key={id}
                    className="flex justify-between w-full border-[1px]"
                    onClick={() => handleRowClick(asset.id)}
                  >
                    <td className="w-[171px] h-[50px] py-[9px] px-[18px]">
                      {asset.id}
                    </td>
                    <td className="w-[171px] h-[50px] py-[9px] px-[18px]">
                      {asset.name}
                    </td>
                    <td className="w-[171px] h-[50px] py-[9px] px-[18px]">
                      {asset.assignBy}
                    </td>
                    <td className="w-[216px] h-[50px] py-[9px] px-[18px]">
                      {asset.assignedDate}
                    </td>
                    <td className="w-[92px] h-[50px] flex items-center justify-center">
                      <BsChevronDown
                        className={`h-3 w-4 stroke-1 ${
                          openRowId === asset.id ? "rotate-180" : "rotate-0"
                        }`}
                      />
                    </td>
                  </tr>
                  {openRowId === asset.id && (
                    <>
                      <div className="pt-[10px]">
                        <div className="h-[50px] bg-[#f2f6ff] justify-between gap-x-1
                         flex flex-row text-[14px] text-[#191919]">
                          <p className="w-[50%] flex justify-start items-center px-[15px]
                           text-[12px] font-medium text-[#686868]">
                            Asset Serial No.
                          </p>
                          <p className="w-[50%] flex justify-start items-center px-[15px] text-[12px]">
                            {asset.serialNo}
                          </p>
                        </div>
                      </div>
                      <div className="py-[2px]">
                        <div className="h-[50px] bg-[#f2f6ff] justify-between gap-x-1
                         flex flex-row text-[14px] text-[#191919]">
                          <p className="w-[50%] flex justify-start items-center px-[15px]
                           text-[12px] font-medium text-[#686868]">
                            Description
                          </p>
                          <p className="w-[50%] flex justify-start items-center px-[15px] text-[12px]">
                            {asset.description}
                          </p>
                        </div>
                      </div>
                      <div className="pb-[10px]">
                        <div className="h-[50px] bg-[#f2f6ff] justify-between gap-x-1
                         flex flex-row text-[14px] text-[#191919]">
                          <p className="w-[50%] flex justify-start items-center px-[15px]
                          text-[12px] font-medium text-[#686868]">
                            Images
                          </p>
                          <div className="w-[50%] h-[50px] flex gap-2">
                            <img
                              src={asset.gadgetImagesUrl}
                              className="w-[50px] h-[50px]"
                              alt="Asset"
                            />
                            <p className="w-[100%] text-[12px] flex justify-start items-center">
                              {asset.gadgetImages}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AssestInfo;

AssestInfo.propTypes = {
  eyeId: PropTypes.any,
  accordionOpen: PropTypes.any,
};
