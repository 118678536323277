import React, { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleAddLeave } from "redux/appThunk/Admin/leave";
import { addLeaves, editLeaves } from "redux/appThunk/Employee/leaves";
import { setSuccess } from "redux/actions/action";

function AddLeavePopup({
  AddEmployessDialoBox,
  checkData,
  employeedata,
  role,
  setSubmitOnce,
  type,
  paramId,
  EditData,
  submitOnce,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { success } = useSelector((state) => state.ClientsReducer);
  const navigate = useNavigate();
  const userData = localStorage.getItem("userLoginToken")
    ? JSON.parse(localStorage.getItem("userLoginToken"))
    : "";

  const submitData = () => {
    setSubmitOnce(true);

    if (role === "admin") {
      dispatch(handleAddLeave(employeedata, setSubmitOnce));
    } else {
      if (paramId) {
        dispatch(editLeaves(EditData, paramId, setSubmitOnce));
      } else {
        dispatch(addLeaves(employeedata, userData.id, setSubmitOnce));
      }

    }
  };

  useEffect(() => {
    if (success) {
      setSubmitOnce(false);
      AddEmployessDialoBox();
      dispatch(setSuccess(false));
      navigate(-1);
    }
  }, [success]);

  return (
    <div className="w-full h-full flex items-center justify-center fixed top-0 left-0 z-50 bg-[rgba(3,27,89,.3)]">
      <div
        className="min-w-[37.5rem] w-[37.5rem] z-10 h-fit flex flex-col items-center gap-[1.5rem] bg-white
        rounded-[18px] shadow-lg"
      >
        <form className="w-full flex flex-col h-full">
          <div
            className="w-full px-7 py-5 flex justify-between items-center text-[#031B59]
          font-extrabold text-xl"
          >
            {/* Modal Heading */}
            <p>{type}</p>
            <button className="w-fit h-fit" onClick={AddEmployessDialoBox}>
              <GrFormClose className="flex w-[2rem] h-[2rem]" />
            </button>
          </div>
          <div className="w-full flex flex-col gap-4">
            <hr />
            {/* Data from Api */}
            <div className="w-full px-7 flex flex-col item-center gap-4">
              <div className="w-full flex flex-col border gap-2 capitalize">
                {checkData &&
                  Object.keys(checkData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{checkData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <hr />
          </div>
          <div className="w-full px-7 py-5 flex justify-end items-center gap-8">
            <button
              onClick={AddEmployessDialoBox}
              className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem]
              rounded-[0.5rem]"
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className={`flex h-[3rem] p-[1rem_1.875rem] w-[6rem] justify-center items-center gap-[0.5rem]
              ${
    submitOnce ? "bg-gray-400" : "bg-[#031B59]"
    } rounded-[2.5rem] font-inter text-[1rem]
               font-bold leading-normal text-white`}
              onClick={submitData}
              disabled ={submitOnce}
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
AddLeavePopup.propTypes = {
  AddEmployessDialoBox: PropTypes.any,
  checkData: PropTypes.object,
  employeedata: PropTypes.object,
  role: PropTypes.string,
  setSubmitOnce: PropTypes.string,
  type: PropTypes.string,
  paramId: PropTypes.string,
  EditData: PropTypes.string,
  submitOnce: PropTypes.any,
};

export default AddLeavePopup;
