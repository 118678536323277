import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchProject,
  fetchProjectWithSearch
} from "redux/appThunk/Admin/project";

const useFetchProjectData = ({ permissionToFetch, query, statusFilter, currentPage }) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);
  useEffect(() => {
    const fetchProjectData = () => {
      if (permissionToFetch.viewAll) {
        if (searchQuery)
          dispatch(fetchProjectWithSearch(currentPage, searchQuery));
        else if (statusFilter) {
          dispatch(fetchProject(statusFilter, currentPage));
        } else {
          dispatch(fetchProject(currentPage));

        }
      }
    };
    fetchProjectData();
  }, [searchQuery, currentPage, statusFilter]);
};

export default useFetchProjectData;
