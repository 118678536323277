import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ChangePassword from "component/profile/ChangePassword";

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addchangePasswordPop , setAddChangePassword] = useState(false);

  return (
    <div
      className="w-[96%] h-[46.063rem] p-5 bg-white flex-flex-col
      space-y-8 rounded-[20px]"
    >
      {addchangePasswordPop && <ChangePassword setAddChangePassword={setAddChangePassword}/>}

      <div className="flex flex-col"></div>
      <div className="flex flex-col space-y-4 text-[#031B59]">
        <button type="button">
          <div
            className="h-[3.125rem] w-full p-5 group flex justify-between items-center rounded-xl
            shadow-[0_0px_6px_0px_rgba(3,27,89,0.1)] hover:text-white hover:bg-[#031B59]"
            onClick={() => setAddChangePassword(true)}
          >
            <h3 className="flex items-center w-full h-full p-2">
              {t("resetPassword")}
            </h3>
            <BsChevronRight className="h-6 w-6 fill-[#64748B] group-hover:fill-white" />
          </div>
        </button>
        <button type="button">
          <div
            className="h-[3.125rem] w-full p-5 group flex justify-between items-center rounded-xl
              shadow-[0_0px_6px_0px_rgba(3,27,89,0.1)] hover:text-white hover:bg-[#031B59]"
            onClick={() => navigate("/settings/manage")}
          >
            <h3 className="flex items-center w-full h-full p-2">
              {t("manage")}
            </h3>
            <BsChevronRight className="h-6 w-6 fill-[#64748B] group-hover:fill-white" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Settings;

