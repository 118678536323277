import React from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../../styles/App.css";
import { EditButton } from "./accessControlUi/Button";
import { typeObj } from "./enum";

function ViewEditPopup({
  popupBox,
  title,
  handleSubmit,
  submitBtnText = "save",
  type,
  isEdit,
  setIsEdit,
  children,
  submitOnce,
  currentResource,
  setIsDisabled,
  isDisabled,
}) {
  const { t } = useTranslation();

  return (
    <div
      className="w-full h-full flex items-center
    justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]"
    >
      <div
        className="min-w-[40%] z-10 h-full flex flex-col items-center gap-[1.5rem] bg-white
        shadow-lg transitionRight"
      >
        <form className="w-full flex flex-col h-full" onSubmit={handleSubmit}>
          <div
            className="w-full px-7 py-5 flex justify-between items-center text-[#031B59]
          font-extrabold text-xl"
          >
            <div className="flex items-center justify-center gap-[0.5rem]">
              <p className="text-nowrap">{title}</p>
              {type === typeObj.view && (
                <EditButton
                  currentResource={currentResource}
                  onClick={() => {
                    setIsEdit(true);
                    setIsDisabled(false);
                  }}
                />
              )}
            </div>
            <button className="w-fit h-fit" onClick={popupBox}>
              <GrFormClose className="flex w-[2rem] h-[2rem]" />
            </button>
          </div>
          <div className="w-full flex flex-col gap-4">
            <hr />
            <div className="w-full px-7 flex flex-col item-center gap-4">
              {children}
            </div>
          </div>
          {(type === typeObj.add || (!isDisabled && isEdit)) && (
            <div className="w-full h-full flex justify-end items-end">
              <div className="w-full  px-7 py-5 flex justify-end  gap-8 ">
                <button
                  onClick={popupBox}
                  className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem]
              rounded-[0.5rem]"
                >
                  {t("cancel")}
                </button>
                <button
                  className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
               bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem]
                font-bold leading-normal text-white`}
                  type="button"
                  onClick={handleSubmit}
                  disabled={submitOnce}
                >
                  {t(`${submitBtnText}`)}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

ViewEditPopup.propTypes = {
  popupBox: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.string,
  handleSubmit: PropTypes.any,
  submitBtnText: PropTypes.string,
  type: PropTypes.string,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  submitOnce: PropTypes.any,
  currentResource: PropTypes.any,
  setIsDisabled: PropTypes.any,
  isDisabled: PropTypes.any,
};

export default ViewEditPopup;
