import ShimmerName from 'component/common/ShimmerName';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightDoubleLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchPermissionsByRoleId } from 'redux/appThunk/Admin/permission';
import ViewRoleLeftPermission from './ViewRoleLeftPermission';
import ViewRoleRightPermission from './ViewRoleRightPermission';

const ViewRolePermission = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { AllPermissions } = useSelector((state) => state.permissionReducer);

  useEffect(() => {
    dispatch(fetchPermissionsByRoleId(id));
  }, [dispatch]);

  return (
    <div className='w-full h-full p-5'>
      <div className='flex flex-col gap-5'>
        <div className=' w-full flex items-center'>
          <Link to="/access">
            <h3 className="font-normal text-xl text-[#191919] flex items-center">
              {t("access")}
              <span className="text-[#A1A1A1] mx-2">
                <RiArrowRightDoubleLine />
              </span>
            </h3>
          </Link>
          <div className='h-full flex items-center'>
            <h3 className=" w-full text-xl text-[#031B59] font-extrabold capitalize">
              {AllPermissions?.name ? (
                <>
                  {AllPermissions?.name === '' ? (<div className="w-full capitalize">
                    {t("noRole")}
                  </div>) : AllPermissions?.name}
                </>
              ) : (
                <ShimmerName
                  height="2rem"
                  width="6rem"
                />
              )}
            </h3>
          </div>
        </div>
        <div>
          <label className='text-[#686868]'>{t('description')}</label>
          <h1 className='text-[1rem] capitalize w-full'>{AllPermissions?.description ? AllPermissions?.description : (
            <>
              {AllPermissions?.description === '' ? (
                <div className="w-full capitalize">
                  {t("noDescFound")}
                </div>
              ) : <ShimmerName
                height="1.5rem"
                width="6rem"
              />}
            </>
          )}</h1>
        </div>
        <div className='w-full grid xl:grid-cols-2 gap-5'>
          {AllPermissions?.requiredResponse?.length > 0 ? <ViewRoleLeftPermission
            newAllPermission={AllPermissions}
          /> : <ShimmerName height='80vh' width='50vh' />}
          {AllPermissions?.requiredResponse?.length > 0 ? <ViewRoleRightPermission
            AllPermissions={AllPermissions}
          /> : <ShimmerName height='80vh' width='50vh' />}
        </div>
      </div>
    </div >
  );
};

export default ViewRolePermission;
