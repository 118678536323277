import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

export default function SidebarButton({
  to,
  isActive,
  icon: Icon,
  text,
  newChildren,
}) {
  // can be used in future if multple tabs are required
  // const location = useLocation();
  // const jobPath = location.pathname.includes('/jobOpening');
  const [open, setOpen] = useState(false);
  const handleButtonClick = () => {
    setOpen(!open);
  };

  if (newChildren) {
    return (
      <div
        className={`flex flex-col ${
          isActive ? "bg-white/10 before:rounded-tr-md" : ""
        }`}
      >
        <button
          title={text}
          className={`w-full h-[3rem] hover:bg-white/10 hover:scale-105 
          ${isActive ? "bg-white/10 before:rounded-tr-md" : ""}`}
          onClick={handleButtonClick}
        >
          <div className={`flex h-14 text-base items-center`}>
            <div className="bg-black "></div>
            <div
              className={`basis-3/12 h-full w-full flex justify-end scale-125 items-center`}
            >
              {Icon && <Icon className="h-5 w-5" />}
            </div>
            <div className="flex items-center w-[70%]">
              <p className="w-full pr-4">{text}</p>
              {open ? <BsChevronUp /> : <BsChevronDown />}
            </div>
          </div>
        </button>
        <div>
          {/* {(open || jobPath) ? <SideBarJobOpening data={newChildren} /> : null} */}
        </div>
      </div>
    );
  }

  return (
    <NavLink
      to={to}
      className={`flex ${isActive ? "bg-white/10 before:rounded-tr-md" : ""}`}
    >
      <div
        title={text}
        className={`w-full h-[3rem] hover:bg-white/10 hover:scale-105 cursor-pointer`}
      >
        <div className={`flex h-14 text-base items-center`}>
          <div
            className={`basis-3/12 h-full w-full flex justify-end scale-125 items-center`}
          >
            {Icon && <Icon className="h-25 w-25" />}
          </div>
          <div className="flex justify-between items-center w-[70%]">
            <p
              className={`flex basis-9/12 w-full h-full items-center pl-4 whitespace-nowrap text-sm`}
            >
              {text}
            </p>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

SidebarButton.propTypes = {
  to: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
  text: PropTypes.string,
  newChildren: PropTypes.any,
};
