import React, { } from 'react';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';

const PayslipModal = ({ isOpen, onClose, selectedItem }) => {
  const setsalary = useSelector((state) => state.dashboardReducers.setsalary);
  const totalEarnings = setsalary?.basicSalary?.map((data) => Number(data.value));
  const totalDeductions = setsalary?.providientfund?.map((data) => Number(data.value));
  const otherValue = setsalary?.incentives?.map((data) => Number(data.value));

  const getTotalDaysInMonth = (monthName) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ];

    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex === -1) return 0;

    const now = new Date();
    const year = now.getFullYear();

    const isLeapYear = (year) => {
      return ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0));
    };

    if (monthName === "February") {
      return isLeapYear(year) ? 29 : 28;
    } else {
      const nextMonth = new Date(year, monthIndex + 1, 0);

      return nextMonth.getDate();
    }
  };

  const totalDays = getTotalDaysInMonth(selectedItem);

  function totalEarning(totalEarnings , otherValue) {
    const data1 = totalEarnings?.reduce((total , num) => total + num,0);
    const data2 = otherValue?.reduce((total, num) => total + num,0 );

    return data1 + data2;

  }

  function totalDeduction(totalDeductions) {
    const data1 = totalDeductions?.reduce((total , num) => total + num,0);

    return data1;

  }

  function totalNetSalary(totalEarning, totalDeduction) {
    const data1 = totalEarning - totalDeduction;

    return data1;
  }

  return (
    <>
      {isOpen && (
        <div className="w-full h-full flex items-center justify-end
        fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
          <div className="max-w-[40%] h-full p-5 bg-white flex flex-col
           space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto">
            <div>
              <IoClose onClick={onClose} className="ml-auto fill-[#686868] w-6 h-6 cursor-pointer" />
            </div>
            <div className="border bg-white w-full custom_scroll overflow-x-hidden rounded-lg pb-4">
              <div className="w-full bg-[#F2F6FF] flex items-start justify-between px-4 py-5 rounded-lg">
                <div className="pt-7">
                  <h2 className="font-Roboto text-xl text-[#031B59] font-extrabold">
                                         Payslip {selectedItem} 2024
                  </h2>
                </div>
                <div className='flex flex-col items-end'>
                  <img className="w-40 h-auto"
                    src="https://deeporion.s3.ap-south-1.amazonaws.com/images/deeporion_logo.jpg"
                    alt="Company_logo" />
                  <p className="text-xs text-gray-500 text-right">
                                         8/266, behind UDB Corporate Tower,<br />
                                         Malviya Nagar, Jaipur, Rajasthan 302017
                  </p>
                </div>
              </div>

              <h3 className="font-semibold text-lg text-[#686868] p-3">Employee Info</h3>
              <div className="grid grid-cols-2 gap-4 p-4">
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Employee Name:</strong></p>
                  <p className="text-[#031B59]">{ setsalary.fullName || "No record"}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Employee ID:</strong></p>
                  <p className="text-[#031B59]">{setsalary.empolyecode || "No record"}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Designation:</strong></p>
                  <p className="text-[#031B59]">{setsalary.designation || "No record"}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Date of Joining:</strong></p>
                  <p className="text-[#031B59]">{setsalary.dateOfJoining || "No record"}</p>
                </div>
              </div>

              <div className="border-t-2 border-b-2">
                <h3 className="font-semibold text-lg text-[#686868] p-3">Bank Info</h3>
                <div className="grid grid-cols-2 gap-4 p-4">
                  <div className="flex flex-col">
                    <p className="text-[#686868]"><strong>Bank Name:</strong></p>
                    <p className="text-[#031B59]">{setsalary.bankname || "No record"}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#686868]"><strong>IFSC Code:</strong></p>
                    <p className="text-[#031B59]">{setsalary.ifsccode || "No record"}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#686868]"><strong>Account Number:</strong></p>
                    <p className="text-[#031B59]">{setsalary.accountnumber || "No record"}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#686868]"><strong>PAN:</strong></p>
                    <p className="text-[#031B59]">{ "No record"}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#686868]"><strong>UAN:</strong></p>
                    <p className="text-[#031B59]">{setsalary.uanno || "No record"}</p>
                  </div>
                </div>
              </div>

              <h3 className="font-semibold text-lg text-[#686868] p-3">Salary Details</h3>
              <div className="grid grid-cols-2 gap-4 p-4">
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Actual Payable Days:</strong></p>
                  <p className="text-[#031B59]">{totalDays}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Total Working Days:</strong></p>
                  <p className="text-[#031B59]">{setsalary.totalworkingdays || "No record"}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>LOP Days:</strong></p>
                  <p className="text-[#031B59]">{setsalary.lossofpaydays || "No record"}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#686868]"><strong>Days Payable:</strong></p>
                  <p className="text-[#031B59]">{setsalary.effectiveworkingdays || "No record"}</p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 p-4">
                <div className="border p-4 flex flex-col justify-between h-full rounded-lg">
                  <h4 className="font-semibold text-[#031B59] mb-2">Earnings (A)</h4>
                  <div className="flex flex-col space-y-2">
                    {setsalary.basicSalary?.length > 0 &&
                       setsalary?.basicSalary?.map((value) => (
                         <div className="flex justify-between" key={value.name}>
                           <p className="text-[#686868]">{value.name}</p>
                           <p className="text-[#031B59]">{value.value}</p>
                         </div>
                       ))
                    }
                    {setsalary.incentives?.length > 0 &&
                       setsalary?.incentives?.map((value) => (
                         <div className="flex justify-between" key={value.name}>
                           <p className="text-[#686868]">{value.name}</p>
                           <p className="text-[#031B59]">{value.value === undefined ? "0" : value.value}</p>
                         </div>
                       ))
                    }

                  </div>
                  <div className="w-full bg-[#F2F6FF] flex items-center justify-between p-4 rounded-lg">
                    <p className="text-[#031B59] font-semibold">Total Earnings:</p>
                    <p className="text-[#031B59] font-semibold">
                         ₹{totalEarning(totalEarnings ,otherValue)}</p>
                  </div>
                </div>

                <div className="border p-4 flex flex-col justify-between h-full rounded-lg">
                  <h4 className="font-semibold text-[#031B59] mb-2">Deductions (B)</h4>
                  <div className="flex flex-col space-y-2">
                    {setsalary.providientfund?.length > 0 &&
                         setsalary?.providientfund?.map((value) => (
                           <div className="flex justify-between" key={value.name}>
                             <p className="text-[#686868]">{value.name}</p>
                             <p className="text-[#031B59]">{value.value === undefined ? "0" : value.value}</p>
                           </div>
                         ))
                    }
                  </div>
                  <div className="w-full bg-[#F2F6FF] flex items-center
                   justify-between p-4 rounded-lg mt-20">
                    <p className="text-[#031B59] font-semibold">
                         Total Deductions:</p>
                    <p className="text-[#031B59] font-semibold">
                         ₹{totalDeduction(totalDeductions)}</p>
                  </div>
                </div>
              </div>
              <div className="w-full bg-[#F2F6FF] flex items-center justify-between p-4 rounded-lg">
                <p className="text-[#031B59] font-semibold">
                                     Net Salary Payable (A - B)
                </p>
                <div className="text-right">
                  <p className="text-[#031B59] font-semibold">
                   ₹{totalNetSalary(totalEarning(totalEarnings ,otherValue), totalDeduction(totalDeductions))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  );
};

export default PayslipModal;

PayslipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedItem: PropTypes.string.isRequired,
};
