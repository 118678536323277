import React from 'react';
import MailList from './MailList';
import MailView from './MailView';

export default function Mail() {
  return (
    <div className='w-[96%] flex space-x-5'>
      <MailList/>
      <MailView/>
    </div>
  );
}
