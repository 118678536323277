/* eslint-disable react/prop-types */
import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useFormik } from "formik";
import { feedSchema } from "redux/validator/admin/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { createFeed, createFeedput } from "redux/appThunk/Admin/dashboard";

const DashboardLatestFeedPopUp = ({ setClose, title, id }) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("userLoginToken"));
  const { feedlatest } = useSelector((state) => state.dashboardReducers);

  const latest = feedlatest.find((data) => data.user_Id == id);
  const initialvalues = {
    title: title === "New Feed" ? "" : latest?.title || "",
    description: title === "New Feed" ? "" : latest?.description || "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: feedSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        title: values?.title,
        description: values?.description,
        user_id: userId?.id,
        created_at: values?.created_time,
        creatorname: values?.creator_name,
      };
      setClose();
      if (title === "New Feed") {
        dispatch(createFeed(jsonObj));
      } else {
        dispatch(createFeedput(id, jsonObj));
      }
    },
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    Formik;

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]  "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="flex justify-between px-[0.88rem] py-[0.62rem]  border-b border-b-[#E2E8F0] ">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">{title}</p>
          </div>
          <div>
            <RxCross2
              onClick={setClose}
              className="text-[#191919] text-xl cursor-pointer"
            />
          </div>
        </div>
        <div className="border border-[#E2E8F0] rounded-lg">
          <div className="border border-[#E2E8F0] flex flex-col">
            <p className="py-5 pl-[0.92rem] text-sm font-medium border-b border-b-[#E2E8F0]] text-[#031B59]">
              Announcement
            </p>
          </div>
          <div className="p-[0.92rem]">
            <div className="flex flex-col bla gap-[0.31rem]">
              <input
                type="text"
                placeholder="Title"
                name="title"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                className="p-[0.62rem] border-b border-b-[#E2E8F0] text-[#000000] "
              />
              {errors.title && touched.title ? (
                <p className="text-[red]">{errors.title}</p>
              ) : null}
              <textarea
                type="text"
                placeholder="Description"
                name="description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                className="p-[0.62rem] text-[#000000]"
              />
              {errors.description && touched.description ? (
                <p className="text-[red]">{errors.description}</p>
              ) : null}
            </div>
          </div>

          <div className="flex justify-end gap-4 p-[0.92rem] border-t border-t-[#E2E8F0]">
            <button
              className="border border-[#E2E8F0] px-6 py-3 rounded-3xl text-[#031B59] hover:bg-[#031B59]
            hover:text-white "
              onClick={setClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="border border-[#E2E8F0] px-6 py-3 rounded-3xl text-[#031B59] hover:bg-[#031B59]
            hover:text-white "
              onClick={handleSubmit}
            >
              {title === "Edit Feed" ? "Update" : "Post"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLatestFeedPopUp;
