import Card from "component/common/Card";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAttendance } from "redux/appThunk/Admin/dashboard";
import PropTypes from "prop-types";
import LoaderComp from "component/loader/LoaderComp";
import { useTranslation } from "react-i18next";

const Frame = () => {
  const dispatch = useDispatch();
  const [val, setVal] = useState("team");
  const selector = useSelector((state) => state.dashboardReducers.allAttendent);
  const myTeamCount = useSelector((select) => select.MyTeamsReducer.barCount);
  const { isLoading } = useSelector((state) => state.statusReducer);
  const teamVar = "team";
  const allDataVar = "all";
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(dashboardAttendance(val));
  }, [val]);

  useEffect(() => {
    if (myTeamCount === 1) {
      setVal(teamVar);
    } else {
      setVal(allDataVar);
    }
  }, [myTeamCount]);

  return (
    <>
      {isLoading ? (
        <div className="items-center ">
          <LoaderComp />
        </div>
      ) : (
        <Card cardClass="w-[57.5625rem] p-5 flex flex-col gap-5 rounded-[1.25rem] bg-white shadow">
          <div className="w-full flex justify-start items-center gap-2.5">
            <div className="flex-grow p-[0.78125rem] bg-white border-r
             border-slate-200 rounded-xl flex flex-col
              justify-between hover:bg-[#F2F6FF]">
              <div className="w-[2.375rem] h-[1.875rem] text-2xl font-black">
                {selector.alluser}
              </div>
              <div className="text-zinc-900 text-[0.875rem] font-normal leading-tight">
                {t("total")}
              </div>
            </div>
            <div className="flex-grow p-[0.78125rem]
             bg-white border-r border-slate-200 flex flex-col
             justify-between hover:bg-[#F2F6FF] rounded-xl">
              <div className="w-[2.375rem] h-[1.875rem] text-green-700 text-2xl font-black">
                {selector.activeruser}
              </div>
              <div className="text-zinc-900 text-[0.875rem] font-normal leading-tight">
                {t("active")}
              </div>
            </div>
            <div className="flex-grow p-[0.78125rem]
             bg-white border-r border-slate-200 flex flex-col
             justify-between hover:bg-[#F2F6FF] rounded-xl">
              <div className="text-red-600 text-2xl font-black">
                {selector.onleave}
              </div>
              <div className="flex-grow text-zinc-900 text-[0.875rem] font-normal leading-tight">
                {t("onLeave")}
              </div>
            </div>
            <div className="flex-grow p-[0.78125rem] bg-white border-r
             border-slate-200 flex flex-col justify-between
              hover:bg-[#F2F6FF] rounded-xl">
              <div className="text-orange-500 text-2xl font-black">
                {selector.halfday}
              </div>
              <div className="text-zinc-900 text-[0.875rem] font-normal leading-tight">
                {t("onHalfDay")}
              </div>
            </div>
            <div className="flex-grow pl-[0.78125rem] pr-[0.3125rem]
             py-[0.78125rem] bg-white flex flex-col justify-between
              hover:bg-[#F2F6FF] rounded-xl">
              <div className="text-zinc-900 text-2xl font-black">
                {selector.wfh}
              </div>
              <div className="w-[7.25rem] text-zinc-900 text-[0.875rem]
               font-normal leading-tight">
                {t("workFromHome")}
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

Frame.propTypes = {
  selectorData: PropTypes.shape({
    alluser: PropTypes.number.isRequired,
    activeruser: PropTypes.number.isRequired,
    onleave: PropTypes.number.isRequired,
    halfday: PropTypes.number.isRequired,
    wfh: PropTypes.number.isRequired,
  }).isRequired,
};

export default Frame;
