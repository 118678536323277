/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import * as Yup from "yup";

export const addEmployeeSchema = Yup.object({
  fullName: Yup.string()
    .required("Please enter your name"),
  dob: Yup.string()
    .required("Invalid date format"),
  contactNo: Yup.number()
    .required("Number is required")
    .test(
      "len",
      "Number must be exactly 10 digits",
      val => val && val.toString().length === 10
    ),
});

