import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiCloudUpload } from "react-icons/bi";
import { setFiles, storeFileData } from "redux/actions/action";
import { AiOutlineClose } from "react-icons/ai";
import { FaFileExcel } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import swalService from "utils/SwalServices";
import { handleFileDownload } from "redux/appThunk/Admin/employee";
import { useTranslation } from "react-i18next";
import ToastServices from "ToastServices";
import PropTypes from "prop-types";

const EmployeeCsvImport = ({onNextClick}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const file = useSelector((state) => state.file.file);

  const inputRef = useRef();

  const [hasFiles, setHasFiles] = useState(!!file);
  const [parsedData, setParsedData] = useState([]); // Store combined data
  const isCSVOrExcelFile = (file) => {
    return (
      file.type === "text/csv" ||
      file.name.toLowerCase().endsWith(".csv") ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.name.toLowerCase().endsWith(".xlsx")
    );
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const validFiles = Array.from(event.dataTransfer.files).filter(
      isCSVOrExcelFile
    );

    if (validFiles.length > 0) {
      processFiles(validFiles);
    } else {
      ToastServices.showToast({
        message: "Invalid file type. Please upload CSV or Excel files only.",
        type: "warning",
        autoClose: 3000,
      });
    }
  };

  const processFiles = (files) => {
    const parsedDataPromises = files.map((file) => {
      return new Promise((resolve) => {
        Papa.parse(file, {
          complete: (result) => {
            const fileData = result.data;

            if (fileData && fileData.length > 0) {
              const headers = Object.keys(fileData[0]);

              const parsed = fileData
                .filter(row => {
                  // Filter out rows where all values are empty
                  return Object.values(row).some(value => value !== null && value !== "");
                })
                .map((row, index) => {
                  const id = `${file.name}_${index}`;

                  return {
                    id,
                    ...headers.reduce((acc, header) => {
                      acc[header] = row[header];

                      return acc;
                    }, {}),
                  };
                });
              resolve(parsed);
            } else {
              resolve([]);
            }
          },
          header: true,
        });
      });
    });

    Promise.all(parsedDataPromises).then((allParsedData) => {
      const mergedData = allParsedData.flat();
      setParsedData((prevData) => [...prevData, ...mergedData]);

    });
  };

  useEffect(() => {
    if(parsedData.length > 0) {
      dispatch(storeFileData(parsedData));
    }
  },[parsedData]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const validFiles = newFiles.filter(isCSVOrExcelFile);

    if (validFiles.length > 0) {
      // Append new files to the existing files in the state
      const updatedFiles = [...(file || []), ...validFiles];
      dispatch(setFiles(updatedFiles));
      processFiles(validFiles);
    } else {
      ToastServices.showToast({
        message: "This is not a valid csv or excel file!",
        type: "warning",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if(file?.length > 0) {
      setHasFiles(!!file);
    }

  }, [file]);

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = file.filter((f) => f !== fileToRemove);
    dispatch(setFiles(updatedFiles));
    setHasFiles(updatedFiles.length > 0);
    inputRef.current.value = "";

  };

  const handleBulkFile = async () => {
    if (hasFiles) {
      try {
        dispatch(storeFileData(parsedData));
        onNextClick();
      } catch (error) {
        ToastServices.showToast({
          message: "Error while uploading files!",
          type: "error",
          autoClose: 3000,
        });
      }
    } else {
      swalService.showWarning({
        icon: "error",
        title: "Alert!",
        text: "You haven't selected any files. Please select files first!",
        confirmButtonText: "Ok",
      });
    }
  };

  const downloadSampleFile = () => {
    try {
      dispatch(handleFileDownload());
    } catch (error) {
      ToastServices.showToast({
        message: "Error downloading sample file !",
        type: "error",
        autoClose: 3000,
      });
    }
  };

  const handleClick = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };

  return (
    <>
      <div>
        <div className="text-[#686868]">
          {t("importing_csv")}
          <span
            className="text-[#031B59] font-bold cursor-pointer underline"
            onClick={downloadSampleFile}
          >
            {t("sample_file")}
          </span>
        </div>
        <div
          className="flex items-center justify-center h-[144px] border-[2px]
          border-dashed border-[#E2E8F0] p-[20px] mt-[10px]"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="h-[56px] w-[350px] flex flex-col justify-center items-center ">
            <button onClick={handleClick} className="h-[40px] w-[40px]">
              <BiCloudUpload className="h-[30px] w-[30px] mt-[5px] ml-[5px] text-[#A1A1A1]" />
            </button>
            <h1 className="text-base font-normal text-[#191919] cursor-pointer">
              {t("Drag_drop_files")}
              <span
                className="text-[#031B59] font-bold cursor-pointer"
                onClick={handleClick}
              >
                &nbsp; {t("browse_files")}
              </span>
            </h1>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept=".csv, .xlsx"
              hidden
              ref={inputRef}
            />
          </div>
        </div>

        {file && file.length > 0 && (
          <div className="mt-[15px]">
            {file.map((f, index) => (
              <div
                key={index}
                className="flex items-center justify-between h-[68px] border-[1px] rounded-[8px] p-[20px] mt-[10px] stroke-[#E2E8F0]"
              >
                <span className="flex items-center justify-between p-[14px_14px_14px_14px]">
                  <FaFileExcel className="w-[24px] h-[24px] mr-[10px] text-[#1A8718]" />
                  {f.name}
                </span>
                <button
                  onClick={() => handleRemoveFile(f)}
                  className="w-[20px] h-[20px] flex items-center justify-center"
                >
                  <AiOutlineClose className="text-[#FF0000] w-[15px] h-[15px]" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        data-testid="cancelBtn-1"
        className="fixed bottom-8 right-10 flex justify-end space-x-4 "
      >
        <button
          className="w-[107px] h-[49px] text-[#686868]
        p-[12px_24px_12px_24px] text-xl "
          onClick={() => navigate("/employees")}
        >
          {t("cancel")}
        </button>
        <button
          data-testid="nextBtn-1"
          className={`text-[${
            hasFiles ? "white" : "#B0B0B0"
          }] w-[107px] h-[49px] p-[15px_36px_15px_36px]
          rounded-[40px] border-[1px] bg-[${hasFiles ? "#031B59" : "#B0B0B0"}]
          flex justify-center items-center`}
          // disabled={!hasFiles}
          onClick={() => {
            handleBulkFile();
            onNextClick();
          }}
        >
          {t("next")}
        </button>
      </div>
    </>
  );
};

EmployeeCsvImport.propTypes = {
  onNextClick: PropTypes.any,
};

export default EmployeeCsvImport;
