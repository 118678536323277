import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReviewModal from "./ReviewModal";
import ScheduleModal from "./ScheduleModal";
import AddProjectResources from "./AddProjectResources";
import { useDispatch, useSelector } from "react-redux";
import { fetchResourceData } from "redux/appThunk/Admin/client";
import { FaArrowLeft } from "react-icons/fa";
import PropTypes from "prop-types";
import LoaderComp from "component/loader/LoaderComp";

const Resources = ({ setOpenResource, setRenderListing, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [isResourcesFormOpen, setIsResourcesFormOpen] = useState(false);
  const [get, setGet] = useState(false);
  const [currentResources, setCurrentResources] = useState([]);
  const [userId, setUserId] = useState();
  const [resourceId, setResourceId] = useState();
  const [reload, setReload] = useState(false);

  const { projectResources } = useSelector((state) => state.ClientsReducer);

  useEffect(() => {
    dispatch(fetchResourceData(projectId, setReload));
  }, [projectId, get]);

  useEffect(() => {
    setCurrentResources(projectResources);
  }, [projectResources]);

  const openScheduleModal = (resource) => {
    setSelectedResource(resource);
    setIsScheduleModalOpen(true);
  };

  const openReviewModal = (resource) => {
    setSelectedResource(resource);
    setIsReviewModalOpen(true);
  };

  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
    setSelectedResource(null);
  };

  const handleSchedulePage = () => {
    setIsScheduleModalOpen(false);
  };

  const handleSchedule = (resource) => {
    handleScheduleClick(resource);
    setUserId(resource.userId);
    setResourceId(resource.id);
  };

  const renderResource = (resource) => (
    <div key={resource?.project_id} className="p-4  bg-white shadow rounded-md">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h4 className="font-extrabold text-xl text-[#031B59]">
            {resource?.employeeName || "User"}
          </h4>
          <p className="text-sm text-gray-500">
            {resource?.designation?.designation || "Software Engineer"}
          </p>
        </div>
        <div className="text-right">
          <p className="text-sm text-gray-500">
            {
              `
            ${new Date('2024-08-09').toLocaleDateString('en-GB', { day: '2-digit', month: 'long' })},
            ${' '}${new Date('2024-08-09').toLocaleDateString('en-GB', { year: 'numeric' })}
            `
            }
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm text-gray-500">{`₹ ${resource?.rate || "00"}`}</p>
        <p className="text-sm text-orange-500 capitalize">{resource?.type || ""}</p>
      </div>
      <div className="flex space-x-2">
        <button
          className="flex-1 py-2 px-4 bg-[#E2E8F0] text-[#031B59] rounded-lg flex
          items-center justify-center space-x-2"
          onClick={() => handleSchedule(resource)}
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M6 2a1 1 0 011 1v1h6V3a1 1 0 112 0v1h1a2 2 0
            012 2v11a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 0 12-2-2h1V3a1
             1 0 011-1zM5 6v11h10V6H5zm7 4a1 1 0 10-2 0 1 1 0 002 0z"
            />
          </svg>
          <span>{t("Schedule")}</span>
        </button>
        <button
          className="flex-1 py-2 px-4 bg-[#E2E8F0] text-[#031B59] rounded-lg flex
          items-center justify-center space-x-2 whitespace-nowrap"
          onClick={() => handleReviewClick(resource)}
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M4 2a2 2 0 00-2 2v12a2 2 0 002 2h5.586a2 2 0
              001.414-.586l5.414-5.414a2 2 0 00.586-1.414V4a2 2 0
              00-2-2H4zm0 2h12v6h-4a2 2 0 00-2 2v4H4V4zm6 8v4l4-4h-4z"
            />
          </svg>
          <span>{t("Write a review")}</span>
        </button>
      </div>
    </div>
  );

  const handleScheduleClick = (resource) => {
    openScheduleModal(resource);
  };

  const handleReviewClick = (resource) => {
    openReviewModal(resource);
  };

  return (
    <div className="w-full p-4 ">
      <div className="sticky top-0 bg-white z-10 w-full flex justify-between items-center mb-4 p-4 ">
        <div className="flex justify-center items-center">
          <span
            className="text-[#031B59] mr-4 cursor-pointer"
            onClick={() => {
              setOpenResource(false);
              setRenderListing(true);
            }}
          >
            <FaArrowLeft />
          </span>
          <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
            {t("Resources")}
          </h3>
        </div>
        <button
          className="py-2 px-4 bg-[#E2E8F0] text-[#031B59] rounded-lg"
          onClick={() => setIsResourcesFormOpen(true)}
        >
          {t("Add New Resource")}
        </button>
      </div>
      {reload ? (
        <div className="flex justify-center ">
          <LoaderComp />
        </div>
      ) : (
        <div className="flex flex-wrap gap-5 w-full custom_scroll overflow-y-auto max-h-[70vh] ">
          {currentResources?.length > 0 ? (
            currentResources?.map(renderResource)
          ) : (
            <div
              className="text-black relative z-10 flex justify-center items-center h-[20rem] col-span-full
            left-[40%]"
            >
              {t("No resources found")}
            </div>
          )}
        </div>
      )}

      {isResourcesFormOpen && (
        <AddProjectResources
          setShowResourcesForm={setIsResourcesFormOpen}
          projectId={projectId}
          setGet={setGet}
        />
      )}
      {isScheduleModalOpen && selectedResource && (
        <ScheduleModal
          handleSchedulePage={handleSchedulePage}
          projectId={projectId}
          projectResourceData={selectedResource}
          setRenderSchedulePage={setIsScheduleModalOpen}
          userId={userId}
          resourceId={resourceId}
        />
      )}

      {isReviewModalOpen && selectedResource && (
        <ReviewModal
          handleReview={handleReviewClick}
          projectId={projectId}
          resourceId={selectedResource}
          onClose={closeReviewModal}
        />
      )}
    </div>
  );
};

export default Resources;

Resources.propTypes = {
  setOpenResource: PropTypes.func.isRequired,
  setRenderListing: PropTypes.func.isRequired,
  projectId: PropTypes.number.projectId,
};
