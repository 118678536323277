/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { TbDownload } from "react-icons/tb";

const DashboardEventDetailPopup = ({
  title,
  setClose,
  selectorData1,
  eventId,
}) => {
  const { t } = useTranslation();
  const formatTime = (timeStrings) => {
    const formattedTimes = timeStrings?.map((timeString) => {
      const time = new Date(timeString).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      return time.replace(/^0/, "");
    });

    return formattedTimes.join(" to ");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleDateString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };
  const event = selectorData1.find((item) => item.id === eventId);
  const url = event?.documents?.find((item) => item.url);
  const documentName = event?.documents?.find((item) => item.name);

  if (!event) {
    return null;
  }

  const handleOpen = () => {
    window.open(url?.url, "_blank");
  };

  return (
    <div
      className="w-full h-full flex items-center capitalize
    justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] text-black  "
    >
      <div
        className="min-w-[25%] h-full p-5 bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="flex justify-between px-[0.88rem] py-[0.62rem]  border-b border-b-[#E2E8F0] ">
          <div>
            <p className="text-[#031B59] text-lg font-extrabold">{title}</p>
          </div>
          <div>
            <RxCross2 onClick={setClose} className="text-[#191919] text-xl" />
          </div>
        </div>

        <div className="flex flex-col gap-[0.62rem]">
          <div className="flex flex-col gap-[0.72rem]">
            <h3 className="text-sm text-[#686868]">{t("title")}</h3>
            <p className="text-base">{event.fullName ? event.fullName : "-"}</p>
          </div>

          <div className="flex flex-col gap-[0.72rem]">
            <h3 className="text-sm text-[#686868]">{t("date")}</h3>
            <p className="text-base">{formatDate(event.date)}</p>
          </div>

          <div className="flex flex-col gap-[0.72rem]">
            <h3 className="text-sm text-[#686868]">{t("time")}</h3>
            <p className="text-base">
              {" "}
              {formatTime([event.startTime, event.endTime])}
            </p>
          </div>

          <div className="flex flex-col gap-[0.72rem]">
            <h3 className="text-sm text-[#686868]">{t("desc")}</h3>
            <p className="text-base w-[32.125rem]">{event.desc ? event.desc : "-"}</p>
          </div>

          <div className="flex  flex-col gap-[0.72rem]">
            <h3 className="text-sm text-[#686868]">{t("guest")}</h3>
            <div className="flex gap-2">
              {event.guest.map((guest, index) => (
                <span key={index} className="flex gap-[0.3rem] items-center">
                  <div className="flex-shrink-0 h-7 w-7 rounded-full bg-gray-200 flex items-center justify-center text-gray-600 text-sm font-medium">
                    {guest.substring(0, 1).toUpperCase()}
                  </div>
                  <h5 className="flex gap-[2rem] text-sm font-medium text-[#031B59]">
                    {guest ? guest : "-"}
                  </h5>
                </span>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-[0.72rem]" onClick={handleOpen}>
            <h3 className="text-sm text-[#686868]">
              {t("uploaded_documents")}
            </h3>
            <div className="flex justify-evenly gap-[0.31rem] border border-[#E2E8F0] w-48 px-[0.62rem] py-[0.31rem] rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM7.91667
                   9.58333C7.91667 10.25 7.33333
                  10.8333 6.66667 10.8333H5.83333V12.5H4.58333V7.5H6.66667C7.33333 7.5 7.91667 8.08333
                  7.91667 8.75V9.58333ZM12.0833 11.25C12.0833 11.9167 11.5 12.5 10.8333 12.5H8.75V7.5H10.8333C11.5 7.5 12.0833
                  8.08333 12.0833 8.75V11.25ZM15.4167 8.75H14.1667V9.58333H15.4167V10.8333H14.1667V12.5H12.9167V7.5H15.4167V8.75ZM10 8.75H10.8333V11.25H10V8.75ZM5.83333 8.75H6.66667V9.58333H5.83333V8.75Z"
                  fill="#FF0000"
                />
              </svg>
              <h6 className="text-[#A1A1A1] text-sm w-[5.93rem] text-ellipsis overflow-hidden whitespace-nowrap ">
                <button >{documentName?.name ? documentName?.name : "--"}</button>
              </h6>

              <TbDownload className="text-[#A1A1A1]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardEventDetailPopup;
