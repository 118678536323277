import React from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import { useNavigate } from "react-router-dom";
import { isShowDialogBoxChange } from "redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import ToastServices from "ToastServices";

function InvoicePopUp({
  AddStatusDialoBox,
  checkData,
  submitData,
  setSubmitOnce,
  submitOnce,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Submit = async () => {
    setSubmitOnce(true);
    const path = apiUrl.invoice;
    const { response, status } = await Helper.post(submitData, path, true);
    if (status === 201) {
      ToastServices.showToast({
        message: "Added Successfully!",
        type: 'success',
      });
      navigate(-1);
      dispatch(isShowDialogBoxChange(!isOpenDialogBoxToggle));
    } else {
      ToastServices.showToast({
        message: response.error,
        type: 'error',
      });
    }
    setSubmitOnce(false);
  };

  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-[rgba(3,27,89,.3)]">
      <div
        className="min-w-[37.5rem] w-[37.5rem] z-10 h-fit flex flex-col items-center gap-[1.5rem] bg-white
        rounded-[18px] shadow-lg"
      >
        <form className="w-full flex flex-col h-full">
          <div
            className="w-full px-7 py-5 flex justify-between items-center text-[#031B59]
          font-extrabold text-xl"
          >
            {/* Modal Heading */}
            <p>{t("invoice")}</p>
            <button className="w-fit h-fit" onClick={AddStatusDialoBox}>
              <GrFormClose className="flex w-[2rem] h-[2rem]" />
            </button>
          </div>
          <div className="w-full flex flex-col gap-4">
            <hr />
            {/* Data from Api */}
            <div className="w-full px-7 flex flex-col item-center gap-4">
              <div className="w-full flex flex-col border gap-2 capitalize">
                {checkData &&
                  Object.keys(checkData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{checkData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <hr />
          </div>
          {/* Submit and cancel button */}
          <div className="w-full px-7 py-5 flex justify-end items-center gap-8">
            <button
              onClick={AddStatusDialoBox}
              className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem]
              rounded-[0.5rem]"
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className={`flex h-[3rem] p-[1rem_1.875rem] w-[6rem] justify-center items-center gap-[0.5rem]
            ${
    submitOnce ? "bg-gray-400" : "bg-[#031B59]"
    } rounded-[2.5rem] font-inter text-[1rem]
             font-bold leading-normal text-white`}
              onClick={() => Submit()}
              disabled={submitOnce}
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
InvoicePopUp.propTypes = {
  AddStatusDialoBox: PropTypes.any,
  submitOnce: PropTypes.any,
  setSubmitOnce: PropTypes.any,
  checkData: PropTypes.object,
  employeedata: PropTypes.object,
  submitData: PropTypes.object,
  setButtonDisabled: PropTypes.any,
};

export default InvoicePopUp;
