/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteResourceFromProject,
  fetchProject
} from "redux/appThunk/Admin/project";
import AddProject from "./AddProject";
import { setProjectId, setResourceId } from "redux/actions/action";
import Popup from "component/common/Popup";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import ProjectTable from "component/project/ProjectsTable";
import IndividualProjects from "component/project/IndividualProject";
import {
  AddButton,
  ColumnButton
} from "component/common/accessControlUi/Button";
import FilterColumn from "../employee/attendance/FilterColumn";
import Search from "component/common/accessControlUi/Search";
import Paginate from "component/admin/Employee/Paginate";
import useFetchProjectData from "./usefetchProjectData";
import { ProjectKeys } from "component/common/enum";
import { IoFilter } from "react-icons/io5";
import ProjectFilter from "./ProjectFilter";

export const getStatusColor = (status) => {
  switch (status) {
  case ProjectKeys.Complete:
    return "text-green-500";
  case ProjectKeys.Delay:
    return "text-red-500";
  case ProjectKeys.notYetStarted:
    return "text-orange-500";
  default:
    return "text-blue-500";
  }
};

export const filterOptions = [
  { key: "resources", title: "Resources" },
  { key: "clientId", title: "Client" },
  { key: "startDate", title: "Start Date" },
  { key: "status", title: "Status" },
  { key: "currency", title: "Currency" },
  { key: "description", title: "Description" },
  { key: "amount", title: "Amount" },
  { key: "billingType", title: "Billing Type" },
  { key: "endDate", title: "End Date" },
];
const dataFilter = [
  { key: 'started', title: "start" },
  { key: 'notyetstarted', title: "Not Yet Start" },
  { key: 'delayed', title: "delay" },
  { key: 'completed', title: "complete" },
];

const disabledFilterState = ["resources", "clientId", "startDate", "status"];

function ProjectListing() {
  const { userPermission } = usePermissions(mappedPermissionObj.Project);

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [showPopup, setShowPopup] = useState();
  const dispatch = useDispatch();
  const { resourceId, projectId } = useSelector(
    (state) => state.projectReducer
  );
  const [statusFilter, setStatusFilter] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const { pageCount } = useSelector((state) => state.leaveReducer);
  const { isLoading } = useSelector((state) => state.ClientsReducer);
  const { allProjects } = useSelector((state) => state.projectReducer);
  const [saveClick, setSaveClick] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [showDropdown, setShowDropdown] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState("");
  const [filter, setFilter] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
  );

  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );

  useFetchProjectData({
    permissionToFetch: userPermission,
    query: searchItem,
    statusFilter,
    currentPage,
  });

  const openDeletePopup = (pId, rId, rName) => {
    dispatch(setProjectId(pId));
    dispatch(setResourceId(rId));
    setShowDeletePopup(rName);
    setShowDropdown("");
  };

  const closeDeletePopup = () => {
    setShowDeletePopup("");
    dispatch(setProjectId(""));
    dispatch(setResourceId(""));
  };

  const handleDelete = () => {
    dispatch(deleteResourceFromProject(projectId, resourceId));
    setShowDeletePopup("");
    setTimeout(() => {
      dispatch(fetchProject(currentPage));
    }, 500);
  };

  const handleAddResource = (projectId) => {
    setSaveClick(true);
    setShowPopup(true);
    dispatch(setProjectId(projectId));
    setShowDropdown("");
  };
  const handleRefresh = () => {
    setFilters(() =>
      Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
    );
    setColumnsFilters(() => new Set(disabledFilterState));
    setStatusFilter([]);
  };

  const handleFilterChange = (e) => {
    const { id, checked } = e.target;

    setFilters((prev) => {
      if (checked) {
        return { ...prev, [id]: true };
      } else {
        const { [id]: removedFilter, ...rest } = prev;

        return rest;
      }
    });
  };

  const handleSaveFilters = () => {
    setColumnsFilters((prev) => {
      const mergedFilters = new Set([...prev, ...Object.keys(filters)]);

      return mergedFilters;

    });
    setFilter(false);
  };

  const handleChange = (title) => {
    setStatusFilter((prev) => {
      if (prev.includes(title)) {
        return prev.filter((status) => status !== title);
      } else {
        return [...prev, title];
      }
    });
  };

  return (
    <div
      className="w-[96%]  h-[calc(100vh-5.1rem)] bg-white space-y-4 flex flex-col p-5 pb-1
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#002169]">
            {t("details")}
          </h2>
        </div>
        <div className="flex items-center justify-center gap-4">
          <Search
            searchItem={searchItem}
            setSearchItem={setSearchItem}
            currentResource={userPermission}
          />
          <div className="p-3 text-xl rounded-full border">
            <IoFilter
              className="w-6 h-6 stroke-[#031B59]"
              onClick={() => setFilter(!filter)}
            />
          </div>
          <AddButton
            currentResource={mappedPermissionObj.Project}
            title={t("add_Project")}
            onClick={() => {
              setShowPopup(true);
              setType("add");
            }}
          />
        </div>
        {filter && (
          <div className="absolute z-50 ml-[60rem] mt-[4rem]">
            <ProjectFilter
              ColumnValue={filterOptions}
              dataFilter={dataFilter}
              filterState={filters}
              title1={"Status"}
              onChange={handleFilterChange}
              onSave={handleSaveFilters}
              onClose={() => setFilter(false)}
              onRefresh={handleRefresh}
              handleChange={handleChange}
              statusFilter={statusFilter}
            />
          </div>
        )}
      </div>

      {showPopup && (
        <AddProject
          setShowPopup={setShowPopup}
          saveClick={saveClick}
          setSaveClick={setSaveClick}
          show={show}
          setShow={setShow}
          currentPage={currentPage}
          type={type}
        />
      )}
      {userPermission?.viewAll && (
        <ProjectTable
          columnModifier={columnFilters}
          handleAddResource={handleAddResource}
          handleView={(projectId) => {
            setType("view");
            setShowPopup(true);
            dispatch(setProjectId(projectId));
          }}
          onSetType={setType}
          onDelete={openDeletePopup}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          isLoading={isLoading}
          allProjects={allProjects}
        />
      )}
      {userPermission?.view && !userPermission?.viewAll && (
        <IndividualProjects />
      )}

      {showDeletePopup !== "" && (
        <Popup
          popupBox={closeDeletePopup}
          title={t("deleteResource")}
          handleSubmit={handleDelete}
          submitBtnText={"Remove"}
        >
          <div className="w-full flex items-center justify-center p-2">
            {t("areYouSure")}
            <span className="text-[1rem] text-[#031B59] font-semibold">
              &nbsp;{showDeletePopup}&nbsp;
            </span>
            ?
          </div>
        </Popup>
      )}
      {!isLoading && userPermission?.viewAll && (
        <div className="w-full h-16 bg-white flex justify-between items-center">
          {allProjects?.length > 0 ? (
            <>
              <div className="text-[#031B59] font-medium">
                Showing {currentPage} of {pageCount}
              </div>{" "}
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-medium">
              {t("no_data_found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProjectListing;
