/* eslint-disable max-len */
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  getAllProjectResources,
  getFeedBackData,
  isLoading,
  isShowDialogBoxChange,
  searchEmployeeData,
  setAllProjects,
  setClientInformation,
  setClientInvoiceData,
  setClientTimesheet,
  setClientTimesheetData,
  setClientsDetails,
  setInterviewId,
  setPageCount,
  setTimeSheetCount,
  settimesheetFilter,
  totalClientsNumber
} from "redux/actions/action";
import {
  formatAddResourceRequest,
  formatAllProjectInformation,
  formatClientInformation,
  formatEmployeeData,
  formatProjectResourceResponse,
  formatedInvoiceData,
  formatedTimeSheet,
  formatedTimesheetData,
  formatfeedback
} from "redux/selector/Admin/client";
import { getAllReviews } from "redux/actions/action";
import { formateReviews } from "redux/selector/Admin/client";
import ToastServices from "ToastServices";

export const fetchResourceData = (projectId, setReload) => async (dispatch) => {
  try {
    setReload(true);
    const path = `${apiUrl.projects}/${projectId}/${apiUrl.projectResources}`;
    const { response, status } = await Helper.get(path);
    if (status === 200 || status === 201) {
      setReload(false);
    }
    const formattedResponse = formatProjectResourceResponse(
      response?.projectResources
    );
    dispatch(getAllProjectResources(formattedResponse));
  } catch (error) {
    setReload(false);
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const fetchClientsDetail = (id, setNewLoading) => async (dispatch) => {
  const path = apiUrl.clients + `/${id}`;
  try {
    setNewLoading(true);
    const { response, status } = await Helper.get(path);
    if (status === 201 || status === 200) {
      const formattedResponse = formatClientInformation(response?.client);
      dispatch(setClientInformation(formattedResponse));
    }
    setNewLoading(false);
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
    setNewLoading(false);
  }
};

export const fetchProjectsDetail = (id, setNewLoading) => async (dispatch) => {
  setNewLoading(true);
  const path = `client_project/${id}`;
  const { response, status } = await Helper.get(path);
  try {
    if (status === 200 || status === 201) {
      const formattedResponse = formatAllProjectInformation(response?.projects);
      dispatch(setAllProjects(formattedResponse));
    }
    setNewLoading(false);
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
    setNewLoading(false);
  }
};

export const updateProjectInfomation =
  (json, id, setReload, setProfileInfoOpen, setRenderListing) => async () => {
    try {
      setReload(true);
      const path = "projects" + `/${id}`;
      const { status } = await Helper.put(json, path);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Updated!",
          type: "success",
          autoClose: 3000,
        });
        setReload(false);
        setProfileInfoOpen(false);
        setRenderListing(true);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
      setReload(false);
    }
  };

export const InvoiceUpdate = (formdata, invoiceData) => async (dispatch) => {
  try {
    const path = `invoices/${invoiceData?.id}`;

    const { status } = await Helper.put(formdata, path, true);
    if (status === 200 || status === 201) {
      dispatch(fetchInvoiceData(invoiceData?.userId));
    }
  } catch (err) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const updateClientInfo =
  (formData, id, setSubmitOnce, setReload) => async () => {
    try {
      setReload(false);
      const path = apiUrl.clients + `/${id}`;
      const { status } = await Helper.put(formData, path, true);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Updated!",
          type: "success",
          autoClose: 3000,
        });
        setSubmitOnce(false);
        setReload(true);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
      setSubmitOnce(false);
      setReload(false);
    }
  };

export const fetchInvoiceData = (id, currentPage) => async (dispatch) => {
  const path = apiUrl.clientbyid + `${id}/?page=${currentPage}`;
  dispatch(isLoading(true));
  try {
    const { response } = await Helper.get(path);
    dispatch(setPageCount(response?.meta?.pagination_data?.total_pages));
    const formatedData = formatedInvoiceData(response?.data);
    dispatch(setPageCount(response?.meta?.pagination_data?.total_pages));
    dispatch(setClientInvoiceData(formatedData));
    dispatch(isLoading(false));
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const handleSearchUser = (userName) => async (dispatch) => {
  const path = `users/search?page=${""}&per_page=${""}&query=${userName}`;
  try {
    const { response } = await Helper.get(path);
    const formatData = formatEmployeeData(response?.users);
    dispatch(searchEmployeeData(formatData));
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};

export const addProjectResources =
  (values, projectId, setGet, setShowResourcesForm) => async () => {
    try {
      setGet(false);
      setShowResourcesForm(true);
      const formattedRequest = formatAddResourceRequest(values, projectId);
      const path = `${apiUrl.projects}/${formattedRequest.projectId}/${apiUrl.clientprojectresource}`;
      const response = await Helper.post(formattedRequest, path);

      if (response.status === 201 || response.status === 200) {
        setShowResourcesForm(false);
        setGet(true);
        ToastServices.showToast({
          message: "Successfully added resources!",
          type: "success",
          autoClose: 3000,
        });
      } else if (response.status === 422) {
        ToastServices.showToast({
          message: "User Allready Exist",
          type: "info",
          autoClose: 3000,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Failed to add resources!",
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const fetchReviews = (resourceId, setReload) => async (dispatch) => {
  setReload(true);
  const path = apiUrl.reviews + `?resource_id=${resourceId}`;
  const { response, status } = await Helper.get(path);
  if (status === 200 || status === 201) {
    setReload(false);
    const formatedData = formateReviews(response?.reviews);
    dispatch(getAllReviews(formatedData));
  }
};

export const addReviews = (values) => async () => {
  const path = apiUrl?.reviews;
  const { status } = await Helper.post(values, path, false);

  if (status === 201) {
    ToastServices.showToast({
      message: "Successfully added!",
      type: "success",
      autoClose: 3000,
    });
  } else if (status === 422) {
    ToastServices.showToast({
      message: "User already added review of this month",
      type: "info",
      autoClose: 3000,
    });
  }
};

export const updateReviews = (values, editId, setHandlePopup) => async () => {
  const path = `reviews/${editId}`;
  const { status } = await Helper.put(values, path, false);
  if (status === 201 || status === 200) {
    ToastServices.showToast({
      message: "Successfully !",
      type: "success",
      autoClose: 3000,
    });
    setHandlePopup(false);
  } else if (status === 422) {
    ToastServices.showToast({
      message: "User already added review of this month",
      type: "info",
      autoClose: 3000,
    });
  }
};

export const createInterviewSchedule =
  (formData, id, projectId, projectResourceId, setRenderSchedulePage, setGet) =>
    async (dispatch) => {
      setGet(true);
      const path = `${apiUrl?.createInterview}?client_id=${id}&project_id=${projectId}&resource_id=${projectResourceId}`;
      const { response, status } = await Helper.post(formData, path, true);
      const responseJson = await response;
      if (status === 201 || status === 200) {
        setRenderSchedulePage(false);
        setGet(false);
        ToastServices.showToast({
          message: "Scheduled Interview! !",
          type: "success",
          autoClose: 3000,
        });

        dispatch(
          setInterviewId({
            interviewId: responseJson?.data?.id,
            id: responseJson?.data?.attributes?.user_id,
          })
        );
      }
    };

export const createFeedback =
  (values, projectId, setRenderSchedulePage, resourceId) =>
    async (dispatch) => {
      const jsonObj = formatfeedback(values, projectId, resourceId);
      const path = `${apiUrl.feedback}`;
      const { status } = await Helper.post(jsonObj, path);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Interview! Feedback !",
          type: "success",
          autoClose: 3000,
        });
      } else if (status === 422) {
        ToastServices.showToast({
          message: "Interview never Scheduled",
          type: "info",
          autoClose: 3000,
        });
      }
      setRenderSchedulePage(false);
      dispatch(isShowDialogBoxChange(false));
    };

export const getFeedback =
  (projectId, allTab, userId, setReload) => async (dispatch) => {
    let path;
    setReload(true);
    if (allTab === 1) {
      path = `${apiUrl.getAllFeedbacks}/${projectId}`;
    } else {
      path = `${apiUrl.myfeedback}user_id=${userId}&project_id=${projectId}`;
    }
    const { response, status } = await Helper.get(path);

    if (status === 200 || status === 201) {
      dispatch(getFeedBackData(response.data));
      setReload(false);
    }
  };

export const fetchClientsData =
  (search, currentPage, selectedFilter) => async (dispatch) => {
    dispatch(isLoading(true));
    const path = `clients?client_type=${selectedFilter?.key || "all"}&page=${
      currentPage || 1
    }
    &query=${search || ""}`;

    const api = apiUrl.showUserChatWithId;
    try {
      const response = await Helper.get(path, api);
      const res = await response.json();
      dispatch(setClientsDetails(res?.clients));
      dispatch(setPageCount(res?.total_pages));
      dispatch(totalClientsNumber(res?.total_count));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const fetchtimesheet = (id, setNewLoading) => async (dispatch) => {
  const path = `time_sheets/all_time_sheets?id=${id}`;
  setNewLoading(true);
  try {
    const { status, response } = await Helper.get(path);
    const formatedData = formatedTimesheetData(response?.statuses);
    if (status === 200 || status === 201) {
      dispatch(setPageCount(response?.pagination_data?.total_pages));
      dispatch(setClientTimesheetData(formatedData));
      setNewLoading(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
    setNewLoading(false);
  }
};

export const fetchtimesheetData =
  (id, setNewLoading, page, monthFilter, selectRes, selectAction, timesheetSearch) => async (dispatch) => {
    let path;

    if (selectRes) {
      const newSelect = JSON.stringify(selectRes);
      const newAction = JSON.stringify(selectAction);
      path = `client_resources/${id}?page=${page}&project_ids=${newAction}&resource_ids=${newSelect}&month=${monthFilter}&per_page=${10}&query=${timesheetSearch}`;
    }
    else {
      path = `client_resources/${id}?page=${page}&per_page=${10}&month=${monthFilter}`;
    }
    try {
      setNewLoading(true);
      const { status, response } = await Helper.get(path);
      const formatedData = formatedTimeSheet(response?.projects);
      if (status === 200 || status === 201) {
        dispatch(setTimeSheetCount(response?.totalPages));
        dispatch(setClientTimesheet(formatedData));
        setNewLoading(false);
      }
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
      setNewLoading(false);
    }
  };

export const listFilterData = (id,page) => async (dispatch) => {
  const path = `client_resources/${id}?page=${page}&per_page=${10}`;
  try{
    const { status, response } = await Helper.get(path);
    const formatedData = formatedTimeSheet(response?.projects);
    if (status === 200 || status === 201) {
      dispatch(setTimeSheetCount(response?.totalPages));
      dispatch(settimesheetFilter(formatedData));
    }
  }catch(error){
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};
