import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightDoubleLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { permissionSchema } from 'redux/validator/admin/permission';
import ListAddRoles from './ListAddRoles';
import { useDispatch } from 'react-redux';
import { AddNewRoleWithPerm } from 'redux/appThunk/Admin/permission';

const AddAccessRole = () => {
  const [perm, setPerm] = useState([]);
  const [checkedPerm, setCheckedPerm] = useState([]);
  const [submitOn, setSubmitOn] = useState(false);
  const [check, setCheck] = useState(false);
  const [newdData, setNewData] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialvalues = {
    name: "",
    description: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: permissionSchema,
    onSubmit: async (values) => {
      const jsonObj = {
        name: values.name,
        description: values.description,
        permissions: perm,
      };
      if (perm?.length > 0) {
        await dispatch(AddNewRoleWithPerm(jsonObj, setSubmitOn));
        await navigate('/access');
      }
    },
  });

  const handleCancel = () => {
    Formik.resetForm();
    setPerm([]);
  };

  const { errors, touched, handleSubmit, values, handleChange, handleBlur } =
    Formik;

  return (
    <div className='w-full p-5'>
      <div className='w-full flex flex-col gap-[0.5rem]'>
        <div className=' w-full flex justify-between items-center'>
          <div className=' w-full flex'>
            <Link to="/access">
              <h3 className="font-normal text-xl text-[#191919] flex items-center">
                {t("access")}
                <span className="text-[#A1A1A1] mx-2">
                  <RiArrowRightDoubleLine />
                </span>
              </h3>
            </Link>
            <div>
              <h3 className="text-xl text-[#031B59] font-extrabold ">
                {t("addRole")}</h3>
            </div>
          </div>
          <div className="w-full flex justify-end items-center gap-5 border-t border-[#F2F6FF]">
            <button
              onClick={handleCancel}
              className="flex w-[6rem] h-[3rem] p-[1rem] justify-center items-center gap-[0.5rem]
              rounded-[2.5rem] border"
            >
              {t("cancel")}
            </button>
            {submitOn ? (
              <div className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
              bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem]
               font-bold leading-normal text-white`}>
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
              </div>
            ) : (
              <div className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
              bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem]
               font-bold leading-normal text-white cursor-pointer`} onClick={() => {
                handleSubmit();
              }}>{t(`save`)}</div>
            )}
          </div>
        </div>
        <div
          className=" h-[2rem] color-[#191919] font-inter
        text-[1.2rem]"
        >
          {t("roleName")}
        </div>
        <div className='lg:flex md:gap-2 md:items-center w-full'>
          <input
            placeholder={t("enterRoleName")}
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="h-[2.625rem] w-full max-w-[37rem] border border-[#E2E8F0] rounded-[0.25rem]
             p-[10px_14px_10px_10px] text-[#191919] mb-1"
          />
          {errors.name && touched.name ? (
            <p className="text-[red] w-full">{errors.name}</p>
          ) : null}
        </div>
        <div
          className="h-[2rem] color-[#191919] font-inter
        text-[1.2rem]"
        >
          {t("desc")}
        </div>
        <div className='w-full lg:flex md:gap-2 md:items-center'>
          <textarea
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("writeADesHere")}
            className="h-[6rem] w-full max-w-[37rem] border border-[#E2E8F0] rounded-[0.25rem]  text-[#191919]
              p-2"
          />
          {errors.description && touched.description ? (
            <p className="text-[red]">{errors.description}</p>
          ) : null}
        </div>
      </div>
      <ListAddRoles
        perm={perm}
        setPerm={setPerm}
        checkedPerm={checkedPerm}
        setCheckedPerm={setCheckedPerm}
        setCheck={setCheck}
        check={check}
        newdData={newdData}
        setNewData={setNewData} />
    </div>
  );
};

export default AddAccessRole;

