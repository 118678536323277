import React, { useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import { addNewTeam, myProject } from "redux/appThunk/Admin/myTeams";
import { useDispatch, useSelector } from "react-redux";
import Close from "svgComponents/myTeam/Close";
import AddMembers from "./AddMembers";
import LoaderComp from "component/loader/LoaderComp";
import ProjectMembers from "./ProjectMembers";
import { useTranslation } from "react-i18next";

const MyProject = () => {
  const dispatch = useDispatch();
  const { isLoading, myProjectData } = useSelector(
    (state) => state.MyTeamsReducer
  );

  const [isNewProject, setIsNewProject] = useState(false);
  const [teamName, setTeamName] = useState(" ");
  const [description, setDescription] = useState(" ");
  const [userIds, setUserIds] = useState([]);
  const [showMembersPage, setShowMembersPage] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const { t } = useTranslation();

  const toggleNewProject = () => {
    setIsNewProject((prev) => !prev);
  };

  useEffect(() => {
    dispatch(myProject());
  }, [dispatch]);

  const handleCreateProject = () => {
    const newTeamData = {
      name: teamName,
      description,
      user_ids: userIds,
    };

    dispatch(addNewTeam(newTeamData));
    setIsNewProject(false);
    setTeamName("");
    setDescription("");
  };

  if (showMembersPage) {
    return (
      <ProjectMembers
        id={projectId}
        setId={setProjectId}
        setMembers={setShowMembersPage}
        projectName={
          myProjectData.find((p) => p.projectId === projectId)?.projectName
        }
      />
    );
  }

  return (
    <div className="border w-fit max-h-full rounded-xl p-4 ml-4 shadow-xl">
      <div className="flex">
        <div className="text-[1.25rem] w-[80rem] h-[3rem] font-[800] text-[#031B59] pl-[2rem]">
          {t("myProjectTeam")}
        </div>
        <button
          onClick={toggleNewProject}
          className="w-[10rem] h-[3rem] border rounded-[3.75rem]"
        >
          {t("newProjectTeam")}
        </button>
      </div>

      {isNewProject && (
        <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] ">
          <div
            className="max-w-[40%] h-full bg-white flex-flex-col
 space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
          >
            <div
              className="w-[769px] h-[900px] bg-white
                rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-[32px]
                shadow flex-col justify-start items-center gap-6 inline-flex"
            >
              <div
                className="w-full pl-10 pr-6 py-6 bg-white
                  border-b border-slate-200 flex justify-between items-center"
              >
                <div className="flex items-center">
                  <div className="text-blue-950 text-xl font-extrabold leading-normal">
                    {t("createNewTeam")}
                  </div>
                </div>
                <button
                  onClick={toggleNewProject}
                  className="text-gray-700 hover:text-gray-900 pr-[2rem]"
                >
                  <Close className="cursor-pointer" />
                </button>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-zinc-800">
                    {t("teamName")}
                  </label>
                  <input
                    type="text"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    className="w-full h-10 p-2.5 bg-white rounded border border-slate-200 text-zinc-900 text-base"
                    placeholder="Enter team name"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-zinc-800">
                    {t("projectDescription")}
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2.5 bg-white rounded border
                   border-slate-200 text-zinc-900 text-base
                   resize-none h-32"
                    placeholder="Enter project description"
                  />
                </div>
                <div className="flex flex-col gap-1 pr-[15rem]">
                  <AddMembers setUserIds={setUserIds} />
                </div>
              </div>
              <div
                className="w-full px-14 py-6 bg-white rounded-lg
                  border-t border-slate-200 flex justify-end gap-4 "
              >
                <button
                  onClick={toggleNewProject}
                  className="text-stone-500 text-base"
                >
                  {t("cancel")}
                </button>
                <button
                  onClick={handleCreateProject}
                  className="px-6
              py-3 bg-blue-950 rounded-full text-white
              text-base border border-blue-950"
                >
                  {t("create")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center mt-3">
          <LoaderComp />
        </div>
      ) : (
        <div className="max-h-[43rem] overflow-x-hidden custom_scroll px-2 py-5">
          {myProjectData?.map((project, id) => (
            <ProjectCard
              setId={setProjectId}
              setMembers={setShowMembersPage}
              key={id}
              projectId={project.projectId}
              projectName={project.projectName}
              projectDescription={project.projectDescription}
              projectResources={project.projectResources}
              projectResourceLength={project.projectResources.length}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MyProject;
