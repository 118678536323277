import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AiFillFileImage } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Svg18 from "svgComponents/Svg18";
import { useDispatch } from "react-redux";
import { fetchData } from "redux/appThunk/Employee/status";
import { BiCloudUpload } from "react-icons/bi";
import { MdOutlineDelete } from "react-icons/md";
import { useFormikContext } from "formik";
import { allowedTimeSheetHoursFormat } from "utils/CommonFunctions";

const TaskDescription = ({task, index }) => {
  const [allProjectsList, setProjectsList] = useState([]);
  const [fileUrl, setFileUrl] = useState("");

  const formik = useFormikContext();
  const { values, errors, touched, setFieldValue } = formik;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRemoveTaskDetails = () => {
    setFieldValue(
      "tasksDetails",
      values.tasksDetails.filter((_, taskIndex) => taskIndex !== index)
    );
  };
  const handleURLChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl((prevState) => [...prevState, fileURL]);
    }
  };
  const handleRemoveImage = (imageIndex) => {
    setFieldValue(
      `tasksDetails[${index}].images`,
      values.tasksDetails[index].images.filter((_, i) => i !== imageIndex)
    );
    if (Array.isArray(fileUrl)) {
      setFileUrl(fileUrl.filter((url, _i) => imageIndex !== _i));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = values.tasksDetails[index].images ?? [];
    setFieldValue(`tasksDetails[${index}].images`, [
      ...updatedImages,
      ...files,
    ]);
  };

  const handleShowImg = (i) => {
    window.open(fileUrl[i], "_blank");
  };

  const handleImageSelect = (e) => {
    handleImageChange(e);
    handleURLChange(e);
  };

  const findError = (key, type) => {
    const isError =
      errors.tasksDetails &&
      errors.tasksDetails[index] &&
      errors?.tasksDetails[index][key] &&
      touched.tasksDetails &&
      touched.tasksDetails[index] &&
      touched?.tasksDetails[index][key];
    if (type === "text") {
      return isError ? (
        <p className="text-[red]">{errors?.tasksDetails[index][key]}</p>
      ) : null;
    } else {
      return isError ? "red" : "";
    }
  };

  useEffect(() => {
    dispatch(fetchData(setProjectsList));
  }, [dispatch]);

  const handleWorkingOnChange = (e) => {
    let inputValue = e.target.value;
    setFieldValue(`tasksDetails[${index}].working_hours`, allowedTimeSheetHoursFormat(inputValue));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  return (
    <div className="flex flex-col items-start gap-2 w-full px-5 ">
      <h2 className="text-md font-semibold text-[#242529] ml-1 mb-1">
        {t("taskDetails")}
      </h2>
      <div className="space-y-2 w-[35rem] flex flex-col items-start h-[px]  gap-4 ">
        <div className="h-fit w-full p-5 flex flex-col gap-3 rounded-lg border border-[#E2E8F0]">
          <div className="flex flex-col w-full gap-4">
            {/* Project Name Input Area */}
            <div className="h-full w-[32rem] flex  flex-col gap-1">
              <label
                className="text-[#313135] font-medium"
                htmlFor={`projectName${index}`}
              >
                {t("project_name")}
              </label>
              <div className="relative flex justify-around ">
                <select
                  className="h-11 w-[32rem] border-[#E2E8F0] p-2 border rounded
                   bg-white appearance-none cursor-pointer capitalize"
                  type="text"
                  id={`projectName${index}`}
                  name={`tasksDetails[${index}].project_id`}
                  value={task.project_id}
                  onChange={handleChange}
                >
                  <option value="">{t("select_option")}</option>
                  {allProjectsList?.project?.map((obj, index) => (
                    <option className="capitalize" key={index} value={obj.id}>
                      {obj.name}
                    </option>
                  ))}
                </select>

                <div className="absolute right-0 top-0 mt-3 inset-y-0 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {findError("project_id", "text")}
            </div>
            {/* Working Hours */}
            <div className="flex flex-col w-[32rem] gap-1">
              <label
                className="text-[#313135] font-medium"
                htmlFor={`working_date${index}`}
              >
                {t("working_hours")}
              </label>
              <input
                placeholder="HH"
                className="h-11 w-full  border-[#E2E8F0] p-2 border rounded"
                style={{
                  borderColor: findError("working_hours", "border"),
                }}
                id={`working_date${index}`}
                name={`tasksDetails[${index}].working_hours`}
                value={task.working_hours}
                maxLength="5"
                onChange={handleWorkingOnChange}
                onBlur={formik.handleBlur}
              />

              {findError("working_hours", "text")}
            </div>
            {/* Present Status Input */}
            <div className="flex flex-col w-[32rem]  gap-1">
              <label
                className="text-[#313135] font-medium"
                htmlFor={`Status${index}`}
              >
                {t("statuses")}
              </label>
              <div className="relative">
                <select
                  className=" h-11 w-full border-[#E2E8F0] p-2 border rounded bg-white appearance-none cursor-pointer"
                  id={`Status${index}`}
                  name={`tasksDetails[${index}].task_status`}
                  value={task?.task_status}
                  style={{
                    borderColor: findError("task_status", "border"),
                  }}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}

                  // onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">{t("select")}</option>
                  <option value={t("in_progress")}>{t("in_progress")}</option>
                  <option value={t("completed")}>{t("completed")}</option>
                </select>
                <div className="absolute right-0 top-0 mt-3 inset-y-0 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
              {findError("task_status", "text")}
            </div>
            {/* Billable or not input */}
            <div className="flex flex-col w-[32rem]  gap-1">
              <label
                className="text-[#313135] font-medium"
                htmlFor={`billable${index}`}
              >
                {t("billable")}
              </label>

              <div className="relative">
                <select
                  className=" h-11 w-full  border-[#E2E8F0] p-2 border rounded bg-white appearance-none cursor-pointer"
                  id={`billable${index}`}
                  style={{
                    borderColor: findError("billable", "border"),
                  }}
                  name={`tasksDetails[${index}].billable`}
                  value={task.billable}
                  onChange={handleChange}
                  // onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">{t("select")}</option>
                  <option value="false">{t("no")}</option>
                  <option value="true">{t("yes")}</option>
                </select>
                <div className="absolute right-0 top-0 mt-3 inset-y-0 pointer-events-none">
                  <Svg18 />
                </div>
              </div>

              {findError("billable", "text")}
            </div>
          </div>

          {/* Text Area */}
          <div className="flex flex-col w-full col-span-1 lg:col-span-4 gap-1">
            <label
              className="text-[#313135] font-medium"
              htmlFor={`task_description${index}`}
            >
              {t("task_description")}
            </label>
            <textarea
              className="min-h-[7.063rem] border-[#E2E8F0] p-2 border rounded "
              style={{
                borderColor: findError("task_description", "border"),
              }}
              type="text"
              id={`task_description${index}`}
              name={`tasksDetails[${index}].task_description`}
              value={task.task_description}
              onChange={handleChange}
              // onKeyDown={(e) => enterClick(e)}
            />
            {findError("task_description", "text")}
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="w-full min-w-full flex flex-col text-[#313135]  font-medium gap-2">
              <label htmlFor="status_image">{t("upload_image")}</label>
              <label
                role="button"
                id={`status_image${index}`}
                className="border h-[160px] flex justify-center items-center"
              >
                <input
                  type="file"
                  multiple
                  id={`status_image${index}`}
                  className="hidden"
                  // onKeyDown={(e) => enterClick(e)}
                  onChange={handleImageSelect}
                  accept=".pdf,.jpg,.png"
                />
                <div className="flex items-center flex-col justify-center w-full">
                  <BiCloudUpload className="h-[30px] w-[30px] mt-[2px] ml-[15px] text-[#A1A1A1]" />
                  <span>
                    {t("drag_and_drop")} <b>{t("browse_file")}</b>
                    <br />
                    {t("Supported format .pdf/.jpg/.png")}
                  </span>
                </div>
              </label>
            </div>
            {values?.tasksDetails[index]?.images &&
              Array.isArray(values.tasksDetails[index].images) &&
              values?.tasksDetails[index]?.images?.length > 0 && (
              <div
                className="w-full border h-[160px] flex flex-col items-start flex-1
              rounded-[4px] py-3 px-4 gap-4 overflow-y-auto lg:mt-8 mt-0"
              >
                <span className=" text-sm text-[#313135] font-medium">
                  {t("uploaded_images")}
                </span>
                <div className="w-full flex flex-col items-start gap-4 pl-2">
                  {values?.tasksDetails[index]?.images?.map((file, _i) => (
                    <li
                      key={index}
                      className="w-full font-normal flex space-x-3 justify-between items-center
                      text-base text-[#191919]"
                    >
                      <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem]" />
                      <span
                        className="w-full h-fit cursor-pointer"
                        onClick={() => handleShowImg(_i)}
                      >
                        {file.name}
                      </span>
                      <GrFormClose
                        onClick={() => {
                          handleRemoveImage(index);
                        }}
                        className="fill-[#686868] cursor-pointer stroke-[#686868] stroke-[1px] w-6 h-6 p-1 box-content"
                      />
                    </li>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-end space-x-2 lg:col-span-4 ">
            {values.tasksDetails.length === 1 ? (
              <button
                type="button"
                className="w-[2.688rem] h-[2.688rem] flex items-center justify-center
                border-[1.5px] border-[#E2E8F0] rounded-full"
                disabled={true}
              >
                <MdOutlineDelete className="w-5 h-5 fill-[#dddddd] stroke-[#dddddd]" />
              </button>
            ) : (
              <button
                type="button"
                onClick={handleRemoveTaskDetails}
                className="w-[2.688rem] h-[2.688rem] flex items-center justify-center
                border-[1.5px] border-[#E2E8F0] rounded-full"
              >
                <MdOutlineDelete className="w-5 h-5 fill-[#FF0000] stroke-[#FF0000]" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDescription;

TaskDescription.propTypes = {
  Formik: PropTypes.object,
  task: PropTypes.object,
  index: PropTypes.string,
};
