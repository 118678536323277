import React, { useEffect } from "react";
import DashboardCommon from "./DashboardCommon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workAnivversary } from "redux/appThunk/Admin/dashboard";

const DashboardAnniversary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selector = useSelector((state) => state.dashboardReducers.aniversary);
  useEffect(() => {
    dispatch(workAnivversary());
  }, []);

  return (

    <div className="h-[10rem]">
      {selector?.length === 0 ? (
        <DashboardCommon title={t("workAnniversary")} count={0} data={[]} message="No One Have Work Anniversaries" />
      ) : selector[0]?.todaysAnniversary?.length === 0 && selector[0]?.upcomingAnniversary?.length === 0 ? (
        <DashboardCommon title={t("workAnniversary")} count={0} data={[]} message="No One Have Work Anniversaries" />
      ) : selector[0]?.todaysAnniversary?.length === 0 ? (
        <DashboardCommon title={t("AnniversaryUpcoming")} count={selector[0]?.upcomingAnniversary?.length}
          data={selector[0]?.upcomingAnniversary} />
      ) : (
        <DashboardCommon title={t("AnniversaryToday")}
          count={selector[0]?.todaysAnniversary?.length} data={selector[0]?.todaysAnniversary} />
      )}
    </div>

  );
};

export default DashboardAnniversary;
