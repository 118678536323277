import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  getAllLeaves,
  setIndividualleave,
  isLoading,
  setPageCount,
  setSuccess,
  setOrganisationLeave

} from "redux/actions/action";
import {

  formatAddLeave,
  formatEditLeaves,
  formatLeave,
  formatLeaveData,
  formatOrganisationLeave
} from "redux/selector/Employee/leaves";

export const fetchEmployeeLeaveData =
  (currentPage, itemsPerPage) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const path = `leaves?page=${currentPage}&per_page=${itemsPerPage}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatLeaveData(response?.data);
      dispatch(getAllLeaves(formattedResponse));
      dispatch(setPageCount(response?.total_pages));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error During Fetch Data ! ",
        autoClose: 3000,
      });
    }
  };

export const fetchLeaves = (id) => async (dispatch) => {
  try {
    const path = apiUrl.leaves + "/" + id;
    const { response } = await Helper.get(path);
    const formattedResponse = formatLeave(response);
    dispatch(setIndividualleave(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error During Fetch Data ! ",
      autoClose: 3000,
    });
  }
};

export const addLeaves =
  (values, userid, setSubmitOnce) => async (dispatch) => {
    try {
      setSubmitOnce(true);
      const request = formatAddLeave(values, userid);
      const path = apiUrl.leaves;
      const { status } = await Helper.post(request, path);
      setSubmitOnce(false);
      if (status === 201) {
        ToastServices.showToast({
          message: "Leave Added",
          type: 'success',
          autoClose: "3000",
        });
        dispatch(setSuccess(true));
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error During Fetch Data ! ",
        autoClose: 3000,
      });
    }
  };

export const editLeaves = (values, id, setSubmitOnce) => async (dispatch) => {
  setSubmitOnce(false);
  const formatData = formatEditLeaves(values);
  const path = apiUrl.editLeave + id;
  await Helper.put(formatData, path);
  dispatch(setSuccess(true));
  setSubmitOnce(true);
};

export const fetchOrganisationLeave = (currentPage, itemsPerPage, department, designation, search) =>
  async (dispatch) =>
  {
    dispatch(isLoading(true));
    try {
      const path = `/leaves?page=${currentPage}&per_page=${itemsPerPage}` +
    `&departments=${department}&designation=${designation}&search=${search}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatOrganisationLeave(response?.data);
      dispatch(setOrganisationLeave(formattedResponse));
      dispatch(setPageCount(response?.total_pages));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: "Error During Fetch Data!",
        autoClose: 3000,
      });
    }
  };

