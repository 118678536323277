import React from 'react';
import { FaRegBell } from "react-icons/fa";
import PropTypes from "prop-types";

const UserAvatarDetails = ({image,name}) => {
  return (
    <div className="flex items-center space-x-3 p-3   ">
      <div className="w-12 h-12 rounded-full overflow-hidden border border-gray-300">
        <img
          src={image}
          alt="User Avatar"
          className="w-full h-full object-cover"
        />
      </div>

      <div>
        <p className="text-sm text-gray-500">Reporting Lead</p>
        <p className="text-lg font-semibold text-blue-800">{name}</p>
      </div>

      <div className="ml-auto">
        <button className="relative">
          <FaRegBell className="text-gray-400 h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default UserAvatarDetails;

UserAvatarDetails.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
};
