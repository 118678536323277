/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getUserTimesheet } from "redux/appThunk/Admin/status";
import usePermissions from "hooks/usePermission";
import MyTimesheetListing from "./MyTimesheetListing";
import { useParams, useSearchParams } from "react-router-dom";
import DatePicker from "component/common/DatePicker";
import TimeSheetForm from "./form/TimeSheetForm";

const MyTimesheet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = usePermissions();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const fullname = searchParams.get("name");
  const reload = useSelector((state) => state.statusReducer.loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [searchDate, setSearchDate] = useState("");

  useEffect(() => {
    dispatch(
      getUserTimesheet({ currentPage, id: id ? id : userId, date: searchDate })
    );
  }, [currentPage, userId, searchDate]);

  useEffect(() => {
    if (reload) {
      dispatch(getUserTimesheet({ currentPage, id: id ? id : userId }));
    }
  }, [reload]);

  const handleClose = () => {
    setOpenForm(false);
  };

  return (
    <div
      className="w-[96%]  h-[calc(100vh-7.1rem)] bg-white space-y-4 flex flex-col p-5 pb-1
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      <div className="w-full bg-white space-y-4 flex flex-col p-5 pb-1">
        <div className="w-full h-16 flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#031B59]">
              {fullname ?? t("timesheetUpdate")}
            </h2>
          </div>
          <div className="flex items-center justify-center space-x-4">
            {id ? (
              <div className="flex items-center  justify-center lg:space-x-4 md:space-x-2 ">
                <div>
                  <DatePicker
                    name="status_date"
                    value={searchDate}
                    handleChange={(e) => {
                      setSearchDate(e.target.value);
                    }}
                    styles=" rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem]
                 md:w-[8.5rem]
                     text-[#A1A1A1] "
                  />
                </div>
              </div>
            ) : (
              <button
                className="h-[3.063rem] w-[8.625rem] p-2 border-[1.5px] border-[#E2E8F0]  text-[#031B59] rounded-full"
                type="submit"
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                {t("fillTimeSheet")}
              </button>
            )}
          </div>
        </div>

        <MyTimesheetListing
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>

      {!id && openForm && <TimeSheetForm closeDrawer={handleClose} />}
    </div>
  );
};

export default MyTimesheet;

MyTimesheet.propTypes = {
  index: PropTypes.number,
  status: PropTypes.object,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  id: PropTypes.any,
};
