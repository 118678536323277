import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { MdChevronLeft } from "react-icons/md";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import LoaderComp from "component/loader/LoaderComp";
import { profileData } from "redux/appThunk/Employee/profile";
import { UpdateSalarydata } from "redux/appThunk/Admin/salary";

export default function SalaryInformation() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.profileData);

  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [periodOption, setPeriodOption] = useState(false);
  const [customError, setCustomError] = useState("");
  const [handle, setHandle] = useState(false);
  const [submitshow,setSubmitshow] = useState(false);

  useEffect(() => {
    dispatch(profileData(userData?.id, setHandle));

    if (id) {
      dispatch(profileData(id, setHandle));
    } else {
      dispatch(profileData(userData?.id, setHandle));
    }
  },
  [id, userData?.token]
  );

  const replaceUnderscoreWithSpace = (str) => {
    return str.replace(/_/g, ' ');
  };

  const initialValues = {
    ctc: user?.salary?.ctc,
    ...user?.salary?.additions?.reduce(
      (acc, val) => ({ ...acc, [val.name]: val.value ? val.value : "" }),
      {}
    ),
    ...user?.salary?.deductions
      ?.filter((val) => val.name !== "Consultancy Fee")
      ?.reduce(
        (acc, val) => ({ ...acc, [val.name]: val.value ? val.value : "" }),
        {}
      ),
    ...user?.salary?.others?.reduce(
      (acc, val) => ({ ...acc, [val.name]: val.value ? val.value : "" }),
      {}
    ),
  };

  const initialData = {
    ...user?.salary?.deductions
      ?.filter((val) => val.name === "Consultancy Fee" || val.name === "TDS")
      ?.reduce(
        (acc, val) => ({ ...acc, [val.name]: val.value ? val.value : "" }),
        {}
      ),
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues:
      user?.section_applicable === "section 192" ? initialValues : initialData,
    onSubmit: async (values) => {
      let jsonObj = {};
      if (user?.section_applicable === "section 192") {
        jsonObj = {};

        let objData = Object.keys(values)
          ?.map((val) => ({
            field_name: val,
            value: values[val],
          }))
          ?.filter((val) => val.field_name != "ctc");

        jsonObj = { salary_info: objData, ctc: values.ctc };
      } else {
        jsonObj = {};

        let objData = Object.keys(values)?.map((val) => ({
          field_name: val,
          value: values[val],
        }));

        jsonObj = { salary_info: objData };
      }
      if (customError === "") {
        swalService
          .showWarning({
            icon: "warning",
            title: "Are you sure?",
            text: "You want to Update this!",
            showCancelButton: true,
            confirmButtonText: "Yes, Update it!",
            cancelButtonText: "No, cancel!",
          })
          .then(async (result) => {
            if (result.value) {
              var id = user?.salary?.id;
              dispatch(UpdateSalarydata(id, jsonObj,setSubmitshow, null, setHandle));
            }
          });
      }
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
  } = Formik;

  useEffect(() => {
    if (values?.tds > 100) {
      setCustomError("tds must be between 0 and 100%");
    }
  }, [values?.tds]);

  const renderFieldFor192 = () => {
    return (
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="ctc" className="text-[#313135]">
            {t("ctc")}
          </label>
          <input
            type="text"
            name="ctc"
            id="ctc"
            value={Formik.values.ctc}
            className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
            readOnly={userData?.role === "employee"}
            onChange={(e) => { handleChange(e);setSubmitshow(true); }}
            onBlur={handleBlur}
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                e.preventDefault();
              }
            }}
          />
          {errors.ctc && touched.ctc ? (
            <p className="text-[red]">{errors.ctc}</p>
          ) : null}
        </div>
        <br />

        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59] mb-4">{t("addition")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user &&
              user?.salary?.additions?.map((val, index) => {
                return (
                  <div key={index} className="flex flex-col space-y-2">
                    <label htmlFor={val.name} className="text-[#313135] capitalize">
                      {replaceUnderscoreWithSpace(val.name)}
                    </label>
                    <input
                      type="text"
                      name={val?.name}
                      id={val?.name}
                      value={Formik.values[val?.name]}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      readOnly={userData?.role === "employee"}
                      onChange={(e) => { handleChange(e);setSubmitshow(true); }}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.val?.name && touched.val?.name ? (
                      <p className="text-[red]">{errors.val?.name}</p>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59] mb-4">{t("deductions")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user &&
              user?.salary?.deductions
                ?.filter((val) => val.name !== "Consultancy Fee")
                ?.map((val, index) => (
                  <div key={index} className="flex flex-col space-y-2">
                    <label htmlFor={val.name} className="text-[#313135] capitalize">
                      {replaceUnderscoreWithSpace(val.name)}
                    </label>
                    <input
                      type="text"
                      name={val?.name}
                      id={val?.name}
                      value={Formik.values[val.name]}
                      className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                      readOnly={userData?.role === "employee"}
                      onChange={(e) => { handleChange(e);setSubmitshow(true); }}
                      onBlur={handleBlur}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.val?.name && touched.val?.name ? (
                      <p className="text-[red]">{errors.val?.name}</p>
                    ) : null}
                  </div>
                ))}
          </div>
        </div>

        <div className="w-full col-span-1 lg:col-span-2 flex flex-col">
          <div className="mb-2">
            <h2 className="text-[#031B59] mb-4">{t("other")}</h2>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
            {user && user?.salary?.others?.map((val, index) => (
              <div key={index} className="flex flex-col space-y-2">
                <label htmlFor={val.name} className="text-[#313135] capitalize">
                  {replaceUnderscoreWithSpace(val.name)}
                </label>
                <input
                  type="text"
                  name={val?.name}
                  id={val?.name}
                  value={Formik.values[val.name]}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  readOnly={userData?.role === "employee"}
                  onChange={(e) => { handleChange(e);setSubmitshow(true); }}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.val?.name && touched.val?.name ? (
                  <p className="text-[red]">{errors.val?.name}</p>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </form>
    );
  };

  const renderFieldFor194 = () => {
    return (
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        {user && user?.salary?.deductions
          ?.filter(
            (val) => val?.name === "Consultancy Fee" || val?.name === "TDS"
          )
          ?.map((val, index) => (
            <div key={index} className="flex flex-col space-y-2">
              <label htmlFor={val?.name} className="text-[#313135]">
                {val?.name}
              </label>
              <div className="flex border border-[#E2E8F0] w-full rounded">
                <input
                  type="text"
                  name={val?.name}
                  id={val?.name}
                  className="w-full h-[2.625rem] p-2 text-[#191919] outline-none bg-[#fff]"
                  value={Formik.values[val.name]}
                  readOnly={userData?.role === "employee"}
                  onChange={(e) => { handleChange(e);setSubmitshow(true); }}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {
                  val?.name === "Consultancy Fee" &&
                  ( <div
                    className="basis-1/4 w-full flex space-x-4 justify-end pr-2 items-center
                  relative capitalize text-[#031B59] cursor-pointer"
                    name="period"
                    value={values?.period}
                    onChange={handleChange}
                    onClick={() => { setPeriodOption(!periodOption);setSubmitshow(true); }}
                  >
                    {values.period}
                    <MdChevronLeft className="w-5 h-5 transform -rotate-90 text-[#A1A1A1]" />
                    {periodOption && (
                      <div
                        className="absolute w-[115px] h-[74px] flex flex-col top-[3rem] right-[0] left-[2]
                    rounded-md justify-center items-center bg-[#fff] z-10 border border-[#E2E8F0]"
                      >
                        <div
                          className="cursor-pointer w-full hover:bg-[#F1F5F9] text-[#191919] rounded-md p-1"
                          onClick={() => setFieldValue("period", "monthly")}
                        >
                          {t("monthly")}
                        </div>
                        <div
                          className="cursor-pointer w-full hover:bg-[#F1F5F9] text-[#191919] rounded-md p-1"
                          onClick={() => setFieldValue("period", "hourly")}
                        >
                          {t("hourly")}
                        </div>
                      </div>
                    )}
                  </div>)
                }
              </div>
              {errors.val?.name && touched.val?.name ? (
                <p className="text-[red]">{errors.val?.name}</p>
              ) : null}
            </div>
          ))}
      </form>
    );
  };

  return (
    <div
      className="w-[96%] max-h-[51.563rem] min:h-[20rem] p-5 bg-white flex-flex-col space-y-8 rounded-[20px] mt-5"
    >

      {handle ? (
        <LoaderComp />
      ) : user?.section_applicable === "section 192" ? (
        <div className="overflow-y-scroll custom_scroll max-h-[42.563rem]"> {renderFieldFor192() }</div>
      ) : (
        renderFieldFor194()
      )}
      {submitshow ? (
        <div className="w-full h-16 bg-white flex justify-end">
          {userData?.role === "admin" && (
            <div className="flex items-center justify-center space-x-4">
              <button
                className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
                onClick={() => { setSubmitshow(false); }}
              >
                {t("cancel")}
              </button>
              <button
                className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                onClick={() => { handleSubmit(); }}
              >
                {t("update")}
              </button>
            </div>
          )}
        </div>) : (null)}
    </div>
  );
}
