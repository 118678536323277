import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const FilterColumn = ({
  setShowFilter,
  onSave,
  title1,
  title2,
  dataFilter,
  dataFilterValue,
  ColumnValue,
  columnFilter,
  setDepartmentId,
}) => {

  const [resourceOpen, setResourceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);

  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermProjects, setSearchTermProjects] = useState("");

  const menuRef = useRef();

  const handleResourceToggle = () => setResourceOpen(!resourceOpen);
  const handleProjectsToggle = () => setProjectsOpen(!projectsOpen);

  const onCLose = () => {
    setShowFilter(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProjectSearch = (e) => {
    setSearchTermProjects(e.target.value);
  };

  useOnClickOutside(menuRef, () => setShowFilter(false));

  return (
    <div
      ref={menuRef}
      className="w-96 p-4 bg-white border rounded-md shadow-md top-96  max-h-[60vh] overflow-y-auto custom_scroll"
    >
      <div className="flex justify-between items-center ">
        <div className="text-[#031B59] text-xl font-bold ">Filters</div>
        <div onClick={onCLose} className="text-xl">
          <IoClose />
        </div>
      </div>

      <div className="">
        <div className="flex flex-col gap-2 mt-4">
          <div
            className="border p-2 cursor-pointer flex justify-between items-center "
            onClick={handleResourceToggle}
          >
            <div>{title1}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {resourceOpen && (
            <div className="p-2 my-1 border">
              <input
                type="text"
                placeholder="Search"
                className="p-2 w-full border rounded-md mb-2"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="p-2 my-1 ">
                {dataFilter?.map((resource,item) => (
                  <div key={resource} className="flex items-center mb-2">
                    <input
                      id={item.id}
                      type="checkbox"
                      className="mr-2"
                      onChange={() => setDepartmentId(resource?.id)}
                    />
                    <label className="text-blue-950">{resource?.title}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleProjectsToggle}
          >
            <div>{title2}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {projectsOpen && (
            <div className="p-2 my-1 border ">
              <input
                type="text"
                placeholder="Search"
                className="p-2 w-full border rounded-md mb-2"
                value={searchTermProjects}
                onChange={handleProjectSearch}
              />
              <div className="p-2 my-1 ">
                {dataFilterValue?.map((project) => (
                  <div key={project} className="flex items-center mb-2 ">
                    <input
                      type="checkbox"
                      className="mr-2"
                    />
                    <label className="text-blue-950">{project?.title}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2">
        {columnFilter && ColumnValue && (
          <h1 className="font-semibold text-blue-950">{columnFilter}</h1>
        )}
        <div className="p-2 my-1 max-h-fit">
          {ColumnValue?.map((project) => (
            <div key={project} className="flex items-center mb-2 ">
              <input
                type="checkbox"
                className="mr-2"
              />
              <label className="text-blue-950">{project?.title}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md">
          <MdRefresh />
        </button>
        <button
          onClick={onSave}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59]
         rounded-md"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};

export default FilterColumn;

FilterColumn.propTypes = {
  setShowFilter: PropTypes.bool,
  clientTimeSheet: PropTypes.any,
  onSave: PropTypes.any,
  filteredKeys: PropTypes.any,
  selectedResources: PropTypes.any,
  selectedProjects: PropTypes.any,
  setSelectedProjects: PropTypes.any,
  setSelectedResources: PropTypes.any,
  resourceName: PropTypes.any,
  setResourceName: PropTypes.any,
  projectName: PropTypes.any,
  setProjectName: PropTypes.any,
  title1: PropTypes.any,
  title2: PropTypes.any,
  ColumnValue: PropTypes.any,
  dataFilter: PropTypes.any,
  dataFilterValue: PropTypes.any,
  columnFilter: PropTypes.string,
  setDepartmentId: PropTypes.number,
};
