/* eslint-disable max-len */
import React from "react";

const Design = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.0013 1.33594H4.0013C3.26597 1.33594 2.66797 1.93394 2.66797 2.66927V14.0026C2.66797 14.1794 2.73821 14.349 2.86323 14.474C2.98826 14.599 3.15782 14.6693 3.33464 14.6693H12.668C12.8448 14.6693 13.0143 14.599 13.1394 14.474C13.2644 14.349 13.3346 14.1794 13.3346 14.0026V2.66927C13.3346 1.93394 12.7366 1.33594 12.0013 1.33594ZM12.0013 13.3359H4.0013V2.66927H12.0013V13.3359Z"
        fill="#A1A1A1"
      />
      <path
        d="M5.33203 4H7.33203V5.33333H5.33203V4ZM8.66536 4H10.6654V5.33333H8.66536V4ZM5.33203 6.66667H7.33203V8H5.33203V6.66667ZM8.66536 6.68733H10.6654V8H8.66536V6.68733ZM5.33203 9.33333H7.33203V10.6667H5.33203V9.33333ZM8.66536 9.33333H10.6654V10.6667H8.66536V9.33333Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};

export default Design;
