import {
  PAGE_COUNT,
  SET_EMPLOYEE_DATA,
  SET_EMPLOYEE_LEAD,
  SET_FILTER_DATA
} from "redux/actions/types";

const initialstate = {
  status: [],
  statusData: {},
  showTask: "",
  pageCount: 0,
  isLoading: true,
  searchData: {},
  myTimesheetData: [],
  filterData: [],
  employeeData: [],
  employeeLead: [],
  isSuccess: false,
  loading: false,
};

export const statusReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "SET_STATUS":
    return { ...state, status: payload, searchData: [] };

  case "INDIVIUAL_STATUS":
    return {
      ...state,
      statusData: payload,
    };

  case "DELETE_STATUS":
    return {
      ...state,
      payload,
    };

  case "ADD_MORE_TASK":
    return {
      ...state,
      showTask: payload,
    };

  case PAGE_COUNT:
    return { ...state, pageCount: payload };

  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };

  case "SEARCH_STATUS_NAME_DATE":
    return {
      ...state,
      searchData: payload,
    };

  case SET_FILTER_DATA:
    return {
      ...state,
      filterData: payload,
    };

  case SET_EMPLOYEE_DATA:
    return {
      ...state,
      employeeData: payload,
    };

  case SET_EMPLOYEE_LEAD:
    return {
      ...state,
      employeeLead: payload,
    };
  case "SET_MY_TIMESHEET":
    return {
      ...state,
      myTimesheetData: payload,
    };
  case "SET_IS_SUCCESS":
    return {
      ...state,
      isSuccess: payload,
    };
  case "SET_IS_LOADING":
    return {
      ...state,
      loading: payload,
    };
  default:
    return state;
  }
};
