/* eslint-disable react/jsx-key */
import Tooltip from "component/Tooltip";
import Card from "component/common/Card";
import usePermissions from "hooks/usePermission";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddEmployee from "svgComponents/dashboard/AddEmployee";
import ApplyLeave from "svgComponents/dashboard/ApplyLeave";
import ViewTimeSheet from "svgComponents/dashboard/ViewTimeSheet";
import PropTypes from 'prop-types';

const DashboardDirectActions = ({setAddEmployeePop,setAddLeavePop}) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { userPermission, isAdmin } = usePermissions();

  const data = [
    {
      id: 1,
      icon: <AddEmployee />,
      status: t("addemployees"),
      path: () => setAddEmployeePop(true),
      permission: userPermission?.employees?.create,
    },
    {
      id: 2,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: () => setAddLeavePop(true),
      permission: userPermission?.employees?.create,
    },
    {
      id: 1,
      icon: <AddEmployee />,
      status: "Fill Timesheet",
      path: "/timesheet",
      permission:
        userPermission?.timeSheet?.view && !userPermission?.timeSheet?.viewAll,
    },
    {
      id: 6,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 7,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 8,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 9,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 2,
      icon: <AddEmployee />,
      status: "Fill Timesheet",
      path: "/timesheet",
      permission:
        userPermission?.timeSheet?.view && !userPermission?.timeSheet?.viewAll,
    },
    {
      id: 5,
      icon: <ViewTimeSheet />,
      status: t("viewTimesheet"),
      path: "/timesheet",
      permission: userPermission?.timeSheet?.view,
    },
    {
      id: 6,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 7,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
    {
      id: 8,
      icon: <ApplyLeave />,
      status: t("applyLeave"),
      path: "/leave",
      permission: !isAdmin,
    },
  ];

  const filteredAction = data?.filter((item) => item.permission);

  return (
    <Card
      cardClass={
        "h-[11 rem] w-full flex flex-col col-span-2 gap-[1.25rem] p-5 "
      }
    >
      <div className="flex flex-row ">
        <div className="h-fit w-full flex">
          <div className="w-full h-full flex items-center ">
            <p
              className={` lg:text-lg sm:text-1xl leading-2 font-medium flex justify-center
              text-[#031B59] `}
            >
              {t("directAction")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex gap-[0.6rem] overflow-x-scroll custom_scroll_actions pb-2">
        {filteredAction?.map((item) =>
          item?.underDev ? (
            <Tooltip message={item.underDev} key={item.id}>
              <div onClick={() => nav("#")}>
                <div className="border cursor-pointer border-[#E2E8F0] p-[0.62rem] rounded-lg w-28 min-w-[9rem] flex flex-col gap-2">
                  <span>{item.icon}</span>
                  <p className="text-[#191919] font-normal text-sm w-16">
                    {item.status}
                  </p>
                </div>
              </div>
            </Tooltip>
          ) : (
            <div key={item.id}
              onClick={
                () => item.id === 1 || item.id === 2 && item.status === "Add Employee" || item.status === "Apply Leave" ? item.path() : nav(item.path)
              }>
              <div className="border cursor-pointer border-[#E2E8F0] p-[0.62rem] rounded-lg w-28 min-w-[9rem] flex flex-col gap-2">
                <span>{item.icon}</span>
                <p className="text-[#191919] font-normal text-sm w-16">
                  {item.status}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </Card>
  );
};

export default DashboardDirectActions;

DashboardDirectActions.propTypes = {
  setAddEmployeePop: PropTypes.func,
  setAddLeavePop: PropTypes.func,
};
