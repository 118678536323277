import React from "react";

const SvgEye = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path d="M17.0617 8.54648C15.3953 5.03613 12.8763 3.26953 9.49955 3.26953C6.12104
  3.26953 3.60385 5.03613 1.93744 8.54824C1.8706 8.68978 1.83594 8.84436 1.83594
   9.00088C1.83594 9.1574 1.8706 9.31198 1.93744 9.45352C3.60385 12.9639 6.12279
   14.7305 9.49955 14.7305C12.8781 14.7305 15.3953 12.9639 17.0617 9.45176C17.197
   9.16699 17.197 8.83652 17.0617 8.54648ZM9.49955 13.4648C6.6642 13.4648 4.58822
   12.027 3.12397 9C4.58822 5.97305 6.6642 4.53516 9.49955 4.53516C12.3349 4.53516
   14.4109 5.97305 15.8751 9C14.4126 12.027 12.3367 13.4648 9.49955 13.4648ZM9.42924
    5.90625C7.72065 5.90625 6.33549 7.29141 6.33549 9C6.33549 10.7086 7.72065 12.0938
     9.42924 12.0938C11.1378 12.0938 12.523 10.7086 12.523 9C12.523 7.29141 11.1378
     5.90625 9.42924 5.90625ZM9.42924 10.9688C8.34115 10.9688 7.46049 10.0881 7.46049
      9C7.46049 7.91191 8.34115 7.03125 9.42924 7.03125C10.5173 7.03125 11.398 7.91191
       11.398 9C11.398 10.0881 10.5173 10.9688 9.42924 10.9688Z" fill="#031B59"/>
    </svg>
  );
};

export default SvgEye;
