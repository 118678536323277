import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchInvoiceData } from "redux/appThunk/Admin/invoice";
// import { fetchInvoiceData } from "redux/appThunk/Admin/invoice";

const useFetchInvoiceData = ({ permissionToFetch, searchName, currentPage, getParams }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchInvoiceDataCom = () => {
      if (permissionToFetch.viewAll) {
        if (searchName){
          dispatch(fetchInvoiceData(searchName, currentPage, getParams));
        }else{
          dispatch(fetchInvoiceData("",currentPage, getParams));
        }
      }
    };
    fetchInvoiceDataCom();
  }, [searchName, currentPage, getParams]);
};

export default useFetchInvoiceData;
