/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import StatusPopup from "component/leavePanel/StatusPopup";
import ProfileAvtar from "component/common/ProfileAvtar";
import { handleStatusChange } from "redux/appThunk/Admin/leave";
import { useDispatch } from "react-redux";
import { handleUpdateWFHStatus } from "redux/appThunk/Admin/dashboard";
import { useTranslation } from "react-i18next";

const DashboardTablePopUp = ({
  title,
  closeDrawer,
  selectorData,
  headerData,
}) => {
  const dispatch = useDispatch();

  const handleOpenDropDown = (id) => {
    setIds(id);
  };
  const {t} = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = selectorData.filter(
    (obj) =>
      obj.fullName &&
      obj.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [ids, setIds] = useState("");
  const formatDate = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleDateString("default", {
      month: "short",
    });
    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleDateString("default", { month: "short" });
    const year = startDate.getFullYear();

    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
  };

  const calculateLeaveDuration = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    if (differenceInTime < 0) {
      return 0;
    }
    const differenceInDays =
      Math.floor(differenceInTime / (1000 * 3600 * 24)) + 1;

    return differenceInDays;
  };
  const handleOptionClick = async (option, leaves) => {
    dispatch(handleStatusChange(leaves, ids, option));
    dispatch(handleUpdateWFHStatus(leaves, ids, option));
  };

  return (
    <div>
      <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
        <div
          className="min-w-[40%]
         h-full p-5 bg-white flex flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
        >
          <div className="flex justify-between px-[0.88rem] py-[0.62rem]  border-b border-b-[#E2E8F0]">
            <div>
              <p className="text-[#031B59] text-lg font-extrabold">{title}</p>
            </div>
            <div>
              <RxCross2
                onClick={closeDrawer}
                className="text-[#191919] text-xl"
              />
            </div>
          </div>
          <div
            className="flex
          justify-between px-[0.88rem] py-[0.62rem] border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
          >
            <div className="w-full">
              <input
                type="search"
                placeholder="search"
                className="text-[#A1A1A1] w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div>
              <CiSearch className="text-[#A1A1A1] text-lg" />
            </div>
          </div>
          <div className="h-100 overflow-y-auto">
            {filteredData.length === 0 ? (
              <div className="flex justify-center items-center h-full text-gray-500">
                {t("no_data_found")}
              </div>
            ) : (
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    {headerData.map((item, id) => (
                      <th
                        key={id}
                        className="font-medium
                      sticky top-0 bg-[#F2F6FF] text-[#686868] px-6 py-3 text-[0.81rem] text-left"
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((rowData, id) => (
                    <tr key={id}>
                      <td key={id} className="border-b px-6 py-4">
                        <div className="flex items-center gap-2 ">
                          {" "}
                          <ProfileAvtar name={rowData?.fullName} />
                          <div
                            className="flex flex-col items-start
                          space-y-[2px] cursor-pointer"
                          >
                            {rowData?.fullName
                              ? rowData.fullName
                              : "-"}
                            <span className="text-[#A1A1A1] text-xs">
                              {rowData?.employeeCode}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td key={id} className="border-b px-6 py-4">
                        {rowData.designation ? rowData.designation : "---"}
                      </td>
                      <td key={id} className="border-b px-6 py-4">
                        {rowData?.leavetype ? rowData?.leavetype ?
                          rowData.leavetype.replace(/^\w/, (c) => c.toUpperCase()) : "---"
                          : rowData ? new Date(rowData.createdDate).toLocaleDateString('en-GB',{day: 'numeric',month: 'short',year: 'numeric'}) : "-"}
                      </td>
                      <td className="border-b px-6 py-4">
                        <div className="flex flex-col items-start space-y-[2px]">
                          {rowData?.leavedate
                            ? formatDate(rowData.leavedate, rowData.leavedate2)
                            : "---"}
                          <span className="text-[#A1A1A1] text-xs">
                            {rowData?.leavedate && rowData?.leavedate2
                              ? calculateLeaveDuration(
                                rowData.leavedate,
                                rowData.leavedate2
                              )
                              : "---"}
                            {t("day")}
                          </span>
                        </div>
                      </td>
                      <td
                        key={id}
                        className="border-b px-6 py-4 w-5 h-1 overflow-y-auto"
                      >
                        {rowData?.reason
                          ? rowData.reason : "-"}
                      </td>

                      <td className="border-b px-6 py-4">
                        <div>
                          <StatusPopup
                            leave={rowData}
                            handleOpenDropDown={handleOpenDropDown}
                            handleSelect={handleOptionClick}
                            showDropDown={rowData.id === ids}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardTablePopUp.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  selectorData: PropTypes.array.isRequired,
  headerData: PropTypes.array.isRequired,
};

export default DashboardTablePopUp;
