import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { storeFileData } from "redux/actions/action";

// eslint-disable-next-line react/prop-types
export default function EmployeeCsvDetails({ onBackClick, onConfirmAndNext }) {
  const { t } = useTranslation();
  const fileData = useSelector((state) => state.file.fileData);
  const dispatch = useDispatch();

  const handleInputChange = (index, field, value) => {
    const updatedFileData = fileData.map((item, i) => (i === index ? { ...item, [field]: value } : item));
    dispatch(storeFileData(updatedFileData));

  };
  const handleConfirmNextClick = () => {
    const allFilled = fileData.every(file =>
      file["designation"] && file["contact_no"] && file["date_of_joining"]
    );

    if (!allFilled) {
      toast.error("Please fill all required fields.");

      return;
    }

    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    const isDateValid = fileData.every(file => dateFormat.test(file["date_of_joining"]));

    if (!isDateValid) {
      toast.error("Date format should be DD/MM/YYYY.");

      return;
    }
    onConfirmAndNext();

  };

  return (
    <>
      <div className="w-[100%] h-[calc(100vh-5.1rem)] bg-white space-y-4 max-h-[620px]
      flex flex-col p-5 pb-1 rounded-[4px] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] overflow-y-auto">
        <table className="w-full p-5 bg-white rounded-lg text-left">
          <thead className="border flex-0 text-left p-2">
            <tr className="max-h-[3.125rem] h-[3.125rem] p-2 text-[#686868]
            text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0] ">
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Employee Name")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Employee ID")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Designation")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Contact No")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Date of Joining")}</th>
            </tr>
          </thead>
          <tbody>
            {fileData?.map((file, index) => (

              <tr key={index} className="border">
                <td className="p-2 text-[#031B59]">
                  <input
                    type="text"
                    value={file["full_name"]}
                    onChange={(e) => handleInputChange(index, "full_name", e.target.value)}
                    className="w-full"
                  />
                  <input
                    type="text"
                    value={file["email"]}
                    onChange={(e) => handleInputChange(index, "email", e.target.value)}
                    className="w-full"
                  />
                </td>
                <td className="p-2 text-[#031B59]">
                  <span>{file["employee_id"]}</span>
                </td>
                <td className="p-2 text-[#031B59]">
                  <input
                    type="text"
                    value={file["designation"]}
                    onChange={(e) => handleInputChange(index, "designation", e.target.value)}
                    className="w-full"
                  />
                </td>
                <td className="p-2 text-[#031B59]">
                  <input
                    type="text"
                    value={file["contact_no"]}
                    onChange={(e) => handleInputChange(index, "contact_no", e.target.value)}
                    className="w-full"
                  />
                </td>
                <td className="p-2 text-[#031B59]">
                  <input
                    type="text"
                    value={file["date_of_joining"]}
                    onChange={(e) => handleInputChange(index, "date_of_joining", e.target.value)}
                    className="w-full"
                  />
                </td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>
      <div className="fixed bottom-8 right-12 flex justify-end space-x-3 ">
        <button
          data-testid='backDetBtn-1'
          className="w-[107px] h-[49px] text-[#686868] p-[12px_24px_12px_24px] text-xl "
          onClick={onBackClick}
        >
          {t("back")}
        </button>
        <button data-testid='ConNext-1' type="button" className="text-[#FFFFFF] w-[180px] h-[49px] px-[15px]
        rounded-full border-[1px] bg-[#031B59]" onClick={handleConfirmNextClick}>
          {t("confirm_next")}
        </button>
      </div>
    </>
  );
}
