import { FiSettings, FiUser } from "react-icons/fi";
import { MdDisplaySettings } from "react-icons/md";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { GiReceiveMoney } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import { IoGridOutline } from "react-icons/io5";
import { HiOutlineCalendar } from "react-icons/hi2";
import { BsClipboard2Pulse } from "react-icons/bs";
// import { CgProfile } from "react-icons/cg";
// import { AiOutlinePartition } from "react-icons/ai";
import { HiOutlineUser } from "react-icons/hi";
import { CgBriefcase } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { MdOutlineVerified } from "react-icons/md";
import MyTeamSvg from "svgComponents/MyTeamSvg";

export function SidebarLink() {
  const { t } = useTranslation();
  // const userData = localStorage.getItem("userLoginToken")
  //   ? JSON.parse(localStorage.getItem("userLoginToken"))
  //   : "";
  // const role = userData?.role;

  let sidebarlink = [];
  const isActive = (match) => {
    return match === Location;
  };
  // if (role === "admin") {
  sidebarlink = [
    {
      to: "/dashboard",
      isActive: isActive("dashboard"),
      icon: IoGridOutline,
      text: t("dashboard"),
      permissionKey: "view",
    },
    {
      to: "/employees",
      isActive: isActive("employees"),
      icon: HiOutlineUser,
      text: t("employee"),
      permissionKey: "viewAll",
    },
    {
      to: "/clients",
      isActive: isActive("clients"),
      icon: FiUser,
      text: t("clients"),
      permissionKey: "view",
    },
    {
      to: "/projects",
      isActive: isActive("projects"),
      icon: GoChecklist,
      text: t("projects"),
      permissionKey: "view",
    },
    {
      to: "/salary",
      isActive: isActive("salary"),
      icon: GiReceiveMoney,
      text: t("salary"),
      permissionKey: "viewAll",
    },
    {
      to: "/leave",
      isActive: isActive("leave"),
      icon: HiOutlineCalendar,
      text: t("leaves"),
      permissionKey: "view",
    },
    {
      to: "/verification",
      isActive: isActive("verfication"),
      icon: MdOutlineVerified,
      text: t("verification"),
      permissionKey: "view",
    },
    {
      to: "/myTeams",
      isActive: isActive("my-teams"),
      icon: MyTeamSvg,
      text: t("Teams"),
      permissionKey: "view",
    },
    {
      to: "/timeSheet",
      isActive: isActive("timeSheet"),
      icon: BsClipboard2Pulse,
      text: t("timesheet"),
      permissionKey: "view",
    },
    {
      to: "/access",
      isActive: isActive("access"),
      icon: FiSettings,
      text: t("access"),
      permissionKey: "view",
    },
    {
      to: "/management",
      isActive: isActive("management"),
      icon: MdDisplaySettings,
      text: t("management"),
      permissionKey: "viewAll",
      childHeaderKey: [
        "td",
        "companytax",
        "providentfund",
        "expense",
        "gadget",
      ],
    },
    {
      to: "/invoices",
      isActive: isActive("invoices"),
      icon: LiaFileInvoiceSolid,
      text: t("invoices"),
      permissionKey: "viewAll",
    },
    {
      to: "/departments",
      isActive: isActive("departments"),
      icon: CgBriefcase,
      text: t("department"),
      permissionKey: "viewAll",
    },
    {
      to: "/attendance",
      isActive: isActive("attendance"),
      icon: CgBriefcase,
      text: t("attendance"),
      permissionKey: "viewAll",
    },
    // {
    //   to: `/employees/profilebasics/${userData.id}`,
    //   isActive: isActive("profile"),
    //   icon: CgProfile,
    //   text: t("Profile"),
    //   permissionKey: "view",
    // },
  ];
  // } else if (role === "employee") {
  //   sidebarlink = [
  //     {
  //       to: "/dashboard",
  //       isActive: isActive("dashboard"),
  //       icon: IoGridOutline,
  //       text: t("dashboard"),
  //     },
  //     {
  //       to: "/status",
  //       isActive: isActive("status"),
  //       icon: BsClipboard2Pulse,
  //       text: t("status"),
  //     },
  //     {
  //       to: "/attendance",
  //       isActive: isActive("attendance"),
  //       icon: FiUser,
  //       text: t("attendance"),
  //     },
  //     {
  //       to: "/team",
  //       isActive: isActive("team"),
  //       icon: LuUsers,
  //       text: t("myTeams"),
  //     },
  //     {
  //       to: "/leave",
  //       isActive: isActive("leave"),
  //       icon: HiOutlineCalendar,
  //       text: t("myLeaves"),
  //     },
  //     // {
  //     //   to: "/interview",
  //     //   isActive: isActive("interview"),
  //     //   icon: AiOutlinePartition,
  //     //   text: t("interview"),
  //     // },
  //   ];
  // } else if (role === "client") {
  //   sidebarlink = [
  //     {
  //       to: "/dashboard",
  //       isActive: isActive("dashboard"),
  //       icon: IoGridOutline,
  //       text: t("dashboard"),
  //     },
  //     {
  //       to: "/projects",
  //       isActive: isActive("projects"),
  //       icon: BsClipboard2Pulse,
  //       text: t("projects"),
  //     },
  //     {
  //       to: "/attendance",
  //       isActive: isActive("attendance"),
  //       icon: FiUser,
  //       text: t("attendance"),
  //     },
  //     {
  //       to: "/team",
  //       isActive: isActive("team"),
  //       icon: LuUsers,
  //       text: t("myTeams"),
  //     },
  //     {
  //       to: "/leave",
  //       isActive: isActive("leave"),
  //       icon: HiOutlineCalendar,
  //       text: t("myLeaves"),
  //     },
  //   ];
  // }

  return sidebarlink;
}
