const initialstate = {
  allProjects: [],
  projectId: "",
  projectInfoById: {},
  resourceId: "",
  totalProjectCount: 0,
  allUserProjects: [],
};

export const projectReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "ALL_PROJECTS":
    return { ...state, allProjects: payload };
  case "PROJECT_BY_ID":
    return { ...state, projectInfoById: payload };
  case "SET_PROJECT_ID":
    return { ...state, projectId: payload };
  case "SET_USER_PROJECTS":
    return { ...state, allUserProjects: payload };
  case "SET_RESOURCE_ID":
    return { ...state, resourceId: payload };
  case "SET_TOTAL_PROJECT_COUNT":
    return { ...state, totalProjectCount: payload };
  default:
    return state;
  }
};
