import * as Yup from "yup";

export const addWfhSchema = Yup.object({
  fromDate: Yup.date().required("Date is required!"),
  toDate: Yup.date()
    .required("Date is required!")
    .min(Yup.ref("fromDate"), "To date must be equal or greater than From date!")
    .test(
      "toDate",
      "To date must be strictly be equal or greater than From Date!",
      function (value) {
        const fromDate = new Date(this.resolve(Yup.ref("fromDate")));
        const toDate = new Date(value);

        return toDate >= fromDate;
      }
    ),
  numOfDays: Yup.string().required("Number of Days is required"),
  reason: Yup.string().required("Reason is required"),
  // shift: Yup.string().required("Status is required"),
  fromshift: Yup.string().required("Status is required"),
  toshift: Yup.string().required("Status is required"),
});

export const addLeaveSchema = Yup.object({
  fromDate: Yup.date().required("Date is required!"),
  toDate: Yup.date()
    .required("Date is required!")
    .min(Yup.ref("fromDate"), "To date must be equal or greater than From date!")
    .test(
      "toDate",
      "To date must be strictly be equal or greater than From Date!",
      function (value) {
        const fromDate = new Date(this.resolve(Yup.ref("fromDate")));
        const toDate = new Date(value);

        return toDate >= fromDate;
      }
    ),
  numOfDays: Yup.string().required("Number of Days is required"),
  reason: Yup.string().required("Reason is required"),
  shift: Yup.string().required("Status is required"),
  leaveType: Yup.string().required("Types is required"),
});

export const addRegularize = Yup.object({
  checkIn: Yup.string().required("checkIn is required!"),
  checkOut: Yup.string()
    .required("checkOut is required!"),
  note: Yup.string().required("note is required"),
});
