/* eslint-disable max-len */
import React from "react";

const MyProject1Svg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.01953 10.7781H4.57055V9.22704H3.01953V10.7781ZM3.01953 13.8801H4.57055V12.3291H3.01953V13.8801ZM3.01953 7.67602H4.57055V6.125H3.01953V7.67602ZM6.12157 10.7781H16.9787V9.22704H6.12157V10.7781ZM6.12157 13.8801H16.9787V12.3291H6.12157V13.8801ZM6.12157 6.125V7.67602H16.9787V6.125H6.12157Z"
        fill="#686868"
      />
    </svg>
  );
};

export default MyProject1Svg;
