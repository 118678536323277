import React from "react";
import PropTypes from "prop-types"; // Import PropTypes

export default function Tooltip({ message, className, children }) {
  return (
    <div className={`group relative flex ${className}`}>
      {children}
      <span
        className="absolute top-10 scale-0 transition-all
      rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"
      >
        {message}
      </span>
    </div>
  );
}

// Add PropTypes
Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
