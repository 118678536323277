import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Employeebrief from "../Employee/Employeebrief";
import AddUserPop from "./AddUserPop";
import Popup from "component/common/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  assignRoleToAUser,
  fetchALLPermissions,
  fetchRoleDataWithoutPage,
  showUsersOfSpecificRole
} from "redux/appThunk/Admin/permission";
import Paginate from "../Employee/Paginate";
import { awsURL } from "utils/Constants";
import Search from "../search_comp/Search";
import useDebounce from "hooks/useDebounce";
import LoadSpinner from "component/common/loadingSpinner";

const ManageAccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = window.location.pathname.split("/").pop();

  const [showDialog, setShowDialog] = useState(false);
  const [roleListing, setRoleListing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOption, setUpdateOption] = useState("");
  const [change, isChange] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const { allRoles, isLoading } = useSelector((state) => state.permissionReducer);
  const popUpState = {
    view: false,
    edit: false,
    add: false,
  };
  const [isPopupOpen, setIsPopupOpen] = useState(popUpState);

  const debouncedSearchQuery = useDebounce(searchItem, 1000);

  const handleOptionSelect = (e, item) => {
    setShowPopUp(true);
    const filteredObj = allRoles.find((obj) => obj.id === Number(e.target.value));
    const roleDetail = {
      roleName: filteredObj.name,
      desc: filteredObj.description,
      userId: item?.id,
    };
    setUpdateOption(roleDetail);
  };

  const handleOpenDialogBox = () => {
    setShowDialog(!showDialog);
  };

  const handleSubmit = () => {
    if (updateOption) {
      setShowPopUp(false);
      dispatch(assignRoleToAUser(updateOption?.userId, updateOption?.roleName, isChange, change, handleClose));
    }
  };

  const newSubmit = () => {
    setShowDialog(false);
    dispatch(fetchALLPermissions());
    setIsPopupOpen({
      ...isPopupOpen,
      add: true,
    });
  };

  const handleClose = () => {
    setShowPopUp(false);
  };

  useEffect(() => {
    dispatch(showUsersOfSpecificRole(path, setRoleListing, currentPage, setPageCount, name));
    dispatch(fetchRoleDataWithoutPage());
  }, [currentPage, change, dispatch, path, name]);
  useEffect(() => {
    dispatch(showUsersOfSpecificRole(path, setRoleListing, currentPage, setPageCount, searchItem));
  }, [debouncedSearchQuery]);

  return (
    <div
      className="w-[96%] h-[calc(100vh-5.3rem)] bg-white space-y-4 flex flex-col p-5 pb-1
    rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mt-2 relative overflow-hidden"
    >
      {showPopUp && (
        <Popup
          popupBox={() => setShowPopUp(false)}
          title={t("changeRole")}
          submitBtnText="Update"
          titleClass="pt-[2.5rem] text-[black] font-extrabold text-2xl"
          headerClass="border-none"
          handleSubmit={handleSubmit}
          submitOnce={isLoading}
        />
      )}

      <div className="w-full h-16 bg-white flex justify-between">
        <div className="flex justify-center items-center">
          <span
            className="text-[#031B59] mr-4 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
          </span>
          <h2 className="font-extrabold text-xl text-[#002169] ml-2">
            {t("manageAccess")}
          </h2>
        </div>
        <div className="flex flex-row gap-5">
          <Search searchItem={searchItem} setSearchItem={setSearchItem} />
          {showDialog && (
            <AddUserPop
              handleCloseDialogBox={() => setShowDialog(false)}
              roleName={path}
              handleSubmit={newSubmit}
              setRoleListing={setRoleListing}
              currentPage={currentPage}
              setPageCount={setPageCount}
              onAccess=""
            />
          )}
          <button
            className="h-[3.063rem] w-[7.625rem] p-2 border-[1.5px] border-[#E2E8F0] text-[#031B59] rounded-full"
            onClick={handleOpenDialogBox}
          >
            {t("addUser")}
          </button>
        </div>
      </div>
      {isLoading ?
        <LoadSpinner /> :
        <>
          <div className={`overflow-x-scroll ${isLoading ? "custom_scroller" : "custom_scroll"}`}>
            <table className="w-full p-5 bg-white rounded-lg text-left">
              <thead className="text-left p-2">
                <tr className="max-h-[3.125rem] h-[3.125rem] p-2 text-[#686868] text-sm
             font-normal bg-[#F2F6FF] border border-[#E2E8F0]">
                  <th className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem]
              p-2 max-h-[3.125rem] h-full bg-[#F2F6FF] sticky top-0 z-20 left-0">
                    {t("user")}
                  </th>
                  <th className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem]
              p-2 max-h-[3.125rem] h-full bg-[#F2F6FF] sticky top-0 z-20 left-0">
                    {t("role")}
                  </th>
                </tr>
              </thead>
              {roleListing?.length > 0 && (
                <tbody className="text-sm font-normal">
                  {roleListing?.map((item) => (
                    <tr key={item.id} className="max-h-[4.855rem] even:bg-[#F8FAFC]
                text-[#031B59] border border-[#E2E8F0] md:p-[1rem]">
                      <td className="lg:min-w-[14rem] md:w-[8rem] p-2 md:p-[1rem]">
                        <div className="flex items-center">
                          <Employeebrief userName={item.name} email={item.email} />
                        </div>
                      </td>
                      <td className="lg:min-w-[14rem] md:w-[8rem] p-2">
                        <select
                          value={path}
                          className="bg-white border p-4 outline-none"
                          onChange={(e) => handleOptionSelect(e, item)}
                        >
                          {allRoles.map((data) => (
                            <option key={data.id} value={data.id}
                              className="cursor-pointer px-4 py-2 hover:bg-gray-100">
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {roleListing?.length === 0 && !isLoading && !debouncedSearchQuery && (
                <div className="w-full h-fit flex justify-center">
                  <div className="text-Roboto text-[#A1A1A1] flex justify-center flex-col items-center leading-snug">
                    <div>
                      <img className="h-[18rem] mt-20" src={`${awsURL}/images/staus.jpg`} alt="not found" />
                    </div>
                    <div className="mt-5">
                      <span>{t("RolesNotfound")}</span>
                    </div>
                  </div>
                </div>
              )}
            </table>
          </div>
          <div className="w-full bg-white h-16 flex justify-between items-center">
            {roleListing?.length > 0 ? (
              <>
                <div className="text-[#031B59] font-medium">
                  Showing {currentPage} of {pageCount}
                </div>
                <Paginate
                  currentPage={currentPage}
                  initialPageCount={pageCount}
                  pageRangeDisplayed={5}
                  next=">"
                  previous="<"
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <div
                className="w-full flex items-center
           justify-center font-medium"
              >
                {t("no_data_found")}
              </div>
            )}
          </div>
        </>
      }

    </div>
  );
};

export default ManageAccess;
