/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from "react";
import Chat from "../component/chat";
import GroupList from "component/chat/GroupList";
import { Routes, Route } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import Layout from "../component/layouts/Layout";
import AuthGuard from "../component/authGuard/AuthGuard.js";
import ProfileBasics from "../component/profile/ProfileBasics";
import PersonalInformation from "../component/profile/PersonalInformation";
import OtherInformation from "../component/profile/OtherInformation";
import Documents from "../component/profile/Documents";
import BankDetails from "../component/profile/BankDetails";
import SalaryInformation from "../component/profile/SalaryInformation";
import Management from "../component/admin/Management";
import SalaryDetails from "../component/admin/Salary/SalaryDetails";
import ChangePassword from "../component/profile/ChangePassword";
import Employee from "../pages/Employees";
import TimeSheetList from "../component/admin/TimeSheet/TimeSheetList";
import ShowTimeSheet from "component/timesheet/MyTimesheet";
import CompanyProfile from "../component/admin/Settings/CompanyProfile.jsx";
import Settings from "../component/admin/Settings/Settings.jsx";
import Mail from "../component/mail/Mail.jsx";
import DesignationListing from "component/admin/designation/DesignationListing";
import EmployeCSVfile from "component/admin/Employee/EmployeCSVfile";
import Verfication from "component/admin/Verification/Verfication";
import Version from "component/admin/Settings/Version";
import Login from "pages/auth/Login";
import Forgot from "pages/auth/Forgot";
import CheckMail from "pages/auth/CheckMail";
import OtpVerification from "pages/auth/OtpVerification";
import ResetPassword from "pages/auth/ResetPassword";
import Page404 from "pages/Page404";
import DashboardPage from "pages/private/app/DashboardPage";
import Attendance from "component/employee/attendance";
import Team from "component/employee/team";
import Interview from "component/employee/Interview/Interview";
import ApplyLeave from "component/employee/leave/ApplyLeave";
import TimeSheetForm from "component/employee/Timesheet/TimeSheetForm";
import AccessControl from "component/admin/AccessControl/AccessControl";
import VerficationDetails from "component/admin/Verification/VerificationDetails";
import ManageAccess from "component/admin/AccessControl/ManageAccess";
import FirstChangePassword from "pages/auth/FirstChangePassword";
import MyTeamsPage from "component/admin/MyTeams/MyTeamsPage";
import ProjectsPage from "pages/Projects";
import SalaryPage from "pages/Salary";
import InvoicePage from "pages/Invoices";
import LeavePage from "pages/Leave";
import { LeaveDetails } from "component/leavePanel/LeaveDetails";
import Manage from "component/admin/Settings/Manage";
import TimeSheet from "pages/Timesheet";
import ClientName from "component/Clients/ClientName";
import ClientListing from "component/Clients/ClientListing";
// import Listing2 from "component/admin/TimeSheet/Listing2";
import TimeSheetList2 from "component/admin/TimeSheet/TimeSheetList2";
import DepartmentList from "component/admin/department/DepartmentList";
import MyTimesheet from "component/timesheet/MyTimesheet";
import AddAccessRole from "component/admin/AccessControl/AddAccessRole";
import ViewRolePermission from "component/admin/AccessControl/ViewRolePermission";

export const MainRoutes = () => {
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const unprotectedRoutes2 = [
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/forgot",
      component: Forgot,
      name: "Forgot",
    },
    {
      path: "/checkYourMail",
      component: CheckMail,
      name: "CheckYourMail",
    },
    {
      path: "/otp",
      component: OtpVerification,
      name: "Otp",
    },
    {
      path: "/reset/:token",
      component: ResetPassword,
      name: "ResetPassword",
    },
    {
      path: "404",
      component: Page404,
      name: "404Page",
    },
    {
      path: "*",
      component: Page404,
      name: "Page404",
    },
    {
      path: "/login/:token",
      component: FirstChangePassword,
      name: "FirstChangePassword",
    },
  ];

  const protectedRoutes = [
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: DashboardPage,
      role: null,
    },
    {
      path: "/timeSheet",
      component: TimeSheet,
      name: "Status",
      role: null,
    },
    {
      path: "/timeSheet/view/:id",
      component: MyTimesheet,
      // component: TimeSheetList2,
      name: "view-status",
      role: null,
    },
    {
      path: "/leave",
      component: LeavePage,
      name: "leave",
      role: null,
    },
    {
      path: "/leave/view/:id",
      component: { admin: LeaveDetails },
      name: "view-leave",
      role: ["admin"],
    },

    {
      path: "/attendance",
      component: Attendance,
      name: "Attendance",
      // role: ["employee", "client"],
      role: null,
    },
    {
      path: "/team",
      component: { employee: Team, client: Page404 },
      name: "Team",
      role: ["employee", "client"],
    },
    {
      path: "/interview",
      component: { employee: Interview, client: Page404 },
      name: "Interview",
      role: ["employee", "client", "admin"],
    },
    {
      path: "/myTeams",
      component: { admin: MyTeamsPage, client: Page404 },
      name: "My Teams",
      role: ["admin"],
    },
    {
      path: "/leave/apply",
      component: { employee: ApplyLeave },
      name: "ApplyLeave",
      role: ["employee"],
    },
    {
      path: "/leave/apply/:id",
      component: { employee: ApplyLeave },
      name: "ApplyLeave",
      role: ["employee"],
    },
    {
      path: "/timeSheet/apply",
      component: TimeSheetForm,
      name: "ApplyStatus",
      role: null,
    },
    {
      path: "/email",
      component: { employee: Mail, client: Mail, admin: Mail },
      name: "Email",
      role: ["employee", "client", "admin"],
    },
    {
      path: "/companyProfile",
      component: { admin: CompanyProfile },
      name: "CompanyProfile ",
      role: ["admin"],
    },
    {
      path: "/settings",
      component: { admin: Settings },
      name: "Settings",
      role: ["admin"],
    },
    {
      path: "/settings/manage",
      component: { admin: Manage },
      name: "Settings",
      role: ["admin"],
    },
    {
      path: "/employees/profilebasics/:id",
      name: "EmployeesProfile",
      component: ProfileBasics,
      role: null,
      // role: ["admin"],
    },
    {
      path: "/profileBasics",
      component: { employee: ProfileBasics },
      name: "Profile Basics",
      role: ["employee", "admin"],
    },
    {
      path: "/profileBasics/otherInfo",
      component: { employee: OtherInformation },
      name: "Other Information",
      role: ["employee"],
    },
    {
      path: "/profileBasics/changePassword",
      component: { employee: ChangePassword },
      name: "Change Password",
      role: ["employee"],
    },
    {
      path: "/profileBasics/salaryInfo",
      component: { employee: SalaryInformation },
      name: "Salary Information",
      role: ["employee"],
    },
    {
      path: "/profileBasics/Documents",
      component: { employee: Documents },
      name: "Documents",
      role: ["employee"],
    },
    {
      path: "profileBasics/bankDetails",
      name: "Bank Details",
      component: { employee: BankDetails },
      role: ["employee"],
    },
    {
      path: "/management",
      name: "Management",
      component: Management,
      role: null,
    },
    {
      path: "/departments",
      name: "Department",
      component: DepartmentList,
      role: null,
    },
    {
      path: "/clients/Name/:id",
      name: "ClientInfo",
      component: ClientName,
      role: null,
    },
    {
      path: "/mail",
      component: { employee: Mail, client: Mail, admin: Mail },
      name: "Mail",
      role: ["employee", "client", "admin"],
    },
    {
      path: "/companyProfile",
      component: { admin: CompanyProfile },
      name: "Company Profile ",
      role: ["admin"],
    },
    {
      path: "/settings",
      component: { admin: Settings },
      name: "Settings",
      role: ["admin"],
    },
    {
      path: "/settings/manage",
      component: { admin: Manage },
      name: "Settings",
      role: ["admin"],
    },
    {
      path: "/profileBasics",
      component: { employee: ProfileBasics },
      name: "Profile Basics",
      role: ["employee"],
    },
    {
      path: "/profileBasics/personalInfo",
      component: { employee: PersonalInformation },
      name: "Personal Information",
      role: ["employee"],
    },
    {
      path: "/profileBasics/otherInfo",
      component: { employee: OtherInformation },
      name: "Other Information",
      role: ["employee"],
    },
    {
      path: "/profileBasics/changePassword",
      component: { employee: ChangePassword },
      name: "Change Password",
      role: ["employee"],
    },
    {
      path: "/profileBasics/salaryInfo",
      component: { employee: SalaryInformation },
      name: "Salary Information",
      role: ["employee"],
    },
    {
      path: "/profileBasics/Documents",
      component: { employee: Documents },
      name: "Documents",
      role: ["employee"],
    },
    {
      path: "profileBasics/bankDetails",
      name: "Bank Details",
      component: { employee: BankDetails },
      role: ["employee"],
    },
    {
      path: "/employees",
      name: "Employees",
      component: Employee,
      role: null,
    },
    {
      path: "/clients",
      name: "Clients",
      component: ClientListing,
      role: null,
    },
    {
      path: "/salary",
      name: "Salary",
      component: SalaryPage,
      role: null,
    },
    {
      path: "/salary/salaryDetails/:id",
      name: "Salary Details",
      component: SalaryDetails,
      role: null,
    },
    {
      path: "/projects",
      name: "Projects",
      component: ProjectsPage,
      role: null,
    },
    {
      path: "/management",
      name: "Management",
      component: Management,
      role: null,
    },
    {
      path: "/invoices",
      name: "Invoices",
      component: InvoicePage,
      role: null,
    },
    {
      path: "employees/import-csv",
      name: "EmployeeCSVfile",
      component: EmployeCSVfile,
      role: null,
    },
    {
      path: "/access/viewRole/:id",
      name: "ViewRole",
      component: ViewRolePermission,
      role: null,
    },
    {
      path: "/verification",
      name: "Verfication",
      component: { admin: Verfication },
      role: ["admin"],
    },
    {
      path: "/version",
      name: "Version",
      component: { admin: Version },
      role: ["admin"],
    },
    {
      path: "/access/addRole",
      name: "Version",
      component: AddAccessRole,
      role: null,
    },
    {
      path: "/access",
      name: "access",
      component: { admin: AccessControl },
      role: ["admin"],
    },
    {
      path: "/access/manageaccess/:name",
      name: "manageaccess",
      component: { admin: ManageAccess },
      role: ["admin"],
    },
    {
      path: "/VerficaticationDetails",
      name: "VerificationDetails",
      component: { admin: VerficationDetails },
      role: ["admin"],
    },
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Navigate
              to={userData && userData?.token !== "" ? "/dashboard" : "/login"}
            />
          }
        />
        <Route>
          {unprotectedRoutes2.map((obj, index) => (
            <Route
              key={index}
              path={obj.path}
              name={obj.name}
              Component={obj.component}
            />
          ))}
        </Route>
        <Route element={<Layout />}>
          {protectedRoutes.map((obj, index) => (
            <Route
              key={index}
              path={obj.path}
              name={obj.name}
              element={
                <AuthGuard
                  Component={obj.component}
                  roleList={obj.role}
                  name={obj.name}
                />
              }
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
