import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for type checking
import { AiFillFileImage } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";

const ImageList = ({ file, index, imageLink, onRemove }) => {
  return (
    <li
      key={index}
      className="w-full font-normal flex space-x-3 justify-between items-center
            text-base text-blue-700 break-words"
    >
      <AiFillFileImage className="fill-[#81CEE4] h-[1.5rem]" />
      <a
        href={imageLink}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full h-fit cursor-pointer line-clamp-1 visited:text-purple-700"
      >
        {typeof file === "string" ? file : file.name}
      </a>
      {onRemove && (
        <GrFormClose
          onClick={onRemove}
          className="fill-[#686868] cursor-pointer stroke-[#686868] stroke-[1px] w-6 h-6 p-1 box-content"
        />
      )}
    </li>
  );
};

export default ImageList;

ImageList.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  imageLink: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};
