import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Employeebrief from "../admin/Employee/Employeebrief";
import { useFormik } from "formik";
import { t } from "i18next";
import PropTypes from "prop-types";
import {
  addProjectResources,
  handleSearchUser
} from "redux/appThunk/Admin/client";
import { useDispatch, useSelector } from "react-redux";
import { newResourceSchema } from "redux/validator/admin/client";
import { IoClose } from "react-icons/io5";
import { GrFormClose } from "react-icons/gr";
import DatePicker from "component/common/DatePicker";
import { BsChevronDown } from "react-icons/bs";

const AddProjectResources = ({ setShowResourcesForm, projectId, setGet }) => {
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchEmployeeData } = useSelector((state) => state.ClientsReducer);

  const initialValues = {
    employeeName: "",
    user_id: "",
    designation: "",
    startDate: "",
    rate: "",
    type: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newResourceSchema,
    onSubmit: async (values) => {

      if (isSubmitting) return;
      setIsSubmitting(true);
      dispatch(addProjectResources(values, projectId,setGet,setShowResourcesForm));
      setIsSubmitting(false);
      setShowResourcesForm(false);
    },
  });

  const { values, errors, handleChange, handleBlur, touched, setFieldValue, handleSubmit } = formik;

  const getColorFromEmail = (email) => {
    const hashCode = email.split("").reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff).toString(16).toUpperCase().padStart(6, "0")}`;

    return color;
  };

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setShow(true);
  };

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  const handleEmployeedata1 = (item) => {
    setUserName(item.fullName);
    setShow(false);
    setFieldValue("employeeName", item.fullName || "User");
    setFieldValue("user_id", item.id);
    setFieldValue("designation", item.designation?.designation || "Software Engineer");
  };

  useEffect(() => {
    let debounceTimer;
    if (userName) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        dispatch(handleSearchUser(userName));
      }, 500);
    } else {
      setShow(false);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [userName, dispatch]);

  return (
    <div className="w-full h-[100vh] flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
      <button onClick={() => setShowResourcesForm(false)}>
        <GrFormClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem]" />
      </button>
      <div className="min-w-[40%] h-full p-5 bg-white flex flex-col space-y-1
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#031B59]">{t("addResources")}</h2>
          </div>
          <button onClick={() => setShowResourcesForm(false)}>
            <IoClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem]" />
          </button>
        </div>

        <div className="w-full color-[#191919] space-y-1">
          <div className="relative flex flex-col w-full">
            <label className="font-bold" htmlFor="employeeName">
              {t("name")}
            </label>
            <input
              type="text"
              id="employeeName"
              name="employeeName"
              value={userName}
              className="border text-sm rounded w-full p-2.5"
              onChange={handleUsernameChange}
              required
              onBlur={handleBlur}
              onKeyDown={enterClick}
              autoComplete="off"
            />
            <button type="button" className="absolute right-4 top-6 mt-3">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </button>
            {show && (
              <div className="absolute flex justify-start flex-col top-[4.6rem] w-full
               bg-white group shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px]
               rounded-md z-30 overflow-y-auto max-h-80">
                {searchEmployeeData && searchEmployeeData.map((item, index) => (
                  <div key={index} className="w-full px-2 flex items-center space-x-2 cursor-pointer
                  hover:bg-[#031B59] group rounded" onClick={() => handleEmployeedata1(item)}>
                    <div className="w-full flex flex-col">
                      <p className="text-[#313131] hover:text-white capitalize p-1">
                        <div className="flex items-center">
                          <div className="w-9 h-8 rounded-full flex items-center justify-center
                           text-white bg-[#031B59] mr-2" style={{ backgroundColor: getColorFromEmail(item.email) }}>
                            {item.email?.[0]?.toUpperCase()}
                          </div>
                          <Employeebrief email={item.email} names={item.fullName} />
                        </div>
                      </p>
                    </div>
                  </div>
                ))}
                {errors.employeeName && touched.employeeName ? (
                  <p className="text-[red]">{errors.employeeName}</p>
                ) : null}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="designation" className="text-[#313135] font-medium mb-1">
              {t("designation")}
            </label>
            <input
              id="designation"
              name="designation"
              value={formik.values.designation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded bg-white"
            />
            {errors.designation && touched.designation ? (
              <p className="text-[red]">{errors.designation}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-[#313135] text-base font-medium">{t("date")}</h3>
            <div className="w-full relative">
              <DatePicker
                name="startDate"
                type="date"
                value={formik.values.startDate}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                min={new Date().toISOString().split("T")[0]}
                styles={"h-[2.625rem] w-full text-black"}
              />
            </div>
            {formik.touched.startDate && formik.errors.startDate && (
              <p className="text-red-500">{formik.errors.startDate}</p>
            )}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="rate" className="text-[#313135] font-bold">
              {t("rate")}
            </label>
            <input
              placeholder={t("rate")}
              name="rate"
              value={values.rate}
              onChange={handleChange}
              className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
            />
            {errors.rate && touched.rate ? (
              <p className="text-[red]">{errors.rate}</p>
            ) : null}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="type" className="text-[#313135] font-bold">
              {t("type")}
            </label>
            <div className="relative">
              <select
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] bg-white rounded
                 text-[#191919] appearance-none"
                id="type"
                name="type"
                value={values.type}
                onChange={handleChange}
              >
                <option>{t("selectType")}</option>
                <option value="monthly">{t("monthly")}</option>
                <option value="yearly">{t("yearly")}</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <div className="absolute top-4 right-2">
                  <BsChevronDown className="h-3 w-4 " />
                </div>
              </div>
              {errors.type && touched.type ? (
                <p className="text-[red]">{errors.type}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className=" h-[37rem] flex justify-end items-end gap-2">
          <button
            type="button"
            onClick={() => setShowResourcesForm(false)}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            disabled={isSubmitting}
          >
            {t("cancel")}
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="px-4 py-2 bg-[#031B59] text-[#FFFFFF] rounded-lg"
            disabled={isSubmitting}
          >
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProjectResources;

AddProjectResources.propTypes = {
  setShowResourcesForm: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  setIsLoading: PropTypes.func,
  setGet: PropTypes.bool,
};

