/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { leaveFilterTableDefaultValue } from "utils/Constants";
import { getDateDashFormat } from "utils/date";
import { RiPlayListAddLine } from "react-icons/ri";
import useDebounce from "hooks/useDebounce";
import { setSuccess } from "redux/actions/action";
import DatePicker from "component/common/DatePicker.jsx";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import Paginate from "component/admin/Employee/Paginate";
import { AddButton } from "component/common/accessControlUi/Button";
import AddLeave from "./AddLeaveData";
import FilterColumn from "./FilterColumnData";
import useFetchLeaveData from "./UseFetchLeaveData";
import TeamLeaves from "./TeamLeaves";
import UserAddForm from "./UserAddForm";
import Search from "component/common/accessControlUi/Search";
import CustomTableVariant2 from "component/common/table/CustomTableVariant2";
import StatusPopup from "./StatusPopup";
import { handleStatusChange } from "redux/appThunk/Admin/leave";
import { FaEye } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import MyLeavepopup from "./MyLeavepopup";
import { alignValues, myLeavesColumn } from "./column";
import EditLeaveDetails from "./EditLeaveDetails";
import Cancelpopup from "./Cancelpopup";
import OrganisationLeave from "./OrganisationLeave";

const LeaveTabs = Object.freeze({
  Leaves: "leaves",
  TeamLeaves: "teamLeaves",
  OrganisationLeaves: "organisationleaves",
});

export default function LeaveListing() {
  const getRole = JSON.parse(localStorage.getItem("userLoginToken"));
  const { userPermission } = usePermissions(mappedPermissionObj.Leave);
  const permisionsLeaveType = usePermissions(mappedPermissionObj.Leavetype);
  const { hasPermission } = usePermissions();
  const permisionsLeave = hasPermission(mappedPermissionObj.Leave);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterRef = useRef();
  const filterButtonRef = useRef();
  const itemsPerPage = 10;
  const { success } = useSelector((state) => state.ClientsReducer);
  const { allLeaves, pageCount, isLoading } = useSelector(
    (state) => state.leaveReducer
  );

  let data = allLeaves;
  const [activeTab, setActiveTab] = useState(LeaveTabs.Leaves);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(() =>
    leaveFilterTableDefaultValue.map((data) => data.key)
  );

  const [leaveData, setLeaveData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [addLeavePop, setAddLeavePop] = useState(false);
  const searchName = useDebounce(searchItem, 800);
  const secondPermission = permisionsLeaveType.userPermission.viewAll;
  const [ids, setIds] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openOrganisationPopup, setOpenOrganisationPopup] = useState(false);
  const [datas, setDatas] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownId, setDropdownId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [handlecancelpopup, setHandlecancelpopup] = useState(false);
  const [leave, setLeave] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [statusType, setStatusType] = useState([]);

  const handleOpenDropDown = (id) => {
    setIds(id);
  };

  const leaveDataFilter = [
    { id: 1, title: "Paid Leave" },
    { id: 2, title: "Sick Leave" },
    { id: 3, title: "Casual Leave" },
    { id: 4, title: "Leave Type 1" },
    { id: 5, title: "Leave Type 2" },
  ];

  const statusDataFilter = [
    { id: 1, title: "Pending " },
    { id: 2, title: "Approved" },
    { id: 3, title: "Rejected" },
    { id: 4, title: "Cancellation" },
  ];

  useEffect(() => {
    const handler = (event) => {
      if (
        showFilter &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
    } else {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showFilter]);

  useEffect(() => {
    setLeaveData(allLeaves);
  }, [allLeaves]);

  const handleColumnFilterChange = (e) => {
    const { id, checked } = e.target;
    setFilters((prev) =>
      checked ? [...prev, id] : prev.filter((d) => d !== id)
    );
  };

  const handleApplyFilters = () => {
    const unchangedKeys = ["id", "fullName", "leaveStatus", "email"];
    const filteredLeaves = allLeaves.map((leave) => {
      const filteredLeave = {};
      unchangedKeys.forEach((key) => {
        if (leave.hasOwnProperty(key)) {
          filteredLeave[key] = leave[key];
        }
      });
      filters.forEach((key) => (filteredLeave[key] = leave[key]));

      return filteredLeave;
    });
    setLeaveData(filteredLeaves);
    setShowFilter(false);
  };

  const handleOptionClick = async (option, leaves) => {
    dispatch(handleStatusChange(leaves, ids, option));
  };

  useEffect(() => {
    if (success) {
      dispatch(setSuccess(false));
    }
  }, [success, dispatch]);

  const handleRefresh = () => {
    setFilters(leaveFilterTableDefaultValue.map((data) => data.key));
    setLeaveData(allLeaves);
  };

  const userLoginToken = JSON.parse(localStorage.getItem("userLoginToken"));

  const userId = userLoginToken.id;

  useFetchLeaveData({
    permissionToFetch: userPermission,
    currentPage,
    itemsPerPage,
    userId,
    searchName,
    searchDate,
    activeTab,
    leave_type: leaveType,
    status: statusType,
  });

  const handleAddLeaveClick = () => {
    setAddLeavePop(true);
  };

  let filterdata;

  const handleOpen = (id) => {
    filterdata = allLeaves.find((data) => data.id === id);
    setDatas(filterdata);
    setOpenPopup(true);
    if (dropdownOpen && dropdownId === id) {
      setDropdownOpen(false);
      setDropdownId(null);
    } else {
      setDropdownOpen(true);
      setDropdownId(id);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleDropdown = (id) => {
    filterdata = allLeaves.find((data) => data.id === id);
    setDatas(filterdata);
    if (dropdownOpen && dropdownId === id) {
      setDropdownOpen(false);
      setDropdownId(null);
    } else {
      setDropdownOpen(true);
      setDropdownId(id);
    }
  };

  const handleChangeLeave = (leaveTitle) => {
    setLeaveType((prevLeaveType) => {
      if (prevLeaveType.includes(leaveTitle.split(" ")[0].toLowerCase())) {
        return prevLeaveType.filter(
          (title) =>
            title.split(" ")[0].toLowerCase() !==
            leaveTitle.split(" ")[0].toLowerCase()
        );
      } else {
        return [...prevLeaveType, leaveTitle.split(" ")[0].toLowerCase()];
      }
    });
  };

  const handleChangeStatus = (statusTitle) => {
    setStatusType((prevStatusType) => {
      if (prevStatusType.includes(statusTitle.split(" ")[0].toLowerCase())) {
        return prevStatusType.filter(
          (title) =>
            title.split(" ")[0].toLowerCase() !==
            statusTitle.split(" ")[0].toLowerCase()
        );
      } else {
        return [...prevStatusType, statusTitle.split(" ")[0].toLowerCase()];
      }
    });
  };

  const toggleEditPopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setOpenPopup(false);
  };

  const handleSaveEdit = (editedLeave) => {
    setSelectedLeave(editedLeave);
    setShowEditPopup(false);
  };
  const handleClick = () => {
    setOpenPopup(!openPopup);
  };

  const handlecancel = () => {
    setHandlecancelpopup(!handlecancelpopup);
  };

  const tempData = [
    {
      title: "Status ",
      field: "status",
      align: alignValues.left,
      render: (index, job) => {
        return (
          <>
            <div className="items-start">
              <StatusPopup
                key={index}
                leave={job}
                handleOpenDropDown={handleOpenDropDown}
                handleSelect={handleOptionClick}
                showDropDown={job?.id === ids}
                setPopupTitle={() => {}}
                setPopupBtn={() => {}}
                activeTab={activeTab}
              />
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, job) => {
        return (
          <>
            <div className="items-start">
              {getRole?.role === "admin" ? (
                <div onClick={() => handleOpen(job?.id)}>
                  <FaEye
                    leave={job}
                    handleOpenDropDown={handleOpenDropDown}
                    handleSelect={handleOptionClick}
                    showDropDown={job.id === ids}
                  />
                </div>
              ) : (
                <div
                  onClick={() => toggleDropdown(job?.id)}
                  className="relative"
                >
                  <BsThreeDots
                    leave={job}
                    handleOpenDropDown={handleOpenDropDown}
                    handleSelect={handleOptionClick}
                    showDropDown={job.id === ids}
                  />

                  {dropdownOpen && dropdownId === job?.id && (
                    <ul
                      className="dropdown-menu absolute right-4 top-2 border px-4 py-2 bg-white"
                      onMouseLeave={() => setDropdownOpen(false)}
                    >
                      <li onClick={toggleEditPopup}>
                        <div className="hover:bg-[#F2F6FF] cursor-pointer text-[#191919] font-Roboto py-1">
                          {t("edit")}
                        </div>
                      </li>

                      <li onClick={handleClick}>
                        <div className="hover:bg-[#F2F6FF] cursor-pointer text-[#191919] font-Roboto py-1">
                          {t("view")}
                        </div>
                      </li>

                      <li onClick={handlecancel}>
                        <div className="hover:bg-[#F2F6FF] cursor-pointer text-[#191919] font-Roboto py-1">
                          {t("cancel")}
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              )}
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  return (
    <div className="w-full h-fit overflow-hidden">
      {openPopup && (
        <MyLeavepopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          datas={datas}
        />
      )}

      {showEditPopup && (
        <EditLeaveDetails
          setOpenPopup={setOpenPopup}
          leaveDetails={datas}
          onClose={handleCloseEditPopup}
          onSave={handleSaveEdit}
        />
      )}

      {handlecancelpopup && (
        <Cancelpopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          datas={datas}
          onClosed={handlecancel}
          onSave={handleSaveEdit}
          setHandlecancelpopup={setHandlecancelpopup}
          popupTitle={t("cancelleave")}
        />
      )}
      <div className="w-full mt-6 sticky top-0 z-20">
        {permisionsLeave.viewAll ? (
          <>
            <button
              className={`px-4 transition-colors duration-300 ${
                activeTab === LeaveTabs.Leaves
                  ? "border-b-2 font-bold border-blue-500 text-[#031B59]"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => handleTabClick(LeaveTabs.Leaves)}
              key={"my-leave"}
            >
              {t("myleaves")}
            </button>
            <button
              key={"team-leave"}
              className={`transition-colors duration-300 ${
                activeTab === LeaveTabs.TeamLeaves
                  ? "border-b-2 font-bold border-blue-500 text-[#031B59]"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
              style={{ marginLeft: "20px" }}
              onClick={() => handleTabClick(LeaveTabs.TeamLeaves)}
            >
              {t("teamleaves")}
            </button>
            <button
              key={"organisation-leave"}
              className={`transition-colors duration-300 ${
                activeTab === LeaveTabs.OrganisationLeaves
                  ? "border-b-2 font-bold border-blue-500 text-[#031B59]"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
              style={{ marginLeft: "20px" }}
              onClick={() => handleTabClick(LeaveTabs.OrganisationLeaves)}
            >
              {t("organisationleaves")}
            </button>
          </>
        ) : (
          permisionsLeave.view && (
            <button
              className={`px-4 transition-colors duration-300 ${
                activeTab === LeaveTabs.Leaves
                  ? "border-b-2 border-blue-500 text-blue-900"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => handleTabClick(LeaveTabs.Leaves)}
              key={"my-leave"}
            >
              {t("myleaves")}
            </button>
          )
        )}
      </div>
      {activeTab === LeaveTabs.Leaves && (
        <div className="w-[100%] h-[calc(100vh-9.1rem)] bg-white space-y-4 flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mt-4">
          {addLeavePop &&
            (getRole?.role === "admin" ? (
              <AddLeave setAddLeavePop={setAddLeavePop} />
            ) : (
              <UserAddForm setShowForm={setAddLeavePop} />
            ))}

          <div className="w-full h-16 bg-white flex justify-between sticky top-14 z-10">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("All Leaves")}
              </h2>
            </div>
            <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
              <div>
                <DatePicker
                  name="status_date"
                  value={
                    searchDate === ""
                      ? getDateDashFormat(new Date())
                      : getDateDashFormat(searchDate)
                  }
                  handleChange={(e) => {
                    setSearchDate(e.target.value);
                  }}
                  styles="rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem] md:w-[8.5rem] text-[#A1A1A1]"
                />
              </div>
              <Search
                searchItem={searchItem}
                setSearchItem={setSearchItem}
                currentResource={userPermission}
              />
              <button
                ref={filterButtonRef}
                className="lg:w-[2.688rem] md:w-[2rem] lg:h-[2.688rem] md:h-[2.5rem] relative flex items-center justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
                onClick={() => {
                  setShowFilter((prev) => !prev);
                }}
              >
                <RiPlayListAddLine
                  className="lg:w-6 lg:h-6 stroke-[#031B59]"
                  title="Add Column"
                />
              </button>
              {showFilter && (
                <FilterColumn
                  leaveDatafilter={leaveDataFilter}
                  statusDatafilter={statusDataFilter}
                  setShowFilter={setShowFilter}
                  filterTitleLeave={"Leave"}
                  filterTitleStatus={"Status"}
                  filterRef={filterRef}
                  leaveFilterTableDefaultValue={leaveFilterTableDefaultValue}
                  filters={filters}
                  handleColumnFilterChange={handleColumnFilterChange}
                  handleRefresh={handleRefresh}
                  handleApplyFilters={handleApplyFilters}
                  handleChangeLeave={handleChangeLeave}
                  leaveType={leaveType}
                  handleChangeStatus={handleChangeStatus}
                  statusType={statusType}
                />
              )}

              <AddButton
                currentResource={
                  mappedPermissionObj.Leave && mappedPermissionObj.User
                }
                title={t("Apply Leave")}
                onClick={handleAddLeaveClick}
              />
            </div>
          </div>
          <div
            className={`overflow-x-scroll ${
              isLoading ? "custom_scroller" : "custom_scroll"
            }`}
          >
            {(userPermission?.view || userPermission?.viewAll) && (
              <CustomTableVariant2
                newColumn={["Status", "Action"]}
                columns={[...myLeavesColumn, ...tempData]}
                data={allLeaves}
                isLoading={isLoading}
              />
            )}
          </div>
          {(userPermission?.view || secondPermission) && (
            <div>
              {!isLoading && (
                <div className="w-full h-12 bg-white flex justify-between items-center">
                  {allLeaves?.length > 0 ? (
                    <>
                      <div className="text-[#031B59] font-medium">
                        {t("Showing")} {currentPage} {t("of")} {pageCount}
                      </div>{" "}
                      <Paginate
                        currentPage={currentPage}
                        initialPageCount={pageCount}
                        pageRangeDisplayed={5}
                        next=">"
                        previous="<"
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <div className="w-full flex items-center justify-center font-medium">
                      {t("no_data_found")}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {activeTab === LeaveTabs.TeamLeaves && (
        <TeamLeaves setOpenPopup={setOpenPopup} />
      )}
      {activeTab === LeaveTabs.OrganisationLeaves && (
        <OrganisationLeave
          setOpenOrganisationPopup={setOpenOrganisationPopup}
          activeTab={activeTab}
        />
      )}
    </div>
  );
}
