import React from "react";
// import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import { useParams } from "react-router-dom";
import { addProjectSchema } from "redux/validator/admin/client";
import { getDateDashFormat } from "utils/date";
import ToastServices from "ToastServices";
import DatePicker from "component/common/DatePicker";
import { GrFormClose } from "react-icons/gr";

function AddNewProjects({ setShowAddProject, setReload }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const initialvalues = {
    name: "",
    description: "",
    startDate: getDateDashFormat(new Date()),
    currency: "",
    projectType: "",
    endDate: getDateDashFormat(new Date()),
    link: "",
    billingType: "",
    amount: "",
    userId: "",
    clientId: "",
  };
  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addProjectSchema,
    onSubmit: async (values) => {
      setReload(true);
      const jsonObj = {
        name: values.name,
        description: values.description,
        start_date: values.startDate,
        currency: values.currency,
        end_date: values.endDate,
        billing_type: values.billingType,
        amount: values.amount,
        link: values.link,
        client_id: id,
        assigned_by: id,
      };

      const path = apiUrl.projects;
      const { status } = await Helper.post(jsonObj, path);
      if (status === 201) {
        // dispatch(fetchProjectsDetail(id, setNewLoading));
        ToastServices.showToast({
          message: "Project added successfully !",
          type: "success",
          autoClose: 3000,
        });
        setShowAddProject(false);
        setReload(false);
      }
    },
  });
  const { values, handleChange, handleSubmit, errors, touched, handleBlur } =
    Formik;

  const handleAmount = (e) => {
    const pattern = /[0-9,]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <div
      className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full p-2 bg-white flex justify-between items-center">
          <div className="font-extrabold text-[20px] text-[#031B59]">
            {t("addProject")}
          </div>
          <div>
            <button className="w-fit h-fit">
              <GrFormClose
                className="flex w-[2rem] h-[2rem]"
                onClick={() => setShowAddProject(false)}
              />
            </button>
          </div>
        </div>
        <hr />
        <div
          className="flex flex-col w-full
         space-y-3 h-[85vh] overflow-scroll custom_scroll mt-2"
        >
          <div className="w-full flex flex-col space-y-2">
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("project_name")}
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
              className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center p-4"
            ></input>
            {errors.name && touched.name ? (
              <p className="text-[red]">{errors.name}</p>
            ) : null}
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("project_desc")}
            </label>
            <input
              type="text"
              name="description"
              id="description"
              value={values.description}
              onChange={handleChange}
              className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-4"
            ></input>
            {errors.description && touched.description ? (
              <p className="text-[red]">{errors.description}</p>
            ) : null}
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("link_any")}
            </label>
            <input
              type="text"
              name="link"
              id="link"
              values={values.link}
              onChange={handleChange}
              className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-4
            text-[#1B4FB6]"
            ></input>
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("start_date")}
            </label>
            <div>
              <DatePicker
                name={"startDate"}
                id="startDate"
                value={
                  values?.startDate === ""
                    ? getDateDashFormat(new Date())
                    : getDateDashFormat(values?.startDate)
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                min={new Date().toISOString()?.split("T")[0]}
                styles={"h-[2.625rem] w-full"}
              />
            </div>
            {Formik.errors.startDate && Formik.touched.startDate ? (
              <p className="text-[red]">{Formik.errors.startDate}</p>
            ) : null}

            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("currency")}
            </label>
            <select
              className="h-[3rem] w-full p-2 border border-[#E2E8F0] bg-white rounded text-[#313135]"
              type="text"
              id="currency"
              name="currency"
              value={values.currency}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="dollar">$</option>
              <option value="canadian">C$</option>
              <option value="indian">₹</option>
            </select>
            {errors.currency && touched.currency ? (
              <p className="text-[red]">{errors.currency}</p>
            ) : null}
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("amount")}
            </label>
            <input
              type="text"
              name="amount"
              id="amount"
              value={values.amount}
              onChange={handleChange}
              onKeyPress={handleAmount}
              className="h-[3rem] border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-4"
            ></input>
            {errors.amount && touched.amount ? (
              <p className="text-[red]">{errors.amount}</p>
            ) : null}
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("billing_type")}
            </label>
            <select
              className="h-[3rem] w-full p-2 border border-[#E2E8F0] bg-white rounded text-[#313135]"
              type="text"
              id="billingType"
              name="billingType"
              value={values.billingType}
              onChange={handleChange}
            >
              <option value="">{t("select")}</option>
              <option value="Monthly">{t("monthly")}</option>
              <option value="Yearly">{t("yearly")}</option>
            </select>
            {errors.billingType && touched.billingType ? (
              <p className="text-[red]">{errors.billingType}</p>
            ) : null}
            <label
              htmlFor=""
              className="text-[16px] text-[#313135] font-medium"
            >
              {t("end_date")}
            </label>
            <div>
              <DatePicker
                name={"endDate"}
                id="endDate"
                value={
                  values?.endDate === ""
                    ? getDateDashFormat(new Date())
                    : getDateDashFormat(values?.endDate)
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                min={new Date().toISOString()?.split("T")[0]}
                styles={"h-[2.625rem] w-full"}
              />
            </div>
            {Formik.errors.endDate && Formik.touched.endDate ? (
              <p className="text-[red]">{Formik.errors.endDate}</p>
            ) : null}
            <br />
          </div>
          <div
            className="flex  justify-end items-center gap-[1rem] rounded-br-[2rem]
              border-t-[1px] border-[#E2E8F0] bg-white pt-4  w-full"
          >
            <div className="flex pr-8">
              <button
                onClick={() => setShowAddProject(false)}
                className="flex w-[7.5rem] h-[3.5rem] p-[1rem] justify-center items-center
                    gap-[0.5rem] rounded-[0.5rem]"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                className="flex h-[3.5rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                  rounded-[2.5rem] bg-[#031B59] font-inter text-[1rem] font-bold leading-normal text-white"
                onClick={handleSubmit}
              >
                {t("add")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewProjects;

AddNewProjects.propTypes = {
  handleProjectPage: PropTypes.func,
  setShowAddProject: PropTypes.bool,
  setReload: PropTypes.bool,
};
