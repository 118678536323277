import React, { forwardRef, useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import PropTypes from "prop-types";

const Input = forwardRef(({ inputClass = "", ...inputProps }, ref) => {
  return (
    <input
      className={`h-12 border rounded-full w-full p-4 ${inputClass}`}
      ref={ref}
      {...inputProps}
    />
  );
});

Input.displayName = "Input";

Input.propTypes = {
  inputClass: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  inputClass: "",
};

export default Input;

export const TextField = forwardRef(
  ({ label, labelClass = "", inputClass = "", ...inputProps }, ref) => {
    return (
      <div className="w-full relative flex flex-col space-y-1">
        {label && (
          <label htmlFor={inputProps.id} className={labelClass}>
            {label}
          </label>
        )}
        <Input ref={ref} inputClass={inputClass} {...inputProps} />
      </div>
    );
  }
);

TextField.displayName = "TextField";

TextField.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
TextField.defaultProps = {
  labelClass: "",
  inputClass: "",
};

export const PasswordField = forwardRef(
  ({ label, labelClass = "", inputClass = "", ...inputProps }, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
      <div className="w-full relative flex flex-col space-y-1">
        <TextField
          ref={ref}
          label={label}
          labelClass={labelClass}
          inputClass={inputClass}
          type={isVisible ? "text" : "password"}
          {...inputProps}
        />
        {isVisible ? (
          <BsEyeFill
            data-testid={
              inputProps["data-testid"] || "toggle-password-visibility"
            }
            className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
            onClick={toggleVisibility}
          />
        ) : (
          <BsEyeSlashFill
            className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
            onClick={toggleVisibility}
          />
        )}
      </div>
    );
  }
);

PasswordField.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  "data-testid": PropTypes.string,
};

PasswordField.defaultProps = {
  labelClass: "",
  inputClass: "",
};
PasswordField.displayName = "PasswordField";
