/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { handleAddLeave, updateLeaveDetails } from "redux/appThunk/Admin/leave";

import * as Yup from "yup";
import { useFormik } from 'formik';
import DatePicker from 'component/common/DatePicker';
import { getDateDashFormat } from 'utils/date';
import { useTranslation } from 'react-i18next';

const UserAddForm = ({ newVal, setShowForm, editData }) => {
  const dispatch = useDispatch();
  const handleCloseForm = () => {
    setShowForm(false);
  };

  const {t} = useTranslation();

  const { newName } = useSelector((state) => state.leaveReducer);
  const editedLesave = editData;
  const [uploadedFiles, setUploadedFiles] = useState(editData ? editData?.imagesUrl ? editData?.imagesUrl : [] : []);
  const [numOfDays, setNumOfDays] = useState(0);

  const handleFileUpload = (e) => {
    const newFilesArray = Array.from(e.target.files);

    setUploadedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
  };

  const initialvalues = {
    fromDate: editData ? editData?.fromDate : "",
    toDate: editData ? editData?.toDate : "",
    fromShift: editData ? editData?.fromTime : "",
    toShift: editData ? editData?.toTime : "",
    leaveType: editData ? editData?.leaveType : "",
    reason: editData ? editData?.reason : "",
    numOfDays: editData ? editData?.consumedLeave : "",
  };

  const validationSchema1 = Yup.object({
    fromDate: Yup.date().required("fromDate is required!"),
    toDate: Yup.date().required("To Date is required!"),
    fromShift: Yup.string().required("From Shift is required!"),
    toShift: Yup.string().required("To Shift is required!"),
    leaveType: Yup.string().required("Leave Type is required!"),
    reason: Yup.string().required("reason is required!"),
  });

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: validationSchema1,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("leave[leave_type]", values.leaveType);
      formData.append("leave[from_date]", values.fromDate);
      formData.append("leave[to_date]", values.toDate);
      formData.append("leave[reason]", values.reason);
      formData.append("leave[consumed_leave]", numOfDays);
      formData.append("leave[from_time]", values.fromShift);
      formData.append("leave[to_time]", values.toShift);
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append('leave[images][]', uploadedFiles[i]);
      }
      if (editData) {
        dispatch(updateLeaveDetails(formData, editedLesave, newName));
      } else {
        dispatch(handleAddLeave(formData));
      }
      setShowForm(false);
    },
  });

  const { errors, touched, handleSubmit, values, handleChange, handleBlur } = Formik;

  useEffect(() => {
    setNumOfDays(calculateDays(values.fromDate, values.toDate, values.fromShift, values.toShift));
  }, [values.fromDate, values.toDate, values.fromShift, values.toShift]);

  const calculateDays = (fromDate, toDate, fromShift, toShift) => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      let diffDays = (to - from) / (1000 * 60 * 60 * 24);

      if (diffDays === 0) {
        if (fromShift === 'First Half' && toShift === 'First Half') {
          return 0.5;
        } else if (fromShift === 'Second Half' && toShift === 'Second Half') {
          return 0.5;
        } else {
          return 1;
        }
      } else {
        diffDays += 1;
        if (fromShift === 'Second Half') {
          diffDays -= 0.5;
        }
        if (toShift === 'First Half') {
          diffDays -= 0.5;
        }

        return diffDays > 0 ? diffDays : 0;
      }
    }

    return 0;
  };

  return (
    <form className="fixed inset-y-0 right-0 w-1/3 h-full bg-white p-5 shadow-lg flex flex-col z-50 overflow-y-auto" onSubmit={handleSubmit}>
      <div className="flex justify-between items-center mb-4">
        <div className="w-[480px] text-blue-950 text-xl font-extrabold font-['Roboto'] leading-normal">
          {editData ? (newVal ? "View Leave" : "Edit Leave") : "Apply Leave"}
        </div>
        <button className="text-black-500" onClick={handleCloseForm}>
          <IoClose size={24} />
        </button>
      </div>
      <div className="space-y-4">
        <div className="w-full">
          <label className="text-zinc-800 text-base font-medium font-['Roboto'] leading-[15px]">From Date</label>
          <div>
            <DatePicker
              name={"fromDate"}
              id="fromDate"
              value={getDateDashFormat(values?.fromDate)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              min={new Date().toISOString()?.split("T")[0]}
              styles={"h-[2.625rem] w-full "}
            />
          </div>
          {errors.fromDate && touched.fromDate ? (
            <p className="text-[red]">{errors.fromDate}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">{t('fromShift')}</label>
          <select
            name='fromShift'
            value={values.fromShift}
            disabled={newVal}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select~</option>
            <option value="First Half">{t('firstHalf')}</option>
            <option value="Second Half">{t('secondHalf')}</option>
          </select>
          {errors.fromShift && touched.fromShift ? (
            <p className="text-[red]">{errors.fromShift}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">To Date</label>
          <div>
            <DatePicker
              name={"toDate"}
              id="toDate"
              value={
                values?.toDate === ""
                  ? getDateDashFormat(new Date())
                  : getDateDashFormat(values?.toDate)

              }
              handleChange={handleChange}
              handleBlur={handleBlur}
              min={new Date().toISOString()?.split("T")[0]}
              styles={"h-[2.625rem] w-full"}
            />
          </div>
          {errors.toDate && touched.toDate ? (
            <p className="text-[red]">{errors.toDate}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">{t('toShift')}</label>
          <select
            name='toShift'
            value={values.toShift}
            disabled={newVal}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">{t('select')}~</option>
            <option value="First Half">{t('firstHalf')}</option>
            <option value="Second Half">{t('secondHalf')}</option>
          </select>
          {errors.toShift && touched.toShift ? (
            <p className="text-[red]">{errors.toShift}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">{t('leaveType')}</label>
          <select
            name='leaveType'
            value={values.leaveType}
            onChange={handleChange}
            disabled={newVal}
            onBlur={handleBlur}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">{t('selectLeaveType')}</option>
            <option value="Paid">{t('paid')}</option>
            <option value="bereavement">{t('bereavementLeave')}</option>
            <option value="paternity">{t('paternityLeave')}</option>
            <option value="marriage">{t('marriageLeave')}</option>
            <option value="compensatory">{t('compensatoryLeave')}</option>
            <option value="maternity">{t('maternityLeave')}</option>
            <option value="sick">{t('sick')}</option>
            <option value="privilege">{t('privilegeleave')}</option>
            <option value="casual">{t('casual')}</option>
          </select>
          {errors.leaveType && touched.leaveType ? (
            <p className="text-[red]">{errors.leaveType}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">{t('numberOfDays')}</label>
          <input
            name='numOfDays'
            type='text'
            value={numOfDays}
            readOnly
            className="w-full p-2 border border-gray-300 rounded bg-gray-100"
          />
        </div>
        <div className="w-full">
          <label className="block text-sm font-bold mb-2">{t('reason')}</label>
          <textarea
            name='reason'
            value={values.reason}
            readOnly={newVal}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter your reason here.."
            className="w-full p-2 border border-gray-300 rounded"
          />
          {errors.reason && touched.reason ? (
            <p className="text-[red]">{errors.reason}</p>
          ) : null}
        </div>
        <div className="w-full">
          <label htmlFor='FileInputs' className={`py-[6px] px-[10px] cursor-pointer border-solid border flex flex-row items-center gap-[8px]`}>Upload Images</label>
          {
            newVal ? editData?.imagesUrl : (
              <input
                id='FileInputs'
                readOnly={newVal}
                className="hidden"
                type="file"
                multiple
                onChange={handleFileUpload}

              />
            )
          }
          {
            newVal ? null : (
              <div className="mt-2 flex flex-wrap gap-2">
                {uploadedFiles?.map((file, index) => (
                  <div key={index} className="flex items-center space-x-2 border border-gray-300 rounded p-2">
                    <span>{`image-${index + 1}`}</span>
                    <button
                      className="text-red-500"
                      onClick={() => setUploadedFiles(uploadedFiles.filter((_, i) => i !== index))}
                    >
                      <IoClose />
                    </button>
                  </div>
                ))}
              </div>
            )
          }
        </div>
        {newVal ? null : (
          <div className="flex justify-end space-x-4 mt-4">
            <button
              className="bg-gray-200 p-2 rounded text-black"
              onClick={handleCloseForm}
            >
              {t('cancel')}
            </button>
            <button className="bg-blue-500 p-2 rounded text-white" type="submit" onClick={handleSubmit}>{t('apply')}</button>
          </div>
        )}
      </div>
    </form>
  );
};

UserAddForm.propTypes = {
  setShowForm: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  newVal: PropTypes.func.isRequired,
};

export default UserAddForm;
