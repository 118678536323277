import React from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

const SVGVerified = () => {
  const { t } = useTranslation();

  return (
    <div className="w-fit p-1.5 bg-[#1a8718] rounded-2xl justify-start items-center gap-2 inline-flex">
      <FaRegCircleCheck className=' text-white'/>
      <div className="text-right text-white text-xs font-normal font-['Roboto']">{t("Verified")}</div>
    </div>
  );
};

export default SVGVerified;
