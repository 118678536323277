/* eslint-disable max-len */
import React from "react";

const Email = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6654 3.9974C14.6654 3.26406 14.0654 2.66406 13.332 2.66406H2.66536C1.93203 2.66406 1.33203 3.26406 1.33203 3.9974V11.9974C1.33203 12.7307 1.93203 13.3307 2.66536 13.3307H13.332C14.0654 13.3307 14.6654 12.7307 14.6654 11.9974V3.9974ZM13.332 3.9974L7.9987 7.32406L2.66536 3.9974H13.332ZM13.332 11.9974H2.66536V5.33073L7.9987 8.66406L13.332 5.33073V11.9974Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};

export default Email;
