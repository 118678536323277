import PropTypes from 'prop-types';
import React from 'react';

const ShimmerName = ({ height = "1.8rem", width = "5rem" }) => {
  return (
    <div className={`h-[${height}] w-[${width}] animate-pulse bg-slate-200 rounded-lg`}>
    </div>
  );
};

export default ShimmerName;

ShimmerName.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
