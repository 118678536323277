/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import DashboardAdmin from "component/dashboard/DashboardAdmin";
// import DashboardEmployee from "component/dashboard/DashboardEmployee";

export default function DashboardPage({ role }) {
  // if (!role) return null;
  // if (role === "admin") return <DashboardAdmin />;
  // if (role === "client") return <DashboardAdmin />;
  // if (role === "employee") return <DashboardEmployee />;
  // // if (role === "employee") return <DashboardAdmin />;
  // code commented to check for individual component, removed later
  return <DashboardAdmin />;
}

DashboardPage.propTypes = {
  role: PropTypes.string,
};
