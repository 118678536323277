import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function InviteInfo({
  accordionOpen,
}) {
  const users = useSelector((state) => state.profileReducer.profileData);
  const time =
    users?.last_invited !== null
      ? new Date(users?.last_invited).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      : "- - -";
  const date =
    users?.last_invited !== null
      ? new Date(users?.last_invited).toLocaleDateString()
      : "- - -";
  const userLoginTime = new Date(users?.log_in_time).toLocaleTimeString();

  return (
    <div>
      <div>
        {accordionOpen === 8 && (
          <div className="h-[12rem] overflow-y-auto m-[0.8rem] p-3 bg-[#f2f6ff]">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label className="text-[1rem] text-[grey] ">Invited By</label>
                <h1 className="text-[1rem]">
                  {users?.invited_by === null ? "- - -" : users?.invited_by}
                </h1>
              </div>
              <div>
                <label className="text-[1rem] text-[grey]">
                    Invited Date
                </label>

                <h1 className="text-[1rem]">{date}</h1>
              </div>
              <div>
                <label className="text-[1rem] text-[grey]">
                    Invite Status
                </label>
                <h1 className="text-[1rem]"> { users?.log_in_time === null ? "Not Accepted" : "Accepted" }</h1>
              </div>
              <div>
                <label className="text-[1rem] text-[grey]">
                    Invited Accept Time
                </label>
                <h1 className="text-[1rem]">  { users?.log_in_time === null ? "- - -" : userLoginTime }</h1>
              </div>
              <div>
                <label className="text-[1rem] text-[grey]">
                    Last Invited
                </label>
                <h1 className="text-[1rem]">{time}</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

InviteInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
};
