import React from "react";
import { BsStopwatch } from "react-icons/bs";
import { CiDollar } from "react-icons/ci";
import { TbCurrencyDollar } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { awsURL } from "utils/Constants";

const IndividualProjects = () => {
  const navigate = useNavigate();

  return (
    <div className="w-[96%] h-fit flex flex-wrap items-start content-start"
      onClick={() => navigate("/clients/projectListing")}
    >
      {/* <div className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-1"> */}
      <div
        className="w-[537px] h-[196px] bg-[#FFF]
                gap-x-[19px] flex items-center
                  border border-[#F2F2F2]"
      >
        <div className="w-[23px] h-[225px] mt-[15px]">
          <img src={`${awsURL}/images/Vector+99.jpg`} alt="frame" />
        </div>
        <div className="w-[485px] h-[158px] gap-[18px] flex flex-col items-start">
          <div className="w-[485px] h-[107px] flex flex-col items-start gap-[18px]">
            <div className="flex gap-[80px] w-[485px] h-[41px] items-center">
              <div className="w-[298px] h-[23px] text-[#191919] text-xl font-bold leading-normal">
                Project 1
                <div className="w-[85px] h-[14px] text-[#FF7914] text-sm font-normal">
                  11 Sep 2024
                </div>
              </div>
              <div
                className="flex p-[4px_8px] gap-[8px] bg-[#EBFFEB] rounded-[5px] items-center justify-center
            w-[84px] h-[30px]"
              >
                <div className="w-[68px] h-[22px] text-[#1A8718] text-sm font-normal leading-6">
                  Completed
                </div>
              </div>
            </div>
            <div className="[474px] h-[48px] text-[#707070] text-sm font-normal mt-[25px]">
              Lorem ipsum dolor sit amet consectetur. Sit arcu felis suscipit
              mollis interdum habitasse massa. Sem amet scelerisque lorem amet.
              Tristique sed eros interdum nunc. Egestas in sit aliquam hendrerit
              eget sodales lect
            </div>
            <div className="w-[485px] h-[33px] flex items-center gap[21px]">
              <div className="w-[299px] h-[17px] flex items-start gap-[20px]">
                <div className="w-[111px] h-[17px] flex items-center gap-[5px]">
                  <div className="w-[17px] h-[17px] text-[#FF7914]">
                    <CiDollar />
                  </div>
                  <div className="w-[46px] h-[14px] text-[#4E4E4E] text-xs font-normal">
                    Amount
                  </div>
                  <div className="w-[38px] h-[14px] text-[#4E4E4E] text-xs font-medium flex">
                    <TbCurrencyDollar /> <span>5000</span>
                  </div>
                </div>
                <div className="w-[127px] h-[17px] flex items-center gap-[5px]">
                  <div className="w-[17px] h-[17px] text-[#FF7914]">
                    <BsStopwatch />
                  </div>
                  <div className="w-[64px] h-[14px] text[#4E4E4E] text-xs font-normal">
                    Billing Type:
                  </div>
                  <div className="w-[36px] h-[14px] text-[#4E4E4E] text-xs font-medium">
                    Hourly
                  </div>
                </div>
              </div>
              <div
                className="w-[165px] h-[33px] flex p-[4px_8px_4px_4px] items-center gap-[4px]
            rounded-[30px] border-[#FFEADB]"
              >
                <div className="w-[49px] h-[25px] flex items-center gap-[-17px]">
                  <div className="w-[25px] h-25px] rounded-[25px] border-[#F2F6FF] text-[#FF7914]">
                    <img src={`${awsURL}/images/Ellipse+295.jpg`} alt="frame" />
                  </div>
                </div>
                <div className="w-[100px] h-[14px] text-[#686868] text-xs font-normal">
                  +3 more resources
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default IndividualProjects;
