import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleLeaveSearchData } from "redux/appThunk/Admin/leave";

const useFetchLeaveData = ({ permissionToFetch, userId, userIdAction, activeTab,
  currentPage, itemsPerPage, searchName, searchDate, status, leave_type }) => {

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchAllLeaveData = () => {
      if (permissionToFetch.viewAll || permissionToFetch.view) {
        if (searchName) {
          dispatch(handleLeaveSearchData(currentPage, itemsPerPage, searchName, searchDate));
        } else if (searchDate) {
          dispatch(handleLeaveSearchData({currentPage, itemsPerPage, searchName, searchDate}));
        } else if (userId) {
          dispatch(handleLeaveSearchData({ currentPage, itemsPerPage, userId, leave_type, status }));
        }
        else if (userIdAction) {
          dispatch(handleLeaveSearchData(currentPage, itemsPerPage, userIdAction));
        }
        else {
          dispatch(handleLeaveSearchData(currentPage, itemsPerPage, "", ""));
        }
      }
    };
    fetchAllLeaveData();
  }, [currentPage, itemsPerPage, searchName, searchDate, userId, activeTab, status, leave_type]);

};

export default useFetchLeaveData;

