import Helper from 'api/Helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import ToastServices from 'ToastServices';

const Assets = ( {ids}) => {

  const { t } = useTranslation();
  const loginToken = localStorage.getItem('userLoginToken');
  const userId = JSON.parse(loginToken).id;
  const [manageId, setManageId] = useState(false);
  const [allData , setAllData] = useState('');

  const fetchAssetsData = async (id) => {
    try {
      const path = `/gadgets/user_gadget?id=${id}`;
      const{ response} = await Helper.get(path);
      setAllData(response);
    } catch (error) {
      ToastServices({
        message: 'Not Fetching Asset Data',
        type: ' error ' ,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (ids === 5) {
      setManageId(true);
    }
  }, [ids]);

  useEffect(() => {
    if (manageId) {
      fetchAssetsData(userId);
    }
  }, [manageId, userId]);

  return (
    <div className='w-[100%] max-h-[33rem] overflow-x-auto'>
      <table className="table-auto w-full mt-6">
        <thead className='h-11 bg-blue-50 text-gray-500'>
          <tr>
            <th >
              <div className='flex justify-start'>
                {t("assetId")}
              </div>
            </th>
            <th>
              <div className='flex justify-start'>
                {t("assetName")}
              </div>
            </th>
            <th >
              <div className='flex justify-start'>
                {t("assignedBy")}
              </div>
            </th>
            <th >
              <div className='flex justify-start'>
                {t("assignedDate")}
              </div>
            </th>
            <th >
              <div className='flex justify-start'>
                {t("assetSerialNo")}
              </div>
            </th>
            <th >
              <div className='flex justify-start'>
                {t("desc")}
              </div>
            </th>
            <th >

              <div className='flex justify-start'>
                {t("images")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {allData?.length === 0 ? (
            <tr>
              <td colSpan="7" className='text-blue-900 text-lg text-center h-[10rem]'>No Data Found</td>
            </tr>
          ) : (
            allData?.map((item) => (
              <tr className='text-blue-900 text-lg border-y  h-12 ' key={item?.id}>
                <td>{item?.id}</td>
                <td>{item?.gadget_name}</td>
                <td>{item?.assigned_by.name}</td>
                <td>{item?.assigned_date}</td>
                <td>{item?.serial_no}</td>
                <td>{item?.configuration}</td>
                <td>
                  {item?.gadget_images && item.gadget_images.length > 0 ? (
                    <img src={item.gadget_images[0].url} alt="Gadget" />
                  ) : (
                    "No Image Available"
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>

      </table>
    </div>
  );
};

export default Assets;

Assets.propTypes = {
  ids: PropTypes.any,
};
