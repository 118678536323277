export const formatProjectData = (response) => {
  const requiredFormat = [];
  response?.map((res) => {
    return requiredFormat?.push({
      project: {
        id: res?.project?.id,
        name: res?.project?.name,
        description: res?.project?.description,
        domain: res?.project?.domain,
        startDate: res?.project?.start_date,
        rate: res?.project?.rate,
        currency: res?.project?.currency,
        projectType: res?.project?.project_type,
        assignedTo: res?.project?.assigned_to,
        assignedBy: res?.project?.assigned_by,
        billable: res?.project?.billable,
        userId: res?.project?.user_id,
        status: res?.project?.status,
        endDate: res?.project?.end_date,
        billingType: res?.project?.billing_type,
        clientId: res?.project?.client_id,
        amount: res?.project?.amount,
        link: res?.project?.link,
      },
      resources: res.projectResources,
      clientDetail: {
        id: res?.clientInfo?.id,
        name: res?.clientInfo?.full_name,
        employeeId: res?.clientInfo?.employee_id,
        email: res?.clientInfo?.email,
        clientProfile: res?.clientInfo?.profile_picture_url,
      },
    });
  });

  return requiredFormat;
};

export const formatProjectDataById = (response) => {
  const requiredFormat = {
    name: response?.project?.name,
    description: response?.project?.description,
    startDate: response?.project?.start_date,
    endDate: response?.project?.end_date,
    clientName: response?.clientInfo?.full_name,
    clientId: response?.project?.client_id,
    currency: response?.project?.currency,
    amount: response?.project?.amount,
    status: response?.project?.status,
    billingType: response?.project?.billing_type,
    resources: response?.resources?.map((obj) => ({
      id: obj?.id,
      name: obj?.employeeName,
      userId: obj?.userId,
    })),
  };

  return requiredFormat;
};

export const formatAddProjectData = (values) => {
  const requiredFormat = {
    name: values?.name,
    description: values?.description,
    start_date: values?.startDate,
    currency: values?.currency,
    end_date: values?.endDate,
    billing_type: values?.billingType,
    amount: values?.amount,
    client_id: values?.clientId,
    status: values?.status,
  };

  return requiredFormat;
};

export const formatAssignProjectData = (values) => {
  const requiredFormat = {
    user_ids: values,
  };

  return requiredFormat;
};

export const formatProjectsById = (response) => {
  const requiredFormat = [];
  response?.project?.map((obj) =>
    requiredFormat.push({
      id: obj?.id,
      name: obj?.name,
    })
  );

  return requiredFormat;
};

export const formatProjectId = (values,newData) => {
  const requiredFormat = [];
  values?.map((obj) => requiredFormat.push(obj?.id));

  return {
    project_ids: requiredFormat,
    user_ids: newData,
  };
};
