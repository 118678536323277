import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "svgComponents/myTeam/Dropdown";
import PropTypes from 'prop-types';

const LocationDropdown = ({ selectedLocations, onLocationChange, clearFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedLocations);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const locations = ["Select All", "Banglore", "Indore", "Jaipur", "Pune"];

  useEffect(() => {
    if (clearFilter) {
      setSelected([]);
    }
  }, [clearFilter]);

  useEffect(() => {
    onLocationChange(selected);
  }, [selected, onLocationChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (location) => {
    if (location === "Select All") {
      if (selected.length === locations.length - 1) {
        setSelected([]);
      } else {
        setSelected(locations.filter((loc) => loc !== "Select All"));
      }
    } else {
      setSelected((prevSelected) =>
        prevSelected.includes(location)
          ? prevSelected.filter((loc) => loc !== location)
          : [...prevSelected, location]
      );
    }
  };

  const isSelectAllChecked = selected.length === locations.length - 1;

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="inline-flex justify-center w-full rounded-md shadow-sm
        px-4 py-2 bg-white text-sm font-medium text-gray-700
         hover:bg-gray-50 focus:outline-none"
        onClick={handleToggle}
      >
        {t("location")}
        <Dropdown />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {locations.map((location) => (
              <div key={location} className="flex items-center px-4 py-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={
                    location === "Select All"
                      ? isSelectAllChecked
                      : selected.includes(location)
                  }
                  onChange={() => handleCheckboxChange(location)}
                />
                <label className="ml-2 block text-sm text-gray-700">
                  {location}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

LocationDropdown.propTypes = {
  selectedLocations: PropTypes.array.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  clearFilter: PropTypes.bool.isRequired,
};

export default LocationDropdown;
