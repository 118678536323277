import React from "react";

import DashboardRevenueGraph from "./DashboardRevenueGraph";
import DashboardTotalClients from "./DashboardTotalClients";

const AdminGraphContainer = () => {
  return (
    <div className="w-[100%] flex justify-center ">
      <div
        className="h-full w-full grid grid-cols-2
      grid-flow-dense xl:grid xl:grid-cols-3 gap-8
     text-white rounded-[20px]"
      >
        <DashboardRevenueGraph />
        <DashboardTotalClients />
      </div>
    </div>
  );
};

export default AdminGraphContainer;
