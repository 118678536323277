import React from "react";
import { TiArrowBackOutline, TiArrowForwardOutline } from "react-icons/ti";
import { HiDotsHorizontal } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import Employeebrief from "component/admin/Employee/Employeebrief";
import { MdChevronLeft } from "react-icons/md";
import { awsURL } from "utils/Constants";

export default function MailView() {
  return (
    <div
      className="w-full h-[42.5rem] p-5 flex flex-col bg-white gap-[20px] rounded-[20px]
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      {/* Sender Info and other Informations */}
      <div className="w-full flex items-center justify-between">
        <div className="w-full flex justify-start space-x-2">
          <Employeebrief />
          <div className="w-full flex items-end pb-[2px]">
            <button>
              <MdChevronLeft className="-rotate-90 fill-[#A1A1A1] w-[18px] h-[18px]" />
            </button>
          </div>
        </div>
        <div className="w-full flex justify-end items-center space-x-[10px] text-[#A1A1A1]">
          <div className="border-r-[2px] px-2">
            <p>20 June 2023, 09:16 AM</p>
          </div>
          <div className="pl-[10px] flex space-x-[10px]">
            <button>
              {" "}
              <TiArrowBackOutline className="h-5 w-5" />
            </button>
            <button>
              {" "}
              <TiArrowForwardOutline className="h-5 w-5" />
            </button>
            <button>
              {" "}
              <HiDotsHorizontal className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      {/* Subject heading of mail */}
      <div className="w-full text-[#191919] font-semibold">
        <h5>Loreum ipsum dolor sit amet it consecutor</h5>
      </div>
      {/* message space */}
      <div className="w-full text-[#A1A1A1]">
        <p>
          Hi, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum. Yours sincerely
          abc
        </p>
      </div>
      {/* Attachment: add conditions */}
      <div className="w-full flex flex-col gap-[15px] text-[#191919] font-semibold">
        <h6>Attachments</h6>
        <div
          className="w-[12.875rem] h-[9.438rem] p-0 flex flex-col items-center justify-between space-y-[10px]
         bg-white rounded-lg shadow-[0px_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div className="h-[5.188rem] w-[11.625rem] mt-[10px] bg-[#E2E8F0] rounded-lg">
            <img className="p-[10px]" src="" alt="" />
          </div>
          <div className="w-full h-[3rem] m-0 flex justify-between items-center p-5 gap-2 bg-[#F2F6FF]">
            <img src={`${awsURL}/images/pdf_file.jpg`} alt="file icon" />
            <p className=" w-full justify-start text-[#031B59] text-xs">
              File Name
            </p>
            <FiDownload className="h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  );
}
