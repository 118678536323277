import React, { useState } from "react";
import { IoFilter } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { isShowDialogBoxChange } from "../../redux/actions/action";
import { useTranslation } from "react-i18next";
import { filterClients } from "utils/Constants";
import Paginate from "../admin/Employee/Paginate";
import Search from "component/common/accessControlUi/Search";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { AddButton } from "component/common/accessControlUi/Button";
import AddClient from "./AddClient";
import ClientTable from "./ClientTable";
import FilterClient from "./FilterClient";
import UseFetchClientData from "./UseFetchClientData";
import ToastServices from "ToastServices";

export default function ClientListing() {
  const { userPermission } = usePermissions(mappedPermissionObj.Client);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [enable, setEnable] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [onSpotChange, setOnSpotChange] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(false);
  const [clientActive, setClientActive] = useState(false);
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const { clientsData, isLoading } = useSelector(
    (state) => state.ClientsReducer
  );
  const { pageCount } = useSelector((state) => state.leaveReducer);
  const [selectedFilter, setSelectedFilter] = useState(filterClients[0]);
  const [clientType, setClientType] = useState(filterClients[0].header);
  const [openOptions, setOpenOptions] = useState(false);
  const [addClientPopUp, setAddClientPopUp] = useState(false);

  UseFetchClientData({
    permissionToFetch: userPermission,
    onSpotChange,
    clientType,
    currentPage,
    selectedFilter,
    search,
    searchItem,
  });

  const handleCloseDialogBox = () => {
    dispatch(isShowDialogBoxChange(false));
    setEnable(false);
  };
  const handleDisable = async (id) => {
    if (!enable) {
      const path = apiUrl.disableclient + id;
      try {
        setClientActive(true);
        const { response } = await Helper.put({}, path);
        if(response){
          ToastServices.showToast({
            message: "Client Disabled Successfully !",
            type: "success",
            autoClose: 3000,
          });
        }
        dispatch(isShowDialogBoxChange(false));
        setClientActive(false);
      } catch (error) {
        ToastServices.showToast({
          message: "Error !",
          type: "Error!",
          autoClose: 3000 });
      }
      setOnSpotChange(!onSpotChange);
    } else {
      const tempObj = {
        id,
      };
      const path = `clients/${id}/activate_client/`;
      try {
        setClientActive(true);
        const { status } = await Helper.patch(tempObj, path, false);
        if(status === 200 || status === 201){
          ToastServices.showToast({
            message: "Client Enabled Successfully !",
            type: "success",
            autoClose: 3000,
          });
        }
        dispatch(isShowDialogBoxChange(false));
        setEnable(false);
        setClientActive(false);

      } catch (error) {
        ToastServices.showToast({
          message: "Error !",
          type: "Error!" ,
          autoClose: 3000,
        });
      }
      setOnSpotChange(!onSpotChange);
    }
  };
  const handleFilterClick = (selectedOption) => {
    setSelectedFilter(selectedOption);
    setClientType(selectedOption.header);
  };
  const handleInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchItem(searchTerm);
  };

  return (
    <div
      className="w-[96%] h-[calc(100vh-5.1rem)] bg-white space-y-4 flex flex-col p-5 pb-1
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] "
    >
      {addClientPopUp &&
        <AddClient setAddClientPopUp={setAddClientPopUp} />
      }
      <div className="w-full h-16 bg-white flex  justify-between">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold xl:text-xl lg:text-xl md:text-[1.1rem]  text-[#031B59]">
            {t("basic_detail")}
          </h2>

        </div>
        {isOpenDialogBoxToggle && (
          <div className="w-[100vw] h-[100vh] z-40 flex justify-center items-center pb-[15rem]">
            <div
              className="w-[37.5rem] h-[15.75rem] flex flex-col rounded-br-[2rem]
            items-center gap-[1.5rem] justify-center bg-white drop-shadow-lg"
            >
              <button onClick={handleCloseDialogBox}>
                <GrFormClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem]" />
              </button>
              <div
                className="w-[32.5rem] h-[4rem] color-[#191919] font-inter font-bold
              text-2xl leading-8 tracking-[0.0075rem]"
              >
                {enable
                  ? t("enable_client_message")
                  : t("disable_client_message")}
              </div>
              <div
                className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem] rounded-br-[2rem]
              border-t-[1px] border-[#E2E8F0] bg-white"
              >
                <button>
                  <div
                    onClick={() => handleCloseDialogBox()}
                    className="flex w-[7.5rem] h-[3.5rem] p-[1rem] justify-center items-center
                    gap-[0.5rem] rounded-[0.5rem]"
                  >
                    {t("cancel")}
                  </div>
                </button>
                <button onClick={() => handleDisable(id)} disabled={clientActive}>
                  <div
                    className="flex h-[3.5rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                  rounded-[2.5rem] bg-[#031B59] font-inter text-[1rem] font-bold leading-normal text-white"
                  >
                    {enable ? t("enable") : t("disable")}
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center space-x-4 md:space-x-2 ">
          <Search searchItem={search} setSearchItem={setSearch}
            currentResource={userPermission}/>
          <button
            className="w-[2.688rem] h-[2.688rem] relative  flex items-center
            justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
          >
            <IoFilter
              className="w-6 h-6 stroke-[#031B59]"
              onClick={() => {
                setFilter(!filter);
              }}
            />
          </button>
          <AddButton
            currentResource={mappedPermissionObj.User}
            title={t("addClient")}
            onClick={() => {
              setAddClientPopUp(true);
            }}
          />
          {filter && (
            <div>
              <FilterClient
                showFilter={filter}
                setFilter={setFilter}
                setOpenOptions={setOpenOptions}
                openOptions={openOptions}
                clientType={clientType}
                handleInputChange={handleInputChange}
                handleFilterClick={handleFilterClick}

              />
            </div>
          )}
        </div>
      </div>
      {
        userPermission?.viewAll && (
          <ClientTable
            isShowDialogBoxChange={isShowDialogBoxChange}
            setId={setId}
            setEnable={setEnable}
          />
        )
      }

      {!isLoading && userPermission?.viewAll && (
        <div className="w-full bg-white flex justify-between items-center">
          {clientsData?.length > 0 ? (
            <>
              <div className="text-[#031B59] font-medium">
                Showing {currentPage} of {pageCount}
              </div>{" "}
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-medium">
              {t("no_data_found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
