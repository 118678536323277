import React, { useState } from 'react';
import EmployeeCsvImport from './EmployeeCsvImport';
import EmployeeCsvDetails from './EmployeeCsvDetails';
import EmployeeCsvPreview from './EmployeeCsvPreview';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFiles } from 'redux/actions/action';

const EmployeCSVfile = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDetailsBackClick = () => {
    setCurrentPage(currentPage - 1);
    dispatch(setFiles([]));
  };

  const handleConfirmAndNext = () => {
    setCurrentPage(3);
  };

  const handlePreviewBackClick = () => {
    setCurrentPage(2);
  };

  return (
    <>
      <div className="w-[96%] max-h-[720px] h-[calc(100vh-6rem)]
       space-y-4 flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]">
        <div className='w-full flex flex-col sm:flex-row items-center gap-4 bg-[#f2f6ff] p-4 rounded-lg'>
          <div className={` ${currentPage === 1 ? 'text-[#031B59] font-bold' : 'text-[#686868]'}`}>
            <h1>{t("Upload_File")}</h1>
          </div>
          <div className='hidden sm:block w-[35rem] h-[2px] rounded-[4px] bg-[#E2E8F0] mt-2'></div>
          <div className={`mt-3 sm:mt-0 ${currentPage === 2 ? 'text-[#031B59] font-bold' : 'text-[#686868]'}`}>
            {t("Modify_Column")}
          </div>
          <div className='hidden sm:block w-[35rem] h-[2px] rounded-[4px] bg-[#E2E8F0] mt-2'></div>
          <div className={`mt-3 sm:mt-0 ${currentPage === 3 ? 'text-[#031B59] font-bold' : 'text-[#686868]'}`}>
            {t("Preview")}
          </div>
        </div>
        {currentPage === 1 && <EmployeeCsvImport onNextClick={handleNextClick} />}
        {currentPage === 2 && (
          <EmployeeCsvDetails
            onBackClick={handleDetailsBackClick}
            onConfirmAndNext={handleConfirmAndNext}
          />
        )}
        {currentPage === 3 && <EmployeeCsvPreview onPreviewBackClick={handlePreviewBackClick} />}
      </div>
    </>
  );
};

export default EmployeCSVfile;
