import React, { useEffect, useRef, useState } from "react";
import CustomTable from "component/common/table/CustomTable";
import Back from "svgComponents/myTeam/Back";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { useTranslation } from "react-i18next";
import Comment from "svgComponents/myTeam/Comment";
import Remove from "svgComponents/myTeam/Remove";
import { useDispatch, useSelector } from "react-redux";
import { addMembersToProject, myProjectMembers, removeMembers } from "redux/appThunk/Admin/myTeams";
import PropTypes from "prop-types";
import LoaderComp from "component/loader/LoaderComp";
import Search from "component/common/accessControlUi/Search";
import useDebounce from "hooks/useDebounce";
import Popup from "component/common/Popup";
import ProfileAvtar from "component/common/ProfileAvtar";

import Employeebrief from "../Employee/Employeebrief";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import { GrFormClose } from "react-icons/gr";
import { GoSearch } from "react-icons/go";
import FilterColumn from "./FilterColumn";
import { ColumnButton } from "component/common/accessControlUi/Button";

export const filterOptions = [
  { key: "employeeName", value: "Member Details" },
  { key: "designation", value: "Designation" },
  { key: "dateAdded", value: "Date Added" },
  { key: "status", value: "Status" },
  { key: "projects", value: "No of projects" },
  { key: "action", value: "Action" },
];
let disabledFilterState = ["employeeName", "designation", "dateAdded", "action"];

const ProjectMembers = ({ id, setMembers, projectName }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelect, setAllSelect] = useState(false);
  const { userPermission } = usePermissions(mappedPermissionObj.Myteams);
  const [showPopUp, setShowPopUp] = useState(false);
  const [serachedData, setSearchedData] = useState([]);
  const alreadyExistResource = [];
  const { searchResult } = useSelector((state) => state.userSearchReducer);

  const [resourses, setResourses] = useState([]);
  const [show, setShow] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const [isRemoveAll, setIsRemoveAll] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const { isLoading, myProjectMembersData } = useSelector(
    (state) => state.MyTeamsReducer
  );
  const [showPopup, setShowPopup] = useState(false);
  const [moreProjects, setMoreProjects] = useState([]);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const searchQuery = useDebounce(search, 500);
  const [userId, setUserId] = useState([]);
  const dropDownRef = useRef(null);

  useEffect(() => {
    dispatch(myProjectMembers(id, searchQuery));
  }, [dispatch, id, searchQuery]);

  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
  );

  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  const handleSelectAll = (checked, data) => {
    const newSelectedItems = checked ? data.map((item) => item.id) : [];
    setSelectedItems(newSelectedItems);
    setAllSelect(checked);
  };

  const handleSelectItem = (id, checked) => {
    setSelectedItems((prevSelectedItems) => {
      if (checked) {
        return [...prevSelectedItems, id];
      } else {
        return prevSelectedItems.filter((itemId) => itemId !== id);
      }
    });
  };

  const toggleRemove = (id) => {
    setUserId((prev) => [...prev, id]);
    setIsRemove(!isRemove);
  };

  const handleRemove = () => {
    if (userId.length) {
      const jsonObj = {
        user_ids: userId,
      };
      dispatch(removeMembers(id, jsonObj));
      toggleRemove();
      setUserId([]);
    }
  };

  const handleRemovedata = (id) => {
    setResourses((prevState) => prevState.filter((obj) => obj.id !== id));
  };

  const toggleRemoveAll = () => {
    setIsRemoveAll(!isRemoveAll);
  };

  const handleRemoveMembers = () => {
    if (selectedItems.length) {
      const jsonObj = {
        user_ids: selectedItems,
      };
      dispatch(removeMembers(id, jsonObj));
      toggleRemoveAll();
      setSelectedItems([]);
    }
  };

  const handleBackButtonClick = () => {
    setMembers(false);
  };

  const showMoreProjects = (projects) => {
    setMoreProjects(projects.slice(2));
    setShowPopup(true);
  };

  const columns = [
    {
      title: (
        <input
          type="checkbox"
          checked={allSelect}
          onChange={(e) =>
            handleSelectAll(e.target.checked, myProjectMembersData)
          }
        />
      ),
      field: "checkbox",
      align: "left",
      className: "w-4 pl-4",
      render: (value, job) => {
        return (
          <input
            type="checkbox"
            checked={selectedItems.includes(job.id)}
            onChange={(e) => handleSelectItem(job.id, e.target.checked)}
          />
        );
      },
    },
    {
      title: "Member Details",
      field: "employeeName",
      align: "left",
      className: "pl-2",
      render: (value, job) => {
        return (
          <div className="flex">
            <div className="w-12 h-12">
              {job?.employeePicture ? (
                <img
                  className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
                  src={job?.employeePicture}
                  alt="Profile Avatar"
                />
              ) : (
                <ProfileAvtar
                  height="3rem"
                  width="3rem"
                  name={job?.employeeName ? job?.employeeName : null}
                />
              )}
            </div>
            <div className="pl-2 text-left">
              <div>{job?.employeeName}</div>
              <div>{job?.empCode}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Designation",
      field: "designation",
      align: "left",
      className: "pl-4",
      render: (projectResources, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer">
            {job?.designation ? projectResources?.designation?.designation : "--"}
          </div>
        );
      },
    },
    {
      title: "Date Added",
      field: "dateAdded",
      align: "left",
      className: "pl-4",
      sorting: true,
    },
    {
      title: "Status",
      field: "status",
      align: "left",
      className: "pl-4",
      render: (projectResources) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer">
            {projectResources?.status ? projectResources.status : "Active"}
          </div>
        );
      },
    },
    {
      title: "No. of Projects",
      field: "projects",
      align: "center",
      className: "pl-4",
      render: (value, job) => {
        const projects = job?.projects || [];

        return (
          <div className="flex gap-2 items-center">
            {projects.length > 0 ? (
              <>
                {projects.slice(0, 2).map((item) => (
                  <div
                    key={item.id}
                    className="w-fit h-6 px-1.5 py-1
                     bg-gray-200 rounded border
                     border-green-700 justify-start
                     items-center gap-2 inline-flex"
                  >
                    {item.name}
                  </div>
                ))}
                {projects.length > 2 && (
                  <div
                    className="ml-2 text-[#031B59] text-sm cursor-pointer"
                    onClick={() => showMoreProjects(projects)}
                  >
                    +{projects.length - 2} more
                  </div>
                )}
              </>
            ) : (
              <span>--</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      field: "action",
      align: "left",

      render: (value, job) => {
        return (
          <div>
            <button className="">
              <Comment />
            </button>
            <button className="pl-4" onClick={() => toggleRemove(job.id)}>
              <Remove />
            </button>
          </div>
        );
      },
    },
  ];

  const array = ["checkbox"];
  columnFilters?.forEach(v => array.push(v));
  const newGet = columns?.filter((item) => array?.includes(item?.field));

  const toggleDelete = () => {
    setShowPopUp(!showPopUp);
    setResourses([]);
    setSearch("");
  };

  useEffect(() => {
    dispatch(myProjectMembers(id));
  }, [dispatch, id]);

  const addMember = () => {
    const memberName = resourses.map((item) => item.name);
    const nameInString = memberName.toString();
    dispatch(addMembersToProject(id, nameInString));
    toggleDelete();
    setResourses([]);
  };

  const handleRefresh = () => {
    setFilters(() =>
      Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
    );
    setColumnsFilters(() => new Set(disabledFilterState));
  };
  const handleFilterChange = (e) => {
    const { id, checked } = e.target;
    setFilters((prev) => {
      if (checked) {
        return { ...prev, [id]: true };
      } else {
        // eslint-disable-next-line no-unused-vars
        const { [id]: removedFilter, ...rest } = prev;

        return rest;
      }
    });
  };

  const handleSaveFilters = () => {
    setColumnsFilters((prev) => {
      const mergedFilters = new Set([...prev, ...Object.keys(filters)]);

      return mergedFilters;
    });
    setOpenFilter(false);
  };

  const handleEmployeedata2 = (item) => {
    setShow(false);
    setResourses([...resourses, item]);
    setSearch("");
  };

  const getColorFromEmail = (email) => {
    const hashCode = email
      ?.split("")
      ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };
  useEffect(() => {
    if (search !== "") {
      setShow(true);
      dispatch(handleSearchUserForChat(search));
    } else {
      setShow(false);
    }
  }, [search]);

  useEffect(() => {
    if (search === "") {
      document.querySelector(".focus")?.focus();
    }
  }, [search]);

  useEffect(() => {
    if (searchResult) {
      const filteredData = searchResult?.filter(
        (obj) => !alreadyExistResource?.includes(obj?.id)
      );
      setSearchedData(filteredData);
    }
  }, [searchResult]);

  const filteredData = myProjectMembersData.filter(member =>
    member.employeeName && member.employeeName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="border w-[94rem] h-[49rem] rounded-xl p-4 shadow-xl">
      <div className="w-[90rem] h-[2.5rem] justify-start items-center gap-2.5 inline-flex mb-4">
        <div className="flex w-full text-blue-950 text-xl font-extrabold pl-4">
          <button onClick={handleBackButtonClick}>
            <Back />
          </button>
          <p className="pl-4">{projectName}</p>
        </div>

        <button
          className="w-[10rem] h-[2.7rem] rounded-[1.5rem]
          border border-slate-200 justify-center
           items-center gap-2 inline-flex text-red-500"
          onClick={toggleRemoveAll}
          disabled={selectedItems.length === 0}
        >
          {t("remove")}
        </button>

        <div className="flex items-center justify-center gap-4 md:gap-2">
          <Search
            searchItem={search}
            setSearchItem={setSearch}
            currentResource={userPermission}
          />
          <div className="w-fit h-fit relative">
            <ColumnButton
              currentResource={mappedPermissionObj.Myteams}
              title={"Add Column"}
              onToggle={() => {
                setOpenFilter(!openFilter);
              }}
            />
            {openFilter && (
              <FilterColumn
                showFilter={openFilter}
                filterOptions={filterOptions}
                disabledFilterState={disabledFilterState}
                filterState={filters}
                onChange={handleFilterChange}
                onSave={handleSaveFilters}
                onClose={() => {
                  setOpenFilter(false);
                }}
                onRefresh={handleRefresh}
              />
            )}
          </div>

          {showPopUp && (
            <><Popup
              title={t("Add Member")}
              submitBtnText={t("add")}
              popupBox={toggleDelete}
              handleSubmit={(item) => addMember(item)} >
              <div
                className="m-[1rem] p-[0.25rem] border rounded-[1rem] flex items-center justify-center"
              >
                <div
                  className="w-full max-w-[37.5rem] h-fit min-h-[45px]
                flex flex-wrap items-center justify-start gap-2 p-[0.5rem]"
                >
                  {resourses?.map((obj, index) => (
                    <span
                      key={index}
                      className="p-2 flex flex-row items-center justify-normal gap-2
                  rounded-full bg-[#F2F6FF]"
                    >
                      {obj.name}
                      <GrFormClose
                        className="fill-[#A1A1A1] w-5 h-5 p-1 box-content cursor-pointer"
                        onClick={() => {
                          handleRemovedata(obj.id);
                        }}
                      />
                    </span>
                  ))}
                  <input
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    name="clientName"
                    className="focus w-[25rem] outline-none"
                  />
                </div>
                <GoSearch className="w-[1.375rem] h-[1.375rem]" />
              </div>
              <div className="relative z-10 h-0 px-2">
                {show && (
                  <div
                    className="flex justify-start flex-col w-full max-w-[36rem] bg-white group custom_scroll
                  shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-3 rounded-2xl z-10 overflow-y-auto max-h-60"
                  >
                    {serachedData?.length > 0 ? (
                      serachedData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full px-2 flex items-center space-x-2
                           cursor-pointer hover:bg-[#031B59] group rounded"
                            onClick={() => handleEmployeedata2(item)}
                          >

                            <div className="w-full flex flex-col">
                              <p className="text-[#313131] hover:text-white capitalize p-1">
                                <div className="flex items-center">
                                  <div
                                    className="w-9 h-8 rounded-full flex items-center
                                  justify-center text-white bg-[#031B59] mr-2"
                                    style={{
                                      backgroundColor: getColorFromEmail(
                                        item.email
                                      ),
                                    }}
                                  >
                                    {item.email?.[0]?.toUpperCase()}
                                  </div>
                                  <Employeebrief
                                    email={item.email}
                                    names={item.name}
                                  />
                                </div>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-[#031B59]">{t("noUserFound")}</p>
                    )}
                  </div>
                )}
              </div>
            </Popup>

            </>
          )}
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                toggleDelete();
              }}
              className="h-[3.063rem] w-[7.625rem] p-2 border-[1.5px] border-[#E2E8F0] text-[#031B59] rounded-full"
            >
              {t("addMember")}
            </button>
          </div>

        </div>
      </div>

      {isRemoveAll && (
        <Popup
          title={t("Are you sure you want to remove these members?")}
          submitBtnText={t("Remove")}
          popupBox={toggleRemoveAll}
          handleSubmit={handleRemoveMembers}
        />
      )}
      {isRemove && (
        <Popup
          title={t("Are you sure you want to remove this member?")}
          submitBtnText={t("Remove")}
          popupBox={toggleRemove}
          handleSubmit={handleRemove}
        />
      )}

      {isLoading ? (
        <div className="mt-16">
          <LoaderComp />
        </div>
      ) : (
        <div className="h-[44rem] custom_scroll overflow-x-hidden">
          <CustomTable columns={newGet} data={filteredData} />
        </div>
      )}
      {showPopup && (
        <div
          className={`h-fit bg-white absolute max-h-[17rem]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-1 border-[1px]
        rounded-md border-[#E2E8F0] custom_scroll overflow-y-auto z-10 top-[33rem] right-[20rem]`}
          ref={dropDownRef}
          size="md"
        >

          <div className="w-[15rem] h-[42px] ml-2 mt-2 pl-2.5
          pr-3.5 py-2.5 rounded border border-zinc-200
           justify-start items-center gap-2 ">
            <div className="grow shrink basis-0 text-neutral-400
            text-base  leading-normal">
                Search
            </div>

          </div>

          <div className="w-[266px] h-[313px] relative rounded-lg">
            <div className="h-[292px] left-0 top-[8px] absolute flex-col justify-start items-center gap-[15px] pl-5 ">
              <ul>
                {moreProjects.map((project) => (
                  <li className="py-3 text-[#031B59]" key={project.id}>{project.name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ProjectMembers.propTypes = {
  projectId: PropTypes.number.isRequired,
  id: PropTypes.string,
  setMembers: PropTypes.func,
  projectName: PropTypes.string,
};

export default ProjectMembers;
