import { useFormik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { deleteDocument, fetchProfileAllDocuments, uplaodEducationDocument }
  from 'redux/appThunk/Admin/profileDocument';
import LoaderComp from 'component/loader/LoaderComp';
import { documentSchema } from 'redux/validator/Profile/document';
import { BiCloudUpload } from 'react-icons/bi';

const Education = ({ setCurrentStep, identiityData }) => {
  const [loader, setLoader] = useState(true);

  const path = useParams().id;
  const { id } = useParams();
  const formData = new FormData();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const [handelButton, setHandelButton] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const deleteFnc = (url, column) => {
    const tempData = {
      url: url,
      column: column,
    };
    setHandelButton(true);
    dispatch(deleteDocument(tempData, id, setLoader, userData?.id));
  };

  useEffect(() => {
    dispatch(fetchProfileAllDocuments(path || userData?.id, setLoading, loader));
  }, [path]);

  const initialvalues = {
    tenthMarkSheet: [],
    twelthMarkSheet: [],
    graduationMarkSheet: [],
    postGraduationMarkSheet: [],
    otherMarkSheet: [],
  };

  const [headings, setHeadings] = useState([
    {
      Name: '10TH Marksheet',
      value: "",
      initialValuesName: "markshseet_10th",
      gettingValues: [],
      initialValues: 'tenthMarkSheet',
      error: "",
    },
    {
      Name: '12th Marksheet',
      value: "",
      initialValuesName: "markshseet_12th",
      gettingValues: [],
      initialValues: 'twelthMarkSheet',
      error: "",
    },
    {
      Name: 'Graduation Degree (if don’t have than last examination passed)',
      value: "",
      initialValuesName: "graduation_degrees",
      gettingValues: [],
      initialValues: 'graduationMarkSheet',
      error: "",
    },
    {
      Name: 'Post Graduation Degree',
      value: "",
      initialValuesName: "post_graduation_degrees",
      gettingValues: [],
      initialValues: 'postGraduationMarkSheet',
    },
    {
      Name: 'Any Other Certification',
      value: "",
      initialValuesName: "other_certifications",
      gettingValues: [],
      initialValues: 'otherMarkSheet',
    },
  ]);

  function checkEducationData() {
    return headings?.some((obj) => obj?.value?.length >= 1);
  }

  function EducationDataSubmit() {
    const isData = headings?.some((obj, index) => 2 >= index ? obj?.gettingValues?.length === 0 : null);
    if (isData) {
      setHeadings((obj) => obj?.map((item, index) => item?.gettingValues?.length === 0 && index <= 2 ? {
        ...item, error: "please add at least one document",
      }
        : item));
    } else if (!handelButton) {
      if (checkEducationData) {
        setCurrentStep(3);
      }
    } else {
      headings[0]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        }); formData.append("background_verification[markshseet_10th][]", file);
      });
      headings[1]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        }); formData.append("background_verification[markshseet_12th][]", file);
      });
      headings[2]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        }); formData.append("background_verification[graduation_degrees][]", file);
      });
      headings[3]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        }); formData.append("background_verification[post_graduation_degrees][]", file);
      });
      headings[4]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        }); formData.append("background_verification[other_certifications][]", file);
      });
      dispatch(uplaodEducationDocument(path || userData?.id, formData, setLoading, setHandelButton));
    }
  }

  const formik = useFormik({
    initialValues: initialvalues,
    validationSchema: documentSchema,
    onSubmit: () => {
      EducationDataSubmit();
    },
  });

  const { handleChange, errors, setFieldValue, touched, values } = formik;

  useEffect(() => {
    if (identiityData) {
      setHeadings((obj) => obj.map((item) => ({
        ...item, gettingValues: identiityData[item.initialValuesName] ?
          identiityData[item.initialValuesName] : [],
      })));
      setHandelButton(false);
    }
  }, [identiityData]);

  const handelEducation = (e) => {
    handleChange(e);
    const Name = e.target.name;
    const file = e.target.files[0];
    if (file || Name) {
      setHandelButton(true);
    }

    if (file) {
      setFieldValue(Name, [...values[Name], file]);
    }
    if (headings?.filter((obj) => obj?.initialValues === Name)[0].gettingValues?.length <= 2) {
      setHeadings((obj) => obj?.map((item) => item?.initialValues === Name ? {
        ...item, gettingValues: [...item.gettingValues, file], error: "",
      }
        : item));
    }

  };

  return (
    <div>
      <div className=" bg-white p-6 overflow-y-auto no-scrollbar h-[350px]">
        {Loading ? <LoaderComp sx={{ marginTop: " 40px " }} /> : <>
          {headings.map((headings, i) => (
            <div key={i} className='mt-8'>
              <label className='font-medium'>
                <h2>{headings.Name}</h2>
              </label>
              <label
                role="button"
                className="border border-dashed h-[120px] flex flex-col gap-3
                justify-center items-center rounded w-full"
              >
                <input
                  type="file"
                  multiple
                  name={headings.initialValues}
                  className="hidden"
                  onChange={handelEducation}
                />
                <BiCloudUpload className='text-[#A1A1A1] text-3xl' />
                <div className="flex items-center">
                  <span>
                    <b className={`text-[${checkEducationData() ? "grey" : "#031B59"}]`}>{t("Upload_File")}</b>
                  </span>
                </div>
              </label>
              {headings.error
                && <div className="text-red-500">{headings.error}</div>}
              {headings[headings?.initialValues]?.length >= 1 && (
                <ul>
                  {headings[headings?.initialValues]?.map((file, index) => (
                    <>
                      <li key={file?.name} className="mt-2 w-6/6 p-2 border border-dotted border-gray-300
                  rounded-md flex justify-between items-center">
                        <a href={file?.url} target="blank" >{file?.name}</a>
                        <button type="button"
                          className="text-gray-500"
                          onClick={() => deleteFnc(file.url, headings.initialValuesName)}>
                          <RxCross2 />
                        </button>
                      </li>
                      {errors[headings?.initialValues] && errors[headings?.initialValues][index]?.name &&
                        touched[headings?.initialValues] && touched[headings?.initialValues][index]?.name &&
                        <div>{errors[headings?.initialValues][index].name}</div>}
                    </>
                  ))}
                </ul>
              )}
              {headings?.gettingValues?.length <= 2 && (
                <ul>
                  {headings?.gettingValues?.map((file) => (
                    <>
                      <li key={file?.name} className="mt-2 w-6/6 p-2 border border-dotted border-gray-300
                  rounded-md flex justify-between items-center">
                        <a href={file?.url} target="blank" >{file?.name}</a>
                        <button type="button"
                          className="text-gray-500"
                          onClick={() => deleteFnc(file.url, headings.initialValuesName)}>
                          <RxCross2 />
                        </button>
                      </li>
                    </>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className='flex justify-end Nextstatic'>
            <button className="mt-10 h-[2.813rem] w-[7.625rem] p-2
            text-gray-500" onClick={() => setCurrentStep(1)}>{t("back")}</button>
            <button
              onClick={EducationDataSubmit}
              className="mt-10 h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E]
              text-white rounded-full"
            >
              {handelButton === false ? t('next') : t('save')}
            </button>
          </div>
        </>}
      </div>
    </div>
  );

};

export default Education;

Education.propTypes = {
  setCurrentStep: PropTypes.number,
  identiityData: PropTypes.object,
};
