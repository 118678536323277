import Card from "component/common/Card";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { leaveBalance } from "redux/appThunk/Admin/dashboard";

const DashboardLeaveBalance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.dashboardReducers.leavebalance);
  useEffect(() => {
    dispatch(leaveBalance());
  }, []);

  return (
    <div>
      <Card cardClass="h-[10.563rem] w-full flex flex-col col-span-1 p-5">
        <div className="flex flex-row justify-between">
          <div>
            <h4 className="text-lg font-medium text-[#031B59] ">
              {t("leaveBalances")}
            </h4>
          </div>
        </div>
        <div className="w-full h-full flex justify-between items-end">
          <div>
            <p
              className={`lg:text-3xl sm:text-1xl leading-2 font-black flex items-end text-[#ED6E0F]`}
            >
              {selector.paidleave + selector.sickleave || 0}
            </p>
            <p
              className={`lg:text-base sm:text-1xl leading-2 flex items-end text-[#ED6E0F]`}
            >
              {t("totalLeaves")}
            </p>
          </div>

          <div>
            <p className="text-[#031B59] text-sm ">
              {t("paid")} ({selector.paidleave !== undefined ? selector.paidleave : 0})
            </p>
            <p className="text-[#031B59] text-sm ">
              {t("sick")} ({selector.sickleave !== undefined ? selector.sickleave : 0})
            </p>
          </div>

        </div>
      </Card>
    </div>
  );
};

export default DashboardLeaveBalance;
