import React from 'react';
/* eslint-disable max-len */

const MyTeamSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6358 17.0011V15.5567C13.6358 14.7906 13.3557 14.0558 12.857 13.5141C12.3584 12.9723 11.682 12.668 10.9768 12.668H5.65895C4.95375 12.668 4.27744 12.9723 3.77879 13.5141C3.28014 14.0558 3 14.7906 3 15.5567V17.0011" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.32301 9.77748C9.79151 9.77748 10.982 8.48414 10.982 6.88874C10.982 5.29333 9.79151 4 8.32301 4C6.85451 4 5.66406 5.29333 5.66406 6.88874C5.66406 8.48414 6.85451 9.77748 8.32301 9.77748Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6192 16.999V15.5546C17.6188 14.9146 17.4227 14.2928 17.0617 13.7869C16.7008 13.2811 16.1954 12.9198 15.625 12.7598" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.9766 4.08789C13.5485 4.24699 14.0555 4.60837 14.4175 5.11506C14.7795 5.62175 14.976 6.24493 14.976 6.88636C14.976 7.52778 14.7795 8.15096 14.4175 8.65765C14.0555 9.16434 13.5485 9.52572 12.9766 9.68482" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MyTeamSvg;
