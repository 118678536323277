import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { BsEyeFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import EditLeaveDetails from "./EditLeaveDetails";
import { AddButton } from "component/common/accessControlUi/Button";
import AddLeave from "./AddLeaveData";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { useTranslation } from "react-i18next";
import Paginate from "component/admin/Employee/Paginate";

const EmployeeLeaveDetails = ({ setShowDetails, employeeName,setOpenPopup}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [addLeavePop, setAddLeavePop] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const { leaveSpecificData } = useSelector((state) => state.leaveReducer);
  const { t } = useTranslation();
  const { userPermission } = usePermissions(mappedPermissionObj.Leave);

  const pageCount = Math.ceil(leaveSpecificData.length / itemsPerPage);
  const currentData = leaveSpecificData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    if (showPopup || showEditPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup, showEditPopup]);

  const handleBack = () => {
    if (!showPopup && !showEditPopup) {
      setShowDetails(false);
    }
  };

  const handleViewClick = (leave) => {
    setSelectedLeave(leave);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedLeave(null);
    setOpenPopup(false);
  };

  const handleEditClick = () => {
    setShowPopup(false);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setOpenPopup(false);
  };

  const handleSaveEdit = (editedLeave) => {
    setSelectedLeave(editedLeave);
    setShowEditPopup(false);
  };

  const handleApprove = (updatedLeave) => {
    setSelectedLeave(updatedLeave);
    setShowPopup(false);
  };

  const handleReject = (updatedLeave) => {
    setSelectedLeave(updatedLeave);
    setShowPopup(false);
  };

  return (
    <div className="relative w-full h-[calc(100vh-5.1rem)] bg-white flex flex-col p-5 pb-1 rounded-xl mt-2">
      <div className="flex justify-between items-center">
        <h2 className="font-extrabold text-xl text-[#031B59] flex items-center">
          <HiArrowNarrowLeft
            className={`mr-2 ${
              showPopup || showEditPopup ? "cursor-not-allowed opacity-50" : "cursor-pointer"
            }`}
            onClick={handleBack}
          />
          {employeeName}
        </h2>
        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="search"
            className="rounded-full p-2 outline-none border border-black-300"
          />
          {userPermission?.create && (
            <AddButton
              currentResource={mappedPermissionObj.Leave}
              title={t("addLeave")}
              onClick={() => setAddLeavePop(true)}
            />
          )}
        </div>
      </div>

      <div className="container mx-auto mt-4">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="h-[3.125rem] max-h-[3.125rem] w-[fit] p-2
             text-[#686868] text-sm font-normal
             bg-[#F2F6FF] sticky top-0 z-10 text-left">
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("ApplyDate")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("LeaveType")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("fromDate")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("toDate")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("numOfDays")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("reason")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("status")}
              </th>
              <th className="py-2 px-4 border-b-2
               border-gray-300 text-left leading-tight" style={{ color: "#686868" }}>
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((leave) => (
              <tr key={leave?.id}>
                <td className="py-2 px-4 border-b border-gray-300">
                  {leave.applydate ? new Date(leave.applydate).toLocaleDateString() : "--"}
                </td>
                <td className="py-2 px-4 border-b
                 border-gray-300">{leave.leaveType ? leave.leaveType : "--"}</td>
                <td className="py-2 px-4 border-b
                 border-gray-300">{leave.fromDate ? leave.fromDate : "--"}</td>
                <td className="py-2 px-4 border-b
                 border-gray-300">{leave.toDate ? leave.toDate : "--"}</td>
                <td className="py-2 px-4 border-b
                 border-gray-300">{leave.consumedleave ? leave.consumedleave : "--"}</td>
                <td className="py-2 px-4 border-b
                 border-gray-300">{leave.reason ? leave.reason : "--"}</td>
                <td className={`py-2 px-4 border-b
                 border-gray-300 ${getStatusTextColor(leave.leaveStatus)}`}>
                  {leave?.leaveStatus ? leave?.leaveStatus : "--"}
                </td>
                <td className="py-2 px-4 border-b
                 border-gray-300">
                  <button className="text-black-500"
                    onClick={() => handleViewClick(leave)}><BsEyeFill /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="w-full h-12 bg-white flex justify-between items-center mt-4">
        <div className="text-[#031B59] font-medium">
          {t("Showing")} {currentPage} {t("of")} {pageCount}
        </div>
        <Paginate
          currentPage={currentPage}
          initialPageCount={pageCount}
          pageRangeDisplayed={10}
          next=">"
          previous="<"
          setCurrentPage={setCurrentPage}
        />
      </div>

      {showPopup && selectedLeave && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleClosePopup}></div>
          <div className="fixed inset-y-0 right-0 w-1/3 bg-white p-5 shadow-lg flex flex-col z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">{t("leavedetails")}</h3>
              <div className="flex items-center">
                <button className="text-black-500" onClick={handleClosePopup}><IoClose size={24} /></button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-zinc-800 text-base font-medium
                font-['Roboto'] leading-snug"> {t("emp_id")}</label>
                <input
                  type="text"
                  name="employeeid"
                  value={selectedLeave.employeeid || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                  font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 mx-2 text-base font-medium
                 font-['Roboto'] leading-snug">{t("name")}</label>
                <input
                  type="text"
                  name="employeename"
                  value={selectedLeave.employee_name || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                   font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 text-base font-medium
                font-['Roboto'] leading-snug">{t("fromDate")}</label>
                <input
                  type="text"
                  name="fromDate"
                  value={selectedLeave.fromDate || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                  font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 mx-2 text-base font-medium
                font-['Roboto'] leading-snug">{t("toDate")}</label>
                <input
                  type="text"
                  name="toDate"
                  value={selectedLeave.toDate || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                  font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 text-base font-medium
                font-['Roboto'] leading-snug">{t("numberofdays")}</label>
                <input
                  type="text"
                  name="consumedleave"
                  value={selectedLeave.consumedleave || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                   font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 mx-2 text-base
                 font-medium font-['Roboto'] leading-snug">  {t("leaveType")}</label>
                <input
                  type="text"
                  name="leaveType"
                  value={selectedLeave.leaveType || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                   font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 text-base font-medium
                font-['Roboto'] leading-snug"> {t("reason")}</label>
                <input
                  type="text"
                  name="reason"
                  value={selectedLeave.reason || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                  font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div>
                <label className="text-zinc-800 mx-2 text-base font-medium
                font-['Roboto'] leading-snug">  {t("status")}</label>
                <input
                  type="text"
                  name="leaveStatus"
                  value={selectedLeave.leaveStatus || ''}
                  readOnly
                  className="mt-2 p-2 text-zinc-900 text-base font-normal
                  font-['Roboto'] block w-full shadow-sm border border-slate-200 bg-gray-100"
                />
              </div>
              <div className="w-[516px] h-[108px] p-2.5 bg-white rounded border
               border-slate-200 flex-col justify-start items-start gap-2 inline-flex">
                <label className="text-zinc-800 text-base
                font-medium font-['Roboto'] leading-snug">{t("images")}</label>
                {selectedLeave.images && selectedLeave.images.length > 0 ? (
                  selectedLeave.images.map((image, index) => (
                    <img key={index} src={image} alt={`Leave document ${index + 1}`}
                      className="mt-2 w-full h-40 object-cover border border-slate-200" />
                  ))
                ) : (
                  <div className="flex items-center gap-2 mt-2">
                    <img className="w-[22.77px] h-6" src="https://via.placeholder.com/23x24" alt="Default" />
                    <div className="text-zinc-900 text-base font-normal font-['Roboto']">Image</div>
                  </div>
                )}
              </div>
            </div>
            <div className="h-full flex justify-end items-end">
              <button className="flex items-center px-4 py-2 text-[#031B59]" onClick={handleEditClick}>
                {t("edit")}
              </button>
              <button
                type="button"
                onClick={handleReject}
                className="px-4 py-2 bg-[#FF0000] text-[#FFFFFF] rounded-full"
              >
                {t("reject")}
              </button>
              <button
                type="button"
                onClick={handleApprove}
                className="px-4 py-2 bg-[#1A8718] text-[#FFFFFF] rounded-full"
              >
                {t("approve")}
              </button>
            </div>
          </div>
        </>
      )}
      {showEditPopup && (
        <EditLeaveDetails
          setOpenPopup={setOpenPopup}
          leaveDetails={selectedLeave}
          onClose={handleCloseEditPopup}
          onSave={handleSaveEdit}
        />
      )}
      {addLeavePop && (
        <AddLeave setAddLeavePop={setAddLeavePop} />
      )}
    </div>
  );
};

EmployeeLeaveDetails.propTypes = {
  setShowDetails: PropTypes.func.isRequired,
  employeeName: PropTypes.string.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};

export default EmployeeLeaveDetails;

function getStatusTextColor(status) {
  switch (status) {
  case 'Pending':
    return 'text-gray-700';
  case 'Approved':
    return 'text-green-700';
  case 'Rejected':
    return 'text-red-700';
  default:
    return 'text-gray-700';
  }
}
