import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Svg19 from "svgComponents/Svg19";
import { addFeedback } from "redux/validator/admin/client";
import { createFeedback, getFeedback } from "redux/appThunk/Admin/client";
import PropTypes from "prop-types";
import ProfileAvtar from "component/common/ProfileAvtar";
import LoaderComp from "component/loader/LoaderComp";

const FeedbackModal = ({ setRenderSchedulePage, projectId, userId, resourceId }) => {
  const [allTab, setAllTab] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const interviewId = useSelector((state) => state.ClientsReducer.interviewId);
  const feedbackData = useSelector((state) => state.ClientsReducer.feedBackData);

  const initialValues = {
    comment: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addFeedback,
    onSubmit: async (values) => {
      if (submitting) return;
      setSubmitting(true);
      dispatch(createFeedback(values, projectId, setRenderSchedulePage, resourceId));
      setSubmitting(false);
      setRenderSchedulePage(false);
    },
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (interviewId) {
      setFieldValue("interview_id", interviewId.interview_Id);
    }
    dispatch(getFeedback(projectId, allTab, userId, setReload));
  }, [setFieldValue, interviewId, dispatch, allTab, projectId, userId]);

  return (
    <>
      <div className="flex flex-col space-y-3 pl-4 pr-4 items-center">
        <div className="w-full flex flex-col space-y-2">
          <label htmlFor="comment" className="text-[14px] font-medium text-[#686868]">
            {t("writeFeedback")}
          </label>
          <textarea
            type="text"
            name="comment"
            id="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="h-[6rem] border border-[#E2E8F0] bg-[#F2F6FF] rounded-[5px] p-3"
          />
          {formik.touched.comment && formik.errors.comment && (
            <p className="text-red-500">{formik.errors.comment}</p>
          )}
          <div className="flex justify-end space-x-4 cursor-pointer">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              style={{ border: "1px solid black" }}
              disabled={submitting}
            >
              <Svg19 className="h-[24px] w-[24px]" />
            </button>
          </div>
        </div>
        <div className="w-full h-16 bg-white flex justify-between items-center">
          <div className="h-12 bg-white flex justify-between items-center">
            <div
              className={`font-bold text-[16px] mr-[3rem] cursor-pointer ${
                allTab === 1 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setAllTab(1)}
            >
              {t("all")}
            </div>
            <div
              className={`font-bold text-[16px] cursor-pointer ${
                allTab === 2 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setAllTab(2)}
            >
              {t("myFeedbacks")}
            </div>
          </div>
        </div>
      </div>

      {reload ? (
        <LoaderComp />
      ) : (
        <div className="w-full rounded-[8px] flex flex-col space-y-3
        custom_scroll overflow-x-hidden h-[50vh]">
          {feedbackData.length > 0 ? (
            feedbackData.map((obj, index) => (
              <div
                key={index}
                className="h-fit border w-[42.5vw] rounded-[8px] flex flex-col pl-3 pr-3 space-y-3 m-4"
              >
                <div className="flex items-center justify-between mt-2">
                  <div className="flex h-fit items-center gap-3">
                    <ProfileAvtar
                      height="2rem"
                      width="3rem"
                      name={obj.attributes.user.full_name ? obj.attributes.user.full_name : "User"}
                    />
                    <div className="text-[#031B59] w-full font-medium ">
                      {obj.attributes.user.full_name}
                    </div>
                  </div>
                  <div className="text-[#031B59]">
                    {new Date(obj.attributes.created_at).toLocaleDateString()}
                  </div>
                </div>
                <div className="text-[#A1A1A1]">{obj.attributes.comment}</div>
              </div>
            ))
          ) : (
            <div className="text-black relative z-10 flex justify-center items-center h-[10rem] left-[5px]">
              {t("Nofeedbacksavailable")}
            </div>
          )}
        </div>
      )}
    </>
  );
};

FeedbackModal.propTypes = {
  setRenderSchedulePage: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  resourceId: PropTypes.number,
};

export default FeedbackModal;
