import React, { useEffect, useState } from "react";
import Graph from "component/common/Graph";
import { useTranslation } from "react-i18next";
import { formatNumberToK } from "utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployeesData,
  fetchRevenueMonthlyData,
  fetchRevenueOverViewData,
  monthlyClientData,
  monthlyEmployeeData,
  monthlyWorkingHourData,
  yearlyWorkingHourData
} from "redux/appThunk/Admin/dashboard";
import Switch from "component/common/Switch";
import Card from "component/common/Card";

const DashboardTotalClients = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { monthlyRevenue } = useSelector((state) => state.dashboardReducers);
  const { revenue } = useSelector((state) => state.dashboardReducers);
  const { monthlyClients } = useSelector((state) => state.dashboardReducers);
  const { monthlyEmployees } = useSelector((state) => state.dashboardReducers);

  const [graphArrState, setGraphArrState] = useState({
    revenue: true,
    clients: true,
    workingHours: true,
  });

  const getTotalRevenue = (givenRevenueData) => {
    let totalEarnings = 0;
    let totalExpenses = 0;
    let totalRevenue = 0;

    givenRevenueData?.forEach((total) => {
      totalEarnings += total?.Earnings;
      totalExpenses += total?.Expenses;
      totalRevenue += total?.Revenue;
    });

    return {
      earnings: formatNumberToK(totalEarnings),
      expenses: formatNumberToK(totalExpenses),
      revenues: formatNumberToK(totalRevenue),
    };
  };

  // eslint-disable-next-line no-unused-vars
  const [revenueData, setRevenueData] = useState([
    {
      title: "Revenue",
      id: "revenues",
      data: "---",
      percent: "+7.0%",
      color: "#978FED",
    },
    {
      title: "Earnings",
      id: "earnings",
      data: "---",
      percent: "+4.0%",
      color: "#EE89DF",
    },
    {
      title: "Expenses",
      id: "expenses",
      data: "---",
      percent: "+11.4%",
      color: "#F6CF7D",
    },
  ]);
  useEffect(() => {
    if (revenue.length > 0)
      setRevenueData([
        {
          title: "Revenue",
          id: "revenues",
          data: getTotalRevenue(revenue).revenues || "---",
          percent: "+7.0%",
          color: "#978FED",
        },
        {
          title: "Earnings",
          id: "earnings",
          data: getTotalRevenue(revenue).earnings || "---",
          percent: "+4.0%",
          color: "#EE89DF",
        },
        {
          title: "Expenses",
          id: "expenses",
          data: getTotalRevenue(revenue).expenses || "---",
          percent: "+11.4%",
          color: "#F6CF7D",
        },
      ]);
  }, [revenue]);

  useEffect(() => {
    dispatch(fetchRevenueMonthlyData());
    dispatch(fetchRevenueOverViewData());
    dispatch(monthlyClientData());
    dispatch(monthlyEmployeeData());
    dispatch(fetchEmployeesData());
    dispatch(yearlyWorkingHourData());
    dispatch(monthlyWorkingHourData());
  }, []);

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    if (name === "revenue") {
      let revenueState = checked ? revenue : monthlyRevenue;
      const updatedRevenue = getTotalRevenue(revenueState);
      setRevenueData((prevData) =>
        prevData.map((prevObj) => ({
          ...prevObj,
          data: updatedRevenue[prevObj.id],
        }))
      );
    }
    setGraphArrState((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <div className="w-full col-span-2 xl:col-span-1 flex ">
      <Card id={"clientCard"} cardClass={"flex flex-col w-full h-[24rem]"}>
        <div className="flex justify-betwen items-center w-[95%] m-[20px] pr-3">
          <div className="w-full flex items-center justify-start">
            <h3 className="text-xl lg:text-lg font-semibold text-[#031B59]">
              {graphArrState.clients ? t("totalEmployee") : t("totalClients")}
            </h3>
          </div>

          <Switch
            name={"clients"}
            handleToggle={handleToggle}
            labelLeft={t("clients")}
            labelRight={t("employees")}
            checkedValue={graphArrState.clients}
            switchContainerClass="gap-6"
          />
        </div>
        <div className="flex h-full">
          {graphArrState.clients ? (
            <Graph type={"Employeegraph"} data={monthlyEmployees} />
          ) : (
            <Graph type={"Clientgraph"} data={monthlyClients} />
          )}
        </div>
        monthlyEmployees
      </Card>
    </div>
  );
};

export default DashboardTotalClients;
