export const formattedData = (response) => {
  const requiredResponse = [];

  response?.map((obj) => {
    requiredResponse.push({
      emp_name: obj?.name,
      id: obj?.id,
      emp_code: obj?.emp_code,
      date: obj?.date,
      checkIn: obj?.check_in,
      checkOut: obj?.check_out,
      totalTime: obj?.total_time,
      punchType: obj?.punch_type,
      work_status: obj?.work_status,
      remark: obj?.remark,
    });
  });

  return requiredResponse;
};

export const formattedEmployeeData = (response,searchQuery) => {
  const requiredResponse = searchQuery === "Week" ? {
    avg_hr: response?.user_total_hrs_of_last_week,
    on_time_active: response?.on_time_last_week,
  } : {
    avg_hr: response?.user_total_hrs_of_last_month,
    on_time_active: response?.on_time_last_month,
  };

  return requiredResponse;
};
