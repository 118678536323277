import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { convertDateFormat } from "utils/date";
import { useDispatch } from "react-redux";
import Popup from "component/common/Popup";
import PropTypes from "prop-types";
import { RxCross2 } from "react-icons/rx";
import { addWfhSchema } from "./addAttendanceSchema";
import { WFH_REQUEST } from "redux/appThunk/Admin/dashboard";

export default function ApplyWfh({setApplyWfhPop}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [submitOnce] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const initialvalues = {
    id: "",
    fromDate: "",
    toDate: "",
    fromshift: "",
    toshift: "",
    numOfDays: "",
    reason: "",
    status: "",
    fromTime: "",
    toTime: "",
    consumedDays: "",
    notify: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addWfhSchema,
    onSubmit: async (values) => {

      const formData = new FormData();
      formData.append("wfh_request[from_date]",values?.fromDate);
      formData.append("wfh_request[to_date]",values?.toDate);
      formData.append("wfh_request[wfh_status]",values?.status);
      formData.append("wfh_request[reason]",values?.reason);
      formData.append("wfh_request[from_time]",values?.fromTime);
      formData.append("wfh_request[to_time]",values?.toTime);
      formData.append("wfh_request[consumed_days]",values?.consumedDays);
      formData.append("wfh_request[notify]",values?.notify);
      formData.append("wfh_request[from_shift]",values?.fromshift);
      formData.append("wfh_request[to_shift]",values?.toshift);

      addEmployessDialoBox();
      dispatch(WFH_REQUEST(formData,setApplyWfhPop));

    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = Formik;

  const displayLeaveData = {
    "From Date": values?.fromDate,
    "To Date": values?.toDate,
    "Consumed Leave": values?.numOfDays,
    Reason: values?.reason,
    "Shift": values?.shift,
  };

  // function calculateNumberOfDays(fromDate, toDate) {
  //   const millisecondsInDay = 864e5;
  //   const startDate = new Date(fromDate);
  //   const endDate = new Date(toDate);
  //   const daysDifference =
  //     Math.floor((endDate - startDate) / millisecondsInDay) + 1;
  //   setFieldValue("numOfDays", daysDifference ? daysDifference : "");

  //   return daysDifference;
  // }
  function calculateNumberOfDays(fromDate, toDate, fromShift, toShift) {
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const startshift = fromShift;
    const endshift = toShift;
    let daysDifference = Math.floor((endDate - startDate) / millisecondsInDay);

    if (startshift === "First Shift" && endshift === "First Shift") {
      daysDifference += 0.5;
    } else if (startshift === "First Shift" && endshift === "Second Shift") {
      daysDifference += 1;
    } else if (startshift === "Second Shift" && endshift === "First Shift") {
      daysDifference += 0;
    } else if (startshift === "Second Shift" && endshift === "Second Shift") {
      daysDifference += 0.5;
    }

    setFieldValue("numOfDays", daysDifference ? daysDifference : "");

    return daysDifference;
  }

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  useEffect(() => {
    if (values?.fromDate && values?.toDate) {
      calculateNumberOfDays(values?.fromDate, values?.toDate,values?.fromshift,values?.toshift);
    }
  }, [values?.fromDate, values?.toDate.fromshift,values?.toshift]);

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setApplyWfhPop(false);
    }
  };

  const handleDateChange = (e) => {
    setFieldValue(`${e.target.name}`, convertDateFormat(e.target.value));
  };

  const addEmployessDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  return (
    <div
      className="w-full h-full flex items-center
    justify-end fixed top-0 left-0 z-50 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                Apply Work From Home
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[2.625rem]  h-[2.688rem] text-black flex text-xl justify-center items-center rounded-full"
              onClick={() => setApplyWfhPop(false)}
            >
              <RxCross2 />
            </button>
          </div>
          {showPopUp && (
            <Popup
              popupBox={addEmployessDialoBox}
              title={t("addLeave")}
              handleSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col border gap-2 capitalize">
                {displayLeaveData &&
                  Object.keys(displayLeaveData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center text-black gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{displayLeaveData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>

            </Popup>
          )}
        </div>
        <div className="flex flex-col gap-[28rem]">
          <div>
            <form
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
              autoComplete="off"
            >
              <div className="relative flex flex-col">
                <label htmlFor="fromDate" className="text-[#313135]">
                  {t("fromDate")}
                </label>
                <div className="flex border p-2 text-black rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3 bg-inherit"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={convertDateFormat(values?.fromDate)}
                    disabled
                  />
                  <input
                    className="w-5 rounded text-black outline-none ml-2"
                    type="date"
                    name="fromDate"
                    id="fromDate"
                    onChange={handleDateChange}
                    min={new Date().toISOString()?.split("T")[0]}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.fromDate && touched.fromDate ? (
                  <p className="text-[red]">{errors.fromDate}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="shift" className="text-[#313135]">
                  {t("shift")}
                </label>
                <select
                  type="text"
                  name="fromshift"
                  id="shift"
                  className="w-90%  p-[10px] text-black border rounded-[0.5rem] outline-none bg-white"
                  value={values.fromshift}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">Select</option>
                  <option value="First Shift">First Shift</option>
                  <option value="Second Shift">Second Shift</option>

                </select>
                {errors.fromshift && touched.fromshift ? (
                  <p className="text-[red]">{errors.fromshift}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="toDate" className="text-[#313135]">
                  {t("toDate")}
                </label>
                <div className="flex border p-2 rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3 text-black bg-inherit"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={convertDateFormat(values?.toDate)}
                    disabled
                  />
                  <input
                    className="m-0 w-5 rounded text-[#191919] outline-none ml-2"
                    type="date"
                    name="toDate"
                    id="toDate"
                    onChange={handleDateChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => enterClick(e)}
                    min={new Date().toISOString()?.split("T")[0]}
                  />
                </div>
                {errors.toDate && touched.toDate ? (
                  <p className="text-[red]">{errors.toDate}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="shift" className="text-[#313135]">
                  {t("shift")}
                </label>
                <select
                  type="text"
                  name="toshift"
                  id="shift"
                  className="w-90% p-[10px] text-black border rounded-[0.5rem] outline-none bg-white"
                  value={values.toshift}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">Select</option>
                  <option value="First Shift">First Shift</option>
                  <option value="Second Shift">Second Shift</option>

                </select>
                {errors.toshift && touched.toshift ? (
                  <p className="text-[red]">{errors.toshift}</p>
                ) : null}
              </div>

              <div className="relative flex flex-col">
                <label htmlFor="reason" className="text-[#313135]">
                  Number Of Days
                </label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded-[0.5rem] p-2 text-[#191919]"
                  value={displayLeaveData?.["Consumed Leave"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  disabled
                />
                {errors.reason && touched.reason ? (
                  <p className="text-[red]">{errors.numOfDays}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="reason" className="text-[#313135]">
                  {t("reason")}
                </label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Writing a reason here"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded-[0.5rem] p-2 text-[#191919]"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                />
                {errors.reason && touched.reason ? (
                  <p className="text-[red]">{errors.reason}</p>
                ) : null}
              </div>
            </form>
          </div>
          <div>
            <div className="flex items-end justify-end space-x-4">
              <button
                className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
                onClick={() => setApplyWfhPop(false)}
              >
                {t("cancel")}
              </button>
              <button
                className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem]
           p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                disabled={submitOnce}
                onClick={ addEmployessDialoBox}
              >
                {t("apply")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ApplyWfh.propTypes = {
  setApplyWfhPop: PropTypes.any,
};
