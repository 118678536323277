import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { filterClients } from "utils/Constants";

const FilterClient = ({
  showFilter,
  setFilter,
  setOpenOptions,
  openOptions,
  clientType,
  handleInputChange,
  handleFilterClick,
  onClose,
}) => {
  // const { t } = useTranslation();

  const handler = (event) => {
    const filterBtn = document.getElementById("filterBtn");
    if (
      showFilter &&
      !filterBtn?.contains(event.target) &&
      !ref?.current?.contains(event.target)
    ) {
      setFilter(false);
    }
  };

  const ref = useRef(handler);

  useEffect(() => {
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showFilter]);

  const { t } = useTranslation();
  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <div
      className="h-[9.5rem] w-[14rem] bg-white absolute top-[5rem] right-[9rem] rounded-md
           shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-20 border-[1px] border-[#E2E8F0] p-[20px]"
      ref={ref}
      onClick={() => setFilter(true)}
      onMouseLeave={() => {
        // setFilter(false),
        // setOpenOptions(false);
      }}
    >
      <div>
        <div className="gap-[4px]">
          <div
            className="text-[#031B59] text-base font-bold pb-2"
            onClick={() => {
              setFilter(false);
            }}
          >
            {t("filter_by")}
          </div>
          <div
            onClick={() => setOpenOptions(!openOptions)}
            className="flex border border-solid border-stroke-light rounded-md justify-between
                  w-full px-2 py-3  m-auto text-[#031B59] bg-white mr-3 relative cursor-pointer justify-items-center"
          >
            <h6>{clientType}</h6>
            <FaAngleDown className="mr-[6px] mt-1" />
          </div>
          <div className="relative">
            {openOptions && (
              <div
                style={{ borderRadius: "8px" }}
                className="w-full flex flex-col
                border border-solid border-stroke-light rounded-md absolute z-10
            items-start gap-[2px] justify-center mt-[0.3rem] p-[4px] bg-white drop-shadow-lg "
              >
                {filterClients?.map((obj, index) => (
                  <div
                    style={{ borderRadius: "8px" }}
                    key={index}
                    className={`w-full px-5 py-3 m-0 ${
                      obj.header === clientType && "bg-[#F2F6FF]"
                    }
                       hover:bg-[#F2F6FF] cursor-pointer`}
                    onChange={handleInputChange}
                    onClick={() => { handleFilterClick(obj); setOpenOptions(!openOptions); }}

                  >
                    <h1
                      className={`text-black  text-lg  mb-1hover:bg-[#F2F6FF]
                }`}
                    >
                      {obj?.header}
                    </h1>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterClient;

FilterClient.propTypes = {
  showFilter: PropTypes.any,
  setFilter: PropTypes.any,
  setOpenOptions: PropTypes.any,
  openOptions: PropTypes.any,
  clientType: PropTypes.any,
  handleInputChange: PropTypes.any,
  handleFilterClick: PropTypes.any,
  onClose: PropTypes.any,

};
