import React from 'react';

export default function SVGMinus() {
  return (
    <div>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.83268 8.1665L4.83268 9.83317L13.166 9.83317V8.1665L4.83268 8.1665ZM8.99935
         0.666504C4.39935 0.666504 0.666016 4.39984 0.666016 8.99984C0.666016 13.5998
         4.39935 17.3332 8.99935 17.3332C13.5993 17.3332 17.3327 13.5998 17.3327 8.99984C17.3327
         4.39984 13.5993 0.666504 8.99935 0.666504ZM8.99935 15.6665C5.32435 15.6665 2.33268 12.6748
         2.33268 8.99984C2.33268 5.32484 5.32435 2.33317 8.99935 2.33317C12.6743 2.33317 15.666
         5.32484 15.666 8.99984C15.666 12.6748 12.6743 15.6665 8.99935 15.6665Z" fill="#031B59" />
      </svg>
    </div>
  );
}
