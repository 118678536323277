/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import ProfileAvtar from "component/common/ProfileAvtar";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { TeamAttendPop } from "redux/appThunk/Admin/dashboard";
import LoaderComp from "component/loader/LoaderComp";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";

const DashboardAttendentPopup = ({ title, closeDrawer, headerData }) => {
  const [loading, setLoading] = useState(true);
  const { userPermission } = usePermissions(mappedPermissionObj.Dashboard);
  const [searchItem, setSearchItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const selector = useSelector((state) => state.dashboardReducers.teampopup);
  const dispatch = useDispatch();

  useEffect(() => {
    if(title === "Team Attendance"){
      dispatch(TeamAttendPop("team",setLoading));
    }else{
      dispatch(TeamAttendPop("all" , setLoading));
    }
  }, [title]);

  useEffect(() => {
    setFilteredData(
      selector.filter((obj) =>
        obj.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, selector]);

  return (
    <div>
      <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]  ">
        <div
          className="min-w-[40%] h-full p-5 bg-white flex-flex-col
         space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
        >
          <div className="flex justify-between px-[0.88rem] py-[0.62rem]  border-b border-b-[#E2E8F0]">
            <div>
              <p className="text-[#031B59] text-lg font-extrabold">{title}</p>
            </div>
            <div>
              <RxCross2
                onClick={closeDrawer}
                className="text-[#191919] text-xl"
              />
            </div>
          </div>
          <div
            className="  flex justify-between px-[0.88rem] py-[0.62rem]
           border border-[#E2E8F0] rounded-[2.5rem] dashboard_search"
          >
            <div className="w-full">
              <input
                type="search"
                placeholder="search"
                className="text-[#A1A1A1] w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div>
              <CiSearch className="text-[#A1A1A1] text-lg"
                searchItem={searchItem}
                setSearchItem={setSearchItem}
                currentResource={userPermission}
              />
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="h-full overflow-y-auto">
              <table className="table-auto w-full text-black">
                <thead>
                  <tr>
                    {headerData.map((item, id) => (
                      <th
                        key={id}
                        className="font-medium sticky top-0 bg-[#F2F6FF]
                           text-[#686868] px-6 py-3 text-[0.81em] text-left"
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>

                {loading ? (
                  <div
                    data-testid="loader"
                    className="absolute top-1/2 right-[22rem] transform -translate-x-1/2 -translate-y-1/2"
                  >
                    <LoaderComp />
                  </div>
                ) : (
                  <tbody>
                    {filteredData.length === 0 ?
                      <td colSpan={7} className="text-black relative z-10 flex justify-center
                       items-center h-[10rem] left-[280px]">
                        <span>No Data found</span>
                      </td> : filteredData?.map((item, id) => (
                        <tr key={id}>
                          <td className="border-b px-6 py-4">
                            <div className="flex items-center gap-2">
                              <ProfileAvtar name={item?.name} />
                              {item?.name &&
                          item.name.replace(/^\w/, (c) => c.toUpperCase())
                                ? item?.name
                                : "User"}
                            </div>
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.department &&
                        item.department.replace(/^\w/, (c) => c.toUpperCase())
                              ? item?.department
                              : "IT Department"}
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.location &&
                        item.location.replace(/^\w/, (c) => c.toUpperCase())
                              ? item?.location
                              : "Jaipur"}
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.designation &&
                        item.designation.replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )
                              ? item?.designation
                              : "Software Engineer"}
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.statuss &&
                        item.statuss.replace(/^\w/, (c) => c.toUpperCase())
                              ? item.statuss
                              : "Active"}
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.punchin &&
                        item?.punchin.replace(/^\w/, (c) => c.toUpperCase())
                              ? item?.punchin
                              : "9:00 AM"}
                          </td>
                          <td className="border-b px-6 py-4">
                            {item?.delay &&
                        item?.delay.replace(/^\w/, (c) => c.toUpperCase())
                              ? item?.delay
                              : "Late"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardAttendentPopup.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  headerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectorData: PropTypes.shape({
    alluser: PropTypes.number.isRequired,
    activeruser: PropTypes.number.isRequired,
    onleave: PropTypes.number.isRequired,
    halfday: PropTypes.number.isRequired,
    wfh: PropTypes.number.isRequired,
  }).isRequired,
};

export default DashboardAttendentPopup;
