import ProfileAvtar from 'component/common/ProfileAvtar';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { GoPlusCircle } from 'react-icons/go';
import { IoSettingsSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { assignRoleForAccessPopup, fetchRoleDataWithoutPage, showUsersOfSpecificRole } from 'redux/appThunk/Admin/permission';
import AddAccessMembers from './AddAccessMembers';

const ViewRoleRightPermission = ({ AllPermissions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { UserByPermission } = useSelector((state) => state.permissionReducer);
  const [manageState, setManageState] = useState(false);
  const [roles, setRoles] = useState(false);
  const [againData, setAgainData] = useState(false);
  const [userPop, setUserPop] = useState(false);
  const [selectUser, setSelectUser] = useState([]);
  const { allRoles } = useSelector(
    (state) => state.permissionReducer
  );
  const AllRoles = allRoles?.filter((item) => item?.id !== AllPermissions?.id) || [];

  useEffect(() => {
    dispatch(showUsersOfSpecificRole(AllPermissions?.name));
  }, [AllPermissions, againData]);

  useEffect(() => {
    dispatch(fetchRoleDataWithoutPage());
  }, [dispatch]);

  const handleManage = () => {
    setManageState(true);
  };

  const handleAddUserPop = () => {
    setUserPop(true);
  };

  const handleCancel = () => {
    setManageState(false);
    setSelectUser([]);
  };

  const handleOpenRoles = () => {
    setRoles(!roles);
  };

  const handleSelectAllUser = () => {
    setSelectUser((prevUsersData) => {
      if (UserByPermission.every(user => prevUsersData.includes(user))) {
        return prevUsersData.filter(user => !UserByPermission.includes(user));
      } else {
        return [...new Set([...prevUsersData, ...UserByPermission])];
      }
    });
  };

  const handleSelectUser = (data) => {
    setSelectUser((prevUsersData) => {
      if (prevUsersData.includes(data)) {
        return prevUsersData.filter((user) => user !== data);
      } else {
        return [...prevUsersData, data];
      }
    });
  };

  const handleMoveRole = async (item) => {
    setRoles(false);
    await dispatch(assignRoleForAccessPopup(selectUser?.map(item => item?.id), item, setAgainData));
    await setSelectUser([]);
    setManageState(false);
  };

  return (
    <div className='w-full rounded-lg border p-3'>
      <AddAccessMembers
        userDrop={userPop}
        setUserDrop={setUserPop}
        roleName={AllPermissions?.name ? AllPermissions?.name : ""}
        setAgainData={setAgainData}
      />
      <div className='flex justify-between items-center relative'>
        {selectUser?.length > 0 ?
          (<button className='border h-[2.1rem] px-2 flex gap-2 items-center justify-center rounded-xl'
            onClick={handleOpenRoles}
          >
            {t('selectRole')}
            <span><FaAngleDown /></span>
          </button>)
          : (<h1 className='font-bold text-[#031b59] text-[1.2rem]'>{t('accessPeople')}</h1>)}
        {manageState ? (
          <div className='text-[1.1rem] text-[#031b59] cursor-pointer flex items-center gap-4'>
            <div className='border h-[2.1rem] w-[2.1rem] flex items-center justify-center rounded-xl hover:text-white hover:bg-[#031b59]'
              onClick={handleAddUserPop}
            >
              <GoPlusCircle />
            </div>
            <button className='border h-[2.1rem] px-2 flex items-center justify-center rounded-xl'
              onClick={handleCancel}
            >
              {t('cancel')}
            </button>
          </div>
        ) : (<div className='text-[1.1rem] text-[#031b59] cursor-pointer flex gap-1 items-center'
          onClick={handleManage}
        >
          <span>
            <IoSettingsSharp />
          </span>
          <span>
            {t('manageAccess')}
          </span>
        </div>)}
        {roles && (
          <div className='bg-white absolute shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] border border-[#DEE4EB]
          w-full max-w-[14rem] max-h-[20rem] overflow-y-auto custom_scroll rounded-md top-10 z-20'>
            {AllRoles?.map((item) => (
              <div key={item?.name} className='h-[2.5rem] capitalize flex items-center pl-4 hover:bg-[#F2F6FF] cursor-pointer'
                onClick={() => handleMoveRole(item?.name)}
              >
                {item?.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='mt-2'>
        <div className='overflow-x-auto custom_scroll h-[74vh] overflow-y-scroll'>
          <table className="w-full p-3 bg-white rounded-lg text-left relative">
            <thead className="flex-0 text-left z-10 left-0 sticky top-0">
              <tr
                className="max-h-[3.125rem] p-2 text-[#686868]
                text-sm  font-normal bg-[#F2F6FF] border-collapse left-0 z-30"
              >
                <th
                  className="lg:w-[18.224rem] min-w-[18.224rem]
                  max-h-[3.125rem] h-full px-2 py-4 bg-[#F2F6FF] flex gap-3 items-center"
                >
                  {manageState && (<div className='flex items-center'>
                    <input
                      className="h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                        accent-[#031B59!important] checked:bg-[#031B59!important]
                        checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                        checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                        checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                        checked:after:rotate-[45deg]
                        checked:after:border-[0.15rem] checked:after:border-l-0
                        checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                        checked:after:bg-transparent checked:after:content-[''] cursor-pointer" type="checkbox"
                      onChange={handleSelectAllUser}
                      checked={(UserByPermission?.length === selectUser?.length) && UserByPermission?.length > 0}
                    />
                  </div>)}
                  {t("employeeName")}
                </th>
                <th
                  className="lg:w-[14rem] min-w-[9rem] max-h-[3.125rem] h-full p-2  bg-[#F2F6FF] "
                >
                  {t("designation")}
                </th>
              </tr>
            </thead>
            {UserByPermission?.length > 0 ? (<>
              {/* {!loder ? */}
              <tbody className="p-2 text-sm text-left font-normal flex-0 w-full ">
                <>
                  {UserByPermission?.map((data, index) => {
                    return (
                      <Fragment key={`permission-${index}`}>
                        <tr key={`resource-row-${index}`} className='h-[50px] even:bg-[#F8FAFC]'
                        >
                          <td className='max-h-[3.125rem] h-full px-2 py-4 capitalize'>
                            <div className='h-full flex gap-3 items-center'>
                              {manageState && (<div className='flex items-center'>
                                <input
                                  className="h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
                                  accent-[#031B59!important] checked:bg-[#031B59!important]
                                  checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
                                  checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
                                  checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
                                  checked:after:rotate-[45deg]
                                  checked:after:border-[0.15rem] checked:after:border-l-0
                                  checked:after:border-t-0 checked:after:border-solid checked:after:border-white
                                  checked:after:bg-transparent checked:after:content-[''] cursor-pointer" type="checkbox"
                                  onChange={() => handleSelectUser(data)}
                                  checked={(selectUser?.map(item => item?.id))?.includes(data?.id)}
                                />
                              </div>)}
                              <div className='flex items-center gap-2'>
                                <ProfileAvtar
                                  height="2rem"
                                  width="2rem"
                                  name={data?.name ? data?.name : "User"}
                                />
                                <div>
                                  <h1 className='text-[1.1rem] font-medium text-[#031b59]'>{data?.name ? data?.name : "User"}</h1>
                                  <span className='text-[#A1A1A1]'>{data?.empCode ? data?.empCode : "---"}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='
                             max-h-[3.125rem] h-full px-2 capitalize '>
                            <div className='w-full flex items-center justify-between'>
                              <p className='font-normal text-[1rem] text-[#031b59] py-4 capitalize
                              '>{data?.designation ? data?.designation : '---'}</p>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  }
                  )}
                </>
              </tbody>
            </>) : (
              <div
                className="w-full flex items-center
                justify-center font-medium mt-5 ml-32"
              >
                {t("no_data_found")}
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewRoleRightPermission;

ViewRoleRightPermission.propTypes = {
  AllPermissions: PropTypes.any,
};
