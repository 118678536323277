import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  deleteDocument,
  fetchProfileAllDocuments,
  uploadHistoryDocument
} from "redux/appThunk/Admin/profileDocument";
import LoaderComp from "component/loader/LoaderComp";
import { employeHistorySchema } from "redux/validator/Profile/document";
import { BiCloudUpload } from "react-icons/bi";

const Releving = ({ setCurrentStep, identiityData }) => {
  const formData = new FormData();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [handelButton, setHandelButton] = useState(false);
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const deleteFnc = (url, column) => {
    const tempData = {
      url: url,
      column: column,
    };
    setHandelButton(true);
    dispatch(deleteDocument(tempData, id,setLoading, userData?.id));
  };

  useEffect(() => {
    dispatch(fetchProfileAllDocuments(id, setLoading, userData?.id));
  }, []);

  const initialvalues = {
    relieving_letters: [],
    experience_letters: [],
    bank_statements: [],
  };

  const [headings, setHeadings] = useState([
    {
      Name: "Releving Letter",
      value: "",
      initialValuesName: "relieving_letters",
      gettingValues: [],
      initialValues: 'relieving_letters',
      error: "",
    },
    {
      Name: "Experience Letter",
      value: "",
      initialValuesName: "experience_letters",
      gettingValues: [],
      initialValues: 'experience_letters',
      error: "",
    },
    {
      Name: "Bank Statement",
      value: "",
      initialValuesName: "bank_statements",
      gettingValues: [],
      initialValues: 'bank_statements',
      error: "",
    },
  ]);

  function checkHistoryData() {
    return headings?.some((obj) => obj?.value?.length >= 1);
  }

  function EducationDataSubmit() {
    const isData = headings?.some((obj) => obj?.gettingValues?.length === 0);
    if(isData){
      setHeadings((obj) => obj?.map((item) => item?.gettingValues?.length === 0 ? {
        ...item,error: "please add at least one document"}
        : item));
    }else if (!handelButton) {
      if (checkHistoryData) {
        setCurrentStep(5);
      }
    } else {
      headings[0]?.gettingValues?.map((obj) => {
        const file = new File(["foo"], obj.name, {
          type: obj.type,
        });
        formData.append("background_verification[relieving_letters][]", file);
      });
      headings[1]?.gettingValues?.map((item) => {
        const file = new File(["foo"], item.name, {
          type: item.type,
        });
        formData.append("background_verification[experience_letters][]", file);
      });
      headings[2]?.gettingValues?.map((file) => {
        const files = new File(["foo"], file.name, {
          type: file.type,
        });
        formData.append("background_verification[bank_statements][]", files);
      });

      dispatch(
        uploadHistoryDocument(id, formData, setLoading, setHandelButton, userData?.id)
      );
    }
  }

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: employeHistorySchema,
    onSubmit: () => {
      EducationDataSubmit();
    },
  });

  const { handleSubmit, handleChange, setFieldValue, errors, touched, values } = Formik;

  useEffect(() => {
    if (identiityData) {
      setHeadings((obj) =>
        obj.map((item) => ({
          ...item,
          gettingValues: identiityData[item.initialValuesName] ?
            identiityData[item.initialValuesName] : [],
        }))
      );
      setHandelButton(false);
    }
  }, [identiityData]);

  const handelEducation = (e) => {
    handleChange(e);
    const Name = e.target.name;
    const file = e.target.files[0];

    if (file || Name) {
      setHandelButton(true);
    }
    if(file){
      setFieldValue(Name,[...values[Name],file]);
    }

    if (headings?.filter((obj) => obj?.initialValues === Name)[0].gettingValues?.length <= 2) {
      setHeadings((obj) => obj?.map((item) => item?.initialValues === Name ? {
        ...item, gettingValues: [...item.gettingValues, file],error: ""}
        : item));
    }
  };

  return (
    <div>
      <div className=" bg-white p-6 overflow-y-auto no-scrollbar h-[350px]">
        {Loading ? (
          <LoaderComp sx={{ marginTop: " 40px " }} />
        ) : (
          <form onSubmit={handleSubmit}>
            {headings.map((headings, i) => (
              <div key={i} className="mt-5">
                <label className="font-medium">
                  <h2>{headings.Name}</h2>
                </label>
                <label
                  role="button"
                  className="border border-dashed h-[120px] flex flex-col gap-3
                    justify-center items-center rounded w-full"
                >
                  <input
                    type="file"
                    multiple
                    name={headings.initialValues}
                    className="hidden"
                    onChange={handelEducation}
                  />
                  <BiCloudUpload className='text-[#A1A1A1] text-3xl'/>
                  <div className="flex items-center">
                    <span>
                      <b
                        className={`text-[${
                          checkHistoryData() ? "grey" : "#031B59"
                        }]`}
                      >
                        {t("Upload_File")}
                      </b>
                    </span>
                  </div>
                </label>

                {headings.error
                && <div className="text-red-500">{headings.error}</div>}
                {headings[headings?.initialValues]?.length >= 1 && (
                  <ul>
                    {headings[headings?.initialValues]?.map((file,index) => (
                      <>
                        <li key={file?.name} className="mt-2 w-6/6 p-2 border border-dotted border-gray-300
                  rounded-md flex justify-between items-center">
                          <a href={file?.url} target="blank" >{file?.name}</a>
                          <button type="button"
                            className="text-gray-500"
                            onClick={() => deleteFnc(file.url,headings.initialValuesName)}>
                            <RxCross2 />
                          </button>
                        </li>
                        {errors[headings?.initialValues] && errors[headings?.initialValues][index]?.name &&
                      touched[headings?.initialValues] && touched[headings?.initialValues][index]?.name &&
                        <div>{errors[headings?.initialValues][index].name}</div>}
                      </>
                    ))}
                  </ul>
                )}
                {headings?.gettingValues?.length <= 2 && (
                  <ul>
                    {headings?.gettingValues?.map((file) => (
                      <li
                        key={file?.name}
                        className="mt-2 w-6/6 p-2 border border-dotted border-gray-300
                    rounded-md flex justify-between items-center"
                      >
                        <a href={file?.url} target="blank">
                          {file?.name}
                        </a>
                        <button
                          type="button"
                          className="text-gray-500"
                          onClick={() =>
                            deleteFnc(file.url, headings.initialValuesName)
                          }
                        >
                          <RxCross2 />
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <div className="flex justify-end static">
              <button
                className="mt-10 h-[2.813rem] w-[7.625rem] p-2
              text-gray-500"
                onClick={() => setCurrentStep(3)}
              >
                {t("back")}
              </button>
              <button
                onClick={EducationDataSubmit}
                className="mt-10 h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E]
                text-white rounded-full"
              >
                {handelButton === false ? t('next') : t('save')}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Releving;

Releving.propTypes = {
  setCurrentStep: PropTypes.number,
  identiityData: PropTypes.object,
};
