import { PROFILE_PICTURE, SET_EMPEYE, SET_PERSONAL_STATUS, STATUS } from "redux/actions/types";

const initialstate = {
  allUser: [],
  profileData: [],
  Country: {},
  CountryState: {},
  city: [],
  manager: [],
  projectList: [],
  totalEmployeesNumber: 0,
  profile: {},
  status: 0,
  eyeEmp: {},
  personalStatus: 0,
  profileDocument: "",
  newCountryFullName: [],
  selectRes: [],
  selectAction: [],
  isLoading: false,
  usersList: [],
};

export const profileReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "USER_PROFILE":
    return { ...state, profileData: payload };
  case "UPDATE_PROFILE":
    return {
      ...state,
      statusData: payload,
    };
  case "SET_NEW_COUNTRY":
    return {
      ...state,
      newCountryFullName: payload,
    };
  case "SET_ALL_EMPLOYEE_DATA":
    return {
      ...state,
      allUser: payload,
    };
  case "SET_COUNTRY":
    return {
      ...state,
      Country: payload,
    };
  case "SET_COUNTRY_STATE":
    return {
      ...state,
      CountryState: payload,
    };

  case "SET_CITY":
    return {
      ...state,
      city: payload,
    };

  case "SET_MANAGER_NAMES":
    return {
      ...state,
      manager: payload,
    };

  case "SET_PROJECT_LIST":
    return {
      ...state,
      projectList: payload,
    };

  case "SET_USERS_NAMES":
    return {
      ...state,
      usersList: payload,
    };

  case "SET_EMPLOYEE_NUMBER":
    return {
      ...state,
      totalEmployeesNumber: payload,
    };
  case PROFILE_PICTURE:
    return {
      ...state,
      profile: payload,
    };

  case STATUS:
    return {
      ...state,
      status: payload,
    };

  case SET_EMPEYE:
    return {
      ...state,
      eyeEmp: payload,
    };

  case "RESOURCES_ACTION":
    return {
      ...state,
      selectRes: payload,
    };

  case "PROJECT_ACTION":
    return {
      ...state,
      selectAction: payload,
    };

  case SET_PERSONAL_STATUS:
    return {
      ...state,
      personalStatus: payload,
    };

  case "SET_POFILE_DOCUMT_DATA":
    return {
      ...state,
      profileDocument: payload,
    };
  case "IS_LOADING":
    return {
      ...state,
      isLoading: payload,
    };

  default:
    return state;
  }
};
