import React from "react";

const SvgWrong = () => {
  return(

    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path d="M14.3873 5.33625C14.6793 5.04423 14.6793 4.57077 14.3873 4.27875C14.0952
  3.98673 13.6218 3.98673 13.3298 4.27875L10.3731 7.23539C9.9826 7.62592 9.34943
   7.62592 8.95891 7.23539L6.00226 4.27875C5.71024 3.98673 5.23679 3.98673 4.94477
   4.27875C4.65275 4.57077 4.65274 5.04423 4.94477 5.33625L7.90141 8.29289C8.29193
   8.68342 8.29193 9.31658 7.90141 9.70711L4.94477 12.6638C4.65274 12.9558 4.65275
   13.4292 4.94477 13.7213C5.23679 14.0133 5.71024 14.0133 6.00227 13.7213L8.95891
   10.7646C9.34943 10.3741 9.9826 10.3741 10.3731 10.7646L13.3298 13.7213C13.6218
    14.0133 14.0952 14.0133 14.3873 13.7213C14.6793 13.4292 14.6793 12.9558 14.3873
    12.6638L11.4306 9.70711C11.0401 9.31658 11.0401 8.68342 11.4306 8.29289L14.3873 5.33625Z" fill="#FF0000"/>
    </svg>
  );
};

export default SvgWrong;
