import React from "react";

/* eslint-disable max-len */
const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M12 11.9687L9.23151 9.20026M9.23151 9.20026C9.67586 8.7559 10.0283 8.22837
        10.2688 7.6478C10.5093 7.06722 10.6331 6.44496 10.6331 5.81654C10.6331 5.18813 10.5093 4.56587 10.2688 3.98529C10.0283 3.40471 9.67586 2.87719 9.23151 2.43283C8.78715 1.98847 8.25962 1.63599 7.67905 1.39551C7.09847 1.15503 6.47621 1.03125 5.84779 1.03125C5.21938 1.03125 4.59712 1.15503 4.01654 1.39551C3.43596 1.63599 2.90844 1.98847 2.46408 2.43283C1.56666 3.33025 1.0625 4.5474 1.0625 5.81654C1.0625 7.08568 1.56666 8.30284 2.46408 9.20026C3.3615 10.0977 4.57865 10.6018 5.84779 10.6018C7.11693 10.6018 8.33409 10.0977 9.23151 9.20026Z"
        stroke="#A1A1A1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Search;
