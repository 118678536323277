import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Helper from "api/Helper";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { allEmployeeList } from "redux/actions/action";
import { useTranslation } from "react-i18next";
import { IoCall } from "react-icons/io5";
import apiUrl from "api/apiUrl";
import Popup from "component/common/Popup";
import { addClientSchema } from "redux/validator/admin/client";
import ToastServices from "ToastServices";
import { fetchClientsData } from "redux/appThunk/Admin/client";
import { RxCross1 } from "react-icons/rx";

export default function AddClient({ setAddClientPopUp }) {
  const employeeData = useSelector(
    (state) => state.employeeReducer.employeeData
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [customError, setCustomError] = useState("");
  const [submitOnce, setSubmitOnce] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const [divContactBorder, setDivContactBorder] = useState(false);

  const handleContactFocus = () => setDivContactBorder(true);
  const handleContactBlur = () => setDivContactBorder(false);

  const initialvalues = {
    fullName: "",
    contactNo: "",
    email: "",
    role: "client",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addClientSchema,
    onSubmit: async () => {
      if (customError) {
        return;
      }
      addClientDialoBox();
    },
  });
  const fetchData = async () => {
    const path = "fetch_all_emails";
    try {
      const { response } = await Helper.get(path);
      dispatch(allEmployeeList(response.active_users));
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
    }
  };
  const { values } = Formik;

  useEffect(() => {
    fetchData();
  }, []);

  const handleEmployee = (val) => {
    setCustomError("");
    const email = employeeData.find((data) => data?.email === val);
    if (email) {
      setCustomError("User already Exists!");
    }
  };

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setAddClientPopUp(false);
    }
  };
  const addClientDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  const checkData = {
    Name: values?.fullName,
    Email: values?.email,
    "Contact Number": values?.contactNo,
    "Date of Birth": values.dob,
  };

  const employeesData = {
    user: {
      full_name: values?.fullName,
      contact_no: values?.contactNo,
      email: values?.email,
      date_of_birth: values.dob,
      role: values?.role,
    },
  };

  const submit = async () => {
    setSubmitOnce(true);
    addClientDialoBox();
    const path = apiUrl.sign;
    const { status } = await Helper.post(employeesData, path);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        type: "success",
        message: "Client Added Successfully !",
        autoClose: 3000,
      });
      dispatch(fetchClientsData());
    } else {
      ToastServices.showToast({
        type: "error",
        message: "Failed To Add New Client",
        autoClose: 3000,
      });
    }
    setSubmitOnce(false);
    setAddClientPopUp(false);
  };

  const handleOnChange = (e) => {
    Formik.handleChange(e);
    handleEmployee(e.target.value);
  };

  const handleOnBlurChange = (e) => {
    Formik.handleBlur(e);
    handleEmployee(e.target.value);
  };

  const handleOnKeyDown = (e) => {
    if (
      !/\d/.test(e.key) &&
    e.key !== "Backspace" &&
    e.key !== "Delete"
    ) {
      e.preventDefault();
    }
    enterClick(e);
  };

  return (
    <div
      className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <h2 className="font-extrabold text-xl text-[#031B59]">
              {t("addNewClient")}
            </h2>
          </div>
          {showPopUp && (
            <Popup
              popupBox={addClientDialoBox}
              title={t("confirmDetail")}
              handleSubmit={submit}
            >
              <div className="w-full flex flex-col border gap-2 capitalize text-black">
                {checkData &&
                  Object.keys(checkData)?.map((val, index) => {
                    return (
                      checkData[val] && (
                        <div
                          key={index}
                          className="flex w-full items-center gap-4 p-2 "
                        >
                          <p className="basis-1/4 w-full">
                            {val} <span>:</span>
                          </p>
                          <p>{checkData[`${val}`]}</p>
                        </div>
                      )
                    );
                  })}
              </div>
            </Popup>
          )}
          <div
            className="border-2 p-2 h-[37px] flex items-center justify-center w-9 cursor-pointer"
            onClick={() => setAddClientPopUp(false)}
          >
            <RxCross1 />
          </div>
        </div>
        <form className="w-full h-full flex flex-col gap-4 justify-between">
          <div className="flex flex-col justify-between h-[80%]">
            <div>
              <div className="flex gap-5">
                <div className="flex flex-col space-y-2 w-[21rem] text-black">
                  <label htmlFor="fullName" className="text-[#313135]">
                    {t("name")}
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px]
                    text-[#191919]"
                    value={Formik.values.fullName}
                    onChange={handleOnChange}
                    onBlur={handleOnBlurChange}
                    onKeyDown={(e) => enterClick(e)}
                  />
                  {Formik.errors.fullName && Formik.touched.fullName ? (
                    <p className="text-[red]">{Formik.errors.fullName}</p>
                  ) : null}
                </div>

                <div className="flex flex-col space-y-2 w-[21rem] text-black">
                  <label htmlFor="email" className="text-[#313135]">
                    {t("email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px]
                    text-[#191919]"
                    value={Formik.values.email}
                    onChange={handleOnChange}
                    onBlur={handleOnBlurChange}
                    onKeyDown={(e) => enterClick(e)}
                  />
                  {customError !== "" && (
                    <p className="text-[red]">{customError}</p>
                  )}
                  {Formik.errors.email && Formik.touched.email ? (
                    <p className="text-[red]">{Formik.errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <div className="w-[21rem] relative flex flex-col space-y-2">
                    <label
                      data-testid="contact_no"
                      htmlFor="Contact"
                      className="text-[#313135]"
                    >
                      {t("contact_no")}
                    </label>
                    <div
                      className={`flex border p-[7px] rounded-lg
             w-[100%] ${divContactBorder ? "border-black border-2" : "null"}
            `}
                    >
                      <div className="flex justify-center items-center relative w-[2rem] text-black">
                        <IoCall className="text-gray-400" />
                      </div>
                      <input
                        data-testid="contactNo"
                        type="text"
                        name="contactNo"
                        id="contactNo"
                        pattern="[0-9]{10}"
                        max="10"
                        maxLength="10"
                        value={Formik.values.contactNo}
                        onChange={Formik.handleChange}
                        onBlur={() => {
                          Formik.handleBlur;
                          handleContactBlur();
                        }}
                        onFocus={handleContactFocus}
                        onKeyDown={handleOnKeyDown}
                        inputMode="numeric"
                        className="w-full mr-3 bg-inherit outline-none p-1 text-black"
                      />
                    </div>
                  </div>
                  {Formik.errors.contactNo && Formik.touched.contactNo ? (
                    <p className="text-[red]">{Formik.errors.contactNo}</p>
                  ) : null}
                </div>
                <div className="flex flex-col space-y-2">
                  <label htmlFor="fullName" className="text-[#313135]">
                    {t("Client Description")}
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    id="description"
                    className="h-[8rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                  />
                </div>
              </div>
            </div>

            <div className="p-5 bg-white flex justify-end overflow-y-hidden">
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="w-[7.625rem] border h-[2.688rem] flex items-center
                 justify-center rounded-full text-[#686868]"
                  onClick={() => setAddClientPopUp(false)}
                >
                  {t("cancel")}
                </button>
                <button
                  className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                  disabled={submitOnce}
                  type="submit"
                  onClick={!submitOnce && Formik.handleSubmit}
                >
                  {t("submit")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

AddClient.propTypes = {
  setAddClientPopUp: PropTypes.any,
};
