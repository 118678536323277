/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CustomTableVariant2 from "component/common/table/CustomTableVariant2";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { getAllTimesheet, getTeamTimesheet } from "redux/appThunk/Admin/status";
import { statusFilterTableDefaultValue } from "utils/Constants";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import FilterColumn from "component/project/FilterColumn";
import { IoFilter } from "react-icons/io5";
import useDebounce from "hooks/useDebounce";
import { alignValues, teamAdminSheet } from "./columnTimeSheet";
import Search from "component/common/accessControlUi/Search";
import Paginate from "component/admin/Employee/Paginate";

export const filterOptions = [
  { key: "fullName", value: "Employee Name" },
  { key: "designation", value: "Designation" },
  // { key: "billable", value: "Billable" },
  { key: "action", value: "Action" },
];

export const disabledFilterState = ["fullName", "designation", "action"];
const UsersTimesheet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { searchData, isLoading, pageCount } = useSelector(
    (state) => state.statusReducer
  );
  const { userPermission } = usePermissions(mappedPermissionObj.Status);
  const { isAdmin, userId } = usePermissions();

  const [searchName, setSearchName] = useState(null);
  const [filter, setFilter] = useState(false);
  const [filterTableValue, setFilterTableValue] = useState(
    statusFilterTableDefaultValue
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
  );
  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );
  const searchFor = useDebounce(searchName, 500);

  useEffect(() => {
    setFilterTableValue(filterTableValue);
  }, [filterTableValue]);

  const handleRefresh = () => {
    setFilters(() =>
      Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
    );
    setColumnsFilters(() => new Set(disabledFilterState));
  };

  const handleFilterChange = (e) => {
    const { id, checked } = e.target;
    setFilters((prev) => {
      if (checked) {
        return { ...prev, [id]: true };
      } else {
        const { [id]: removedFilter, ...rest } = prev;

        return rest;
      }
    });
  };

  const handleSaveFilters = () => {
    setColumnsFilters((prev) => {
      const mergedFilters = new Set([...prev, ...Object.keys(filters)]);

      return mergedFilters;
    });
    setFilter(false);
  };

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAllTimesheet({ name: searchFor, currentPage }));
    } else {
      dispatch(getTeamTimesheet({ name: searchFor, currentPage, id: userId }));
    }
  }, [currentPage, searchFor]);

  const handleOpen = (job) => {
    searchParams.set("name", job?.fullName);
    navigate(`/timeSheet/view/${job?.employeeId}?${searchParams.toString()}`);
  };

  const tempData = [
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, job) => {
        return (
          <>
            <div className="items-start cursor-pointer">
              <div onClick={() => handleOpen(job)}>
                <FaEye leave={job} />
              </div>
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  let arr = [];
  columnFilters?.forEach((v) => arr?.push(v));
  const allTable = [...teamAdminSheet, ...tempData];
  const filterAllTable = allTable?.filter((obj) => arr?.includes(obj?.field));

  return (
    <div
      className="w-[96%] h-[calc(100vh-8.1rem)] bg-white flex flex-col gap-[1.35rem] pt-[1.25rem] px-[1.38rem]
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
    >
      <div className="w-full h-[2.663rem] flex justify-between">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {isAdmin ? "All Employee" : t("details")}
          </h2>
        </div>
        <div className="flex items-center  justify-center lg:space-x-4 md:space-x-2 ">
          <Search
            searchItem={searchName}
            setSearchItem={setSearchName}
            currentResource={userPermission}
          />
          {/* filter may be required in future, so commented */}
          {/* <button
            className="w-[2.688rem] h-[2.688rem] relative  flex items-center
            justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
          >
            <IoFilter
              className="w-6 h-6 stroke-[#031B59]"
              onClick={() => {
                setFilter(!filter);
              }}
            />
          </button> */}
        </div>
      </div>
      <div
        className={`w-full h-full overflow-auto border rounded-lg custom_scroll`}
      >
        {userPermission?.viewAll && (
          <CustomTableVariant2
            newColumn={["Action"]}
            columns={filterAllTable}
            data={searchData}
            isLoading={isLoading}
          />
        )}
      </div>

      {searchData.length > 0 && (
        <div className="w-full h-16 bg-white flex justify-between items-center">
          <div className="text-[#031B59] font-medium">
            {t("showing")} {currentPage} {t("of")} {pageCount}
          </div>
          <Paginate
            currentPage={currentPage}
            initialPageCount={pageCount}
            pageRangeDisplayed={5}
            next=">"
            previous="<"
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default UsersTimesheet;

UsersTimesheet.propTypes = {
  data: PropTypes.object,
  statusdelete: PropTypes.bool,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  setCurrentPage: PropTypes.any,
  currentPage: PropTypes.number,
};
