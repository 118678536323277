import * as Yup from "yup";

export const feedSchema = Yup.object({
  title: Yup.string()
    .required("Title is Required"),
  description: Yup.string()
    .required("Description is Required"),

});

export const addScheduleSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is Required"),
  date: Yup.date()
    .required("Date is Required"),
  startTime: Yup.string().required("Start Time is Required"),
  endTime: Yup.string().required("End Time is Required")
    .test("is-greater", "End time should be greater than start time", function (value) {
      const { startTime } = this.parent;

      return startTime && value > startTime;
    }),
  description: Yup.string()
    .required("Description is Required"),
  addGuest: Yup.array().required("Please Add a Guest First"),
  url: Yup.string().required("Url Is Required"),
  document: Yup.mixed().required('Upload the document first'),

});

