import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { clientInformationSchema } from "redux/validator/admin/client";
import LoaderComp from "../loader/LoaderComp";
import { useDispatch, useSelector } from "react-redux";
import { isShowDialogBoxChange } from "redux/actions/action";
import { AiFillFileImage, AiOutlineRight } from "react-icons/ai";
import { convertDateFormat } from "utils/date";
import Svg18 from "svgComponents/Svg18";
import AddInvoiceDetails from "./AddInvoiceDetails";
import Paginate from "../admin/Employee/Paginate";
import {
  fetchClientsDetail,
  fetchInvoiceData,
  fetchProjectsDetail,
  fetchtimesheet,
  fetchtimesheetData,
  InvoiceUpdate,
  updateClientInfo,
  updateProjectInfomation
} from "redux/appThunk/Admin/client";
import { INVOICE_ARR, awsURL } from "utils/Constants";
import DatePicker from "component/common/DatePicker";
import {
  handleCountryName,
  handleStateChange,
  handlecitychange
} from "redux/appThunk/Admin/companyProfile";
import Resources from "./Resources";
import AddNewProjects from "./AddNewProject";
import { FaArrowLeft } from "react-icons/fa";
import { fetchstatus } from "redux/appThunk/Admin/status";
import { MdCalendarToday } from "react-icons/md";
import { projectInfoSchema } from "schemas";
import { IoFilter } from "react-icons/io5";
import ClientTimesheetFilter from "./ClientTimesheetFilter";
import Search from "component/admin/search_comp/Search";
import useDebounce from "hooks/useDebounce";
import { LuArrowUpDown } from "react-icons/lu";

function ClientName() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [timesheetPage, setTimesheetPage] = useState(1);
  const [document, setDocument] = useState({});
  const [passwordVisible, setPasswordVisible] = useState([false, false]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [renderListing, setRenderListing] = useState(true);
  const [addNewInvoice, setAddNewInvoice] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [reload, setReload] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false);
  const [openResource, setOpenResource] = useState(false);
  const [showInvoice, setShowInvoice] = useState(true);
  const [timesheetSearch, setTimesheetSearch] = useState("");
  const countryname = useSelector(
    (state) => state.CompanyProfileReducer.countryname
  );
  const { selectRes } = useSelector((state) => state.profileReducer);
  const { selectAction } = useSelector((state) => state.profileReducer);

  const statename = useSelector(
    (state) => state.CompanyProfileReducer.statename
  );
  const cityname = useSelector((state) => state.CompanyProfileReducer.cityname);
  const [showFilter, setShowFilter] = useState(false);
  const Year = new Date().getFullYear();
  const { clientTimeSheet } = useSelector((state) => state.ClientsReducer);
  const [userTimesheet, setUserTimesheet] = useState(clientTimeSheet);
  const searchFor = useDebounce(timesheetSearch, 500);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [resourceName, setResourceName] = useState([]);
  const [projectName, setProjectName] = useState([]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("Select a Month");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMonthClick = (month1) => {
    setSelectedMonth(month1);
    setIsOpen(false);
    const month21 = month.indexOf(month1) + 1;
    const filteredData = clientTimeSheet.filter(
      (item) => item.startDate?.split("-")[1] === `0${month21}`
    );
    setUserTimesheet(filteredData);
  };
  useEffect(() => {
    setUserTimesheet(clientTimeSheet);
  }, [clientTimeSheet]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  useEffect(() => {
    dispatch(handleCountryName());
  }, []);

  const monthFilter = Year + "-" + (month.indexOf(selectedMonth) + 1);
  useEffect(() => {
    dispatch(
      fetchtimesheetData(
        id,
        setNewLoading,
        timesheetPage,
        monthFilter != Year + "-" + 0 ? monthFilter : null,
        selectRes,
        selectAction,
        searchFor
      )
    );
  }, [timesheetPage, monthFilter, searchFor]);

  useEffect(() => {
    dispatch(handleStateChange(country));
  }, [country]);

  useEffect(() => {
    dispatch(handlecitychange(state));
  }, [state]);

  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisible(updatedVisibility);
  };

  useEffect(() => {
    dispatch(fetchstatus());
  }, []);

  const {
    clientInformation,
    clientInvoiceData,
    isLoading,
    pageCount,
    clientTimesheetData,
    clickClient,
    allProjects,
    timeSheetCount,
  } = useSelector((state) => state.ClientsReducer);

  const [defaultValue, setDefaultVal] = useState("");
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  window.addEventListener("popstate", () => {
    dispatch(isShowDialogBoxChange(false));
  });

  if (activeTab === 1) {
    dispatch(isShowDialogBoxChange(false));
  }

  const handleHeaderClick = (header) => {
    if (sortBy === header) {
      setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
    } else {
      setSortBy(header);
      setSortOrder("desc");
    }
  };

  useEffect(() => {
    setInvoiceList(clientInvoiceData);
  }, [clientInvoiceData]);

  const sortData = (data, sortBy, sortOrder) => {
    if (data && data.length > 0) {
      const sorted = invoiceList.sort((a, b) => {
        const valueA = a[sortBy] || "";
        const valueB = b[sortBy] || "";
        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB, undefined, { numeric: true });
        } else {
          return valueB.localeCompare(valueA, undefined, { numeric: true });
        }
      });

      return sorted;
    }
  };

  useEffect(() => {
    if (invoiceList) {
      const sorted = sortData(invoiceList, sortBy, sortOrder);
      setInvoiceList(sorted);
    }
  }, [sortBy, sortOrder, currentPage]);

  const formikInitialvalues = {
    ProjectName: "",
    description: "",
    startDate: "",
    endDate: "",
    clientName: "",
    clientId: "",
    currency: "",
    amount: "",
    billingType: "",
    status: "",
  };

  const FormikOne = useFormik({
    initialValues: formikInitialvalues,
    validationSchema: projectInfoSchema,
    onSubmit: async (values) => {
      updateProjectInfo(values);
    },
  });

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    clientType: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    timeZone: "",
    onboardDate: "",
    onboardedBy: "",
    documentUrl: "",
    goodsAndServicesTax: "",
    taxRate: "",
    password: "",
    passwordConfirmation: "",
  };

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: clientInformationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append("client[name]", values.name);
      formData.append("client[document]", document);
      formData.append("client[email]", values.email);
      formData.append("client[phone_number]", values.phoneNumber);
      formData.append("client[street_address]", values.streetAddress);
      formData.append("client[country]", values.country);
      formData.append("client[state]", values.state);
      formData.append("client[city]", values.city);
      formData.append("client[client_type]", values.clientType);
      formData.append("client[onboard_date]", values.onboardDate);
      formData.append("client[onboarded_by]", values?.onboardedBy);
      formData.append(
        "client[goods_and_services_tax]",
        values.goodsAndServicesTax
      );
      formData.append("client[tax_rate]", values.taxRate);
      formData.append("client[password]", values.password);
      formData.append(
        "client[password_confirmation]",
        values.passwordConfirmation
      );
      formData.append("client[time_zone]", values.timeZone);
      if (submitOnce) {
        dispatch(updateClientInfo(formData, id, setSubmitOnce, setReload));
      }
    },
  });
  const handleClickresources = (projectId) => {
    setOpenResource(true);
    setRenderListing(false);
    setProjectId(projectId);
  };
  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
  } = Formik;

  const handleOnChange = (e) => {
    handleChange(e);
    setSubmitOnce(true);
  };
  useEffect(() => {
    if (Object.keys(clientInformation)?.length > 0) {
      fieldValues();
      setCountry(clientInformation?.country);
      setState(clientInformation?.state);
    }
  }, [clientInformation]);
  const handleupdateStatus = (e, data) => {
    const formdata = new FormData();
    formdata.append("[invoice][status]", e.target.value);
    dispatch(InvoiceUpdate(formdata, data));
  };

  const renderRow = (data, index) => {
    if (Array.isArray(data?.gstFiles) && data.gstFiles.length > 0) {
      const firstFile = data.gstFiles[0];
      if (typeof firstFile === "string") {
        firstFile.split("/");
      }
    }
    const currentDate = new Date();

    return (
      <>
        {activeTab === 4 ? (
          data.statuses.map((item) => {
            return (
              <tr className="h-[2rem] border border-[#E2E8F0]" key={data.id}>
                <td
                  className={`lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] sticky 
          left-0 ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                >
                  {activeTab === 3 ? (
                    <div>{data?.invoiceNumber || "---"}</div>
                  ) : (
                    <>
                      <div className="flex flex-col gap-1 h-[1rem]">
                        <div className="h-4">{data?.employeeName || "---"}</div>
                        <div className="text-[#A1A1A1]">
                          {data?.email || "---"}
                        </div>
                      </div>
                    </>
                  )}
                </td>

                <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] capitalize h-[1rem]">
                  {activeTab === 3 ? (
                    <div>
                      {" "}
                      <span>{data?.currency === "rupee" ? "₹" : "$"}</span>
                      &nbsp;{data?.amount || "---"}
                    </div>
                  ) : (
                    <div>{data?.projectName || "---"}</div>
                  )}
                </td>

                <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 h-[1rem]">
                  {activeTab === 3 ? (
                    <td>
                      {" "}
                      {(data?.invoiceDate &&
                        convertDateFormat(data?.invoiceDate)) ||
                        "---"}
                    </td>
                  ) : (
                    <td>{item.statusDate}</td>
                  )}
                </td>

                <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 h-[1rem]">
                  {activeTab === 3 ? (
                    <div
                      style={{
                        color:
                          new Date(data?.dueDate).toLocaleDateString() ===
                          currentDate.toLocaleDateString()
                            ? "red"
                            : "#031B59",
                      }}
                    >
                      {(data?.dueDate && convertDateFormat(data?.dueDate)) ||
                        "---"}
                    </div>
                  ) : (
                    <div>
                      {item.tasks.length > 0
                        ? item.tasks[0].workingHours
                          ? item.tasks[0].workingHours
                          : "0"
                        : "0"}
                    </div>
                  )}
                </td>
                <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 h-[1rem]">
                  {activeTab === 3 ? (
                    <div
                      style={{
                        color:
                          data?.status === "paid"
                            ? "green"
                            : data?.status === "pending"
                              ? "orange"
                              : "red",
                      }}
                    >
                      <select
                        defaultValue={data?.status}
                        onChange={(e) => handleupdateStatus(e, data)}
                      >
                        <option value="pending">pending</option>
                        <option value="paid">paid</option>
                        onboarded_by <option value="cancel">cancel</option>
                      </select>
                    </div>
                  ) : (
                    <div>{data?.workinghours || "---"}</div>
                  )}
                </td>
                {activeTab === 3 && (
                  <td
                    className={`lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 flex items-center sticky
            right-0 ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
                  >
                    <AiFillFileImage
                      fontSize="30px"
                      className="mx-2"
                      color="skyBlue"
                    />
                    {Array.isArray(data?.gstFiles) &&
                    data.gstFiles.length > 0 ? (
                        <span className="text-xs">
                          {data.gstFiles[0].name.slice(0, 12) + "..."}
                        </span>
                      ) : (
                        "---"
                      )}
                  </td>
                )}
              </tr>
            );
          })
        ) : (
          <tr
            className="h-[3.125rem] even:bg-[#F8FAFC] text-[#031B59] border border-[#E2E8F0] "
            key={data.id}
          >
            <td
              className={`lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 sticky
        left-0 ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
            >
              {activeTab === 3 && <div>{data?.invoiceNumber || "---"}</div>}
            </td>

            <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 capitalize">
              {activeTab === 3 && (
                <div>
                  {" "}
                  <span>{data?.currency === "rupee" ? "₹" : "$"}</span>
                  &nbsp;{data?.amount || "---"}
                </div>
              )}
            </td>

            <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
              {activeTab === 3 && (
                <td>
                  {" "}
                  {(data?.invoiceDate &&
                    convertDateFormat(data?.invoiceDate)) ||
                    "---"}
                </td>
              )}
            </td>

            <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
              {activeTab === 3 && (
                <div
                  style={{
                    color:
                      new Date(data?.dueDate).toLocaleDateString() ===
                      currentDate.toLocaleDateString()
                        ? "red"
                        : "#031B59",
                  }}
                >
                  {(data?.dueDate && convertDateFormat(data?.dueDate)) || "---"}
                </div>
              )}
            </td>
            <td className="lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2">
              {activeTab === 3 && (
                <div
                  style={{
                    color:
                      data?.status === "paid"
                        ? "green"
                        : data?.status === "pending"
                          ? "orange"
                          : "red",
                  }}
                >
                  <select
                    defaultValue={data?.status}
                    onChange={(e) => handleupdateStatus(e, data)}
                    className="bg-transparent capitalize border-none"
                  >
                    <option value="pending">pending</option>
                    <option value="paid">paid</option>
                    <option value="cancel">cancel</option>
                  </select>
                </div>
              )}
            </td>
            {activeTab === 3 && (
              <td
                className={`lg:min-w-[14rem]  md:w-[8rem] md:p-[1.5rem] p-2 flex items-center sticky
        right-0 ${index % 2 === 0 ? "bg-white" : "bg-[#F8FAFC]"}`}
              >
                <AiFillFileImage
                  fontSize="30px"
                  className="mx-2"
                  color="skyBlue"
                />
                {Array.isArray(data?.gstFiles) && data.gstFiles.length > 0 ? (
                  <span className="text-xs">
                    {data.gstFiles[0].name.slice(0, 12) + "..."}
                  </span>
                ) : (
                  "---"
                )}
              </td>
            )}
          </tr>
        )}
      </>
    );
  };

  function setFields(values) {
    Object.keys(values).forEach((key) => {
      setFieldValue(key, values[key]);
    });
  }

  function fieldValues() {
    setDefaultVal(clientInformation?.fileName);
    const clientInfo = {
      name: clientInformation?.name,
      email: clientInformation?.email,
      clientType: clientInformation?.clientType,
      phoneNumber: clientInformation?.phoneNumber,
      streetAddress: clientInformation?.streetAddress,
      city: clientInformation?.city,
      state: clientInformation?.state,
      country: clientInformation?.country,
      timeZone: clientInformation?.timeZone,
      onboardDate: clientInformation?.onboardDate,
      onboardedBy: clientInformation?.onboardedBy,
      documentUrl: clientInformation?.documentUrl,
      goodsAndServicesTax: clientInformation?.goodsAndServiceTax,
      taxRate: clientInformation?.taxRate,
      passwordConfirmation: clientInformation?.passwordConfirmation,
    };

    setFields(clientInfo);
  }

  useEffect(() => {
    dispatch(fetchClientsDetail(id, setNewLoading));
  }, [id, reload]);

  const handleKeyPress = (e) => {
    const isNumeric = /^\d+$/.test(e.key);
    if (!isNumeric) {
      e.preventDefault();
    }
  };
  const handletaxRate = (e) => {
    const isNumeric = /^[0-9!@#$%^&*()_+{}\\[\]:;<>,.?~\\/-]*$/.test(e.key);
    if (!isNumeric) {
      e.preventDefault();
    }
  };
  const handleUploadFile = (e, name) => {
    e.preventDefault();
    const documentUrl = name;
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    }
  };

  useEffect(() => {
    if (clientTimesheetData) {
      setInvoiceList(clientTimesheetData);
    }
  }, [clientTimesheetData]);

  useEffect(() => {
    if (activeTab === 3) {
      dispatch(fetchInvoiceData(id, currentPage));
    }
  }, [currentPage, activeTab]);

  const buttonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (activeTab === 2) {
      dispatch(fetchProjectsDetail(id, setNewLoading));
    }
  }, [activeTab, reload]);

  const updateProjectInfo = (values) => {
    const json = {
      name: values?.ProjectName,
      description: values?.description,
      domain: values?.domain,
      start_date: values?.startDate,
      currency: values?.currency,
      project_type: values?.project_type,
      billable: values?.billingType,
      end_date: values?.endDate,
      billing_type: values?.billingType,
      client_id: id,
      amount: values?.amount,
      link: values?.projectLink,
      status: values?.status,
    };
    dispatch(
      updateProjectInfomation(
        json,
        projectId,
        setReload,
        setProfileInfoOpen,
        setRenderListing
      )
    );
  };

  useEffect(() => {
    allProjects?.map((item) => {
      if (item.id === projectId) {
        FormikOne.setFieldValue("ProjectName", item?.name);
        FormikOne.setFieldValue("description", item?.description);
        FormikOne.setFieldValue("startDate", item?.startDate);
        FormikOne.setFieldValue("endDate", item?.endDate);
        FormikOne.setFieldValue("currency", item?.currency);
        FormikOne.setFieldValue("amount", item?.amount);
        FormikOne.setFieldValue("billingType", item?.billingType);
        FormikOne.setFieldValue("status", item?.status);
        FormikOne.setFieldValue("projectLink", item?.link);
        FormikOne.setFieldValue("clientName", values?.name);
      }
    });
  }, [projectId]);

  useEffect(() => {
    if (activeTab === 4) {
      dispatch(fetchtimesheet(id, setNewLoading));
    }
  }, [activeTab]);

  const handleFilterApply = () => {
    //  dispatch(fetchtimesheet(id, setNewLoading, selectRes));
    dispatch(
      fetchtimesheetData(
        id,
        setNewLoading,
        timesheetPage,
        monthFilter != Year + "-" + 0 ? monthFilter : null,
        selectRes,
        selectAction,
        timesheetSearch
      )
    );
    setShowFilter(false);
  };

  const [profileInfoOpen, setProfileInfoOpen] = useState(false);

  const ProjectCard = ({ name, date, projectId }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
      <div className="relative p-4 border border-gray-200 rounded-lg shadow-sm bg-white w-full cursor-pointer">
        <div
          className="flex justify-between items-center"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div>
            <h3 className="font-bold text-lg text-[#031B59]">{name}</h3>
            <p className="text-sm text-gray-500">{date}</p>
          </div>
          <AiOutlineRight className="h-5 w-5 text-gray-500" />
        </div>
        {isMenuOpen && (
          <div
            className="absolute top-full left-0 mt-2 w-full  bg-white shadow-lg border border-gray-200
          rounded-lg z-10"
          >
            <div className="p-2 cursor-pointer">
              <button
                className="p-2 bg-[#E2E8F0] hover:bg-blue-100 w-[100%] cursor-pointer text-left"
                onClick={() => {
                  setProfileInfoOpen(true);
                  setRenderListing(false);
                  setProjectId(projectId);
                }}
              >
                {t("projectInfo")}
              </button>
            </div>
            <div className="p-2 cursor-pointer">
              <button
                className="p-2 bg-[#E2E8F0] hover:bg-blue-100 w-[100%] cursor-pointer text-left"
                onClick={() => {
                  handleClickresources(projectId);
                }}
              >
                {t("resources")}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="w-[96%] flex items-center justify-start space-x-12 pb-4">
        <button
          onClick={function () {
            handleTabClick(1);
          }}
        >
          <Link rel="stylesheet" href="#">
            <h5
              className={`text-xl ${
                activeTab === 1
                  ? "text-[#002169] font-bold underline-small"
                  : "text-[#686868]"
              }`}
            >
              {t("clientinfo")}
            </h5>
          </Link>
        </button>
        <button
          onClick={function () {
            handleTabClick(2);
          }}
        >
          <Link rel="stylesheet" href="#">
            <h5
              className={`text-xl ${
                activeTab === 2
                  ? "text-[#002169] font-bold underline-small"
                  : "text-[#686868]"
              }`}
            >
              {t("project")}
            </h5>
          </Link>
        </button>
        <button
          onClick={function () {
            handleTabClick(3);
          }}
        >
          <Link rel="stylesheet" href="#">
            <h5
              className={`text-xl ${
                activeTab === 3
                  ? "text-[#002169] font-bold underline-small"
                  : "text-[#686868]"
              }`}
            >
              {t("invoices")}
            </h5>
          </Link>
        </button>
        <button
          onClick={() => {
            handleTabClick(4);
          }}
        >
          <Link rel="stylesheet" href="#">
            <h5
              className={`text-xl ${
                activeTab === 4
                  ? "text-[#002169] font-bold underline-small"
                  : "text-[#686868]"
              }`}
            >
              {t("timesheet")}
            </h5>
          </Link>
        </button>
      </div>
      {activeTab === 4 && (
        <div
          className="w-[96%] h-[85vh] bg-white space-y-4 flex flex-col p-5 rounded-xl
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div className="w-full h-16 bg-white flex">
            <div className="items-center justify-between relative w-full mx-2 z-50 flex ">
              <div
                className="border border-gray-300 p-2 flex justify-between gap-2 w-48
                items-center cursor-pointer rounded-md"
                onClick={toggleDropdown}
              >
                <span>{selectedMonth}</span>
                <MdCalendarToday className="text-gray-500" />
              </div>
              {isOpen && (
                <ul
                  className="absolute left-0 w-48 top-[50px] bg-white border border-gray-300 mt-1
                  rounded-md z-10 shadow-lg
                 max-h-[13rem] overflow-y-auto custom_scroll"
                >
                  {month.map((item) => (
                    <li
                      key={item}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleMonthClick(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
              <div className="flex items-center gap-4">
                <div>
                  <div className="flex items-center border rounded-full justify-center lg:space-x-4 md:space-x-2 ">
                    <Search
                      searchItem={timesheetSearch}
                      setSearchItem={setTimesheetSearch}
                    />
                  </div>
                </div>
                <div
                  className="p-3 text-xl rounded-full border"
                  onClick={() => setShowFilter(true)}
                >
                  <IoFilter className="" />
                </div>
              </div>
              {showFilter && (
                <div
                  className="absolute right-0 w-96 top-[50px] bg-white border border-gray-300 mt-1
                rounded-md z-10 shadow-lg custom_scroll"
                >
                  <ClientTimesheetFilter
                    setNewLoading={setNewLoading}
                    setShowFilter={setShowFilter}
                    clientTimeSheet={clientTimeSheet}
                    handleFilterApply={handleFilterApply}
                    filteredKeys={selectAction}
                    currentPage={currentPage}
                    selectedResources={selectedResources}
                    selectedProjects={selectedProjects}
                    setSelectedProjects={setSelectedProjects}
                    setSelectedResources={setSelectedResources}
                    resourceName={resourceName}
                    setResourceName={setResourceName}
                    projectName={projectName}
                    setProjectName={setProjectName}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={`overflow-x-scroll w-full
               overflow-y-scroll h-full ${
        isLoading ? "custom_scroller" : "custom_scroll"
        }`}
          >
            <table className=" w-full bg-white rounded-lg overflow-scroll text-left">
              <thead className="border  flex-0 text-left p-2 ">
                <tr
                  className="h-[3.125rem] p-2 text-[#686868]
                        text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0] "
                >
                  <th className="lg:w-[16.5rem] md:w-[8rem] md:p-[1.5rem] p-2 sticky top-0 left-0 z-10 bg-[#F2F6FF]">
                    {t("resources")}
                  </th>
                  <th className="lg:w-[16.5rem] md:w-[8rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                    {t("project")}
                  </th>
                  <th className="lg:w-[16.5rem] md:-[8rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                    {t("date")}
                  </th>
                  <th className="lg:w-[16.5rem] md:w-[8rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                    {t("workedHours")}
                  </th>
                  <th className="lg:w-[16.5rem] md:w-[8rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                    {t("approveHours")}
                  </th>
                </tr>
              </thead>
              <tbody className="p-2 text-sm text-left font-normal w-full relative">
                {userTimesheet?.length > 0 && (
                  <>
                    {newLoading ? (
                      <div className=" absolute top-8 left-0 w-full justify-center flex mt-3">
                        <LoaderComp />
                      </div>
                    ) : (
                      userTimesheet?.map((data, index) =>
                        renderRow(data, index)
                      )
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full h-fit bg-white flex items-end justify-between">
            {userTimesheet?.length > 0 ? (
              <>
                <div className="text-[#031B59] font-medium">
                  Showing {timesheetPage} {t("of")} {pageCount}
                </div>
                <Paginate
                  currentPage={timesheetPage}
                  initialPageCount={timeSheetCount}
                  pageRangeDisplayed={5}
                  next=">"
                  previous="<"
                  setCurrentPage={setTimesheetPage}
                />
              </>
            ) : (
              <div className="w-full flex items-center justify-center font-medium">
                {t("no_data_found")}
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === 3 && (
        <>
          <div
            className="w-[96%] h-[85vh] bg-white space-y-4 flex flex-col p-5 rounded-xl
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] m-5"
          >
            {showInvoice ? (
              <>
                <div className="w-full h-16 bg-white flex justify-between">
                  <div className="flex justify-center items-center">
                    <h2 className="font-extrabold text-xl text-[#031B59]">
                      {t("basic_detail")}
                    </h2>
                  </div>
                  <div className="flex items-center justify-center space-x-4">
                    <button
                      className="h-[3.063rem] w-[7.625rem] p-2 border-[1.5px]
                      border-[#E2E8F0] text-[#031B59] rounded-full"
                      onClick={() => {
                        setShowInvoice(false);
                        setAddNewInvoice(true);
                      }}
                    >
                      {t("add_invoice")}
                    </button>
                  </div>
                </div>
                <div
                  className={`overflow-x-scroll ${
                    isLoading ? "custom_scroller" : "custom_scroll"
                  }`}
                >
                  <table className="h-full w-full p-5 bg-white rounded-lg text-left">
                    <thead className="border flex-0 text-left p-2">
                      <tr
                        className="h-[3.125rem] p-2 text-[#686868] text-sm
                    font-normal bg-[#F2F6FF] border border-[#E2E8F0]"
                      >
                        <th
                          className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem]
                    p-2 sticky left-0 bg-[#F2F6FF] top-0 z-10"
                        >
                          {t("invoice_number")}
                        </th>
                        <th className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                          {t("amount")}
                        </th>
                        <th
                          className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]"
                          onClick={() => handleHeaderClick("invoice_date")}
                        >
                          <div className="flex items-center gap-2 justify-start">
                            <span>{t("invoice_date")}</span>
                            <span className="cursor-pointer">
                              <LuArrowUpDown fontSize="22px" />
                            </span>
                          </div>
                        </th>
                        <th
                          className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]"
                          onClick={() => handleHeaderClick("due_date")}
                        >
                          <div className="flex items-center gap-2 justify-start">
                            <span>{t("due_date")}</span>
                            <span className="cursor-pointer">
                              <LuArrowUpDown fontSize="22px" />
                            </span>
                          </div>
                        </th>
                        <th className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2 sticky top-0 bg-[#F2F6FF]">
                          <div className="flex items-center gap-2 justify-start">
                            <span>{t("Status")}</span>
                            <span
                              className="cursor-pointer"
                              onClick={() => setStatusModal(!statusModal)}
                            >
                              <LuArrowUpDown fontSize="22px" />
                            </span>
                          </div>
                          {statusModal && (
                            <div
                              className="h-fit w-[8rem] bg-white absolute
                        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-10 p-2 border-[1px] rounded-md border-[#E2E8F0]"
                              onMouseEnter={() => setStatusModal(true)}
                              onMouseLeave={() => setStatusModal(false)}
                            >
                              {INVOICE_ARR?.map((option, index) => (
                                <div
                                  style={{ borderRadius: "8px" }}
                                  className="w-full px-3 py-2 m-0
                      hover:bg-[#F2F6FF] cursor-pointer"
                                  key={index}
                                >
                                  {option?.value}
                                </div>
                              ))}
                            </div>
                          )}
                        </th>
                        <th
                          className="lg:w-[10rem] md:w-[6rem] md:p-[1.5rem] p-2
                      sticky top-0 right-0 bg-[#F2F6FF] z-10"
                        >
                          {t("gstInvoice")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="p-2 text-sm text-left font-normal flex-0">
                      {invoiceList?.length > 0 &&
                        invoiceList?.map((data, index) => renderRow(data, index))}
                    </tbody>
                  </table>
                  {isLoading && (
                    <div className="items-center mt-3">
                      <LoaderComp />
                    </div>
                  )}
                </div>
                {!isLoading && (
                  <div className="w-full h-16 bg-white flex justify-between items-center">
                    {invoiceList?.length > 0 ? (
                      <>
                        <div className="text-[#031B59] font-medium">
                          {t("showing")} {currentPage} {t("of")} {pageCount}
                        </div>{" "}
                        <Paginate
                          currentPage={currentPage}
                          initialPageCount={pageCount}
                          pageRangeDisplayed={5}
                          next=">"
                          previous="<"
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <div className="w-full flex items-center justify-center font-medium">
                        {t("no_data_found")}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              addNewInvoice && (
                <AddInvoiceDetails
                  setShowInvoice={setShowInvoice}
                  setRenderListing={setRenderListing}
                  clickClient={clickClient}
                  setAddNewInvoice={setAddNewInvoice}
                />
              )
            )}
            <div></div>
          </div>
        </>
      )}
      {activeTab === 2 && (
        <div
          className="w-[96%] h-[85vh] bg-white space-y-4 flex flex-col p-5 rounded-xl
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          {renderListing && (
            <>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-[#031B59]">
                  {t("allProjects")}
                </h2>
                <button
                  className="bg-transparent text-[#031B59] px-4 py-2 rounded-2xl border border-slate-200"
                  onClick={() => {
                    setShowAddProject(true);
                  }}
                >
                  {t("addProject")}
                </button>
              </div>
              {newLoading ? (
                <div className="flex justify-center ">
                  <LoaderComp />
                </div>
              ) : allProjects.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
                  {allProjects.map((project, index) => (
                    <ProjectCard
                      key={index}
                      name={project.name}
                      date={project.startDate}
                      projectId={project?.id}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  {t("noProjectFound")}
                </div>
              )}
            </>
          )}
          {showAddProject && !profileInfoOpen && (
            <AddNewProjects
              setRenderListing={setRenderListing}
              setShowAddProject={setShowAddProject}
              setNewLoading={setNewLoading}
              setReload={setReload}
            />
          )}
          {profileInfoOpen && (
            <div
              className="w-[96%] h-fit p-5 bg-white flex-flex-col space-y-8
                rounded-[20px]"
            >
              {" "}
              <div className="w-full h-16 bg-white flex justify-between">
                <div className="flex justify-center items-center">
                  <span
                    className="text-[#031B59] mr-4 cursor-pointer"
                    onClick={() => {
                      setProfileInfoOpen(false);
                      setRenderListing(true);
                    }}
                  >
                    <FaArrowLeft />
                  </span>
                  <h3 className="font-extrabold text-xl text-[#031B59] flex items-center">
                    {t("projectInfo")}
                  </h3>
                </div>

                <div className="flex items-center justify-center space-x-4 ">
                  <button
                    className="w-[7.625rem]  h-[2.688rem] flex items-center
                      justify-center rounded-full text-[#686868]"
                    onClick={() => {
                      setProfileInfoOpen(false);
                      setRenderListing(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
                    type="submit"
                    disabled={reload}
                    onClick={FormikOne.handleSubmit}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
              {reload ? (
                <LoaderComp />
              ) : (
                <form className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
                  <div className="flex flex-col gap-[0.25rem] ">
                    <label className="text-[16px]">{t("projectName")}</label>
                    <input
                      className=" p-[10px] border rounded-[0.25rem] outline-none"
                      type="text"
                      placeholder={t("projectName")}
                      name="ProjectName"
                      value={FormikOne?.values?.ProjectName}
                      onChange={FormikOne.handleChange}
                      onBlur={FormikOne.handleBlur}
                    />
                    {FormikOne.errors?.name && FormikOne?.touched?.name && (
                      <p className="text-[1rem] text-red-500">{errors.name}</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-[0.25rem]">
                    <label className="text-[16px]">{t("project_desc")}</label>
                    <input
                      className=" p-[10px] border rounded-[0.25rem] outline-none"
                      type="text"
                      placeholder={t("project_desc")}
                      name="description"
                      value={FormikOne?.values?.description}
                      onChange={FormikOne.handleChange}
                      onBlur={FormikOne.handleBlur}
                    />
                    {FormikOne?.errors?.description &&
                      FormikOne?.touched?.description && (
                      <p className="text-[1rem] text-red-500">
                        {FormikOne?.errors.description}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col gap-[0.25rem] ">
                    <label
                      htmlFor="projectLink"
                      className="text-[16px] text-[#313135] font-medium"
                    >
                      {t("link_any")}
                    </label>
                    <input
                      type="text"
                      name="projectLink"
                      id="link"
                      value={FormikOne?.values?.projectLink}
                      onChange={FormikOne.handleChange}
                      className="h-[3rem]
                        border border-[#E2E8F0] rounded-[5px] flex items-center justify-between p-4
            text-[#1B4FB6]"
                    ></input>
                  </div>

                  <div className="flex flex-col">
                    <div className="grid">
                      <label className="text-[16px]">{t("startDate")}</label>
                      <DatePicker
                        name={"startDate"}
                        value={FormikOne?.values?.startDate}
                        handleChange={FormikOne.handleChange}
                        handleBlur={FormikOne.handleBlur}
                        styles={" mt-[0.25rem]"}
                      />
                    </div>
                    {FormikOne?.errors?.startDate &&
                      FormikOne?.touched?.startDate && (
                      <p className="text-[1rem] text-red-500">
                        {FormikOne?.errors.startDate}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col align-middle">
                    <div className="flex flex-col gap-[0.25rem]">
                      <label className="text-[16px]">{t("currency")}</label>
                      <select
                        className="p-[10px] border rounded-[0.25rem] outline-none bg-white"
                        name="currency"
                        onChange={FormikOne.handleChange}
                        onBlur={FormikOne.handleBlur}
                        value={FormikOne?.values?.currency}
                      >
                        <option value="">{t("selectCurrency")}</option>
                        <option value="dollar">$</option>
                        <option value="canadian">C$</option>
                        <option value="indian">₹</option>
                      </select>
                      {FormikOne?.errors?.currency &&
                        FormikOne?.touched?.currency && (
                        <p className="text-[1rem] text-red-500">
                          {FormikOne?.errors?.currency}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-[0.25rem]">
                      <label className="text-[16px]">{t("amount")}</label>
                      <input
                        className=" p-[10px] border rounded-[0.25rem] outline-none"
                        placeholder={t("amount")}
                        type="text"
                        name="amount"
                        value={FormikOne?.values?.amount}
                        onChange={FormikOne.handleChange}
                        onBlur={FormikOne.handleBlur}
                      />
                      {FormikOne?.errors?.amount &&
                        FormikOne?.touched?.amount && (
                        <p className="text-[1rem] text-red-500">
                          {FormikOne?.errors.amount}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col align-middle">
                    <div className="flex flex-col gap-[0.25rem]">
                      <label className="text-[16px]">{t("billingType")}</label>
                      <select
                        className=" p-[10px] border rounded-[0.25rem] outline-none bg-white"
                        name="billingType"
                        onChange={FormikOne.handleChange}
                        value={FormikOne?.values?.billingType}
                        onBlur={FormikOne.handleBlur}
                      >
                        <option value="">{t("selectBillingType")}</option>
                        <option value="Monthly">{t("monthly")}</option>
                        <option value="Yearly">{t("yearly")}</option>
                      </select>
                      {FormikOne?.errors?.billingType &&
                        FormikOne?.touched?.billingType && (
                        <p className="text-[1rem] text-red-500">
                          {FormikOne?.errors?.billingType}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <label className="text-[16px]">{t("endDate")}</label>
                    <DatePicker
                      name={"endDate"}
                      value={FormikOne?.values?.endDate}
                      handleChange={FormikOne.handleChange}
                      handleBlur={FormikOne.handleBlur}
                      styles={" mt-[0.25rem]"}
                    />
                    {FormikOne?.errors?.endDate &&
                      FormikOne?.touched?.endDate && (
                      <p className="text-[1rem] text-red-500">
                        {FormikOne?.errors?.endDate}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col align-middle">
                    <div className="flex flex-col gap-[0.25rem]">
                      <label className="text-[16px]">{t("Status")}</label>
                      <select
                        name="status"
                        className=" p-[10px] border rounded-[0.25rem] outline-none bg-white"
                        onChange={FormikOne.handleChange}
                        onBlur={FormikOne.handleBlur}
                        value={FormikOne?.values?.status}
                      >
                        <option value="">{t("select")}</option>
                        <option value="start">{t("started")}</option>
                        <option value="complete">{t("completed")}</option>
                        <option value="Not Yet Start">
                          {t("Not Yet Started")}
                        </option>
                        <option value="delay">{t("delayed")}</option>
                      </select>
                      {FormikOne?.errors.status && FormikOne?.touched.status ? (
                        <p className="text-[red]">{FormikOne.errors.status}</p>
                      ) : null}
                    </div>
                  </div>
                </form>
              )}
            </div>
          )}
          {openResource && (
            <Resources
              projectId={projectId}
              setOpenResource={setOpenResource}
              setRenderListing={setRenderListing}
            />
          )}
        </div>
      )}
      {activeTab === 1 &&
        (newLoading ? (
          <LoaderComp />
        ) : (
          <div
            className="w-[96%] h-[fit] p-5 bg-white flex-flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]
          rounded-[20px]"
          >
            <div className="w-full bg-white flex justify-between">
              <div className="flex justify-center items-center">
                <Link rel="canonical" onClick={() => navigate(-1)}>
                  <h3 className="font-normal text-xl text-[#191919] flex items-center"></h3>
                </Link>
                <h2 className="font-extrabold text-xl text-[#031B59]">
                  {t("detail")}
                </h2>
              </div>

              <div className="flex items-center justify-center space-x-4">
                <button
                  className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
                  onClick={() => navigate(-1)}
                >
                  {t("cancel")}
                </button>
                <button
                  className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>

            <form className="w-full grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="clientName" className="text-[#313135]">
                  {t("client_name")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="clientName"
                  value={values.name}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                ></input>
                {errors.name && touched.name ? (
                  <p className="text-[red]">{errors.name}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="email" className="text-[#313135]">
                  {t("email")}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                />
                {errors.email && touched.email ? (
                  <p className="text-[red]">{errors.email}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="" className="text-[#313135]">
                  {t("contact_no")}
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  max="10"
                  maxLength="10"
                  onKeyPress={handleKeyPress}
                  value={values.phoneNumber}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <p className="text-[red]">{errors.phoneNumber}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="clientType" className="text-[#313135]">
                  {t("clientType")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none "
                    type="text"
                    id="clientType"
                    name="clientType"
                    value={values.clientType}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  >
                    <option value="">{t("select")}</option>
                    <option value="Company">{t("company")}</option>
                  </select>
                  <div className="absolute right-0 top-0 mt-3">
                    <Svg18 />
                  </div>
                </div>
                {errors.clientType && touched.clientType ? (
                  <p className="text-[red]">{errors.clientType}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="address" className="text-[#313135]">
                  {t("address")}
                </label>
                <input
                  type="text"
                  id="address"
                  name="streetAddress"
                  value={values.streetAddress}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                />
                {errors.streetAddress && touched.streetAddress ? (
                  <p className="text-[red]">{errors.streetAddress}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="country" className="text-[#313135]">
                  {t("country")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                    type="text"
                    id="country"
                    name="country"
                    value={values.country}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setCountry(e.target.value);
                      setFieldValue(`country`, e.target.value);
                    }}
                  >
                    <option className="p-5" value="">
                      {t("select_country")}
                    </option>
                    {countryname &&
                      Object.keys(countryname)?.map((st, index) => (
                        <option value={st} key={index}>
                          {countryname[st]}
                        </option>
                      ))}
                  </select>
                  <div className="absolute right-0 top-0 mt-3">
                    <Svg18 />
                  </div>
                </div>
                {errors.country && touched.country ? (
                  <p className="text-[red]">{errors.country}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="state" className="text-[#313135]">
                  {t("state")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                    type="text"
                    id="state"
                    name="state"
                    value={values.state}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setState(e.target.value);
                      setFieldValue(`state`, e.target.value);
                      setSubmitOnce(true);
                    }}
                  >
                    <option className="p-5" value="">
                      {t("select_state")}
                    </option>
                    {statename &&
                      Object.keys(statename)?.map((pm, index) => (
                        <option value={pm} key={index}>
                          {statename[pm]}
                        </option>
                      ))}
                  </select>
                  <div className="absolute right-0 top-0 mt-3">
                    <Svg18 />
                  </div>
                </div>
                {errors.state && touched.state ? (
                  <p className="text-[red]">{errors.state}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="city" className="text-[#313135]">
                  {t("city")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                    type="text"
                    id="city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option className="p-5" value="">
                      {cityname === null
                        ? "No Data Available"
                        : t("select_city")}
                    </option>
                    {cityname?.length > 0 &&
                      cityname?.map((pm, index) => (
                        <option value={pm} key={index}>
                          {pm}
                        </option>
                      ))}
                  </select>
                  <div className="absolute right-0 top-0 mt-3">
                    <Svg18 />
                  </div>
                </div>
                {errors.city && touched.city ? (
                  <p className="text-[red]">{errors.city}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="timeZone" className="text-[#313135]">
                  {t("timezone")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                    type="text"
                    id="timeZone"
                    name="timeZone"
                    value={values.timeZone}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  >
                    <option value="">select</option>
                    <option value="ist">IST</option>
                  </select>
                  <div className="absolute right-0 top-0 mt-3">
                    <Svg18 />
                  </div>
                </div>

                {errors.timeZone && touched.timeZone ? (
                  <p className="text-[red]">{errors.timeZone}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="onBoardDate" className="text-[#313135]">
                  {t("onBoardDate")}
                </label>
                <DatePicker
                  name="onboardDate"
                  value={values.onboardDate}
                  handleChange={handleOnChange}
                  handleBlur={handleBlur}
                />
                {errors.onboardDate && touched.onboardDate ? (
                  <p className="text-[red]">{errors.onboardDate}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="onboardedBy" className="text-[#313135]">
                  {t("onboardedBy")}
                </label>
                <input
                  type="text"
                  name="onboardedBy"
                  id="onboardedBy"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  value={values?.onboardedBy}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                />
                {errors.onboardedBy && touched.onboardedBy ? (
                  <p className="text-[red]">{errors.onboardedBy}</p>
                ) : null}
              </div>

              <div className="relative flex flex-col space-y-2">
                <label htmlFor="document">{t("document")}</label>
                <div
                  className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center justify-between"
                  onClick={() => buttonClick(inputRef)}
                >
                  <input
                    type="file"
                    name="documentUrl"
                    ref={inputRef}
                    id="document"
                    className="hidden"
                    onChange={(e) => {
                      setDocument(e.target.files[0]);
                      setDefaultVal(e.target.files[0]?.name);
                    }}
                    accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
                  />
                  <button onClick={(e) => handleUploadFile(e, values.document)}>
                    {defaultValue}
                  </button>
                  <img
                    className="absolute top-[36px] right-[15px] mt-2"
                    src={`${awsURL}/images/cloudUpload.png`}
                    alt="upload"
                  />
                </div>
                {errors.documentUrl && touched.documentUrl ? (
                  <p className="text-[red]">{errors.documentUrl}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="goodsAndServicesTax" className="text-[#313135]">
                  {t("goodAndServiceTax")}
                </label>
                <input
                  type="text"
                  name="goodsAndServicesTax"
                  id="goodsAndServicesTax"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  value={values.goodsAndServicesTax}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  onKeyPress={handletaxRate}
                />
                {errors.goodsAndServicesTax && touched.goodsAndServicesTax ? (
                  <p className="text-[red]">{errors.goodsAndServicesTax}</p>
                ) : null}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="taxRate" className="text-[#313135]">
                  {t("taxrate")}
                </label>
                <input
                  type="text"
                  id="taxRate"
                  name="taxRate"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  value={values.taxRate}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  onKeyPress={handletaxRate}
                />
                {errors.taxRate && touched.taxRate ? (
                  <p className="text-[red]">{errors.taxRate}</p>
                ) : null}
              </div>

              <div className="w-full h-fit flex flex-col space-y-3">
                <div className="w-full relative flex flex-col space-y-1">
                  <label htmlFor="Password">{t("password")}</label>
                  <input
                    className="h-12 border w-full p-4"
                    type={passwordVisible[0] ? "text" : "password"}
                    id="Password"
                    name="password"
                    value={values.password}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  />
                  {passwordVisible[0] ? (
                    <BsEyeFill
                      className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
                      onClick={() => togglePasswordVisibility(0)}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
                      onClick={() => togglePasswordVisibility(0)}
                    />
                  )}
                </div>
                {errors.password && touched.password ? (
                  <p className="text-[red]">{errors.password}</p>
                ) : null}
              </div>

              <div className="w-full h-fit flex flex-col space-y-3">
                <div className="w-full relative flex flex-col space-y-1">
                  <label htmlFor="passwordConfirmation">
                    {t("confirm_password")}
                  </label>
                  <input
                    className="h-12 border w-full p-4"
                    type={passwordVisible[0] ? "text" : "password"}
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  />
                  {passwordVisible[0] ? (
                    <BsEyeFill
                      className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
                      onClick={() => togglePasswordVisibility(0)}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.9rem] cursor-pointer"
                      onClick={() => togglePasswordVisibility(0)}
                    />
                  )}
                </div>
                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                  <p className="text-[red]">{errors.passwordConfirmation}</p>
                ) : null}
              </div>
            </form>
          </div>
        ))}
    </>
  );
}

export default ClientName;

ClientName.propTypes = {
  handleHeaderClick: PropTypes.any,
  setStatusModal: PropTypes.func.isRequired,
  invoiceList: PropTypes.func.isRequired,
  sortBy: PropTypes.func.isRequired,
  sortOrder: PropTypes.func.isRequired,
  setInvoiceList: PropTypes.func.isRequired,
  statusModal: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  projectId: PropTypes.number.projectId,
  name: PropTypes.any.name,
  date: PropTypes.number.date,
};
