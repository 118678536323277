import ToastServices from "ToastServices";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleUploadBulkFile } from "redux/appThunk/Admin/employee";

// eslint-disable-next-line react/prop-types
export default function EmployeeCsvPreview({onPreviewBackClick}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.file.fileData);
  const [isClicked ,setIsClicked] = useState(false);
  const convertToCSV = (fileData) => {
    const header = Object.keys(fileData[0]).join(",");
    const rows = fileData?.map(row => Object.values(row).join(","));

    return `${header}\n${rows.join("\n")}`;
  };

  const handleConfirmSaveClick = async () => {
    if (isClicked) {
      return;
    }

    setIsClicked(true);

    try {
      const csvData = convertToCSV(fileData);
      const blob = new Blob([csvData], { type: "text/csv" });
      const formData = new FormData();
      formData.append("file", blob, "employee_data.csv");
      await dispatch(handleUploadBulkFile(formData));
      navigate("/employees");
    } catch (error) {
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
      setIsClicked(false);
    }
  };

  return (
    <>
      <div className="w-[100%] h-[calc(100vh-5.1rem)] bg-white space-y-4 max-h-[620px]
      flex flex-col p-5 pb-1 rounded-[4px] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] overflow-y-auto">
        <table className="w-full p-5 bg-white rounded-lg text-left">
          <thead className="border flex-0 text-left p-2">
            <tr className="max-h-[3.125rem] h-[3.125rem] p-2 text-[#686868]
            text-sm font-normal bg-[#F2F6FF] border border-[#E2E8F0] ">
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Employee Name")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Employee ID")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Designation")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Contact No")}</th>
              <th className="max-h-[3.125rem] h-full w-[360px] p-2">{t("Date of Joining")}</th>
            </tr>
          </thead>
          <tbody>
            {fileData?.map((file, index) => (
              <tr key={index} className="border p-2 text-[]">
                <td className="p-2 text-[#031B59]">
                  <div className="whitespace-pre-line">{file["full_name"]}</div>
                  <div className="whitespace-pre-line">{file["email"]}</div>
                </td>
                <td className="p-2 text-[#031B59]">
                  <span>{file["employee_id"]}</span>
                </td>
                <td className="p-2 text-[#031B59]">
                  <span>{file["designation"]}</span>
                </td>
                <td className="p-2 text-[#031B59]">
                  <span>{file["contact_no"]}</span>
                </td>
                <td className="p-2 text-[#031B59]">
                  <span>{file["date_of_joining"]}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fixed bottom-8 right-12 flex justify-end space-x-3 ">
        <button
          data-testid='backPre-1'
          className="w-[107px] h-[49px] text-[#686868] p-[12px_24px_12px_24px] text-xl "
          onClick={onPreviewBackClick}
        >
          {t("back")}
        </button>
        <button className="text-[#FFFFFF] w-[180px] h-[49px] px-[15px]
        rounded-full border-[1px] bg-[#031B59]" onClick={handleConfirmSaveClick}>
          {t("confirm_save")}
        </button>
      </div>
    </>
  );
}
