/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Popup from "component/common/Popup";
import {
  assignRoleForAccessPopup,
  assignRoleForAccessPopupNew
} from "redux/appThunk/Admin/permission";

function AddDepartmentPop({
  handleCloseDialogBox,
  handleSubmit,
  setVal,
}) {
  const { t } = useTranslation();

  return (
    <Popup
      title={t("departmentName")}
      popupBox={handleCloseDialogBox}
      handleSubmit={handleSubmit}
      submitBtnText={t("add")}
    >
      <div className="w-full">
        <>
          <div className=" m-auto flex flex-col gap-[10px]">
            <div className="w-full">
              <div className="relative flex flex-col">
                <div
                  className="m-[1rem] p-[0.25rem] border rounded-[0.4rem] flex items-center
                    justify-center"
                >
                  <input className="w-full max-w-[37.5rem] h-fit min-h-[45px] flex flex-wrap items-center capitalize
                      justify-start gap-2 p-[0.5rem]" onChange={(e) => { setVal(e.target.value); }} />
                </div>

              </div>
            </div>
          </div>
        </>
      </div>
    </Popup>
  );
}

export default AddDepartmentPop;
AddDepartmentPop.propTypes = {
  handleCloseDialogBox: PropTypes.any,
  handleSubmit: PropTypes.func,
  setVal: PropTypes.any,
};
