import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RxCross2 } from 'react-icons/rx';
import SvgRight from 'svgComponents/department/SvgRignt.jsx';
import SvgWrong from 'svgComponents/department/SvgWrong.jsx';
import SvgDelete from 'svgComponents/department/SvgDelete';
import SvgEdit from 'svgComponents/department/SvgEdit';
import ProfileAvtar from 'component/common/ProfileAvtar';
import Search from '../search_comp/Search.jsx';
import { AddButton } from 'component/common/accessControlUi/Button';
import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';
import CustomTable from 'component/common/table/CustomTable.jsx';
import { createDesignation,deleteDesignation,EditDesignation,
  showDesignation } from 'redux/appThunk/Admin/department.js';
import Popup from 'component/common/Popup.jsx';
import LoaderComp from 'component/loader/LoaderComp.js';
import useDebounce from 'hooks/useDebounce.jsx';
import { IoIosArrowDown } from 'react-icons/io';

const DepartmentView = ({ id, onClose }) => {
  const { t } = useTranslation();
  const [searchItem, setSearchItem] = useState("");
  const [close, setClose] = useState(true);
  const [editField, setEditField] = useState(null);
  const { userPermission } = usePermissions(mappedPermissionObj.Myteams);
  const [newDesignation, setNewDesignation] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [getData, setGetData] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  const { designationData} = useSelector((state) => state.departmentReducer);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [designationIdToDelete, setdesignationIdToDelete] = useState(null);
  const [loader, setLoder] = useState(false);
  const [users,setUsers] = useState();

  useEffect(() => {
    dispatch(showDesignation(id));
  }, [id, dispatch,getData]);

  useEffect(() => {
    if (editField) {
      setNewDesignation(editField.designation);
      setShowInput(true);
    }
  }, [editField]);
  const searchQuery = useDebounce (searchItem, 800);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(showDesignation(id,searchQuery));
  }, [id, dispatch,searchQuery]);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const handleInputChange = useCallback((e) => {
    setNewDesignation(e.target.value);
  }, []);

  const handleAddOrUpdateDesignation = useCallback(() => {
    if (newDesignation.trim()) {
      if (editField) {
        dispatch(EditDesignation(newDesignation, editField?.id, id));
      } else {
        dispatch(createDesignation(newDesignation, id, setGetData));
      }
      setNewDesignation("");
      setShowInput(false);
      setEditField(null);
    }
  }, [newDesignation, dispatch, editField, id]);

  const handleEdit = (job) => {
    setEditField(job);
    setNewDesignation(job.designation);
    setShowInput(true);
  };

  const handleClose = useCallback(() => {
    setClose(false);
    onClose();
  }, [onClose]);

  const handleCancelNewRow = useCallback(() => {
    setShowInput(false);
    setNewDesignation("");
    setEditField(null);
  }, []);

  const Input = useCallback(({ value, onChange }) => {
    return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        ref={inputRef}
        className="active:outline-[#031B59] focus-visible:outline-gray-500 text-black"
      />
    );
  }, []);
  const handleDelete = (id) => {
    setdesignationIdToDelete(id);
    setIsDeletePopupOpen(true);
  };
  const handleCancelDelete = () => {
    setIsDeletePopupOpen(false);
    setdesignationIdToDelete(null);
  };
  const handleConfirmDelete = () => {
    if (designationIdToDelete !== null) {
      dispatch(deleteDesignation(id,designationIdToDelete,setLoder,setGetData)).then(() => {
        dispatch(showDesignation(id));
        setIsDeletePopupOpen(false);
        setdesignationIdToDelete(null);
      });
    }
  };

  const handleArrowClick = (designation) => {
    setUsers(designation.users);
    setIsDialogOpen(true);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsDialogOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  const SvgData = useCallback(({ value, onClick, onCancel }) => {
    return (
      <>
        <button
          onClick={() => onClick(value)}
          className="text-white p-1 rounded items-center justify-center"
        >
          <SvgRight value={""} />
        </button>
        <button
          onClick={onCancel}
          className="text-white p-1 rounded  items-center justify-center"
        >
          <SvgWrong/>
        </button>

      </>
    );
  }, []);

  const TopNewData = useMemo(() => (
    <tr>
      <td className="min-w-[11rem] w-fit px-[15px] h-[50px] text-[#d8d5d5] capitalize">
        <Input value={newDesignation} onChange={handleInputChange} />
      </td>
      <td className = "min-w-[15rem] w-[15px] h-[60px] text-[#686868] flex items-center justify-center"></td>
      <td className="min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]">
        <SvgData value={newDesignation} onClick={handleAddOrUpdateDesignation} onCancel={handleCancelNewRow}/>
      </td>
    </tr>
  ), [newDesignation, handleInputChange, handleAddOrUpdateDesignation, handleCancelNewRow]);

  const columns = useMemo(() => [
    {
      title: "Designation Name",
      field: "designation",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
    },
    {
      title: "Employees",
      field: "employees",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      render: (value, job) => {
        const users = job?.users || [];

        if (users.length === 0) {
          return (
            <div className="pl-2 text-left text-[#686868]">
              Please Add employees !.
            </div>
          );
        }

        return(
          <div className="relative flex items-center justify-between gap-[2rem]">
            <div className="flex items-center relative w-[2rem]">
              {job?.users.slice(0, 3).map((user, index) => (
                <div
                  key={index}
                  style={{ left: index !== 0 && `${index * 1.75}rem` }}
                  className="absolute overflow-hidden w-[3rem] h-[3rem] rounded-full"
                >
                  {job?.employeePicture ? (
                    <img
                      className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
                      src={job?.employeePicture}
                      alt="Profile Avatar"
                    />
                  ) : (
                    <ProfileAvtar
                      height="3rem"
                      width="3rem"
                      name={user.email ? user.email : null}
                    />
                  )}
                </div>
              ))}
            </div>
            {users.length > 2 ? (
              <div className="ml-12 text-[#031B59] text-sm cursor-pointer gap-9" onClick={() => handleArrowClick(job)}>
              +{users.length - 2} more
              </div>
            ) : (
              users.length > 0 && (
                <button onClick={() => handleArrowClick(job)} className="ml-12 text-[#031B59] text-sm gap-9
                cursor-pointer">
                  <IoIosArrowDown size={20} />
                </button>
              )
            )}
            <div className="pl-2 text-left">
              <div>{job?.employeeName}</div>
              <div>{job?.empCode}</div>
            </div>

          </div>
        );
      },
    },
    {
      title: "Action",
      field: "action",
      align: "left",
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868]",
      render: (value, job) => (
        <div>
          <button onClick={() => handleEdit(job)}>
            <SvgEdit />
          </button>
          <button className="pl-4"
            onClick={() => { handleDelete(job.id); }}
          >
            <SvgDelete />
          </button>
        </div>
      ),
    },
  ], [handleEdit]);

  if (!close) return null;

  return (
    <div className="w-full h-full flex justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] items-start">
      <div className="min-w-[40%] h-full p-5 bg-white flex
      flex-col space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
        <div className="flex border-b-2 w-[726px] justify-between items-center">
          <div className="flex gap-1 mb-5">
            <h1 className="font-semibold text-[1.4rem] text-[#031B59] flex items-center">
              {t("designation")}
            </h1>
          </div>
          <div className="p-1 text-[1.5rem] cursor-pointer mb-5" onClick={handleClose}>
            <RxCross2 />
          </div>
        </div>

        <div className="w-[100%] h-fit">
          <div className='w-full h-16 flex lg:justify-end md:justify-start'>
            <div className="flex justify-end gap-[10px] text-xs items-center">
              <div className='flex items-center justify-center lg:space-x-4 md:space-x-2'>
                <Search
                  searchItem = {searchItem}
                  setSearchItem ={setSearchItem}
                  currentResource={userPermission}
                />
              </div>
              <AddButton
                className="rounded-[2.5rem] border-[1.5px] h-[3rem] lg:w-[12rem] md:w-[8.5rem] text-[#A1A1A1]"
                currentResource={mappedPermissionObj.designation}
                onClick={() => setShowInput(!showInput)}
                title={t("addDesignation")}
              />
            </div>
          </div>
        </div>
        {isDeletePopupOpen && (
          <Popup
            popupBox={handleCancelDelete}
            title={t("deleteDesignation")}
            handleSubmit={handleConfirmDelete}
            submitBtnText="Delete"
          >
          </Popup>
        )}

        <div className="h-[44rem] custom_scroll overflow-x-hidden mt-7 w-full capitalize">
          {loader ? <LoaderComp/> : (
            <CustomTable
              columns={columns}
              data={designationData}
              isLoading={false}
              tableContainerClass=""
              customTableClass=""
              renderAction={(job) => (
                <div>
                  <button onClick={() => handleEdit(job)}>
                    <SvgEdit />
                  </button>
                  <button className="pl-4">
                    <SvgDelete />
                  </button>
                </div>
              )}
              TopNewData={showInput ? TopNewData : null}
            // showNoDataMessage={!showInput}
            // setGetData={setGetData}
            />
          )}
        </div>
        {isDialogOpen && (
          <div
            className={`bg-white absolute shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-1 border-[1px] mt-6
        rounded-md border-[#E2E8F0] custom_scroll overflow-y-auto z-10 top-[18rem] right-[9rem]`}
            ref={dropDownRef}
            style={{ maxHeight: '17rem' }}
          >
            <div className="w-[266px] relative rounded-lg">
              <div className="flex-col justify-start items-center gap-[15px] pl-5">

                {users && users.length > 0 ? (
                  users.map((user, index) => (
                    <div key={index} className="py-3 text-[#031B59] flex items-center">
                      <div className="w-[3rem] h-[3rem] rounded-full overflow-hidden mr-3">
                        {user.employeePicture ? (
                          <img
                            className="w-full h-full object-cover"
                            src={user.employeePicture}
                            alt="Profile Avatar"
                          />
                        ) : (
                          <ProfileAvtar
                            height="3rem"
                            width="3rem"
                            name={user.email}
                          />
                        )}
                      </div>
                      <span>{user.email}</span>
                    </div>
                  ))
                ) : (
                  <div className="py-3 text-[#031B59]">No users found</div>
                )}

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DepartmentView.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepartmentView;
